import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {getAddressList} from 'modules/addresses/thunks';
import {DELIVERY_ID_FIELD_NAME, DELIVERY_TYPE_FIELD_NAME} from 'modules/checkout/constants/fields';
import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {getPaymentMethodList, getPickupPointList} from 'modules/checkout/thunks';
import {LocationService} from 'modules/locations/services/LocationService';
import {DeliveryType} from 'new-models';
import {ChangeEventHandler, useCallback, useContext} from 'react';

export const useCheckoutDeliveryMethod = () => {
    const dispatch = useAppDispatch();
    const {setFieldValue, values} = useCheckoutFormikContext();
    const {deliveryMethodData} = useContext(CheckoutContext);

    const handleDeliveryMethodChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        async (event) => {
            const selectedDeliveryId = event.target.value as string;
            const {type} = deliveryMethodData[selectedDeliveryId];

            if (type) {
                setFieldValue(DELIVERY_ID_FIELD_NAME, selectedDeliveryId);
                setFieldValue(DELIVERY_TYPE_FIELD_NAME, type);

                const userCityId = (await LocationService.getUserCityId()) ?? '';

                const params = {locationId: userCityId};

                switch (type) {
                    case DeliveryType.PICKUP: {
                        dispatch(getPickupPointList(selectedDeliveryId, params));
                        break;
                    }
                    case DeliveryType.COURIER: {
                        // dispatch(getCourierTimeSlotList(selectedDeliveryId, params));

                        dispatch(getAddressList);

                        break;
                    }
                    default:
                        break;
                }
                dispatch(getPaymentMethodList(selectedDeliveryId));
            }
        },
        [deliveryMethodData, dispatch, setFieldValue]
    );

    return {
        deliveryId: values.deliveryData?.deliveryId,
        handleDeliveryMethodChange,
    };
};
