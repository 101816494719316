import classNames from 'classnames';
import {LogoLoader} from 'components/Loader';
import {SvgIcon} from 'components/SvgIcon';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {YandexMapControls} from 'modules/yandex-map/components/YandexMapControls';
import {useYandexMapStateChange} from 'modules/yandex-map/hooks/useYandexMapStateChange';
import {YandexMapProvider} from 'modules/yandex-map/provider';
import {selectYandexMapIsLoadSuccess} from 'modules/yandex-map/selectors';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import mapMarker from 'public/icons/placemark.svg';
import React, {memo, MouseEventHandler, ReactNode, useEffect, useId, useState} from 'react';
import {IMapState} from 'yandex-maps';

import style from './style.module.scss';

export interface IProps extends IMapState {
    className?: string;
    children?: ReactNode;
    onMapReady?: (api: YandexMapService) => void;
    withStaticCentredMarker?: boolean;
    handleCenter?: MouseEventHandler<HTMLButtonElement>;
}

export const YandexMap = memo<IProps>(function YandexMap({
    onMapReady,
    className,
    children,
    handleCenter,
    // withInitialMarker = false,
    withStaticCentredMarker = false,
    ...defaultRestMapStateProps
}) {
    const uid = useId();
    const [yandexMapService, setYandexMapService] = useState<YandexMapService>();
    const [isReady, setIsReady] = useState<boolean>(false);

    useYandexMapStateChange(defaultRestMapStateProps, yandexMapService?.map);

    const isLoadSuccess = useAppSelector(selectYandexMapIsLoadSuccess);

    useEffect(() => {
        if (!isLoadSuccess) {
            return;
        }

        setYandexMapService(new YandexMapService(global.ymaps));
    }, [isLoadSuccess]);

    useEffect(() => {
        if (!yandexMapService || isReady) {
            return;
        }

        setIsReady(true);
        yandexMapService.render(uid, defaultRestMapStateProps);
        yandexMapService?.map?.controls.remove('zoomControl');
        yandexMapService?.map?.controls.remove('geolocationControl');

        // if (withInitialMarker) {
        //     yandexMapService?.addPlaceMark(
        //         defaultRestMapStateProps.center as number[],
        //         {},
        //         YANDEX_PLACEMARK_OPTIONS_DEFAULT
        //     );
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady, yandexMapService]);

    useEffect(() => {
        if (!isLoadSuccess || !isReady || !yandexMapService || !onMapReady) {
            return;
        }

        onMapReady(yandexMapService);
    }, [isLoadSuccess, isReady, onMapReady, yandexMapService]);

    return (
        <YandexMapProvider ready={isReady} service={yandexMapService}>
            <div className={classNames(className, style.yandexMapContainer)} id={uid}>
                {!isReady && <LogoLoader />}
                {isReady && (
                    <>
                        <YandexMapControls
                            handleCenter={handleCenter}
                            initialCenter={defaultRestMapStateProps.center}
                            mapInstance={yandexMapService?.map}
                        />
                        {withStaticCentredMarker && (
                            <span className={style.CentredMarker}>
                                <SvgIcon svg={mapMarker} />
                            </span>
                        )}
                    </>
                )}
            </div>
            {isReady && children}
        </YandexMapProvider>
    );
});
