import {TPropsWithClassName} from 'core/types';
import {ListingBanner} from 'modules/common/components/ListingBanner';
import React, {ReactNode, useCallback} from 'react';

interface IRenderListingPannerParams extends TPropsWithClassName {
    idx: number;
    multiplicity: number;
}

export const useRenderListingBanner = () => {
    return useCallback(
        ({idx, multiplicity, className}: IRenderListingPannerParams): ReactNode =>
            !((idx + 1) % multiplicity) ? (
                <ListingBanner className={className} idx={(idx + 1) / multiplicity - 1} />
            ) : null,
        []
    );
};
