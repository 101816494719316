import {useScrollDirection} from 'core/hooks/useScrollDirection';
import {useCallback, useMemo, useState} from 'react';

export const useIsScrollBottom = () => {
    const [isBottom, setIsBottom] = useState<boolean>(false);

    useScrollDirection({
        onScrollDown: useCallback(() => {
            setIsBottom(true);
        }, []),
        onScrollUp: useCallback(() => {
            setIsBottom(false);
        }, []),
    });

    return useMemo(() => isBottom, [isBottom]);
};
