import {formatBonus} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {Bonus, LoyaltyCardType} from 'models';
import {actionSetPortalVisible} from 'modules/portal/slice';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {actionChangeBonuses} from 'modules/price-n-bonuses/slice';
import {TUsedIn} from 'modules/price-n-bonuses/types';
import {PRODUCT_CARD_VARIANT, PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {TProductCardVariant, TProductListView} from 'modules/products/types';
import {ProductPrice} from 'new-models';
import {MouseEventHandler, useCallback} from 'react';

export const usePriceAndBonuses = () => {
    const dispatch = useAppDispatch();

    const isSale = useCallback((basePrice: number | null | undefined): boolean => Boolean(basePrice), []);

    // TODO: for the time of mixing two mock servers
    // const isBonuses = useCallback(
    //     (usedIn: TUsedIn, bonus: Bonus, isProductAvailable: boolean, loyaltyCardType?: LoyaltyCardType): boolean =>
    //         Boolean(formatBonus(bonus)) &&
    //         (!loyaltyCardType || loyaltyCardType === LoyaltyCardType?.Bonus) &&
    //         isProductAvailable &&
    //         usedIn !== USED_IN.productSet,
    //     []
    // );

    const isAlternative = useCallback(
        (usedIn: TUsedIn, alternative?: ProductPrice['additional'], isProductAvailable?: boolean): boolean =>
            Boolean(alternative && isProductAvailable && usedIn === USED_IN.mainOffer),
        []
    );

    const isRenderBonusesIcon = useCallback(
        (usedIn: TUsedIn, isLogged: boolean, extraBonus?: Bonus, loyaltyCardType?: LoyaltyCardType): boolean =>
            (!isLogged || !loyaltyCardType || Boolean(formatBonus(extraBonus))) &&
            usedIn !== USED_IN.searchPanel &&
            usedIn !== USED_IN.mainHeader &&
            usedIn !== USED_IN.cartPopup,
        []
    );

    const isBottomOldPrice = useCallback(
        (usedIn: TUsedIn): boolean => usedIn !== USED_IN.mainOffer && usedIn !== USED_IN.techListPreview,
        []
    );

    const isBottomTitle = useCallback(
        (usedIn: TUsedIn, isProductAvailable?: boolean): boolean =>
            usedIn !== USED_IN.mainOffer && usedIn !== USED_IN.cartResume && !isProductAvailable,
        []
    );

    const openModal = useCallback(
        (modalName: string): MouseEventHandler<HTMLButtonElement> =>
            (event) => {
                event.stopPropagation();
                dispatch(actionSetPortalVisible(modalName));
            },

        [dispatch]
    );

    const handleBonusesButtonClick = useCallback(
        (modalName: string, bonus: Bonus, extraBonus?: Bonus): MouseEventHandler<HTMLButtonElement> =>
            (event) => {
                event.preventDefault();
                event.stopPropagation();
                dispatch(actionChangeBonuses({bonus, extraBonus}));
                dispatch(actionSetPortalVisible(modalName));
            },
        [dispatch]
    );

    const setSimpleCardUsedIn = useCallback((listType: TProductListView, variant: TProductCardVariant): TUsedIn => {
        if (PRODUCT_LIST_VIEW.list === listType) {
            return USED_IN.techListPreview;
        }

        if (variant === PRODUCT_CARD_VARIANT.auto) {
            return USED_IN.techPreview;
        }

        return variant === PRODUCT_CARD_VARIANT.preview ? USED_IN.techPreview : USED_IN.visualPreview;
    }, []);

    const setCartUsedIn = useCallback(
        (isForHeaderPopup?: boolean): TUsedIn => (Boolean(isForHeaderPopup) ? USED_IN.cartPopup : USED_IN.cartResume),
        []
    );

    return {
        handleBonusesButtonClick,
        isAlternative,
        isBottomOldPrice,
        isBottomTitle,
        isRenderBonusesIcon,
        isSale,
        openModal,
        setCartUsedIn,
        setSimpleCardUsedIn,
    };
};
