import {TAppRootState, TSelect} from 'core/redux/types';
import {ISortingState} from 'plugins/modules/sorting/types';

export function createSortingSelectors<T extends ISortingState>(select: TSelect<T>) {
    function selectSortingList(state: TAppRootState): T['sortingList'] {
        return select(state).sortingList;
    }

    return {
        selectSortingList,
    };
}
