import {isBrowserContext} from 'core/helpers';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectLog} from 'modules/request-logger/selectors';
import {useEffect} from 'react';

export const useRequestLog = () => {
    const log = useAppSelector(selectLog);

    useEffect(() => {
        if (!isBrowserContext()) {
            return;
        }

        log.forEach((record) => {
            /* eslint-disable no-console */
            console.groupCollapsed(
                `%c[SSR] ${record.hostname} :: %c ${record.code} %c ${new Date(record.time).toISOString()}`,
                'color: red',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                `background: ${200 === record.code ? 'green' : 'red'}; color: white;`,
                'color: yellow; font-size: 12px'
            );

            console.groupCollapsed('Response');
            console.log(record.response);
            console.groupEnd();

            console.groupCollapsed('Payload');
            console.log(record.payload);
            console.groupEnd();

            console.groupCollapsed('Headers');
            console.log(record.headers);
            console.groupEnd();

            console.groupEnd();

            /* eslint-enable */
        });
    }, [log]);
};
