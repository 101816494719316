import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {ReviewStatus} from 'modules/orders/components/ReviewStatus';
import {OrderPaymentStatusEnum} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    statusType: OrderPaymentStatusEnum;
}

export const PaymentStatus = memo<IProps>(function PaymentStatus({statusType, className}) {
    return (
        <div className={classNames(style.root, className)}>
            <ReviewStatus statusType={statusType} />
        </div>
    );
});
