import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectPopularList} from 'modules/products/selectors';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import {PromoPlace} from 'new-models';

export const HomePagePopular = () => {
    const popularList = useAppSelector(selectPopularList);

    if (!popularList?.length) {
        return null;
    }

    return (
        <PageSectionBlock href="/catalog/popular" linkText="Все товары" title="Популярное">
            <SliderWithBanner href="/catalog/popular" products={popularList} promoPlace={PromoPlace.Popular} />
        </PageSectionBlock>
    );
};
