import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {BREAKPOINT_SLIDER} from 'modules/brand/constants';
import {Modal} from 'modules/modals/components/Modal';
import {RegularModal} from 'modules/modals/components/RegularModal';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {Slider} from 'modules/slider/components/Slider';
import {BrandDetail, MediaGroupVisual, MediaTypeEnum} from 'new-models';
import React, {memo, useCallback, useState} from 'react';

import style from './style.module.scss';

interface IBrandVideoReviewsProps {
    presentationList: BrandDetail['presentation'];
}

const MODAL_NAME = MODALS.BRAND_VIDEO_REVIEWS_MODAL.name;

export const BrandVideoReviews = memo<IBrandVideoReviewsProps>(({presentationList}) => {
    const dispatch = useAppDispatch();
    const [reviews, setReviews] = useState<MediaGroupVisual | null>(null);

    const handleClick = useCallback(
        (media: MediaGroupVisual) => () => {
            setReviews(media);
            dispatch(showModal(MODAL_NAME));
        },
        [setReviews, dispatch]
    );

    if (!presentationList?.length) {
        return null;
    }

    return (
        <div className={style.root}>
            <Typography color="gray100" variant="h2-bold">
                Видео обзоры
            </Typography>
            <Slider breakpoints={BREAKPOINT_SLIDER} className={style.content} isNavigation>
                {presentationList
                    .filter(({type}) => MediaTypeEnum.Rutube === type || MediaTypeEnum.Youtube === type)
                    .map((media) => (
                        <>
                            <div className={style.card} key={media.title} onClick={handleClick(media)}>
                                <MediaHarvester className={style.img} isPreview media={media} />
                                <Typography color="gray80" variant="h5-bold">
                                    {media.title}
                                </Typography>
                            </div>
                        </>
                    ))}
            </Slider>
            <Modal name={MODAL_NAME} willMount>
                {reviews ? (
                    <RegularModal className={style.modal}>
                        <div className={style.modalContent}>
                            <MediaHarvester media={reviews} />
                        </div>
                    </RegularModal>
                ) : null}
            </Modal>
        </div>
    );
});
