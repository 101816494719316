import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ICategoriesState} from 'modules/categories/types';
import {HYDRATE} from 'next-redux-wrapper';

export const categoryModuleName = 'category';

const initialState: ICategoriesState = {
    categoriesList: [],
    popularCategoryList: [],
    productPreviewList: {},
};

export const categoriesSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[categoryModuleName];
        },
    },
    initialState,
    name: categoryModuleName,
    reducers: {
        actionSetCategoryList(state, {payload}: PayloadAction<ICategoriesState['categoriesList']>) {
            state.categoriesList = payload;
        },
        actionSetPopularCategoryList(state, {payload}: PayloadAction<ICategoriesState['popularCategoryList']>) {
            state.popularCategoryList = payload;
        },

        actionSetPreviewProductList(state, {payload}: PayloadAction<ICategoriesState['productPreviewList']>) {
            state.productPreviewList = payload;
        },
    },
});

export const {
    actions: {actionSetCategoryList, actionSetPreviewProductList, actionSetPopularCategoryList},
} = categoriesSlice;

export const {reducer: categoriesReducer} = categoriesSlice;
