import {TAppThunk} from 'core/redux/types';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {actionSetIsYandexMapLoading, actionSetIsYandexMapLoadSuccess} from 'modules/yandex-map/slice';

export const connectYandexMap: TAppThunk<void> = async (dispatch) => {
    YandexMapService.connect({
        onScriptAdd: () => {
            dispatch(actionSetIsYandexMapLoading(true));
        },
        onScriptLoaded: () => {
            global?.ymaps?.ready(() => {
                dispatch(actionSetIsYandexMapLoading(false));
                dispatch(actionSetIsYandexMapLoadSuccess(true));
            });
        },
    });
};
