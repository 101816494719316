import {BrandListPageContext} from 'modules/brand/context';
import {BrandListPage} from 'modules/brand/pages/BrandListPage';
import {BRAND_LIST_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const BrandListPageSkeleton: FC = () => {
    return (
        <BrandListPageContext.Provider value={BRAND_LIST_PAGE_SKELETON_DATA}>
            <BrandListPage />
        </BrandListPageContext.Provider>
    );
};
