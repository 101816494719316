import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {PRODUCT_CARD_VARIANT, PRODUCT_LIST_VIEW, PRODUCT_TYPE} from 'modules/products/constants';
import {TProductCardVariant, TProductListView} from 'modules/products/types';
import {ProductDetail} from 'new-models';
import noImageIcon from 'public/icons/no-image.svg';
import React, {memo} from 'react';

import style from './style.module.scss';

export const NO_IMAGE_SIZE = {
    big: 'big',
    default: 'default',
    small: 'small',
    stretched: 'stretched',
} as const;

type TNoImageSize = keyof typeof NO_IMAGE_SIZE;

export interface IProps extends TPropsWithClassName {
    type?: ProductDetail['type'];
    size?: TNoImageSize;
    listView?: TProductListView;
    variant?: TProductCardVariant;
    heightAuto?: boolean;
}

export const NoImage = memo<IProps>(function NoImage({
    className,
    size = NO_IMAGE_SIZE.big,
    type = PRODUCT_TYPE.simple,
    listView = PRODUCT_LIST_VIEW.tile,
    variant = PRODUCT_CARD_VARIANT.auto,
    heightAuto,
}) {
    return (
        <div
            className={classNames(
                style.noImage,
                className,
                {
                    [style[type]]: Boolean(type),
                    [style[size]]: Boolean(size),
                    [style[listView]]: Boolean(listView),
                    [style[variant]]: Boolean(variant),
                    [style.heightAuto]: heightAuto,
                },
                'no-image-component'
            )}
        >
            <SvgIcon svg={noImageIcon} />
        </div>
    );
});
