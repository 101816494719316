import {IListingState, TWithListing} from 'plugins/modules/listing/types';

export const initialListingState: IListingState = {
    list: [],
    listType: 'tile',
};

export const withListing = <T>(originState: T): TWithListing<T> => {
    return {
        ...originState,
        ...initialListingState,
    };
};
