import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useSlice} from 'plugins/hooks/useSlice';
import {PAGINATION_TYPE} from 'plugins/modules/pagination/constants';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {IPaginationState, TPaginationActions} from 'plugins/modules/pagination/types';
import {useContext, useEffect} from 'react';

export const usePaginationType = () => {
    const dispatch = useAppDispatch();
    const {page} = useContext(PaginationContext);
    const slice = useSlice<IPaginationState, TPaginationActions>();

    useEffect(() => {
        const actionSetPaginationType = slice?.actions?.actionSetPaginationType;

        if (!actionSetPaginationType) {
            return;
        }

        if (1 === page) {
            dispatch(actionSetPaginationType(PAGINATION_TYPE.AUTO));
        }

        // eslint-disable-next-line consistent-return
        return () => {
            dispatch(actionSetPaginationType(PAGINATION_TYPE.AUTO));
        };
    }, [dispatch, page, slice?.actions]);
};
