import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {MENU_LAYOUT_CODES} from 'modules/menu/constants';
import {getFooterMenu, getMenuByCode, makeHeaderCatalogMenu} from 'modules/menu/helpers';
import {menuModuleName} from 'modules/menu/slice';
import {IMenuState} from 'modules/menu/types';

const {
    catalogActionsMenu,
    copyRight,
    footerMenu,
    headerCatalogMenu,
    headerTopBuyers,
    headerTopLeft,
    payments,
    social,
    headerTopRight,
} = MENU_LAYOUT_CODES;

const select = (state: TAppRootState): IMenuState => state[menuModuleName];
export const selectMenuList = createDraftSafeSelector([select], (state) => state.menuList);
export const selectHeaderCatalogMenu = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, headerCatalogMenu)
);
export const selectHeaderCatalogMenuFormatted = createDraftSafeSelector([selectHeaderCatalogMenu], (state) =>
    makeHeaderCatalogMenu(state)
);
export const selectHeaderTopLeftMenu = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, headerTopLeft)
);

export const selectHeaderTopRightMenu = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, headerTopRight)
);
export const selectHeaderTopForBuyersMenu = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, headerTopBuyers)
);
export const selectFooterMenu = createDraftSafeSelector([selectMenuList], (state) => getMenuByCode(state, footerMenu));
export const selectFooterMenuFormatted = createDraftSafeSelector([selectFooterMenu], (state) => getFooterMenu(state));
export const selectSocialMedia = createDraftSafeSelector([selectMenuList], (state) => getMenuByCode(state, social));
export const selectPaymentServices = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, payments)
);
export const selectCopyright = createDraftSafeSelector([selectMenuList], (state) => getMenuByCode(state, copyRight));
export const selectCatalogPromotionsMenu = createDraftSafeSelector([selectMenuList], (state) =>
    getMenuByCode(state, catalogActionsMenu)
);
