import {RouteProvider} from 'modules/router/components/RouteProvider';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {RouteContext} from 'modules/router/context';
import {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import {useRouter} from 'next/router';
import React, {FC, PropsWithChildren, ReactNode, useContext, useMemo} from 'react';

interface IRouteProps extends PropsWithChildren {
    path: string;
    component?: ReactNode;
    layout?: ReactNode;
    initialQuery?: NextParsedUrlQuery;
    fallbackComponent?: ReactNode;
    queryKey?: string;
}

export const Route: FC<IRouteProps> = ({
    queryKey = ROUTER_QUERY_KEY,
    fallbackComponent,
    initialQuery,
    layout,
    component,
    children,
    path,
}) => {
    const {prevPath, defaultQuery = initialQuery} = useContext(RouteContext);
    const {query} = useRouter();

    const routerQuery = useMemo<NextParsedUrlQuery>(() => {
        return defaultQuery || query;
    }, [defaultQuery, query]);

    const routerQueryData = useMemo<string>(() => routerQuery[queryKey] as string, [queryKey, routerQuery]);

    const regexString = useMemo<string>(() => {
        return `${prevPath}${path}$`;
    }, [path, prevPath]);

    const regex = useMemo<RegExp>(() => {
        return new RegExp(regexString, 'u');
    }, [regexString]);

    const isMatch = useMemo<boolean>(() => {
        return regex.test(routerQueryData as string);
    }, [regex, routerQueryData]);

    const matchedList = useMemo<string[]>(() => {
        const regexMatchList = (routerQueryData as string)?.match(regex);

        if (!regexMatchList) {
            return [];
        }

        return Array.from(regexMatchList);
    }, [routerQueryData, regex]);

    const matchedGroups = useMemo<Record<string, any>>(() => {
        const groups = (routerQueryData as string)?.match(regex)?.groups;

        if (!groups) {
            return {};
        }

        return groups;
    }, [routerQueryData, regex]);

    const matched = useMemo<string | undefined>(() => {
        return matchedList[matchedList.length - 1];
    }, [matchedList]);

    const renderComponentOrChild = useMemo<ReactNode>(() => {
        return isMatch ? layout || component : children;
    }, [children, component, isMatch, layout]);

    if (fallbackComponent && !isMatch) {
        return <>{fallbackComponent}</>;
    }

    return (
        <RouteProvider
            allMatches={matchedList}
            component={component}
            defaultQuery={defaultQuery}
            matched={matched}
            matchedGroups={matchedGroups}
            prevPath={`${prevPath}${path}`}
        >
            {renderComponentOrChild}
        </RouteProvider>
    );
};
