import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TProductResponse} from 'modules/product-card/models/ProductResponse';
import {PRODUCT_PAGE_ROUTE} from 'modules/product-card/routes';
import {TProductDeliveryInfoResponse} from 'modules/product-card/services/ProductDeliveryInfoResponse';
import {IProductRouterQuery} from 'modules/product-card/types';
import {ProductBase, ProductDetail} from 'new-models';
import {PRODUCT_ROUTE} from 'routing/constants';

export class ProductCardService extends EvolutionHttpClient {
    public async getProduct(productId: ProductDetail['id']): Promise<TProductResponse> {
        const {data} = await this.get<TProductResponse>({
            url: `/product/${productId}`,
        });

        return data;
    }

    public static makeProductCardUrl = (productCode: ProductDetail['code'], productId: ProductDetail['id']) => {
        return `${productCode}-${productId}`;
    };

    public static makeFullProductCardUrl = ({code, id}: Pick<ProductBase, 'id' | 'code'>) => {
        return `${PRODUCT_ROUTE}/${this.makeProductCardUrl(code, id)}`;
    };

    public static parseProductUrlPath = (path: string): IProductRouterQuery => {
        const matchedGroup = new RegExp(PRODUCT_PAGE_ROUTE, 'ug').exec(path)?.groups || {};
        return {...matchedGroup} as unknown as IProductRouterQuery;
    };

    public async getDeliveryInfo(
        locationId: string,
        productIds?: ProductDetail['id'][] | null
    ): Promise<TProductDeliveryInfoResponse> {
        const query = new URLSearchParams();
        if (locationId) {
            query.set('locationId', locationId);
        }
        if (productIds) {
            query.set('productIds', productIds.join());
        }
        const {data} = await this.get<TProductDeliveryInfoResponse>({
            url: `/delivery/info?${query}`,
        });

        return data;
    }
}
