import {disableScroll, enableScroll} from 'core/helpers';
import {TAppThunk} from 'core/redux/types';
import {IShowModalOptions} from 'modules/modals/types';
import {selectIsAnyPortalVisible} from 'modules/portal/selectors';
import {actionPortalHideAll, actionSetPortalHidden, actionSetPortalVisible} from 'modules/portal/slice';

export const showModal =
    (modalName: string, showModalOptions: IShowModalOptions = {withoutScrollToTop: true}): TAppThunk<void> =>
    async (dispatch, getState) => {
        const isAnyModalVisible = selectIsAnyPortalVisible(getState());

        if (!isAnyModalVisible) {
            disableScroll(showModalOptions?.withoutScrollToTop);
        }

        if (showModalOptions?.isHideAll) {
            dispatch(actionPortalHideAll());
        }

        dispatch(actionSetPortalVisible(modalName));
    };

export const hideModal =
    (modalName: string): TAppThunk<any> =>
    async (dispatch, getState) => {
        dispatch(actionSetPortalHidden(modalName));

        const isAnyModalVisible = selectIsAnyPortalVisible(getState());

        if (!isAnyModalVisible) {
            enableScroll();
        }
    };

export const hideAllModals: TAppThunk<any> = async (dispatch, getState) => {
    dispatch(actionPortalHideAll());

    const isAnyModalVisible = selectIsAnyPortalVisible(getState());

    if (!isAnyModalVisible) {
        enableScroll();
    }
};
