import {TClosureCallback} from 'core/types';
import {useRouter} from 'next/router';

export const useIsNeedRoute: TClosureCallback<string[], boolean> = (paths) => {
    const {route} = useRouter();
    return paths.some((path) => path === route);
};

export const useIsIncludesRoute: TClosureCallback<string[], boolean> = (paths) => {
    const {route} = useRouter();
    return paths.some((path) => route.includes(path));
};
