import * as Slider from '@radix-ui/react-slider';
import {SliderProps} from '@radix-ui/react-slider';
import classNames from 'classnames';
import {Input, IProps as IInputProps, TOnValueChange} from 'components/Input';
import {DEBOUNCE_DELAY, PRICE_DENOMINATOR} from 'core/constants';
import debounce from 'lodash/debounce';
import {FilterRange} from 'new-models';
import {FilterContext} from 'plugins/modules/filter/context';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import style from './style.module.scss';

export const RangeFilter: FC<FilterRange> = ({maxRange, minRange, code, maxValue, minValue, step, unit}) => {
    const {urlService: filterService} = useContext(FilterContext);

    const [minRangeValue, setMinRangeValue] = useState<number>(0);
    const [maxRangeValue, setMaxRangeValue] = useState<number>(0);

    const [minRangeValueInput, setMinRangeValueInput] = useState<number>();
    const [maxRangeValueInput, setMaxRangeValueInput] = useState<number>();

    const isLoading = useIsListLoading();

    useEffect(() => {
        if ('number' !== typeof maxRange || 'number' !== typeof minRange) {
            return;
        }

        setMinRangeValue(minValue ?? minRange);
        setMinRangeValueInput(minValue ?? minRange);

        setMaxRangeValue(maxValue ?? maxRange);
        setMaxRangeValueInput(maxValue ?? maxRange);
    }, [maxRange, maxValue, minRange, minValue]);

    const handleValueCommit = useCallback<NonNullable<SliderProps['onValueCommit']>>(
        async ([min, max]) => {
            await filterService?.addRangeParams({
                filterCode: code,
                from: min * PRICE_DENOMINATOR,
                to: max * PRICE_DENOMINATOR,
            });
        },
        [filterService, code]
    );

    const handleMinSubmit = useCallback(
        (min?: number) => {
            if ('number' !== typeof min || 'number' !== typeof maxRange) {
                return;
            }

            if (min < (minRange ?? 0)) {
                setMinRangeValueInput(minRange ?? 0);
                handleValueCommit([minRange ?? 0, maxRange]);
                return;
            }

            if (min > maxRange) {
                setMinRangeValueInput(maxRange);
                handleValueCommit([maxRange, maxRange]);
                return;
            }

            handleValueCommit([min, maxRange]);
        },
        [handleValueCommit, maxRange, minRange]
    );

    const handleMaxSubmit = useCallback(
        // eslint-disable-next-line complexity
        (max?: number) => {
            if ('number' !== typeof max || 'number' !== typeof maxRange) {
                return;
            }

            if (max > maxRange) {
                setMaxRangeValueInput(maxRange);
                handleValueCommit([minRange ?? 0, maxRange]);
                return;
            }

            if (max < (minRange ?? 0)) {
                setMaxRangeValueInput(minRange ?? 0);
                handleValueCommit([minRange ?? 0, minRange ?? 0]);
                return;
            }

            handleValueCommit([minRange ?? 0, max]);
        },
        [handleValueCommit, maxRange, minRange]
    );

    const debounceMinSubmit = debounce(handleMinSubmit, DEBOUNCE_DELAY);
    const debounceMaxSubmit = debounce(handleMaxSubmit, DEBOUNCE_DELAY);

    const handleValueChange = useCallback(([min, max]: number[]) => {
        setMaxRangeValue(max);
        setMinRangeValue(min);
    }, []);

    const handleMinRangeInputChange = useCallback<TOnValueChange>(
        (value) => {
            const numberVal = Number(value);
            if (minRangeValueInput === numberVal) {
                return;
            }

            setMinRangeValueInput(numberVal || undefined);
            debounceMinSubmit(numberVal);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleMaxRangeInputChange = useCallback<TOnValueChange>(
        (value) => {
            const numberVal = Number(value);
            if (maxRangeValueInput === numberVal) {
                return;
            }

            setMaxRangeValueInput(numberVal || undefined);
            debounceMaxSubmit(numberVal);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const inputType: IInputProps['type'] = 'price' === code ? 'currency' : 'number';

    return (
        <div className={style.rangeFilterContainer}>
            <div className={style.rangeFilterInputs}>
                <Input
                    className={classNames(style.rangeFilterInput, {
                        [style.isEmpty]: minRangeValueInput === minRange,
                    })}
                    currency={unit}
                    disabled={isLoading}
                    labelText="от"
                    onValueChange={handleMinRangeInputChange}
                    step={step}
                    type={inputType}
                    value={minRangeValueInput ?? undefined}
                />
                <Input
                    className={classNames(style.rangeFilterInput, {
                        [style.isEmpty]: maxRangeValueInput === maxRange,
                    })}
                    currency={unit}
                    disabled={isLoading}
                    labelText="до"
                    onValueChange={handleMaxRangeInputChange}
                    step={step}
                    type={inputType}
                    value={maxRangeValueInput ?? undefined}
                />
            </div>
            <div className={style.rangeFilterTrackContainer}>
                <Slider.Root
                    className={style.rangeFilter}
                    disabled={isLoading}
                    max={maxRange}
                    min={minRange}
                    onValueChange={handleValueChange}
                    onValueCommit={handleValueCommit}
                    step={step}
                    value={[minRangeValue, maxRangeValue]}
                >
                    <Slider.Track className={style.rangeFilterTrack}>
                        <Slider.Range className={style.rangeFilterRange} />
                    </Slider.Track>

                    <Slider.Thumb className={style.rangeFilterThumb} />
                    <Slider.Thumb className={style.rangeFilterThumb} />
                </Slider.Root>
            </div>
        </div>
    );
};
