import {FilterTag, IProps as IFilterTagProps} from 'components/Tag/FilterTag';
import {ChooseVariant, FilterChoose} from 'new-models';
import {FilterContext} from 'plugins/modules/filter/context';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import React, {FC, useCallback, useContext} from 'react';

interface IProps extends ChooseVariant {
    filterTitle: FilterChoose['title'];
    filterType: FilterChoose['type'];
    index: number;
    filterCode: FilterChoose['code'];
}

export const ChooseFilterTagItem: FC<IProps> = ({index, filterTitle, title, filterCode, code}) => {
    const isLoading = useIsListLoading();
    const {urlService: filterUrlService} = useContext(FilterContext);

    const handleDiscardChooseFilter: IFilterTagProps['onClick'] = useCallback(async () => {
        if (isLoading) {
            return;
        }

        await filterUrlService?.removeParam({id: filterCode, value: code});
    }, [code, filterCode, filterUrlService, isLoading]);

    return <FilterTag description={title} name={0 === index ? filterTitle : ''} onClick={handleDiscardChooseFilter} />;
};
