import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IMenuState} from 'modules/menu/types';
import {HYDRATE} from 'next-redux-wrapper';

export const menuModuleName = 'menu';

const initialState: IMenuState = {
    menuList: [],
};

export const menuSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[menuModuleName];
        },
    },
    initialState,
    name: menuModuleName,
    reducers: {
        actionSetMenuList(state, {payload}: PayloadAction<IMenuState['menuList']>) {
            state.menuList = payload;
        },
    },
});

export const {
    actions: {actionSetMenuList},
} = menuSlice;

export const {reducer: menuReducer} = menuSlice;
