import {AbstractApiResponseInterceptor} from 'core/abstract/AbstractApiResponseInterceptor';
import {isBrowserContext} from 'core/helpers';
import {ModifiedControlRequestInterceptor} from 'core/interceptors/ModifiedControlRequestInterceptor';
import {IHttpClientResponse, TJson, TRequestOption, TRequestParameters} from 'core/types';

export class ModifiedControlResponseInterceptor extends AbstractApiResponseInterceptor<
    TRequestParameters<TRequestOption, object>
> {
    public override async intercept(
        response: IHttpClientResponse<TJson, Headers, Response>,
        requestOption?: TRequestParameters<TRequestOption, object>
    ): Promise<typeof response> {
        if (!isBrowserContext()) {
            return response;
        }

        const modifiedControlRequestInterceptor = ModifiedControlRequestInterceptor.getInstance();
        const lastModified = response.headers.get('Last-Modified');
        const previousModified =
            requestOption?.url && modifiedControlRequestInterceptor.requestLastModifiedMap.get(requestOption?.url);
        const isNeedUpdate = Boolean(!previousModified || (lastModified && lastModified !== previousModified));

        if (requestOption?.url && isNeedUpdate && lastModified) {
            modifiedControlRequestInterceptor.requestLastModifiedMap.set(requestOption.url, lastModified);
        }
        return response;
    }

    private static instance: ModifiedControlResponseInterceptor;
    public static getInstance(): ModifiedControlResponseInterceptor {
        if (!ModifiedControlResponseInterceptor.instance) {
            ModifiedControlResponseInterceptor.instance = new ModifiedControlResponseInterceptor();
        }

        return ModifiedControlResponseInterceptor.instance;
    }
}
