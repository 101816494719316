import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {useGetMediaHarvesterOptionsAdaptiveImage} from 'modules/common/hooks/useGetMediaHarvesterOptionsAdaptiveImage';
import {selectBannerListByPlace} from 'modules/common/selectors';
import {MediaImageAdaptive, PromoPlace} from 'new-models';
import {memo, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    idx: number;
}

export const ListingBanner = memo<IProps>(function ListingBanner({className, idx}) {
    const banners = useAppSelector(selectBannerListByPlace(PromoPlace.Listing));
    const bannerImage = useMemo<MediaImageAdaptive | undefined>(() => {
        return banners[idx]?.media as MediaImageAdaptive;
    }, [banners, idx]);

    const options = useGetMediaHarvesterOptionsAdaptiveImage('urlM');

    if (!bannerImage) {
        return null;
    }

    return (
        <div className={classNames(style.container, className)} data-skeleton-item>
            <MediaHarvester media={bannerImage} options={options} />
        </div>
    );
});
