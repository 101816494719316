import {TOKEN_KEY} from 'core/constants';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {CookieService} from 'core/services/CookieService';
import {getCart} from 'modules/cart/thunks';
import {getCompareProductsList} from 'modules/compareProducts/thunks';
import {getFavoritesList} from 'modules/favourites/thunks';
import {useGeolocation} from 'modules/locations/hooks/useGeolocation';
import {useEffect} from 'react';

/**
 * Выполнение реквестов завязанных только на токен
 */
export const useRequestCommonData = () => {
    useGeolocation();

    const dispatch = useAppDispatch();
    const cookieService = CookieService.getInstance();

    const token = cookieService.getItem(TOKEN_KEY);

    useEffect(() => {
        // DISABLED - пока нет данных с бэкенда и возникают ошибки
        if (!token) {
            return;
        }
        dispatch(getCart);
        dispatch(getFavoritesList);
        dispatch(getCompareProductsList);
    }, [dispatch, token]);
};
