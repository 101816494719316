import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {promoModuleName} from 'modules/promo/slice';
import {TPromoState} from 'modules/promo/types';
import {createPaginationSelectors} from 'plugins/modules/pagination/selectors';

const select = (state: TAppRootState): TPromoState => state[promoModuleName];
export const selectPopularPromoList = (state: TAppRootState): TPromoState['popularPromoList'] =>
    select(state).popularPromoList;
export const selectPromoList = (state: TAppRootState): TPromoState['promoList'] => select(state).promoList;
export const selectPromoData = (state: TAppRootState): TPromoState['promoData'] => select(state).promoData;
export const {selectPaginationType, selectPage, selectPageCount, selectPageSize, selectPageSizeList} = {
    ...createPaginationSelectors(select),
};
export const selectCountOfPromoList = (count: number) =>
    createDraftSafeSelector([selectPopularPromoList], (promoList) => {
        return promoList.slice(0, count);
    });
