import classNames from 'classnames';
import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {BrandBase, MediaTypeEnum} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    brand: BrandBase;
}

const ImageFallback: FC = () => <NoImage size={NO_IMAGE_SIZE.stretched} />;

export const BrandCard = memo<IProps>(function BrandCard({brand, className}) {
    const {logo, title} = brand;

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.imageBlock}>
                <MediaWithImageFallback
                    NoImageComponent={ImageFallback}
                    className={style.image}
                    media={[logo]}
                    mediaType={MediaTypeEnum.Image}
                    title={title}
                />
            </div>
            <Typography align="center" className={style.title} color="gray80" element="div">
                {title}
            </Typography>
        </div>
    );
});
