import {Anchor} from 'components/Anchor';
import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import Link from 'next/link';
import React, {FC, useMemo} from 'react';

import style from './style.module.scss';

interface IProps {
    path?: string;
    title: string;
    productCount?: number | null;
}

export const SliderTitle: FC<IProps> = ({path, title, productCount = 111}) => {
    const renderTitle = useMemo(
        () => (
            <Typography className={style.sliderTitleWithCountTitle} color="gray100" element="h2" variant="h2">
                {title}
            </Typography>
        ),
        [title]
    );

    return (
        <div className={style.sliderTitleWithCount}>
            {Boolean(path) ? (
                <div className={style.content}>
                    <Link href={path!} passHref scroll>
                        <a data-skeleton-item>{renderTitle}</a>
                    </Link>

                    {null !== productCount && 0 < productCount && (
                        <Link href={path!} passHref scroll>
                            <Anchor
                                className={style.anchorLink}
                                data-skeleton-item
                                element="span"
                                icon="right"
                                size="large"
                                theme="standalone"
                            >
                                {productCount}
                                <span className={style.plural}>&nbsp;{getProductPluralText(productCount)}</span>
                            </Anchor>
                        </Link>
                    )}
                </div>
            ) : (
                renderTitle
            )}
        </div>
    );
};
