import {TOKEN_KEY} from 'core/constants';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {IHttpClientResponse} from 'core/types';
import {LoyaltyCard} from 'models';
import {TLoyaltyCardChangeResponse} from 'modules/loyalty-cards/models/LoyaltyCardChangeResponse';
import {TLoyaltyCardListResponse} from 'modules/loyalty-cards/models/LoyaltyCardListResponse';
import {IChangeLoyaltyCardData} from 'modules/loyalty-cards/types';

export class LoyaltyCardService extends EvolutionHttpClient {
    public async getLoyaltyCardList(): Promise<TLoyaltyCardListResponse> {
        const {data} = await this.get<TLoyaltyCardListResponse>({
            url: '/loyalty-cards',
        });

        return data;
    }

    public async changeLoyaltyCard(loyaltyCardNumber: LoyaltyCard['number']): Promise<TLoyaltyCardChangeResponse> {
        const {headers} = await this.post<IChangeLoyaltyCardData, IHttpClientResponse>({
            body: {
                loyaltyCardNumber,
            },
            url: '/state/loyalty-card',
        });

        return {
            data: {
                token: headers.get(TOKEN_KEY) || '',
            },
        };
    }
}
