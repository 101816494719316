import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {getOrderPluralText} from 'core/helpers/plural';
import {EmptyOrderList} from 'modules/orders/components/EmptyOrderList';
import {OrderCardItem} from 'modules/orders/components/OrderCardItem';
import {OrderShort} from 'new-models';
import {ListingContext} from 'plugins/modules/listing/context';
import {ListPagination} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {usePageChange} from 'plugins/modules/pagination/hooks/usePageChange';
import {usePaginationType} from 'plugins/modules/pagination/hooks/usePaginationType';
import React, {FC, forwardRef, PropsWithChildren, useContext, useRef} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

const OrderPageLayout = forwardRef<HTMLDivElement, PropsWithChildren>(({children}, ref) => {
    return (
        <div className={style.orderPage} ref={ref}>
            {children}
        </div>
    );
});

export const OrdersPage: FC = () => {
    usePaginationType();

    const {count, pageSize, page} = useContext(PaginationContext);

    const {list, isListPartiallyLoading, isListLoading} = useContext(ListingContext);
    const topNodeRef = useRef<HTMLDivElement>(null);

    const onPageChange = usePageChange();

    if (0 === list.length && false === isListLoading) {
        return (
            <OrderPageLayout>
                <EmptyOrderList />
            </OrderPageLayout>
        );
    }

    return (
        <OrderPageLayout ref={topNodeRef}>
            <Grid className={style.page}>
                <Grid className={classNames(style.orderList, columnStyle)}>
                    {list.map((item) => {
                        const currentItem = item as OrderShort;
                        return <OrderCardItem className={columnStyle} {...currentItem} key={currentItem.id} />;
                    })}
                </Grid>
                {Boolean(list.length) && (
                    <div className={columnStyle} data-skeleton-item>
                        <ListPagination
                            isLoading={isListPartiallyLoading}
                            onChange={onPageChange}
                            pageNomer={page}
                            pageSize={pageSize}
                            pluralFunction={getOrderPluralText}
                            recordCount={count}
                            scrollTarget={topNodeRef.current}
                        />
                    </div>
                )}
            </Grid>
        </OrderPageLayout>
    );
};
