import {isPage} from 'core/helpers';
import {useRouter} from 'next/router';
import {useMemo} from 'react';
import {CART_ROUTE} from 'routing/constants';

export const useIsCartPage = (): boolean => {
    const router = useRouter();

    return useMemo<boolean>(() => {
        return isPage(CART_ROUTE, router.pathname);
    }, [router.pathname]);
};
