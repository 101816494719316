import {
    getAdaptiveImageResizeMapCode,
    getResizedImageAdaptiveUrl,
    TImageAdaptiveResizeMapKey,
} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {ADAPTIVE_IMAGE_MAP} from 'modules/images/constants';
import {IImageAdaptiveMap} from 'modules/images/types';
import {MediaImageAdaptive} from 'new-models';
import React, {memo, ReactNode, SourceHTMLAttributes, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    image: MediaImageAdaptive;
    adaptiveMap?: IImageAdaptiveMap[];
    title?: string;
    resizeMapKey?: TImageAdaptiveResizeMapKey;
}

export const AdaptiveImage = memo<IProps>(function AdaptiveImage({image, adaptiveMap, title, className, resizeMapKey}) {
    const resizeMedia = useMemo(() => {
        if (!image) {
            return {} as MediaImageAdaptive;
        }
        return getResizedImageAdaptiveUrl(image, getAdaptiveImageResizeMapCode(resizeMapKey));
    }, [image, resizeMapKey]);

    const imageDefault = resizeMedia.urlL || resizeMedia.urlM || resizeMedia.urlS || resizeMedia.urlMobile;

    const renderSourceList = useMemo<ReactNode[]>(() => {
        return (adaptiveMap || ADAPTIVE_IMAGE_MAP).reduce<ReactNode[]>(
            (sourceList, {screenWidth, urlScreenVariant}, idx) => {
                if (resizeMedia[urlScreenVariant]) {
                    const media: SourceHTMLAttributes<HTMLSourceElement>['media'] = screenWidth
                        ? `(min-width: ${screenWidth}px)`
                        : undefined;

                    sourceList.push(<source key={idx} media={media} srcSet={resizeMedia[urlScreenVariant]} />);
                }
                return sourceList;
            },
            []
        );
    }, [adaptiveMap, resizeMedia]);

    return (
        <picture className={className}>
            {renderSourceList}
            <img
                alt={image.alternative ?? (image.title || title)}
                className={style.img}
                loading="lazy"
                src={imageDefault}
            />
        </picture>
    );
});
