import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TResponse} from 'core/types';
import {
    FeedbackCreateDataRequest,
    FeedbackSubjectsDataResponse,
    PromosDataResponse,
    SubscribeDataRequest,
} from 'new-models';

export class CommonService extends EvolutionHttpClient {
    public async getPromoList(): Promise<TResponse<PromosDataResponse>> {
        const {data} = await this.get<TResponse<PromosDataResponse>>({
            url: '/promos',
        });

        return data;
    }

    public async emailSubscribe(req: SubscribeDataRequest): Promise<TResponse<undefined>> {
        const {data} = await this.post<SubscribeDataRequest, TResponse<undefined>>({
            body: req,
            url: '/subscribe',
        });

        return data;
    }

    public async getFeedbackSubjects(): Promise<TResponse<FeedbackSubjectsDataResponse>> {
        const {data} = await this.get<TResponse<FeedbackSubjectsDataResponse>>({
            url: '/feedback/subjects',
        });

        return data;
    }

    public async handleFeedback(req: FeedbackCreateDataRequest): Promise<TResponse<undefined>> {
        const {data} = await this.post<FeedbackCreateDataRequest, TResponse<undefined>>({
            body: req,
            url: '/feedback',
        });

        return data;
    }
}
