import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {getProductPluralText} from 'core/helpers/plural';
import {TPropsWithClassName} from 'core/types';
import {useFormikContext} from 'formik';
import {TotalProducts} from 'modules/checkout/components/TotalProducts';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import Link from 'next/link';
import React, {memo, useMemo} from 'react';

import {useCheckoutTotalInfo} from '../../hooks/useCheckoutTotalInfo';
import style from './style.module.scss';

interface IProps extends TPropsWithClassName {}

export const CheckoutTotalInfo = memo<IProps>(function CheckoutTotalInfo({className}) {
    const {totalPrice, discountPrice, productSum, productCount, volumeValue, weightValue, products, deliveryCost} =
        useCheckoutTotalInfo();

    const {submitForm, isSubmitting} = useFormikContext();

    const renderOptionsBLock = useMemo(
        () => (
            <div className={style.options}>
                <div className={style.optionsItem}>
                    <Typography color="gray70" variant="p">
                        {productCount} {getProductPluralText(productCount)}
                    </Typography>
                    <Typography variant="p">
                        {formatPrice((productSum / PRICE_DENOMINATOR).toString(), {
                            maximumFractionDigits: 2,
                        })}
                        <span className={style.totalPriceCurrency}>₽</span>
                    </Typography>
                </div>

                {Boolean(discountPrice) && (
                    <div className={style.optionsItem}>
                        <Typography color="gray70" variant="p-strong">
                            Скидка
                        </Typography>
                        <Typography variant="p-strong">
                            -
                            {formatPrice((discountPrice / PRICE_DENOMINATOR).toString(), {
                                maximumFractionDigits: 2,
                            })}
                            <Typography className={style.totalPriceCurrency} variant="p">
                                ₽
                            </Typography>
                        </Typography>
                    </div>
                )}

                <div className={style.optionsItem}>
                    <Typography color="gray70" variant="p">
                        Доставка
                    </Typography>
                    <Typography variant="p">
                        {Boolean(deliveryCost) ? (
                            <>
                                {formatPrice((deliveryCost / PRICE_DENOMINATOR).toString(), {
                                    maximumFractionDigits: 2,
                                })}
                                <span className={style.totalPriceCurrency}>₽</span>
                            </>
                        ) : (
                            'Бесплатно'
                        )}
                    </Typography>
                </div>

                {Boolean(weightValue && volumeValue) && (
                    <div className={style.optionsItem}>
                        <Typography color="gray70" variant="p">
                            Вес/объем
                        </Typography>
                        <Typography variant="p">
                            {weightValue} кг/{volumeValue} м³
                        </Typography>
                    </div>
                )}
            </div>
        ),
        [productCount, productSum, deliveryCost, discountPrice, weightValue, volumeValue]
    );

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.content}>
                {Boolean(products) && (
                    <>
                        <TotalProducts products={products} />
                        {renderOptionsBLock}
                    </>
                )}

                <div className={style.bottom}>
                    {Boolean(products) && (
                        <TotalPriceBlock deliveryCost={deliveryCost} total={totalPrice} usedIn="cartResume" />
                    )}
                    <Button disabled={!productCount} isLoading={isSubmitting} isStretched onClick={submitForm}>
                        Оформить заказ
                    </Button>
                    <div className={style.agreeInfo}>
                        <Typography variant="p-small">
                            Нажимая на кнопку, вы даете согласие на обработку{' '}
                            <Link href="/info/privacy-security" passHref>
                                <Anchor size="small">персональных данных</Anchor>
                            </Link>{' '}
                            и принимаете{' '}
                            <Link href="/info/general-terms-and-conditions" passHref>
                                <Anchor size="small">правила продаж</Anchor>
                            </Link>{' '}
                            товаров через Интернет-магазин.
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
});
