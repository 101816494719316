import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {InputHTMLAttributes, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement>, TPropsWithClassName {}

export const QuantityVariantFilter: FC<IProps> = (props) => {
    const uid = useId();
    const {value, className, ...restInputAttributes} = props;
    return (
        <div className={classNames(style.root, className)}>
            <input id={uid} {...restInputAttributes} className={style.input} type="checkbox" />
            <label className={style.label} htmlFor={uid}>
                <Typography variant="p">{value}</Typography>
            </label>
        </div>
    );
};
