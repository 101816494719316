import {Button} from 'components/Button';
import {InfoBlock} from 'components/InfoBlock';
import {SvgIcon} from 'components/SvgIcon';
import plusIcon from 'public/icons/plus.svg';
import React, {memo, MouseEventHandler, useCallback} from 'react';

interface IProps {
    onClick: MouseEventHandler;
    rootClassName?: string;
}

export const EmptyAddressList = memo<IProps>(function EmptyAddressList({onClick, rootClassName}) {
    const buttonComponent = useCallback<FC>(
        ({className}) => (
            <Button
                className={className}
                leftIcon={<SvgIcon svg={plusIcon} />}
                onClick={onClick}
                size="medium"
                theme="secondary"
            >
                Добавить адрес
            </Button>
        ),
        [onClick]
    );

    return (
        <InfoBlock
            ButtonComponent={buttonComponent}
            className={rootClassName}
            contentText="Добавьте адрес доставки, чтобы быстрее оформлять заказы"
            headerText="У вас нет сохраненных адресов"
            isHideIcon
        />
    );
});
