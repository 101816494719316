import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {FormikProps} from 'formik/dist/types';
import {
    CUSTOMER_EMAIL_FIELD_NAME,
    CUSTOMER_FIRSTNAME_FIELD_NAME,
    CUSTOMER_LASTNAME_FIELD_NAME,
    CUSTOMER_PHONE_FIELD_NAME,
    PAYMENT_ID_FIELD_NAME,
    PICKUP_POINT_ID_FIELD_NAME,
    USER_ADDRESS_ID_FIELD_NAME,
} from 'modules/checkout/constants/fields';
import {CheckoutContext} from 'modules/checkout/context';
import {actionSetSelectedPickupPointId} from 'modules/checkout/slice';
import {ICheckoutValues} from 'modules/checkout/types';
import {useUser} from 'modules/user/hooks/useUser';
import {OrderPickupDeliveryData} from 'new-models';
import {MutableRefObject, useContext, useEffect} from 'react';

export const useCheckoutFormUpdateData = (formRef: MutableRefObject<FormikProps<ICheckoutValues> | null>) => {
    const {paymentMethodData, pickupPointData, userAddressList} = useContext(CheckoutContext);
    const dispatch = useAppDispatch();
    const {userData} = useUser();

    // eslint-disable-next-line complexity
    useEffect(() => {
        if (formRef.current && userData) {
            const lastname =
                formRef.current.getFieldProps<ICheckoutValues['customer']['lastname']>(
                    CUSTOMER_LASTNAME_FIELD_NAME
                ).value;

            const firstname =
                formRef.current.getFieldProps<ICheckoutValues['customer']['firstname']>(
                    CUSTOMER_FIRSTNAME_FIELD_NAME
                ).value;

            const phone =
                formRef.current.getFieldProps<ICheckoutValues['customer']['phone']>(CUSTOMER_PHONE_FIELD_NAME).value;

            const email =
                formRef.current.getFieldProps<ICheckoutValues['customer']['email']>(CUSTOMER_EMAIL_FIELD_NAME).value;

            if (!lastname.length && userData.lastname) {
                formRef.current.setFieldValue(CUSTOMER_LASTNAME_FIELD_NAME, userData.lastname, true);
            }

            if (!firstname.length && userData.firstname) {
                formRef.current.setFieldValue(CUSTOMER_FIRSTNAME_FIELD_NAME, userData.firstname, true);
            }

            if (!phone.length && userData.phone) {
                formRef.current.setFieldValue(CUSTOMER_PHONE_FIELD_NAME, userData.phone, true);
            }

            if (!email?.length && userData.email) {
                formRef.current.setFieldValue(CUSTOMER_EMAIL_FIELD_NAME, userData.email, true);
            }
        }
    }, [formRef, userData]);

    useEffect(() => {
        if (formRef.current && paymentMethodData) {
            const {value} = formRef.current.getFieldProps<ICheckoutValues['paymentId']>(PAYMENT_ID_FIELD_NAME);
            if (value && (!(value in paymentMethodData) || !paymentMethodData[value].available)) {
                formRef.current.setFieldValue(PAYMENT_ID_FIELD_NAME, '', true);
            }
        }
    }, [formRef, paymentMethodData]);

    useEffect(() => {
        if (formRef.current && pickupPointData) {
            const {value} =
                formRef.current.getFieldProps<OrderPickupDeliveryData['pickupPointId']>(PICKUP_POINT_ID_FIELD_NAME);
            if (value && !(value in pickupPointData)) {
                dispatch(actionSetSelectedPickupPointId(null));
                formRef.current.setFieldValue(PICKUP_POINT_ID_FIELD_NAME, '', true);
            }
        }
    }, [dispatch, formRef, pickupPointData]);

    useEffect(() => {
        if (formRef.current && userAddressList?.length) {
            const value = formRef.current
                .getFieldProps<NonNullable<ICheckoutValues['tempFields']>['userAddressCount']>(
                    USER_ADDRESS_ID_FIELD_NAME
                )
                .value?.toString();
            const isValueFound = userAddressList.some((address) => address.id === value);

            if (!isValueFound) {
                formRef.current.setFieldValue(USER_ADDRESS_ID_FIELD_NAME, '', true);
            }
        }
    }, [formRef, pickupPointData, userAddressList]);
};
