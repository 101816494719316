import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {INFO_BLOCK_TYPE, InfoBlock} from 'components/InfoBlock';
import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import {TPropsWithClassName} from 'core/types';
import {CartProductsList} from 'modules/cart/components/CartProductsList';
import {CartTotalInfo} from 'modules/cart/components/CartTotalInfo';
import {TabBarCart} from 'modules/cart/components/TabBarCart';
import {CartContext} from 'modules/cart/context';
import {useCartTotalInfo} from 'modules/cart/hooks/useCartTotalInfo';
import {TCheckedListCondition} from 'modules/cart/types';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {BasketItem} from 'new-models';
import React, {FC, useContext, useRef} from 'react';

import style from './style.module.scss';

const productsColumnStyle = getGridColumnStyles({md: 8});
const totalPriceColumnStyle = getGridColumnStyles({md: 4});

interface IProps extends TPropsWithClassName {
    divideByAvailability: BasketItem[][];
    checkedListCondition: TCheckedListCondition;
}

export const CartProducts: FC<IProps> = ({className, divideByAvailability}) => {
    const {isCartLoading} = useContext(CartContext);
    const isSkeleton = useIsSkeleton();
    const notEnoughRef = useRef<HTMLDivElement>(null);

    const cartTotalInfoParams = useCartTotalInfo(notEnoughRef.current);

    return (
        <div className={classNames(style.cartProducts, className)}>
            <Grid>
                <div className={classNames(style.cartProductsListContainer, productsColumnStyle)}>
                    {Boolean(divideByAvailability[0].length) && (
                        <CartProductsList
                            cartProductsList={divideByAvailability[0]}
                            className={style.cartProductsList}
                        />
                    )}
                    {Boolean(divideByAvailability[3]?.length) && (
                        <div className={style.isGift}>
                            <Typography className={style.isNotEnoughTitle} element="div" variant="h4">
                                Подарок за покупку
                            </Typography>

                            <CartProductsList
                                cartProductsList={divideByAvailability[3]}
                                className={style.cartProductsList}
                            />
                        </div>
                    )}
                    {Boolean(divideByAvailability[2]?.length) && (
                        <div className={style.isNotEnough} ref={notEnoughRef}>
                            <Typography className={style.isNotEnoughTitle} element="div" variant="h4">
                                Нет в наличии
                            </Typography>
                            <InfoBlock
                                contentText="Измените количество товара на доступное"
                                type={INFO_BLOCK_TYPE.warning}
                            />
                            <CartProductsList
                                cartProductsList={divideByAvailability[2]}
                                className={style.cartProductsList}
                            />
                        </div>
                    )}
                    {Boolean(divideByAvailability[1].length) && (
                        <div className={style.ofStock}>
                            <div className={style.ofStockHeader}>
                                <Typography variant="h4">Недоступны для заказа</Typography>
                                <Typography className={style.titleCount} color="gray70" variant="p">
                                    {divideByAvailability[1]?.length}{' '}
                                    <span className={style.titleUnit}>
                                        {getProductPluralText(divideByAvailability[1]?.length)}
                                    </span>
                                </Typography>
                            </div>
                            <CartProductsList
                                cartProductsList={divideByAvailability[1]}
                                className={style.cartProductsList}
                            />
                        </div>
                    )}
                </div>
                <CartTotalInfo
                    className={classNames(style.orderMakingWrap, totalPriceColumnStyle)}
                    {...cartTotalInfoParams}
                />
            </Grid>
            {(isCartLoading || !isSkeleton) && <TabBarCart {...cartTotalInfoParams} />}
        </div>
    );
};
