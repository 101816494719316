import classNames from 'classnames';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {useScroll} from 'core/hooks/useScroll';
import arrowIcon from 'public/icons/arrow-24.svg';
import React, {useCallback} from 'react';

import style from './style.module.scss';

const SCROLL_BOTTOM_MAX_OFFSET = 600;

export const GoTopButton = () => {
    const isButtonActive = useScroll(SCROLL_BOTTOM_MAX_OFFSET);

    const handleGoTopClick = useCallback(() => {
        window.document.body.scrollIntoView({behavior: 'smooth'});
    }, []);

    return (
        <div className={classNames(style.goTopContainer, {[style.goTopHidden]: !isButtonActive})}>
            <Button
                className={style.gotTopButton}
                isSquare
                leftIcon={<SvgIcon svg={arrowIcon} />}
                onClick={handleGoTopClick}
            ></Button>
        </div>
    );
};
