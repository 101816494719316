import classNames from 'classnames';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {formatPhone} from 'core/regex';
import {TPropsWithClassName} from 'core/types';
import {Form, Formik} from 'formik';
import {ERRORS, MIN_PHONE_LENGTH} from 'modules/authentication/constants';
import {FeedbackFormFields} from 'modules/common/components/FeedbackFormFields';
import {handleFeedback} from 'modules/common/thunks';
import {ModalContent} from 'modules/modals/components/ModalContent';
import {ModalTitle} from 'modules/modals/components/ModalTitle';
import {RegularModal} from 'modules/modals/components/RegularModal';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {Feedback} from 'new-models';
import {memo, useCallback, useMemo, useState} from 'react';
import * as Yup from 'yup';

import style from './style.module.scss';

const MESSAGE_MAX_LENGTH = 200;

const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(ERRORS.incorrectEmail),
    message: Yup.string()
        .required(ERRORS.messageRequired)
        .test('long message', ERRORS.longMessage, (value) => MESSAGE_MAX_LENGTH > (value?.length || 0)),
    name: Yup.string().required(ERRORS.firstNameRequired),
    phone: Yup.string()
        .required(ERRORS.phoneRequired)
        .test('short phone', ERRORS.shortPhone, (value) => formatPhone(value as string)?.length > MIN_PHONE_LENGTH),
    subjectId: Yup.string().required(ERRORS.feedbackSubjectRequired),
});

export const FeedbackModal = memo<TPropsWithClassName>(function FeedbackModal({className}) {
    const dispatch = useAppDispatch();

    const [isAgreement, setIsAgreement] = useState(false);

    const initialValues = useMemo<Feedback>(() => ({email: '', message: '', name: '', phone: '', subjectId: ''}), []);

    const onFormSubmit = useCallback(
        async (values: Feedback) => {
            await dispatch(handleFeedback({...values, agreementConfirmed: isAgreement}));

            dispatch(hideModal(MODALS.FEEDBACK.name));
        },
        [dispatch, isAgreement]
    );

    const handleAgreementChange = useCallback(() => setIsAgreement(!isAgreement), [isAgreement]);

    return (
        <RegularModal adaptive className={className} hasClose>
            <ModalTitle title="Обратная связь" />
            <ModalContent>
                <Formik initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={validationSchema}>
                    {({handleSubmit, isSubmitting}) => (
                        <Form className={classNames(style.form, className)} onSubmit={handleSubmit}>
                            <FeedbackFormFields
                                handleAgreementChange={handleAgreementChange}
                                isAgreement={isAgreement}
                                isSubmitting={isSubmitting}
                            />
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </RegularModal>
    );
});
