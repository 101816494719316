import {BaseHttpClient} from 'core/base/BaseHttpClient';
import {getRuntimeConfig} from 'core/next/helpers';
import {daDataHeaders} from 'modules/da-data/constants';
import {IDaDataResponse} from 'modules/da-data/models/DaDataResponse';
import {IGetDaDataAddressByCoords, IGetDaDataSuggestions} from 'modules/da-data/types';

export class DaDataService extends BaseHttpClient {
    constructor(api?: string, host?: string) {
        super(
            host ?? getRuntimeConfig().NEXT_PUBLIC_DADATA_API_URL ?? '',
            api ?? getRuntimeConfig().NEXT_PUBLIC_DADATA_API ?? ''
        );
    }

    public async getDaDataSuggestionsByParty(payload: IGetDaDataSuggestions) {
        const response = await this.post<Partial<IGetDaDataSuggestions>, IDaDataResponse>({
            body: payload,
            options: {...daDataHeaders},
            url: '/rs/findById/party',
        });

        return response.data;
    }

    public async getDaDataSuggestionsByAddress(payload: IGetDaDataSuggestions) {
        const response = await this.post<Partial<IGetDaDataSuggestions>, IDaDataResponse>({
            body: payload,
            options: {...daDataHeaders},
            url: '/rs/suggest/address',
        });

        return response.data;
    }

    public async getDaDataAddressByCoords(coords: IGetDaDataAddressByCoords) {
        const response = await this.post<Partial<IGetDaDataAddressByCoords>, IDaDataResponse>({
            body: coords,
            options: {...daDataHeaders},
            url: '/rs/geolocate/address',
        });

        return response.data;
    }
}
