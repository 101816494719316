import {getGridColumnStyles, Grid} from 'components/Grid';
import {Input} from 'components/Input';
import {TextArea} from 'components/TextArea';
import {TPropsWithClassName} from 'core/types';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {CheckoutSection} from 'modules/checkout/components/CheckoutSection';
import {
    COMMENT_FIELD_NAME,
    CUSTOMER_EMAIL_FIELD_NAME,
    CUSTOMER_FIRSTNAME_FIELD_NAME,
    CUSTOMER_LASTNAME_FIELD_NAME,
    CUSTOMER_PHONE_FIELD_NAME,
} from 'modules/checkout/constants/fields';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import React, {memo} from 'react';
import InputMask from 'react-input-mask';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {}

export const CheckoutRecipientDataForm = memo<IProps>(function CheckoutRecipientDataForm({className}) {
    const {submitCount} = useCheckoutFormikContext();

    const isOnceSubmitted = Boolean(submitCount);

    return (
        <CheckoutSection className={className} title="Получатель">
            <Grid className={style.form}>
                <Field name={CUSTOMER_LASTNAME_FIELD_NAME}>
                    {({field, meta}: FieldProps) => (
                        <Input
                            {...field}
                            className={columnStyle}
                            errorText={isOnceSubmitted || meta.touched ? meta.error : undefined}
                            isBorder
                            labelText="Фамилия *"
                        />
                    )}
                </Field>
                <Field name={CUSTOMER_FIRSTNAME_FIELD_NAME}>
                    {({field, meta}: FieldProps) => (
                        <Input
                            {...field}
                            className={columnStyle}
                            errorText={isOnceSubmitted || meta.touched ? meta.error : undefined}
                            isBorder
                            labelText="Имя *"
                        />
                    )}
                </Field>
                <Field name={CUSTOMER_PHONE_FIELD_NAME}>
                    {({field, meta}: FieldProps) => (
                        <InputMask className={columnStyle} mask="+7 999 999 99 99" maskPlaceholder="" {...field}>
                            <Input
                                errorText={isOnceSubmitted || meta.touched ? meta.error : undefined}
                                isBorder
                                labelText="Номер телефона *"
                                name="phone"
                                notFadingPlaceholder="+7 999 999 99 99"
                                type="tel"
                            />
                        </InputMask>
                    )}
                </Field>
                <Field name={CUSTOMER_EMAIL_FIELD_NAME}>
                    {({field, meta}: FieldProps) => (
                        <Input
                            {...field}
                            className={columnStyle}
                            errorText={isOnceSubmitted || meta.touched ? meta.error : undefined}
                            isBorder
                            labelText="Email *"
                            type="email"
                        />
                    )}
                </Field>
                <Field name={COMMENT_FIELD_NAME}>
                    {({field}: FieldProps) => <TextArea {...field} className={columnStyle} labelText="Комментарий" />}
                </Field>
            </Grid>
        </CheckoutSection>
    );
});
