import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectIsUserDataLoading, selectUserData} from 'modules/user/selectors';

export const useUser = (): {
    userData: ReturnType<typeof selectUserData> | null;
    isLoading: ReturnType<typeof selectIsUserDataLoading>;
} => {
    const userData = useAppSelector(selectUserData);
    const isLoading = useAppSelector(selectIsUserDataLoading);
    return {
        isLoading,
        userData,
    };
};
