import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Tooltip, TRIGGER_ACTIONS} from 'components/Tooltip';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TPropsWithClassName} from 'core/types';
import {useSlice} from 'plugins/hooks/useSlice';
import {FilterContext} from 'plugins/modules/filter/context';
import {IFilterState, TFilterActions} from 'plugins/modules/filter/types';
import filterIcon from 'public/icons/filter.svg';
import filterCloseIcon from 'public/icons/filter-close.svg';
import React, {FC, MouseEventHandler, useCallback, useContext, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    isHidden?: boolean;
}

export const FilterButton: FC<IProps> = ({className, isHidden = false}) => {
    const dispatch = useAppDispatch();

    const {isFilterPanelVisible, appliedChooseFiltersByVariantList, appliedRangeFiltersList} =
        useContext(FilterContext);

    const slice = useSlice<IFilterState, TFilterActions>();

    const handleShowFilter = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        dispatch(slice.actions.actionSetIsFilterPanelVisible(true));
    }, [dispatch, slice.actions]);

    const handleHideFilter = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        dispatch(slice.actions.actionSetIsFilterPanelVisible(false));
    }, [dispatch, slice.actions]);

    const appliedFiltersVariantCount = useMemo<number>(() => {
        return (
            appliedChooseFiltersByVariantList.reduce((count, appliedFilter) => {
                if (appliedFilter.variants) {
                    return count + appliedFilter.variants.length;
                }

                return 0;
            }, 0) + appliedRangeFiltersList.length
        );
    }, [appliedChooseFiltersByVariantList, appliedRangeFiltersList.length]);

    const handleFilterButtonClick = useMemo<MouseEventHandler<HTMLButtonElement>>(() => {
        if (isFilterPanelVisible) {
            return handleHideFilter;
        }

        return handleShowFilter;
    }, [handleHideFilter, handleShowFilter, isFilterPanelVisible]);

    const renderIconWithCount = useMemo(() => {
        return (
            <span className={style.iconWrapper} data-filters-count={appliedFiltersVariantCount}>
                <SvgIcon className={style.filterCaptionSvg} svg={isFilterPanelVisible ? filterCloseIcon : filterIcon} />
            </span>
        );
    }, [appliedFiltersVariantCount, isFilterPanelVisible]);

    return (
        <Tooltip
            overlay={<Typography color="gray80">{`${isFilterPanelVisible ? 'Скрыть' : 'Показать'} фильтр`}</Typography>}
            overlayClassName={style.tooltip}
            showArrow
            trigger={TRIGGER_ACTIONS.hover}
        >
            <button
                className={classNames(className, style.filterButton, {
                    [style.filtersHidden]: !isFilterPanelVisible,
                    [style.hidden]: isHidden,
                })}
                data-skeleton-item
                onClick={handleFilterButtonClick}
            >
                {renderIconWithCount}
            </button>
        </Tooltip>
    );
};
