import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ICollectionsStateOrigin, TCollectionState} from 'modules/collections/types';
import {HYDRATE} from 'next-redux-wrapper';
import {createFilterActions} from 'plugins/modules/filter/actions';
import {createFilterExtraReducers} from 'plugins/modules/filter/reducer';
import {withFilter} from 'plugins/modules/filter/state';
import {createListingActions} from 'plugins/modules/listing/actions';
import {withListing} from 'plugins/modules/listing/state';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {withPagination} from 'plugins/modules/pagination/state';

export const collectionsModuleName = 'collections';

const initialState: TCollectionState = withListing(
    withFilter(
        withPagination<ICollectionsStateOrigin>({
            popularCollectionList: [],
            relatedCollection: [],
        })
    )
);

export const collectionsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[collectionsModuleName];
        },
        ...createFilterExtraReducers<TCollectionState>(),
    },
    initialState,
    name: collectionsModuleName,
    reducers: {
        actionSetPopularCollectionList(
            state: TCollectionState,
            {payload}: PayloadAction<TCollectionState['popularCollectionList']>
        ) {
            state.popularCollectionList = payload;
        },
        actionSetRelatedCollectionList(
            state: TCollectionState,
            {payload}: PayloadAction<TCollectionState['relatedCollection']>
        ) {
            state.relatedCollection = payload;
        },

        ...createPaginationActions<TCollectionState>(),
        ...createFilterActions<TCollectionState>(),
        ...createListingActions<TCollectionState>(),
    },
});

export const {
    actions: {
        actionSetPopularCollectionList,
        actionSetRelatedCollectionList,
        actionSetPageSize,
        actionSetPageCount,
        actionSetPage,
        actionSetFilterList,
        actionSetList,
    },
} = collectionsSlice;

export const {reducer: collectionsReducer} = collectionsSlice;
