import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {isPage} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {LOGOUT_ITEM, PROFILE_USER_MENU_LIST} from 'modules/user/constants';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {FC, MouseEventHandler, useCallback, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    menuItemClassName?: string;
    isFullPathMatch?: boolean;
}

export const HeaderUserMenu: FC<IProps> = ({className, menuItemClassName, isFullPathMatch}) => {
    const router = useRouter();
    const uid = useId();

    const handleMenuClick = useCallback<MouseEventHandler<HTMLUListElement>>((event) => {
        event.stopPropagation();
    }, []);

    return (
        <ul className={classNames(style.root, className)} onClick={handleMenuClick}>
            {PROFILE_USER_MENU_LIST.map(({text, url, icon}, index) => {
                const key = `${text}:${index}:${uid}`;

                const isActive = isFullPathMatch ? url === router.pathname : isPage(url, router.pathname);

                const item = (
                    <li data-skeleton-item key={key}>
                        <Link href={url} key={key} passHref shallow>
                            <a
                                className={classNames(menuItemClassName, style.item, {
                                    [style.currentActive]: isActive,
                                })}
                            >
                                <Typography className={style.linkContent} color="gray80" variant="p">
                                    <SvgIcon svg={icon} />
                                    <span>{text}</span>
                                </Typography>
                            </a>
                        </Link>
                    </li>
                );

                return item;
            })}
            <li data-skeleton-item key={LOGOUT_ITEM.url}>
                <Link href={LOGOUT_ITEM.url} passHref shallow>
                    <a className={classNames(menuItemClassName, style.item)}>
                        <Typography className={style.linkContent} color="gray80" variant="p">
                            <SvgIcon svg={LOGOUT_ITEM.icon} />
                            <span>{LOGOUT_ITEM.text}</span>
                        </Typography>
                    </a>
                </Link>
            </li>
        </ul>
    );
};
