export const CATEGORY_LEVEL = {
    first: 1,
    second: 2,
    third: 3,
};

export const TITLE_LOCATION = {
    left: 'left',
    top: 'top',
} as const;

export const CATALOG_TITLE = 'Каталог';
