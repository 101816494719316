import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {memo} from 'react';

interface IProps extends TPropsWithClassName {}

export const CheckoutTitle = memo<IProps>(function CheckoutTitle({className}) {
    return (
        <Typography className={className} color="gray100" data-skeleton-item element="h2" variant="h2">
            Оформление заказа
        </Typography>
    );
});
