import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useAddToFavourite} from 'modules/analytics/hooks/useAddToFavourite';
import {selectFavouritesIdList} from 'modules/favourites/selector';
import {removeProductInFavouritesList} from 'modules/favourites/thunks';
import {ProductShort} from 'new-models';
import {useCallback, useEffect, useState} from 'react';

interface IUseFavouritesReturns {
    addToFavourites: () => void;
    removeProductInFavourites: () => void;
    isChecked: boolean;
    switchFavourites: (checked: boolean) => void;
    setIsChecked: (checked: boolean) => void;
}

export const useFavourites = (product: ProductShort): IUseFavouritesReturns => {
    const dispatch = useAppDispatch();
    const favouritesIdList = useAppSelector(selectFavouritesIdList);
    const [isChecked, setIsChecked] = useState(false);

    const addToFavourite = useAddToFavourite();

    const addToFavourites = useCallback(() => {
        addToFavourite(product);
    }, [addToFavourite, product]);

    const removeProductInFavourites = useCallback(() => {
        dispatch(removeProductInFavouritesList({productId: product.id}));
    }, [dispatch, product]);

    const switchFavourites = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);

            if (checked) {
                addToFavourites();
                return;
            }

            removeProductInFavourites();
        },
        [addToFavourites, removeProductInFavourites]
    );

    useEffect(() => {
        setIsChecked(favouritesIdList.includes(product.id));
    }, [favouritesIdList, product]);

    return {
        addToFavourites,
        isChecked,
        removeProductInFavourites,
        setIsChecked,
        switchFavourites,
    };
};
