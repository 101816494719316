import {Input} from 'components/Input';
import {TPropsWithClassName} from 'core/types';
import {Field, FieldProps} from 'formik';
import {ADDRESS_MAX_LENGTH} from 'modules/addresses/constants';
import {useAddressField} from 'modules/addresses/hooks/useAddressField';
import {DaDataSuggestions} from 'modules/da-data/components/DaDataSuggestions';
import {DA_DATA_TYPES} from 'modules/da-data/constants';
import {useDaDataSuggestions} from 'modules/da-data/hooks/useDaDataSuggestions';
import {DaData, DeliveryAddressBase} from 'new-models';

import style from './style.module.scss';

export const Address: FC<TPropsWithClassName> = ({className}) => {
    const {handleChange, setFieldValue, fieldMeta} = useAddressField('address', ADDRESS_MAX_LENGTH);

    const {onBlur, onFocus, showSuggestionsList, suggestionsList} = useDaDataSuggestions(
        DA_DATA_TYPES.address,
        fieldMeta.value as string,
        true
    );

    const handleGetAddress = (suggestion: DaData) => {
        if (!suggestion.data.geo_lat || !suggestion.data.geo_lon) {
            return;
        }

        setFieldValue('latitude', suggestion.data.geo_lat);
        setFieldValue('longitude', suggestion.data.geo_lon);

        if (suggestion.value) {
            setFieldValue('address', suggestion.value);
        }

        if (suggestion.data.flat) {
            setFieldValue('apartment', suggestion.data.flat);
        }

        onBlur();
    };

    return (
        <div className={style.address}>
            <Field name="address">
                {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                    <Input
                        className={className}
                        isBorder
                        labelText="Город, улица и номер дома *"
                        {...field}
                        autoComplete="off"
                        errorText={meta.touched ? meta.error : ''}
                        onChange={handleChange}
                        onFocus={onFocus}
                    />
                )}
            </Field>
            {showSuggestionsList && (
                <DaDataSuggestions onItemSelect={handleGetAddress} suggestionsList={suggestionsList} />
            )}
        </div>
    );
};
