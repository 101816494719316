import {useFormikSubmit} from 'core/hooks/useFormikSubmit';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useDeliveryAddressesActions} from 'modules/addresses/hooks/useDeliveryAddressesActions';
import {useEditAddressModalApi} from 'modules/addresses/hooks/useEditAddressModalApi';
import {selectCurrentEditAddress} from 'modules/addresses/selectors';
import {getAddressValidator} from 'modules/addresses/validators';
import {DeliveryAddress} from 'new-models';
import {useCallback, useMemo} from 'react';
import * as yup from 'yup';

const validationSchema = getAddressValidator({
    isDefault: yup.boolean().notRequired(),
});

export const useEditAddressForm = () => {
    const {update} = useDeliveryAddressesActions();
    const {close} = useEditAddressModalApi();
    const addressData = useAppSelector(selectCurrentEditAddress);

    const initialValues = useMemo(() => {
        if (!addressData) {
            return null;
        }
        return {
            ...addressData,
        };
    }, [addressData]);

    const handleSubmitForm = useCallback(
        async (values: DeliveryAddress) => {
            const {id, ...restValues} = values;
            await update({
                body: {
                    ...restValues,
                    entrance: Number(restValues.entrance),
                    floor: Number(restValues.floor),
                    ...(values.isPrivateHouse && {apartment: '', doorcode: '', entrance: null, floor: null}),
                },
                id,
            });
            close();
        },
        [close, update]
    );

    const onSubmitForm = useFormikSubmit<DeliveryAddress>(handleSubmitForm);

    return {
        initialValues,
        onSubmitForm,
        validationSchema,
    };
};
