import classNames from 'classnames';
import {BackButton} from 'components/BackButton';
import {Button} from 'components/Button';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import {CartInfoBlock} from 'modules/cart/components/CartInfoBlock';
import {CartProducts} from 'modules/cart/components/CartProducts';
import {CartProductsDeleteButton} from 'modules/cart/components/CartProducts/DeleteButton';
import {CHECKED_LIST_CONDITIONS} from 'modules/cart/constants';
import {CartContext} from 'modules/cart/context';
import style from 'modules/cart/pages/CartPage/style.module.scss';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {PopularProductsSlider} from 'modules/slider/components/PopularProductsSlider';
import {RecommendedProductsSlider} from 'modules/slider/components/RecommendedProductsSlider';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import emptyCart from 'public/icons/empty-cart.svg';
import React, {FC, useContext, useEffect, useState} from 'react';

const CartUserActionsModal = dynamic(() => import('modules/cart/components/CartUserActionsModal'), {
    loading: () => <ModalLazyLoader />,
});

const CartSharingModal = dynamic(() => import('modules/cart/components/CartSharingModal'), {
    loading: () => <ModalLazyLoader />,
});

const CartOutOfStockModal = dynamic(() => import('modules/cart/components/CartOutOfStockModal'), {
    loading: () => <ModalLazyLoader />,
});

const CartAnalogsModal = dynamic(() => import('modules/cart/components/CartAnalogsModal'), {
    loading: () => <ModalLazyLoader />,
});

// TODO: убрать два файла со стилями на стр 3 и 15
export const CartPage: FC = () => {
    const {cartProductsList, checkedListCondition, divideByAvailability, isCartLoading} = useContext(CartContext);

    const [isEmptyCartMessage, setIsEmptyCartMessage] = useState(false);

    useEffect(() => {
        if (isCartLoading) {
            return;
        }

        if (!cartProductsList.length) {
            setIsEmptyCartMessage(true);
            return;
        }

        setIsEmptyCartMessage(false);
    }, [cartProductsList, isCartLoading]);

    return (
        <SkeletonWrapper disabled={!isCartLoading}>
            <section className={classNames(style.cartPage)}>
                <div className={style.container}>
                    <BackButton className={style.backButton} theme="ghost" />
                </div>
                <div className={style.titleContainer}>
                    <div className={style.titleBlock}>
                        <Typography data-skeleton-item variant="h1">
                            Корзина
                        </Typography>
                        {Boolean(cartProductsList?.length) && (
                            <Typography
                                className={style.titleCount}
                                color="secondary100"
                                data-skeleton-item
                                variant="p"
                            >
                                {cartProductsList?.length}{' '}
                                <span className={style.titleUnit}>
                                    {getProductPluralText(cartProductsList?.length)}
                                </span>
                            </Typography>
                        )}
                    </div>
                    {Boolean(cartProductsList?.length) && (
                        <CartProductsDeleteButton checkedListCondition={CHECKED_LIST_CONDITIONS.NONE} />
                    )}
                </div>
                <div className={style.main}>
                    {isEmptyCartMessage && (
                        <div className={style.withoutProducts}>
                            <SvgIcon svg={emptyCart} />
                            <div className={style.withoutProductsContainer}>
                                <Typography variant="h4">В корзине пока пусто</Typography>
                                <Typography className={style.description} element="p" variant="p">
                                    Воспользуйтесь поиском или каталогом для выбора товаров
                                </Typography>
                            </div>
                            <Link href="/" passHref>
                                <a className={style.backLink}>
                                    <Button>Перейти на главную</Button>
                                </a>
                            </Link>
                        </div>
                    )}
                    {Boolean(cartProductsList.length) && (
                        <>
                            <CartInfoBlock cartProductsList={cartProductsList} />
                            <CartProducts
                                checkedListCondition={checkedListCondition}
                                divideByAvailability={divideByAvailability}
                            />
                        </>
                    )}
                </div>

                <RecommendedProductsSlider className={style.sliderBlock} />
                <PopularProductsSlider className={style.sliderBlock} />
                <Modal name={MODALS.CART_USER_ACTIONS.name} willMount>
                    <CartUserActionsModal />
                </Modal>
                <Modal name={MODALS.CART_SHARING.name} willMount>
                    <CartSharingModal />
                </Modal>
                <Modal name={MODALS.OUT_OF_STOCK.name} willMount>
                    <CartOutOfStockModal divideByAvailability={divideByAvailability} />
                </Modal>
                <Modal name={MODALS.CART_ANALOGS.name} willMount>
                    <CartAnalogsModal />
                </Modal>
            </section>
        </SkeletonWrapper>
    );
};
