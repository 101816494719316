import {Anchor} from 'components/Anchor';
import {TPropsWithClassName} from 'core/types';
import {useHandleShowGalleryModal} from 'modules/product-card/hooks/useHandleShowGalleryModal';
import React, {FC} from 'react';

interface IProps extends TPropsWithClassName {}

export const ShowMoreImagesLink: FC<IProps> = ({className}) => {
    const handleShowGalleryModal = useHandleShowGalleryModal();

    return (
        <Anchor className={className} icon="right" onClick={handleShowGalleryModal()} size="large" theme="standalone">
            Смотреть все фото
        </Anchor>
    );
};
