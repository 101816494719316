import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {Price, ProductPrice} from 'new-models';
import React, {FC, ReactNode, useCallback, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    alternative: NonNullable<ProductPrice['additional']>;
}

export const Alternative: FC<IProps> = ({className, alternative}) => {
    const uid = useId();

    const renderAlternativeItem = useCallback(
        ({price, unit}: Price): ReactNode => (
            <Typography color="gray100" element="p" variant="p-strong">
                <span>
                    {formatPrice((price / PRICE_DENOMINATOR).toString(), {
                        maximumFractionDigits: 2,
                    })}
                </span>{' '}
                <span>₽</span>
                <span>/{unit}</span>
            </Typography>
        ),
        []
    );

    return Array.isArray(alternative) ? (
        <ul className={classNames(className, style.list)}>
            {alternative.map((alternativeItem, idx) => (
                <li className={style.item} key={`${uid}--${idx}`}>
                    {renderAlternativeItem(alternativeItem)}
                </li>
            ))}
        </ul>
    ) : (
        <div className={className}>{renderAlternativeItem(alternative)}</div>
    );
};
