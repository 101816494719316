import {TClosureCallback} from 'core/types';
import {IHandleSlideProps, TGalleryMode} from 'modules/slider/types';
import {MediaImage, MediaYoutube} from 'new-models';
import {createContext, MouseEventHandler} from 'react';

export interface IGallerySliderContextProps {
    isVisible: boolean;
    mode: TGalleryMode;
    onClick: TClosureCallback<IHandleSlideProps, MouseEventHandler<HTMLButtonElement>>;
    slidesList: (MediaImage | MediaYoutube)[];
}

export const GallerySliderContext = createContext<IGallerySliderContextProps>({} as IGallerySliderContextProps);
