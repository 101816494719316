import {YOUTUBE_STATE} from 'components/VideoYoutube';
import {REDIRECT_REQUEST_COOKIE_NAME} from 'core/constants';
import {CookieService} from 'core/services/CookieService';
import {TBeforePopStateCallback} from 'core/types';
import {ProductOfferPrice, ProductOfferProductSet} from 'models';
import {PRODUCT_RATING_ANCHOR_ID} from 'modules/product-card/constants';
import {SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY} from 'modules/products/constants';
import {ILocalStorageSafeProductData} from 'modules/products/types';
import {ProductPrice} from 'new-models';
import {PaginationUrlService} from 'plugins/modules/pagination/services/PaginationUrlService';
import {RefObject} from 'react';
import {YouTubePlayer} from 'react-youtube';

const {playing, paused} = YOUTUBE_STATE;

export const countTotal = (priceData: ProductOfferPrice, productSet: ProductOfferProductSet[]) => {
    const main = {...priceData.main};

    productSet.forEach((productSetItem) =>
        productSetItem.variants.forEach((variant) => {
            if (variant.isSelected && main.basePrice) {
                main.price += variant.price.price;
                main.basePrice += variant.price.price;
            } else {
                main.price += variant.price.price;
            }
        })
    );
    return {
        alternative: null,
        main,
    } as ProductPrice;
};

export const onListingHistoryPopState: TBeforePopStateCallback = ({as}) => {
    const restoringProductData: ILocalStorageSafeProductData = JSON.parse(
        localStorage.getItem(SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY) || '{}'
    );

    if (!restoringProductData.productPage) {
        return true;
    }

    const rewriteUrl = new URL(window.location.origin + as);
    rewriteUrl.searchParams.set(
        PaginationUrlService.COMMON_PARAM_NAMES.PAGE.toString(),
        restoringProductData.productPage
    );

    const cookieService = CookieService.getInstance();
    cookieService.setItem(REDIRECT_REQUEST_COOKIE_NAME, rewriteUrl.toString());

    return true;
};

// TODO: убрать метод transformUnitToSet, когда будет доделан бэк
export const transformUnitToSet = (productSetTotalPrice: ProductOfferPrice) => {
    return {...productSetTotalPrice, main: {...productSetTotalPrice.main, unit: 'компл.'}};
};

export const stopVideo = (videoRef: RefObject<HTMLVideoElement>) => {
    if (!videoRef.current) {
        return;
    }

    videoRef.current.pause();
    videoRef.current.currentTime = 0;
};

export const stopYoutubeVideo = (youTubePlayer: YouTubePlayer) => {
    if (!youTubePlayer) {
        return;
    }

    const state = youTubePlayer.getPlayerState();

    if (playing === state || paused === state) {
        youTubePlayer.stopVideo();
    }
};

export const getProductRatingAnchorUrl = (url: string): string => {
    return `${url}#${PRODUCT_RATING_ANCHOR_ID}`;
};
