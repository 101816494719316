import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {formatDateWithLocal} from 'core/helpers/date';
import {DeliveryInfo} from 'new-models';
import React from 'react';

import style from './style.module.scss';

interface IProps {
    delivery: DeliveryInfo[];
}

export const TotalDelivery: FC<IProps> = ({delivery}) => {
    return (
        <div className={style.root}>
            {Boolean(delivery.length) && (
                <>
                    {delivery.map((item) => {
                        const {deliveryMethod, deliveryDate, deliveryMinPrice} = item;

                        return (
                            <div className={style.deliveryItem} key={deliveryMethod.id}>
                                <div className={style.leftBlock}>
                                    <MediaHarvester className={style.icon} media={deliveryMethod.icon} />
                                    <Typography variant="p-strong">
                                        {deliveryMethod.title}{' '}
                                        {deliveryDate && (
                                            <Typography variant="p-regular">{`с ${formatDateWithLocal(
                                                deliveryDate,
                                                'dd MMMM'
                                            )},`}</Typography>
                                        )}{' '}
                                        {Boolean(deliveryMinPrice) && (
                                            <Typography variant="p-bold-strong">{`от ${formatPrice(
                                                (deliveryMinPrice / PRICE_DENOMINATOR).toString(),
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            )}₽`}</Typography>
                                        )}
                                    </Typography>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <Typography variant="p-small">Условия доставки и оплаты можно выбрать при оформлении заказа</Typography>
        </div>
    );
};
