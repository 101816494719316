import {createAction, PayloadAction} from '@reduxjs/toolkit';
import {IFilterState} from 'plugins/modules/filter/types';

export function createFilterActions<T extends IFilterState>() {
    return {
        actionSetComboFilterList(state: T, {payload}: PayloadAction<T['comboFilterList']>) {
            state.comboFilterList = payload;
        },

        actionSetFilterList(state: T, {payload}: PayloadAction<T['filterList']>) {
            state.filterList = payload;
        },

        actionSetIsFilterPanelVisible(state: T, {payload}: PayloadAction<T['isFilterPanelVisible']>) {
            state.isFilterPanelVisible = payload;
        },
    };
}

export const GLOBAL_FILTER_ACTIONS = {
    actionSetIsFilterPanelVisible: createAction<boolean>('plugin:filter/actionSetIsFilterPanelVisible'),
};
