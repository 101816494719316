import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {updateCartBatch} from 'modules/cart/thunks';
import {getPaymentLink} from 'modules/checkout/thunks';
import {OrderXPageContext} from 'modules/orders/context';
import {OrderPaymentStatusEnum, PatchBasketRequestData} from 'new-models';
import {useRouter} from 'next/router';
import {MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {CART_ROUTE, THANKS_FOR_YOUR_ORDER_ROUTE} from 'routing/constants';

interface IUseNoPaidCbsData {
    payOrder: MouseEventHandler<HTMLButtonElement>;
    openPayments: MouseEventHandler<HTMLButtonElement>;
    createNew: MouseEventHandler<HTMLButtonElement>;
}

export const useStatusCbs = (): IUseNoPaidCbsData => {
    const {orderData} = useContext(OrderXPageContext);
    const dispatch = useAppDispatch();

    const {push} = useRouter();

    const [payLink, setPayLink] = useState<string>();

    const {paidStatus, id} = orderData ?? {};

    const mountPaidLink = useCallback(async () => {
        if (!id) {
            return;
        }
        const link = await dispatch(getPaymentLink(id));
        setPayLink(link);
    }, [dispatch, id]);

    const payOrder = useCallback(async () => {
        if (!payLink) {
            return;
        }

        const redirectUrl = `${window.location.protocol}//${window.location.host}${THANKS_FOR_YOUR_ORDER_ROUTE}/${id}`;

        const url = new URL(payLink);
        url.searchParams.set('back_url_s', redirectUrl);
        url.searchParams.set('back_url_f', redirectUrl);

        push(url);
    }, [id, payLink, push]);

    const openPayments = useCallback(async () => {
        // eslint-disable-next-line
        console.log('изменить способ оплаты');
    }, []);

    useEffect(() => {
        if (paidStatus?.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID) {
            mountPaidLink();
        }
    }, [mountPaidLink, paidStatus?.status]);

    const createNew = useCallback(async () => {
        const cartItems = orderData?.products.map<PatchBasketRequestData>(({product, quantity}) => ({
            productId: product.id,
            quantity,
        }));

        if (!cartItems?.length) {
            return;
        }
        const success = await dispatch(updateCartBatch(cartItems));

        if (success) {
            push(CART_ROUTE);
        }
    }, [dispatch, orderData?.products, push]);

    return useMemo(
        () => ({
            createNew,
            openPayments,
            payOrder,
        }),
        [createNew, openPayments, payOrder]
    );
};
