import {useCallback, useMemo, useState} from 'react';
import {Swiper as SwiperClass} from 'swiper/types';

interface IUseSwiperData {
    swiper?: SwiperClass;
    onInit: (swiper: SwiperClass) => void;
}
const useSwiper = (): IUseSwiperData => {
    const [swiper, setSwiper] = useState<SwiperClass>();

    const onInit = useCallback((swiperClass: SwiperClass) => {
        setSwiper(swiperClass);
    }, []);

    return useMemo(
        () => ({
            onInit,
            swiper,
        }),
        [onInit, swiper]
    );
};

export default useSwiper;
