import {TPropsWithChildrenRequire} from 'core/types';
import React, {useEffect} from 'react';

interface IProps<K extends string, E extends HTMLElement> extends TPropsWithChildrenRequire {
    container: E | null;
    scrollId?: K;
    behavior?: ScrollBehavior;
    block?: ScrollLogicalPosition;
    inline?: ScrollLogicalPosition;
    scrollMarkAttribute?: string;
    onScroll?: () => void;
    disabled?: boolean;
    verticalOffset?: number;
}

/**
 * Компонент скрола
 * @param {string} scrollMarkAttribute - дата селекторо для поиска тега, на которой нужно проскролить
 * @param {ScrollBehavior} block - одна из опций
 * @param {ScrollBehavior} behavior - одна из опций
 * @param {ScrollBehavior} inline - одна из опций
 * @param {HTMLElement} container - контейнер, в котором будет производится поиск узлов для скрола
 * @param {string} scrollId - метка скрола
 * @param {ReactNode} children
 * @param {() => void} onScroll - колбэк после скрола
 * @param {boolean} disabled
 * @param {number} verticalOffset
 */
export function ScrollTo<K extends string, E extends HTMLElement>({
    scrollMarkAttribute = 'data-scroll-mark',
    block = 'center',
    behavior = 'smooth',
    inline = 'center',
    container,
    scrollId,
    children,
    onScroll,
    disabled,
    verticalOffset,
}: IProps<K, E>) {
    useEffect(() => {
        if (disabled) {
            return;
        }

        if (!scrollId) {
            return;
        }

        if (!container) {
            return;
        }

        const scrollCandidate = container.querySelector(`[${scrollMarkAttribute}="${scrollId}"]`);

        if (!scrollCandidate) {
            return;
        }

        if (onScroll) {
            onScroll();
        }

        if ('number' === typeof verticalOffset) {
            const dimension = scrollCandidate.getBoundingClientRect();

            window.scrollTo(window.scrollX, dimension.top - verticalOffset + window.scrollY);

            return;
        }

        scrollCandidate.scrollIntoView({behavior, block, inline});
    }, [behavior, block, container, disabled, inline, onScroll, scrollId, scrollMarkAttribute, verticalOffset]);

    return <>{children}</>;
}
