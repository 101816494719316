import {Typography} from 'components/Typography';
import {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    value: string;
    handleClickOnSuggestionItem: (value: string) => void;
}

export const DaDataSuggestion = memo<IProps>(function DaDataSuggestion({value, handleClickOnSuggestionItem}) {
    const handleClickItem = () => handleClickOnSuggestionItem(value);

    return (
        <li className={style.suggestionItem}>
            <button onClick={handleClickItem} type="button">
                <Typography variant="p-regular">{value}</Typography>
            </button>
        </li>
    );
});
