import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TNormalize} from 'core/types';
import {IProductSubscribeRequest} from 'modules/product-subscription/models/ProductSubscribeRequest';
import {TProductSubscribeResponse} from 'modules/product-subscription/models/ProductSubscribeResponse';

export class ProductSubscriptionService extends EvolutionHttpClient {
    public async subscribeToProduct(
        subscribeData: TNormalize<IProductSubscribeRequest>
    ): Promise<TProductSubscribeResponse> {
        const {data} = await this.post<TNormalize<IProductSubscribeRequest>, TProductSubscribeResponse>({
            body: subscribeData,
            url: '/product/receipt-notice',
        });

        return data;
    }
}
