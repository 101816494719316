import {useHash} from 'core/hooks/useHash';
import debounce from 'lodash/debounce';
import {BrandListPageContext} from 'modules/brand/context';
import {TAlphabetData, TBrandDataFormatted} from 'modules/brand/types';
import {PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {TProductListView} from 'modules/products/types';
import {BrandTop} from 'new-models';
import {RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';

interface IUseBrandListPageReturn extends TBrandDataFormatted {
    activeItem: string;
    brandListContentRef: RefObject<HTMLDivElement>;
    handleSwitchView: () => void;
    isListView: boolean;
    isScrolledPage: boolean;
    onClickAlphabet: (hash: string) => () => void;
    stickyRef: RefObject<HTMLDivElement>;
    view: TProductListView;
    popularBrandList: BrandTop[];
}

export const useBrandListPage = (): IUseBrandListPageReturn => {
    const stickyRef = useRef<HTMLDivElement>(null);
    const brandListContentRef = useRef<HTMLDivElement>(null);
    const {brandList, popularBrandList} = useContext(BrandListPageContext);
    const [view, setView] = useState<TProductListView>(PRODUCT_LIST_VIEW.list);
    const [activeItem, setActiveItem] = useState<string>('');
    const [isScrolledPage, setIsScrolledPage] = useState(false);
    const hash = useHash();

    const handleSwitchView = useCallback(() => {
        setView((currentView) => {
            return currentView === PRODUCT_LIST_VIEW.list ? PRODUCT_LIST_VIEW.tile : PRODUCT_LIST_VIEW.list;
        });
    }, []);

    const onClickAlphabet = useCallback(
        (currentHash: string) => () => {
            setActiveItem(currentHash);
        },
        []
    );

    useEffect(() => {
        setActiveItem(hash || '');
    }, [hash]);
    const {alphabetData, alphabetLetterList}: TBrandDataFormatted = useMemo(() => {
        const data: TAlphabetData = {};
        const sortedBrandList = [...brandList].sort((a, b) => a.title.localeCompare(b.title, 'en'));

        sortedBrandList.forEach((brand) => {
            const {title} = brand;
            const letter = title.at(0);

            if (!letter) {
                return;
            }

            const array = data[letter];

            if (!array) {
                data[letter] = [brand];

                return;
            }

            array.push(brand);
        });

        return {
            alphabetData: data,
            alphabetLetterList: Object.keys(data),
        };
    }, [brandList]);

    const isListView = view === PRODUCT_LIST_VIEW.list;

    useEffect(() => {
        const handleScroll = debounce(() => {
            const stickyPosition = stickyRef?.current?.getBoundingClientRect().y || 0;
            const stickyHeight = stickyRef?.current?.getBoundingClientRect().height || 0;
            const brandListContentPosition = brandListContentRef?.current?.getBoundingClientRect().y || 0;
            setIsScrolledPage(stickyPosition + stickyHeight > brandListContentPosition);
        }, 10);

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return {
        activeItem,
        alphabetData,
        alphabetLetterList,
        brandListContentRef,
        handleSwitchView,
        isListView,
        isScrolledPage,
        onClickAlphabet,
        popularBrandList,
        stickyRef,
        view,
    };
};
