import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import pencilIcon from 'public/icons/pencil.svg';
import plusIcon from 'public/icons/plus.svg';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    allowEdit?: boolean;
    className?: string;
    title: string;
    content?: string | null;
    onClick: () => void;
}

export const ContactField = memo<IProps>(function ContactField({className, title, content, onClick, allowEdit = true}) {
    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.info}>
                <Typography color="gray50" variant="elements-strong">
                    {title}
                </Typography>
                <Typography className={style.infoContent} color="gray100" variant="p">
                    {content ? content : 'Не добавлен'}
                </Typography>
            </div>
            {content && allowEdit && (
                <Anchor
                    as="button"
                    icon="left"
                    iconNode={<SvgIcon className={style.icon} svg={pencilIcon} />}
                    onClick={onClick}
                    size="large"
                    theme="standalone"
                >
                    Изменить
                </Anchor>
            )}
            {!content && (
                <Anchor
                    as="button"
                    icon="left"
                    iconNode={<SvgIcon className={style.icon} svg={plusIcon} />}
                    onClick={onClick}
                    size="large"
                    theme="standalone"
                >
                    Добавить
                </Anchor>
            )}
        </div>
    );
});
