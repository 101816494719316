import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {useCustomSwiperNavigation} from 'modules/slider/hooks/useCustomSwiperNavigation';
import arrowIcon from 'public/icons/arrow.svg';

import style from './style.module.scss';

const SLIDES_COUNT = 3;

interface IProps {
    prevRef: React.MutableRefObject<HTMLButtonElement | null>;
    nextRef: React.MutableRefObject<HTMLButtonElement | null>;
    className?: string;
    slidesCount?: number;
}

export const CustomButtons: FC<IProps> = ({className, nextRef, prevRef, slidesCount = SLIDES_COUNT}) => {
    const {onNext, onPrev, isNextDisabled, isPrevDisabled} = useCustomSwiperNavigation(slidesCount);

    return (
        <>
            <button
                className={classNames(style.navigation, style.prev, className, 'prev', {
                    ['prev-disabled']: isPrevDisabled,
                })}
                disabled={isPrevDisabled}
                onClick={onPrev}
                ref={prevRef}
            >
                <SvgIcon svg={arrowIcon} />
            </button>

            <button
                className={classNames(style.navigation, style.next, className, 'next', {
                    ['next-disabled']: isNextDisabled,
                })}
                disabled={isNextDisabled}
                onClick={onNext}
                ref={nextRef}
            >
                <SvgIcon svg={arrowIcon} />
            </button>
        </>
    );
};
