import {disableScroll, enableScroll, isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TWith} from 'core/types';
// import {PRODUCT_TYPE} from 'modules/products/constants';
// import {Category, CategoryType} from 'new-models';
import {useSlice} from 'plugins/hooks/useSlice';
import {FilterContext} from 'plugins/modules/filter/context';
import {IFilterState, TFilterActions} from 'plugins/modules/filter/types';
// import {ListingContext} from 'plugins/modules/listing/context';
import {IListingState, TListingActions} from 'plugins/modules/listing/types';
import {useContext, useEffect, useMemo} from 'react';

export const useListType = () => {
    const dispatch = useAppDispatch();

    // const {listType} = useContext(ListingContext);
    // const {filterList, isFilterPanelVisible, appliedChooseFiltersByVariantList, appliedRangeFiltersList} =
    //     useContext(FilterContext);
    const {filterList, isFilterPanelVisible} = useContext(FilterContext);

    const slice = useSlice<TWith<IFilterState, IListingState>, TWith<TFilterActions, TListingActions>>();

    const isFilterDataNotEmpty = useMemo<boolean>(() => {
        return Boolean(filterList.length);
    }, [filterList?.length]);

    // const hasAppliedFilters = useMemo<boolean>(() => {
    //     return Boolean(appliedChooseFiltersByVariantList.length || appliedRangeFiltersList.length);
    // }, [appliedRangeFiltersList.length, appliedChooseFiltersByVariantList.length]);

    // useEffect(() => {
    //     if ('list' === listType && PRODUCT_TYPE.visual === categoryType) {
    //         if (isMobileByScreen()) {
    //             return;
    //         }
    //
    //         dispatch(slice.actions.actionSetIsFilterPanelVisible(isFilterDataNotEmpty));
    //         return;
    //     }
    //
    //     if ('tile' === listType && PRODUCT_TYPE.visual === categoryType) {
    //         dispatch(slice.actions.actionSetIsFilterPanelVisible(hasAppliedFilters && !isMobileByScreen()));
    //         return;
    //     }
    //
    //     if ('visual' !== categoryType) {
    //         if (isMobileByScreen()) {
    //             return;
    //         }
    //
    //         dispatch(slice.actions.actionSetIsFilterPanelVisible(isFilterDataNotEmpty));
    //     }
    //     // [hasAppliedFilters]
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [categoryType, dispatch, isFilterDataNotEmpty, listType, slice.actions]);

    useEffect(() => {
        // if (PRODUCT_TYPE.visual === categoryType) {
        //     dispatch(slice.actions.actionSetListViewType('tile'));
        //     dispatch(slice.actions.actionSetIsFilterPanelVisible(false));
        //     return;
        // }
        //
        // if (PRODUCT_TYPE.simple === categoryType) {
        //     dispatch(slice.actions.actionSetListViewType('tile'));
        //
        //     if (isMobileByScreen()) {
        //         return;
        //     }
        //
        //     dispatch(slice.actions.actionSetIsFilterPanelVisible(isFilterDataNotEmpty));
        //     return;
        // }
        //
        // dispatch(slice.actions.actionSetListViewType('tile'));
        //
        // if (isMobileByScreen()) {
        //     return;
        // }

        dispatch(slice.actions.actionSetIsFilterPanelVisible(isFilterDataNotEmpty && !isMobileByScreen()));
    }, [dispatch, isFilterDataNotEmpty, slice.actions]);

    useEffect(() => {
        if (isFilterPanelVisible && isMobileByScreen()) {
            disableScroll();
            return;
        }

        if (!isFilterPanelVisible) {
            enableScroll();
        }
    }, [isFilterPanelVisible]);
};
