import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProductOfferPrice} from 'models';
import {IPriceAndBonusesState} from 'modules/price-n-bonuses/types';

export const priceAndBonusesModuleName = 'price-n-bonuses';

const initialState: IPriceAndBonusesState = {
    bonuses: null,
};

export const priceAndBonusesSlice = createSlice({
    initialState,
    name: priceAndBonusesModuleName,
    reducers: {
        actionChangeBonuses(
            state: IPriceAndBonusesState,
            {payload}: PayloadAction<Pick<ProductOfferPrice, 'bonus' | 'extraBonus'> | null>
        ) {
            state.bonuses = payload;
        },
    },
});

export const {
    actions: {actionChangeBonuses},
} = priceAndBonusesSlice;

export const {reducer: priceAndBonusesReducer} = priceAndBonusesSlice;
