import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import arrowIcon from 'public/icons/arrow.svg';
import React, {AnchorHTMLAttributes, forwardRef, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

export interface ILinkPublicProps {
    theme?: 'inline' | 'standalone';
    size?: 'extra' | 'large' | 'medium' | 'small' | 'button-l';
    icon?: 'off' | 'left' | 'right';
    element?: 'div' | 'span';
    iconNode?: ReactNode;
    as?: 'a' | 'button';
    isHideTextAdaptive?: boolean;
    disabled?: boolean;
    mode?: 'dark' | 'light';
    onlyIcon?: boolean;
    withBorder?: boolean;
}

export interface IProps
    extends ILinkPublicProps,
        AnchorHTMLAttributes<HTMLAnchorElement>,
        Pick<ITypographyPublicProps, 'variant'> {}

function renderTextTag(linkSize: ILinkPublicProps['size']): ITypographyPublicProps['variant'] {
    switch (linkSize) {
        case 'button-l':
            return 'button-l';

        case 'medium':
            return 'link';

        case 'small':
            return 'p-small';

        case 'extra':
            return 'p-strong';

        case 'large':
        default:
            return 'link';
    }
}

export const Anchor = forwardRef<HTMLAnchorElement, IProps>(function Anchor(
    {
        children,
        iconNode,
        as = 'a',
        theme = 'inline',
        size = 'medium',
        icon = 'off',
        className = '',
        element = 'span',
        isHideTextAdaptive,
        disabled = false,
        mode = 'dark',
        onlyIcon = false,
        variant,
        withBorder = true,
        ...rest
    },
    ref
) {
    const childrenNode = useMemo<ReactNode>(() => {
        return (
            <>
                {'left' === icon &&
                    (iconNode ? (
                        iconNode
                    ) : (
                        <SvgIcon className={classNames(style.icon, style.iconLeft)} svg={arrowIcon} />
                    ))}
                {!onlyIcon && (
                    <Typography
                        className={classNames({[style.isHideTextAdaptive]: isHideTextAdaptive})}
                        element={element}
                        variant={variant ?? renderTextTag(size)}
                    >
                        {children}
                    </Typography>
                )}
                {'right' === icon && (iconNode ? iconNode : <SvgIcon className={style.icon} svg={arrowIcon} />)}
            </>
        );
    }, [icon, iconNode, onlyIcon, isHideTextAdaptive, element, variant, size, children]);

    return React.createElement(
        as,
        {
            ...rest,
            className: classNames(
                style.link,
                style[mode],
                className,
                {
                    [style[theme]]: Boolean(theme),
                    [style.disabled]: disabled,
                    [style.onlyIcon]: onlyIcon,
                    [style.withBorder]: withBorder,
                },
                'anchor-component'
            ),
            ref,
            tabIndex: 0,
        },
        childrenNode
    );
});
