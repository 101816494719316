import {Button, BUTTON_SIZE, BUTTON_THEMES} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {isMobileByScreen} from 'core/helpers';
import {CHECKED_LIST_CONDITIONS, REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {useCartModalActions} from 'modules/cart/hooks/useCartModalActions';
import {TCheckedListCondition} from 'modules/cart/types';
import deleteIcon from 'public/icons/delete-20.svg';
import React, {memo} from 'react';

interface ICartProductsDeleteButtonProps {
    className?: string;
    checkedListCondition: TCheckedListCondition;
}

export const CartProductsDeleteButton = memo<ICartProductsDeleteButtonProps>(function ({
    checkedListCondition,
    className,
}) {
    const {openCartRemoveModal} = useCartModalActions();

    return (
        <Button
            className={className}
            data-skeleton-item
            isHideTextAdaptive
            isWithoutPaddings
            onClick={openCartRemoveModal(
                checkedListCondition === CHECKED_LIST_CONDITIONS.NONE
                    ? REMOVE_MODAL_VIEW.DEFAULT
                    : REMOVE_MODAL_VIEW.ASSIGNED
            )}
            rightIcon={<SvgIcon svg={deleteIcon} />}
            size={BUTTON_SIZE.large}
            theme={isMobileByScreen() ? BUTTON_THEMES.default : BUTTON_THEMES.ghost}
        >
            {checkedListCondition === CHECKED_LIST_CONDITIONS.NONE ? 'Очистить корзину' : 'Удалить выбранные'}
        </Button>
    );
});
