import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IOrdersStateOrigin, TOrdersState} from 'modules/orders/types';
import {OrderDetail} from 'new-models';
import {createListingActions} from 'plugins/modules/listing/actions';
import {withListing} from 'plugins/modules/listing/state';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {withPagination} from 'plugins/modules/pagination/state';

export const ordersModuleName = 'orders';

const initialState: TOrdersState = withListing(
    withPagination<IOrdersStateOrigin>({
        currentCancelOrderId: null,
        currentShelfLifeOrderId: null,
        detailedOrders: {},
    })
);

export const ordersSlice = createSlice({
    initialState,
    name: ordersModuleName,
    reducers: {
        ...createListingActions<TOrdersState>(),
        ...createPaginationActions<TOrdersState>(),

        actionSetCurrentCancelOrderId(state, {payload}: PayloadAction<TOrdersState['currentCancelOrderId']>) {
            state.currentCancelOrderId = payload;
        },
        actionSetCurrentShelfLifeOrderId(state, {payload}: PayloadAction<TOrdersState['currentShelfLifeOrderId']>) {
            state.currentShelfLifeOrderId = payload;
        },
        actionSetDetailedOrders(state, {payload}: PayloadAction<OrderDetail>) {
            state.detailedOrders[payload.id] = payload;
        },
    },
});

export const {
    actions: {
        actionSetPage,
        actionSetPageCount,
        actionSetPageSize,
        actionSetList,
        actionSetDetailedOrders,
        actionSetCurrentCancelOrderId,
        actionSetCurrentShelfLifeOrderId,
    },
} = ordersSlice;

export const {reducer: ordersReducer} = ordersSlice;
