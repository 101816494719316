import {MediaTypeEnum, MediaVideo, MediaYoutube, ProductDetail} from 'new-models';
import {useMemo} from 'react';

interface IUseGetVideoList {
    allVideos: MediaVideo[] | MediaYoutube[];
    videoList: MediaVideo[];
    youtubeList: MediaYoutube[];
}

export const useGetVideoList = (product?: ProductDetail): IUseGetVideoList => {
    return useMemo(() => {
        if (!product?.media) {
            return {
                allVideos: [],
                videoList: [],
                youtubeList: [],
            };
        }

        const video = product.media.filter(({type}) => type === MediaTypeEnum.Video) as MediaVideo[];
        const youtube = product.media.filter(({type}) => type === MediaTypeEnum.Youtube) as MediaYoutube[];

        return {
            allVideos: [...video, ...youtube],
            videoList: video,
            youtubeList: youtube,
        };
    }, [product]);
};
