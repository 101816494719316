import {RESPONSE_STATUS_CODE} from 'core/constants';
import {Layout} from 'layouts/BasicLayout';
import {Error404} from 'modules/errors/components/Error404';

interface IErrorLayout {
    statusCode?: number;
}

export const ErrorLayout: FC<IErrorLayout> = ({children, statusCode}) => {
    if (RESPONSE_STATUS_CODE.NOT_FOUND === statusCode) {
        return (
            <Layout>
                <Error404 />
            </Layout>
        );
    }

    return <>{children}</>;
};
