import {Button} from 'components/Button';
import {useDemoAlert} from 'core/hooks/useDemoAlert';
import React, {memo} from 'react';

import style from './style.module.scss';

export const ShouldPayAlert = memo(function ShouldPayAlert() {
    const {handleShowDemoAlert} = useDemoAlert();

    return (
        <div className={style.root}>
            <Button isStretched onClick={handleShowDemoAlert} size="medium" theme="secondary">
                Изменить способ оплаты
            </Button>
            <Button isStretched onClick={handleShowDemoAlert} size="medium">
                Оплатить
            </Button>
        </div>
    );
});
