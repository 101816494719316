import {MediaVideo} from 'new-models';
import React, {forwardRef, HTMLAttributes, memo} from 'react';

import style from './style.module.scss';

export interface IVideoProps extends HTMLAttributes<HTMLVideoElement> {
    media: MediaVideo;
}

export const Video = memo(
    forwardRef<HTMLVideoElement, IVideoProps>(function Video({media, ...rest}, ref) {
        const {preview, title, url} = media;

        return (
            <video
                className={style.video}
                controls
                loop
                muted
                playsInline
                poster={preview?.url}
                ref={ref}
                src={url}
                {...rest}
            >
                {title}
            </video>
        );
    })
);
