import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TProductsListResponse} from 'modules/products/models/ProductsListResponse';
import {selectList, selectPaginationType} from 'modules/products/selectors';
import {
    actionSetBestsellersList,
    actionSetCategoryId,
    actionSetComboFilterList,
    actionSetFilterList,
    actionSetList,
    actionSetNewList,
    actionSetPage,
    actionSetPageCount,
    actionSetPageSize,
    actionSetPopularList,
    actionSetRecommendedProductList,
    actionSetSimilarProductList,
    actionSetSortingList,
    actionSetViewedProductList,
} from 'modules/products/slice';
import {TSelection} from 'modules/products/types';
import {Category, ProductGroupEnum, ProductShort} from 'new-models';
import {makeListingLoadingId} from 'plugins/modules/listing/helpers';
import {PAGINATION_TYPE} from 'plugins/modules/pagination/constants';
import {getPageFromQuery, getPageSizeFromQuery} from 'plugins/modules/pagination/helpers';

import {TProductGroupsResponse} from '../models/ProductGroupsResponse';

export const getProductList =
    (searchQuery: URLSearchParams, categoryId?: Category['id']): TAppThunk<TProductsListResponse['data']> =>
    async (dispatch, getState, {productsService}) => {
        const paginationType = selectPaginationType(getState());

        const page = getPageFromQuery(searchQuery);
        const limit = getPageSizeFromQuery(searchQuery);

        if (categoryId) {
            searchQuery.set('filter[categoryId]', categoryId);
        }

        const data = await dispatch(
            requestAction<TProductsListResponse>({
                requestCb: () =>
                    productsService.getProductList(searchQuery, {
                        limit,
                        page,
                    }),
                type: makeListingLoadingId(actionSetList.type, paginationType),
            })
        );

        const productList =
            paginationType === PAGINATION_TYPE.ACCUMULATIVE
                ? [...selectList<ProductShort>(getState()), ...data.products]
                : data.products;

        dispatch(actionSetPage(data.page.page));
        dispatch(actionSetPageSize(data.page.limit));
        dispatch(actionSetPageCount(data.page.count));

        dispatch(actionSetFilterList(data.filters));
        dispatch(actionSetComboFilterList(data.tags ?? []));

        if (categoryId) {
            dispatch(actionSetCategoryId(categoryId));
        }

        dispatch(actionSetList(productList || []));
        dispatch(actionSetSortingList(data.sorts));

        return data;
    };

export const getProductGroupsList =
    (code: ProductGroupEnum, productId?: string): TAppThunk<void> =>
    async (dispatch, _, {productsService}) => {
        const searchParams = new URLSearchParams();

        searchParams.set('code', code);

        if (productId) {
            searchParams.set('productId', productId);
        }

        const data = await dispatch(
            requestAction<TProductGroupsResponse>({
                requestCb: () =>
                    productsService.getProductGroupsList(searchParams, {
                        limit: 50,
                        page: 1,
                    }),
            })
        );

        const currentProducts = (data?.groups || [])[0]?.products ?? [];

        switch (code) {
            case ProductGroupEnum.Viewed:
            default:
                dispatch(actionSetViewedProductList(currentProducts));
                break;
            case ProductGroupEnum.Recommended:
                dispatch(actionSetRecommendedProductList(currentProducts));
                break;
            case ProductGroupEnum.Similar:
                dispatch(actionSetSimilarProductList(currentProducts));
        }
    };

export const getSelectionList: (selection: TSelection) => TAppThunk<void> =
    (selection) =>
    async (dispatch, _, {productsService}) => {
        const searchParams = new URLSearchParams();
        searchParams.set('filter[nameplates]', selection);

        const data = await dispatch(
            requestAction<TProductsListResponse>({
                requestCb: () =>
                    productsService.getProductList(searchParams, {
                        limit: 50,
                        page: 1,
                    }),
            })
        );

        switch (selection) {
            case 'bestseller':
            default:
                dispatch(actionSetBestsellersList(data.products));
                break;
            case 'new':
                dispatch(actionSetNewList(data.products));
                break;
            case 'popular':
                dispatch(actionSetPopularList(data.products));
        }
    };
