import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IPromo, TPromoState} from 'modules/promo/types';
import {HYDRATE} from 'next-redux-wrapper';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {withPagination} from 'plugins/modules/pagination/state';

export const promoModuleName = 'promo';

const initialState: TPromoState = withPagination<IPromo>({
    popularPromoList: [],
    promoData: {
        code: '',
        id: '',
        title: '',
    },
    promoList: [],
});

export const promoSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[promoModuleName];
        },
    },
    initialState,
    name: promoModuleName,
    reducers: {
        actionSetPopularPromoList(state: TPromoState, {payload}: PayloadAction<IPromo['popularPromoList']>) {
            state.popularPromoList = payload;
        },
        actionSetPromoData(state: TPromoState, {payload}: PayloadAction<IPromo['promoData']>) {
            state.promoData = payload;
        },
        actionSetPromoList(state: TPromoState, {payload}: PayloadAction<IPromo['promoList']>) {
            state.promoList = payload;
        },
        ...createPaginationActions<TPromoState>(),
    },
});

export const {
    actions: {
        actionSetPage,
        actionSetPageCount,
        actionSetPageSize,
        actionSetPromoData,
        actionSetPopularPromoList,
        actionSetPromoList,
    },
} = promoSlice;

export const {reducer: promoReducer} = promoSlice;
