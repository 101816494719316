import {formatPriceToDefaultDivideDenominator} from 'core/helpers';
import {OrderDetail} from 'new-models';
import {useMemo} from 'react';

interface IUseOrderXTotalPrice {
    cost: string;
    deliveryCost: string;
    discount: string | null;
    totalCost: string;
}

export const useOrderXTotalPrice = (total: OrderDetail['total']): IUseOrderXTotalPrice => {
    const {cost, deliveryCost, discount, totalCost, currency} = total;

    return useMemo(() => {
        const optionsFormatPrice: Intl.NumberFormatOptions = {...(currency ? {currency} : {})};

        return {
            cost: formatPriceToDefaultDivideDenominator(cost, optionsFormatPrice),
            deliveryCost: deliveryCost
                ? formatPriceToDefaultDivideDenominator(deliveryCost, optionsFormatPrice)
                : 'бесплатно',
            discount: discount ? formatPriceToDefaultDivideDenominator(discount, optionsFormatPrice) : null,
            totalCost: formatPriceToDefaultDivideDenominator(totalCost, optionsFormatPrice),
        };
    }, [cost, currency, deliveryCost, discount, totalCost]);
};
