export interface IGetFormattedCoordinatesProps {
    latitude?: string | number;
    longitude?: string | number;
}

export type TGetFormattedCoordinates = [number, number];

export const getFormattedCoordinates = (data: IGetFormattedCoordinatesProps = {}): TGetFormattedCoordinates => {
    const {latitude, longitude} = data;

    return [Number(latitude), Number(longitude)];
};

interface IGetPlacemarkProperties {
    meta: {
        customId: string;
    };
}

export const getPlacemarkProperties = (id: string | number): IGetPlacemarkProperties => {
    return {
        meta: {
            customId: String(id),
        },
    };
};
