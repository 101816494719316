import {IProps as IPropsTypography, Typography} from 'components/Typography';
import React, {FC} from 'react';

type TProps = Pick<IPropsTypography, 'onlyResponsiveVariant'>;
export const ContactUsPhoneLink: FC<TProps> = ({onlyResponsiveVariant}) => {
    return (
        <Typography onlyResponsiveVariant={onlyResponsiveVariant} variant="p-bold-strong">
            <a href="tel:+78006001457">+7 (800) 600 1457</a>
        </Typography>
    );
};
