import classNames from 'classnames';
import {IInputCaptionPublicProps, InputCaption} from 'components/InputCaption';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {ChangeEventHandler, forwardRef, TextareaHTMLAttributes, useCallback, useState} from 'react';

import style from './style.module.scss';

export interface IProps
    extends TPropsWithClassName,
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        IInputCaptionPublicProps {
    disabled?: boolean;
    labelText?: string;
    placeholder?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, IProps>(function TextArea(
    {className, errorText, captionText, disabled, onChange, labelText, ...restTextAreaAttributes},
    ref
) {
    const [isFilled, setIsFilled] = useState<boolean>(Boolean(restTextAreaAttributes.value));

    const handleTextAreaChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
        (event) => {
            const target = event.target;
            setIsFilled(Boolean(target.value));

            if (onChange) {
                onChange(event);
            }
        },
        [onChange]
    );

    return (
        <label className={classNames(style.textArea, className)} data-skeleton-item>
            <textarea
                {...restTextAreaAttributes}
                className={classNames(style.textAreaOriginal, {
                    [style.textAreaOriginalFilled]: isFilled,
                    [style.textAreaOriginalNonLabel]: !labelText,
                })}
                disabled={disabled}
                onChange={handleTextAreaChange}
                ref={ref}
            />
            <div
                className={classNames(style.textAreaFrame, 'textarea-frame', {
                    [style.textAreaFrameError]: errorText,
                    [style.textAreaFrameDisabled]: disabled,
                })}
            />
            {labelText && (
                <Typography className={style.textAreaLabel} color="gray60" variant="p">
                    {labelText}
                </Typography>
            )}
            <InputCaption captionText={captionText} disabled={disabled} errorText={errorText} />
        </label>
    );
});
