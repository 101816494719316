import classNames from 'classnames';
import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Typography} from 'components/Typography';
import {EMAIL_SUPPORT} from 'core/constants';
import {HOME_ROUTE} from 'routing/constants';

import style from './style.module.scss';

const helperColumnStyle = getGridColumnStyles({md: 1});
const imageColumnStyle = getGridColumnStyles({md: 3});
const contentColumnStyle = getGridColumnStyles({md: 7});

export const Error503 = () => {
    return (
        <div className={style.root}>
            <Grid noRowGap>
                <div className={classNames(style.helper, helperColumnStyle)}></div>
                <picture className={classNames(style.picture, imageColumnStyle)}>
                    <img alt="Error 503" className={style.image} src="/images/503.svg" />
                </picture>
                <div className={classNames(style.content, contentColumnStyle)}>
                    <Typography className={style.title} color="gray100" variant="h3">
                        Страница недоступна
                    </Typography>
                    <Typography color="gray80" variant="p">
                        На сервере произошла ошибка. Мы уже работаем над её устранением. Попробуйте перейти на главную
                        страницу сайта и повторить попытку позже.
                    </Typography>
                    <div className={style.buttonsContainer}>
                        <Button className={style.button} href={HOME_ROUTE}>
                            Вернуться на главную
                        </Button>
                        <Button className={style.button} href={`mailto:${EMAIL_SUPPORT}`} theme="secondary">
                            Написать в службу поддержки
                        </Button>
                    </div>
                </div>
            </Grid>
        </div>
    );
};
