import classNames from 'classnames';
import {IInputCaptionPublicProps, InputCaption} from 'components/InputCaption';
import {Loader, LOADER_MODES} from 'components/Loader';
import {Typography} from 'components/Typography';
import React, {
    ChangeEventHandler,
    forwardRef,
    HTMLAttributes,
    InputHTMLAttributes,
    KeyboardEventHandler,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    Ref,
    useCallback,
    useEffect,
    useId,
    useMemo,
    useState,
} from 'react';
import CurrencyInput from 'react-currency-input-field';
import {CurrencyInputProps, IntlConfig} from 'react-currency-input-field/src/components/CurrencyInputProps';

import style from './style.module.scss';

type TInputValue = InputHTMLAttributes<HTMLInputElement>['value'];

export interface IProps<V extends TInputValue = string>
    extends InputHTMLAttributes<HTMLInputElement>,
        IInputCaptionPublicProps {
    labelText?: string;
    name?: string;
    value?: V;
    disabled?: boolean;
    placeholder?: string;
    trailIcon?: ReactNode;
    trailIconClassname?: string;
    inputClassname?: string;
    onExtraActionClick?: MouseEventHandler;
    type?: InputHTMLAttributes<HTMLInputElement>['type'] | 'currency';
    isNeedCaption?: boolean;
    notFadingPlaceholder?: string;
    onValueChange?: CurrencyInputProps['onValueChange'];
    currency?: string;
    onlyNumbers?: boolean;
    isLoading?: boolean;
    isBorder?: boolean;
}

export type TOnValueChange = Required<CurrencyInputProps>['onValueChange'];

const CURRENCY_DEFAULT_CONFIG: IntlConfig = {currency: 'RUB', locale: 'ru-RU'};

const allowOnlyNumbers: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (['KeyA', 'KeyY', 'KeyZ'].includes(e.code) && e.ctrlKey) {
        return;
    }
    if (!/^Digit\d+|^Numpad\d+|Backspace|ArrowRight|ArrowLeft|Tab|Delete|Enter/u.test(e.code)) {
        e.preventDefault();
    }
};

const InputOriginal = <V extends TInputValue>(
    {
        className,
        defaultValue: _defaultValue,
        inputClassname,
        captionText,
        step,
        errorText,
        successText,
        labelText,
        onChange,
        value,
        type = 'text',
        trailIcon,
        trailIconClassname,
        onExtraActionClick,
        isLoading = false,
        isNeedCaption = true,
        notFadingPlaceholder,
        onValueChange,
        currency,
        onlyNumbers,
        isBorder = false,
        ...restInputAttributes
    }: IProps<V>,
    ref: Ref<HTMLInputElement>
) => {
    const uid = useId();
    const [isInputFilled, setIsInputFilled] = useState<boolean>(Boolean(value?.toString()));

    useEffect(() => {
        setIsInputFilled(Boolean(value?.toString()));
    }, [value]);

    const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setIsInputFilled(Boolean(event.target.value));
            onChange?.(event);
        },
        [onChange]
    );

    const handleInputValueChange = useCallback<TOnValueChange>(
        (val) => {
            setIsInputFilled(Boolean(val?.length));
            onValueChange?.(val);
        },
        [onValueChange]
    );

    const renderPlaceholder = useMemo<ReactNode>(() => {
        if (!notFadingPlaceholder) {
            return '';
        }
        return (
            <>
                <Typography className={style.notFadingPlaceholderTransparent} variant="p">
                    {value}
                </Typography>
                <Typography color="gray50" variant="p">
                    {notFadingPlaceholder.slice(String(value).length)}
                </Typography>
            </>
        );
    }, [notFadingPlaceholder, value]);

    const inputAttributes = useMemo<HTMLAttributes<HTMLInputElement>>(
        () => ({
            ...restInputAttributes,
            className: classNames(
                style.inputOriginal,
                inputClassname,
                {
                    [style.inputOriginalFilled]: isInputFilled,
                    [style.inputOriginalNoLabel]: !labelText,
                    [style.inputOriginalHasExtra]: trailIcon,
                    [style.transparent]: Boolean(notFadingPlaceholder),
                },
                'input-original'
            ),
            id: uid,
            inputMode: onlyNumbers ? 'numeric' : undefined,
            onKeyDown: onlyNumbers ? allowOnlyNumbers : undefined,
            ref,
            step,
            value,
        }),
        [
            inputClassname,
            isInputFilled,
            labelText,
            notFadingPlaceholder,
            ref,
            restInputAttributes,
            step,
            trailIcon,
            uid,
            value,
            onlyNumbers,
        ]
    );

    const renderInput = useMemo(() => {
        switch (type) {
            case 'currency': {
                return (
                    <CurrencyInput
                        {...(inputAttributes as CurrencyInputProps)}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        intlConfig={currency ? {...CURRENCY_DEFAULT_CONFIG, currency} : CURRENCY_DEFAULT_CONFIG}
                        onValueChange={handleInputValueChange}
                        step={Number(step) || 1}
                    />
                );
            }
            default:
                return <input {...inputAttributes} onChange={handleInputChange} type={type} />;
        }
    }, [currency, handleInputChange, handleInputValueChange, inputAttributes, step, type]);

    return (
        <div className={className} data-skeleton-item>
            <label
                className={classNames(style.input, {[style.isDisabled]: restInputAttributes.disabled})}
                htmlFor={uid}
            >
                {renderInput}

                <div
                    className={classNames(style.inputFrame, 'input-frame', {
                        [style.inputFrameError]: errorText,
                        [style.isBorder]: isBorder,
                    })}
                />
                {notFadingPlaceholder && (
                    <label className={style.notFadingPlaceholder} htmlFor={uid}>
                        {renderPlaceholder}
                    </label>
                )}
                {labelText && (
                    <label
                        className={classNames(style.inputLabel, 'input-label', {[style.inputLabelHasExtra]: trailIcon})}
                        htmlFor={uid}
                    >
                        {labelText}
                    </label>
                )}
                {trailIcon && (
                    <button
                        className={classNames(style.inputExtraAction, trailIconClassname)}
                        onClick={onExtraActionClick}
                        type="button"
                    >
                        {trailIcon}
                    </button>
                )}
                {isLoading && <Loader className={style.inputLoader} mode={LOADER_MODES.compact} />}
            </label>
            {isNeedCaption && (captionText || errorText || successText) && (
                <InputCaption
                    captionText={captionText}
                    disabled={restInputAttributes.disabled}
                    errorText={errorText}
                    successText={successText}
                />
            )}
        </div>
    );
};

export const Input = forwardRef(InputOriginal) as <V extends TInputValue>(
    props: IProps<V> & {ref?: Ref<HTMLInputElement>}
) => ReactElement;
