import {MODALS} from 'modules/modals/constants';
import {useModalApi} from 'modules/modals/hooks/useModalApi';

export const useCheckoutMoreDetailsPickupPointModalApi = () => {
    const {open, close} = useModalApi();

    return {
        close: () => close(MODALS.CHECKOUT_MORE_DETAILS_SHOP.name),
        open: () => open(MODALS.CHECKOUT_MORE_DETAILS_SHOP.name),
    };
};
