import {AbstractApiResponseInterceptor} from 'core/abstract/AbstractApiResponseInterceptor';
import {isBrowserContext} from 'core/helpers';
import {IHttpClientResponse, TJson, TRequestOption, TRequestParameters} from 'core/types';

export class LoggerResponseInterceptor extends AbstractApiResponseInterceptor<
    TRequestParameters<TRequestOption, object>
> {
    public override async intercept(
        response: IHttpClientResponse<TJson, Headers, Response>,
        requestOption?: TRequestParameters<TRequestOption, object>
    ): Promise<typeof response> {
        if (isBrowserContext()) {
            return response;
        }

        // eslint-disable-next-line no-console
        console.groupCollapsed(`[SSR]: ${response.raw?.url}, status: [${response.status}, ${response.raw.statusText}]`);
        // eslint-disable-next-line no-console
        console.log({
            RAW: response.raw,
            REQUEST_OPTION: requestOption,
            RESPONSE_DATA: response.data,
            RESPONSE_HEADERS: response.headers,
        });
        // eslint-disable-next-line no-console
        console.groupEnd();

        return response;
    }

    public static instance: LoggerResponseInterceptor;

    public static getInstance(): LoggerResponseInterceptor {
        if (!LoggerResponseInterceptor.instance) {
            LoggerResponseInterceptor.instance = new LoggerResponseInterceptor();
        }

        return LoggerResponseInterceptor.instance;
    }
}
