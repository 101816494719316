import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {ContentContext} from 'modules/content/context';
import {ContentPage} from 'modules/content/pages/ContentPage';
import {CONTENT_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const ContentPageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.main);

    return (
        <ContentContext.Provider value={CONTENT_PAGE_SKELETON_DATA}>
            <ContentPage />
        </ContentContext.Provider>
    );
};
