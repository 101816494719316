import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {getProductPluralText} from 'core/helpers/plural';
import React from 'react';

import style from './style.module.scss';

interface IProps {
    productCount: number;
    weightValue?: number;
    volumeValue?: number;
    productSum: number;
}

export const TotalOptions: FC<IProps> = ({productCount, weightValue, volumeValue, productSum}) => (
    <ul className={style.root}>
        <li className={style.optionsItem}>
            <Typography variant="p">
                {productCount} {getProductPluralText(productCount)}
            </Typography>
            <Typography variant="p">
                {formatPrice((productSum / PRICE_DENOMINATOR).toString(), {
                    maximumFractionDigits: 2,
                })}
                <span className={style.totalPriceCurrency}>₽</span>
            </Typography>
        </li>
        {Boolean(weightValue && volumeValue) && (
            <li className={style.optionsItem}>
                <Typography variant="p">Вес/объем</Typography>
                <Typography variant="p">
                    {weightValue} кг/{volumeValue} м³
                </Typography>
            </li>
        )}
    </ul>
);
