import {OrderXPageDeliveryInfo} from 'modules/orders/components/OrderXPageDeliveryInfo';
import {OrderXPageProductList} from 'modules/orders/components/OrderXPageProductList';
import {OrderXPageTotalPrice} from 'modules/orders/components/OrderXPageTotalPrice';
import {OrderDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends OrderDetail {
    isHideActions?: boolean;
}

// const getTitle = (type: DeliveryType) => {
//     switch (type) {
//         case DeliveryType.COURIER:
//         case DeliveryType.POST:
//         case DeliveryType.POSTMATE:
//             return 'Отправление';
//         default:
//             return 'Заказ';
//     }
// };

export const OrderXPageDetailsInfo = memo<IProps>(function OrderXPageDetailsInfo(orderData) {
    const {deliveryMethod, products, quantity, total} = orderData;
    return (
        <div className={style.root}>
            {/* <div className={style.header}>
                <Typography element="h4" variant="h4">
                    {getTitle(deliveryMethod.type)}
                </Typography>
                <div>
                    <OrderItemStatus status={orderStatus} />
                </div>
            </div> */}

            <OrderXPageDeliveryInfo {...orderData} />

            <OrderXPageProductList className={style.productList} products={products} />

            <OrderXPageTotalPrice deliveryType={deliveryMethod.type} quantity={quantity} total={total} />

            {/* {!isHideActions && (
                <>
                    <HrElement className={style.lastHr} />

                    <OrderXPageActions {...orderData} />
                </>
            )} */}
        </div>
    );
});
