import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {SegmentedControl} from 'components/SegmentedControl';
import {Typography} from 'components/Typography';
import {ShopCardList} from 'modules/shops/components/ShopCardList';
import {DEFAULT_SELECTED_TAB_ID, LIST_TAB, MAP_TAB, TABS_LIST} from 'modules/shops/constants';
import {ShopListPageContext} from 'modules/shops/context';
import {useShopsMarkersMapList} from 'modules/shops/hooks/useShopsMarkersMapList';
import {YandexMap} from 'modules/yandex-map/components/YandexMap';
import {YandexMapClusterer} from 'modules/yandex-map/components/YandexMapClusterer';
import {DEFAULT_CLUSTER_OPTIONS, YANDEX_MAP_ZOOM_CLICKED} from 'modules/yandex-map/constants';
import {getFormattedCoordinates} from 'modules/yandex-map/helpers';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {StoreShort} from 'new-models';
import React, {FC, useCallback, useContext, useState} from 'react';

import style from './style.module.scss';

const mapColumnStyle = getGridColumnStyles({
    md: 8,
});

const shopListColumnStyle = getGridColumnStyles({
    md: 4,
});

export interface IActiveShop extends StoreShort {
    isClickedPlaceMark?: boolean;
}

export const ShopListPage: FC = () => {
    const {shopsData} = useContext(ShopListPageContext);
    const [tabId, setTabId] = useState(DEFAULT_SELECTED_TAB_ID);
    const [yandexMapApi, setYandexMapApi] = useState<YandexMapService>();

    const {placeMarkList, mapCenter, setActiveShop, activeShop} = useShopsMarkersMapList(shopsData.stores);

    const handleChange = useCallback(() => {
        setTabId((_tabId) => (_tabId === MAP_TAB.id ? LIST_TAB.id : MAP_TAB.id));
    }, []);

    const handleMapReady = useCallback((yandexApi: YandexMapService) => {
        setYandexMapApi(yandexApi);
    }, []);

    const handleClickShopCard = useCallback(
        (data: StoreShort) => {
            setActiveShop(data);
            yandexMapApi?.map?.setCenter(getFormattedCoordinates(data), YANDEX_MAP_ZOOM_CLICKED);
        },
        [setActiveShop, yandexMapApi?.map]
    );

    return (
        <>
            <PageHeaderTitle className={style.container}>
                <Typography color="gray100" data-skeleton-item element="h1" variant="h1">
                    Магазины
                </Typography>
            </PageHeaderTitle>
            <div className={classNames(style.container, style.areaContent)}>
                <SegmentedControl
                    className={style.controls}
                    defaultCheckedId={DEFAULT_SELECTED_TAB_ID}
                    onChange={handleChange}
                    valueList={TABS_LIST}
                />
                <Grid className={classNames(style.contentBlock, tabId === MAP_TAB.id ? style.mapView : null)}>
                    <div className={classNames(mapColumnStyle, style.mapBlock)} data-skeleton-item>
                        <YandexMap center={mapCenter} onMapReady={handleMapReady}>
                            <YandexMapClusterer options={DEFAULT_CLUSTER_OPTIONS}>{placeMarkList}</YandexMapClusterer>
                        </YandexMap>
                    </div>
                    <div className={classNames(shopListColumnStyle, style.shopListBlock)} data-skeleton-item>
                        <ShopCardList
                            activeShop={activeShop}
                            activeTabId={tabId}
                            onClick={handleClickShopCard}
                            stores={shopsData.stores}
                        />
                    </div>
                </Grid>
            </div>
        </>
    );
};
