import classNames from 'classnames';
import {LogoLoader} from 'components/Loader';
import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {TPropsWithClassName} from 'core/types';
import {IProps as ISliderProps, Slider} from 'modules/slider/components/Slider';
import {
    SLIDER_SPEED_AUTO,
    SLIDER_SPEED_SLOW,
    SLIDERS_PER_GROUP,
    SLIDERS_PER_VIEW,
    SPACE_BETWEEN,
} from 'modules/slider/constants';
import React, {FC, useMemo, useState} from 'react';

import style from './style.module.scss';

export interface IProps extends ISliderProps, TPropsWithClassName {
    isFullPageWidth?: boolean;
}

const DEFAULT_BREAKPOINT_SLIDER: ISliderProps['breakpoints'] = {
    [0]: {
        slidesPerGroup: SLIDERS_PER_GROUP.one,
        slidesPerView: SLIDERS_PER_VIEW.auto,
        spaceBetween: SPACE_BETWEEN.cards,
        speed: SLIDER_SPEED_AUTO,
    },
    [MIN_DESKTOP_WIDTH]: {
        slidesPerGroup: SLIDERS_PER_GROUP.six,
        slidesPerView: SLIDERS_PER_VIEW.auto,
        spaceBetween: SPACE_BETWEEN.default,
        speed: SLIDER_SPEED_SLOW,
    },
};

export const PreviewCardsSlider: FC<IProps> = ({
    className,
    children,
    isNavigation = true,
    isFullPageWidth = true,
    ...sliderProps
}) => {
    const [isSliderInitialized, setIsSliderInitialized] = useState<boolean>(false);

    const isUseDefaultBreakpoint = useMemo(() => {
        const keys = Object.keys(sliderProps);
        if (!keys.length) {
            return true;
        }

        return !keys.some((item) => item in DEFAULT_BREAKPOINT_SLIDER[0]);
    }, [sliderProps]);

    return (
        <div className={classNames(style.previewCardsSlider, className)}>
            {!isSliderInitialized && <LogoLoader className={style.previewCardsSliderPreloader} />}
            {isFullPageWidth && <div className={style.previewCardsSliderLeftSpacer} />}
            <Slider
                breakpoints={isUseDefaultBreakpoint ? DEFAULT_BREAKPOINT_SLIDER : undefined}
                className={classNames(style.slider, {[style.fullPageWidth]: isFullPageWidth})}
                isNavigation={isNavigation}
                onInitialized={() => setIsSliderInitialized(true)}
                {...sliderProps}
            >
                {children}
            </Slider>
            {isFullPageWidth && <div className={style.previewCardsSliderRightSpacer} />}
        </div>
    );
};
