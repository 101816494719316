import {BaseUrlParam} from 'core/base/BaseUrlParam';
import {BaseUrlService} from 'core/base/BaseUrlService';
import {Sorting} from 'new-models';

export class SortUrlService extends BaseUrlService {
    public static readonly COMMON_PARAM_NAMES = {
        SORT: new BaseUrlParam('sortBy', false),
    };

    constructor(
        query: BaseUrlService['query'],
        onParamsChange: BaseUrlService['onParamsChange'],
        options: BaseUrlService['options']
    ) {
        super(query, onParamsChange, options);

        this.fill(Object.values(SortUrlService.COMMON_PARAM_NAMES), 'include');
        this.format();
    }

    public async setSortParam(sortOrder: Sorting['direction']) {
        await this.addParam({id: SortUrlService.COMMON_PARAM_NAMES.SORT.toString(), value: sortOrder});
    }
}
