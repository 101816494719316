import {IProps as IMediaHarvesterProps, MediaHarvester} from 'components/MediaHarvester';
import {IProps as INoImageProps, NoImage} from 'components/NoImage';
import {MediaTypeEnum} from 'new-models';
import React, {FC, memo, NamedExoticComponent} from 'react';

export interface IProps extends Omit<IMediaHarvesterProps, 'media'>, Pick<INoImageProps, 'size'> {
    mediaType?: MediaTypeEnum;
    NoImageComponent?: NamedExoticComponent | FC;
    media?: IMediaHarvesterProps['media'][];
}

export const MediaWithImageFallback = memo<IProps>(function MediaWithImageFallback({
    media,
    mediaType,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NoImageComponent = NoImage,
    size,
    ...mediaHarvesterProps
}) {
    if (!media || !media.length) {
        return <NoImageComponent size={size} />;
    }

    const foundImage = media.find((item) => {
        if (!item) {
            return false;
        }

        return mediaType === item.type;
    });

    if (!foundImage) {
        return <NoImageComponent size={size} />;
    }

    return <MediaHarvester media={foundImage} {...mediaHarvesterProps} />;
});
