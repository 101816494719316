import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {IProps as IHeaderMenuProps} from 'modules/user/components/HeaderUserMenu';
import {LOGOUT_ITEM, PROFILE_USER_MENU_LIST} from 'modules/user/constants';
import {useMenuList} from 'modules/user/hooks/useMenuList';
import Link from 'next/link';
import React, {FC, MouseEventHandler, useCallback, useId} from 'react';

import style from './style.module.scss';

interface IProps extends IHeaderMenuProps, TPropsWithClassName {}

export const ProfileUserMenu: FC<IProps> = ({className}) => {
    const uid = useId();
    const {getMenuItemHref, checkForActive} = useMenuList();

    const handleMenuClick = useCallback<MouseEventHandler<HTMLUListElement>>((event) => {
        event.stopPropagation();
    }, []);

    return (
        <ul className={classNames(style.root, style.profileMenu, className)} onClick={handleMenuClick}>
            {PROFILE_USER_MENU_LIST.map(({text, url, icon}, index) => {
                const key = `${text}:${index}:${uid}`;

                const item = (
                    <li data-skeleton-item key={key}>
                        <Link href={url} key={key} passHref shallow>
                            <a
                                className={classNames(style.profileMenuItem, style.item, {
                                    [style.currentActive]: checkForActive(url || ''),
                                })}
                            >
                                <SvgIcon svg={icon} />
                                <Typography color="current-color" variant="h7">
                                    {text}
                                </Typography>
                            </a>
                        </Link>
                    </li>
                );

                return item;
            })}
            <li data-skeleton-item key={LOGOUT_ITEM.url}>
                <Link href={getMenuItemHref(LOGOUT_ITEM.url)} passHref shallow>
                    <a className={classNames(style.profileMenuItem, style.item)}>
                        <SvgIcon svg={LOGOUT_ITEM.icon} />
                        <Typography color="current-color" variant="h7">
                            {LOGOUT_ITEM.text}
                        </Typography>
                    </a>
                </Link>
            </li>
        </ul>
    );
};
