import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React from 'react';

import style from './style.module.scss';

export const NotFound: FC<TPropsWithClassName> = ({className}) => (
    <div className={classNames(className, style.root)}>
        <Typography variant="h4">Ничего не найдено </Typography>
        <Typography color="gray80" variant="p">
            Но, может быть, вам будет интересно:
        </Typography>
    </div>
);
