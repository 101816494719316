import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IShopsState} from 'modules/shops/types';
import {HYDRATE} from 'next-redux-wrapper';

export const shopsModuleName = 'shops';

const initialState: IShopsState = {
    shopsData: {
        filters: [],
        stores: [],
    },
};

export const shopsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[shopsModuleName];
        },
    },
    initialState,
    name: shopsModuleName,
    reducers: {
        actionSetShopList(state, {payload}: PayloadAction<IShopsState['shopsData']>) {
            state.shopsData = payload;
        },
    },
});

export const {
    actions: {actionSetShopList},
} = shopsSlice;

export const {reducer: shopsReducer} = shopsSlice;
