import classNames from 'classnames';
import {BlockTitleWithAction} from 'components/BlockTitleWithAction';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {FC, ReactNode} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    title?: string;
    href?: string;
    linkText?: string;
    subtitle?: ReactNode;
    withoutPaddingTop?: boolean;
}

export const PageSectionBlock: FC<IProps> = ({
    className,
    children,
    title,
    href,
    linkText,
    subtitle,
    withoutPaddingTop = false,
}) => {
    return (
        <article className={classNames(style.root, className, {[style.withoutPaddingTop]: withoutPaddingTop})}>
            {title && <BlockTitleWithAction href={href} linkText={linkText} subtitle={subtitle} title={title} />}
            {children}
        </article>
    );
};
