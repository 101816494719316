import * as Sentry from '@sentry/nextjs';
import {Layout} from 'layouts/BasicLayout';
import {Error503} from 'modules/errors/components/Error503';
import {Component, ReactNode} from 'react';

interface IState {
    hasError: boolean;
}

interface IProps {
    children: ReactNode;
}

class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    componentDidCatch(error: any) {
        Sentry.captureException(error);
        console.error(error);
    }

    shouldComponentUpdate(_: IProps, nextState: typeof this.state) {
        if (nextState.hasError) {
            this.setState({hasError: false});
            return false;
        }
        return true;
    }

    render() {
        if (this.state.hasError) {
            return (
                <Layout>
                    <Error503 />
                </Layout>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
