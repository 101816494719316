import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {
    actionDeleteFromCompareProducts,
    actionPushToCompareProducts,
    actionSetCompareProductsList,
} from 'modules/compareProducts/slice';
import {CompareDataResponse, ProductBase} from 'new-models';

export const getCompareProductsList: TAppThunk<void> = async (dispatch, _, {compareProductsService}) => {
    try {
        const {compareList} = await dispatch(
            requestAction<TResponse<CompareDataResponse>>({
                requestCb: () => compareProductsService.getCompareProductsList(),
                type: actionSetCompareProductsList.type,
            })
        );

        if (!compareList) {
            return;
        }

        dispatch(actionSetCompareProductsList(compareList));
    } catch (e) {
        console.error(e);
    }
};

export const addToCompareProductsList =
    (product: ProductBase): TAppThunk<void> =>
    async (dispatch, _, {compareProductsService}) => {
        try {
            const {ok} = await compareProductsService.addToCompareProductsList({productId: product.id});

            if (!ok) {
                return;
            }

            dispatch(actionPushToCompareProducts(product));
        } catch (e) {
            console.error(e);
        }
    };

export const removeFromCompareProductsList =
    (product: ProductBase): TAppThunk<void> =>
    async (dispatch, _, {compareProductsService}) => {
        try {
            const {ok} = await compareProductsService.removeFromCompareProductsList({productId: product.id});

            if (!ok) {
                return;
            }

            dispatch(actionDeleteFromCompareProducts(product));
        } catch (e) {
            console.error(e);
        }
    };
