import {IRouteContextProps} from 'modules/router/context';
import {useRouter} from 'next/router';
import {useMemo} from 'react';

export const useUrlSegmentGroupData = <
    T extends IRouteContextProps['matchedGroups'] = IRouteContextProps['matchedGroups']
>(
    regexString: string,
    queryKey: string = 'path'
): T | null => {
    const router = useRouter();

    const regex = useMemo<RegExp>(() => new RegExp(regexString, 'u'), [regexString]);
    const routerQuery = useMemo<string>(() => <string>router.query[queryKey], [router.query, queryKey]);

    return useMemo<T | null>(() => {
        const matchedGroups = routerQuery?.match(regex)?.groups;

        if (!matchedGroups) {
            return <T>{};
        }

        return <T>matchedGroups;
    }, [regex, routerQuery]);
};
