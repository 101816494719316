import {isBrowserContext} from 'core/helpers';
import {useEffect} from 'react';

export const useResize = (callback: (event: UIEvent) => void) => {
    useEffect(() => {
        if (callback && isBrowserContext()) {
            window.addEventListener('resize', callback);
        }

        return () => {
            window.removeEventListener('resize', callback);
        };
    }, [callback]);
};
