import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {MIN_SWIPE_DISTANCE, SPACE_BETWEEN} from 'modules/slider/constants';
import {selectActiveIdx} from 'modules/slider/selectors';
import {actionSetActiveIdx} from 'modules/slider/slice';
import arrowIcon from 'public/icons/arrow.svg';
import React, {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Mousewheel, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {PaginationOptions} from 'swiper/types/modules/pagination';
import TSwiper from 'swiper/types/swiper-class';
import {SwiperOptions} from 'swiper/types/swiper-options';

import style from './style.module.scss';

export interface IProps extends SwiperOptions, TPropsWithClassName {
    children: ReactNode[];
    isSlideTo?: boolean;
    isPagination?: boolean;
    isNavigation?: boolean;
    onInitialized?: (swiper: TSwiper) => void;
    isHideDisableNavigation?: boolean;
    classNamePrevButton?: string;
    classNameNextButton?: string;
}

export const SliderWithExternalButton: FC<IProps> = ({
    children,
    className,
    isSlideTo = false,
    isPagination = false,
    isNavigation = false,
    spaceBetween,
    onInitialized,
    loop = false,
    isHideDisableNavigation = false,
    classNamePrevButton,
    classNameNextButton,
    ...sliderProps
}) => {
    const dispatch = useAppDispatch();

    const activeIdx = useAppSelector(selectActiveIdx);

    const slider = useRef<TSwiper | undefined>();

    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);

    const [isInit, setIsInit] = useState(false);

    const isSkeleton = useIsSkeleton();

    useEffect(() => {
        if (isSlideTo && Boolean(slider.current)) {
            slider.current?.slideTo(activeIdx);
        }
    }, [activeIdx, isSlideTo, slider]);

    const onSwiper = useCallback(
        (swiper: TSwiper) => {
            if (slider) {
                slider.current = swiper;

                setIsInit(true);
            }

            onInitialized?.(swiper);
        },
        [onInitialized]
    );

    const onActiveIndexChange = useCallback(
        (swiper: TSwiper) => {
            if (isSlideTo) {
                dispatch(actionSetActiveIdx(swiper.activeIndex));
            }
        },
        [dispatch, isSlideTo]
    );

    const renderSlides = useMemo<ReactNode[]>(
        () => children.map((child, index) => <SwiperSlide key={index}>{child}</SwiperSlide>),
        [children]
    );

    const renderModules = useMemo<SwiperOptions['modules']>(() => {
        const modules = [Mousewheel];

        if (isPagination) {
            modules.push(Pagination);
        }

        if (isNavigation) {
            modules.push(Navigation);
        }

        return modules;
    }, [isNavigation, isPagination]);

    useEffect(() => {
        if (slider.current?.params?.navigation && true !== slider.current.params.navigation) {
            slider.current.params.navigation.nextEl = nextRef.current;
            slider.current.params.navigation.prevEl = prevRef.current;
        }
    }, [slider]);

    const paginationParams = useMemo<PaginationOptions | boolean>(() => isPagination, [isPagination]);

    return (
        <div
            className={classNames(style.wrapper, {
                [style.hideDisableButton]: Boolean(isHideDisableNavigation),
                [style.hideNavigation]: !isInit,
            })}
        >
            {isNavigation && !isSkeleton && (
                <>
                    <button
                        className={classNames(
                            style.navigation,
                            style.prev,
                            classNamePrevButton,
                            'vertical' === sliderProps.direction && style[sliderProps.direction]
                        )}
                        ref={prevRef}
                    >
                        <SvgIcon svg={arrowIcon} />
                    </button>
                    <button
                        className={classNames(
                            style.navigation,
                            style.next,
                            classNameNextButton,
                            'vertical' === sliderProps.direction && style[sliderProps.direction]
                        )}
                        ref={nextRef}
                    >
                        <SvgIcon svg={arrowIcon} />
                    </button>
                </>
            )}

            <Swiper
                {...sliderProps}
                className={classNames(className, style.slider)}
                enabled={!isSkeleton}
                loop={loop}
                modules={renderModules}
                mousewheel={{
                    forceToAxis: true,
                }}
                navigation={
                    isNavigation && {
                        nextEl: nextRef.current,
                        prevEl: prevRef.current,
                    }
                }
                onActiveIndexChange={onActiveIndexChange}
                onSwiper={onSwiper}
                pagination={paginationParams}
                spaceBetween={spaceBetween ?? SPACE_BETWEEN.default}
                threshold={MIN_SWIPE_DISTANCE}
            >
                {renderSlides}
            </Swiper>
        </div>
    );
};
