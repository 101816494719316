import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TNormalize, TResponse, TResponseWithoutContent} from 'core/types';
import {TProfileRemoveDataRequest} from 'modules/user/models/ProfileRequest';
import {IProfileResponse} from 'modules/user/models/ProfileResponse';
import {PasswordChangeDataRequest, Profile, ProfileEmailDataRequest, ProfilePhoneDataRequest} from 'new-models';

export class UserService extends EvolutionHttpClient {
    // TODO: возможно, следует отказаться от использования типизации `body` -- слишком много палок в колеса
    public registerUser(user: TNormalize<Profile>) {
        return this.post<TNormalize<Profile>, TResponse<IProfileResponse>>({
            body: user,
            url: '/profile/registration',
        });
    }

    public async getUserData(): Promise<TResponse<IProfileResponse>> {
        const {data} = await this.get<TResponse<IProfileResponse>>({
            url: '/profile',
        });

        return data;
    }

    public async updateProfile(profile: Partial<Profile>) {
        const data = await this.patch<Partial<Profile>, TResponse<IProfileResponse>>({
            body: profile,
            url: '/profile',
        });

        return data;
    }

    public async changePhone({phone, code}: ProfilePhoneDataRequest): Promise<TResponseWithoutContent> {
        const body: ProfilePhoneDataRequest = {phone};

        if (code) {
            body.code = code;
        }

        return this.post<ProfilePhoneDataRequest, TResponse<null>>({
            body,
            url: '/profile/phone',
        });
    }

    public async changeEmail({email, code}: ProfileEmailDataRequest): Promise<TResponseWithoutContent> {
        const body: ProfileEmailDataRequest = {email};

        if (code) {
            body.code = code;
        }

        return this.post<ProfileEmailDataRequest, TResponse<null>>({
            body,
            url: '/profile/email',
        });
    }

    public async changePassword({newPassword}: PasswordChangeDataRequest): Promise<TResponseWithoutContent> {
        return this.put<PasswordChangeDataRequest, TResponse<null>>({
            body: {newPassword},
            url: '/profile/password',
        });
    }

    public async removeAccount(params?: TProfileRemoveDataRequest): Promise<TResponseWithoutContent> {
        const {code} = params || {};
        const body: TProfileRemoveDataRequest = {};

        if (code) {
            body.code = code;
        }

        return this.post<TProfileRemoveDataRequest, TResponse<null>>({
            body,
            url: '/profile/delete',
        });
    }
}
