import {TAppRootState} from 'core/redux/types';
import {actionSetFavouritesList, favouritesModuleName} from 'modules/favourites/slice';
import {TFavouritesState} from 'modules/favourites/types';
import {selectIsLoading} from 'modules/loader/selectors';

const select = (state: TAppRootState): TFavouritesState => state[favouritesModuleName];

export const selectFavouritesList = (state: TAppRootState) => select(state).favouritesList;

export const selectFavouritesIdList = (state: TAppRootState) => select(state).favouritesIdList;

export const selectFavouritesIsLoading = (state: TAppRootState) => selectIsLoading(actionSetFavouritesList.type)(state);
