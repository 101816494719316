import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {isMobileByScreen, makeScrollListStyles, removeScrollListStyles} from 'core/helpers';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {ChooseVariant} from 'new-models';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {ChangeEventHandler, FC, RefObject, useCallback, useEffect, useId, useState} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    count: number;
    callbackFn?: () => void;
    onClose?: () => void;
    parentContainer?: RefObject<HTMLUListElement>;
    variants?: ChooseVariant[];
}

const SCROLL_LIST_LENGTH = 12;
const SCROLL_LIST_VISIBLE_ITEMS = 10;

const ChevronIcon: FC<TPropsWithClassName> = ({className}) => <SvgIcon className={className} svg={chevronDownIcon} />;

// TODO: переписать с использованием компонента `<CallapseAnimated />`

export const FilterCollapse: FC<IProps> = ({
    className,
    children,
    count,
    callbackFn,
    onClose,
    parentContainer,
    variants,
}) => {
    const uid = useId();
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleShowMoreClick = useCallback<ChangeEventHandler<HTMLInputElement>>(({target}) => {
        requestAnimationFrame(() => {
            setIsVisible(target.checked);
        });
    }, []);

    const handleClose = useCallback<ChangeEventHandler<HTMLInputElement>>(
        ({target}) => {
            requestAnimationFrame(() => {
                target.checked = false;
                setIsVisible(target.checked);
                onClose?.();
            });
        },
        [onClose]
    );

    useEffect(() => {
        if (isMobileByScreen()) {
            if (isVisible) {
                callbackFn?.();
            }

            return;
        }

        if (!isVisible) {
            if (parentContainer?.current) {
                removeScrollListStyles(parentContainer.current);
            }

            return;
        }

        if (parentContainer && variants && variants.length > SCROLL_LIST_LENGTH) {
            makeScrollListStyles({
                listContainer: parentContainer.current,
                sliceEnd: SCROLL_LIST_VISIBLE_ITEMS,
            });
        }

        callbackFn?.();
    }, [isVisible, callbackFn, variants, parentContainer]);

    useEffect(() => {
        return () => {
            onClose?.();
        };
    }, [onClose]);

    return isVisible ? (
        <>
            {children}
            <div className={style.filterCollapseBtnClose}>
                <input className={style.filterCollapseCheckbox} id={uid} onChange={handleClose} type="checkbox" />
                <label className={style.filterCollapseLabel} htmlFor={uid}>
                    <Typography className={style.filterCollapseText} element="div" variant="button-m">
                        Скрыть
                    </Typography>
                    <div className={style.filterStyleCloseIcon}>
                        <ChevronIcon className={style.icon} />
                    </div>
                </label>
            </div>
        </>
    ) : (
        <div className={classNames(className, style.filterCollapse)}>
            <input className={style.filterCollapseCheckbox} id={uid} onChange={handleShowMoreClick} type="checkbox" />
            <label className={style.filterCollapseLabel} htmlFor={uid}>
                <Typography className={style.filterCollapseText} element="div" variant="button-m">
                    Ещё {count}
                </Typography>
                <ChevronIcon className={style.icon} />
            </label>
        </div>
    );
};
