import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {selectPaymentServices} from 'modules/menu/selectors';
import React, {memo} from 'react';

import style from './style.module.scss';

export const BankCardList = memo<TPropsWithClassName>(function BankCardListComponent({className}) {
    const paymentList = useAppSelector(selectPaymentServices);

    return (
        <ul className={classNames(style.bankCardList, className)}>
            {paymentList?.sections.map(({code, icon}) => {
                return (
                    <li className={style.bankCardItem} key={code}>
                        <MediaHarvester className={style.bankCardItem} media={icon} />
                    </li>
                );
            })}
        </ul>
    );
});
