import classNames from 'classnames';
import {disableScroll, enableScroll} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import React, {FC, useEffect, useState} from 'react';

import style from './style.module.scss';

export const LOADER_THEMES = {
    logo: 'logo',
    primary: 'primary',
    secondary: 'secondary',
} as const;

export const LOADER_MODES = {
    compact: 'compact',
    standard: 'standard',
} as const;

export type TLoaderTheme = keyof typeof LOADER_THEMES;
export type TLoaderMode = keyof typeof LOADER_MODES;

export const LOADER_DISABLED_TIME = 500;

export interface ILoaderProps extends TPropsWithClassName {
    theme?: TLoaderTheme;
    mode?: TLoaderMode;
}

export interface ILogoLoaderProps extends TPropsWithClassName {
    isLoading?: boolean;
    isNotForReload?: boolean;
    fixed?: boolean;
    scrollDisabling?: boolean;
}

export const Loader: FC<ILoaderProps> = ({className, theme = LOADER_THEMES.primary, mode = LOADER_MODES.standard}) => (
    <div
        className={classNames(style.loader, className, {
            [style[theme]]: Boolean(theme),
            [style[mode]]: Boolean(mode),
        })}
    />
);

export const LogoLoader: FC<ILogoLoaderProps> = ({
    fixed = false,
    className,
    isLoading = true,
    isNotForReload = false,
    scrollDisabling = false,
}) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(isNotForReload);

    useEffect(() => {
        if (scrollDisabling) {
            disableScroll();
        }

        return () => {
            if (scrollDisabling) {
                enableScroll();
            }
        };
    }, [scrollDisabling]);

    useEffect(() => {
        if (isLoading && isNotForReload) {
            setIsDisabled(false);
        }
        if (!isLoading) {
            setTimeout(() => {
                setIsDisabled(true);
                enableScroll();
            }, LOADER_DISABLED_TIME);
        }
    }, [isLoading, isNotForReload]);

    return (
        <div
            className={classNames(style.logoLoader, className, {
                [style.loaded]: !isLoading,
                [style.disabled]: isDisabled,
                [style.transparent]: isNotForReload,
                [style.logoLoaderFixed]: fixed,
            })}
        >
            <Loader theme={LOADER_THEMES.logo} />
        </div>
    );
};
