import {Nameplate} from 'models';
export const SHIELD_THEMES = {
    ads: 'ads',
    info: 'info',
    new: 'new',
    notAvailable: 'notAvailable',
    sale: 'sale',
} as const;

export const SHIELD_SIZES = {
    big: 'big',
    medium: 'medium',
    small: 'small',
} as const;

export const SHIELDS_LIST_VIEW = {
    list: 'list',
    slider: 'slider',
    tile: 'tile',
} as const;

// TODO: Убрать "| '3d-viz'" когда обновят модель
export const SHIELDS_TYPES: Record<string, Nameplate['type'] | '3d-viz'> = {
    VIZ: '3d-viz',
};
