import {createListenerMiddleware, isAnyOf, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {TListener} from 'core/redux/types';
import {bottomBarModalEffect} from 'modules/bottombar/effects';
import {actionSetPortalHidden, actionSetPortalVisible} from 'modules/portal/slice';
import {IPortalState} from 'modules/portal/types';

export const dialogListener = createListenerMiddleware<IRootReducer>();

const startDialogListener = dialogListener.startListening as TListener;

startDialogListener({
    effect: (action, {dispatch, getState}) => {
        bottomBarModalEffect(dispatch, getState, action as PayloadAction<keyof IPortalState['modalList']>);
    },
    matcher: isAnyOf(actionSetPortalVisible, actionSetPortalHidden),
});
