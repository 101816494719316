import {CollapseAnimated, ICollapseAnimatedProps} from 'components/CollapseAnimated';
import {useIsScrollBottom} from 'core/hooks/useIsScrollBottom';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {FC} from 'react';

interface IProps extends TPropsWithChildrenRequire, ICollapseAnimatedProps, TPropsWithClassName {
    isCollapsed?: boolean;
    onCollapsed?: (state: boolean) => void;
}
export const CollapseAnimatedOnScrollBottom: FC<IProps> = ({className, children, isCollapsed = true, ...rest}) => {
    const isScrollBottom = useIsScrollBottom();

    return (
        <CollapseAnimated isHidden={isCollapsed && isScrollBottom} {...rest} className={className}>
            {children}
        </CollapseAnimated>
    );
};
