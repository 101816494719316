import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {formatDateWithLocal} from 'core/helpers/date';
import {DeliveryMethodBase, DeliveryType, OrderAddress, OrderCourierDeliveryData} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    className?: string;
    deliveryData?: OrderCourierDeliveryData;
    deliveryMethod?: DeliveryMethodBase;
    date: string;
}

export const OrderCardItemDeliveryDetails = memo<IProps>(function OrderCardItemDeliveryDetails({
    className,
    deliveryData,
    deliveryMethod,
    date,
}) {
    const {address} = deliveryData ?? {};

    const typeDeliveryText = deliveryMethod?.type === DeliveryType.PICKUP ? 'получения' : 'доставки';

    return (
        <div className={classNames(style.root, className)}>
            <div className={style.content}>
                <Typography variant="p-strong">{deliveryMethod?.title}</Typography>
                <Typography className={style.text} variant="p-medium">
                    {(address as OrderAddress)?.address}
                </Typography>
                <Typography color="gray100" variant="p-strong">
                    Дата {typeDeliveryText}: {formatDateWithLocal(date)}
                </Typography>
            </div>
        </div>
    );
});
