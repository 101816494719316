import {formatPrice} from 'core/helpers';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {CalculatorType, ProductOfferDetail} from 'models';
import {ERROR_TEXT, FORM_SWITCHERS, INITIAL_VALUES} from 'modules/calculators/constants';
import {getFieldData, getResultFields} from 'modules/calculators/helpers';
import {
    ICalculatorField,
    ICalculatorSurfaceField,
    IResultAccData,
    TCalculationResponse,
    TCalculationResult,
    TCalculatorFieldsKeys,
    TCalculatorSurfaceFieldsKeys,
} from 'modules/calculators/types';
import * as Yup from 'yup';

export class CalculatorService extends EvolutionHttpClient {
    async postCalculation(
        calculationData: Partial<Record<TCalculatorFieldsKeys, string | number>>,
        productId: ProductOfferDetail['id']
    ): Promise<TCalculationResponse> {
        const {data} = await this.post<Partial<Record<TCalculatorFieldsKeys, string | number>>, TCalculationResponse>({
            body: calculationData,
            url: `/product/${productId}/calculate`,
        });

        return data;
    }

    /** Строит объект валидации в зависимости от полей, которые будут задействованы в форме калькулятора */
    public static setValidationSchema = (
        values: Partial<Record<TCalculatorFieldsKeys | TCalculatorSurfaceFieldsKeys, string>>,
        fields: Record<string, ICalculatorField | ICalculatorSurfaceField>
    ) =>
        Yup.object().shape(
            Object.fromEntries(
                Object.entries(values).map((value) => [
                    value[0],
                    'string' === typeof value[1]
                        ? Yup.string().required(`
                         ${ERROR_TEXT} ${getFieldData({fields, name: value[0] as TCalculatorFieldsKeys}).error}`)
                        : Yup.number().required(`
                        ${ERROR_TEXT}  ${getFieldData({fields, name: value[0] as TCalculatorFieldsKeys}).error}`),
                ])
            )
        );

    /** Проверяем на необходимость вычисления площади из длины и ширины и формируем правильные данные для пост запроса  */
    public static countCalculationData = (
        calcType: CalculatorType,
        values: Partial<Record<TCalculatorFieldsKeys, string>>,
        isNeedConversion: boolean,
        selectedTab?: Record<string, string>
    ) => {
        let data = {};

        if (isNeedConversion) {
            data = {
                [Object.keys(INITIAL_VALUES[calcType])[0]]: Object.values(values).reduce(
                    (result, value) => result * Number(value),
                    1
                ),
            };
        } else if (
            calcType === CalculatorType.InteriorPaint &&
            selectedTab?.paintableSurface === FORM_SWITCHERS.PAINT_ABLE_SURFACE.radios[0].value
        ) {
            data = {
                ...values,
                ...selectedTab,
            };
        } else if (
            calcType === CalculatorType.InteriorPaint &&
            selectedTab?.paintableSurface === FORM_SWITCHERS.PAINT_ABLE_SURFACE.radios[1].value
        ) {
            data = {
                ...values,
                ...selectedTab,
                ...{
                    doorwaysArea: 0,
                    height: 0,
                    windowArea: 0,
                },
            };
        } else {
            data = values;
        }

        return {
            ...Object.fromEntries(
                Object.entries(data).map((value) => [value[0], Boolean(Number(value[1])) ? Number(value[1]) : value[1]])
            ),
            type: calcType,
        };
    };

    /** В зависимости от типа калкулятора формируем массив объектов {title, value} для дальнейшего вывода списка результатов вычисления */

    public static renderResultList(calcType: CalculatorType, result: TCalculationResult): IResultAccData[] {
        const resultFields = getResultFields(calcType);

        if (calcType === CalculatorType.Tile) {
            return resultFields.reduce<IResultAccData[]>((acc, {title, adaptiveTitle, name, unit}) => {
                if (title) {
                    return [
                        ...acc,
                        {
                            adaptiveTitle,
                            title,
                            value: `${formatPrice(result[name]?.toString() ?? '', {
                                maximumFractionDigits: undefined,
                            })} ${unit ?? ''}`,
                        },
                    ];
                }

                return acc.map<IResultAccData>((item, idx) => {
                    if (idx === acc.length - 1) {
                        const extraQuantity =
                            0 < Number(result[name] ?? 0) ? ` + ${result[name] ?? ''} ${unit ?? ''}` : '';

                        return {
                            adaptiveTitle: item.adaptiveTitle,
                            title: item.title,
                            value: `${item.value}${extraQuantity}`,
                        };
                    }

                    return item;
                });
            }, []);
        }

        return resultFields.map(({title, name, unit}) => {
            return {
                title,
                value: `${formatPrice(result[name]?.toString() ?? '', {
                    maximumFractionDigits: undefined,
                })} ${unit ?? ''}`,
            };
        });
    }
}
