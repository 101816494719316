import {setIsSelection} from 'modules/products/helpers';
import {useIsSearchContext} from 'modules/search-page/hooks/useIsSearchContext';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {Category} from 'new-models';
import {useRouter} from 'next/router';
import {useMemo} from 'react';
import {findPage} from 'routing/helpers';

export const useListingTitle = (category?: Category): string => {
    const router = useRouter();

    const isSkeleton = useIsSkeleton();

    const isSearchContext = useIsSearchContext();

    return useMemo<string>(() => {
        if (isSkeleton) {
            return '';
        }

        if (setIsSelection(router.query.path)) {
            return findPage(router.query.path)?.title ?? '';
        }

        if (category) {
            return category.title;
        }

        if (isSearchContext) {
            return `Результаты поиска по запросу «${router.query.query}»`;
        }

        return '';
    }, [category, isSearchContext, isSkeleton, router.query.path, router.query.query]);
};
