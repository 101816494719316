import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {AuthContext} from 'modules/authentication/context';
import {actionSetModalParams} from 'modules/authentication/slice';
import {IModalParams, TModalParamsHandler} from 'modules/authentication/types';
import {useCallback, useContext} from 'react';

export const useModalParamsHandler = (): TModalParamsHandler => {
    const {modalParams: currentModalParams} = useContext(AuthContext);
    const dispatch = useAppDispatch();

    return useCallback(
        (modalParams: IModalParams) => {
            dispatch(actionSetModalParams({...currentModalParams, ...modalParams}));
        },
        [currentModalParams, dispatch]
    );
};
