import {TOKEN_KEY} from 'core/constants';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {CookieService} from 'core/services/CookieService';
import {TResponse} from 'core/types';
import {IJwtPayload} from 'core/types';
import jwtDecode from 'jwt-decode';
import {TChangeLocationResponse, TLocationResponse} from 'modules/locations/models/LocationsResponse';
import {Location, LocationChangeDataRequest} from 'new-models';

export class LocationService extends EvolutionHttpClient {
    public async getLocationsList(): Promise<TResponse<TLocationResponse>> {
        const {data} = await this.get<TResponse<TLocationResponse>>({
            url: '/locations',
        });

        return data;
    }

    public async changeLocation(locationId: Location['id']): Promise<TResponse<TChangeLocationResponse>> {
        const {headers} = await this.post<LocationChangeDataRequest>({
            body: {
                locationId,
            },
            url: '/state/location',
        });

        return {
            data: {
                token: headers.get(TOKEN_KEY) || '',
            },
        };
    }

    public static getUserCityId(): Location['id'] | null {
        const token = CookieService.getInstance().getItem(TOKEN_KEY);

        try {
            const jwtPayload = jwtDecode<IJwtPayload>(token ?? '');
            return jwtPayload.loc;
        } catch (e) {
            return null;
        }
    }
}
