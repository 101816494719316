import {CalculatorType} from 'models';
import {DISPLAYED_RESULT_FIELD_INDEX, FIELDS, RESULTS, UNIT} from 'modules/calculators/constants';
import {
    ICalculatorField,
    ICalculatorResult,
    ICalculatorSurfaceField,
    TCalculationResult,
    TCalculatorFieldsKeys,
    TCalculatorSurfaceFieldsKeys,
} from 'modules/calculators/types';

export const getFieldData = ({
    fields,
    name,
}: {
    fields: Record<string, ICalculatorField | ICalculatorSurfaceField>;
    name: TCalculatorFieldsKeys | TCalculatorSurfaceFieldsKeys;
}): ICalculatorField =>
    (Object.entries(fields).find((field) => field[1].name === name) as unknown as [string, ICalculatorField])[1];

// eslint-disable-next-line complexity
export const getUnit = (calculatorType: CalculatorType) => {
    switch (calculatorType) {
        case CalculatorType.Laminate:
        case CalculatorType.GlassWool:
        default:
            return UNIT.PACK;
        case CalculatorType.DryMixes:
        case CalculatorType.Grout:
        case CalculatorType.Drywall:
        case CalculatorType.InteriorPaint:
        case CalculatorType.OutdoorPaint:
        case CalculatorType.Radiator:
            return UNIT.PIECE;
        case CalculatorType.Tile:
            return UNIT.METER;
        case CalculatorType.Wallpaper:
            return UNIT.ROLL;
    }
};

export const getResultFields = (calculatorType: CalculatorType) => {
    const resultsArray = (
        Object.entries(RESULTS).find((result) => result[0] === calculatorType) as unknown as [
            string,
            ICalculatorResult[]
        ]
    )[1];

    return resultsArray;
};

// eslint-disable-next-line complexity
export const getResultNumber = (calculatorType: CalculatorType, result: TCalculationResult): number => {
    const resultsField = getResultFields(calculatorType);

    switch (calculatorType) {
        case CalculatorType.Laminate:
        case CalculatorType.GlassWool:
        case CalculatorType.Wallpaper:
        case CalculatorType.InteriorPaint:
        case CalculatorType.OutdoorPaint:
        case CalculatorType.Drywall:
        default:
            return result[resultsField[DISPLAYED_RESULT_FIELD_INDEX[0]].name] || 0;
        case CalculatorType.DryMixes:
        case CalculatorType.Grout:
            return result[resultsField[DISPLAYED_RESULT_FIELD_INDEX[1]].name] || 0;
        case CalculatorType.Tile:
            return result[resultsField[DISPLAYED_RESULT_FIELD_INDEX[2]].name] || 0;
    }
};

export const setIsIntegerFields = (name: string): boolean =>
    name === FIELDS.DOORS_QUANTITY.name ||
    name === FIELDS.WINDOWS_QUANTITY.name ||
    name === FIELDS.PAINT_COATS_QUANTITY.name;
