import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {HomePagePromotionsItem} from 'modules/home-page/components/HomePagePromotionsItem';
import {selectPopularPromoList} from 'modules/promo/selectors';

import style from './style.module.scss';

export const HomePagePromotions = () => {
    const promotionsList = useAppSelector(selectPopularPromoList);

    if (!promotionsList?.length || promotionsList.every((item) => !item.products || !item.products.products.length)) {
        return null;
    }

    return (
        <div className={style.root}>
            {promotionsList.map((item) => (
                <HomePagePromotionsItem promotion={item} />
            ))}
        </div>
    );
};
