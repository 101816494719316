import {StatusPage} from 'modules/order-status/pages/StatusPage';
import {OrderXPageContext} from 'modules/orders/context';
import {THANKS_FOR_YOUR_ORDER_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const ThanksForYourOrderPageSkeleton: FC = () => {
    return (
        <OrderXPageContext.Provider value={THANKS_FOR_YOUR_ORDER_PAGE_SKELETON_DATA}>
            <StatusPage />
        </OrderXPageContext.Provider>
    );
};
