import {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import {createContext, ReactNode} from 'react';

export interface IRouteContextProps {
    prevPath: string;
    matched: string | undefined;
    matchedGroups: Record<string, any>;
    allMatches: string[];
    component?: ReactNode;
    defaultQuery?: NextParsedUrlQuery;
}

export const RouteContext = createContext<IRouteContextProps>({
    allMatches: [],
    defaultQuery: undefined,
    matched: undefined,
    matchedGroups: {},
    prevPath: '',
});
