import classNames from 'classnames';
import {ISvgIcon} from 'core/types';
import React, {FC, HTMLAttributes, memo} from 'react';

import style from './style.module.scss';

interface ISvgIconProps extends Omit<HTMLAttributes<SVGElement>, 'className' | 'onClick'> {
    fillContainer?: boolean;
    svg: ISvgIcon;
    isAutoSize?: boolean;
    width?: string | number;
    height?: string | number;
    className?: string | boolean;
}

export const SvgIcon: FC<ISvgIconProps> = memo(function SvgIcon({
    className,
    fillContainer,
    svg,
    isAutoSize = false,
    width,
    height,
    ...restProps
}) {
    if (!svg.url || !svg.viewBox) {
        return null;
    }

    let icon = {
        ...svg,
        height,
        width,
    };

    if (isAutoSize) {
        const [, , vWidth, vHeight] = svg.viewBox.split(' ') ?? [];

        icon.width = vWidth;
        icon.height = vHeight;
    }

    return (
        <svg
            height={icon.height}
            id={icon.id}
            viewBox={icon.viewBox}
            width={icon.width}
            {...restProps}
            className={classNames(style.svgIcon, className, {[style.fillContainer]: fillContainer})}
        >
            <use href={icon.url} />
        </svg>
    );
});
