import classNames from 'classnames';
import {getPreviewSrc} from 'components/VideoRutube/helpers/getPreviewSrc';
import {TVideoId} from 'components/VideoRutube/type';
import {TPropsWithClassName} from 'core/types';
import {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    videoId: TVideoId;
}

export const PreviewRutube = memo<IProps>(({videoId, className}) => {
    return (
        <div className={classNames(style.root, className)}>
            <img alt="Rutube Preview" className={style.img} src={getPreviewSrc(videoId)} />
            <img alt="Play" className={style.play} src="/images/play-video-reviews.png" />
        </div>
    );
});
