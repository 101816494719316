import classNames from 'classnames';
import {Button, BUTTON_SIZE, BUTTON_THEMES} from 'components/Button';
import {Counter, COUNTER_SIZE} from 'components/Counter';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {CartProductImg} from 'modules/cart/components/CartProductImg';
import {REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {useCartModalActions} from 'modules/cart/hooks/useCartModalActions';
import {useCartProduct} from 'modules/cart/hooks/useCartProduct';
import {selectIsCartCountLoads} from 'modules/cart/selectors';
import {actionSetRemoveId} from 'modules/cart/slice';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {usePriceAndBonuses} from 'modules/price-n-bonuses/hooks/usePriceAndBonuses';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {BasketItem} from 'new-models';
import Link from 'next/link';
import deleteIcon from 'public/icons/delete.svg';
import React, {FC, MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

interface IProps extends BasketItem, TPropsWithClassName {}

// eslint-disable-next-line max-lines-per-function, complexity
export const CartPopupProductItem: FC<IProps> = ({className, ...basketItem}) => {
    const dispatch = useAppDispatch();
    const {id, title, price, code, availability, availabilityCount} = basketItem.product;

    const isLoading = useAppSelector(selectIsCartCountLoads(id));

    const productUrl = useProductUrl({code, id});

    const {openCartRemoveModal} = useCartModalActions();

    const {
        handleCartCountAddClick,
        handleCartCountChange,
        handleCartCounterInputBlur,
        handleCartCountRemoveClick,
        cartProductCount,
    } = useCartProduct(basketItem.product);

    const {setCartUsedIn} = usePriceAndBonuses();
    const handleRemoveClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            dispatch(actionSetRemoveId(id));
            openCartRemoveModal(REMOVE_MODAL_VIEW.ONE_SELECTED)(event);
        },
        [dispatch, id, openCartRemoveModal]
    );

    return (
        <div className={classNames(style.cartPopupProductItem, className)}>
            <CartProductImg productUrl={productUrl} {...basketItem.product} isInPopup />
            <div className={style.infoContainer}>
                <div className={style.mainInfoWrap}>
                    <div className={style.mainInfo}>
                        <Link href={productUrl} prefetch={false}>
                            <a>
                                <Typography
                                    className={style.title}
                                    color="gray100"
                                    data-skeleton-item
                                    element="div"
                                    variant="p"
                                >
                                    {title}
                                </Typography>
                            </a>
                        </Link>
                        <Button
                            className={style.headerPopupDeleteButton}
                            isSquare
                            onClick={handleRemoveClick}
                            size={BUTTON_SIZE.small}
                            theme={BUTTON_THEMES.ghost}
                        >
                            <SvgIcon svg={deleteIcon} />
                        </Button>
                    </div>
                    <div className={style.headerPopupPrice}>
                        <Typography variant="p-small">
                            {formatPrice((price.main.price / PRICE_DENOMINATOR).toString(), {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                        <Typography className={style.headerPopupPriceCurrency} variant="p-small">
                            {price.main.unit ? `₽/${price.main.unit}` : '₽'}
                        </Typography>
                    </div>
                </div>

                <div className={style.counterAndPrice}>
                    {availability && (
                        <div className={style.counterContainer}>
                            <Counter
                                className={style.counter}
                                count={cartProductCount}
                                handleAddClick={handleCartCountAddClick}
                                handleChange={handleCartCountChange}
                                handleInputBlur={handleCartCounterInputBlur}
                                handleRemoveClick={handleCartCountRemoveClick}
                                isHorizontal={false}
                                isLoading={isLoading}
                                maxCount={availabilityCount}
                                minCount={1}
                                size={COUNTER_SIZE.small}
                            />
                        </div>
                    )}

                    <PriceAndBonuses
                        className={style.finalOffer}
                        isProductAvailable={availability}
                        price={price}
                        usedIn={setCartUsedIn(true)}
                    />
                </div>
            </div>
        </div>
    );
};
