import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBottomBarState} from 'modules/bottombar/types';

export const bottomBarModuleName = 'bottomBar';

const initialState: IBottomBarState = {
    activeItem: 'main',
    prevActiveItem: 'main',
};

export const bottomBarSlice = createSlice({
    initialState,
    name: bottomBarModuleName,
    reducers: {
        actionSetActive(state, {payload}: PayloadAction<IBottomBarState['activeItem']>) {
            state.prevActiveItem = state.activeItem;
            state.activeItem = payload;
        },
    },
});

export const {
    actions: {actionSetActive},
} = bottomBarSlice;

export const {reducer: bottomBarReducer} = bottomBarSlice;
