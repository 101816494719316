import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {TProductCardVariant} from 'modules/products/types';
import {IShieldProps, Shield} from 'modules/shields/components/Shield';
import {SHIELD_SIZES, SHIELDS_LIST_VIEW, SHIELDS_TYPES} from 'modules/shields/constants';
import {TShieldsList} from 'modules/shields/types';
import React, {memo, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    shields: IShieldProps[];
    listType?: TShieldsList;
    variant?: TProductCardVariant;
}

export const ShieldsList = memo<IProps>(function ShieldsList({
    variant,
    className,
    listType = SHIELDS_LIST_VIEW.tile,
    shields,
}) {
    const uid = useId();

    return (
        <ul
            className={classNames(
                className,
                style.cardShieldList,
                {
                    [style[listType]]: listType,
                },
                'shields-list'
            )}
        >
            {Boolean(shields?.length) &&
                shields.map((shield, index) => (
                    <li
                        className={classNames(style.cardShieldItem, {
                            [style[listType]]: listType,
                        })}
                        data-skeleton-item
                        key={`${uid}--${index}`}
                    >
                        <Shield
                            className={classNames(style.cardShieldItemShield, {
                                [style[listType]]: listType,
                            })}
                            compact={PRODUCT_CARD_VARIANT.preview === variant && SHIELDS_TYPES.VIZ === shield.type}
                            size={SHIELD_SIZES[PRODUCT_CARD_VARIANT.main === variant ? 'medium' : 'small']}
                            {...shield}
                        />
                    </li>
                ))}
        </ul>
    );
});
