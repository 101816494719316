import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {CategoryShort, MediaTypeEnum} from 'new-models';
import Link from 'next/link';
import React, {CSSProperties, FC} from 'react';

import style from './style.module.scss';

interface ICategoriesCardProps extends CategoryShort, TPropsWithClassName {
    href: string;
    styleProp?: CSSProperties;
}

export const CategoriesCard: FC<ICategoriesCardProps> = ({title, image, href, className}) => {
    return (
        <Link href={href} passHref>
            <a className={classNames(style.root, className)}>
                <div className={style.imgWrap} data-skeleton-item>
                    <MediaHarvester
                        media={image}
                        options={generateMediaOptions({
                            resizeKey: IMAGE_RESIZE_CODE.w220,
                            type: MediaTypeEnum.Image,
                        })}
                        title={title}
                    />
                </div>
                <Typography className={style.text} color="gray80" data-skeleton-item element="p" variant="p">
                    {title}
                </Typography>
            </a>
        </Link>
    );
};
