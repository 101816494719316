import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {actionSetPreviewProductList, categoryModuleName} from 'modules/categories/slice';
import {ICategoriesState} from 'modules/categories/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {Category} from 'new-models';

const select = (state: TAppRootState): ICategoriesState => state[categoryModuleName];

export const selectCategoriesList = createDraftSafeSelector([select], (state) => state.categoriesList);

export const selectPopularCategoryList = createDraftSafeSelector([select], (state) => state.popularCategoryList);

export const selectTreeCategoriesList = createDraftSafeSelector([selectCategoriesList], (flatCategoryList) => {
    return CategoryService.makeCategoryTree(flatCategoryList);
});

export const selectCategoryNodeById = (id?: Category['id']) =>
    createDraftSafeSelector([selectTreeCategoriesList], (treeCategoryList) => {
        return CategoryService.findCategoryById(treeCategoryList, id);
    });

export const selectCategoryIdChainList = (id?: Category['id']) =>
    createDraftSafeSelector([selectCategoriesList], (treeCategoryList) => {
        return (id && CategoryService.makeParentCategoryIdList(id, [id], treeCategoryList)) || null;
    });

export const selectCategoryById = (categoryId?: Category['id']) =>
    createDraftSafeSelector([selectCategoriesList], (categoriesList) =>
        categoriesList.find(({id}) => categoryId === id)
    );

export const selectCategoryPreviewProducts = (state: TAppRootState): ICategoriesState['productPreviewList'] =>
    select(state).productPreviewList;

export const selectIsProductPreviewLoads = (state: TAppRootState) => {
    return selectIsLoading(actionSetPreviewProductList.type)(state);
};

export const selectCategoryBySectionId = (id: Category['id']) =>
    createDraftSafeSelector([selectCategoriesList], (categoriesList) => {
        return categoriesList.find((category) => category.id === id);
    });
