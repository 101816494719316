import {BaseUrlParam} from 'core/base/BaseUrlParam';
import {BaseUrlService} from 'core/base/BaseUrlService';

export class SearchUrlService extends BaseUrlService {
    public static readonly COMMON_PARAM_NAMES = {
        QUERY: new BaseUrlParam('query', true),
    };

    constructor(
        query: BaseUrlService['query'],
        onParamsChange: BaseUrlService['onParamsChange'],
        options: BaseUrlService['options']
    ) {
        super(query, onParamsChange, options);
        this.fill(Object.values(SearchUrlService.COMMON_PARAM_NAMES), 'include');
        this.format();
    }

    public async addTagParam(tag: string): Promise<void> {
        await this.addParam({id: SearchUrlService.COMMON_PARAM_NAMES.QUERY.toString(), value: tag});
    }
}
