import {SvgIcon} from 'components/SvgIcon';
import {FavouriteSwitcherBase} from 'modules/favourites/components/FavouriteSwitcher/Base';
import {useFavourites} from 'modules/favourites/hooks/useFavourites';
import {ProductShort} from 'new-models';
import favouriteIcon from 'public/icons/favourite.svg';
import {AllHTMLAttributes, ChangeEventHandler, FC, useCallback} from 'react';

export interface IProps extends AllHTMLAttributes<HTMLElement> {
    product: ProductShort;
}

export const FavouriteSwitcher: FC<IProps> = ({product, ...restInputAttributes}) => {
    const {switchFavourites, isChecked} = useFavourites(product);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            switchFavourites(event.target.checked);
        },
        [switchFavourites]
    );

    return (
        <FavouriteSwitcherBase
            isChecked={isChecked}
            notCheckedIcon={<SvgIcon svg={favouriteIcon} />}
            onChange={handleChange}
            {...restInputAttributes}
        />
    );
};
