import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {IHeaderCatalogMenuItem} from 'modules/menu/types';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import Link from 'next/link';
import React, {FC, MouseEventHandler, useCallback} from 'react';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    href: string;
    categoryNode: IHeaderCatalogMenuItem;
}

export const CatalogMenuSectionLink: FC<IProps> = ({className, children, href, categoryNode}) => {
    const dispatch = useAppDispatch();

    const handleLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isMobileByScreen() && categoryNode.childrenCategory.length) {
                event.preventDefault();
                return;
            }

            dispatch(hideModal(MODALS.CATALOG_MENU.name));
        },
        [categoryNode.childrenCategory.length, dispatch]
    );

    return (
        <Link href={href} passHref scroll>
            <a className={className} onClick={handleLinkClick}>
                {children}
            </a>
        </Link>
    );
};
