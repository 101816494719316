import {Button, BUTTON_SIZE, BUTTON_THEMES} from 'components/Button';
import {CollapseAnimated} from 'components/CollapseAnimated';
import {Input} from 'components/Input';
import {Typography} from 'components/Typography';
import {useTotalPromo} from 'modules/cart/hooks/useTotalPromo';
import React, {useEffect, useRef} from 'react';

import style from './style.module.scss';

export const TotalPromo: FC = () => {
    const {
        handleChange,
        handleSubmit,
        isHidden,
        promoCode,
        switchPromo,
        error,
        successText,
        isPromocodeDisabled,
        isSubmitting,
    } = useTotalPromo();

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isHidden && inputRef.current) {
            inputRef.current.focus({preventScroll: true});
        }
    }, [isHidden]);

    return (
        <div className={style.root}>
            <header className={style.header}>
                <Typography variant="p">Промокод</Typography>

                <button className={style.switcher} disabled={isSubmitting} onClick={switchPromo}>
                    {isHidden ? 'Добавить' : 'Отменить'}
                </button>
            </header>
            <CollapseAnimated isHidden={isHidden}>
                <form className={style.form} onSubmit={handleSubmit}>
                    <Input
                        className={style.input}
                        disabled={isPromocodeDisabled}
                        errorText={error}
                        name="promoCode"
                        onChange={handleChange}
                        ref={inputRef}
                        successText={successText}
                        value={promoCode}
                    />
                    <Button
                        className={style.submitButton}
                        disabled={isPromocodeDisabled}
                        size={BUTTON_SIZE.medium}
                        theme={BUTTON_THEMES.secondary}
                        type="submit"
                    >
                        Применить
                    </Button>
                </form>
            </CollapseAnimated>
        </div>
    );
};
