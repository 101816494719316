import {TPropsWithClassName} from 'core/types';
import {ProductCardCollectionsItem} from 'modules/product-card/components/ProductCardCollectionsItem';
import {CollectionShort} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    collections?: CollectionShort[];
}

export const ProductCardCollections = memo<IProps>(function ProductCardInCollections({collections}) {
    if (!collections?.length) {
        return null;
    }

    return (
        <ul className={style.root}>
            {collections.map((item) => (
                <ProductCardCollectionsItem collectionItem={item} key={item.id} />
            ))}
        </ul>
    );
});
