import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TClosureCallback, TPropsWithClassName} from 'core/types';
import minusIcon from 'public/icons/minus.svg';
import plusIcon from 'public/icons/plus.svg';
import React, {ChangeEventHandler, FocusEventHandler, memo, MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

export const COUNTER_SIZE = {
    large: 'large',
    largeMedium: 'large-medium',
    medium: 'medium',
    small: 'small',
} as const;

export const COUNTER_BACKGROUND = {
    blue: 'blue',
    gray: 'gray',
    grayLight: 'gray-light',
} as const;

export const COUNTER_ACTION = {
    add: 'add',
    remove: 'remove',
} as const;

export type TCounterSize = (typeof COUNTER_SIZE)[keyof typeof COUNTER_SIZE];
export type TCounterBackground = (typeof COUNTER_BACKGROUND)[keyof typeof COUNTER_BACKGROUND];
export type TCounterAction = keyof typeof COUNTER_ACTION;

export const MAX_COUNT = 50000;

export interface IProps extends TPropsWithClassName {
    size?: TCounterSize;
    background?: TCounterBackground;
    handleAddClick?: MouseEventHandler<HTMLButtonElement>;
    handleRemoveClick?: MouseEventHandler<HTMLButtonElement>;
    handleChange?: ChangeEventHandler<HTMLInputElement>;
    handleInputBlur?: FocusEventHandler<HTMLInputElement>;
    count: number | null;
    isLoading?: boolean;
    minCount?: number;
    maxCount?: number;
    isHorizontal?: boolean;
    isStaticWidth?: boolean;
}

export const Counter = memo<IProps>(function Counter({
    className,
    size = COUNTER_SIZE.large,
    background = COUNTER_BACKGROUND.grayLight,
    handleAddClick,
    handleRemoveClick,
    count,
    handleChange,
    handleInputBlur,
    isLoading = false,
    minCount = 0,
    maxCount = MAX_COUNT,
    isHorizontal = false,
    isStaticWidth = false,
}) {
    const onClick = useCallback<TClosureCallback<TCounterAction, MouseEventHandler<HTMLButtonElement>>>(
        (action) => {
            return (e) => {
                if (isLoading) {
                    return;
                }

                if (action === COUNTER_ACTION.remove && handleRemoveClick) {
                    handleRemoveClick(e);
                }

                if (action === COUNTER_ACTION.add && handleAddClick) {
                    handleAddClick(e);
                }
            };
        },
        [handleAddClick, handleRemoveClick, isLoading]
    );

    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            const value = Number(e.target.value);

            if (value < minCount) {
                return;
            }

            handleChange?.(e);
        },
        [handleChange, minCount]
    );

    return (
        <div
            className={classNames(
                style.counter,
                className,
                {
                    [style[size]]: Boolean(size),
                    [style.horizontal]: isHorizontal,
                    [style.static]: isStaticWidth,
                },
                'counter-component'
            )}
        >
            <button
                className={classNames(style.button, style.decrement, {
                    [style[background]]: Boolean(background),
                })}
                data-prevent-default-marker
                data-stop-propagation-marker
                disabled={minCount >= Number(count)}
                onClick={onClick(COUNTER_ACTION.remove)}
                type="button"
            >
                <SvgIcon svg={minusIcon} />
            </button>
            <input
                className={style.input}
                data-prevent-default-marker
                data-stop-propagation-marker
                onBlur={handleInputBlur}
                onChange={onChange}
                type="text"
                value={Math.round((count || 0) * 100) / 100}
            />
            <button
                className={classNames(style.button, style.increment, {
                    [style[background]]: Boolean(background),
                })}
                data-prevent-default-marker
                data-stop-propagation-marker
                disabled={maxCount <= Number(count)}
                onClick={onClick(COUNTER_ACTION.add)}
                type="button"
            >
                <SvgIcon svg={plusIcon} />
            </button>
        </div>
    );
});
