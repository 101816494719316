import classNames from 'classnames';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {TPropsWithClassName} from 'core/types';
import {GalleryButtonSlide} from 'modules/slider/components/GalleryButtonSlide';
import {IProps as ISliderProps, Slider} from 'modules/slider/components/Slider';
import {GALLERY_MODE, SLIDER_DIRECTION, SPACE_BETWEEN} from 'modules/slider/constants';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {TSliderDirection} from 'modules/slider/types';
import React, {FC, useCallback, useContext, useId, useMemo, useRef} from 'react';
import TSwiper from 'swiper/types/swiper-class';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    fullWidthThumb?: boolean;
}

export const GalleryButtonSlider: FC<IProps> = ({className, fullWidthThumb}) => {
    const uid = useId();
    const {slidesList, mode, onClick} = useContext<IGallerySliderContextProps>(GallerySliderContext);

    const slider = useRef<TSwiper | undefined>();

    const onInitSlider = useCallback<NonNullable<ISliderProps['onInitialized']>>((swiper) => {
        slider.current = swiper;
    }, []);

    const isMobile = useIsMobile();

    const direction = useMemo<TSliderDirection>(() => {
        switch (mode) {
            case GALLERY_MODE.technical:
                return SLIDER_DIRECTION.horizontal;

            case GALLERY_MODE.fullscreen:
            case GALLERY_MODE.basic: {
                if (isMobile) {
                    return SLIDER_DIRECTION.horizontal;
                }
                return SLIDER_DIRECTION.vertical;
            }
            default:
                return SLIDER_DIRECTION.vertical;
        }
    }, [isMobile, mode]);

    const renderSpaceBetween = useMemo<number>(() => {
        if (mode === GALLERY_MODE.fullscreen && isMobile) {
            return SPACE_BETWEEN.mobileGalleryButtons;
        }

        if (mode === GALLERY_MODE.fullscreen || mode === GALLERY_MODE.basic) {
            return SPACE_BETWEEN.galleryButtons;
        }

        return SPACE_BETWEEN.galleryButtonsHorizontal;
    }, [isMobile, mode]);

    return (
        <Slider
            className={classNames(className, style.galleryButtonSlider, {
                [style[mode]]: Boolean(mode),
                [style.locked]: slider.current?.isLocked,
            })}
            direction={direction}
            isNavigation
            isSlideTo
            isSliderButtonsDefault
            onInitialized={onInitSlider}
            slidesPerView="auto"
            spaceBetween={renderSpaceBetween}
        >
            {slidesList.map((slide, idx) => (
                <GalleryButtonSlide
                    fullWidthThumb={fullWidthThumb}
                    idx={idx}
                    key={`${uid}--${slide.url}`}
                    media={slide}
                    onClick={onClick({
                        activeSlideIdx: idx,
                        isNeedOpenModal: false,
                        isVideoButton: 'youtube' === slide.type,
                    })}
                />
            ))}
        </Slider>
    );
};
