import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILoyaltyCardListState} from 'modules/loyalty-cards/types';

export const loyaltyCardListModuleName = 'loyalty-card-list';

const initialState: ILoyaltyCardListState = {
    loyaltyCardList: [],
};

export const loyaltyCardListSlice = createSlice({
    initialState,
    name: loyaltyCardListModuleName,
    reducers: {
        actionSetLoyaltyCardList(
            state: ILoyaltyCardListState,
            {payload}: PayloadAction<ILoyaltyCardListState['loyaltyCardList']>
        ) {
            state.loyaltyCardList = payload;
        },
    },
});

export const {
    actions: {actionSetLoyaltyCardList},
} = loyaltyCardListSlice;

export const {reducer: loyaltyCardsListReducer} = loyaltyCardListSlice;
