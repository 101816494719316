import classNames from 'classnames';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithChildrenRequire, TPropsWithClassName, ITypographyPublicProps {
    titleColor?: string;
}
export const HeaderElement: FC<IProps> = ({children, className, titleColor, variant = 'p', ...rest}) => {
    return (
        <Typography
            className={classNames(style.root, className)}
            style={titleColor ? {color: titleColor} : undefined}
            {...rest}
            variant={variant}
        >
            {children}
        </Typography>
    );
};
