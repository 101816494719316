import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TBrandListResponse, TBrandResponse, TPopularBrandListResponse} from 'modules/brand/models/BrandListResponse';

export class BrandService extends EvolutionHttpClient {
    public async getPopularBrandList(): Promise<TPopularBrandListResponse> {
        const {data} = await this.get<TPopularBrandListResponse>({
            url: '/brands/top',
        });

        return data;
    }

    public async getBrandList(): Promise<TBrandListResponse> {
        const {data} = await this.get<TBrandListResponse>({
            url: '/brands',
        });

        return data;
    }

    public async getBrand(id: string): Promise<TBrandResponse> {
        const {data} = await this.get<TBrandResponse>({
            url: `/brand/${id}`,
        });

        return data;
    }

    public async getBrandByCode(code: string): Promise<TBrandResponse> {
        const {data} = await this.get<TBrandResponse>({
            url: `/brand/by-code/${code}`,
        });

        return data;
    }
}
