import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {getProductPluralText} from 'core/helpers/plural';
import {TPropsWithClassName} from 'core/types';
import {CollectionShort, CollectionTop, MediaTypeEnum} from 'new-models';
import React, {FC, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    collectionItem: CollectionShort | CollectionTop;
}

export const CollectionItem: FC<IProps> = ({collectionItem, className}) => {
    const {image, title, products, code} = collectionItem;

    const renderContent = useMemo<ReactNode>(() => {
        if (!products?.products || 1 > products.products.length || !collectionItem.image) {
            return null;
        }

        const count = products.products.length;

        return (
            <li className={classNames(style.root, className)} data-skeleton-item>
                <PrevDefaultLink href={`/collections/${code}`}>
                    <MediaHarvester
                        className={style.img}
                        media={image}
                        options={generateMediaOptions({
                            resizeMapKey: 'homePageCollection',
                            type: MediaTypeEnum.ImageAdaptive,
                        })}
                    />
                    <div className={style.gradient} />
                    <div className={style.content}>
                        <Typography color="white100" element="h4" variant="h4">
                            {title}
                        </Typography>
                        <Typography className={style.link} color="white100" variant="link">
                            {count} {getProductPluralText(count)}
                        </Typography>
                    </div>
                </PrevDefaultLink>
            </li>
        );
    }, [className, code, collectionItem.image, image, products?.products, title]);

    return renderContent;
};
