import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {DISPLAYED_PRODUCTS_COUNT} from 'modules/checkout/constants';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {BasketItem} from 'new-models';
import {MouseEventHandler, useCallback, useEffect, useMemo, useState} from 'react';

interface IUseTotalProducts {
    onClick: MouseEventHandler<HTMLButtonElement>;
    mountProducts: BasketItem[];
    isHidden: boolean;
}

export const useTotalProducts = (products: BasketItem[]): IUseTotalProducts => {
    const dispatch = useAppDispatch();

    const [isHidden, setIsHidden] = useState<boolean>(false);

    useEffect(() => {
        setIsHidden(products.length > DISPLAYED_PRODUCTS_COUNT);
    }, [products.length]);

    const onClick = useCallback(() => dispatch(showModal(MODALS.CHECKOUT_TOTAL_PRODUCTS.name)), [dispatch]);

    const mountProducts = useMemo<BasketItem[]>(
        () => (isHidden ? products.slice(0, DISPLAYED_PRODUCTS_COUNT) : products),
        [isHidden, products]
    );

    return useMemo(
        () => ({
            isHidden,
            mountProducts,
            onClick,
        }),
        [isHidden, mountProducts, onClick]
    );
};
