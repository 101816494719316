import {SvgIcon} from 'components/SvgIcon';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {OrderPaymentStatusEnum} from 'new-models';
import checkIcon from 'public/icons/check.svg';
import closeIcon from 'public/icons/close.svg';
import React, {memo, ReactNode} from 'react';

import style from './style.module.scss';

interface IProps {
    statusType: OrderPaymentStatusEnum;
}

const REVIEW_STATUS_PAYMENT_TEXT: {[key in OrderPaymentStatusEnum.StatusEnum]: string} = {
    [OrderPaymentStatusEnum.StatusEnum.NOTPAID]: 'Не оплачено',
    [OrderPaymentStatusEnum.StatusEnum.PAID]: 'Оплачено',
};

const ICON_BEFORE_TEXT: {[key in OrderPaymentStatusEnum.StatusEnum]?: ReactNode} = {
    [OrderPaymentStatusEnum.StatusEnum.NOTPAID]: <SvgIcon svg={closeIcon} />,
    [OrderPaymentStatusEnum.StatusEnum.PAID]: <SvgIcon svg={checkIcon} />,
};

const getColor = (status: OrderPaymentStatusEnum.StatusEnum): ITypographyPublicProps['color'] => {
    switch (status) {
        case OrderPaymentStatusEnum.StatusEnum.NOTPAID:
            return 'error50';
        default:
            return 'success50';
    }
};

export const ReviewStatus = memo<IProps>(function ReviewStatus({statusType}) {
    if (!(statusType?.status && statusType.status in REVIEW_STATUS_PAYMENT_TEXT)) {
        return null;
    }

    return (
        <Typography className={style.root} color={getColor(statusType.status)} data-skeleton-item variant="p">
            {ICON_BEFORE_TEXT[statusType.status]}
            {REVIEW_STATUS_PAYMENT_TEXT[statusType.status]}
        </Typography>
    );
});
