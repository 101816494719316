import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import {IUseCartOrderMakingReturns} from 'modules/cart/hooks/useCartTotalInfo';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import React, {FC} from 'react';

import style from './style.module.scss';

export const TabBarCart: FC<IUseCartOrderMakingReturns> = (props) => {
    const {totalPrice, productCount, handleStartCheckout} = props;

    return (
        <div className={style.root}>
            <div className={style.content}>
                <Typography color="gray60" variant="p-medium">
                    {productCount ? `${productCount} ${getProductPluralText(productCount)}` : 'Товары не выбраны'}
                </Typography>
                <TotalPriceBlock isHiddeTotalTitle total={totalPrice} usedIn="cartResume" />
            </div>
            <Button disabled={!productCount} onClick={handleStartCheckout}>
                Оформить
            </Button>
        </div>
    );
};
