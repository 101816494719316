import classNames from 'classnames';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {disableScroll, enableScroll} from 'core/helpers';
import {TServerError} from 'core/types';
import {NotFound} from 'modules/search-popup/components/NotFound';
import {SearchPanelCategories} from 'modules/search-popup/components/SearchPanelCategories';
import {SearchPanelGoods} from 'modules/search-popup/components/SearchPanelGoods/SearchPanelGoods';
import {SearchPanelPopularRequests} from 'modules/search-popup/components/SearchPanelPopularRequests';
import {POPUP_TITLE} from 'modules/search-popup/constants';
import {useShowSearchPopupData} from 'modules/search-popup/hooks/useShowSearchPopupData';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {CategoryBase, ProductBase, ProductShort} from 'new-models';
import {TSearchSuggestions} from 'plugins/modules/search/types';
import chevronIcon from 'public/icons/chevron-right.svg';
import React, {FC, memo, useEffect, useMemo} from 'react';

import style from './style.module.scss';

const {categoriesTitle, goodsTitle, popularRequestsTitle} = POPUP_TITLE;

interface IProps {
    isLoading: boolean;
    errors?: TServerError[];
    searchCategories?: CategoryBase[];
    searchProducts: ProductShort[];
    searchSuggestions?: TSearchSuggestions;
    searchValue?: string;
    recommendations?: ProductBase[];
}

// eslint-disable-next-line complexity
export const SearchPanel: FC<IProps> = memo(function SearchPanel({
    searchCategories = [],
    searchProducts,
    searchSuggestions = [],
    searchValue,
    isLoading,
    recommendations,
}) {
    const {isShowProducts, isShowCategories, isShowPopularRequests} = useShowSearchPopupData({
        searchCategories,
        searchProducts,
        searchSuggestions,
        searchValue,
    });

    useEffect(() => {
        disableScroll();

        return () => enableScroll();
    }, []);

    const isData = useMemo(
        () => Boolean(searchCategories.length || searchProducts.length || searchSuggestions.length),
        [searchCategories.length, searchProducts.length, searchSuggestions.length]
    );

    const isFirstBlock = useMemo(
        () => Boolean(searchCategories.length || searchSuggestions.length),
        [searchCategories.length, searchSuggestions.length]
    );

    return (
        <SkeletonWrapper className={style.container} disabled={!isLoading}>
            <div className={classNames(style.panel)}>
                {!isData && !isLoading ? (
                    <div className={style.notFoundContent}>
                        <NotFound />
                        {recommendations && (
                            <SearchPanelGoods className={style.products} data={recommendations} title={goodsTitle} />
                        )}
                    </div>
                ) : (
                    <>
                        <div className={style.content}>
                            {isFirstBlock && (
                                <div className={style.firstBlock}>
                                    {isShowCategories && (
                                        <SearchPanelCategories data={searchCategories} title={categoriesTitle} />
                                    )}
                                    {isShowPopularRequests && (
                                        <SearchPanelPopularRequests
                                            list={searchSuggestions}
                                            title={popularRequestsTitle}
                                        />
                                    )}
                                </div>
                            )}

                            {isShowProducts && (
                                <SearchPanelGoods className={style.products} data={searchProducts} title={goodsTitle} />
                            )}
                        </div>
                        <Button
                            className={style.button}
                            data-skeleton-item
                            isStretched
                            rightIcon={<SvgIcon svg={chevronIcon} />}
                            type="submit"
                        >
                            Все результаты
                        </Button>
                    </>
                )}
            </div>
        </SkeletonWrapper>
    );
});
