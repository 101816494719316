import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {SvgIcon} from 'components/SvgIcon';
import {Tooltip, TRIGGER_ACTIONS} from 'components/Tooltip';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TPropsWithClassName} from 'core/types';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {ShieldTooltipContent} from 'modules/shields/components/ShieldTooltipContent';
import {SHIELD_SIZES, SHIELD_THEMES, SHIELDS_TYPES} from 'modules/shields/constants';
import {actionSetTooltipContent} from 'modules/shields/slice';
import {ITooltipContent, TShieldSize, TShieldTheme} from 'modules/shields/types';
import {Nameplate} from 'new-models';
import escVisualIcon from 'public/icons/ecs-visual.svg';
import React, {memo, MouseEventHandler, PropsWithChildren, ReactNode, useCallback, useMemo} from 'react';

import style from './style.module.scss';

// TODO: Пока не полностью приходят данные с бэка
const expressVisualizationTooltip = {
    description: 'Экспериментируйте и творите, чтобы создать свой уникальный стиль в ремонте.',
    link: 'Подробнее об услуге',
    title: 'Доступна примерка обоев и ламината',
};

// TODO: Исправить пропсы, когда полностью перейдем на корректную модель
export interface IShieldProps extends Partial<Nameplate>, TPropsWithClassName, PropsWithChildren {
    theme?: TShieldTheme;
    size?: TShieldSize;
    tooltip?: ITooltipContent;
    tooltipOverlay?: ReactNode;
    variant?: ITypographyPublicProps['variant'];
    compact?: boolean;
}

export const Shield = memo<IShieldProps>(function Shield({
    className,
    children,
    size = SHIELD_SIZES.medium,
    theme = SHIELD_THEMES.info,
    variant,
    tooltip,
    type,
    icon,
    title,
    bgColor,
    textColor,
    url,
    compact = false,
}) {
    const dispatch = useAppDispatch();

    const typographyVariant = useMemo<ITypographyPublicProps['variant']>(
        () => (SHIELD_SIZES.big === size ? 'p' : `p-${size}-strong`) as ITypographyPublicProps['variant'],
        [size]
    );

    // TODO: Пока не полностью приходят данные с бэка (Потом нужно будет убрать " || type === SHIELDS_TYPES.VIZ")
    const isNeedTooltip = useMemo<boolean>(() => Boolean(tooltip || type === SHIELDS_TYPES.VIZ), [tooltip, type]);

    const isAdaptiveCompact = useMemo<boolean>(() => type === SHIELDS_TYPES.VIZ, [type]);

    const renderIcon = useMemo<ReactNode>(() => {
        if (type === SHIELDS_TYPES.VIZ) {
            return <SvgIcon svg={escVisualIcon} />;
        }

        if (Boolean(icon?.url)) {
            return (
                <MediaHarvester
                    className={style.icon}
                    media={icon}
                    options={{iconStyle: {backgroundColor: textColor ?? undefined}}}
                />
            );
        }

        return null;
    }, [icon, textColor, type]);

    // TODO: Пока не полностью приходят данные и нет полного понимания по компоненту, ест то что с бэка или моки (Потом нужно будет убрать "type === SHIELDS_TYPES.VIZ ? <VisualizationIcon /> :" )
    const renderChildren = useMemo<ReactNode>(
        () =>
            // TODO: заменить 'supply-expect` на параметр из `enum`. Будет позже
            title ? (
                <>
                    {renderIcon}
                    <Typography className={style.text} variant={variant || typographyVariant}>
                        {title}
                    </Typography>
                </>
            ) : (
                <Typography className={style.text} variant={variant || typographyVariant}>
                    {children}
                </Typography>
            ),
        [children, renderIcon, title, typographyVariant, variant]
    );

    const handleShieldClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        if (Boolean(isNeedTooltip)) {
            // TODO: Пока не полностью приходят данные с бэка (Потом убрать "?? {...expressVisualizationTooltip, url}")
            dispatch(actionSetTooltipContent(tooltip ?? {...expressVisualizationTooltip, url}));
            dispatch(showModal(MODALS.SHIELD.name));
        }
    }, [dispatch, isNeedTooltip, tooltip, url]);

    return (
        <>
            <Tooltip
                className={style.shieldTooltip}
                // TODO: Пока не полностью приходят данные и нет полного понимания по компоненту, ест то что с бэка или моки (убрать {...expressVisualizationTooltip})
                overlay={<ShieldTooltipContent {...expressVisualizationTooltip} {...tooltip} url={url ?? undefined} />}
                trigger={isNeedTooltip ? TRIGGER_ACTIONS.hover : ''}
                zIndex={5}
            >
                <button
                    className={classNames(
                        style.shield,
                        style.shieldDesktop,
                        className,
                        {
                            [style[theme]]: Boolean(theme),
                            [style[size]]: Boolean(size),
                            [style.clickable]: isNeedTooltip,
                            [style.shieldCompactDesktop]: compact,
                        },
                        'shield-component'
                    )}
                    data-prevent-default-marker
                    data-stop-propagation-marker
                    style={{backgroundColor: bgColor ?? '', color: textColor ?? ''}}
                >
                    {renderChildren}
                </button>
            </Tooltip>
            <button
                className={classNames(
                    style.shield,
                    style.shieldMobile,
                    className,
                    {
                        [style[theme]]: Boolean(theme),
                        [style[size]]: Boolean(size),
                        [style.shieldCompactMobile]: isAdaptiveCompact,
                    },
                    'shield-component'
                )}
                data-prevent-default-marker
                data-stop-propagation-marker
                onClick={handleShieldClick}
                style={{backgroundColor: bgColor ?? '', color: textColor ?? ''}}
            >
                {renderChildren}
            </button>
        </>
    );
});
