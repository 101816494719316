import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectList} from 'modules/products/selectors';
import {getProductList} from 'modules/products/thunks';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import {ProductShort} from 'new-models';
import React, {FC, useEffect} from 'react';

interface IProps extends TPropsWithClassName {
    collectionCode?: string;
}

export const CollectionProductsSlider: FC<IProps> = ({className, collectionCode}) => {
    const dispatch = useAppDispatch();
    const collectionProducts = useAppSelector(selectList);

    useEffect(() => {
        if (!collectionCode) {
            return;
        }

        const searchParams = new URLSearchParams();
        searchParams.set('filter[collectionCode]', collectionCode);

        dispatch(getProductList(searchParams));
    }, [collectionCode, dispatch]);

    if (!collectionProducts.length) {
        return null;
    }

    return (
        <PageSectionBlock
            className={className}
            href={`/collections/${collectionCode}`}
            linkText="Все товары"
            title="Товары коллекции"
            withoutPaddingTop
        >
            <SliderWithBanner products={collectionProducts as ProductShort[]} />
        </PageSectionBlock>
    );
};
