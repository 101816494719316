import {TModalName} from 'modules/modals/types';

export const MODALS: Record<
    TModalName,
    {
        closeOnRouteChange?: boolean;
        name: TModalName;
        type?: 'modal' | 'portal';
    }
> = {
    ABOUT_ADDRESS_MAP: {
        closeOnRouteChange: true,
        name: 'ABOUT_ADDRESS_MAP',
    },
    ADAPTIVE_LOCATION_CHOOSE: {
        closeOnRouteChange: true,
        name: 'ADAPTIVE_LOCATION_CHOOSE',
    },
    ADD_PHONE: {
        closeOnRouteChange: false,
        name: 'ADD_PHONE',
    },
    ADDRESS_ADD: {
        closeOnRouteChange: true,
        name: 'ADDRESS_ADD',
    },
    ADDRESS_CHANGE: {
        closeOnRouteChange: true,
        name: 'ADDRESS_CHANGE',
    },
    ADDRESS_REMOVE: {
        closeOnRouteChange: true,
        name: 'ADDRESS_REMOVE',
    },
    AUTH_CONF_SENT: {
        closeOnRouteChange: true,
        name: 'AUTH_CONF_SENT',
    },
    AUTH_DIALOG: {
        closeOnRouteChange: true,
        name: 'AUTH_DIALOG',
    },
    AUTH_MENU: {
        closeOnRouteChange: true,
        name: 'AUTH_MENU',
    },
    AUTH_REGISTRATION: {
        closeOnRouteChange: true,
        name: 'AUTH_REGISTRATION',
    },
    AVAILABILITIES_TOOLTIP: {
        closeOnRouteChange: true,
        name: 'AVAILABILITIES_TOOLTIP',
    },
    BARCODE_SCANNER: {
        closeOnRouteChange: true,
        name: 'BARCODE_SCANNER',
    },
    BARCODE_SCANNER_INFO: {
        closeOnRouteChange: true,
        name: 'BARCODE_SCANNER_INFO',
    },
    BRAND_VIDEO_REVIEWS_MODAL: {
        closeOnRouteChange: true,
        name: 'BRAND_VIDEO_REVIEWS_MODAL',
    },
    CALCULATOR: {
        closeOnRouteChange: true,
        name: 'CALCULATOR',
    },
    CAMERA_NOT_ALLOWED: {
        closeOnRouteChange: true,
        name: 'CAMERA_NOT_ALLOWED',
    },
    CART_ANALOGS: {
        closeOnRouteChange: true,
        name: 'CART_ANALOGS',
    },
    CART_REMOVE: {
        closeOnRouteChange: true,
        name: 'CART_REMOVE',
    },
    CART_SHARING: {
        closeOnRouteChange: true,
        name: 'CART_SHARING',
    },
    CART_USER_ACTIONS: {
        closeOnRouteChange: true,
        name: 'CART_USER_ACTIONS',
    },
    CATALOG_MENU: {
        closeOnRouteChange: true,
        name: 'CATALOG_MENU',
    },
    CHECKOUT_MORE_DETAILS_SHOP: {
        closeOnRouteChange: true,
        name: 'CHECKOUT_MORE_DETAILS_SHOP',
    },
    CHECKOUT_SELECT_SHOP: {
        closeOnRouteChange: true,
        name: 'CHECKOUT_SELECT_SHOP',
    },
    CHECKOUT_TOTAL_PRODUCTS: {
        closeOnRouteChange: true,
        name: 'CHECKOUT_TOTAL_PRODUCTS',
    },
    CONFIRM_LOCATION: {
        closeOnRouteChange: false,
        name: 'CONFIRM_LOCATION',
    },
    DASHBOARD_ADD_EMAIL: {
        closeOnRouteChange: true,
        name: 'DASHBOARD_ADD_EMAIL',
    },
    DELIVERY_TOOLTIP: {
        closeOnRouteChange: true,
        name: 'DELIVERY_TOOLTIP',
    },
    EMAIL_RECEIPT: {
        closeOnRouteChange: true,
        name: 'EMAIL_RECEIPT',
    },
    FEEDBACK: {
        closeOnRouteChange: true,
        name: 'FEEDBACK',
    },
    FILTER_TOOLTIP: {
        closeOnRouteChange: true,
        name: 'FILTER_TOOLTIP',
    },
    INIT: {
        closeOnRouteChange: true,
        name: 'INIT',
    },
    LOCATION_CHOOSE: {
        closeOnRouteChange: true,
        name: 'LOCATION_CHOOSE',
    },
    MAIN_GALLERY: {
        closeOnRouteChange: true,
        name: 'MAIN_GALLERY',
    },
    MODAL_DIALOG: {
        closeOnRouteChange: true,
        name: 'MODAL_DIALOG',
    },
    ORDER_CANCEL: {
        closeOnRouteChange: true,
        name: 'ORDER_CANCEL',
    },
    ORDER_SHELF_LIFE: {
        closeOnRouteChange: true,
        name: 'ORDER_SHELF_LIFE',
    },
    OUT_OF_STOCK: {
        closeOnRouteChange: true,
        name: 'OUT_OF_STOCK',
    },
    PASSWORD_CREATE: {
        closeOnRouteChange: true,
        name: 'PASSWORD_CREATE',
    },
    PRODUCT_AVAILABILITY_INFO: {
        closeOnRouteChange: true,
        name: 'PRODUCT_AVAILABILITY_INFO',
    },
    PRODUCT_PICKUP: {
        closeOnRouteChange: true,
        name: 'PRODUCT_PICKUP',
    },
    PRODUCT_PRICE: {
        closeOnRouteChange: true,
        name: 'PRODUCT_PRICE',
    },
    PRODUCT_SET: {
        closeOnRouteChange: true,
        name: 'PRODUCT_SET',
    },
    PRODUCT_SHOP: {
        closeOnRouteChange: true,
        name: 'PRODUCT_SHOP',
    },
    PRODUCT_SUBSCRIPTION: {
        closeOnRouteChange: true,
        name: 'PRODUCT_SUBSCRIPTION',
    },
    PRODUCT_SUBSCRIPTION_AUTH: {
        closeOnRouteChange: true,
        name: 'PRODUCT_SUBSCRIPTION_AUTH',
    },
    PRODUCT_SUBSCRIPTION_CONF_SENT: {
        closeOnRouteChange: true,
        name: 'PRODUCT_SUBSCRIPTION_CONF_SENT',
    },
    PROFILE_CHANGE_EMAIL_MODAL: {
        closeOnRouteChange: true,
        name: 'PROFILE_CHANGE_EMAIL_MODAL',
    },
    PROFILE_CHANGE_PHONE_MODAL: {
        closeOnRouteChange: true,
        name: 'PROFILE_CHANGE_PHONE_MODAL',
    },
    PROFILE_PASSWORD_CHANGE: {
        closeOnRouteChange: true,
        name: 'PROFILE_PASSWORD_CHANGE',
    },
    PROFILE_REMOVE_ACCOUNT: {
        closeOnRouteChange: true,
        name: 'PROFILE_REMOVE_ACCOUNT',
    },
    RECOVERY_PASSWORD_BY_EMAIL: {
        closeOnRouteChange: true,
        name: 'RECOVERY_PASSWORD_BY_EMAIL',
    },
    SEARCH_MOBILE: {
        closeOnRouteChange: true,
        name: 'SEARCH_MOBILE',
    },
    SHIELD: {
        closeOnRouteChange: true,
        name: 'SHIELD',
    },
    SHOP_LIST: {
        closeOnRouteChange: true,
        name: 'SHOP_LIST',
    },
    SMS_REGISTRATION: {
        closeOnRouteChange: true,
        name: 'SMS_REGISTRATION',
    },
    SORT_MODAL: {
        closeOnRouteChange: true,
        name: 'SORT_MODAL',
    },
    SUBSCRIPTION_DIALOG: {
        closeOnRouteChange: true,
        name: 'SUBSCRIPTION_DIALOG',
    },
    SUPPORT_REDIRECT: {
        closeOnRouteChange: true,
        name: 'SUPPORT_REDIRECT',
    },
    TOOLTIP_MODAL: {
        closeOnRouteChange: true,
        name: 'TOOLTIP_MODAL',
    },
    USER_ALREADY_REGISTERED: {
        closeOnRouteChange: true,
        name: 'USER_ALREADY_REGISTERED',
    },
    USER_BLOCKED: {
        closeOnRouteChange: true,
        name: 'USER_BLOCKED',
    },
    USER_DOES_NOT_EXIST: {
        closeOnRouteChange: true,
        name: 'USER_DOES_NOT_EXIST',
    },
    WISH_LIST_MODAL: {
        closeOnRouteChange: true,
        name: 'WISH_LIST_MODAL',
    },
} as const;
