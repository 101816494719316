import classNames from 'classnames';
import {CategoryTag} from 'components/Tag/CategoryTag';
import {TPropsWithClassName} from 'core/types';
import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {CustomButtons} from 'modules/slider/components/CustomButtons';
import {Slider} from 'modules/slider/components/Slider';
import {SPACE_BETWEEN} from 'modules/slider/constants';
import useSwiper from 'modules/slider/hooks/useSwiper';
import Link from 'next/link';
import React, {FC, useRef} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    categoryNode?: ICategoryNode;
    withHidingInactiveNavBtn?: boolean;
    withSidesGradient?: boolean;
}

export const CategoryChildSlider: FC<IProps> = ({
    className,
    categoryNode,
    withHidingInactiveNavBtn = false,
    withSidesGradient = false,
}) => {
    const {swiper, onInit} = useSwiper();

    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);

    if (!categoryNode?.childrenCategory.length) {
        return null;
    }

    return (
        <div
            className={classNames(style.root, className, {
                [style.withHidingInactiveNavBtn]: withHidingInactiveNavBtn,
            })}
        >
            {!swiper?.isLocked && (
                <CustomButtons className={style.navigationActive} nextRef={nextRef} prevRef={prevRef} />
            )}
            <Slider
                className={classNames(style.categoryChildListSlider, {
                    [style.withSidesGradient]: !swiper?.isLocked && withSidesGradient,
                })}
                isSlideTo
                navigation={{
                    nextEl: nextRef.current,
                    prevEl: prevRef.current,
                }}
                onInit={onInit}
                slidesPerGroup={1}
                slidesPerView="auto"
                spaceBetween={SPACE_BETWEEN.galleryButtons}
            >
                {categoryNode.childrenCategory.map(({id, title: subTitle, code, productCount}) => (
                    <Link href={CategoryService.makeCategoryUrl(code, id)} key={`tags-item-${id}`} passHref>
                        <a>
                            <CategoryTag
                                description={productCount ? `(${productCount})` : undefined}
                                name={subTitle}
                                variant="elements-p"
                            />
                        </a>
                    </Link>
                ))}
            </Slider>
        </div>
    );
};
