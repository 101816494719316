import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {IHttpClientResponse, TResponse} from 'core/types';
import {IDefaultThunkOutput} from 'modules/authentication/types';
import {IProfileResponse} from 'modules/user/models/ProfileResponse';
import {actionSetUser} from 'modules/user/slice';
import {Profile} from 'new-models';

export const getUser: TAppThunk<void> = async (dispatch, _, {userService}) => {
    const data = await dispatch(
        requestAction<TResponse<IProfileResponse>>({
            requestCb: () => userService.getUserData(),
            type: actionSetUser.type,
        })
    );

    dispatch(actionSetUser(data.profile));
};

export const patchProfile =
    (profile: Partial<Profile>): TAppThunk<IDefaultThunkOutput<IProfileResponse>> =>
    async (dispatch, _, {userService}) => {
        const data = await dispatch(
            requestAction<IHttpClientResponse<TResponse<IProfileResponse>>>({
                requestCb: () => userService.updateProfile(profile),
                type: actionSetUser.type,
            })
        );

        dispatch(actionSetUser(data.data.profile));

        return {success: true};
    };
