import classNames from 'classnames';
import {TabProvider} from 'components/TabProvider';
import React, {FormHTMLAttributes, forwardRef, ReactElement, Ref} from 'react';

import style from './style.module.scss';

export interface IProps<T extends string> extends FormHTMLAttributes<HTMLFormElement> {
    name: T;
}

const TabsGroupOriginal = <T extends string>(
    {className, children, name, ...restFormAttributes}: IProps<T>,
    ref: Ref<HTMLFormElement>
) => {
    return (
        <TabProvider<T> name={name}>
            <form {...restFormAttributes} className={classNames(style.tabsGroup, className)} ref={ref}>
                {children}
            </form>
        </TabProvider>
    );
};

export const TabsGroup = forwardRef(TabsGroupOriginal) as <T extends string>(
    props: IProps<T> & {ref?: Ref<HTMLFormElement>}
) => ReactElement;
