import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {actionSetActiveIdx} from 'modules/slider/slice';
import {useEffect} from 'react';

export const useSetActiveFirstSlide = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(actionSetActiveIdx(0));
        };
    }, [dispatch]);
};
