import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {MediaTypeEnum, Promo} from 'new-models';
import {ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps {
    banner: Promo;
    linkText: string;
    link?: string;
}

export const LeftSideBlock: FC<IProps> = ({linkText, banner, link}) => {
    const {media, title, isLight, link: bannerLink} = banner;

    const href = link ?? bannerLink.value;

    const renderContent = useMemo<ReactNode>(
        () => (
            <>
                <MediaHarvester
                    className={style.image}
                    media={media}
                    options={generateMediaOptions({
                        resizeMapKey: 'productSliderBanner',
                        type: MediaTypeEnum.ImageAdaptive,
                    })}
                />
                <div className={style.leftSideContent}>
                    {title && (
                        <Typography
                            className={style.title}
                            color={isLight ? 'gray100' : 'white100'}
                            element="h4"
                            variant="h4"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: title,
                                }}
                            />
                        </Typography>
                    )}
                    <Typography
                        className={classNames(style.link, {[style.light]: isLight ? 'dark' : 'light'})}
                        variant="link"
                    >
                        {linkText}
                    </Typography>
                </div>
            </>
        ),
        [isLight, linkText, media, title]
    );

    return href ? <PrevDefaultLink href={href}>{renderContent}</PrevDefaultLink> : renderContent;
};
