import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TWishListDetailResponse, TWishListsResponse} from 'modules/wish-list/models/WishListsResponse';
import {IWishListState} from 'modules/wish-list/types';
export const wishListModuleName = 'wishList';

const initialState: IWishListState = {
    activeWishList: null,
    addedProductQuantity: 1,
    isAfterCalculation: false,
    isProductAdded: false,
    selectedProducts: null,
    wishLists: [],
};

export const wishListSlice = createSlice({
    initialState,
    name: wishListModuleName,
    reducers: {
        actionDecreaseAddedProductQuantity(state: IWishListState) {
            if (1 >= state.addedProductQuantity) {
                return;
            }
            state.addedProductQuantity -= 1;
        },
        actionIncreaseAddedProductQuantity(state: IWishListState) {
            state.addedProductQuantity += 1;
        },
        actionResetAddedProductQuantity(state: IWishListState) {
            state.addedProductQuantity = 1;
        },
        actionSetActiveWishList(state: IWishListState, {payload}: PayloadAction<TWishListDetailResponse['data']>) {
            state.activeWishList = payload.wishList;
        },
        actionSetAddedProductQuantity(
            state: IWishListState,
            {payload}: PayloadAction<IWishListState['addedProductQuantity']>
        ) {
            state.addedProductQuantity = payload;
        },
        actionSetIsAfterCalculation(
            state: IWishListState,
            {payload}: PayloadAction<IWishListState['isAfterCalculation']>
        ) {
            state.isAfterCalculation = payload;
        },
        actionSetIsProductAdded(state: IWishListState, {payload}: PayloadAction<IWishListState['isProductAdded']>) {
            state.isProductAdded = payload;
        },
        actionSetSelectedProducts(state: IWishListState, {payload}: PayloadAction<IWishListState['selectedProducts']>) {
            state.selectedProducts = payload;
        },
        actionSetWishLists(state: IWishListState, {payload}: PayloadAction<TWishListsResponse['data']>) {
            state.wishLists = payload.wishLists;
        },
    },
});

export const {
    actions: {
        actionDecreaseAddedProductQuantity,
        actionIncreaseAddedProductQuantity,
        actionResetAddedProductQuantity,
        actionSetAddedProductQuantity,
        actionSetSelectedProducts,
        actionSetIsAfterCalculation,
        actionSetIsProductAdded,
        actionSetWishLists,
        actionSetActiveWishList,
    },
} = wishListSlice;

export const {reducer: wishListReducer} = wishListSlice;
