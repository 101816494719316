import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {CollectionItem} from 'modules/collections/components/CollectionItem';
import {CollectionShort, CollectionTop} from 'new-models';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    list: CollectionShort[] | CollectionTop[];
}

export const CollectionsList: FC<IProps> = ({list, className}) => {
    if (!list?.length) {
        return null;
    }

    return (
        <ul className={classNames(style.root, className)}>
            {list.map((item) => (
                <CollectionItem collectionItem={item} key={item.id} />
            ))}
        </ul>
    );
};
