import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import closeIcon from 'public/icons/close.svg';
import searchIcon from 'public/icons/search.svg';
import React, {InputHTMLAttributes, memo, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string;
    onReset?: () => void;
    inputSize?: 'large' | 'medium';
}

export const SearchInput = memo<IProps>(function SearchInput({
    className,
    placeholder,
    onChange,
    onReset,
    value,
    inputSize = 'medium',
    ...restInputAttributes
}) {
    const renderAction = useMemo<ReactNode>(() => {
        if (!value.length) {
            return (
                <span className={style.searchIcon}>
                    <SvgIcon svg={searchIcon} />
                </span>
            );
        }

        return (
            <button className={style.labelCross} onClick={onReset} type="reset">
                <SvgIcon svg={closeIcon} />
            </button>
        );
    }, [onReset, value.length]);

    return (
        <label className={classNames(style.label, className)}>
            <input
                {...restInputAttributes}
                className={classNames(style.input, style[inputSize])}
                onChange={onChange}
                placeholder={placeholder}
                type="search"
                value={value}
            />
            {renderAction}
        </label>
    );
});
