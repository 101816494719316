import {PayloadAction} from '@reduxjs/toolkit';
import {createFilterActions} from 'plugins/modules/filter/actions';
import {createListingActions} from 'plugins/modules/listing/actions';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {TSearchState} from 'plugins/modules/search/types';
import {createSortingActions} from 'plugins/modules/sorting/actions';

export function createSearchActions<T extends TSearchState>() {
    return {
        actionClearSearchData(state: T) {
            state.categoryList = undefined;
            state.redirect = null;
            state.list = [];
        },

        actionSetRedirect(state: T, {payload}: PayloadAction<T['redirect']>) {
            state.redirect = payload;
        },

        actionSetSearchCategoryList(state: T, {payload}: PayloadAction<T['categoryList']>) {
            state.categoryList = payload;
        },

        actionSetSearchQuery(state: T, {payload}: PayloadAction<T['query']>) {
            state.query = payload;
        },

        actionSetSearchRecommendationsList(state: T, {payload}: PayloadAction<T['recommendationsList']>) {
            state.recommendationsList = payload;
        },

        actionSetSearchSuggestions(state: T, {payload}: PayloadAction<T['suggestions']>) {
            state.suggestions = payload;
        },

        ...createPaginationActions(),
        ...createFilterActions(),
        ...createSortingActions(),
        ...createListingActions(),
    };
}
