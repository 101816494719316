import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {selectBannerListByPlace} from 'modules/common/selectors';
import {Category, Link as TLink, MediaTypeEnum, PromoPlace} from 'new-models';
import Link from 'next/link';
import React, {memo, useCallback} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

interface IProps extends TPropsWithClassName {
    categoryCode?: Category['code'];
}

interface IBannerProps extends TLink, TPropsWithChildrenRequire {}
const Banner: FC<IBannerProps> = (props) => {
    const {value, children} = props;
    if (!value) {
        return <div>{children}</div>;
    }

    return (
        <Link href={value}>
            <a>{children}</a>
        </Link>
    );
};

export const CategoryBanners = memo<IProps>(function CategoryBanners({className, categoryCode}) {
    const categoryBannersList = useAppSelector(selectBannerListByPlace((categoryCode as PromoPlace) ?? 'catalog'));

    const generateBannerMediaOptions = useCallback(
        (idx: number) =>
            generateMediaOptions({
                resizeMapKey: idx % 2 ? 'navigationMainBanner' : 'navigationSecondaryBanner',
                type: MediaTypeEnum.ImageAdaptive,
            }),
        []
    );

    return Boolean(categoryBannersList.length) ? (
        <Grid as="ul" className={classNames(className, style.bannersList)}>
            {categoryBannersList.map(({media, link, id, title, description}, idx) => (
                <li
                    className={classNames(columnStyle, style.bannersItem)}
                    data-skeleton-item
                    key={`${id}_${media.type}`}
                >
                    <Banner {...link}>
                        <MediaHarvester media={media} options={generateBannerMediaOptions(idx)} />
                        <div className={style.content}>
                            <Typography color="white100" element="h5" variant="h5">
                                {title}
                            </Typography>
                            <Anchor href={link.value} icon="off" mode="light" size="large" theme="standalone">
                                {description}
                            </Anchor>
                        </div>
                    </Banner>
                </li>
            ))}
        </Grid>
    ) : null;
});
