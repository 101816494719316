import {IEntity, INormalizedData} from 'core/types';

export const getValueByKey =
    <T extends IEntity>(key: keyof T) =>
    (item: T) =>
        String(item[key]);

export const mapListItemToData =
    <T extends IEntity>(key: keyof T) =>
    (acc: Record<PropertyKey, T>, item: T): Record<PropertyKey, T> => ({
        ...acc,
        [getValueByKey(key)(item)]: item,
    });

export const normalizeList = <T extends IEntity>(list: T[], key: keyof T = 'id'): INormalizedData<T> => {
    return {
        data: list.reduce(mapListItemToData<T>(key), {}),
        list: list.map(getValueByKey<T>(key)),
    };
};
