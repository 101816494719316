import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TShopListResponse} from 'modules/shops/models/ShopListResponse';

export class ShopService extends EvolutionHttpClient {
    public async getStores(): Promise<TShopListResponse> {
        const {data} = await this.get<TShopListResponse>({
            url: '/stores',
        });

        return data;
    }
}
