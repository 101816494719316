import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {InputCaption} from 'components/InputCaption';
import {TPropsWithClassName} from 'core/types';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {CheckoutSection} from 'modules/checkout/components/CheckoutSection';
import {DELIVERY_ID_FIELD_NAME} from 'modules/checkout/constants/fields';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {DeliveryMethodShort} from 'new-models';
import React, {ChangeEventHandler, memo} from 'react';

import {CheckoutPointSelection} from '../CheckoutPointSelection';
import {CheckoutSegmentedRadioWIthNote} from '../CheckoutSegmentedRadioWIthNote';

const columnStyle = getGridColumnStyles();
const itemColumnStyle = getGridColumnStyles({md: 4});

import {useMountDeliveryNoteText} from 'modules/checkout/hooks/useMountDeliveryNoteText';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    deliveryMethods: DeliveryMethodShort[];
    onChange: ChangeEventHandler<HTMLInputElement>;
    deliveryId?: string;
}

export const CheckoutDeliveryForm = memo<IProps>(function CheckoutDeliveryForm({
    className,
    deliveryMethods,
    onChange,
    deliveryId,
}) {
    const {getFieldMeta, submitCount} = useCheckoutFormikContext();
    const fieldMeta = getFieldMeta(DELIVERY_ID_FIELD_NAME);

    const isOnceSubmitted = Boolean(submitCount);

    const mountNoteText = useMountDeliveryNoteText();
    return (
        <CheckoutSection className={className} title="Способ получения">
            <Grid className={style.grid} gridColumnsCount={8}>
                <Grid as="ul" className={classNames(style.deliveryTypeList, columnStyle)} gridColumnsCount={8}>
                    {deliveryMethods?.map(({id, title, isAvailable}) => (
                        <li className={classNames(style.deliveryItem, itemColumnStyle)} key={id}>
                            <Field name={DELIVERY_ID_FIELD_NAME} type="radio" value={id}>
                                {({field, meta}: FieldProps) => (
                                    <CheckoutSegmentedRadioWIthNote
                                        {...field}
                                        disabled={!isAvailable}
                                        error={meta.touched || isOnceSubmitted ? Boolean(meta.error) : undefined}
                                        label={title}
                                        noteText={mountNoteText(id)}
                                        onChange={onChange}
                                    />
                                )}
                            </Field>
                        </li>
                    ))}
                </Grid>
                {(isOnceSubmitted || fieldMeta.touched) && fieldMeta.error && (
                    <InputCaption className={columnStyle} errorText={fieldMeta.error} />
                )}
                <CheckoutPointSelection className={columnStyle} deliveryId={deliveryId} />
            </Grid>
        </CheckoutSection>
    );
});
