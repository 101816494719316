import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    title?: string;
}

export const ModalTitle: FC<IProps> = ({title, className}) => {
    if (!title) {
        return null;
    }

    return (
        <Typography className={classNames(style.root, className)} element="div" variant="h4">
            {title}
        </Typography>
    );
};
