import classNames from 'classnames';
import {ISelectOption} from 'components/InputSelect';
import {SvgIcon} from 'components/SvgIcon';
import checkIcon from 'public/icons/check.svg';
import React, {FC, useCallback} from 'react';
import {ActionMeta, components} from 'react-select';
import Select from 'react-select';
import {OptionProps} from 'react-select/dist/declarations/src/components/Option';
import {StateManagerProps} from 'react-select/dist/declarations/src/useStateManager';

import style from './style.module.scss';
interface IDateSelectProps {
    handleChange: (value: ISelectOption<any>) => void;
    options: ISelectOption<number>[];
}

export const DateSelect: FC<IDateSelectProps & StateManagerProps> = ({options, handleChange, ...props}) => {
    const handleSelectChange = useCallback(
        (newValue: ISelectOption<unknown>) => handleChange?.(newValue),
        [handleChange]
    );

    const Option = (optionProps: OptionProps) => {
        if (optionProps.isSelected) {
            return (
                <components.Option {...optionProps}>
                    {optionProps.children} <SvgIcon svg={checkIcon} />
                </components.Option>
            );
        }

        return <components.Option {...optionProps}>{optionProps.children}</components.Option>;
    };

    return (
        <Select
            {...props}
            className={classNames(style.dateSelect, 'react-select')}
            classNamePrefix="react-select"
            components={{Option}}
            isSearchable={false}
            onChange={handleSelectChange as (newValue: unknown, actionMeta: ActionMeta<unknown>) => void}
            options={options}
        />
    );
};
