import {Toast} from 'components/Toast';
import {WISH_LISTS_LINK} from 'core/constants';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {PRODUCT_ADDED_TOAST_BUTTON_TEXT, PRODUCT_ADDED_TOAST_TEXT} from 'modules/wish-list/constants';
import {selectActiveWishList, selectIsProductAdded} from 'modules/wish-list/selectors';
import {actionSetIsProductAdded} from 'modules/wish-list/slice';
import React, {useEffect} from 'react';
import {toast} from 'react-toastify';

export const useWishListNotification = () => {
    const dispatch = useAppDispatch();
    const isProductAdded = useAppSelector(selectIsProductAdded);
    const activeWishList = useAppSelector(selectActiveWishList);

    // TODO: возможно лучше, показывать тост прямо в санке, и избавится от избыточного поля в состоянии

    useEffect(() => {
        if (isProductAdded && activeWishList?.title) {
            toast(
                <Toast
                    buttonText={PRODUCT_ADDED_TOAST_BUTTON_TEXT}
                    hasIcon={false}
                    isNeedCloseButton
                    path={WISH_LISTS_LINK}
                    text={`${PRODUCT_ADDED_TOAST_TEXT}«${activeWishList.title}»`}
                    theme="info"
                />,
                {
                    className: 'notification',
                }
            );
        }

        return () => {
            dispatch(actionSetIsProductAdded(false));
        };
    }, [isProductAdded, activeWishList, dispatch]);
};
