import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {FormikProps} from 'formik/dist/types';
import {CHECKOUT_INITIAL_VALUES} from 'modules/checkout/constants';
import {actionSetSelectedPickupPointId} from 'modules/checkout/slice';
import {ICheckoutValues} from 'modules/checkout/types';
import {useUserLocation} from 'modules/locations/hooks/useUserLocation';
import {Location} from 'new-models';
import {MutableRefObject, useEffect, useRef} from 'react';

export const useResetCheckoutForm = (formRef: MutableRefObject<FormikProps<ICheckoutValues> | null>) => {
    const dispatch = useAppDispatch();
    const location = useUserLocation();
    const locationRef = useRef<Location | null>(location);

    useEffect(() => {
        if (location?.id !== locationRef.current?.id) {
            formRef.current?.setFormikState((prevState) => ({
                ...prevState,
                values: {
                    ...CHECKOUT_INITIAL_VALUES,
                    comment: prevState.values.comment,
                    customer: prevState.values.customer,
                },
            }));
            locationRef.current = location;
            dispatch(actionSetSelectedPickupPointId(null));
        }
    }, [dispatch, formRef, location]);
};
