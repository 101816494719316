import {AbstractUrlService, TFillStrategy} from 'core/abstract/AbstractUrlService';
import {BaseUrlParam} from 'core/base/BaseUrlParam';
import {SEPARATORS_LIST} from 'core/constants';
import {contextDecorator} from 'core/decorators';
import Router from 'next/router';

export type TUrlParam = {
    id: string;
    value?: string;
};

export class BaseUrlService extends AbstractUrlService {
    protected paramsList: Record<string, null | string> = {};

    protected options: AbstractUrlService['options'];
    protected onParamsChange: AbstractUrlService['onParamsChange'];
    protected query: AbstractUrlService['query'];

    // не стандартные фильтры, например фильтр по магазину имеет другую форму записи
    public static readonly COMMON_PARAM_NAMES: Record<string, BaseUrlParam> = {
        ...AbstractUrlService.COMMON_PARAM_NAMES,
    };

    public static readonly RESET_PARAM = 'page';

    constructor(
        query: AbstractUrlService['query'],
        onParamsChange: AbstractUrlService['onParamsChange'],
        options: AbstractUrlService['options']
    ) {
        super();

        this.options = options;
        this.onParamsChange = onParamsChange;
        this.query = query;
    }

    public async hydrate() {
        await this.onParamsChange(this.format());
    }

    @contextDecorator('client')
    public async addParam(...params: TUrlParam[]) {
        const prevQuery = {...Router.query};

        const routerParamObject: Record<string, string> = params.reduce<Record<string, string>>(
            (routerObject, {id, value = 'Y'}) => {
                this.paramsList[id] = value;

                return {
                    ...routerObject,
                    [id]: value,
                };
            },
            {}
        );

        const isNeedResetParams = !Object.keys(this.paramsList).some((id) => BaseUrlService.RESET_PARAM === id);

        if (isNeedResetParams) {
            delete Router.query[BaseUrlService.RESET_PARAM];
            delete prevQuery[BaseUrlService.RESET_PARAM];
        }

        await BaseUrlService.setQueryParams(routerParamObject);

        await this.onParamsChange(this.format());
    }

    @contextDecorator('client')
    public async removeParam(...params: TUrlParam[]) {
        params.forEach(({id, value}) => {
            const newParamsList = this.paramsList[id]
                ?.split(SEPARATORS_LIST.COMMA_AND_SPACE)
                .filter((v) => Boolean(value) && v !== value)
                .join(SEPARATORS_LIST.COMMA_AND_SPACE);

            if (newParamsList?.length) {
                this.paramsList[id] = newParamsList;
                Router.query[id] = newParamsList;
                return;
            }

            this.paramsList[id] = null;
            delete Router.query[id];
        });

        const isNeedResetParams =
            Boolean(Object.keys(this.paramsList)?.length) &&
            !Object.keys(this.paramsList).some((id) => BaseUrlService.RESET_PARAM === id);

        if (isNeedResetParams) {
            delete Router.query[BaseUrlService.RESET_PARAM];
        }

        await Router.push(
            {
                query: Router.query,
            },
            undefined,
            {shallow: true}
        );

        await this.onParamsChange(this.format());
    }

    protected format(): URLSearchParams {
        Object.keys(this.paramsList).forEach((appliedParamId) => {
            if (null === this.paramsList[appliedParamId]) {
                this.query.delete(appliedParamId);
                return;
            }

            this.query.set(appliedParamId, this.paramsList[appliedParamId]!);
        });

        const isNeedResetParams =
            Boolean(Object.keys(this.paramsList)?.length) &&
            !Object.keys(this.paramsList).some((id) => BaseUrlService.RESET_PARAM === id);

        if (isNeedResetParams) {
            this.query.set(BaseUrlService.RESET_PARAM, '1');
        }

        return this.query;
    }

    protected fill(fields: BaseUrlParam[], strategy: TFillStrategy) {
        Object.keys(this.options.query).forEach((queryKey: string) => {
            if ('include' === strategy) {
                if (fields.map(String).includes(queryKey)) {
                    this.paramsList[queryKey] = this.options.query[queryKey] as string;
                }
            }

            if ('exclude' === strategy) {
                if (!fields.map(String).includes(queryKey)) {
                    this.paramsList[queryKey] = this.options.query[queryKey] as string;
                }
            }
        });
    }

    public static async setQueryParams(queryParams: Record<string, string>) {
        await Router.push(
            {
                query: {
                    ...Router.query,
                    ...queryParams,
                },
            },
            undefined,
            {
                shallow: true,
            }
        );
    }
}
