import classNames from 'classnames';
import {DatePickerCalendar} from 'components/Datepicker';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Input} from 'components/Input';
import {RadioButton} from 'components/RadioButton';
import {formatDateWithLocal} from 'core/helpers/date';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {GENDER_FIELDS} from 'modules/user/constants';
import React, {memo, useCallback} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

export const IndividualDataMain = memo(function IndividualDataMain() {
    const callbackOnChange = useCallback(
        ({field, form}: FieldProps<string>) =>
            (value: Date | null) => {
                if (!value) {
                    return;
                }
                form.setValues((prevState: Record<string, string>) => ({
                    ...prevState,
                    [field.name]: formatDateWithLocal(value, 'yyyy-MM-dd'),
                }));
            },
        []
    );

    return (
        <Grid as="fieldset" className={classNames(style.fieldSet, columnStyle)} data-skeleton-item>
            <Field name="lastname">
                {({field, meta}: FieldProps) => (
                    <Input className={columnStyle} isBorder labelText="Фамилия *" {...field} errorText={meta.error} />
                )}
            </Field>
            <Field name="firstname">
                {({field, meta}: FieldProps) => (
                    <Input className={columnStyle} isBorder labelText="Имя *" {...field} errorText={meta.error} />
                )}
            </Field>
            <Field name="birthday">
                {({field, form, meta}: FieldProps<string>) => {
                    const handleChange = callbackOnChange({field, form, meta});
                    return (
                        <DatePickerCalendar
                            {...field}
                            captionText="Изменить дату можно будет через год"
                            dateFormat="yyyy-MM-dd"
                            dateFormatCalendar="MMMM"
                            labelText="День рождения"
                            mask="9999-99-99"
                            maxDate={new Date()}
                            onChange={handleChange}
                            placeholderText="ГГГГ-ММ-ДД"
                            rootClassName={columnStyle}
                            selected={field.value ? new Date(field.value) : null}
                        />
                    );
                }}
            </Field>
            <div className={style.mainFormBlockGender}>
                {GENDER_FIELDS.map(({gender, value}) => (
                    <Field name="gender">
                        {({field, meta}: FieldProps) => (
                            <RadioButton
                                defaultChecked={meta.value === value}
                                {...field}
                                isLabel
                                label={gender}
                                value={value}
                            />
                        )}
                    </Field>
                ))}
            </div>
        </Grid>
    );
});
