import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ILocationsState} from 'modules/locations/types';
import {HYDRATE} from 'next-redux-wrapper';

export const locationsModuleName = 'locations';

const initialState: ILocationsState = {
    isLocationSelected: false,
    locationsList: null,
    userLocation: null,
};

export const locationsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[locationsModuleName];
        },
    },
    initialState,
    name: locationsModuleName,
    reducers: {
        actionSetIsLocationSelected(state, {payload}: PayloadAction<ILocationsState['isLocationSelected']>) {
            state.isLocationSelected = payload;
        },

        actionSetLocationsList(state, {payload}: PayloadAction<ILocationsState['locationsList']>) {
            state.locationsList = payload;
        },

        actionSetUserLocation(state, {payload}: PayloadAction<ILocationsState['userLocation']>) {
            state.userLocation = payload;
        },
    },
});

export const {
    actions: {actionSetLocationsList, actionSetUserLocation, actionSetIsLocationSelected},
} = locationsSlice;

export const {reducer: locationsReducer} = locationsSlice;
