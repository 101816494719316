import {ConfirmLocationContent} from 'modules/locations/components/ConfirmLocationContent';
import {MODALS} from 'modules/modals/constants';
import {Portal} from 'modules/portal/components/Portal';
import React, {FC} from 'react';

const ConfirmLocationMiniModal: FC = () => {
    return (
        <Portal name={MODALS.CONFIRM_LOCATION.name} willMount>
            <ConfirmLocationContent />
        </Portal>
    );
};

export default ConfirmLocationMiniModal;
