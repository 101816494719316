import classNames, {Argument} from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {FC, useMemo} from 'react';

import style from './style.module.scss';

export interface IInputCaptionPublicProps {
    captionText?: string;
    errorText?: string;
    successText?: string;
}

export interface IProps extends IInputCaptionPublicProps, TPropsWithClassName {
    disabled?: boolean;
}

export const InputCaption: FC<IProps> = ({className, captionText, errorText, disabled, successText}) => {
    const renderText = useMemo<string>(() => {
        if (errorText) {
            return errorText;
        }

        if (successText) {
            return successText;
        }

        return captionText || '';
    }, [captionText, errorText, successText]);

    const renderClassName = useMemo<Argument>(() => {
        if (errorText) {
            return {
                [style.inputCaptionError]: errorText,
                [style.inputCaptionDisabled]: disabled,
            };
        }

        if (successText) {
            return {
                [style.inputCaptionSuccess]: successText,
                [style.inputCaptionDisabled]: disabled,
            };
        }

        return {
            [style.inputCaptionDisabled]: disabled,
        };
    }, [disabled, errorText, successText]);

    return (
        <Typography className={classNames(style.inputCaption, className, renderClassName)} variant="badge">
            {renderText}
        </Typography>
    );
};
