import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {usePriceAndBonuses} from 'modules/price-n-bonuses/hooks/usePriceAndBonuses';
import {TUsedIn} from 'modules/price-n-bonuses/types';
import {ProductPrice} from 'new-models';
import React, {FC, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    usedIn: TUsedIn;
    price: ProductPrice;
    isProductAvailable: boolean;
    withUnit?: boolean;
}

export const Main: FC<IProps> = ({className, price, usedIn, isProductAvailable, withUnit}) => {
    const {isSale} = usePriceAndBonuses();
    const currentPrice = useMemo(() => {
        return price?.mainDiscount ? price?.mainDiscount?.price : price?.main.price;
    }, [price]);

    return (
        <div
            className={classNames(style.main, 'price-n-bonuses-main', {
                [style[usedIn]]: usedIn,
                [style.vertical]: usedIn !== USED_IN.mainOffer && price?.mainDiscount,
            })}
        >
            <div className={classNames(className, style.container)}>
                <Typography
                    className={classNames(style.mainPrice, {
                        [style[usedIn]]: usedIn,
                        [style.isNotAvailable]: !isProductAvailable,
                    })}
                >
                    {formatPrice((currentPrice / PRICE_DENOMINATOR).toString(), {
                        maximumFractionDigits: 2,
                    })}
                </Typography>
                <div
                    className={classNames(style.unitContainer, {
                        [style.isNotAvailable]: !isProductAvailable,
                        [style[usedIn]]: usedIn,
                    })}
                >
                    <Typography
                        className={classNames(style.currency, {
                            [style[usedIn]]: usedIn,
                        })}
                    >
                        ₽
                    </Typography>
                    {withUnit && (
                        <Typography
                            className={classNames(style.unit, {
                                [style[usedIn]]: usedIn,
                            })}
                        >
                            /{price?.mainDiscount ? price?.mainDiscount?.unit : price?.main?.unit}
                        </Typography>
                    )}
                </div>
            </div>
            {isSale(price?.mainDiscount?.price) && (
                <div
                    className={classNames(style.bottomContainer, {
                        [style[usedIn]]: usedIn,
                    })}
                >
                    <Typography
                        className={classNames(style.basePrice, {
                            [style[usedIn]]: usedIn,
                        })}
                    >
                        {formatPrice((price?.main.price! / PRICE_DENOMINATOR).toString(), {
                            maximumFractionDigits: 2,
                        })}
                    </Typography>
                    <Typography
                        className={classNames(style.currencyBottom, {
                            [style[usedIn]]: usedIn,
                        })}
                    >
                        ₽
                    </Typography>
                </div>
            )}
        </div>
    );
};
