import {ClientHttpException} from 'core/exceptions/ClientHttpException';
import {ServerHttpException} from 'core/exceptions/ServerHttpException';
import {WrongEnvException} from 'core/exceptions/WrongEnvException';
import {isBrowserContext} from 'core/helpers';
import {TAbstractCall, TEnv, TInterceptCallback, TJson, TPreflightCallback} from 'core/types';
import {IHttpClientResponse, TDecorator, THttpMethod, TRequestParameters} from 'core/types';

export type TContext = 'server' | 'client';
export function contextDecorator(context: TContext): Function {
    return function (_: unknown, property: string, descriptor: PropertyDescriptor) {
        const newDescriptor = Object.assign({}, descriptor);

        newDescriptor.value = function (): unknown {
            if ('server' === context && isBrowserContext()) {
                throw new Error(`Method "${property}" not allowed on the client side!`);
            }

            if ('client' === context && !isBrowserContext()) {
                throw new Error(`Method "${property}" not allowed on the server side!`);
            }

            return descriptor.value.apply(this, arguments);
        };

        return newDescriptor;
    };
}

export function exception(method: THttpMethod): TDecorator {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return function (_: unknown, __: string, descriptor: PropertyDescriptor) {
        const clonedDescriptor = Object.assign({}, descriptor);

        clonedDescriptor.value = async function (...args: unknown[]): Promise<IHttpClientResponse> {
            const response: IHttpClientResponse = await descriptor.value.apply(this, args);

            /**
             * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
             */
            if (400 <= response.status && 499 >= response.status) {
                throw new ClientHttpException(response.status, response, {
                    method,
                    url: <string>args[0],
                });
            }

            /**
             * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
             */
            if (500 <= response.status && 599 >= response.status) {
                throw new ServerHttpException(response.status, response, {
                    method,
                    url: <string>args[0],
                });
            }

            return response;
        };

        return clonedDescriptor;
    };
}

export function intercept(cbList: TInterceptCallback[]): TDecorator {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return function (_: unknown, __: string, descriptor: PropertyDescriptor) {
        const clonedDescriptor = Object.assign({}, descriptor);

        clonedDescriptor.value = async function (
            ...args: unknown[]
        ): Promise<IHttpClientResponse<TJson, Headers, Response>> {
            const response: IHttpClientResponse<TJson, Headers, Response> = await descriptor.value.apply(this, args);

            let interceptedResponse = response;
            for (const interceptor of cbList) {
                // eslint-disable-next-line no-await-in-loop
                interceptedResponse = await interceptor.intercept(interceptedResponse, <TRequestParameters>args[0]);
            }

            return interceptedResponse;
        };

        return clonedDescriptor;
    };
}

export function preflight(cbList: TPreflightCallback[]): TDecorator {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return function (_: unknown, __: string, descriptor: PropertyDescriptor) {
        const clonedDescriptor = Object.assign({}, descriptor);

        clonedDescriptor.value = async function (...args: unknown[]): Promise<IHttpClientResponse> {
            let interceptedArgs = args[0];

            for (const preflightCallback of cbList) {
                // eslint-disable-next-line no-await-in-loop
                interceptedArgs = await preflightCallback.intercept(interceptedArgs);
            }

            const response: IHttpClientResponse = await descriptor.value.apply(this, [interceptedArgs, ...args]);
            return response;
        };

        return clonedDescriptor;
    };
}

export const env = <A extends TAbstractCall>(envId: TEnv, call: A): A => {
    return <A>(<unknown>function () {
        if ('server' === envId && isBrowserContext()) {
            throw new WrongEnvException(call.name || '<anonymouse>', 'client');
        }

        if ('client' === envId && !isBrowserContext()) {
            throw new WrongEnvException(call.name || '<anonymouse>', 'server');
        }

        // eslint-disable-next-line prefer-rest-params
        return call(...arguments);
    });
};

export const memoize = <A extends TAbstractCall>(call: A): A => {
    const table = new Map();
    return <A>(<unknown>function () {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;

        if (table.has(args)) {
            return table.get(args);
        }

        const result = call(...args);
        table.set(JSON.stringify(args), result);

        return result;
    });
};
