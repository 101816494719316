import {AbstractHttpClient} from 'core/abstract/AbstractHttpClient';
import {intercept, preflight} from 'core/decorators';
import {getFetch} from 'core/helpers';
import {AbortRequestInterceptor} from 'core/interceptors/AbortRequestInterceptor';
import {LoggerResponseInterceptor} from 'core/interceptors/LoggerResponseInterceptor';
import {IHttpClientResponse, TCommonRequestParameters, TJson, TRequestOption, TRequestParameters} from 'core/types';

export class BaseHttpClient extends AbstractHttpClient<typeof fetch, TRequestOption, Response> {
    public readonly host: string;
    public readonly api: string;

    constructor(host: BaseHttpClient['host'], api: BaseHttpClient['api']) {
        super(getFetch());

        this.host = host;
        this.api = api;
    }

    private async json<R>(response: Response): Promise<R> {
        try {
            return await response.json();
        } catch (e) {
            // @ts-expect-error
            if ('SyntaxError' === e.name) {
                return <Promise<R>>{};
            }

            throw e;
        }
    }

    public get baseUrl(): string {
        return `${this.host}/${this.api}`;
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async post<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            body: JSON.stringify(body),
            method: 'POST',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.post<Body, Rs>(<TRequestParameters<TRequestOption, Body>>{body, options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async put<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            body: JSON.stringify(body),
            method: 'PUT',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.put<Body, Rs>(<TRequestParameters<TRequestOption, Body>>{body, options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async patch<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            body: JSON.stringify(body),
            method: 'PATCH',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.patch<Body, Rs>(<TRequestParameters<TRequestOption, Body>>{body, options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async delete<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            method: 'DELETE',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.delete<Rs>({options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async deleteWithBody<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            body: JSON.stringify(body),
            method: 'DELETE',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.delete<Rs>({options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async head<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            method: 'HEAD',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.head<Rs>({options, url}),
            status: response.status,
        };
    }

    @intercept([LoggerResponseInterceptor.getInstance()])
    @preflight([AbortRequestInterceptor.getInstance()])
    public async get<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        const response = await this.vendorClient(`${this.baseUrl}${url}`, {
            method: 'GET',
            ...options,
        });

        return {
            data: await this.json(response),
            headers: response.headers,
            ok: response.ok,
            raw: response,
            retry: () => this.get<Rs>({options, url}),
            status: response.status,
        };
    }
}
