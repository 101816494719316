import {createDraftSafeSelector, Slice} from '@reduxjs/toolkit';
import {TAppRootState, TSelect} from 'core/redux/types';
import {createFilterSelectors} from 'plugins/modules/filter/selectors';
import {createListingSelectors} from 'plugins/modules/listing/selectors';
import {createPaginationSelectors} from 'plugins/modules/pagination/selectors';
import {TSearchState} from 'plugins/modules/search/types';
import {createSortingSelectors} from 'plugins/modules/sorting/selectors';

export function createSearchSelector<T extends TSearchState>(select: TSelect<T>, slice: Slice) {
    const selectSearchCategoryList = createDraftSafeSelector([select], (state) => state.categoryList);

    const selectSearchSuggestions = createDraftSafeSelector([select], (state) => state.suggestions);

    const selectRecommendationsList = createDraftSafeSelector([select], (state) => state.recommendationsList);

    function selectSearchQuery(state: TAppRootState): T['query'] {
        return select(state).query;
    }

    function selectSearchRedirect(state: TAppRootState): T['redirect'] {
        return select(state).redirect;
    }

    return {
        selectRecommendationsList,
        selectSearchCategoryList,
        selectSearchQuery,
        selectSearchRedirect,
        selectSearchSuggestions,

        ...createPaginationSelectors(select),
        ...createFilterSelectors(select),
        ...createSortingSelectors(select),
        ...createListingSelectors(select, slice),
    };
}
