import {DEBOUNCE_DELAY} from 'core/constants';
import debounce from 'lodash/debounce';
import {useCallback, useEffect, useState} from 'react';

export const useScroll = (scrollPosition: number, debounceDelay?: number): boolean => {
    const [, setScrollPosition] = useState<number>(0);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handlePageScroll = useCallback(
        debounce(() => {
            const isDisabledScroll = document.body.classList.contains('scroll-disabled-fixed');
            if (isDisabledScroll) {
                return;
            }
            setScrollPosition(window.scrollY);
            setIsScrolled(window.scrollY >= scrollPosition);
        }, debounceDelay ?? DEBOUNCE_DELAY),
        [scrollPosition]
    );

    useEffect(() => {
        window.addEventListener('scroll', handlePageScroll);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('scroll', handlePageScroll);
        };
    }, [handlePageScroll]);
    return isScrolled;
};
