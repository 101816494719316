import classNames from 'classnames';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    withBorder?: boolean;
}

export const OrderXPageSection = memo<IProps>(function OrderXPageSection({className, children, withBorder = false}) {
    return (
        <section className={classNames(style.root, className, {[style.withBorder]: withBorder})} data-skeleton-item>
            {children}
        </section>
    );
});
