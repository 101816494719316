import {TAppRootState} from 'core/redux/types';
import {bottomBarModuleName} from 'modules/bottombar/slice';
import {IBottomBarState} from 'modules/bottombar/types';

const select = (state: TAppRootState): IBottomBarState => {
    return state[bottomBarModuleName];
};

export const selectBottomBarActiveItem = (state: TAppRootState): IBottomBarState['activeItem'] =>
    select(state).activeItem;

export const selectBottomBarPrevActiveItem = (state: TAppRootState): IBottomBarState['prevActiveItem'] =>
    select(state).prevActiveItem;
