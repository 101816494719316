import {TGalleryMode} from 'modules/slider/types';
import {DeliveryInfo, MediaGroupVisual, ProductDetail, SetProductBase} from 'new-models';
import {createContext} from 'react';

interface IProductData {
    product?: ProductDetail & SetProductBase;
}

interface IProductPageContextProps {
    galleryMode: TGalleryMode;
    isProductLoading?: boolean;
    isProductAvailable?: boolean;
    productImages: MediaGroupVisual[];
    productData: IProductData | null;
    productDeliveryInfo?: DeliveryInfo[];
}

export const ProductPageContext = createContext<IProductPageContextProps>({} as IProductPageContextProps);
