import {IRouteContextProps, RouteContext} from 'modules/router/context';
import {useContext} from 'react';

export const useRouterQuery = <
    T extends IRouteContextProps['matchedGroups'] = IRouteContextProps['matchedGroups']
>(): T => {
    const {matchedGroups} = useContext(RouteContext);

    return matchedGroups as T;
};
