import {Typography} from 'components/Typography';
import {formatDateWithLocal} from 'core/helpers/date';
import {OrderStatusBadge} from 'modules/orders/components/OrderStatusBadge';
import {OrderDetail} from 'new-models';
import React from 'react';

import style from './style.module.scss';

interface IProps extends Pick<OrderDetail, 'orderStatus' | 'date' | 'id'> {}

export const Header: FC<IProps> = ({orderStatus, date, id}) => {
    return (
        <header className={style.root}>
            <div className={style.title}>
                <Typography className={style.titleText} element="h3" variant="h3">
                    Заказ № {id}
                </Typography>
                <OrderStatusBadge status={orderStatus} />
            </div>
            <Typography color="gray50" variant="p">
                от {formatDateWithLocal(date)}
            </Typography>
        </header>
    );
};
