import {TAppRootState} from 'core/redux/types';
import {sliderModuleName} from 'modules/slider/slice';
import {ISliderState} from 'modules/slider/types';

const select = (state: TAppRootState): ISliderState => state[sliderModuleName];

export const selectActiveIdx = (state: TAppRootState): ISliderState['activeIdx'] => select(state).activeIdx;

export const selectAutoStartVideoPath = (state: TAppRootState): ISliderState['autoStartVideoPath'] =>
    select(state).autoStartVideoPath;
