import classNames from 'classnames';
import {CollapseAnimated} from 'components/CollapseAnimated';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {isMobileByScreen} from 'core/helpers';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {useScrollDirection} from 'core/hooks/useScrollDirection';
import {TPropsWithClassName} from 'core/types';
import React, {FC, PropsWithChildren, ReactNode, useCallback, useRef, useState} from 'react';

import style from './style.module.scss';

interface IScrollRules {
    isTopHidden: boolean;
    isBottomHidden: boolean;
}

interface IProps extends PropsWithChildren, TPropsWithClassName {
    top?: ReactNode;
    bottom?: ReactNode;
    behaviour?: {
        onScrollDown: IScrollRules;
        onScrollUp: IScrollRules;
    };
    onlyMobile?: boolean;
}

export const PageHeader: FC<IProps> = ({
    children,
    className,
    top,
    bottom,
    behaviour = {
        onScrollDown: {
            isBottomHidden: false,
            isTopHidden: true,
        },
        onScrollUp: {
            isBottomHidden: true,
            isTopHidden: false,
        },
    } as IProps['behaviour'],
    onlyMobile = true,
}) => {
    const headerRef = useRef<HTMLDivElement>(null);

    // const isNotTop = useScroll(1, 0);
    const isNotTop = false;

    const [isTopHidden, setIsTopHidden] = useState<boolean>(false);
    const [isBottomHidden, setIsBottomHidden] = useState<boolean>(false);

    useElementHeightWithCssVar(headerRef, RUNTIME_CSS_VARS.fixedHeaderContentMarginTop);

    useScrollDirection({
        onScrollDown: useCallback(() => {
            if (onlyMobile && !isMobileByScreen()) {
                return;
            }

            setIsTopHidden(behaviour!.onScrollDown.isTopHidden);
            setIsBottomHidden(behaviour!.onScrollDown.isBottomHidden);
        }, [behaviour, onlyMobile]),

        onScrollUp: useCallback(() => {
            if (onlyMobile && !isMobileByScreen()) {
                return;
            }

            setIsTopHidden(behaviour!.onScrollUp.isTopHidden);
            setIsBottomHidden(behaviour!.onScrollUp.isBottomHidden);
        }, [behaviour, onlyMobile]),
    });

    return (
        <div
            className={classNames(style.root, className, {
                [style.isNotTop]: isNotTop,
            })}
        >
            <div ref={headerRef}>
                {top && <CollapseAnimated isHidden={isTopHidden}>{top}</CollapseAnimated>}
                {children}
                {bottom && <CollapseAnimated isHidden={isBottomHidden}>{bottom}</CollapseAnimated>}
            </div>
        </div>
    );
};
