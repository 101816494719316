import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalizeList} from 'core/helpers/normalize';
import {TAddressesState} from 'modules/addresses/types';
import {
    DeliveryAddressCreateDataResponse,
    DeliveryAddressesDataResponse,
    DeliveryAddressUpdateDataResponse,
} from 'new-models';

export const addressesModuleName = 'addresses';

const initialState: TAddressesState = {
    currentDeleteAddressId: null,
    currentEditAddressId: null,
    data: {},
    list: [],
};

const uncheckedDefaultAddress = (data: TAddressesState['data']) => {
    Object.keys(data).forEach((id) => {
        const currentItem = data[id];
        if (currentItem.isDefault) {
            currentItem.isDefault = false;
        }
    });
};

export const addressesSlice = createSlice({
    initialState,
    name: addressesModuleName,
    reducers: {
        actionDeleteAddress(state, {payload}: PayloadAction<string>) {
            if (payload in state.data) {
                delete state.data[payload];
                state.list = state.list.filter((id) => id !== payload);
            }
        },

        actionSetAddressList(state, {payload}: PayloadAction<DeliveryAddressesDataResponse['deliveryAddresses']>) {
            const {data, list} = normalizeList(payload);
            state.data = data;
            state.list = list;
        },

        actionSetCurrentDeleteAddressId(state, {payload}: PayloadAction<string | null>) {
            state.currentDeleteAddressId = payload;
        },

        actionSetCurrentEditAddressId(state, {payload}: PayloadAction<string | null>) {
            state.currentEditAddressId = payload;
        },

        actionSetNewAddress(state, {payload}: PayloadAction<DeliveryAddressCreateDataResponse['deliveryAddress']>) {
            const {data, list} = normalizeList([payload]);

            if (payload.isDefault) {
                uncheckedDefaultAddress(state.data);
            }

            state.data = {
                ...state.data,
                ...data,
            };
            state.list = [...state.list, ...list];
        },

        actionUpdateAddress(state, {payload}: PayloadAction<DeliveryAddressUpdateDataResponse['deliveryAddress']>) {
            if (payload.id in state.data) {
                if (payload.isDefault && !state.data[payload.id].isDefault) {
                    uncheckedDefaultAddress(state.data);
                }

                state.data[payload.id] = payload;
            }
        },
    },
});

export const {
    actions: {
        actionDeleteAddress,
        actionSetAddressList,
        actionSetCurrentDeleteAddressId,
        actionSetNewAddress,
        actionUpdateAddress,
        actionSetCurrentEditAddressId,
    },
} = addressesSlice;

export const {reducer: addressesReducer} = addressesSlice;
