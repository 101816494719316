import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IHomePageState} from 'modules/home-page/types';
import {HYDRATE} from 'next-redux-wrapper';

export const homePageModuleName = 'homePage';

const initialState: IHomePageState = {
    homeAdviceList: [],
    homePrivateLabelList: [],
    homeServiceList: [],
};

export const homePageSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[homePageModuleName];
        },
    },
    initialState,
    name: homePageModuleName,
    reducers: {
        actionSetHomeAdvicesList(state: IHomePageState, {payload}: PayloadAction<IHomePageState['homeAdviceList']>) {
            state.homeAdviceList = payload;
        },

        actionSetHomePrivateLabelsList(
            state: IHomePageState,
            {payload}: PayloadAction<IHomePageState['homePrivateLabelList']>
        ) {
            state.homePrivateLabelList = payload;
        },

        actionSetHomeServiceList(state: IHomePageState, {payload}: PayloadAction<IHomePageState['homeServiceList']>) {
            state.homeServiceList = payload;
        },
    },
});

export const {
    actions: {actionSetHomeAdvicesList, actionSetHomePrivateLabelsList, actionSetHomeServiceList},
} = homePageSlice;

export const {reducer: homePageReducer} = homePageSlice;
