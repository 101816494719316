import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {TPropsWithClassName} from 'core/types';
import {IProps as ITabBarCheckoutProps} from 'modules/checkout/components/TabBarCheckout';
import React, {ForwardRefExoticComponent, RefAttributes} from 'react';

const columnStyle = getGridColumnStyles();
const totalColumnStyle = getGridColumnStyles({
    md: 4,
});
const contentColumnStyle = getGridColumnStyles({
    md: 8,
});

import {useInView} from 'react-intersection-observer';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    HeaderComponent: FC;
    LeftComponent: FC;
    RightComponent: ForwardRefExoticComponent<TPropsWithClassName & RefAttributes<HTMLDivElement>>;
    FooterComponent: FC<ITabBarCheckoutProps>;
}

export const CheckoutPageLayout: FC<IProps> = ({
    className,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HeaderComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LeftComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RightComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FooterComponent,
}) => {
    const {ref, inView} = useInView({
        // TODO: пара грязных трюков, чтобы работало
        rootMargin: `-100px 0% -530px 0%`,
    });

    return (
        <Grid as="section" className={classNames(style.root, className)} container noRowGap>
            <HeaderComponent className={columnStyle} />
            <Grid className={classNames(style.container, columnStyle)}>
                <LeftComponent className={contentColumnStyle} />
                <RightComponent className={totalColumnStyle} ref={ref} />
            </Grid>
            <FooterComponent inView={inView} />
        </Grid>
    );
};
