import {AgreementBlock} from 'components/AgreementBlock';
import {Input} from 'components/Input';
import {InputSelect, ISelectOption} from 'components/InputSelect';
import {TextArea} from 'components/TextArea';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {Field, FieldProps, useFormikContext} from 'formik';
import {selectFeedbackSubjects} from 'modules/common/selectors';
import {ModalActions} from 'modules/modals/components/ModalActions';
import {Feedback} from 'new-models';
import {memo, useCallback, useMemo} from 'react';
import InputMask from 'react-input-mask';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    isSubmitting: boolean;
    handleAgreementChange: VoidFunction;
    isAgreement: boolean;
}

export const FeedbackFormFields = memo<IProps>(function FeedbackFormFields({
    isSubmitting,
    handleAgreementChange,
    isAgreement,
}) {
    const {values, setFieldValue, isValid, dirty} = useFormikContext<Feedback>();
    const feedbackSubjects = useAppSelector(selectFeedbackSubjects);

    const subjectOptions = useMemo<ISelectOption<string>[]>(() => {
        if (!feedbackSubjects || !feedbackSubjects?.length) {
            return [];
        }

        return feedbackSubjects.map((item) => ({label: item.title, value: item.id}));
    }, [feedbackSubjects]);
    const handleSubjectSelectChange = useCallback(
        (option: ISelectOption<string>) => {
            setFieldValue('subjectId', option.value);
        },
        [setFieldValue]
    );

    return (
        <>
            <Field name="subjectId">
                {({meta}: FieldProps<string, Feedback['subjectId']>) => (
                    <InputSelect
                        errorText={meta.error}
                        onChange={handleSubjectSelectChange}
                        options={subjectOptions}
                        placeholder="Выберите тему обращения*"
                    />
                )}
            </Field>
            <Field name="name">
                {({field, meta}: FieldProps<string, Feedback['name']>) => (
                    <Input {...field} errorText={meta.error} isBorder labelText="Ваше имя*" />
                )}
            </Field>
            <Field name="email">
                {({field, meta}: FieldProps<string, Feedback['email']>) => (
                    <Input {...field} errorText={meta.error} isBorder labelText="Электронная почта" />
                )}
            </Field>
            <Field name="phone">
                {({field, meta}: FieldProps<string, Feedback['phone']>) => (
                    <InputMask mask="+7 999 999 99 99" maskPlaceholder="" {...field} disabled={isSubmitting}>
                        <Input
                            {...field}
                            errorText={meta.error}
                            isBorder
                            labelText="Номер телефона*"
                            notFadingPlaceholder="+7 999 999 99 99"
                        />
                    </InputMask>
                )}
            </Field>
            <Field name="message">
                {({field, meta}: FieldProps<string, Feedback['message']>) => (
                    <TextArea
                        {...field}
                        captionText={`${values.message.length} / 200`}
                        errorText={meta.error}
                        labelText="Ваше сообщение*"
                    />
                )}
            </Field>

            <AgreementBlock
                className={style.agreement}
                handleAgreementChange={handleAgreementChange}
                isAgreement={isAgreement}
            />
            <ModalActions
                buttonsProps={[
                    {
                        disabled: !dirty || !isValid || !isAgreement,
                        isLoading: isSubmitting,
                        isNotAdaptive: true,
                        isStretched: true,
                        type: 'submit',
                        value: 'Отправить',
                    },
                ]}
                className={style.actions}
            />
        </>
    );
});
