import {DaDataSuggestion} from 'modules/da-data/components/DaDataSuggestion';
import {DaData} from 'new-models';
import {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    suggestionsList: DaData[];
    onItemSelect: (daDataItem: DaData) => void;
}

export const DaDataSuggestions = memo<IProps>(function DaDataSuggestions({suggestionsList, onItemSelect}) {
    const handleClickOnSuggestionItem = (value: string) => {
        const suggestionItem = suggestionsList.find((item) => item.value === value);

        if (!suggestionItem) {
            return;
        }

        onItemSelect(suggestionItem);
    };

    return (
        <ul className={style.list}>
            {suggestionsList.map((item) => (
                <DaDataSuggestion handleClickOnSuggestionItem={handleClickOnSuggestionItem} value={item.value} />
            ))}
        </ul>
    );
});
