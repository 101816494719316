import classNames from 'classnames';
import {Button} from 'components/Button';
import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPriceToDefault} from 'core/helpers';
import {getPriceGroups} from 'core/regex';
import {useCartProduct} from 'modules/cart/hooks/useCartProduct';
import {useFavourites} from 'modules/favourites/hooks/useFavourites';
import {MediaTypeEnum, OrderItem} from 'new-models';
import Link from 'next/link';
import addToCartIcon from 'public/icons/add-to-cart.svg';
import favouriteCheckedIcon from 'public/icons/favourite-checked.svg';
import favouriteIcon from 'public/icons/heart.svg';
import React, {memo, useCallback, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends OrderItem {
    className?: string;
}

const ImageFallback: FC = () => <NoImage className={style.image} size={NO_IMAGE_SIZE.default} />;

export const OrderCardItemProductItem = memo<IProps>(function OrderCardItemProductItem({className, product, quantity}) {
    const {media, article, title, price} = product;

    const {handleCartCountAddClick, isProductInCart} = useCartProduct(product);
    const {isChecked, switchFavourites} = useFavourites(product);

    const renderPrice = useMemo(() => {
        const currentPrice = (price?.mainDiscount ? price?.mainDiscount?.price : price?.main?.price) * quantity;
        const currentCurrency = price?.mainDiscount ? price?.mainDiscount?.currency : price?.main?.currency;
        const formattedPrice = formatPriceToDefault((currentPrice / PRICE_DENOMINATOR).toString(), {
            ...(currentCurrency ? {currency: currentCurrency} : {}),
        });
        const {price: parsedPrice, currency: parsedCurrency} = getPriceGroups(formattedPrice);
        return (
            <Typography color="gray100" element="p" variant="price-preview-s">
                {parsedPrice}&nbsp;
                <Typography color="gray100" element="span" variant="p-medium-strong">
                    {parsedCurrency}
                </Typography>
            </Typography>
        );
    }, [price?.main?.currency, price?.main?.price, price?.mainDiscount, quantity]);

    const renderPricePerOne = useMemo(() => {
        const currentPrice = price?.mainDiscount ? price?.mainDiscount?.price : price?.main?.price;
        const currentCurrency = price?.mainDiscount ? price?.mainDiscount?.currency : price?.main?.currency;
        const unit = (price?.mainDiscount ? price?.mainDiscount?.unit : price?.main?.unit) || 'шт';
        const formattedPrice = formatPriceToDefault((currentPrice / PRICE_DENOMINATOR).toString(), {
            ...(currentCurrency ? {currency: currentCurrency} : {}),
        });

        return (
            <Typography color="gray50" element="p" variant="p">
                {quantity} {unit}. по {formattedPrice}
            </Typography>
        );
    }, [price?.main?.currency, price?.main?.price, price?.main?.unit, price?.mainDiscount, quantity]);

    const handleSwitch = useCallback(() => {
        switchFavourites(!isChecked);
    }, [isChecked, switchFavourites]);

    const renderDesktopAddToCartBtn = useMemo<React.ReactNode>(() => {
        if (isProductInCart) {
            return (
                <Link href="/cart" passHref>
                    <Button isWithoutPaddings size="medium" theme="ghost">
                        Перейти в корзину
                    </Button>
                </Link>
            );
        }

        return (
            <Button
                isWithoutPaddings
                onClick={handleCartCountAddClick}
                rightIcon={<SvgIcon svg={addToCartIcon} />}
                size="medium"
                theme="ghost"
            >
                Добавить в корзину
            </Button>
        );
    }, [handleCartCountAddClick, isProductInCart]);

    const renderMobileAddToCartBtn = useMemo(() => {
        if (isProductInCart) {
            return (
                <Link href="/cart" passHref>
                    <Button isNotAdaptive isWithoutPaddings size="small" theme="ghost">
                        Перейти в корзину
                    </Button>
                </Link>
            );
        }

        return (
            <Button isNotAdaptive isWithoutPaddings onClick={handleCartCountAddClick} theme="ghost">
                <SvgIcon svg={addToCartIcon} />
            </Button>
        );
    }, [handleCartCountAddClick, isProductInCart]);

    return (
        <div className={classNames(style.root, className)}>
            <MediaWithImageFallback
                NoImageComponent={ImageFallback}
                className={style.image}
                media={media}
                mediaType={MediaTypeEnum.Image}
            />
            <div className={style.contentText}>
                <Typography color="gray70" element="p" variant="p">
                    {article}
                </Typography>
                <Typography className={style.title} color="gray100" element="p" variant="p">
                    {title}
                </Typography>
                <div className={style.actionsDesktop}>
                    {renderDesktopAddToCartBtn}
                    <Button
                        isWithoutPaddings
                        onClick={handleSwitch}
                        rightIcon={<SvgIcon svg={isChecked ? favouriteCheckedIcon : favouriteIcon} />}
                        size="medium"
                        theme="ghost"
                    >
                        {isChecked ? 'Убрать из избранного' : 'В избранное'}
                    </Button>
                </div>
            </div>
            <div className={style.pricePerOneDesktop}>
                {renderPrice}
                {renderPricePerOne}
            </div>
            <div className={style.pricePerOneMobile}>
                {renderPricePerOne}
                {renderPrice}
            </div>
            <div className={style.actionsMobile}>
                {renderMobileAddToCartBtn}
                <Button isNotAdaptive isWithoutPaddings onClick={handleSwitch} theme="ghost">
                    <SvgIcon svg={isChecked ? favouriteCheckedIcon : favouriteIcon} />
                </Button>
            </div>
        </div>
    );
});
