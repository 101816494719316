import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IProductStateOrigin, TProductState} from 'modules/products/types';
import {HYDRATE} from 'next-redux-wrapper';
import {createFilterActions} from 'plugins/modules/filter/actions';
import {createFilterExtraReducers} from 'plugins/modules/filter/reducer';
import {withFilter} from 'plugins/modules/filter/state';
import {createListingActions} from 'plugins/modules/listing/actions';
import {withListing} from 'plugins/modules/listing/state';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {withPagination} from 'plugins/modules/pagination/state';
import {createSortingActions} from 'plugins/modules/sorting/actions';
import {withSorting} from 'plugins/modules/sorting/state';

export const productsModuleName = 'products';

const initialState: TProductState = withListing(
    withSorting(
        withFilter(
            withPagination<IProductStateOrigin>({
                bestsellersList: [],
                newList: [],
                popularList: [],
                recommendedProductsList: [],
                sectionId: null,
                similarProductsList: [],
                storeFilterList: [],
                viewedProductsList: [],
            })
        )
    )
);

export const productsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return {
                ...payload[productsModuleName],
                isFilterPanelVisible: state.isFilterPanelVisible,
            };
        },
        ...createFilterExtraReducers<TProductState>(),
    },
    initialState,
    name: productsModuleName,
    reducers: {
        actionSetBestsellersList(state: TProductState, {payload}: PayloadAction<TProductState['bestsellersList']>) {
            state.bestsellersList = payload;
        },

        actionSetCategoryId(state: TProductState, {payload}: PayloadAction<TProductState['sectionId']>) {
            state.sectionId = payload;
        },

        actionSetNewList(state: TProductState, {payload}: PayloadAction<TProductState['bestsellersList']>) {
            state.newList = payload;
        },

        actionSetPopularList(state: TProductState, {payload}: PayloadAction<TProductState['popularList']>) {
            state.popularList = payload;
        },

        actionSetRecommendedProductList(
            state: TProductState,
            {payload}: PayloadAction<TProductState['recommendedProductsList']>
        ) {
            state.recommendedProductsList = payload;
        },

        actionSetSimilarProductList(
            state: TProductState,
            {payload}: PayloadAction<TProductState['similarProductsList']>
        ) {
            state.similarProductsList = payload;
        },

        actionSetStoreFilterList(state: TProductState, {payload}: PayloadAction<TProductState['storeFilterList']>) {
            state.storeFilterList = payload;
        },

        actionSetViewedProductList(
            state: TProductState,
            {payload}: PayloadAction<TProductState['viewedProductsList']>
        ) {
            state.viewedProductsList = payload;
        },

        ...createPaginationActions<TProductState>(),
        ...createFilterActions<TProductState>(),
        ...createSortingActions<TProductState>(),
        ...createListingActions<TProductState>(),
    },
});

export const {
    actions: {
        actionSetCategoryId,
        actionSetViewedProductList,
        actionSetSimilarProductList,
        actionSetRecommendedProductList,
        actionSetPageCount,
        actionSetPageSize,
        actionSetPage,
        actionSetComboFilterList,
        actionSetFilterList,
        actionSetNewList,
        actionSetSortingList,
        actionSetPopularList,
        actionSetList,
        actionSetListViewType,
        actionSetBestsellersList,
    },
} = productsSlice;

export const {reducer: productsReducer} = productsSlice;
