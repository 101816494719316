import classNames from 'classnames';
import {IProps as ISegmentedRadioProps, SegmentedRadio} from 'components/SegmentedRadio';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends ISegmentedRadioProps {
    IconComponent?: FC;
}

export const CheckoutPaymentMethod = memo<IProps>(function CheckoutPaymentMethod({
    className,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    IconComponent,
    isLabel = true,
    disabled,
    ...restSegmentProps
}) {
    return (
        <SegmentedRadio
            className={classNames(style.root, className)}
            disabled={disabled}
            isLabel={isLabel}
            {...restSegmentProps}
        >
            {IconComponent && <IconComponent className={classNames(style.icon, {[style.disabled]: disabled})} />}
        </SegmentedRadio>
    );
});
