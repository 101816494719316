import classNames from 'classnames';
import {BUTTON_SIZE} from 'components/Button';
import {TPropsWithClassName} from 'core/types';
import {AddToCartBlock} from 'modules/cart/components/AddToCartBlock';
import {useCartProduct} from 'modules/cart/hooks/useCartProduct';
import {FavouriteButton} from 'modules/favourites/components/FavouriteButton';
// import {ActionsSubBlock} from 'modules/product-card/components/ActionsSubBlock';
// import {useProductCardActions} from 'modules/product-card-actions/hooks/useProductCardActions';
// import {SubscriptionButton} from 'modules/product-subscription/components/SubscriptionButton';
import {useIsProductAvailable} from 'modules/products/hooks/useIsProductAvailable';
import {TPositionInCardVariant, TProductCardVariant, TProductListView} from 'modules/products/types';
import {ProductBase} from 'new-models';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends ProductBase, TPropsWithClassName {
    variant: TProductListView | TProductCardVariant | TPositionInCardVariant;
    stretched?: boolean;
    showPrice?: boolean;
    isHorizontal?: boolean;
    isStaticWidth?: boolean;
    favoriteBtnSize?: 'default' | 'large' | 'medium' | 'small';
}

export const ProductCardActions = memo<IProps>(function ProductCardActions({
    showPrice = false,
    className,
    variant,
    stretched,
    isHorizontal = false,
    isStaticWidth = false,
    favoriteBtnSize = BUTTON_SIZE.large,
    ...product
}) {
    const cartProduct = useCartProduct(product);
    const {cartProductCount} = cartProduct;
    const isProductAvailable = useIsProductAvailable(product);
    // const {subscribeColor, subscribeTheme, productCardActionsButtonsSize} = useProductCardActions();

    const renderContent = useMemo(() => {
        if (isProductAvailable) {
            return (
                <>
                    <AddToCartBlock
                        {...product}
                        {...cartProduct}
                        isHorizontal={isHorizontal}
                        isStaticWidth={isStaticWidth}
                        showPrice={showPrice}
                        stretched={stretched}
                        variant={variant}
                    />
                    <FavouriteButton className={style.availableFavourite} product={product} size={favoriteBtnSize} />

                    {/* <ActionsSubBlock className={style.actionsSubBlock} {...product} />*/}
                </>
            );
        }

        // return (
        //     <div className={style.subscription}>
        //         <SubscriptionButton
        //             color={subscribeColor(variant)}
        //             size={productCardActionsButtonsSize(variant)}
        //             theme={subscribeTheme(variant)}
        //         />
        //         <FavouriteButton className={style.favourite} product={product} size={favoriteBtnSize} />
        //     </div>
        // );

        return null;
    }, [
        cartProduct,
        favoriteBtnSize,
        isHorizontal,
        isProductAvailable,
        isStaticWidth,
        product,
        showPrice,
        stretched,
        variant,
    ]);

    return (
        <div
            className={classNames(className, style.productCardActions, {
                [style[variant]]: variant,
                [style.productInCart]: Boolean(cartProductCount),
            })}
            data-skeleton-item
        >
            {renderContent}
        </div>
    );
});
