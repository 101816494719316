import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {selectCategoriesList} from 'modules/categories/selectors';
import {ProductPageContext} from 'modules/product-card/context';
import {TProductResponse} from 'modules/product-card/models/ProductResponse';
import {ProductPage} from 'modules/product-card/pages/ProductPage';
import {IProductRouterQuery} from 'modules/product-card/types';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {useRouterQuery} from 'modules/router/hooks/useRouterQuery';
import {
    SIMPLE_PRODUCT_CARD_SKELETON_DATA,
    TECHNICAL_PRODUCT_CARD_SKELETON_DATA,
    VISUAL_PRODUCT_CARD_SKELETON_DATA,
} from 'modules/skeleton/constants';
import {Category} from 'new-models';
import React, {FC, useMemo} from 'react';

export const ProductPageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.catalog);

    const productUrlData = useRouterQuery<IProductRouterQuery>();
    const categoryList = useAppSelector(selectCategoriesList);

    const productCategoryId = useMemo<Category['id'] | undefined>(() => {
        if (!productUrlData.productCategoryChainedId) {
            return undefined;
        }

        const categoryIdList = productUrlData.productCategoryChainedId.split('-');
        const categoryId = categoryIdList[categoryIdList.length - 1];

        if (!categoryId) {
            return undefined;
        }

        return categoryId;
    }, [productUrlData.productCategoryChainedId]);

    const categoryType = useMemo<Category['type'] | undefined>(() => {
        if (!productCategoryId) {
            return undefined;
        }

        const category = categoryList.find(({id}) => id === productCategoryId);

        if (!category) {
            return undefined;
        }

        return category.type;
    }, [productCategoryId, categoryList]);

    const skeletonData = useMemo<TProductResponse['data'] | null>(() => {
        switch (categoryType) {
            case PRODUCT_TYPE.simple: {
                return SIMPLE_PRODUCT_CARD_SKELETON_DATA;
            }

            case PRODUCT_TYPE.visual: {
                return VISUAL_PRODUCT_CARD_SKELETON_DATA;
            }

            case PRODUCT_TYPE.technical: {
                return TECHNICAL_PRODUCT_CARD_SKELETON_DATA;
            }

            default: {
                return SIMPLE_PRODUCT_CARD_SKELETON_DATA;
            }
        }
    }, [categoryType]);

    return (
        <ProductPageContext.Provider
            value={{
                galleryMode: 'basic',
                isProductLoading: false,
                productData: skeletonData,
                productImages: skeletonData?.product?.media ?? [],
            }}
        >
            <ProductPage />
        </ProductPageContext.Provider>
    );
};
