import classNames from 'classnames';
import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {TPropsWithClassName} from 'core/types';
import {PopularBrandCard} from 'modules/brand/components/PopularBrandCard';
import {CardsSlider} from 'modules/slider/components/CardsSlider';
import {IProps as ISliderProps} from 'modules/slider/components/SliderWithExternalButton';
import {SLIDERS_PER_GROUP, SLIDERS_PER_VIEW, SPACE_BETWEEN} from 'modules/slider/constants';
import {BrandTop} from 'new-models';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

const DEFAULT_BREAKPOINT_SLIDER: ISliderProps['breakpoints'] = {
    [0]: {
        slidesPerGroup: SLIDERS_PER_GROUP.one,
        slidesPerView: SLIDERS_PER_VIEW.auto,
        spaceBetween: SPACE_BETWEEN.cards,
    },
    [MIN_DESKTOP_WIDTH]: {
        slidesPerGroup: SLIDERS_PER_GROUP.one,
        slidesPerView: SLIDERS_PER_VIEW.auto,
        spaceBetween: SPACE_BETWEEN.default,
    },
};

interface IProps extends TPropsWithClassName {
    brandList: BrandTop[];
}

export const BrandsSlider = memo<IProps>(function PopularBrandsSlider({brandList, className}) {
    const renderSlides = useMemo(
        () =>
            brandList?.map((item, index) => (
                <PopularBrandCard brand={item} className={style.brandCard} key={`${item.code}_${index}`} />
            )) || [],
        [brandList]
    );

    const renderMobileSlides = useMemo(() => {
        const mobileBrandList = brandList.reduce<BrandTop[][]>((acc, brand, idx) => {
            if (!(idx % 2)) {
                return [...acc, [brand]];
            }
            acc[acc.length - 1].push(brand);
            return acc;
        }, []);

        return mobileBrandList.map((item, idx) => (
            <div className={style.mobileSlide}>
                <PopularBrandCard brand={item[0]} className={style.brandCard} key={`${item[0].code}_${idx}`} />
                {item[1] && (
                    <PopularBrandCard brand={item[1]} className={style.brandCard} key={`${item[1].code}_${idx}`} />
                )}
            </div>
        ));
    }, [brandList]);

    return (
        <div className={classNames(style.root, className)}>
            <CardsSlider breakpoints={DEFAULT_BREAKPOINT_SLIDER} className={style.desktop}>
                {renderSlides}
            </CardsSlider>
            <CardsSlider breakpoints={DEFAULT_BREAKPOINT_SLIDER} className={style.mobile}>
                {renderMobileSlides}
            </CardsSlider>
        </div>
    );
});
