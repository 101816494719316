import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ISearchPageStateOrigin, TSearchPageState} from 'modules/search-page/types';
import {HYDRATE} from 'next-redux-wrapper';
import {createFilterExtraReducers} from 'plugins/modules/filter/reducer';
import {createSearchActions} from 'plugins/modules/search/actions';
import {withSearch} from 'plugins/modules/search/state';

export const searchPageModuleName = 'search-page';

const initialState: TSearchPageState = withSearch<ISearchPageStateOrigin>({
    nothingFound: null,
});

export const searchPageSlice = createSlice({
    extraReducers: {
        ...createFilterExtraReducers<TSearchPageState>(),
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload[searchPageModuleName]) {
                return state;
            }

            return {
                ...payload[searchPageModuleName],
                isFilterPanelVisible: state.isFilterPanelVisible,
            };
        },
    },
    initialState,
    name: searchPageModuleName,
    reducers: {
        ...createSearchActions(),

        actionSetIsNothingFound(state, {payload}: PayloadAction<TSearchPageState['nothingFound']>) {
            state.nothingFound = payload;
        },
    },
});

export const {
    actions: {
        actionSetSearchCategoryList,
        actionSetIsNothingFound,
        actionSetFilterList,
        actionSetPageSize,
        actionSetPage,
        actionSetPageCount,
        actionClearSearchData,
        actionSetSortingList,
        actionSetList,
        actionSetRedirect,
    },
} = searchPageSlice;

export const {reducer: searchPageReducer} = searchPageSlice;
