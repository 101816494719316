import {TPropsWithClassName} from 'core/types';
import {
    CollectionPreviewCard,
    IProps as ICollectionPreviewCardProps,
} from 'modules/collections/components/CollectionPreviewCard';
import type {IProps as IProductListProps} from 'modules/products/components/ProductList';
import {SimpleProductCard} from 'modules/products/components/SimpleProductCard';
import {VisualProductCard} from 'modules/products/components/VisualProductCard';
import {PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {TProductCardVariant, TProductListView} from 'modules/products/types';
import {Category, CategoryType, ProductDetail, ProductShort} from 'new-models';
import React, {FC, MouseEventHandler, ReactNode, useMemo} from 'react';

export interface IProps extends TPropsWithClassName {
    listType: TProductListView;
    variant?: TProductCardVariant;
    categoryType: Category['type'];
    onClick?: MouseEventHandler<HTMLDivElement>;
    listContext?: IProductListProps['listContext'];
    classNameRoot?: string;
}

export type TCommonProductCardProps = IProps & ProductShort;
export type TCollectionProductCardProps = IProps & ICollectionPreviewCardProps;

export const ProductCard: FC<TCommonProductCardProps | TCollectionProductCardProps> = ({
    listContext,
    onClick,
    categoryType,
    classNameRoot,
    ...restProductCardAttr
}) => {
    const renderProductCard = useMemo<ReactNode>(() => {
        if ('collections-list' === listContext) {
            return <CollectionPreviewCard {...(restProductCardAttr as TCollectionProductCardProps)} />;
        }

        if (PRODUCT_LIST_VIEW.list === restProductCardAttr.listType) {
            return (
                <SimpleProductCard
                    {...(restProductCardAttr as TCommonProductCardProps)}
                    type={categoryType as ProductDetail['type']}
                />
            );
        }

        // TODO: for the time of mixing two mock servers
        // if ((restProductCardAttr as TCommonProductCardProps).availableType === AvailableType.OfflineWish) {
        //     return (
        //         <SimpleProductCard
        //             {...(restProductCardAttr as TCommonProductCardProps)}
        //             type={categoryType as ProductDetail['type']}
        //         />
        //     );
        // }

        switch (categoryType) {
            case CategoryType.Simple:
            case CategoryType.Technical:
            case CategoryType.Collection: {
                return (
                    <SimpleProductCard
                        {...(restProductCardAttr as TCommonProductCardProps)}
                        type={categoryType as ProductDetail['type']}
                    />
                );
            }

            case CategoryType.Mixed:
            default: {
                return <SimpleProductCard {...(restProductCardAttr as TCommonProductCardProps)} type="simple" />;
            }

            case CategoryType.Visual: {
                return <VisualProductCard {...(restProductCardAttr as TCommonProductCardProps)} />;
            }
        }
    }, [categoryType, listContext, restProductCardAttr]);

    return (
        <div className={classNameRoot} data-product-card-id={restProductCardAttr.id} onClick={onClick}>
            {renderProductCard}
        </div>
    );
};
