import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {mindBoxService} from 'modules/analytics/services/MindBoxService';
import {TMindboxCartProduct} from 'modules/analytics/types';
import {makePatchBasket} from 'modules/cart/helpers';
import {updateCart, updateCartBatch} from 'modules/cart/thunks';
import {selectUserData} from 'modules/user/selectors';
import {useCallback} from 'react';

export const useAddToCart = () => {
    const profile = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();

    return useCallback(
        (cartItems: TMindboxCartProduct[]) => {
            if (profile) {
                mindBoxService.addToCart(cartItems, profile);
            }

            const cartProducts = cartItems.map(makePatchBasket);

            if (1 === cartProducts.length) {
                dispatch(updateCart(cartProducts[0]));
                return;
            }

            dispatch(updateCartBatch(cartProducts));
        },
        [dispatch, profile]
    );
};
