import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {actionSetRemoveModalView} from 'modules/cart/slice';
import {TRemoveModalView} from 'modules/cart/types';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {MouseEventHandler, useCallback} from 'react';

export const useCartModalActions = () => {
    const dispatch = useAppDispatch();

    const openCartModal = useCallback(
        ({modalName}: {modalName: string}) => {
            dispatch(showModal(modalName, {withoutScrollToTop: true}));
        },
        [dispatch]
    );

    const openCartRemoveModal = useCallback(
        (removeModalView: TRemoveModalView = REMOVE_MODAL_VIEW.ASSIGNED): MouseEventHandler<HTMLButtonElement> =>
            () => {
                dispatch(actionSetRemoveModalView(removeModalView));
                openCartModal({modalName: MODALS.CART_REMOVE.name});
            },
        [dispatch, openCartModal]
    );

    return {
        openCartModal,
        openCartRemoveModal,
    };
};
