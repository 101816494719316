import {mountLinkUrl} from 'core/helpers/mountLinkUrl';
import {TMountLinkUrlOptions} from 'core/types';
import {MOBILE_BANNER_MAP} from 'modules/common/constants';
import {IImageAdaptiveMap} from 'modules/images/types';
import {Promo} from 'new-models';

export const mountAdaptiveImageMaps = (
    urlScreenVariant: IImageAdaptiveMap['urlScreenVariant']
): IImageAdaptiveMap[] => {
    return [{screenWidth: 768, urlScreenVariant}, MOBILE_BANNER_MAP];
};

export const parseBannerLinks = (bannerList: Promo[], options: TMountLinkUrlOptions): Promo[] =>
    bannerList.map((banner: Promo) => ({
        ...banner,
        link: mountLinkUrl(banner.link, options),
    }));
