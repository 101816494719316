import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {TClosureCallback, TPropsWithClassName} from 'core/types';
import {useRenderListingBanner} from 'modules/common/hooks/useRenderListingBanner';
import {EmptyProductList} from 'modules/products/components/EmptyProductList';
import {ProductCard} from 'modules/products/components/ProductCard';
import {PRODUCT_LIST_VIEW, SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY} from 'modules/products/constants';
import {TProductCardVariant, TProductListView} from 'modules/products/types';
import {IShieldProps} from 'modules/shields/components/Shield';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {Category, CategoryType, ProductShort} from 'new-models';
import {ListingContext} from 'plugins/modules/listing/context';
import {PaginationContext} from 'plugins/modules/pagination/context';
import React, {FC, Fragment, MouseEventHandler, ReactNode, useCallback, useContext} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

export interface IProps extends TPropsWithClassName {
    listType?: TProductListView;
    variant?: TProductCardVariant;
    productList: ProductShort[];
    emptyListComponent?: ReactNode;
    shields?: IShieldProps[];
    categoryType?: Category['type'];
    listContext?: 'common-list' | 'collections-list';
    productCardRootClassName?: string;
}

export const ProductList: FC<IProps> = ({
    listType = PRODUCT_LIST_VIEW.tile,
    productList,
    emptyListComponent = <EmptyProductList className={style.emptyProductList} />,
    categoryType = CategoryType.Simple,
    variant = 'auto',
    listContext = 'common-list',
    className,
    productCardRootClassName,
}) => {
    const {isListLoading, isShowListingBanner} = useContext(ListingContext);
    const {pageSize, page} = useContext(PaginationContext);

    const renderListingBanner = useRenderListingBanner();

    const handleProductCardClick = useCallback<
        TClosureCallback<[number, ProductShort['id']], MouseEventHandler<HTMLDivElement>>
    >(
        ([position, productId]) => {
            return () => {
                const listingCount = productList.length;

                if (listingCount <= pageSize) {
                    localStorage.setItem(
                        SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY,
                        JSON.stringify({
                            productId,
                        })
                    );
                    return;
                }

                const safePageNumber = Math.ceil(position / pageSize) + Math.round(page - listingCount / pageSize);
                localStorage.setItem(
                    SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY,
                    JSON.stringify({
                        productId,
                        productPage: safePageNumber,
                    })
                );
            };
        },
        [page, pageSize, productList.length]
    );

    if (!productList.length) {
        return <>{emptyListComponent}</>;
    }

    return (
        <SkeletonWrapper
            className={classNames(className, {
                [style[listType]]: Boolean(listType),
            })}
            disabled={!isListLoading}
        >
            <Grid
                className={classNames(style.grid, {
                    [style.tile]: listType === PRODUCT_LIST_VIEW.tile,
                })}
            >
                {productList.map((product, i) => (
                    <Fragment key={product.id}>
                        <ProductCard
                            {...product}
                            categoryType={categoryType}
                            className={style.card}
                            classNameRoot={classNames(
                                style.col,
                                {[style.withSeparator]: false},
                                style.productListItem,
                                productCardRootClassName
                            )}
                            listContext={listContext}
                            listType={listType}
                            onClick={handleProductCardClick([i + 1, product.id])}
                            variant={variant}
                        />
                        {isShowListingBanner &&
                            renderListingBanner({
                                className: columnStyle,
                                idx: i,
                                multiplicity: 6,
                            })}
                    </Fragment>
                ))}
            </Grid>
        </SkeletonWrapper>
    );
};
