import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import closeIcon from 'public/icons/close.svg';
import React, {memo, MouseEventHandler, PropsWithChildren} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, PropsWithChildren {
    isActive?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const Tag = memo<IProps>(function Tag({isActive = false, onClick, children, className}) {
    return (
        <button className={classNames(style.tag, className)} data-skeleton-item onClick={onClick}>
            {children}
            {isActive && <SvgIcon svg={closeIcon} />}
        </button>
    );
});
