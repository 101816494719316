import {IActiveShop} from 'modules/shops/pages/ShopListPage';
import {YandexMapPlaceMark} from 'modules/yandex-map';
import {TYandexMapPlaceMarkClickEvent} from 'modules/yandex-map/components/YandexMapPlacemark';
import {YANDEX_MAP_ZOOM_CLICKED} from 'modules/yandex-map/constants';
import {getFormattedCoordinates, getPlacemarkProperties, TGetFormattedCoordinates} from 'modules/yandex-map/helpers';
import {useGetFormattedCoordinates} from 'modules/yandex-map/hooks/useGetFormattedCoordinates';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {StoreShort} from 'new-models';
import {ReactNode, useCallback, useMemo, useState} from 'react';

export const useShopsMarkersMapList = (
    stores: StoreShort[]
): {
    activeShop?: IActiveShop;
    mapCenter: TGetFormattedCoordinates;
    placeMarkList: ReactNode[];
    setActiveShop: (activeShop?: IActiveShop) => void;
} => {
    const [activeShop, setActiveShop] = useState<IActiveShop>();
    const mapCenter = useGetFormattedCoordinates(activeShop || stores[0]);

    const handleClickPlaceMark = useCallback(
        (shopData: StoreShort) => (_: TYandexMapPlaceMarkClickEvent, mapApi: YandexMapService) => {
            setActiveShop({
                ...shopData,
                isClickedPlaceMark: true,
            });

            mapApi.map?.setCenter(getFormattedCoordinates(shopData), YANDEX_MAP_ZOOM_CLICKED);
        },
        []
    );

    const placeMarkList = useMemo<ReactNode[]>(() => {
        return stores.map((shop) => {
            const {id} = shop;

            return (
                <YandexMapPlaceMark
                    geometry={getFormattedCoordinates(shop)}
                    key={id}
                    onClick={handleClickPlaceMark(shop)}
                    properties={getPlacemarkProperties(id)}
                />
            );
        });
    }, [handleClickPlaceMark, stores]);

    return {activeShop, mapCenter, placeMarkList, setActiveShop};
};
