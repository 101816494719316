import classNames from 'classnames';
import {Button} from 'components/Button';
import {CollapseAnimated} from 'components/CollapseAnimated';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {InputCaption} from 'components/InputCaption';
import {SvgIcon} from 'components/SvgIcon';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {useEditAddressModalApi} from 'modules/addresses/hooks/useEditAddressModalApi';
import {CheckoutDeliveryAddressItem} from 'modules/checkout/components/CheckoutDeliveryAddressItem';
import {USER_ADDRESS_ID_FIELD_NAME} from 'modules/checkout/constants/fields';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {DeliveryAddress} from 'new-models';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {memo, useCallback, useMemo, useState} from 'react';

const columnStyle = getGridColumnStyles();

import {useCheckoutDeliveryAddress} from 'modules/checkout/hooks/useCheckoutDeliveryAddress';

import style from './style.module.scss';

interface IProps {
    list: DeliveryAddress[];
}

const DEFAULT_COUNT_MAX_VISIBLE_ADDRESS = 3;

export const CheckoutDeliveryAddressList = memo<IProps>(function CheckoutDeliveryAddressList({list}) {
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);

    useCheckoutDeliveryAddress(list);

    const {getFieldMeta, submitCount} = useCheckoutFormikContext();
    const fieldMeta = getFieldMeta(USER_ADDRESS_ID_FIELD_NAME);

    const isOnceSubmitted = Boolean(submitCount);

    const {open: openEditModal} = useEditAddressModalApi();

    const [visibleList, hideList] = useMemo(() => {
        if (list.length <= DEFAULT_COUNT_MAX_VISIBLE_ADDRESS) {
            return [list, []];
        }

        return [list.slice(0, DEFAULT_COUNT_MAX_VISIBLE_ADDRESS), list.slice(DEFAULT_COUNT_MAX_VISIBLE_ADDRESS)];
    }, [list]);

    const onToggleCollapse = useCallback(() => setIsOpenCollapse((prevState) => !prevState), []);

    if (!visibleList.length) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const renderAddressItem = useCallback(
        (item: DeliveryAddress) => {
            return (
                <Field key={item.id} name={USER_ADDRESS_ID_FIELD_NAME} type="radio" value={item.id}>
                    {({field, meta}: FieldProps) => (
                        <CheckoutDeliveryAddressItem
                            {...field}
                            className={columnStyle}
                            error={isOnceSubmitted ? Boolean(meta.error) : undefined}
                            label={item.address}
                            onOpenModal={openEditModal(item.id)}
                        />
                    )}
                </Field>
            );
        },
        [isOnceSubmitted, openEditModal]
    );

    return (
        <Grid noRowGap>
            <Grid className={classNames(style.visibleList, columnStyle)}>{visibleList.map(renderAddressItem)}</Grid>

            {Boolean(hideList.length) && (
                <div className={columnStyle}>
                    <CollapseAnimated isHidden={!isOpenCollapse}>
                        <Grid className={style.hiddenList}>{hideList.map(renderAddressItem)}</Grid>
                    </CollapseAnimated>

                    <Button
                        className={style.button}
                        isStretched
                        onClick={onToggleCollapse}
                        rightIcon={
                            <SvgIcon
                                className={classNames(style.icon, {[style.rotateIcon]: isOpenCollapse})}
                                svg={chevronDownIcon}
                            />
                        }
                        theme="secondary"
                    >
                        {isOpenCollapse ? 'Скрыть все' : 'Показать все'}
                    </Button>
                </div>
            )}
            {isOnceSubmitted && fieldMeta.error && <InputCaption className={columnStyle} errorText={fieldMeta.error} />}
        </Grid>
    );
});
