import {DeliveryAddress} from 'new-models';
import {createContext} from 'react';

interface IAddressPageContextProps {
    addressList: DeliveryAddress[];
    isLoading?: boolean;
}

export const AddressPageContext = createContext<IAddressPageContextProps>({
    addressList: [],
});
