import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {SHOW_LOADER_DELAY_THRESHOLD} from 'modules/search-popup/constants';
import {selectIsSearchPopUpLoading} from 'modules/search-popup/selectors';
import {useCallback, useEffect, useRef, useState} from 'react';

export const usePendingLoader = (): boolean => {
    const loaderTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

    const isSearchDataLoading = useAppSelector(selectIsSearchPopUpLoading);

    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

    const showLoaderIcon = useCallback(() => {
        clearTimeout(loaderTimerRef.current);

        loaderTimerRef.current = setTimeout(() => {
            setIsLoaderVisible(true);
        }, SHOW_LOADER_DELAY_THRESHOLD);
    }, []);

    useEffect(() => {
        if (isSearchDataLoading) {
            showLoaderIcon();
            return;
        }

        clearTimeout(loaderTimerRef.current);
        setIsLoaderVisible(false);
    }, [isSearchDataLoading, showLoaderIcon]);

    return isLoaderVisible;
};
