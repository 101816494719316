import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {LogoLoader} from 'components/Loader';
import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import {EmptyFavouritesList} from 'modules/favourites/components/EmptyFavouritesList';
import {COLUMNS_COUNT_MD, COLUMNS_COUNT_SM} from 'modules/favourites/constants';
import {ProductList} from 'modules/products/components/ProductList';
import {PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {ProductShort} from 'new-models';
import {ListingContext} from 'plugins/modules/listing/context';
import React, {useContext} from 'react';
import {PAGES} from 'routing/constants';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();
const productCardColumnStyle = getGridColumnStyles({
    md: COLUMNS_COUNT_MD,
    sm: COLUMNS_COUNT_SM,
});

export const FavouritesPage: FC = () => {
    const {isListLoading, list} = useContext(ListingContext);

    return (
        <Grid container noRowGap>
            <div className={classNames(style.headerContainer, columnStyle)}>
                <Typography data-skeleton-item variant="h1">
                    {PAGES.FAVOURITES_PAGE.title}
                </Typography>
                <Typography color="gray70" data-skeleton-item variant="p">
                    {list?.length} {getProductPluralText(list?.length)}
                </Typography>
            </div>
            <div className={columnStyle}>
                {isListLoading ? (
                    <LogoLoader fixed />
                ) : (
                    <ProductList
                        emptyListComponent={<EmptyFavouritesList />}
                        productCardRootClassName={productCardColumnStyle}
                        productList={list as ProductShort[]}
                        variant={PRODUCT_CARD_VARIANT.favourites}
                    />
                )}
            </div>
        </Grid>
    );
};
