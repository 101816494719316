import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectNewList} from 'modules/products/selectors';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import {PromoPlace} from 'new-models';

export const HomePageNew = () => {
    const list = useAppSelector(selectNewList);

    if (!list?.length) {
        return null;
    }

    return (
        <PageSectionBlock href="/catalog/new" linkText="Все товары" title="Для новых кулинарных свершений">
            <SliderWithBanner href="/catalog/new" products={list} promoPlace={PromoPlace.New} />
        </PageSectionBlock>
    );
};
