import classNames from 'classnames';
// import {NoImage} from 'components/NoImage';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {CollectionXService} from 'modules/collection-x/services/CollectionXService';
// import {COLLECTION_CARD_RESIZING_MAP} from 'modules/collections/constants';
// import {ResizedImage} from 'modules/images/components/ResizedImage';
import {PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {TProductCardVariant} from 'modules/products/types';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {CollectionShort} from 'new-models';
import {useRouter} from 'next/router';
import React, {FC, useMemo} from 'react';
import {CATALOG_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export interface IProps extends CollectionShort {
    variant?: TProductCardVariant;
}

export const CollectionPreviewCard: FC<IProps> = ({variant = 'auto', title, id, code}) => {
    const router = useRouter();

    const categoryUrl = useMemo<string>(() => router.query[ROUTER_QUERY_KEY] as string, [router.query]);
    const collectionCardUrl = useMemo<string>(() => {
        const collectionUrl = CollectionXService.makeCollectionUrl(code, Number(id));

        return `${CATALOG_ROUTE}/${categoryUrl}/${collectionUrl}`;
    }, [categoryUrl, code, id]);

    return (
        <PrevDefaultLink className={style.link} href={collectionCardUrl} prefetch={false}>
            <div className={style.collectionCard}>
                {/* {image ? (
                    <div
                        className={classNames(style.imageContainer, {
                            [style.isPreview]: variant === PRODUCT_CARD_VARIANT.preview,
                        })}
                        data-skeleton-item
                    >
                        <ResizedImage
                            alt={image?.title}
                            loading="lazy"
                            resizingMap={COLLECTION_CARD_RESIZING_MAP}
                            src={image.title}
                            thumbnailOptions={{
                                element: null,
                                skeletoned: true,
                                zeroByteFallback: true,
                            }}
                            title={image?.title}
                        />
                    </div>
                ) : (
                    <NoImage />
                )} */}
                <div className={style.collectionCardContent}>
                    {/* <Typography className={style.collectionQuantity} color="gray60" data-skeleton-item>
                        {quantity} {plural(quantity, ['товар', 'товара', 'товаров'])} в коллекции
                    </Typography> */}
                    <Typography
                        className={classNames(style.collectionTitle, {
                            [style.isPreview]: variant === PRODUCT_CARD_VARIANT.preview,
                        })}
                        color="gray100"
                        data-skeleton-item
                        variant="h5"
                    >
                        {title}
                    </Typography>
                    {/* {priceFrom && (
                        <Typography color="gray80" data-skeleton-item element="p">
                            от{' '}
                            <Typography variant="price-preview-m">
                                {formatPrice((priceFrom?.price / PRICE_DENOMINATOR).toString())}
                            </Typography>{' '}
                            {'RUB' === priceFrom?.currency ? '₽' : ''}
                        </Typography>
                    )} */}
                </div>
            </div>
        </PrevDefaultLink>
    );
};
