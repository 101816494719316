import {PRICE_DENOMINATOR} from 'core/constants';
import {FilterChoose, FilterDateRange, FilterFlag, FilterRange, FilterType} from 'new-models';
import {FilterDatePickerRange} from 'plugins/modules/filter/components/FilterDateRange';
import {FilterFlagVariant} from 'plugins/modules/filter/components/FilterFlagVariant';
import {RangeFilter} from 'plugins/modules/filter/components/RangeFilter';
import {RegularVariantFilter} from 'plugins/modules/filter/components/RegularVariantFilter';
import {TFilter} from 'plugins/modules/filter/types';
import React, {FC, useCallback} from 'react';

interface IProps {
    filter: TFilter;
}

export const FilterItem: FC<IProps> = ({filter}) => {
    const {type} = filter;

    const calculate = useCallback(
        <T extends FilterRange['minValue']>(value: T) => value && value / PRICE_DENOMINATOR,
        []
    );

    switch (type) {
        case FilterType.Choose:
        case FilterType.Store: {
            return <RegularVariantFilter {...(filter as FilterChoose)} />;
        }

        case FilterType.Range: {
            const {minValue, minRange, maxRange, maxValue} = filter as FilterRange;
            return (
                <RangeFilter
                    {...(filter as FilterRange)}
                    maxRange={calculate(maxRange)}
                    maxValue={calculate(maxValue)}
                    minRange={calculate(minRange)}
                    minValue={calculate(minValue)}
                />
            );
        }

        case FilterType.DateRange: {
            return <FilterDatePickerRange {...(filter as FilterDateRange)} />;
        }

        case FilterType.Flag: {
            return <FilterFlagVariant {...(filter as FilterFlag)} />;
        }

        default: {
            return null;
        }
    }
};
