import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {FavouritesPage} from 'modules/favourites/pages/FavouritesPage';
import {FAVOURITES_SKELETON_PAGE_DATA} from 'modules/skeleton/constants';
import {ListingProvider} from 'plugins/modules/listing/provider';
import {FC} from 'react';

export const FavouritesPageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.favorite);

    return (
        <ListingProvider isListLoading list={FAVOURITES_SKELETON_PAGE_DATA.favouritesList} listType="list">
            <FavouritesPage />
        </ListingProvider>
    );
};
