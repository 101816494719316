import {MediaHarvester} from 'components/MediaHarvester';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectIsAnyPortalVisible} from 'modules/portal/selectors';
import {stopVideo} from 'modules/product-card/helpers';
import {GALLERY_MODE} from 'modules/slider/constants';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {isNeedPauseVideo} from 'modules/slider/helpers';
import {MediaVideo} from 'new-models';
import React, {useContext, useEffect, useRef, useState} from 'react';

interface IProps {
    isActive?: boolean;
    media: MediaVideo;
}

export const GalleryMainVideoSlide: FC<IProps> = ({isActive, media}) => {
    const {isVisible, mode} = useContext<IGallerySliderContextProps>(GallerySliderContext);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isActivePaused, setIsActivePaused] = useState(false);
    const isAnyModalVisible = useAppSelector(selectIsAnyPortalVisible);

    useEffect(() => {
        const isNeedPause = isNeedPauseVideo(isActive, isAnyModalVisible, mode);

        if (isNeedPause) {
            setIsActivePaused(true);
        }
    }, [isActive, isAnyModalVisible, mode]);

    useEffect(() => {
        if (isAnyModalVisible && mode === GALLERY_MODE.basic) {
            return;
        }

        if (!videoRef.current || isActive) {
            return;
        }

        stopVideo(videoRef);
        setIsActivePaused(false);
    }, [isActive, isAnyModalVisible, media, mode]);

    useEffect(() => {
        if (isAnyModalVisible) {
            return;
        }

        if (!videoRef.current || !isActive) {
            return;
        }

        if (!isVisible) {
            setIsActivePaused(true);
            videoRef.current.pause();

            return;
        }

        if (!isActivePaused) {
            videoRef.current.play();
        }
    }, [isActive, isActivePaused, isAnyModalVisible, isVisible, media, mode]);

    return <MediaHarvester media={media} ref={videoRef} />;
};
