import classNames from 'classnames';
import {PageHead} from 'components/PageHead';
import {useSeoMetaData} from 'core/hooks/useSeoMetaData';
import {SKELETON_BLOCK_NUMBER} from 'modules/content/constants';
import {ContentContext} from 'modules/content/context';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import React, {Fragment, useContext} from 'react';

import style from './style.module.scss';

export const ContentPage: FC = () => {
    const content = useContext(ContentContext);
    const isSkeleton = useIsSkeleton();

    const renderHTML = (rawHTML: string) =>
        // eslint-disable-next-line @typescript-eslint/naming-convention
        React.createElement('div', {dangerouslySetInnerHTML: {__html: unescape(rawHTML)}});

    const seoMetaData = useSeoMetaData({
        seoMetaData: content?.seoMetaData,
        titles: [content?.title],
    });

    return (
        <>
            <PageHead {...seoMetaData} />
            <main className={classNames(style.content, 'content-pages')}>
                <div className="container">
                    <h1 data-skeleton-item>{content?.title}</h1>

                    <div data-skeleton-item>{content?.content ? renderHTML(content?.content) : ''}</div>

                    {isSkeleton &&
                        Array.from(Array(SKELETON_BLOCK_NUMBER).keys()).map((_, index) => (
                            <Fragment key={index}>
                                <div data-skeleton-item>{content?.content ? renderHTML(content?.content) : ''}</div>
                                <br />
                            </Fragment>
                        ))}
                </div>
            </main>
        </>
    );
};
