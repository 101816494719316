import classNames from 'classnames';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {getElementPluralText} from 'core/helpers/plural';
import {PromoCardList} from 'modules/promo/components/PromoCardList';
import {ActionShort} from 'new-models';
import {ListPagination} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {usePageChange} from 'plugins/modules/pagination/hooks/usePageChange';
import React, {FC, useContext, useRef} from 'react';

import style from './style.module.scss';

interface IProps {
    promoList: ActionShort[];
}

export const PromoListPage: FC<IProps> = ({promoList}) => {
    const topNodeRef = useRef(null);
    const {count, page, pageSize} = useContext(PaginationContext);
    const onPageChange = usePageChange();

    return (
        <>
            <PageHeaderTitle className={style.container} theme="ghost">
                <Typography color="gray100" data-skeleton-item element="h1" variant="h1">
                    Акции
                </Typography>
            </PageHeaderTitle>
            <div className={classNames(style.container, style.areaContent)}>
                <PromoCardList promoList={promoList} />
                {Boolean(promoList.length >= pageSize) && (
                    <div className={style.paginationContainer} data-skeleton-item>
                        <ListPagination
                            onChange={onPageChange}
                            pageNomer={page}
                            pageSize={pageSize}
                            pluralFunction={getElementPluralText}
                            recordCount={count}
                            scrollTarget={topNodeRef.current}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
