import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {SimpleProductCard} from 'modules/products/components/SimpleProductCard';
import {getPromoActivePeriod} from 'modules/promo/helpers';
import {SliderWithExternalButton} from 'modules/slider/components/SliderWithExternalButton';
import {IProps as ISliderProps} from 'modules/slider/components/SliderWithExternalButton';
import {SLIDER_DIRECTION, SPACE_BETWEEN} from 'modules/slider/constants';
import {ActionTop, MediaTypeEnum} from 'new-models';
import {useCallback} from 'react';

import style from './style.module.scss';

const DEFAULT_BREAKPOINT_SLIDER: ISliderProps['breakpoints'] = {
    [0]: {
        spaceBetween: SPACE_BETWEEN.previews,
    },
    [MIN_DESKTOP_WIDTH]: {
        spaceBetween: SPACE_BETWEEN.default,
    },
};

interface IProps {
    promotion: ActionTop;
}

export const HomePagePromotionsItem: FC<IProps> = ({promotion}) => {
    const renderSlides = useCallback(() => {
        const productsSlides = (promotion.products?.products || []).map((slide, idx) => (
            <SimpleProductCard
                className={classNames(style.slideContent)}
                key={idx}
                {...slide}
                isWithSlider
                listType="list"
                variant="preview"
            />
        ));

        if (promotion.image) {
            return [
                <PrevDefaultLink className={style.banner} href={`/promotion/${promotion.code}`}>
                    <MediaHarvester
                        className={style.image}
                        media={promotion.image}
                        options={generateMediaOptions({
                            resizeMapKey: 'productSliderBanner',
                            type: MediaTypeEnum.ImageAdaptive,
                        })}
                    />
                </PrevDefaultLink>,
                ...productsSlides,
            ];
        }

        return productsSlides;
    }, [promotion.code, promotion.image, promotion.products?.products]);

    return (
        <PageSectionBlock
            className={style.inner}
            href={`/promotion/${promotion.code}`}
            linkText="Все товары"
            subtitle={
                <Typography className={style.subtitle} color="secondary100" variant="h7">
                    {getPromoActivePeriod('', promotion.activeTo)}
                </Typography>
            }
            title={promotion.title}
        >
            <div className={style.content}>
                <SliderWithExternalButton
                    autoplay={false}
                    breakpoints={DEFAULT_BREAKPOINT_SLIDER}
                    className={style.slider}
                    direction={SLIDER_DIRECTION.horizontal}
                    isNavigation
                    slidesPerView="auto"
                >
                    {renderSlides()}
                </SliderWithExternalButton>
            </div>
        </PageSectionBlock>
    );
};
