import {getVideoSrc} from 'components/VideoRutube/helpers/getVideoSrc';
import {TVideoId} from 'components/VideoRutube/type';
import {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    videoId: TVideoId;
}

export const VideoPlayerRutube = memo<IProps>(({videoId}) => {
    return <iframe className={style.root} src={getVideoSrc(videoId)} />;
});
