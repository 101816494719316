import {BaseUrlParam} from 'core/base/BaseUrlParam';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import {ROUTER_QUERY_TAG_OR_COLLECTION_KEY} from 'plugins/modules/filter/constants';

export type TFillStrategy = 'include' | 'exclude';

export abstract class AbstractUrlService {
    public static COMMON_PARAM_NAMES: Record<string, BaseUrlParam> = {
        PATH: new BaseUrlParam(ROUTER_QUERY_KEY, false),
        TAG: new BaseUrlParam(ROUTER_QUERY_TAG_OR_COLLECTION_KEY, false),
    };

    protected abstract options: {
        query: NextParsedUrlQuery;
    };

    // Агрегатор
    protected abstract query: URLSearchParams;

    // Параметры в виде объекта из query-провайдера
    protected abstract paramsList: Record<string, null | string>;

    // Колбэк на изменение параметра
    protected abstract onParamsChange(urlSearchParams: URLSearchParams): void;

    // Собрать параметры из query-провайдера
    protected abstract fill(fields: BaseUrlParam[], strategy: TFillStrategy): void;

    // Собрать URLSearchParams из paramsList
    protected abstract format(urlSearchParam: URLSearchParams): URLSearchParams;

    // Искусственно стриггерить запрос
    public abstract hydrate(): Promise<void>;

    public static mapToUrlSearchParams(urlSearchParams: URLSearchParams, record: Record<string, any>): void {
        Object.entries(record).forEach(([key, val]) => urlSearchParams.set(key, val));
    }
}
