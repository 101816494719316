import classNames from 'classnames';
import {Loader, LOADER_MODES, LOADER_THEMES} from 'components/Loader';
import {SvgIcon} from 'components/SvgIcon';
import {isMobileByScreen} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {MediaYoutube} from 'new-models';
import playIcon from 'public/icons/play.svg';
import React, {forwardRef, memo, ReactNode, useCallback, useMemo, useState} from 'react';
import YouTube, {YouTubeEvent} from 'react-youtube';

import style from './style.module.scss';

interface IYoutubeState {
    buffering: number;
    ended: number;
    notStart: number;
    paused: number;
    playing: number;
}

// подробнее https://developers.google.com/youtube/iframe_api_reference
export const YOUTUBE_STATE: IYoutubeState = {
    buffering: 3,
    ended: 0,
    notStart: -1,
    paused: 2,
    playing: 1,
};

const youtubePlayerOptions: YouTube['props']['opts'] = {
    height: '100%',
    width: '100%',
};

const VIDEO_ID = 'v';

export interface IVideoYoutubeProps extends MediaYoutube, TPropsWithClassName {
    loop?: boolean;
    onStateChange?: (youtubeApiEvent: YouTubeEvent<number>) => void;
    onYoutubeVideoReady?: (youtubeApiEvent: YouTubeEvent) => void;
    overlayOnlyMobile?: boolean;
}

const VideoPlayButton = memo(function VideoPlayButton() {
    return (
        <div className={style.videoPlayContent}>
            <SvgIcon svg={playIcon} />
        </div>
    );
});

export const VideoYoutube = forwardRef<HTMLDivElement, IVideoYoutubeProps>(function VideoYoutube(
    {className, loop = true, onStateChange, onYoutubeVideoReady, overlayOnlyMobile, url, ...video},
    ref
) {
    const [youtubeApiEvent, setYoutubeApiEvent] = useState<YouTubeEvent>();
    const [isShowOverlay, setIsShowOverlay] = useState(true);

    const handleYouTubePlayerReady = useCallback(
        (e: YouTubeEvent) => {
            setYoutubeApiEvent(e);
            onYoutubeVideoReady?.(e);
        },
        [onYoutubeVideoReady]
    );

    const handlePlayClick = useCallback(() => {
        if (!youtubeApiEvent) {
            return;
        }

        setIsShowOverlay(false);
        youtubeApiEvent.target.playVideo();
    }, [youtubeApiEvent]);

    const videoId = useMemo<string>(() => {
        if (!url) {
            return '';
        }

        const id = new URL(url).searchParams.get(VIDEO_ID);

        if (id) {
            return id;
        }

        const pathArr = url.split('/');

        return pathArr[pathArr.length - 1];
    }, [url]);

    const renderThumbnail = useMemo<ReactNode>(() => {
        if (!video || !video.preview) {
            return null;
        }

        return (
            <img
                alt={video.preview.alternative || ''}
                className={style.videoThumbnail}
                loading="lazy"
                src={video.preview.url}
            />
        );
    }, [video]);

    const renderPlayButton = useMemo<ReactNode>(() => {
        return (
            isShowOverlay && (
                <div className={style.videoPlayButtonContainer}>
                    {!youtubeApiEvent ? (
                        <Loader
                            className={style.videoPlayButtonPreloader}
                            mode={LOADER_MODES.compact}
                            theme={LOADER_THEMES.secondary}
                        />
                    ) : (
                        <button className={style.videoPlayButton} onClick={handlePlayClick}>
                            <VideoPlayButton />
                        </button>
                    )}
                </div>
            )
        );
    }, [isShowOverlay, youtubeApiEvent, handlePlayClick]);

    const renderPlayerOverlay = useMemo<ReactNode>(() => {
        if (!isShowOverlay) {
            return null;
        }

        if (overlayOnlyMobile) {
            if (!isMobileByScreen()) {
                return null;
            }
        }

        return (
            <>
                {renderThumbnail}
                {renderPlayButton}
            </>
        );
    }, [isShowOverlay, overlayOnlyMobile, renderPlayButton, renderThumbnail]);

    const handleStateChange = useCallback(
        (e: YouTubeEvent<number>) => {
            const {data, target} = e;

            onStateChange?.(e);

            if (YOUTUBE_STATE.ended === data && loop) {
                target.playVideo();

                return;
            }

            setIsShowOverlay(YOUTUBE_STATE.playing !== data);
        },
        [loop, onStateChange]
    );

    return (
        <div className={classNames(style.video, className)} ref={ref}>
            {renderPlayerOverlay}
            <YouTube
                className={style.videoPlayer}
                onReady={handleYouTubePlayerReady}
                onStateChange={handleStateChange}
                opts={youtubePlayerOptions}
                videoId={videoId}
            />
        </div>
    );
});
