import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {UNDERSTAND} from 'core/constants';
import {stripHtml} from 'core/helpers';
import {BaseFilter} from 'models';
import {Modal} from 'modules/modals/components/Modal';
import {RegularModal} from 'modules/modals/components/RegularModal';
import {MODALS} from 'modules/modals/constants';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps {
    content: BaseFilter['hint'];
    onClose?: () => void;
}

export const FilterTooltipModal: FC<IProps> = ({content, onClose}) => {
    return (
        <Modal hasPortal={false} name={MODALS.FILTER_TOOLTIP.name} onClose={onClose}>
            <RegularModal
                adaptive
                className={style.filterTooltipModal}
                hasClose
                overlayClassName={style.filterTooltipModalOverlay}
            >
                {content && (
                    <Typography
                        className={style.filterTooltipModalText}
                        color="gray100"
                        element="div"
                        variant="p-medium"
                    >
                        {stripHtml(content)}
                    </Typography>
                )}
                <Button data-modal-action="close" isStretched size="large" theme="primary">
                    {UNDERSTAND}
                </Button>
            </RegularModal>
        </Modal>
    );
};
