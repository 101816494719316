import 'react-toastify/dist/ReactToastify.css';

import classNames from 'classnames';
import {CookiePopup} from 'components/CookiePopup';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {Page} from 'components/Page';
import {TOAST_CLOSE_TIME} from 'core/constants';
import {isMobileByScreen} from 'core/helpers';
import {useIsIncludesRoute, useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {useRequestCommonData} from 'core/hooks/useRequestCommonData';
import {useRequestPrivateData} from 'core/hooks/useRequestPrivateData';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire} from 'core/types';
import {BottomBar} from 'modules/bottombar/components/BottomBar';
import {selectCartCheckedListCondition} from 'modules/cart/selectors';
import {DIYCatalogMenuMasonry} from 'modules/categories/components/Menu/DIY/DIYCatalogMenuMasonry';
import {FeedbackModal} from 'modules/common/components/FeedbackModal';
import {Footer} from 'modules/footer/components/Footer';
import {Header} from 'modules/header';
import {MobileHeader} from 'modules/header/components/MobileHeader';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useRequestLog} from 'modules/request-logger/hooks/useRequestLog';
import {MobileAuthMenu} from 'modules/user/components/MobileAuthMenu';
import {useLogout} from 'modules/user/hooks/useLogout';
import {useWishListNotification} from 'modules/wish-list/hooks/useWishListNotification';
import dynamic from 'next/dynamic';
import React, {memo} from 'react';
import {ToastContainer} from 'react-toastify';
import {CART_ROUTE, DASHBOARD_ROOT_ROUTE, PRODUCT_PATH} from 'routing/constants';

import style from './style.module.scss';

const ModalDialog = dynamic(() => import('modules/authentication/view/components/ModalDialog'), {
    loading: () => <ModalLazyLoader />,
});

const CartRemoveModal = dynamic(() => import('modules/cart/components/CartRemoveModal'), {
    loading: () => <ModalLazyLoader />,
});

const ShieldModal = dynamic(() => import('modules/shields/components/ShieldModal'), {
    loading: () => <ModalLazyLoader />,
});

const SearchMobileModal = dynamic(() => import('modules/search-popup/components/SearchMobileModal'), {
    loading: () => <ModalLazyLoader />,
});

const SubscriptionDialog = dynamic(() => import('modules/product-subscription/components/SubscriptionDialog'), {
    loading: () => <ModalLazyLoader />,
});

const BonusesModal = dynamic(() => import('modules/price-n-bonuses/components/BonusesModal'), {
    loading: () => <ModalLazyLoader />,
});

const WishListModal = dynamic(() => import('modules/wish-list/components/WishListModal'), {
    loading: () => <ModalLazyLoader />,
});

const AdaptiveLocationChooseModal = dynamic(() => import('modules/locations/components/AdaptiveLocationChooseModal'), {
    loading: () => <ModalLazyLoader />,
});

const AboutAddressMapModal = dynamic(() => import('modules/home-page/components/AboutAddressMapModal'), {
    loading: () => <ModalLazyLoader />,
});

export const Layout = memo<TPropsWithChildrenRequire>(function Layout({children}) {
    const isCartRoute = useIsNeedRoute([CART_ROUTE]);
    const isWithoutPaddingInProfile = useIsIncludesRoute([DASHBOARD_ROOT_ROUTE]);
    const isHigher = useIsNeedRoute([PRODUCT_PATH]);
    const isWithAdaptivePaddingInProduct = !useIsNeedRoute([PRODUCT_PATH]);
    useRequestLog();

    useRequestCommonData();
    useRequestPrivateData();
    useWishListNotification();
    useLogout();

    const checkedListCondition = useAppSelector(selectCartCheckedListCondition);

    return (
        <div
            className={classNames(style.layout, {
                [style.withTopPadding]: !isCartRoute,
                [style.withAdaptiveAdditionalPadding]: isWithAdaptivePaddingInProduct,
                [style.withoutTopPaddingOnMobile]: isWithoutPaddingInProfile || isCartRoute,
            })}
        >
            <Header />
            <MobileHeader />
            <main className={classNames(style.layoutContent)}>
                <Page>{children}</Page>
            </main>
            <CookiePopup />
            <Footer />
            <BottomBar />
            <ToastContainer
                autoClose={TOAST_CLOSE_TIME}
                className={classNames(style.toastContainer, {[style.isHigher]: isHigher})}
                closeButton={false}
                closeOnClick={false}
                hideProgressBar
                icon={false}
                position={isMobileByScreen() ? 'bottom-right' : 'top-right'}
            />
            <Modal disableOnPageLoading={false} name={MODALS.CATALOG_MENU.name} willCloseOnUrlChange willMount>
                <DIYCatalogMenuMasonry />
            </Modal>
            <Modal disableOnPageLoading={false} name={MODALS.AUTH_MENU.name} willCloseOnUrlChange willMount>
                <MobileAuthMenu />
            </Modal>
            <Modal name={MODALS.CART_REMOVE.name} willMount>
                <CartRemoveModal checkedListCondition={checkedListCondition} />
            </Modal>
            <Modal disableOnPageLoading={false} name={MODALS.MODAL_DIALOG.name} willMount>
                <ModalDialog />
            </Modal>
            <Modal hasPortal name={MODALS.SHIELD.name} willMount>
                <ShieldModal />
            </Modal>
            <Modal name={MODALS.SEARCH_MOBILE.name} willMount>
                <SearchMobileModal />
            </Modal>
            <Modal name={MODALS.SUBSCRIPTION_DIALOG.name} willMount>
                <SubscriptionDialog />
            </Modal>
            <Modal name={MODALS.WISH_LIST_MODAL.name} willMount>
                <WishListModal />
            </Modal>
            <AdaptiveLocationChooseModal />
            <Modal hasPortal name={MODALS.PRODUCT_PRICE.name} willMount>
                {/* TODO: Передать тип бонусной карты, когда будет реализована логика работы с картами лояльности (type: LoyaltyCardType) */}
                <BonusesModal />
            </Modal>
            <Modal name={MODALS.FEEDBACK.name} willMount>
                <FeedbackModal />
            </Modal>
            <Modal name={MODALS.ABOUT_ADDRESS_MAP.name} willMount>
                <AboutAddressMapModal />
            </Modal>
        </div>
    );
});
