import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {CollectionDetail} from 'models';
import {
    TCollectionsListResponse,
    TPopularCollectionsListResponse,
    TRelatedCollectionsListResponse,
} from 'modules/collections/models';
import {IProductListParams} from 'modules/products/services/ProductsService';

export interface ICollectionsService {
    getCollections(query: URLSearchParams, options: IProductListParams): Promise<TCollectionsListResponse>;
}

export class CollectionsService extends EvolutionHttpClient implements ICollectionsService {
    public async getCollections(
        query: URLSearchParams,
        options: IProductListParams
    ): Promise<TCollectionsListResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, options);

        const {data} = await this.get<TCollectionsListResponse>({
            url: `/collections?${query}`,
        });

        return data;
    }

    public async getRelatedCollections(
        query: URLSearchParams,
        collectionRefId: CollectionDetail['id'],
        options: IProductListParams
    ): Promise<TRelatedCollectionsListResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, options);

        const {data} = await this.get<TRelatedCollectionsListResponse>({
            url: `/collection/${collectionRefId}/related-collections?${query}`,
        });

        return data;
    }

    public async getPopularCollections(page = 1, limit = 4): Promise<TPopularCollectionsListResponse> {
        const {data} = await this.get<TPopularCollectionsListResponse>({
            url: `/collections/top?page=${page}&limit=${limit}`,
        });

        return data;
    }
}
