import {IMenuItem, IPasswordChangeValues} from 'modules/user/types';
import docOrderIcon from 'public/icons/doc-order.svg';
import locationMarkIcon from 'public/icons/location-mark.svg';
import logoutIcon from 'public/icons/logout.svg';
import profileIcon from 'public/icons/profile.svg';
import {LOGOUT_ROUTE, PROFILE_ADDRESS_ROUTE, PROFILE_ORDERS_ROUTE, PROFILE_ROOT_ROUTE} from 'routing/constants';

export const PROFILE_USER_MENU_LIST: IMenuItem[] = [
    {
        icon: profileIcon,
        shallow: true,
        text: 'Личные данные',
        url: PROFILE_ROOT_ROUTE,
    },
    {
        icon: docOrderIcon,
        shallow: true,
        text: 'Мои заказы',
        url: PROFILE_ORDERS_ROUTE,
    },
    {
        icon: locationMarkIcon,
        shallow: true,
        text: 'Мои адреса',
        url: PROFILE_ADDRESS_ROUTE,
    },
];

export const LOGOUT_ITEM: IMenuItem = {
    icon: logoutIcon,
    shallow: true,
    text: 'Выйти',
    url: LOGOUT_ROUTE,
};

export const PHONE_FIELD_NAME = 'phone' as const;
export const CODE_FIELD_NAME = 'code' as const;
export const EMAIL_FIELD_NAME = 'email' as const;

export const CHANGE_PHONE_VIEW = {
    changePhone: 'changePhone',
    enterCode: 'enterCode',
} as const;

export const CHANGE_EMAIL_VIEW = {
    changeEmail: 'changeEmail',
    enterCode: 'enterCode',
} as const;

export const REMOVE_ACCOUNT_VIEW = {
    clarifyingQuestion: 'clarifyingQuestion',
    enterCode: 'enterCode',
} as const;

export const PHONE_CHANGE_MODULE_NAME = 'profile.phoneChange' as const;
export const EMAIL_CHANGE_MODULE_NAME = 'profile.emailChange' as const;
export const PASSWORD_CHANGE_MODULE_NAME = 'profile.passwordChange' as const;
export const REMOVE_ACCOUNT_MODULE_NAME = 'profile.removeAccount' as const;

export const PASSWORD_CHANGE_FIELDS: {label: string; name: keyof IPasswordChangeValues}[] = [
    {
        label: 'Новый пароль',
        name: 'newPassword',
    },
    {
        label: 'Повторите новый пароль',
        name: 'duplicateNewPassword',
    },
];

export const GENDER_FIELDS: {gender: string; value: string}[] = [
    {gender: 'Женский пол', value: 'female'},
    {gender: 'Мужской пол', value: 'male'},
];
