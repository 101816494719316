import {useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {IS_GEOLOCATION_ON} from 'modules/locations/constants';
import {findLocation} from 'modules/locations/helpers';
import {useCheckForActuality} from 'modules/locations/hooks/useCheckForActuality';
import {useSetDefaultLocation} from 'modules/locations/hooks/useSetDefaultLocation';
import {selectIsLocationSelected, selectLocationsList, selectUserLocation} from 'modules/locations/selectors';
import {LocationService} from 'modules/locations/services/LocationService';
import {getLocationsList} from 'modules/locations/thunks';
import {MODALS} from 'modules/modals/constants';
import {actionSetPortalVisible} from 'modules/portal/slice';
import {useEffect} from 'react';
import {PROFILE_DELETE_ROUTE} from 'routing/constants';

export const useGeolocation = () => {
    const isProfileDeleteRoute = useIsNeedRoute([PROFILE_DELETE_ROUTE]);
    const dispatch = useAppDispatch();

    const tokenLocationId = LocationService.getUserCityId();

    const locations = useAppSelector(selectLocationsList);
    const userLocation = useAppSelector(selectUserLocation);
    const isLocationSelected = useAppSelector(selectIsLocationSelected);
    const checkForActuality = useCheckForActuality();
    const setDefaultLocation = useSetDefaultLocation();

    useEffect(() => {
        if (!locations) {
            dispatch(getLocationsList);
            return;
        }

        if ('geolocation' in navigator && !userLocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const currentLocation = findLocation({locations, userCoords: position.coords}) ?? null;

                if (!currentLocation) {
                    localStorage.removeItem(IS_GEOLOCATION_ON);
                    return;
                }

                checkForActuality(currentLocation);
            }, setDefaultLocation);
        }

        if (isProfileDeleteRoute) {
            return;
        }

        const isNeedConfirmLocation =
            !tokenLocationId ||
            (Boolean(userLocation?.id) && (tokenLocationId !== userLocation?.id || !isLocationSelected));

        if (isNeedConfirmLocation) {
            dispatch(actionSetPortalVisible(MODALS.CONFIRM_LOCATION.name));
        }
    }, [
        checkForActuality,
        dispatch,
        isLocationSelected,
        locations,
        setDefaultLocation,
        tokenLocationId,
        userLocation,
        isProfileDeleteRoute,
    ]);
};
