import {BreadCrumbs} from 'modules/breadcrumbs/components/BreadCrumbs';
import {Outlet} from 'modules/router/components/Outlet';
import {BREAD_CRUMBS_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

interface IProps {
    isHideBreadcrumbs?: boolean;
}

export const SkeletonPageLayout: FC<IProps> = ({isHideBreadcrumbs}) => {
    return (
        <>
            {!isHideBreadcrumbs && <BreadCrumbs crumbsList={BREAD_CRUMBS_SKELETON_DATA} />}
            <Outlet />
        </>
    );
};
