import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {THeaderCatalogMenu} from 'modules/menu/types';
import Link from 'next/link';
import React, {memo} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

interface IProps extends TPropsWithClassName {
    categoriesList: THeaderCatalogMenu;
}

export const DIYCatalogMenuListItem = memo<IProps>(function DIYCatalogMenuListItem({categoriesList, className}) {
    return (
        <Grid className={classNames(style.root, className)}>
            <div className={classNames(style.subcategoryList, columnStyle)}>
                {categoriesList.map((item) => {
                    const hasIcon = Boolean(item.icon);
                    return (
                        <div className={style.subcategoryContainer} key={item.id}>
                            <div className={style.subcategorySection}>
                                <Link href={getMenuItemUrl(item.url)} passHref>
                                    <Typography className={style.subcategoryLink} element="a" variant="elements-strong">
                                        {hasIcon && <img alt="" className={style.icon} src={item.icon?.url} />}
                                        {item.title}
                                    </Typography>
                                </Link>
                                {Boolean(item.childrenCategory.length) && (
                                    <div
                                        className={classNames(style.subChildren, {
                                            [style.parentHasIcon]: hasIcon,
                                        })}
                                    >
                                        {item.childrenCategory.map((child) => {
                                            return (
                                                <Link href={getMenuItemUrl(child.url)} key={child.id} passHref>
                                                    <Typography
                                                        className={style.subChildrenLink}
                                                        color="gray80"
                                                        element="a"
                                                        variant="p"
                                                    >
                                                        {child.title}
                                                    </Typography>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Grid>
    );
});
