import classNames from 'classnames';
import {Button, BUTTON_SIZE, BUTTON_THEMES} from 'components/Button';
import {Counter, COUNTER_SIZE} from 'components/Counter';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {CartProductImg} from 'modules/cart/components/CartProductImg';
import {REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {useCartModalActions} from 'modules/cart/hooks/useCartModalActions';
import {useCartProduct} from 'modules/cart/hooks/useCartProduct';
import {selectIsCartCountLoads} from 'modules/cart/selectors';
import {actionSetRemoveId} from 'modules/cart/slice';
import {FavouriteButton} from 'modules/favourites/components/FavouriteButton';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {usePriceAndBonuses} from 'modules/price-n-bonuses/hooks/usePriceAndBonuses';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {ShieldsList} from 'modules/shields/components/ShieldsList';
import {SHIELDS_LIST_VIEW} from 'modules/shields/constants';
import {BasketItem, ProductPrice} from 'new-models';
import Link from 'next/link';
import deleteIcon from 'public/icons/delete-20.svg';
import React, {memo, MouseEventHandler, useCallback, useMemo} from 'react';

import style from './style.module.scss';

export interface ICartProductItemProps extends BasketItem, TPropsWithClassName {}

// eslint-disable-next-line max-lines-per-function, complexity
export const CartProductItem = memo<ICartProductItemProps>(function CartProductItem({className, ...basketItem}) {
    const dispatch = useAppDispatch();
    const {quantity} = basketItem;
    const {id, title, price, code, availabilityCount, availability, nameplates} = basketItem.product;

    const isLoading = useAppSelector(selectIsCartCountLoads(id));

    const productUrl = useProductUrl({code, id});

    const {openCartRemoveModal} = useCartModalActions();
    const {
        handleCartCountAddClick,
        handleCartCountChange,
        handleCartCounterInputBlur,
        handleCartCountRemoveClick,
        cartProductCount,
    } = useCartProduct(basketItem.product);

    const {setCartUsedIn} = usePriceAndBonuses();

    const handleRemoveClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (event) => {
            dispatch(actionSetRemoveId(id));
            openCartRemoveModal(REMOVE_MODAL_VIEW.ONE_SELECTED)(event);
        },
        [dispatch, id, openCartRemoveModal]
    );

    const renderTotalPriceData = useMemo<ProductPrice>(() => {
        const {main, mainDiscount} = price;

        return {
            main: {...main, price: main.price * quantity},
            mainDiscount: mainDiscount ? {...mainDiscount, price: mainDiscount.price * quantity} : undefined,
        };
    }, [price, quantity]);

    const renderActions = useMemo(
        () =>
            availability && (
                <>
                    <PriceAndBonuses
                        className={style.finalOffer}
                        isProductAvailable={availability}
                        price={renderTotalPriceData}
                        usedIn={setCartUsedIn(false)}
                    />
                    <Counter
                        count={cartProductCount}
                        handleAddClick={handleCartCountAddClick}
                        handleChange={handleCartCountChange}
                        handleInputBlur={handleCartCounterInputBlur}
                        handleRemoveClick={handleCartCountRemoveClick}
                        isLoading={isLoading}
                        isStaticWidth
                        maxCount={availabilityCount}
                        minCount={1}
                        size={COUNTER_SIZE.small}
                    />
                </>
            ),
        [
            availability,
            availabilityCount,
            cartProductCount,
            handleCartCountAddClick,
            handleCartCountChange,
            handleCartCountRemoveClick,
            handleCartCounterInputBlur,
            isLoading,
            renderTotalPriceData,
            setCartUsedIn,
        ]
    );

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.mainContainer}>
                <CartProductImg className={style.imgBlock} productUrl={productUrl} {...basketItem.product} />
                <div className={style.infoContainer}>
                    <ShieldsList className={style.shieldsList} listType={SHIELDS_LIST_VIEW.list} shields={nameplates} />
                    <div className={style.mainInfo}>
                        <Link href={productUrl} prefetch={false}>
                            <a>
                                {/* {Boolean(article) && (*/}
                                {/*    <Typography*/}
                                {/*        color={availability ? 'gray60' : 'gray50'}*/}
                                {/*        data-skeleton-item*/}
                                {/*        variant="p-regular"*/}
                                {/*    >*/}
                                {/*        {article}*/}
                                {/*    </Typography>*/}
                                {/* )}*/}
                                <Typography
                                    className={style.title}
                                    color={availability ? 'gray100' : 'gray50'}
                                    data-skeleton-item
                                    element="div"
                                    variant="p"
                                >
                                    {title}
                                </Typography>
                                {price.main && availability && (
                                    <div className={style.price} data-skeleton-item>
                                        <Typography color="gray60" variant="p-regular">
                                            {formatPrice((price.main.price / PRICE_DENOMINATOR).toString(), {
                                                maximumFractionDigits: 2,
                                            })}
                                        </Typography>
                                        <Typography color="gray60" variant="p-regular">
                                            {price.main.unit ? `₽/${price.main.unit}.` : '₽'}
                                        </Typography>
                                    </div>
                                )}
                                {!availability && (
                                    <div className={style.endedText}>
                                        <Typography color="error50" variant="p-medium-strong">
                                            Закончился
                                        </Typography>
                                    </div>
                                )}
                            </a>
                        </Link>
                    </div>
                </div>
                <div className={style.controls}>
                    <FavouriteButton product={basketItem.product} theme={BUTTON_THEMES.ghost} />
                    <Button isSquare onClick={handleRemoveClick} size={BUTTON_SIZE.medium} theme={BUTTON_THEMES.ghost}>
                        <div className={style.iconWrap}>
                            <SvgIcon svg={deleteIcon} />
                        </div>
                    </Button>
                </div>
            </div>
            {!basketItem.isGift && (
                <div className={style.actionsWrapper}>
                    {Boolean(cartProductCount && availabilityCount && availabilityCount < cartProductCount) && (
                        <Typography color="error50" variant="p-medium-strong">
                            В наличии: {availabilityCount} шт.
                        </Typography>
                    )}
                    <div className={style.actions} data-skeleton-item>
                        {renderActions}
                    </div>
                </div>
            )}
        </div>
    );
});
