import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {
    TDeliveryCourierTimeSlotList,
    TDeliveryPickupPointList,
    TGetDeliveryMethodListResponse,
} from 'modules/checkout/models/DeliveriesResponse';
import {TCreateOrderResponse} from 'modules/checkout/models/OrderResponse';
import {TPaymentLinkResponse} from 'modules/checkout/models/PaymentLinkResponse';
import {TGetPaymentMethodListResponse} from 'modules/checkout/models/PaymentsResponse';
import {ISpecificDeliveryParams} from 'modules/checkout/services/CheckoutService';
import {
    actionSetCourierTimeSlotList,
    actionSetDeliveries,
    actionSetDeliveryInfo,
    actionSetNewOrder,
    actionSetPayments,
    actionSetPickupPoints,
} from 'modules/checkout/slice';
import {TProductDeliveryInfoResponse} from 'modules/product-card/services/ProductDeliveryInfoResponse';
import {OrderBase, OrderCreateRequest, ProductDetail} from 'new-models';

export const getDeliveryMethodList =
    (paymentId?: string): TAppThunk<void> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TGetDeliveryMethodListResponse>({
                requestCb: () => checkoutService.getDeliveryMethods(paymentId),
                type: actionSetPayments.type,
            })
        );
        const {deliveries} = data || {};

        dispatch(actionSetDeliveries(deliveries));
    };

export const getDeliveryInfo =
    (locationId: string, productIds?: ProductDetail['id'][] | null): TAppThunk<void> =>
    async (dispatch, _, {productCardService}) => {
        const data = await dispatch(
            requestAction<TProductDeliveryInfoResponse>({
                requestCb: () => productCardService.getDeliveryInfo(locationId, productIds),
                type: actionSetDeliveryInfo.type,
            })
        );

        const {deliveries} = data || {};

        if (!deliveries) {
            return;
        }

        dispatch(actionSetDeliveryInfo(deliveries));
    };

export const getPaymentMethodList =
    (deliveryId?: string): TAppThunk<void> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TGetPaymentMethodListResponse>({
                requestCb: () => checkoutService.getPaymentMethods(deliveryId),
                type: actionSetPayments.type,
            })
        );
        const {payments} = data || {};

        dispatch(actionSetPayments(payments));
    };

export const getPickupPointList =
    (deliveryId: string, params: ISpecificDeliveryParams): TAppThunk<void> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TDeliveryPickupPointList>({
                requestCb: () => checkoutService.getPickupPoints(deliveryId, params),
                type: actionSetPickupPoints.type,
            })
        );
        const {pickupPoints} = data || {};

        dispatch(actionSetPickupPoints(pickupPoints));
    };

export const getCourierTimeSlotList =
    (deliveryId: string, params: ISpecificDeliveryParams): TAppThunk<void> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TDeliveryCourierTimeSlotList>({
                requestCb: () => checkoutService.getCourierTimeSlots(deliveryId, params),
                type: actionSetCourierTimeSlotList.type,
            })
        );
        const {timeslots} = data || {};

        dispatch(actionSetCourierTimeSlotList(timeslots ?? []));
    };

export const createOrder =
    (body: OrderCreateRequest): TAppThunk<TCreateOrderResponse['data']> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TCreateOrderResponse>({
                requestCb: () => checkoutService.createOrder(body),
            })
        );

        const {order} = data || {};

        if (order) {
            dispatch(actionSetNewOrder(order));
        }

        return data;
    };

export const getPaymentLink =
    (id: OrderBase['id']): TAppThunk<string | undefined> =>
    async (dispatch, _, {checkoutService}) => {
        const data = await dispatch(
            requestAction<TPaymentLinkResponse>({
                requestCb: () => checkoutService.getPaymentLink(id),
                type: 'paymentLink',
            })
        );

        return data.paymentLink.value;
    };
