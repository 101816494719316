import {BrandMobileHeader} from 'modules/brand/components/BrandMobileHeader';
import {BrandPage} from 'modules/brand/pages/BrandPage';
import {BRAND_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import {CategoryThirdLevelSkeleton} from 'modules/skeleton/pages/CategoryThirdLevelSkeleton';
import React, {FC} from 'react';

export const BrandPageSkeleton: FC = () => {
    const {brandData} = BRAND_PAGE_SKELETON_DATA;

    return (
        <CategoryThirdLevelSkeleton
            ComponentHeader={<BrandPage brandData={brandData} />}
            MobileHeaderComponent={<BrandMobileHeader title={brandData.title} />}
            noMobileHeaderOffset
            withBanners={false}
        />
    );
};
