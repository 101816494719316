import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {useAddAddressModalApi} from 'modules/addresses/hooks/useAddAddressModalApi';
import {CheckoutDeliveryAddressList} from 'modules/checkout/components/CheckoutDeliveryAddressList';
import {CheckoutSection} from 'modules/checkout/components/CheckoutSection';
import {DeliveryAddress} from 'new-models';
import plusIcon from 'public/icons/plus.svg';
import {memo} from 'react';

export interface IProps extends TPropsWithClassName {
    addressList: DeliveryAddress[];
}

const AddAddressButton: FC = () => {
    const {open} = useAddAddressModalApi();

    return (
        <Button isHideTextAdaptive leftIcon={<SvgIcon svg={plusIcon} />} onClick={open} theme="ghost">
            Добавить
        </Button>
    );
};

export const CheckoutCourierAddressFromProfile = memo<IProps>(function CheckoutCourierAddressFromProfile({
    addressList,
    className,
}) {
    return (
        <CheckoutSection
            RightSideTitleComponent={AddAddressButton}
            className={className}
            isFluidGap
            title="Адрес доставки"
        >
            <CheckoutDeliveryAddressList list={addressList} />
        </CheckoutSection>
    );
});
