import {Button, BUTTON_SIZE, BUTTON_THEMES, IProps as IButtonProps} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {useFavourites} from 'modules/favourites/hooks/useFavourites';
import {ProductBase, ProductDetail, ProductShort} from 'new-models';
import favouriteIcon from 'public/icons/favourite.svg';
import favouriteCheckedIcon from 'public/icons/favourite-checked.svg';
import React, {memo, useCallback} from 'react';

interface IProps extends IButtonProps, TPropsWithClassName {
    product: ProductShort | ProductDetail;
}

export const FavouriteButton = memo<IProps>(function FavouriteButton({
    product,
    size = BUTTON_SIZE.medium,
    isNotAdaptive,
    className,
    theme = BUTTON_THEMES.secondary,
    ...rest
}) {
    const {switchFavourites, isChecked} = useFavourites(product as ProductBase);

    const handleFavouriteChange = useCallback(() => {
        switchFavourites(!isChecked);
    }, [isChecked, switchFavourites]);

    return (
        <Button
            {...rest}
            className={className}
            isNotAdaptive={isNotAdaptive}
            isSquare
            onClick={handleFavouriteChange}
            size={size}
            theme={theme}
        >
            <SvgIcon svg={isChecked ? favouriteCheckedIcon : favouriteIcon} />
        </Button>
    );
});
