import {HomeAbout} from 'modules/home-page/components/About';
import {HomePopularCategory} from 'modules/home-page/components/Categories';
// import {HomeCommercialBlock} from 'modules/home-page/components/CommercialBlock';
// import {HomeFooter} from 'modules/home-page/components/Footer';
// import {HomeGiftIdeas} from 'modules/home-page/components/GiftIdeas';
import {HomePageBanner} from 'modules/home-page/components/HomePageBanner';
import {HomePageBestseller} from 'modules/home-page/components/HomePageBestseller';
import {HomePageNew} from 'modules/home-page/components/HomePageNew';
import {HomePagePopular} from 'modules/home-page/components/HomePagePopular';
import {HomePagePromotions} from 'modules/home-page/components/HomePagePromotions';
import {HomeTopCollections} from 'modules/home-page/components/HomeTopCollections';
// import {HomePopularBrands} from 'modules/home-page/components/PopularBrands';
import {HomePageContext} from 'modules/home-page/context';
import React, {useContext} from 'react';

import style from './style.module.scss';

export const HomePage = () => {
    const {homeBannerSlideList, popularCategoryList} = useContext(HomePageContext);

    return (
        <section>
            {Boolean(homeBannerSlideList?.length) && <HomePageBanner bannerSlideList={homeBannerSlideList} />}
            <div className={style.content}>
                <HomePopularCategory categoryList={popularCategoryList} />
                <HomePagePromotions />
                <HomeTopCollections />
                <HomePagePopular />
                {/* <HomePopularBrands brandList={popularBrandList} />*/}
                {/* <HomeGiftIdeas giftCardSlideList={giftCardSlideList} />*/}
                {/* <HomeCommercialBlock*/}
                {/*    leftSlideList={summerFiestaLeftSlideList}*/}
                {/*    rightSlideList={summerFiestaRightSlideList}*/}
                {/*    slideList={summerFiestaSlideList}*/}
                {/* />*/}
                {/* <HomeCommercialBlock*/}
                {/*    isRevert*/}
                {/*    leftSlideList={floristryStudioLeftSlideList}*/}
                {/*    rightSlideList={floristryStudioRightSlideList}*/}
                {/*    slideList={floristryStudioSlideList}*/}
                {/* />*/}
                <HomePageBestseller />
                <HomePageNew />
                <HomeAbout />
            </div>

            {/* OLD components*/}
            {/* {Boolean(popularPromoList?.length) && (*/}
            {/*    <HomePageBlock>*/}
            {/*        <PopularPromoList promoList={popularPromoList} />*/}
            {/*    </HomePageBlock>*/}
            {/* )}*/}

            {/* {Boolean(popularCollectionList?.length) && (*/}
            {/*    <HomePageBlock>*/}
            {/*        <PopularCollectionList collectionList={popularCollectionList} />*/}
            {/*    </HomePageBlock>*/}
            {/* )}*/}

            {/* <HomePageBlock>*/}
            {/*    <HomePageLoyaltyProgramBanner />*/}
            {/* </HomePageBlock>*/}

            {/* {popularServices && Boolean(popularServices?.count) && (*/}
            {/*    <HomePageBlock title="Наши услуги">*/}
            {/*        <PopularServiceCardSlider count={popularServices.count} serviceList={popularServices.list} />*/}
            {/*    </HomePageBlock>*/}
            {/* )}*/}
            {/* <HomePageBlock>*/}
            {/*    <HomePageAdvantages advantagesList={HOME_PAGE_ADVANTAGES_LIST} />*/}
            {/* </HomePageBlock>*/}
            {/* <HomePageBlock>*/}
            {/*    <ViewedProductsList />*/}
            {/* </HomePageBlock>*/}
        </section>
    );
};
