import classNames from 'classnames';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {GalleryMainSlide} from 'modules/slider/components/GalleryMainSlide';
import {Slider} from 'modules/slider/components/Slider';
import {SPACE_BETWEEN} from 'modules/slider/constants';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {selectActiveIdx} from 'modules/slider/selectors';
import {MediaTypeEnum} from 'new-models';
import React, {FC, useContext, useId} from 'react';
import {SwiperProps} from 'swiper/swiper-react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, SwiperProps {
    customPrevRef?: React.MutableRefObject<HTMLButtonElement | null>;
    customNextRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export const GalleryMainSlider: FC<IProps> = ({className, customPrevRef, customNextRef, ...sliderProps}) => {
    const uid = useId();

    const {slidesList, mode, onClick} = useContext<IGallerySliderContextProps>(GallerySliderContext);
    const activeIdx = useAppSelector(selectActiveIdx);

    return (
        <Slider
            {...sliderProps}
            allowTouchMove={'fullscreen' !== mode}
            centeredSlides
            className={classNames(className, style.galleryMainSlider, {
                [style[mode]]: Boolean(mode),
            })}
            freeMode
            initialSlide={activeIdx}
            {...(customNextRef &&
                customPrevRef && {navigation: {nextEl: customNextRef.current, prevEl: customPrevRef.current}})}
            isPagination
            isSlideTo
            pagination={{dynamicBullets: true, dynamicMainBullets: 3}}
            slidesPerGroup={1}
            slidesPerView={1}
            spaceBetween={SPACE_BETWEEN.default}
        >
            {slidesList.map((slide, idx) => {
                return (
                    <GalleryMainSlide
                        className={classNames({[style.grabCursor]: 'fullscreen' === mode})}
                        isActive={activeIdx === idx}
                        key={`${uid}--${slide.url}`}
                        media={slide}
                        onClick={onClick({
                            activeSlideIdx: idx,
                            isNeedOpenModal: true,
                            isVideoButton: MediaTypeEnum.Youtube === slide.type,
                            path: slide.url,
                        })}
                    />
                );
            })}
        </Slider>
    );
};
