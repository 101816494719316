import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {TPropsWithClassName} from 'core/types';
import {CheckoutDeliveryDetails} from 'modules/checkout/components/CheckoutDeliveryDetails';
import {CheckoutDeliveryForm} from 'modules/checkout/components/CheckoutDeliveryForm';
import {CheckoutInfoBlock} from 'modules/checkout/components/CheckoutInfoBlock';
import {CheckoutPaymentType} from 'modules/checkout/components/CheckoutPaymentType';
import {CheckoutRecipientDataForm} from 'modules/checkout/components/CheckoutRecipientDataForm';
import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutDeliveryMethod} from 'modules/checkout/hooks/useCheckoutDeliveryMethod';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import dynamic from 'next/dynamic';
import React, {memo, useContext} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles({md: 8});

const CheckoutSelectShopModal = dynamic(() => import('modules/checkout/components/CheckoutSelectShopModal'), {
    loading: () => <ModalLazyLoader />,
});

const CheckoutMoreDetailsShopModal = dynamic(() => import('modules/checkout/components/CheckoutMoreDetailsShopModal'), {
    loading: () => <ModalLazyLoader />,
});

const AddressAddModal = dynamic(() => import('modules/addresses/components/AddressAddModal'), {
    loading: () => <ModalLazyLoader />,
});

const AddressEditModal = dynamic(() => import('modules/addresses/components/AddressEditModal'), {
    loading: () => <ModalLazyLoader />,
});

export interface IProps extends TPropsWithClassName {}

export const CheckoutMain = memo<IProps>(function CheckoutMain({className}) {
    const {paymentMethodList, deliveryMethodList} = useContext(CheckoutContext);
    const {deliveryId, handleDeliveryMethodChange} = useCheckoutDeliveryMethod();

    return (
        <>
            <Grid className={classNames(style.container, className)} gridColumnsCount={8}>
                <CheckoutInfoBlock className={columnStyle} />
                <CheckoutRecipientDataForm className={columnStyle} />
                <CheckoutDeliveryForm
                    className={columnStyle}
                    deliveryId={deliveryId}
                    deliveryMethods={deliveryMethodList}
                    onChange={handleDeliveryMethodChange}
                />
                <CheckoutDeliveryDetails className={columnStyle} deliveryId={deliveryId} />

                <CheckoutPaymentType className={columnStyle} paymentMethods={paymentMethodList} />
            </Grid>

            <Modal name={MODALS.CHECKOUT_SELECT_SHOP.name} willMount>
                <CheckoutSelectShopModal />
            </Modal>
            <Modal name={MODALS.CHECKOUT_MORE_DETAILS_SHOP.name} willMount>
                <CheckoutMoreDetailsShopModal />
            </Modal>
            <Modal name={MODALS.ADDRESS_ADD.name} willMount>
                <AddressAddModal isHideOptionalFields />
            </Modal>
            <Modal name={MODALS.ADDRESS_CHANGE.name} willMount>
                <AddressEditModal isHideOptionalFields />
            </Modal>
        </>
    );
});
