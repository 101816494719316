import {Anchor} from 'components/Anchor';
import {Button} from 'components/Button';
import {OnlyDesktop} from 'components/OnlyDesktop';
import {OnlyMobile} from 'components/OnlyMobile';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {ITooltipContent} from 'modules/shields/types';
import arrowCorrectIcon from 'public/icons/arrow-correct.svg';
import React, {FC, MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

export const ShieldTooltipContent: FC<ITooltipContent> = ({title, description, link, url}) => {
    const dispatch = useAppDispatch();

    const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            event.stopPropagation();
            dispatch(hideModal(MODALS.SHIELD.name));
        },
        [dispatch]
    );

    return (
        <div className={style.content}>
            <Typography className={style.title} variant="p-strong">
                {title}
            </Typography>
            <Typography className={style.description} variant="p-medium">
                {description}
            </Typography>
            <a href={url ?? undefined} onClick={handleClick} target="_blank">
                <OnlyDesktop displayType="contents">
                    <Anchor className={style.contentButtonDesktop} icon="right" size="button-l" theme="standalone">
                        {link}
                    </Anchor>
                </OnlyDesktop>
                <OnlyMobile displayType="contents">
                    <Button className={style.contentButtonAdaptive} isStretched>
                        {link}
                        <SvgIcon svg={arrowCorrectIcon} />
                    </Button>
                </OnlyMobile>
            </a>
        </div>
    );
};
