import {createDraftSafeSelector, createSelector} from '@reduxjs/toolkit';
import {TSelect} from 'core/redux/types';
import {ChooseVariant, FilterChoose, FilterRange, FilterType} from 'new-models';
import {IFilterState, TBuildRangeQueryData} from 'plugins/modules/filter/types';

export function createFilterSelectors<T extends IFilterState>(select: TSelect<T>) {
    const selectFilterList = createDraftSafeSelector([select], (state) => {
        return state.filterList;
    });

    const selectChooseFilterList = createDraftSafeSelector([selectFilterList], (filterList) => {
        return filterList?.filter(({type}) => type === FilterType.Choose) as FilterChoose[];
    });

    const selectAppliedChooseFilterList = createDraftSafeSelector(
        [selectChooseFilterList],
        (chooseFilterList): FilterChoose[] => {
            return chooseFilterList?.filter(({variants}) => variants.some((variant) => variant.isApplied));
        }
    );

    const selectAppliedChooseFilterByVariants = createDraftSafeSelector(
        [selectAppliedChooseFilterList],
        (appliedChooseFilterList) => {
            return appliedChooseFilterList.reduce((acc, filter) => {
                const appliedVariant = filter.variants.filter(({isApplied}) => isApplied) || [];

                return [
                    ...acc,
                    {
                        ...filter,
                        variants: appliedVariant,
                    },
                ];
            }, [] as FilterChoose[]);
        }
    );

    const selectRangeFilterList = createDraftSafeSelector([selectFilterList], (filterList) => {
        return filterList?.filter(({type}) => type === FilterType.Range) as FilterRange[];
    });

    const selectAppliedRangeFilterList = createDraftSafeSelector([selectRangeFilterList], (rangeFilterList) => {
        return rangeFilterList.filter(({maxRange, maxValue, minRange, minValue}) => {
            return (
                (maxValue !== maxRange && maxValue !== undefined) || (minRange !== minValue && minValue !== undefined)
            );
        });
    });

    const selectAppliedChooseFilterVariantIdList = createSelector(
        [selectAppliedChooseFilterList],
        (appliedChooseFilterList) => {
            return appliedChooseFilterList.reduce((acc: ChooseVariant['code'][], filter) => {
                return [...acc, ...filter.variants.map(({code}) => code)];
            }, []);
        }
    );

    const selectAppliedRangeFilterListKeyBy = createDraftSafeSelector(
        [selectAppliedRangeFilterList],
        (appliedRangeFilterList) => {
            return appliedRangeFilterList.reduce((acc: TBuildRangeQueryData, appliedRangeFilter) => {
                return {
                    ...acc,
                    [appliedRangeFilter.code]: String(appliedRangeFilter.minValue),
                    [appliedRangeFilter.code]: String(appliedRangeFilter.maxValue),
                };
            }, {} as TBuildRangeQueryData);
        }
    );

    const selectComboFilterList = createDraftSafeSelector([select], (state) => state.comboFilterList);

    const selectIsFilterPanelVisible = createDraftSafeSelector([select], (state) => state.isFilterPanelVisible);

    return {
        selectAppliedChooseFilterByVariants,
        selectAppliedChooseFilterVariantIdList,
        selectAppliedRangeFilterList,
        selectAppliedRangeFilterListKeyBy,
        selectComboFilterList,
        selectFilterList,
        selectIsFilterPanelVisible,
    };
}
