import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {FormikProps, useFormikContext} from 'formik';
import {debounce} from 'lodash';
import {useGeoLocationForMap} from 'modules/addresses/hooks/useGeoLocationForMap';
import {DaDataService} from 'modules/da-data/services/DaDataService';
import {selectUserLocation} from 'modules/locations/selectors';
import {YandexMap} from 'modules/yandex-map';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {DeliveryAddressBase} from 'new-models';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

const COORDINATE_THRESHOLD = 0.0001;
const DELAY = 1000;

export const AddressMap = <V extends DeliveryAddressBase>(formProps: FormikProps<V>) => {
    const markerLat = formProps.values.latitude;
    const markerLon = formProps.values.longitude;
    const userLocation = useAppSelector(selectUserLocation);
    const {positive, negative, handleCenter} = useGeoLocationForMap();

    const daDataService = useMemo(() => new DaDataService(), []);

    const {setFieldValue} = useFormikContext();

    const [yandexMapApi, setYandexMapApi] = useState<YandexMapService['map']>();
    const [center, setCenter] = useState<number[]>([]);

    const prevCenter = useRef<number[] | null>(null);

    const handleMapReady = useCallback((yandexApi: YandexMapService) => {
        setYandexMapApi(yandexApi.map);
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateCenterCoordinates = useCallback(
        debounce(async (newCenter: number[]) => {
            if (
                prevCenter.current &&
                COORDINATE_THRESHOLD > Math.abs(prevCenter.current[0] - newCenter[0]) &&
                COORDINATE_THRESHOLD > Math.abs(prevCenter.current[1] - newCenter[1])
            ) {
                return;
            }

            prevCenter.current = newCenter;

            const data = await daDataService.getDaDataAddressByCoords({lat: newCenter[0], lon: newCenter[1]});

            if (!data.suggestions.length) {
                return;
            }

            const suggestion = data.suggestions[0];
            setFieldValue('street', suggestion.data.street);

            setFieldValue('zip', suggestion.data.postal_code);
            setFieldValue('address', suggestion.value);
            setFieldValue('latitude', suggestion.data.geo_lat);
            setFieldValue('longitude', suggestion.data.geo_lon);
            setFieldValue('apartment', suggestion.data.flat ?? '');

            if (suggestion.data.house && suggestion.data.block) {
                setFieldValue('house', suggestion.data.house);
                setFieldValue('block', suggestion.data.block);
                return;
            }

            if (suggestion.data.house) {
                setFieldValue('house', suggestion.data.house);
            }
        }, DELAY),

        [daDataService, setFieldValue]
    );

    const boundsChangeHandler = useCallback((e: object | ymaps.IEvent<{}, {}>) => {
        const event = e as ymaps.IEvent<{}, {}>;
        const newCenter = event.get('newCenter');
        const oldCenter = event.get('oldCenter');

        if (newCenter !== oldCenter) {
            setCenter(newCenter);
            updateCenterCoordinates(newCenter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!yandexMapApi) {
            return;
        }

        yandexMapApi.events.add('boundschange', boundsChangeHandler);

        // eslint-disable-next-line consistent-return
        return () => {
            yandexMapApi.events.remove('boundschange', boundsChangeHandler);
        };
    }, [boundsChangeHandler, yandexMapApi]);

    useEffect(() => {
        if (markerLat && markerLon) {
            setCenter([Number(markerLat), Number(markerLon)]);
            return;
        }

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(positive(setCenter), negative(setCenter));
            return;
        }

        if (userLocation?.latitude && userLocation?.longitude) {
            setCenter([Number(userLocation?.latitude), Number(userLocation?.longitude)]);
        }
    }, [markerLat, markerLon, negative, positive, userLocation?.latitude, userLocation?.longitude]);

    useEffect(() => {
        if (markerLat && markerLon) {
            return;
        }

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                positive(updateCenterCoordinates),
                negative(updateCenterCoordinates)
            );
            return;
        }

        if (userLocation?.latitude && userLocation?.longitude) {
            updateCenterCoordinates([Number(userLocation?.latitude), Number(userLocation?.longitude)]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <YandexMap
            center={center}
            handleCenter={handleCenter(setCenter)}
            onMapReady={handleMapReady}
            withStaticCentredMarker
        />
    );
};
