import {ISegmentControlValueList} from 'components/SegmentedControl';

export const LIST_TAB: ISegmentControlValueList = {
    id: 0,
    label: 'Список',
    value: 'list',
};

export const MAP_TAB: ISegmentControlValueList = {
    id: 1,
    label: 'Карта',
    value: 'map',
};

export const TABS_LIST: ISegmentControlValueList[] = [MAP_TAB, LIST_TAB];

export const COLUMNS_COUNT_COURIER_ADDRESS_ITEM_SM = 6;
export const COLUMNS_COUNT_COURIER_ADDRESS_ITEM_MD = 3;

export const DISPLAYED_PRODUCTS_COUNT = 7;

export const STATUS_TITLES = {
    CANCELED: 'Заказ отменён',
    NOT_FOUND: 'Заказ не найден',
    NOT_PAID: 'Оплата не прошла',
    THANK: 'Спасибо за заказ!',
};

export const PAYMENT_METHODS = {
    ['card-or-sbp']: 'card-or-sbp',
    ['on-reciept']: 'on-reciept',
} as const;

export const CHECKOUT_INITIAL_VALUES = {
    comment: '',
    customer: {email: '', firstname: '', lastname: '', phone: ''},
    deliveryData: {address: {isPrivateHouse: false}, deliveryId: ''},
    paymentId: '',
    products: [],
};
