import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICalculatorState, TCalculationResult} from 'modules/calculators/types';

export const calculatorModuleName = 'calculator';

const initialState: ICalculatorState = {
    calculationResult: {},
    isAddedToCart: false,
    isAddedToWishList: false,
    selectedTab: {},
};
export const calculatorSlice = createSlice({
    initialState,
    name: calculatorModuleName,
    reducers: {
        actionCalculationAddToCart(state: ICalculatorState, {payload}: PayloadAction<boolean>) {
            state.isAddedToCart = payload;
        },
        actionCalculationAddToWishList(state: ICalculatorState, {payload}: PayloadAction<boolean>) {
            state.isAddedToWishList = payload;
        },
        actionPostCalculationResult(state: ICalculatorState, {payload}: PayloadAction<TCalculationResult>) {
            state.calculationResult = payload;
        },
        actionSetSelectedTab(state: ICalculatorState, {payload}: PayloadAction<ICalculatorState['selectedTab']>) {
            state.selectedTab = payload;
        },
    },
});

export const {
    actions: {
        actionCalculationAddToCart,
        actionCalculationAddToWishList,
        actionPostCalculationResult,
        actionSetSelectedTab,
    },
} = calculatorSlice;

export const {reducer: calculatorReducer} = calculatorSlice;
