import {SvgIcon} from 'components/SvgIcon';
import {FavouriteSwitcherBase} from 'modules/favourites/components/FavouriteSwitcher/Base';
import {useFavourites} from 'modules/favourites/hooks/useFavourites';
import {ProductShort} from 'new-models';
import recoverIcon from 'public/icons/recover.svg';
import {AllHTMLAttributes, ChangeEventHandler, FC, useCallback, useState} from 'react';

export interface IProps extends AllHTMLAttributes<HTMLElement> {
    product: ProductShort;
}

export const DELAY = 3000;

export const FavouriteSwitcherRecoverable: FC<IProps> = ({product, ...restInputAttributes}) => {
    const [recoverTimer, setRecoverTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
    const {removeProductInFavourites, isChecked, setIsChecked} = useFavourites(product);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            setIsChecked(event.target.checked);

            if (recoverTimer) {
                clearTimeout(recoverTimer);
                setRecoverTimer(null);
                return;
            }

            setRecoverTimer(
                setTimeout(() => {
                    removeProductInFavourites();
                }, DELAY)
            );
        },
        [recoverTimer, setIsChecked, removeProductInFavourites]
    );

    return (
        <FavouriteSwitcherBase
            isChecked={isChecked}
            notCheckedIcon={<SvgIcon svg={recoverIcon} />}
            onChange={handleChange}
            {...restInputAttributes}
        />
    );
};
