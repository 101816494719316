import {TPropsWithClassName} from 'core/types';
import React, {createContext, forwardRef, ReactNode} from 'react';

export const SkeletonContext = createContext<boolean>(false);

interface IProps extends TPropsWithClassName {
    children: ReactNode | ReactNode[];
    disabled?: boolean;
    fadeInEffect?: boolean;
}

export const SkeletonWrapper = forwardRef<HTMLDivElement, IProps>(
    ({children, className, disabled = false, fadeInEffect = false}, ref) => {
        if (disabled) {
            return <div className={className}>{children}</div>;
        }

        return (
            <div className={className} data-skeleton-item-fadein-effect={fadeInEffect} data-skeleton-wrapper ref={ref}>
                <SkeletonContext.Provider value>{children}</SkeletonContext.Provider>
            </div>
        );
    }
);
