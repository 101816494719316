import {PayloadAction} from '@reduxjs/toolkit';
import {IListingState} from 'plugins/modules/listing/types';

export function createListingActions<T extends IListingState>() {
    return {
        actionSetList(state: T, {payload}: PayloadAction<T['list']>) {
            state.list = payload;
        },

        actionSetListViewType(state: T, {payload}: PayloadAction<T['listType']>) {
            state.listType = payload;
        },
    };
}
