import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IPortalState} from 'modules/portal/types';

export const portalModuleName = 'portal';

const initialState: IPortalState = {};

export const portalSlice = createSlice({
    initialState,
    name: portalModuleName,
    reducers: {
        actionPortalHideAll(state: IPortalState) {
            Object.keys(state).forEach((portalName) => (state[portalName].visible = false));
        },

        actionSetPortalHidden(state: IPortalState, {payload}: PayloadAction<string>) {
            state[payload] = {
                visible: false,
            };
        },

        actionSetPortalVisible(state: IPortalState, {payload}: PayloadAction<string>) {
            state[payload] = {
                visible: true,
            };
        },
        actionTogglePortal(state: IPortalState, {payload}: PayloadAction<string>) {
            state[payload] = {
                visible: !state[payload]?.visible,
            };
        },
    },
});

export const {
    actions: {actionSetPortalHidden, actionSetPortalVisible, actionPortalHideAll, actionTogglePortal},
} = portalSlice;

export const {reducer: portalReducer} = portalSlice;
