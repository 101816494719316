import {Typography} from 'components/Typography';
import {getProductPluralText} from 'core/helpers/plural';
import {PriceItem} from 'modules/orders/components/OrderXPageTotalPrice/components/PriceItem';
import {useOrderXTotalPrice} from 'modules/orders/hooks/useOrderXTotalPrice';
import {DeliveryType, OrderDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

const getDeliveryTypeText = (deliveryType: DeliveryType) => {
    switch (deliveryType) {
        case DeliveryType.PICKUP:
            return 'Самовывоз';
        default:
            return 'Доставка';
    }
};

interface IProps extends Pick<OrderDetail, 'total' | 'quantity'> {
    deliveryType: DeliveryType;
}

export const OrderXPageTotalPrice = memo<IProps>(function OrderXPageTotalPrice({total, deliveryType, quantity = 0}) {
    const {cost, deliveryCost, discount, totalCost} = useOrderXTotalPrice(total);

    return (
        <div className={style.root}>
            <div className={style.row}>
                <Typography className={style.left} color="gray70" element="p" variant="p">
                    {quantity} {getProductPluralText(quantity)}
                </Typography>
                <Typography element="p" variant="p">
                    {cost}
                </Typography>
            </div>
            {Boolean(discount) && (
                <div className={style.row}>
                    <Typography className={style.left} color="gray70" element="p" variant="p">
                        Скидка
                    </Typography>
                    <Typography element="p" variant="p">
                        {discount}
                    </Typography>
                </div>
            )}
            <div className={style.row}>
                <Typography className={style.left} color="gray70" element="p" variant="p">
                    {getDeliveryTypeText(deliveryType)}
                </Typography>
                <Typography element="p" variant="p">
                    {deliveryCost}
                </Typography>
            </div>
            <div className={style.row}>
                <Typography className={style.left} color="gray100" element="p" variant="p-strong">
                    Итого
                </Typography>
                <PriceItem cost={cost} isDiscount={Boolean(discount)} totalCost={totalCost} />
            </div>
        </div>
    );
});
