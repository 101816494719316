import {IHomePageState} from 'modules/home-page/types';
import {IServiceState} from 'modules/services/types';
import {ActionTop, BrandTop, CategoryShort, CollectionTop, ContentPage, Promo} from 'new-models';
import {createContext} from 'react';

export interface IHomePageContext extends IHomePageState {
    homeBannerSlideList: Promo[];
    homeAboutBanners: Promo[];
    homeBannerSecondSlideList: Promo[];
    popularCategoryList: CategoryShort[];
    popularBrandList: BrandTop[];
    popularPromoList: ActionTop[];
    popularCollectionList: CollectionTop[];
    popularServices: IServiceState['popularServices'];
    giftCardSlideList: Promo[];
    floristryStudioSlideList: Promo[];
    floristryStudioLeftSlideList: Promo[];
    floristryStudioRightSlideList: Promo[];
    summerFiestaSlideList: Promo[];
    summerFiestaLeftSlideList: Promo[];
    summerFiestaRightSlideList: Promo[];
    aboutSlideList: Promo[];
    aboutLeftSlideList: Promo[];
    aboutRightSlideList: Promo[];
    content: ContentPage | null;
}

export const HomePageContext = createContext<IHomePageContext>({
    aboutLeftSlideList: [],
    aboutRightSlideList: [],
    aboutSlideList: [],
    content: null,
    floristryStudioLeftSlideList: [],
    floristryStudioRightSlideList: [],
    floristryStudioSlideList: [],
    // TODO: Transferring services to the service module
    giftCardSlideList: [],
    homeAboutBanners: [],
    homeAdviceList: [],
    homeBannerSecondSlideList: [],
    homeBannerSlideList: [],
    homePrivateLabelList: [],
    homeServiceList: [],
    popularBrandList: [],
    popularCategoryList: [],
    popularCollectionList: [],
    popularPromoList: [],
    popularServices: null,
    summerFiestaLeftSlideList: [],
    summerFiestaRightSlideList: [],
    summerFiestaSlideList: [],
} as IHomePageContext);
