import {AddressForm} from 'modules/addresses/components/AddressForm';
import {AddressMap} from 'modules/addresses/components/AddressMap';
import {AddressModalLayout} from 'modules/addresses/components/AddressModalLayout';
import {FooterFields, HeaderFields} from 'modules/addresses/components/AddressOptionalFields';
import {useAddAddressForm} from 'modules/addresses/hooks/useAddAddressForm';
import React, {memo} from 'react';

interface IProps {
    isHideOptionalFields?: boolean;
}

const AddressAddModal = memo<IProps>(function AddressAddModal({isHideOptionalFields}) {
    const {initialValues, validationSchema, onSubmitForm} = useAddAddressForm();

    return (
        <AddressModalLayout isSmallerContent={isHideOptionalFields} title="Добавить адрес">
            <AddressForm<typeof initialValues>
                FooterComponent={!isHideOptionalFields ? FooterFields : undefined}
                HeaderComponent={!isHideOptionalFields ? HeaderFields : undefined}
                RightSideComponent={AddressMap}
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                validationSchema={validationSchema}
            />
        </AddressModalLayout>
    );
});

export default AddressAddModal;
