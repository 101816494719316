import {Anchor} from 'components/Anchor';
import {Typography} from 'components/Typography';
import {useAuthBySmsStrategy} from 'modules/authentication/hooks/strategies/useAuthBySmsStrategy';

import style from './style.module.scss';

export const AuthSuggestionBlock = () => {
    const {startAuthBySms} = useAuthBySmsStrategy();

    return (
        <div className={style.root} data-skeleton-item>
            <Typography color="gray80" element="p" variant="p-regular">
                Войдите, чтобы получать персональные скидки и предложения
            </Typography>
            <Anchor onClick={startAuthBySms} variant="p-regular">
                Войти или зарегистрироваться
            </Anchor>
        </div>
    );
};
