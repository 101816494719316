import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {HomePageContext} from 'modules/home-page/context';
import {HomePage} from 'modules/home-page/pages/HomePage';
import {HOME_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const HomePageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.main);

    return (
        <HomePageContext.Provider value={HOME_PAGE_SKELETON_DATA}>
            <HomePage />
        </HomePageContext.Provider>
    );
};
