import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {CollapseAnimated} from 'components/CollapseAnimated';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {HIDE} from 'core/constants';
import {TPropsWithClassName} from 'core/types';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {ProductDetail, ProductProperty, PropertyBooleanValue, PropertyListValue, PropertyStringValue} from 'new-models';
import {FilterUrlService} from 'plugins/modules/filter/services/FilterUrlService';
import chevronIcon from 'public/icons/chevron-down.svg';
import React, {FC, useCallback, useState} from 'react';
import {SEARCH_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    properties: ProductProperty[];
    productType: ProductDetail['type'];
}

const DISPLAYED_PROPERTIES_NUMBER = 8;
const BRAND_CODE = 'brand';

const PropertyValue: FC<ProductProperty> = (props) => {
    const {code, value} = props;
    if (code === BRAND_CODE) {
        return (
            <>
                {(value as PropertyListValue).map(({title, code: valueCode}, idx) => (
                    <Anchor
                        data-skeleton-item
                        href={`${SEARCH_ROUTE}?${FilterUrlService.COMMON_PARAM_NAMES.QUERY}=${valueCode}`}
                        size="large"
                        target="_blank"
                    >
                        {0 === idx ? title : ` ${title}`}
                    </Anchor>
                ))}
            </>
        );
    }

    if (value.constructor === Array) {
        return (
            <>
                {(value as PropertyListValue).map(({title}, idx) => (
                    <Typography
                        className={style.value}
                        data-skeleton-item
                        shrinkWidthOnTextWrapOptions={{
                            onResize: true,
                            onlyMobile: true,
                        }}
                    >
                        {0 === idx ? title : ` ${title}`}
                    </Typography>
                ))}
            </>
        );
    }

    if ('boolean' === typeof value) {
        return (
            <Typography
                className={style.value}
                data-skeleton-item
                shrinkWidthOnTextWrapOptions={{
                    onResize: true,
                    onlyMobile: true,
                }}
            >
                {(value as PropertyBooleanValue) ? 'Да' : 'Нет'}
            </Typography>
        );
    }

    return (
        <Typography
            className={style.value}
            data-skeleton-item
            shrinkWidthOnTextWrapOptions={{
                onResize: true,
                onlyMobile: true,
            }}
        >
            {value as PropertyStringValue}
        </Typography>
    );
};

export const ProductCardProperties: FC<IProps> = ({className, properties, productType}) => {
    const [isHidden, setIsHidden] = useState<boolean>(
        properties.length > DISPLAYED_PROPERTIES_NUMBER && productType !== PRODUCT_TYPE.technical
    );

    const renderProperty = useCallback((property: ProductProperty) => {
        return (
            <div className={style.productCardProperty} key={property.title}>
                <Typography
                    data-skeleton-item
                    shrinkWidthOnTextWrapOptions={{
                        onResize: true,
                        onlyMobile: true,
                    }}
                >
                    {property.title}
                </Typography>
                <div className={style.divider} />
                <PropertyValue {...property} />
            </div>
        );
    }, []);

    return (
        <div className={classNames(className, style.productCardProperties)} id={MAIN_INFO_DATA.PROPERTIES.name}>
            <Typography className={style.title} data-skeleton-item element="div" variant="h3">
                Характеристики
            </Typography>
            <div className={style.productCardPropertiesStatic}>
                {properties.slice(0, DISPLAYED_PROPERTIES_NUMBER).map((property) => renderProperty(property))}
            </div>
            {properties.length > DISPLAYED_PROPERTIES_NUMBER && (
                <>
                    <CollapseAnimated isHidden={isHidden}>
                        <div className={style.productCardPropertiesAnimated}>
                            {properties.slice(DISPLAYED_PROPERTIES_NUMBER).map((property) => renderProperty(property))}
                        </div>
                    </CollapseAnimated>
                    <Anchor
                        className={classNames(style.button, {
                            [style.propertiesHidden]: isHidden,
                        })}
                        data-skeleton-item
                        icon="right"
                        iconNode={<SvgIcon svg={chevronIcon} />}
                        onClick={() => setIsHidden(!isHidden)}
                        size="large"
                        theme="standalone"
                        withBorder={false}
                    >
                        {isHidden ? 'Все характеристики' : HIDE}
                    </Anchor>
                </>
            )}
        </div>
    );
};
