import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {OrderDetail} from 'new-models';
import React, {useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends Pick<OrderDetail, 'customer'>, TPropsWithClassName {}

export const User: FC<IProps> = ({customer}) => {
    const mountName = useMemo(() => {
        const name = [];

        if (customer?.firstname) {
            name.push(customer.firstname);
        }

        if (customer?.lastname) {
            name.push(customer.lastname);
        }
        return name.join(' ');
    }, [customer?.firstname, customer?.lastname]);

    if (!customer) {
        return null;
    }

    return (
        <div className={style.root}>
            <Typography color="gray60">Получатель</Typography>
            <div className={style.info}>
                <Typography>{mountName}</Typography>
                <Typography>{customer.phone}</Typography>
                {customer.email && <Typography>{customer.email}</Typography>}
            </div>
        </div>
    );
};
