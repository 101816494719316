import classNames from 'classnames';
import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useFormikContext} from 'formik';
import {IndividualDataMain} from 'modules/user/components/IndividualDataMain';
import {selectUserData} from 'modules/user/selectors';
import {memo} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

export const PersonalDataMain = memo(function PersonalDataMain() {
    const {isSubmitting} = useFormikContext();
    const userData = useAppSelector(selectUserData);

    if (!userData) {
        return null;
    }

    return (
        <Grid className={style.mainFormBlock}>
            <IndividualDataMain />
            <Button
                className={classNames(style.mainFormBlockBtn, columnStyle)}
                data-skeleton-item
                isLoading={isSubmitting}
                isNotAdaptive
                type="submit"
            >
                Сохранить изменения
            </Button>
        </Grid>
    );
});
