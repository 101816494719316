import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {actionSetCurrentEditAddressId} from 'modules/addresses/slice';
import {MODALS} from 'modules/modals/constants';
import {useModalApi} from 'modules/modals/hooks/useModalApi';
import {DeliveryAddress} from 'new-models';
import {useMemo} from 'react';

interface IUseEditAddressModalApi {
    close: () => void;
    open: (id: DeliveryAddress['id']) => () => void;
}
export const useEditAddressModalApi = (): IUseEditAddressModalApi => {
    const dispatch = useAppDispatch();
    const {open, close} = useModalApi();

    return useMemo(
        () => ({
            close: () => {
                close(MODALS.ADDRESS_CHANGE.name);
                dispatch(actionSetCurrentEditAddressId(null));
            },
            open: (id) => () => {
                dispatch(actionSetCurrentEditAddressId(id));
                open(MODALS.ADDRESS_CHANGE.name);
            },
        }),
        [close, dispatch, open]
    );
};
