import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {searchPageSlice} from 'modules/search-page/slice';
import {actionSetSearchQuery, searchPopupModuleName} from 'modules/search-popup/slice';
import {createSearchSelector} from 'plugins/modules/search/selectors';

const select = (state: TAppRootState) => state[searchPopupModuleName];

export const selectIsSearchPanelVisible = createDraftSafeSelector([select], (state) => state.isSearchPanelVisible);

export const selectIsSearchPopUpLoading = (state: TAppRootState) => selectIsLoading(actionSetSearchQuery.type)(state);

export const {
    selectSearchQuery,
    selectSearchCategoryList,
    selectList,
    selectSearchSuggestions,
    selectRecommendationsList,
} = createSearchSelector(select, searchPageSlice);
