import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {getParentPath} from 'core/helpers';
import {captureUrlIdRegExp} from 'core/regex';
import {TPropsWithClassName} from 'core/types';
import {useRouterIdPath} from 'modules/router/hooks/useRouterIdPath';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React, {memo, MouseEventHandler, ReactNode, useCallback, useMemo} from 'react';
import {HOME_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export const BACK_BUTTON_URL_TYPE = {
    new: 'new',
    old: 'old',
} as const;

export const BACK_BUTTON_THEMES = {
    general: 'general',
    ghost: 'ghost',
} as const;

export type TBackButtonUrlType = (typeof BACK_BUTTON_URL_TYPE)[keyof typeof BACK_BUTTON_URL_TYPE];
export type TBackButtonTheme = keyof typeof BACK_BUTTON_THEMES;

export interface IProps extends TPropsWithClassName {
    path?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isParentPath?: boolean;
    urlType?: TBackButtonUrlType;
    back?: boolean;
    isOnlyAdaptive?: boolean;
    theme?: TBackButtonTheme;
    isOnlyIcon?: boolean;
}

const LAST_NEW_URL_PARAM_LENGTH = 2;

export const BackButton = memo<IProps>(function BackButton({
    back = false,
    urlType = BACK_BUTTON_URL_TYPE.old,
    className,
    path,
    onClick,
    isParentPath = false,
    isOnlyAdaptive = true,
    theme = BACK_BUTTON_THEMES.general,
    isOnlyIcon = false,
}) {
    const router = useRouter();
    const chainedId = useRouterIdPath();

    const backUrl = useMemo<string>(() => {
        if (BACK_BUTTON_URL_TYPE.old === urlType) {
            return '';
        }

        if (LAST_NEW_URL_PARAM_LENGTH === chainedId.length) {
            return HOME_ROUTE;
        }
        return chainedId[0]?.replace(captureUrlIdRegExp, '') || HOME_ROUTE;
    }, [chainedId, urlType]);

    const backPath = useMemo<string>(() => {
        if (isParentPath && path) {
            return path;
        } else if (path) {
            return getParentPath(path);
        }

        return HOME_ROUTE;
    }, [isParentPath, path]);

    const handleBackClick = useCallback(() => {
        router.back();
    }, [router]);

    const renderContent = useMemo<ReactNode>(() => {
        return (
            <Anchor
                className={classNames(style.BackButton, !(back || Boolean(onClick)) && className, {
                    [style.onlyAdaptive]: isOnlyAdaptive,
                    [style[theme]]: Boolean(theme),
                    [style.onlyIcon]: isOnlyIcon,
                })}
                data-skeleton-item
                element="div"
                icon="left"
                size="large"
                theme="standalone"
            >
                {isOnlyIcon ? '' : 'Назад'}
            </Anchor>
        );
    }, [back, className, isOnlyAdaptive, isOnlyIcon, onClick, theme]);

    if (back) {
        return (
            <button
                className={classNames(style.BackButton, className, {
                    [style.onlyAdaptive]: isOnlyAdaptive,
                    [style[theme]]: Boolean(theme),
                })}
                onClick={handleBackClick}
            >
                {renderContent}
            </button>
        );
    }

    return onClick ? (
        <button
            className={classNames(style.BackButton, className, {
                [style.onlyAdaptive]: isOnlyAdaptive,
                [style[theme]]: Boolean(theme),
            })}
            onClick={onClick}
        >
            {renderContent}
        </button>
    ) : (
        <Link href={backUrl || backPath} passHref>
            {renderContent}
        </Link>
    );
});
