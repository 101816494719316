import {TAppRootState} from 'core/redux/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {
    actionSetPopularList,
    actionSetRecommendedProductList,
    actionSetSimilarProductList,
    actionSetViewedProductList,
    productsModuleName,
    productsSlice,
} from 'modules/products/slice';
import {TProductState} from 'modules/products/types';
import {createFilterSelectors} from 'plugins/modules/filter/selectors';
import {createListingSelectors} from 'plugins/modules/listing/selectors';
import {createPaginationSelectors} from 'plugins/modules/pagination/selectors';
import {createSortingSelectors} from 'plugins/modules/sorting/selectors';

const select = (state: TAppRootState): TProductState => state[productsModuleName];

export const selectViewedProductList = (state: TAppRootState): TProductState['viewedProductsList'] =>
    select(state).viewedProductsList;

export const selectRecommendedProductList = (state: TAppRootState): TProductState['recommendedProductsList'] =>
    select(state).recommendedProductsList;

export const selectSimilarProductList = (state: TAppRootState): TProductState['similarProductsList'] =>
    select(state).similarProductsList;

export const selectBestsellersList = (state: TAppRootState): TProductState['bestsellersList'] =>
    select(state).bestsellersList;

export const selectPopularList = (state: TAppRootState): TProductState['popularList'] => select(state).popularList;

export const selectNewList = (state: TAppRootState): TProductState['newList'] => select(state).newList;

export const selectIsViewedProductsLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetViewedProductList.type)(state);

export const selectIsRecommendedProductsLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetRecommendedProductList.type)(state);

export const selectIsSimilarProductsLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetSimilarProductList.type)(state);

export const selectIsPopularProductsLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetPopularList.type)(state);

export const {selectPageSize, selectPageCount, selectPage, selectPaginationType, selectPageSizeList} =
    createPaginationSelectors<TProductState>(select);

export const {
    selectIsFilterPanelVisible,
    selectAppliedChooseFilterByVariants,
    selectComboFilterList,
    selectFilterList,
    selectAppliedRangeFilterList,
} = createFilterSelectors<TProductState>(select);

export const {selectSortingList} = createSortingSelectors<TProductState>(select);

export const {selectListType, selectList, selectIsListingLoading, selectIsListingPartiallyLoading} =
    createListingSelectors<TProductState>(select, productsSlice);
