import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {FranchiseCreateDataRequest} from 'new-models';

export const handleFranchise =
    (values: FranchiseCreateDataRequest): TAppThunk<void> =>
    async (dispatch, _, {franchiseService}) => {
        await dispatch(
            requestAction<TResponse<undefined>>({
                requestCb: () => franchiseService.handleFranchise(values),
            })
        );
    };
