import {useFormikSubmit} from 'core/hooks/useFormikSubmit';
import {useAddAddressModalApi} from 'modules/addresses/hooks/useAddAddressModalApi';
import {useDeliveryAddressesActions} from 'modules/addresses/hooks/useDeliveryAddressesActions';
import {getAddressValidator} from 'modules/addresses/validators';
import {DeliveryAddressCreateDataRequest} from 'new-models';
import {useCallback, useMemo} from 'react';
import * as yup from 'yup';

const validationSchema = getAddressValidator({
    isDefault: yup.boolean().notRequired(),
});

export const useAddAddressForm = () => {
    const {create} = useDeliveryAddressesActions();
    const {close} = useAddAddressModalApi();

    const handleSubmitForm = useCallback(
        async (values: DeliveryAddressCreateDataRequest) => {
            await create({
                ...values,
                entrance: Number(values.entrance),
                floor: Number(values.floor),
                ...(values.isPrivateHouse && {apartment: '', doorcode: '', entrance: null, floor: null}),
            });
            close();
        },
        [close, create]
    );

    const initialValues = useMemo<DeliveryAddressCreateDataRequest>(() => {
        return {
            address: '',
            isDefault: false,
            isPrivateHouse: false,
        };
    }, []);

    const onSubmitForm = useFormikSubmit<DeliveryAddressCreateDataRequest>(handleSubmitForm);

    return {
        initialValues,
        onSubmitForm,
        validationSchema,
    };
};
