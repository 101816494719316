import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ICommonState} from 'modules/common/types';
import {HYDRATE} from 'next-redux-wrapper';

export const commonModuleName = 'common';

const initialState: ICommonState = {
    bannerList: [],
    feedbackSubjects: [],
};

export const commonSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[commonModuleName];
        },
    },
    initialState,
    name: commonModuleName,
    reducers: {
        actionSetBannerList(state: ICommonState, {payload}: PayloadAction<ICommonState['bannerList']>) {
            state.bannerList = payload;
        },

        actionSetFeedbackSubjects(state: ICommonState, {payload}: PayloadAction<ICommonState['feedbackSubjects']>) {
            state.feedbackSubjects = payload;
        },
    },
});

export const {
    actions: {actionSetBannerList, actionSetFeedbackSubjects},
} = commonSlice;

export const {reducer: commonReducer} = commonSlice;
