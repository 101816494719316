import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {BreadCrumbs} from 'modules/breadcrumbs/components/BreadCrumbs';
import {selectBreadCrumbsList} from 'modules/breadcrumbs/selectors';
import React, {FC} from 'react';

export const BreadCrumbProvider: FC = () => {
    const crumbsList = useAppSelector(selectBreadCrumbsList);

    return <BreadCrumbs crumbsList={crumbsList} />;
};
