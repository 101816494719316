export const USED_IN = {
    cartPopup: 'cartPopup',
    cartResume: 'cartResume',
    mainHeader: 'mainHeader',
    mainOffer: 'mainOffer',
    productSet: 'productSet',
    searchPanel: 'searchPanel',
    techListPreview: 'techListPreview',
    techPreview: 'techPreview',
    visualPreview: 'visualPreview',
} as const;
