import {IAuthContext} from 'modules/authentication/types';
import {createContext} from 'react';

export const AuthContext = createContext<IAuthContext>({
    data: null,
    errors: {},
    fieldsParams: {},
    loads: null,
    modalParams: null,
});
