import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IBreadCrumbsState} from 'modules/breadcrumbs/types';
import {HYDRATE} from 'next-redux-wrapper';

export const breadCrumbsModuleName = 'breadcrumbs';

const initialState: IBreadCrumbsState = {
    crumbsList: [],
};

export const breadCrumbsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[breadCrumbsModuleName];
        },
    },
    initialState,
    name: breadCrumbsModuleName,
    reducers: {
        actionSetBreadCrumbsList(state, {payload}: PayloadAction<IBreadCrumbsState['crumbsList']>) {
            state.crumbsList = payload;
        },
    },
});

export const {
    actions: {actionSetBreadCrumbsList},
} = breadCrumbsSlice;

export const {reducer: breadCrumbsReducer} = breadCrumbsSlice;
