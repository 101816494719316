import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IProductSubscriptionState} from 'modules/product-subscription/types';

export const productSubscriptionModuleName = 'product-subscription';

const initialState: IProductSubscriptionState = {
    product: null,
};

export const productSubscriptionSlice = createSlice({
    initialState,
    name: productSubscriptionModuleName,
    reducers: {
        actionFlushProductToSubscription(state: IProductSubscriptionState) {
            state.product = null;
        },

        actionSetProductToSubscription(
            state: IProductSubscriptionState,
            {payload}: PayloadAction<IProductSubscriptionState['product']>
        ) {
            state.product = payload;
        },
    },
});

export const {reducer: productSubscriptionReducer} = productSubscriptionSlice;

export const {
    actions: {actionFlushProductToSubscription, actionSetProductToSubscription},
} = productSubscriptionSlice;
