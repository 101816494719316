import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {CollectionsPage} from 'modules/collections/pages/CollectionsPage';
import {collectionsSlice} from 'modules/collections/slice';
import {COLLECTIONS_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import {ListingProvider} from 'plugins/modules/listing/provider';
import React, {FC} from 'react';

export const CollectionsPageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.catalog);

    return (
        <SliceProvider slice={collectionsSlice}>
            <ListingProvider list={COLLECTIONS_PAGE_SKELETON_DATA} listType="list">
                <CollectionsPage />
            </ListingProvider>
        </SliceProvider>
    );
};
