import {PayloadAction} from '@reduxjs/toolkit';
import {GLOBAL_FILTER_ACTIONS} from 'plugins/modules/filter/actions';
import {IFilterState} from 'plugins/modules/filter/types';

export function createFilterExtraReducers<T extends IFilterState>() {
    return {
        [GLOBAL_FILTER_ACTIONS.actionSetIsFilterPanelVisible.type]: (
            state: T,
            {payload}: PayloadAction<boolean>
        ): T => {
            return {
                ...state,
                isFilterPanelVisible: payload,
            };
        },
    };
}
