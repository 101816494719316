import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {ITypographyPublicProps} from 'components/Typography';
import {useDemoAlert} from 'core/hooks/useDemoAlert';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import Link from 'next/link';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends ITypographyPublicProps, TPropsWithClassName, TPropsWithChildrenRequire {
    href: string;
    isIgnoreLinkAction?: boolean;
}

export const ShowMoreTitleLink: FC<IProps> = ({className, children, href, isIgnoreLinkAction}) => {
    // TODO: Delete after adding functionality or pages
    const {handleShowDemoAlert} = useDemoAlert();

    return (
        <Link href={href} passHref>
            <Anchor
                className={classNames(style.showMoreIcon, className)}
                data-skeleton-item
                icon="right"
                isHideTextAdaptive
                onClick={isIgnoreLinkAction ? handleShowDemoAlert : undefined}
                size="large"
                theme="standalone"
                withBorder={false}
            >
                {children}
            </Anchor>
        </Link>
    );
};
