import {TAppRootState} from 'core/redux/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {actionSetLocationsList, actionSetUserLocation, locationsModuleName} from 'modules/locations/slice';
import {ILocationsState} from 'modules/locations/types';

const select = (state: TAppRootState): ILocationsState => state[locationsModuleName];

export const selectLocationsList = (state: TAppRootState): ILocationsState['locationsList'] =>
    select(state).locationsList;

export const selectUserLocation = (state: TAppRootState): ILocationsState['userLocation'] => select(state).userLocation;

export const selectIsLocationsListLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetLocationsList.type)(state);

export const selectIsChangeCityLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetUserLocation.type)(state);

export const selectIsLocationSelected = (state: TAppRootState): boolean => select(state).isLocationSelected;
