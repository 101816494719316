import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {Typography} from 'components/Typography';
import {getCategoryPluralText} from 'core/helpers/plural';
import {SHOWING_CATEGORIES_AMOUNT} from 'modules/search-popup/constants';
import {useSearch} from 'modules/search-popup/hooks/useSearch';
import {CategoryBase} from 'new-models';
import Link from 'next/link';
import React, {FC, memo, useCallback, useId, useMemo, useState} from 'react';
import styles from 'styles/search-styles.module.scss';

interface IProps {
    className?: string;
    title: string;
    data: CategoryBase[];
}

export const SearchPanelCategories: FC<IProps> = memo(function SearchPanelCategories({className, data, title}) {
    const {makeSearchCategoryUrl} = useSearch();
    const [isOpen, setIsOpen] = useState(false);
    const uid = useId();

    const categoriesData = useMemo<CategoryBase[] | null>(() => {
        if (!data.length) {
            return null;
        }

        if (isOpen) {
            return data;
        }

        return data.slice(0, SHOWING_CATEGORIES_AMOUNT);
    }, [data, isOpen]);

    const handleClick = useCallback(() => {
        setIsOpen((open) => !open);
    }, []);

    const renderData = useMemo<JSX.Element[] | null>(
        () =>
            categoriesData?.map(({title: linkTitle, id, code}) => (
                <li key={id}>
                    <Link href={makeSearchCategoryUrl(code, id)}>
                        <Typography data-skeleton-item element="a" variant="p">
                            {linkTitle || ''}
                        </Typography>
                    </Link>
                </li>
            )) || null,
        [categoriesData, makeSearchCategoryUrl]
    );

    const renderRemainder = useMemo<JSX.Element | null>(() => {
        const categoriesRemainder = data.length - SHOWING_CATEGORIES_AMOUNT;

        if (isOpen || 0 >= categoriesRemainder) {
            return null;
        }

        return (
            <li key={uid}>
                <Anchor
                    className={styles.searchPanelLink}
                    data-skeleton-item
                    icon="right"
                    onClick={handleClick}
                    size="large"
                    theme="standalone"
                    variant="elements-p"
                >
                    Ещё {categoriesRemainder} {getCategoryPluralText(categoriesRemainder)}
                </Anchor>
            </li>
        );
    }, [data.length, handleClick, isOpen, uid]);

    return (
        <div className={classNames(styles.searchPanelElement, className)}>
            <Typography color="gray50" data-skeleton-item element="p" variant="elements-p">
                {title}
            </Typography>
            <ul className={styles.searchPanelList}>
                {renderData}
                {renderRemainder}
            </ul>
        </div>
    );
});
