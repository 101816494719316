import classNames from 'classnames';
import {IProps as ISegmentRadioProps, SegmentedRadio} from 'components/SegmentedRadio';
import {Typography} from 'components/Typography';
import React, {memo} from 'react';

import style from './style.module.scss';

export interface IProps extends ISegmentRadioProps {
    noteText?: string;
}

export const CheckoutSegmentedRadioWIthNote = memo<IProps>(function CheckoutSegmentedRadioWIthNote(props) {
    const {className, noteText, isLabel = true, disabled} = props;
    return (
        <SegmentedRadio className={classNames(style.root, className)} isLabel={isLabel} {...props}>
            {Boolean(noteText && !disabled) && (
                <Typography className={style.note} color="gray60" variant="p-medium">
                    {noteText}
                </Typography>
            )}
        </SegmentedRadio>
    );
});
