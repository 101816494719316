import {ListingContext} from 'plugins/modules/listing/context';
import {useContext, useMemo} from 'react';

export const useIsListLoading = (): boolean => {
    const {isListLoading, isListPartiallyLoading} = useContext(ListingContext);

    return useMemo<boolean>(() => {
        return Boolean(isListLoading || isListPartiallyLoading);
    }, [isListLoading, isListPartiallyLoading]);
};
