import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {Advice} from 'models';
import {ProductCardDescription} from 'modules/product-card/components/ProductCardDescription';
import {ProductCardFiles} from 'modules/product-card/components/ProductCardFiles';
import {ProductCardProperties} from 'modules/product-card/components/ProductCardProperties';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {useIsNeedMainInfoBlock} from 'modules/product-card/hooks/useIsNeedMainInfoBlock';
import {SetProductsSlider} from 'modules/slider/components/SetPorductsSlider';
import {ProductDetail} from 'new-models';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    product: ProductDetail;
    isProductAvailable: boolean;
    advices?: Advice[] | null;
}
export const MainInfo: FC<IProps> = ({product, isProductAvailable, className}) => {
    const isNeedMainInfoBlock = useIsNeedMainInfoBlock({isProductAvailable, product});

    return (
        <div className={classNames(style.mainInfo, className)}>
            {isNeedMainInfoBlock(MAIN_INFO_DATA.SET_PRODUCTS.name) && <SetProductsSlider />}
            {isNeedMainInfoBlock(MAIN_INFO_DATA.DESCRIPTION.name) && (
                // isNeedMainInfoBlock проверяет на наличие description `!`
                <ProductCardDescription className={style.mainInfoItem} description={product.description!} />
            )}
            {isNeedMainInfoBlock(MAIN_INFO_DATA.PROPERTIES.name) && (
                <ProductCardProperties
                    className={style.mainInfoItem}
                    productType={product.type}
                    // isNeedMainInfoBlock проверяет на наличие properties `!`
                    properties={product.properties!}
                />
            )}
            {isNeedMainInfoBlock(MAIN_INFO_DATA.FILES.name) && (
                <ProductCardFiles className={style.mainInfoItem} files={product.files} />
            )}
        </div>
    );
};
