import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import Link from 'next/link';
import React, {memo, MouseEventHandler} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    letterList: string[];
    activeItem?: string;
    onClick?: (hash: string) => MouseEventHandler;
}

export const Alphabet = memo<IProps>(function Alphabet({letterList, activeItem, className, onClick}) {
    return (
        <ul className={classNames(style.alphabet, className)}>
            {letterList.map((letter) => {
                const hash = `#${letter}`;
                return (
                    <Typography element="li" key={letter} variant="h6">
                        <Link href={hash}>
                            <a
                                className={classNames(style.alphabetItem, style.alphabetLetter, {
                                    [style.active]: hash === activeItem,
                                })}
                                onClick={onClick?.(hash)}
                            >
                                {letter}
                            </a>
                        </Link>
                    </Typography>
                );
            })}
        </ul>
    );
});
