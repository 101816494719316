import {useRouter} from 'next/router';
import {useCallback} from 'react';
import {CART_ROUTE} from 'routing/constants';

export const useOpenCartPage = () => {
    const router = useRouter();

    return useCallback(async () => {
        await router.push(CART_ROUTE);
    }, [router]);
};
