import {IFilterState, TWithFilter} from 'plugins/modules/filter/types';

export const initialFilterState: IFilterState = {
    comboFilterList: [],
    filterList: [],
    isFilterPanelVisible: true,
};

export const withFilter = <T>(originState: T): TWithFilter<T> => {
    return {
        ...originState,
        ...initialFilterState,
    };
};
