export const ANIMATION_LATENCY = 300;
export const MIN_FETCH_SYMBOLS_AMOUNT = 3;
export const SHOWING_CATEGORIES_AMOUNT = 5;
export const SHOWING_CATEGORIES_ENLARGED_AMOUNT = 10;
export const SHOWING_PRODUCTS_AMOUNT = 5;
export const SHOWING_ADVICES_AMOUNT = 5;
export const MAX_SEARCH_TERM_LENGTH = 128;
export const NOTHING_FOUND_ERROR_CODE = 'queryNotFound';
export const SHOW_LOADER_DELAY_THRESHOLD = 350;

export const POPUP_TITLE = {
    categoriesTitle: 'Категории',
    goodsTitle: 'Товары',
    popularRequestsTitle: 'Популярные запросы',
};
