import classNames from 'classnames';
import {Button} from 'components/Button';
import {Counter, COUNTER_SIZE, TCounterSize} from 'components/Counter';
import {SvgIcon} from 'components/SvgIcon';
import {Toast} from 'components/Toast';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TClosureCallback} from 'core/types';
import {CART_MAX_SIZE, COUNT_ACTIONS, TOAST_NOTIFICATIONS} from 'modules/cart/constants';
import {IUseCartProduct} from 'modules/cart/hooks/useCartProduct';
import {useOpenCartPage} from 'modules/cart/hooks/useOpenCartPage';
import {selectCartProductsList} from 'modules/cart/selectors';
import {TCountActions, THandleCartCountClickParams} from 'modules/cart/types';
import {useProductCardActions} from 'modules/product-card-actions/hooks/useProductCardActions';
import {POSITION_IN_CARD_VARIANT, PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {TPositionInCardVariant, TProductCardVariant, TProductListView} from 'modules/products/types';
import {ProductShort} from 'new-models';
import addToCartIcon from 'public/icons/add-to-cart.svg';
import React, {FC, MouseEventHandler, ReactNode, useCallback, useMemo} from 'react';
import {toast} from 'react-toastify';

import style from './style.module.scss';

interface IProps extends ProductShort, IUseCartProduct {
    showPrice: boolean;
    variant: TProductListView | TProductCardVariant | TPositionInCardVariant;
    stretched?: boolean;
    isHorizontal?: boolean;
    isStaticWidth?: boolean;
    isShowIcon?: boolean;
    isProductInCart: boolean;
}

export const AddToCartBlock: FC<IProps> = ({
    showPrice,
    variant,
    stretched,
    isHorizontal = false,
    isStaticWidth = false,
    isShowIcon = false,
    ...rest
}) => {
    const {
        id,
        price,
        isLocalProductInCart,
        isProductInCart,
        handleCartCountAddClick,
        handleCartCountChange,
        handleCartCountRemoveClick,
        handleCartCounterInputBlur,
        cartProductCount,
    } = rest;
    // TODO: for the time of mixing two mock servers
    // const getTotalNumber = useCartTotal();
    const handleCartClick = useOpenCartPage();

    const {
        productCardActionsButtonsSize,
        isPreview,
        productCardActionsButtonsColor,
        isSquare,
        isStretched,
        isWithoutPaddings,
    } = useProductCardActions();

    const cartProductsList = useAppSelector(selectCartProductsList);

    const counterSize = useMemo<TCounterSize>(() => {
        if (POSITION_IN_CARD_VARIANT.productPageHeader === variant || isPreview(variant) || 'preview' === variant) {
            return COUNTER_SIZE.medium;
        }

        return COUNTER_SIZE.large;
    }, [isPreview, variant]);

    const renderAddToCardChildren = useMemo<ReactNode>(() => {
        if (PRODUCT_CARD_VARIANT.preview === variant || POSITION_IN_CARD_VARIANT.searchPanel === variant) {
            return <SvgIcon className={style.svg} svg={addToCartIcon} />;
        }

        if (isPreview(variant)) {
            return (
                <>
                    <SvgIcon className={style.svg} svg={addToCartIcon} />
                    <span className={style.text}>В корзину</span>
                </>
            );
        }

        if (isProductInCart) {
            return <>В корзине</>;
        }

        return (
            <>
                {isShowIcon && (
                    <>
                        <SvgIcon svg={addToCartIcon} />{' '}
                    </>
                )}
                В корзину{' '}
                {showPrice && price && price.main && (
                    <span className={style.addToCartPrice}>
                        <Typography variant="p">
                            {formatPrice((price.main.price / PRICE_DENOMINATOR).toString(), {
                                maximumFractionDigits: 2,
                            })}
                        </Typography>
                        <Typography className={style.addToCartPriceCurrency} variant="p-medium">
                            {price.main.unit ? `₽/${price.main.unit}.` : '₽'}
                        </Typography>
                    </span>
                )}
            </>
        );
    }, [isPreview, isProductInCart, isShowIcon, price, showPrice, variant]);

    const handleClick = useCallback<
        TClosureCallback<THandleCartCountClickParams, MouseEventHandler<HTMLButtonElement>>
    >(
        ({action, isCartBtn}: {action: TCountActions; isCartBtn?: boolean}) =>
            () => {
                const isNewProduct = !cartProductsList.find(({product: productInBasket}) => productInBasket.id === id);
                if (cartProductsList.length >= CART_MAX_SIZE && isNewProduct) {
                    toast(<Toast isNeedCloseButton text={TOAST_NOTIFICATIONS.CART_FILLED} theme="error" />, {
                        className: 'notification',
                    });
                }

                if (isCartBtn && isLocalProductInCart) {
                    handleCartClick();
                    return;
                }

                if (action === COUNT_ACTIONS.ADD || (isCartBtn && !isLocalProductInCart)) {
                    handleCartCountAddClick();
                    return;
                }

                handleCartCountRemoveClick();
            },
        [
            cartProductsList,
            isLocalProductInCart,
            handleCartCountRemoveClick,
            id,
            handleCartClick,
            handleCartCountAddClick,
        ]
    );

    return (
        <>
            {(PRODUCT_CARD_VARIANT.main === variant ||
                PRODUCT_CARD_VARIANT.mainBottom === variant ||
                !isProductInCart) && (
                <Button
                    className={classNames(style.addToCartButton, {
                        [style[variant]]: Boolean(variant),
                    })}
                    color={productCardActionsButtonsColor(isProductInCart)}
                    data-prevent-default-marker
                    data-stop-propagation-marker
                    isSquare={isSquare(variant)}
                    isStretched={stretched ?? isStretched(variant)}
                    isWithoutPaddings={isWithoutPaddings(variant)}
                    onClick={handleClick({
                        action: COUNT_ACTIONS.ADD,
                        isCartBtn: true,
                    })}
                    size={productCardActionsButtonsSize(variant)}
                >
                    {renderAddToCardChildren}
                </Button>
            )}
            <Counter
                className={classNames(style.addToCartCounter, {
                    [style.isDisplay]: isProductInCart,
                    [style[variant]]: Boolean(variant),
                })}
                count={cartProductCount}
                handleAddClick={handleClick({
                    action: COUNT_ACTIONS.ADD,
                })}
                handleChange={handleCartCountChange}
                handleInputBlur={handleCartCounterInputBlur}
                handleRemoveClick={handleClick({
                    action: COUNT_ACTIONS.REMOVE,
                })}
                isHorizontal={isHorizontal}
                isStaticWidth={isStaticWidth}
                // TODO: for the time of mixing two mock servers
                // maxCount={getTotalNumber(availability)}
                size={counterSize}
            />
        </>
    );
};
