import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {THomePageAdvicesResponse} from 'modules/home-page/models/HomePageAdvicesResponse';
import {THomePagePrivateLabelsResponse} from 'modules/home-page/models/HomePagePrivateLabelsResponse';
import {THomePageServicesResponse} from 'modules/home-page/models/HomePageServicesResponse';

export class HomePageService extends EvolutionHttpClient {
    public async getPrivateLabelsList(): Promise<THomePagePrivateLabelsResponse> {
        const {data} = await this.get<THomePagePrivateLabelsResponse>({
            url: '/labels',
        });

        return data;
    }

    public async getHomePageAdvicesList(): Promise<THomePageAdvicesResponse> {
        const {data} = await this.get<THomePageAdvicesResponse>({
            url: '/advices',
        });

        return data;
    }

    public async getHomePageServices(): Promise<THomePageServicesResponse> {
        const {data} = await this.get<THomePageServicesResponse>({
            url: '/services',
        });

        return data;
    }
}
