import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IBrandState} from 'modules/brand/types';
import {HYDRATE} from 'next-redux-wrapper';

export const brandModuleName = 'brand';

const initialState: IBrandState = {
    brandData: {} as IBrandState['brandData'],
    brandList: [],
    popularBrandList: [],
};

export const brandSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[brandModuleName];
        },
    },
    initialState,
    name: brandModuleName,
    reducers: {
        actionSetBrandData(state, {payload}: PayloadAction<IBrandState['brandData']>) {
            state.brandData = payload;
        },
        actionSetBrandList(state, {payload}: PayloadAction<IBrandState['brandList']>) {
            state.brandList = payload;
        },
        actionSetPopularBrandList(state, {payload}: PayloadAction<IBrandState['popularBrandList']>) {
            state.popularBrandList = payload;
        },
    },
});

export const {
    actions: {actionSetBrandData, actionSetBrandList, actionSetPopularBrandList},
} = brandSlice;

export const {reducer: brandReducer} = brandSlice;
