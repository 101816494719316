import {mountLinkUrlOptions} from 'core/helpers/mountLinkUrl';
import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {selectCategoriesList} from 'modules/categories/selectors';
import {parseBannerLinks} from 'modules/common/helpers';
import {actionSetBannerList, actionSetFeedbackSubjects} from 'modules/common/slice';
import {
    FeedbackCreateDataRequest,
    FeedbackSubjectsDataResponse,
    PromosDataResponse,
    SubscribeDataRequest,
} from 'new-models';

export const getBannerList: TAppThunk<void> = async (dispatch, getState, {commonService}) => {
    const data = await dispatch(
        requestAction<TResponse<PromosDataResponse | {}>>({
            requestCb: () => commonService.getPromoList(),
            type: actionSetBannerList.type,
        })
    );

    if (!('promos' in data)) {
        return;
    }

    // Монтирование урлов, которые понимает клиент
    const bannerList = parseBannerLinks(data.promos, mountLinkUrlOptions(selectCategoriesList(getState())));

    dispatch(actionSetBannerList(bannerList));
};

export const emailSubscribe =
    (values: SubscribeDataRequest): TAppThunk<void> =>
    async (dispatch, _, {commonService}) => {
        await dispatch(
            requestAction<TResponse<undefined>>({
                requestCb: () => commonService.emailSubscribe(values),
            })
        );
    };

export const getFeedbackSubjects: TAppThunk<void> = async (dispatch, _, {commonService}) => {
    const data = await dispatch(
        requestAction<TResponse<FeedbackSubjectsDataResponse>>({
            requestCb: () => commonService.getFeedbackSubjects(),
            type: actionSetFeedbackSubjects.type,
        })
    );

    dispatch(actionSetFeedbackSubjects(data.subjects));
};

export const handleFeedback =
    (values: FeedbackCreateDataRequest): TAppThunk<void> =>
    async (dispatch, _, {commonService}) => {
        await dispatch(
            requestAction<TResponse<undefined>>({
                requestCb: () => commonService.handleFeedback(values),
            })
        );
    };
