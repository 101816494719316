import {IProps as IMediaHarvesterProps} from 'components/MediaHarvester';
import {mountAdaptiveImageMaps} from 'modules/common/helpers';
import {IImageAdaptiveMap} from 'modules/images/types';
import {useMemo} from 'react';

export const useGetMediaHarvesterOptionsAdaptiveImage = (urlScreenVariant?: IImageAdaptiveMap['urlScreenVariant']) => {
    return useMemo<IMediaHarvesterProps['options']>(() => {
        return urlScreenVariant ? {adaptiveImage: {adaptiveMap: mountAdaptiveImageMaps(urlScreenVariant)}} : undefined;
    }, [urlScreenVariant]);
};
