import classNames from 'classnames';
import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {useAuthBySmsStrategy} from 'modules/authentication/hooks/strategies/useAuthBySmsStrategy';
import React, {memo, MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

export const LoginWarning = memo<TPropsWithClassName>(function LoginWarning({className}) {
    const {startAuthBySms} = useAuthBySmsStrategy();

    const handleLoginClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        startAuthBySms();
    }, [startAuthBySms]);

    return (
        <div className={classNames(className, style.container)}>
            <div>
                <Typography color="gray100" element="p" variant="p-strong">
                    Войдите или зарегистрируйтесь
                </Typography>
                <Typography color="gray100" element="p" variant="p-medium">
                    Чтобы составлять списки покупок, получать уведомления о наличии товаров и пользоваться остальными
                    возможностями интернет-магазина.
                </Typography>
            </div>
            <Button onClick={handleLoginClick} size="medium" theme="secondary">
                Войти или зарегистрироваться
            </Button>
        </div>
    );
});
