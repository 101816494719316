import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {hideModal, showModal} from 'modules/modals/thunks';
import {useMemo} from 'react';

export const useModalApi = (): {open: (modalName: string) => void; close: (modalName: string) => void} => {
    const dispatch = useAppDispatch();

    return useMemo(
        () => ({
            close: (modalName: string) => {
                dispatch(hideModal(modalName));
            },
            open: (modalName: string) => {
                dispatch(showModal(modalName));
            },
        }),
        [dispatch]
    );
};
