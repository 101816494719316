import classNames from 'classnames';
import {getGridColumnStyles, getGridStyles, Grid} from 'components/Grid';
import {Typography} from 'components/Typography';
import {BrandCard} from 'modules/brand/components/BrandCard';
import {TAlphabetData} from 'modules/brand/types';
import React, {memo} from 'react';

import style from './style.module.scss';

const liGridStyle = getGridStyles();
const containerLetterColumnStyle = getGridColumnStyles();
const containerBrandTileColumnStyle = getGridColumnStyles({md: 9, sm: 12});
const letterColumnStyle = getGridColumnStyles({md: 3, sm: 12});
const brandTileColumnStyle = getGridColumnStyles({md: 4, sm: 6});

interface IProps {
    alphabetData: TAlphabetData;
}

export const BrandTileView = memo<IProps>(function BrandTileView({alphabetData}) {
    return (
        <Grid as="ul" className={style.root}>
            {Object.entries(alphabetData).map(([letter, brandList]) => {
                return (
                    <li
                        className={classNames(liGridStyle, containerLetterColumnStyle)}
                        data-intersection-mark={`#${letter}`}
                        data-scroll-mark={`#${letter}`}
                        key={letter}
                    >
                        <Typography
                            className={letterColumnStyle}
                            color="primary50"
                            data-skeleton-item
                            element="div"
                            variant="h2-bold"
                        >
                            {letter}
                        </Typography>

                        <Grid as="ul" className={containerBrandTileColumnStyle}>
                            {brandList.map((brand) => {
                                return (
                                    <li className={brandTileColumnStyle} key={brand.id}>
                                        <BrandCard brand={brand} className={style.brandCard} />
                                    </li>
                                );
                            })}
                        </Grid>
                    </li>
                );
            })}
        </Grid>
    );
});
