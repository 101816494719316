import {getRuntimeConfig} from 'core/next/helpers';
import GoogleAnalytics from 'modules/analytics/components/AnalyticScripts/GoogleAnalytics';
import YandexMetrikaScript from 'modules/analytics/components/AnalyticScripts/YandexMetrikaScript';
import {FC} from 'react';

const AnalyticScripts: FC = () => {
    const yaMetrikaId = getRuntimeConfig().NEXT_PUBLIC_YANDEX_METRIKA_ID;
    const googleAnalyticsId = getRuntimeConfig().NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

    return (
        <>
            {Boolean(yaMetrikaId) && <YandexMetrikaScript yaMetrikaId={yaMetrikaId || ''} />}
            {Boolean(googleAnalyticsId) && <GoogleAnalytics googleAnalyticsId={googleAnalyticsId || ''} />}
        </>
    );
};

export default AnalyticScripts;
