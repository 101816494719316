import {HeaderRequestInterceptor} from 'core/interceptors/HeaderRequestInterceptor';
import {AUTH_TOKEN_HEADER_NAME, AuthSdk} from 'core/services/AuthSdk';
import {CookieService} from 'core/services/CookieService';
import {TRequestOption, TRequestParameters} from 'core/types';

export class TokenRequestInterceptor extends HeaderRequestInterceptor {
    public override async intercept(
        requestOption: TRequestParameters<TRequestOption, object>
    ): Promise<TRequestParameters<TRequestOption, object>> {
        const authSdk = AuthSdk.getInstance(CookieService.getInstance());
        const token = await authSdk.getToken();

        if (!requestOption.options) {
            requestOption.options = {
                headers: {},
            };
        }

        requestOption.options = {
            ...requestOption.options,
            headers: {
                [AUTH_TOKEN_HEADER_NAME]: token,
                ['Accept']: 'application/json',
                ['Content-Type']: 'application/json',
            },
        };

        return requestOption;
    }

    public static instance: TokenRequestInterceptor;

    public static getInstance(): TokenRequestInterceptor {
        if (!TokenRequestInterceptor.instance) {
            TokenRequestInterceptor.instance = new TokenRequestInterceptor();
        }

        return TokenRequestInterceptor.instance;
    }
}
