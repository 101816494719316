import {TAppRootState, TSelect} from 'core/redux/types';
import {IPaginationState} from 'plugins/modules/pagination/types';

export function createPaginationSelectors<T extends IPaginationState>(select: TSelect<T>) {
    function selectPage(state: TAppRootState): T['page'] {
        return select(state).page;
    }

    function selectPageCount(state: TAppRootState): T['count'] {
        return select(state).count;
    }

    function selectPageSize(state: TAppRootState): T['pageSize'] {
        return select(state).pageSize;
    }

    function selectPaginationType(state: TAppRootState): T['type'] {
        return select(state).type;
    }

    function selectPageSizeList(state: TAppRootState): T['pageSizeList'] {
        return select(state).pageSizeList;
    }

    return {
        selectPage,
        selectPageCount,
        selectPageSize,
        selectPageSizeList,
        selectPaginationType,
    };
}
