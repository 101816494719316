import {BaseHttpClient} from 'core/base/BaseHttpClient';
import {intercept, preflight} from 'core/decorators';
import {keyBy} from 'core/helpers';
import {ModifiedControlRequestInterceptor} from 'core/interceptors/ModifiedControlRequestInterceptor';
import {ModifiedControlResponseInterceptor} from 'core/interceptors/ModifiedControlResponseInterceptor';
import {SentryResponseInterceptor} from 'core/interceptors/SentryResponseInterceptor';
import {TokenRequestInterceptor} from 'core/interceptors/TokenRequestInterceptor';
import {TokenResponseInterceptor} from 'core/interceptors/TokenResponseInterceptor';
import {getRuntimeConfig} from 'core/next/helpers';
import {
    IHttpClientResponse,
    TCommonRequestParameters,
    TJson,
    TMappedServerError,
    TRequestOption,
    TRequestParameters,
    TServerError,
} from 'core/types';

const resInterceptors = [
    TokenResponseInterceptor.getInstance(),
    ModifiedControlResponseInterceptor.getInstance(),
    SentryResponseInterceptor.getInstance(),
];
const reqInterceptors = [TokenRequestInterceptor.getInstance(), ModifiedControlRequestInterceptor.getInstance()];

export class EvolutionHttpClient extends BaseHttpClient {
    constructor(api?: EvolutionHttpClient['api'], host?: EvolutionHttpClient['host']) {
        super(
            host ?? getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST ?? '',
            api ?? getRuntimeConfig().NEXT_PUBLIC_API_V ?? ''
        );
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override async get<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.get({options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override async head<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.head({options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override async delete<Rs extends TJson = TJson>({
        url,
        options,
    }: TCommonRequestParameters<TRequestOption>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.delete({options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override deleteWithBody<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.deleteWithBody({body, options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override post<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.post({body, options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override put<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.put({body, options, url});
    }

    @intercept(resInterceptors)
    @preflight(reqInterceptors)
    public override patch<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<TRequestOption, Body>): Promise<IHttpClientResponse<Rs, TJson, Response>> {
        return super.patch({body, options, url});
    }

    /**
     * @deprecated
     * @param errorsList
     */
    public static normalizeResponseErrors = <T extends Record<string, any> = Record<string, any>>(
        errorsList: TServerError<T>[]
    ): TMappedServerError<T> => {
        return keyBy<TServerError<T>, true>(errorsList, 'field', ({message}) => message) as TMappedServerError<T>;
    };
}
