import {TWishListParams} from 'modules/wish-list/types';

const WISH_LIST_TITLE = `Мой список покупок ${new Date().toLocaleDateString('ru-RU')}`;
export const ADD_WISH_LIST_INITIAL_VALUES: TWishListParams = {comment: '', title: WISH_LIST_TITLE};

export const MIN_TITLE_LENGTH = 1;
export const MAX_TITLE_LENGTH = 50;
export const TITLE_ERRORS = {
    LONG: 'Название списка слишком длинное',
    REQUIRED: 'Пожалуйста, введите название списка покупок',
    SHORT: 'Название списка слишком короткое',
};

export const MAX_COMMENT_LENGTH = 250;

export const COMMENT_ERRORS = {
    LONG: 'Комментарий слишком длинный',
};

export const PRODUCT_ADDED_TOAST_TEXT = 'Товар добавлен в список покупок';
export const PRODUCT_ADDED_TOAST_BUTTON_TEXT = 'Перейти в список';
