import classNames from 'classnames';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {forwardRef, useCallback, useMemo, useRef, useState} from 'react';
import useResizeObserver, {ResizeHandler} from 'use-resize-observer';

import style from './style.module.scss';

export interface ICollapseAnimatedProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    isHidden?: boolean;
    noOverflowHiddenIfShow?: boolean;
}

export const CollapseAnimated = forwardRef<HTMLDivElement, ICollapseAnimatedProps>(function CollapseAnimated(
    {children, isHidden = false, className, noOverflowHiddenIfShow},
    ref
) {
    const [initialHeight, setInitialHeight] = useState<number>();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleContainerResize = useCallback<ResizeHandler>(
        ({height}) => {
            if (!containerRef.current) {
                return;
            }

            requestAnimationFrame(() => {
                setInitialHeight(height);
            });
        },
        [setInitialHeight]
    );

    const height = useMemo<string>(() => {
        if (isHidden) {
            return '0';
        }

        return 'undefined' === typeof initialHeight ? '100%' : `${initialHeight}px`;
    }, [initialHeight, isHidden]);

    useResizeObserver<HTMLDivElement>({onResize: handleContainerResize, ref: containerRef});

    return (
        <div
            className={classNames(className, style.container, {
                [style.hidden]: isHidden,
                [style.noOverflowHiddenIfShow]: noOverflowHiddenIfShow,
            })}
            ref={ref}
            style={{maxHeight: height}}
        >
            <div className={style.innerContainer} ref={containerRef}>
                {children}
            </div>
        </div>
    );
});
