import classNames from 'classnames';
import React, {FC, HTMLAttributes} from 'react';

import style from './style.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export const ShadowBlock: FC<IProps> = ({children, ...restDivAttributes}) => {
    return (
        <div {...restDivAttributes} className={classNames(restDivAttributes.className, style.shadowBlock)}>
            {children}
        </div>
    );
};
