import {LogoLoader} from 'components/Loader';
import React, {FC, useEffect, useRef, useState} from 'react';

import style from './style.module.scss';

const MINIMAL_LOADING_THRESHOLD = 800;

export const ModalLazyLoader: FC = () => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const [shouldRenderPreloader, setIsShouldRenderPreloader] = useState<boolean>();

    useEffect(() => {
        if (timeoutRef.current) {
            clearInterval(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            setIsShouldRenderPreloader(true);
        }, MINIMAL_LOADING_THRESHOLD);

        return () => {
            clearInterval(timeoutRef.current);
        };
    }, []);

    return (
        <div className={style.modalLazyLoaderContainer}>{shouldRenderPreloader && <LogoLoader isNotForReload />}</div>
    );
};
