import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {ISliderState} from 'modules/slider/types';
import {HYDRATE} from 'next-redux-wrapper';

export const sliderModuleName = 'slider';

const initialState: ISliderState = {
    activeIdx: 0,
    autoStartVideoPath: null,
};

export const sliderSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return {
                ...payload[sliderModuleName],
                activeIdx: state.activeIdx,
            };
        },
    },
    initialState,
    name: sliderModuleName,
    reducers: {
        actionSetActiveIdx(state: ISliderState, {payload}: PayloadAction<ISliderState['activeIdx']>) {
            state.activeIdx = payload;
        },

        actionSetAutoStartVideoPath(state: ISliderState, {payload}: PayloadAction<ISliderState['autoStartVideoPath']>) {
            state.autoStartVideoPath = payload;
        },
    },
});

export const {
    actions: {actionSetActiveIdx, actionSetAutoStartVideoPath},
} = sliderSlice;

export const {reducer: sliderReducer} = sliderSlice;
