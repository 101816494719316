import {OrderStatus} from 'new-models';

export const ORDER_STATUS_TYPE_TEXT: {[key in OrderStatus]: string} = {
    [OrderStatus.CREATED]: 'Оформлен',
    [OrderStatus.IN_DELIVERY]: 'Доставляется',
    [OrderStatus.COMPLETED]: 'Получен',
    [OrderStatus.PAYED]: 'Оплачен',
    [OrderStatus.IN_ASSEMBLY]: 'Собирается',
    [OrderStatus.READY_FOR_PICKUP]: 'Готов к выдаче',
    [OrderStatus.ARCHIVE]: 'Архивирован',
    [OrderStatus.CANCELED]: 'Отменен',
};

export const ANOTHER_REASON_TEXT = 'Другое';

export const REASON_CANCEL_TEXT_LIST = [
    'Заказал по ошибке',
    'Хочу изменить состав заказа',
    'Не удается оплатить',
    'Купил в другом месте',
    ANOTHER_REASON_TEXT,
] as const;

export const STATUS_PAYMENT_TYPE = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NOT_PAID: 'NOT_PAID',
    PAID: 'PAID',
    RECEIPT: 'RECEIPT',
    REFUND: 'REFUND',
} as const;

export const MOCK_DELIVERY_ADDRESS = 'Калининградская область, г. Калининград, ул. Ленина, д. 25, кв. 287';
export const MOCK_PICKUP_POINT = 'ТЦ "Мега"';
export const MOCK_DATE_RECEIVING = new Date();
export const MOCK_TIME_PERIOD_RECEIVING = 'C 10:00 до 21:00';
export const MOCK_SHELF_LIFE = '3 дня';

export const MOCK_DATE_SHELF_LIFE_LIST: string[] = Array.from({length: 3}, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() + 1 + i);
    return date.toISOString();
});
