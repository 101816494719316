import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {mindBoxService} from 'modules/analytics/services/MindBoxService';
import {addToFavouritesList} from 'modules/favourites/thunks';
import {selectUserData} from 'modules/user/selectors';
import {ProductBase} from 'new-models';
import {useCallback} from 'react';

export const useAddToFavourite = () => {
    const profile = useAppSelector(selectUserData);
    const dispatch = useAppDispatch();

    return useCallback(
        (product: ProductBase) => {
            if (profile) {
                mindBoxService.addToFavouriteProducts([product], profile);
            }

            dispatch(addToFavouritesList(product));
        },
        [dispatch, profile]
    );
};
