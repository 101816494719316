import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TResponse} from 'core/types';
import {IProductListParams} from 'modules/products/services/ProductsService';
import {SearchResultDataResponse, SearchSuggestDataResponse} from 'new-models';

export class SearchService extends EvolutionHttpClient {
    async getSearchResults(
        query: URLSearchParams,
        params: IProductListParams
    ): Promise<TResponse<SearchResultDataResponse>> {
        AbstractUrlService.mapToUrlSearchParams(query, params);

        const {data} = await this.get<TResponse<SearchResultDataResponse>>({
            options: {
                cancellable: true,
                id: this.getSearchResults.name,
            },
            url: `/search?${query}`,
        });

        return data;
    }

    async getSearchSuggestions(query: URLSearchParams): Promise<TResponse<SearchSuggestDataResponse>> {
        const {data} = await this.get<TResponse<SearchSuggestDataResponse>>({
            options: {
                cancellable: true,
                id: this.getSearchSuggestions.name,
            },
            url: `/search/suggest?${query}`,
        });

        return data;
    }
}
