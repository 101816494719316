import classNames from 'classnames';
import {Button, BUTTON_COLORS, BUTTON_SIZE} from 'components/Button';
import {TPropsWithClassName} from 'core/types';
import {ChooseFilterTagItem} from 'plugins/modules/filter/components/ChooseFilterTagItem';
import {RangeFilterTagItem} from 'plugins/modules/filter/components/RangeFilterTagItem';
import {FilterContext} from 'plugins/modules/filter/context';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import React, {FC, useCallback, useContext, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    isInFiltersList?: boolean;
}

export const FiltersTagList: FC<IProps> = ({className, isInFiltersList = false}) => {
    const isLoading = useIsListLoading();

    const {appliedRangeFiltersList, appliedChooseFiltersByVariantList} = useContext(FilterContext);

    const {urlService: filterService} = useContext(FilterContext);

    const filterCount = useMemo(
        () => appliedChooseFiltersByVariantList.length + appliedRangeFiltersList.length,
        [appliedChooseFiltersByVariantList.length, appliedRangeFiltersList.length]
    );

    const handleResetFilters = useCallback(async () => {
        await filterService?.flushParam();
    }, [filterService]);

    if (!filterCount) {
        return null;
    }

    return (
        <ul className={classNames(style.filterTagList, className, {[style.isInFiltersList]: isInFiltersList})}>
            {Boolean(filterCount) && (
                <li className={style.resetAllAdaptive}>
                    <Button
                        color={BUTTON_COLORS.secondaryColor}
                        disabled={isLoading}
                        onClick={handleResetFilters}
                        size={BUTTON_SIZE.small}
                    >
                        Сбросить все
                    </Button>
                </li>
            )}
            {appliedChooseFiltersByVariantList.map(({title, variants, code, type}) => {
                return variants.map((variant, index) => {
                    return (
                        <li data-skeleton-item key={variant.code}>
                            <ChooseFilterTagItem
                                {...variant}
                                filterCode={code}
                                filterTitle={title}
                                filterType={type}
                                index={index}
                            />
                        </li>
                    );
                });
            })}
            {appliedRangeFiltersList.map((rangeFilter) => {
                return (
                    <li data-skeleton-item key={rangeFilter.code}>
                        <RangeFilterTagItem {...rangeFilter} />
                    </li>
                );
            })}
            {Boolean(filterCount) && (
                <li className={style.resetAllDesktop}>
                    <Button
                        color={BUTTON_COLORS.secondaryColor}
                        disabled={isLoading}
                        onClick={handleResetFilters}
                        size={BUTTON_SIZE.small}
                    >
                        Сбросить все
                    </Button>
                </li>
            )}
        </ul>
    );
};
