import classNames from 'classnames';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {ScrollTo} from 'components/ScrollTo';
import {Typography} from 'components/Typography';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {Alphabet} from 'modules/brand/components/Alphabet';
import {BrandListView} from 'modules/brand/components/BrandListView';
import {BrandsSlider} from 'modules/brand/components/BrandsSlider';
import {BrandTileView} from 'modules/brand/components/BrandTileView';
import {useBrandListPage} from 'modules/brand/hooks/useBrandListPage';
import {ViewSwitcher} from 'modules/products/components/ViewSwitcher';
import React, {FC} from 'react';

import style from './style.module.scss';

const OFFSET_ALPHABET_DESKTOP = 170;
const OFFSET_ALPHABET_MOBILE = 65;

export const BrandListPage: FC = () => {
    const isMobile = useIsMobile();

    const {
        activeItem,
        alphabetData,
        alphabetLetterList,
        brandListContentRef,
        handleSwitchView,
        isListView,
        isScrolledPage,
        onClickAlphabet,
        popularBrandList,
        stickyRef,
        view,
    } = useBrandListPage();

    return (
        <>
            <PageHeaderTitle className={classNames(style.header, style.container)}>
                <Typography color="gray100" data-skeleton-item element="h1" variant="h2-bold">
                    Бренды
                </Typography>
            </PageHeaderTitle>
            <div className={style.brandsSliderContainer}>
                <BrandsSlider brandList={popularBrandList} />
            </div>
            <div className={classNames(style.areaSwitch, style.container)}>
                <Typography className={style.subHeader} data-skeleton-item element="h2" variant="h2-bold">
                    Все бренды
                </Typography>
                <ViewSwitcher className={style.switcher} onChange={handleSwitchView} value={view} />
            </div>

            <div
                className={classNames(style.alphabetSection, style.stickyHeader, {[style.isNotAtTop]: isScrolledPage})}
                ref={stickyRef}
            >
                <div className={classNames(style.alphabetContainer)} data-skeleton-item>
                    <Alphabet
                        activeItem={activeItem}
                        className={style.alphabet}
                        letterList={alphabetLetterList}
                        onClick={onClickAlphabet}
                    />
                </div>
            </div>
            <div className={classNames(style.container, style.areaContent)} ref={brandListContentRef}>
                <ScrollTo
                    behavior="auto"
                    container={brandListContentRef.current}
                    scrollId={activeItem}
                    verticalOffset={isMobile ? OFFSET_ALPHABET_MOBILE : OFFSET_ALPHABET_DESKTOP}
                >
                    {isListView ? (
                        <BrandListView alphabetData={alphabetData} />
                    ) : (
                        <BrandTileView alphabetData={alphabetData} />
                    )}
                </ScrollTo>
            </div>
        </>
    );
};
