import {BaseUrlService, TUrlParam} from 'core/base/BaseUrlService';
import {SEPARATORS_LIST} from 'core/constants';
import {contextDecorator} from 'core/decorators';
import {FastFilter} from 'models';
import {FilterChoose, FilterFlag, FilterRange, FilterType} from 'new-models';
import Router from 'next/router';
import {COMBO_FILTER_URL_ROUTE, ROUTER_QUERY_TAG_OR_COLLECTION_KEY} from 'plugins/modules/filter/constants';
import {
    IStaticFilter,
    ITagData,
    TFilter,
    THandleAddFlagParams,
    THandleAddRangeParams,
} from 'plugins/modules/filter/types';
import {PaginationUrlService} from 'plugins/modules/pagination/services/PaginationUrlService';
import {SearchUrlService} from 'plugins/modules/search/services/SearchUrlService';
import {SortUrlService} from 'plugins/modules/sorting/services/SortUrlService';
import {SYSTEM_CATALOG_PAGE_ROUTE, SYSTEM_CATALOG_TAG_PAGE_ROUTE} from 'routing/constants';

export class FilterUrlService extends BaseUrlService {
    public readonly comboFilterQueryData: ITagData | null = null;
    private static staticFilter: IStaticFilter | undefined;

    constructor(
        query: BaseUrlService['query'],
        onParamsChange: BaseUrlService['onParamsChange'],
        options: BaseUrlService['options']
    ) {
        super(query, onParamsChange, options);

        this.comboFilterQueryData = this.parseTagData();

        this.fill(
            Object.values({
                ...SortUrlService.COMMON_PARAM_NAMES,
                ...PaginationUrlService.COMMON_PARAM_NAMES,
                ...BaseUrlService.COMMON_PARAM_NAMES,
                ...SearchUrlService.COMMON_PARAM_NAMES,
            }),
            'exclude'
        );

        this.format();
    }

    public static setStaticFilter = (data: IStaticFilter) => {
        FilterUrlService.staticFilter = {...data};
    };

    public static resetStaticFilter = () => {
        FilterUrlService.staticFilter = undefined;
    };

    public static setFilterQueryParams = async (filters: TFilter[]) => {
        const currentParams = filters.reduce<Record<string, string>>((params, filter) => {
            if (filter.type === FilterType.Range || filter.type === FilterType.DateRange) {
                const {code, minValue, maxValue} = filter as FilterRange;

                const rangeParams = minValue && maxValue ? {[code]: `${minValue},${maxValue}`} : undefined;
                return {...params, ...rangeParams};
            }

            if (filter.type === FilterType.Choose || filter.type === FilterType.Store) {
                const {code, variants} = filter as FilterChoose;
                const variantsString = variants
                    .filter(({isApplied, isEnabled}) => (isEnabled ?? true) && isApplied)
                    .map(({code: variantCode}) => variantCode)
                    .join(SEPARATORS_LIST.COMMA_AND_SPACE);

                const chooseParams = variantsString ? {[code]: variantsString} : undefined;

                return {...params, ...chooseParams};
            }

            if (filter.type === FilterType.Flag) {
                const {code, isApplied} = filter as FilterFlag;
                return {...params, [code]: isApplied};
            }

            return params;
        }, {});
        await super.setQueryParams(currentParams);
    };

    @contextDecorator('client')
    public override async addParam(...params: TUrlParam[]): Promise<void> {
        await super.addParam(...params);
        await this.removeComboFilter();
    }
    @contextDecorator('client')
    public async addChooseParams(params: TUrlParam) {
        if (Boolean(this.paramsList[params.id])) {
            await this.addParam({...params, value: `${this.paramsList[params.id]}, ${params.value}`});
            return;
        }
        await this.addParam(params);
    }

    @contextDecorator('client')
    public async addFlagParams({code, isFilterApplied}: THandleAddFlagParams) {
        const params: TUrlParam = {id: code, value: `${isFilterApplied}`};
        await this.addParam(params);
    }

    @contextDecorator('client')
    public async addRangeParams({filterCode, from, to}: THandleAddRangeParams) {
        const params: TUrlParam[] = [{id: filterCode, value: `${from},${to}`}];
        await this.addParam(...params);
    }

    @contextDecorator('client')
    public override async removeParam(...params: TUrlParam[]): Promise<void> {
        await super.removeParam(...params);
        await this.removeComboFilter();

        const onlyStandardFilters = Object.keys(this.paramsList).reduce<BaseUrlService['paramsList']>(
            (standardFilters, standardParamKey) => {
                return {
                    ...standardFilters,
                    [standardParamKey]: this.paramsList[standardParamKey],
                };
            },
            <BaseUrlService['paramsList']>{}
        );

        const isAnyFilterExist = Object.values(onlyStandardFilters).some((value) => value);
        if (!isAnyFilterExist) {
            await Router.replace(
                {
                    query: Router.query,
                },
                undefined,
                {
                    shallow: true,
                }
            );
        }
    }

    @contextDecorator('client')
    public async flushParam() {
        const appliedFilterIdList = Object.keys(this.paramsList);
        appliedFilterIdList.forEach((id) => {
            const param = Object.values(FilterUrlService.COMMON_PARAM_NAMES).find(({name}) => name === id);

            if (param && !param.flushed) {
                return;
            }

            delete Router.query[id];
            this.paramsList[id] = null;
        });

        // Если комбофильтры имеются, то при сбросе всех фильтров нужно сбросить и комбофильтры
        const routerPathname = this.hasComboFilters ? SYSTEM_CATALOG_PAGE_ROUTE : Router.pathname;

        delete Router.query[FilterUrlService.COMMON_PARAM_NAMES.FILTER_QUERY_MARK?.toString()];

        if (this.hasComboFilters) {
            delete Router.query[ROUTER_QUERY_TAG_OR_COLLECTION_KEY];
        }

        const isNeedResetParams = !Object.keys(this.paramsList).some((id) => BaseUrlService.RESET_PARAM === id);

        if (isNeedResetParams) {
            delete Router.query[BaseUrlService.RESET_PARAM];
        }

        await Router.push(
            {
                pathname: routerPathname,
                query: Router.query,
            },
            undefined,
            {shallow: true}
        );

        // Иными словами если `pathname` изменился, нет необходимости запрашивать данные снова, т.к., они прилетят уже из GSSP
        if (!this.hasComboFilters) {
            await this.onParamsChange(this.format());
        }
    }

    @contextDecorator('client')
    public async addComboFilter(comboFilter: FastFilter): Promise<void> {
        Object.keys(this.paramsList).forEach((paramKey) => {
            delete Router.query[paramKey];
            this.paramsList[paramKey] = null;
        });

        const filterSet = comboFilter.filters;
        filterSet.forEach((filterItem) => {
            Router.query[filterItem.id] = filterItem.value;
            this.paramsList[filterItem.id] = filterItem.value;
        });

        Router.query[FilterUrlService.COMMON_PARAM_NAMES.FILTER_QUERY_MARK.toString()] = 'Y';
        await Router.push(
            {
                pathname: SYSTEM_CATALOG_TAG_PAGE_ROUTE,
                query: {
                    ...Router.query,

                    [ROUTER_QUERY_TAG_OR_COLLECTION_KEY]: FilterUrlService.makeCategoryTagUrl(comboFilter),
                },
            },
            undefined,
            {
                shallow: true,
            }
        );

        // Если комбо уже применены, значит роутинг на уровне одной и той же странице, значит GSSP не запустится
        if (this.hasComboFilters) {
            await this.onParamsChange(this.format());
        }
    }

    @contextDecorator('client')
    public async removeComboFilter(): Promise<void> {
        if (!this.hasComboFilters) {
            return;
        }

        delete Router.query[ROUTER_QUERY_TAG_OR_COLLECTION_KEY];
        await Router.replace(
            {
                pathname: SYSTEM_CATALOG_PAGE_ROUTE,
                query: Router.query,
            },
            undefined,
            {
                shallow: true,
            }
        );
    }

    protected getParamsListWithStaticFilter = () => {
        if (!FilterUrlService.staticFilter) {
            return this.paramsList;
        }

        const params = {...this.paramsList};

        Object.entries(FilterUrlService.staticFilter).forEach(([key, value]) => {
            const paramsString = params[key];

            if (!paramsString) {
                params[key] = value;

                return;
            }

            params[key] = `${paramsString},${value}`;
        });

        return params;
    };

    protected override format(): URLSearchParams {
        const params = this.getParamsListWithStaticFilter();

        Object.keys(params).forEach((appliedParamId) => {
            if (null === params[appliedParamId]) {
                this.query.delete(`filter[${appliedParamId}]`);
                return;
            }

            this.query.set(`filter[${appliedParamId}]`, params[appliedParamId]!);
        });

        return this.query;
    }

    private parseTagData<T extends Record<string, any> = ITagData>(): T | null {
        const regex = new RegExp(COMBO_FILTER_URL_ROUTE, 'u');
        const matchedGroups = (<string>this.options.query[ROUTER_QUERY_TAG_OR_COLLECTION_KEY])?.match(regex)?.groups;

        if (!matchedGroups) {
            return null;
        }

        return matchedGroups as T;
    }

    private get hasComboFilters(): boolean {
        return null !== this.comboFilterQueryData;
    }

    public static makeCategoryTagUrl(comboFilter: FastFilter): string {
        return `${comboFilter.code}-tag-${comboFilter.id}`;
    }
}

Router.events.on('routeChangeStart', () => {
    FilterUrlService.resetStaticFilter();
});
