export const MENU_LAYOUT_CODES = {
    catalogActionsMenu: 'catalog-actions-menu',
    copyRight: 'menyu-copyright',
    footerMenu: 'menyu-v-futere',
    headerCatalogMenu: 'menyu-kataloga',
    headerTopBuyers: 'menyu-dlya-pokupateley',
    headerTopLeft: 'menyu-v-shapke-sleva',
    headerTopRight: 'menyu-v-shapke-sprava',
    payments: 'platezhnye-servisy',
    social: 'sots-seti',
};

export const MENU_HEADER_BRANDS_CODE = 'brendy';
export const MENU_FOOTER_NEWS_CODE = 'budte-v-kurse-novostey';
