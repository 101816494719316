import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {ScrollTo} from 'components/ScrollTo';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {RelatedCollectionsList} from 'modules/collections/components/RelatedCollectionsList';
import {IProps as IProductListProps, ProductList} from 'modules/products/components/ProductList';
import {ProductListControlBar} from 'modules/products/components/ProductListControlBar';
import {COLUMNS_COUNT_LAYOUT, COLUMNS_COUNT_PRODUCT_ITEM, PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {ProductListPageContext, ProductListPageFunctionalityContext} from 'modules/products/context';
import {useProductRestore} from 'modules/products/hooks/useProductRestore';
import productListStyle from 'modules/products/pages/ProductListPage/style.module.scss';
import {ViewedProductsSlider} from 'modules/slider/components/ViewedProductsSlider';
import {ProductShort} from 'new-models';
import {FilterList} from 'plugins/modules/filter/components/FilterList';
import {FiltersTagList} from 'plugins/modules/filter/components/FiltersTagList';
import {FilterContext} from 'plugins/modules/filter/context';
import {ListingContext} from 'plugins/modules/listing/context';
import {ListPagination} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {usePageChange} from 'plugins/modules/pagination/hooks/usePageChange';
import {usePaginationType} from 'plugins/modules/pagination/hooks/usePaginationType';
import {SortingContext} from 'plugins/modules/sorting/context';
import {useSortChange} from 'plugins/modules/sorting/hooks/useSortChange';
import React, {FC, useContext, useMemo, useRef} from 'react';

import style from './style.module.scss';

const {filter, productsFilterHide, productsFilterShow} = COLUMNS_COUNT_LAYOUT;

const filterColumnStyle = getGridColumnStyles({md: filter});
const productsFilterHideColumnStyle = getGridColumnStyles({md: productsFilterHide});
const productsFilterShowColumnStyle = getGridColumnStyles({md: productsFilterShow});
const columnStyle = getGridColumnStyles();

interface IProps extends TPropsWithClassName {
    ProductListComponent?: FC<IProductListProps>;
}

export const BrandProductList: FC<IProps> = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ProductListComponent = ProductList,
    className,
}) => {
    usePaginationType();

    const functionality = useContext(ProductListPageFunctionalityContext);
    const {category} = useContext(ProductListPageContext);
    const {sortingList} = useContext(SortingContext);
    const {count, pageSize, page} = useContext(PaginationContext);
    const {filterList, isFilterPanelVisible} = useContext(FilterContext);
    const {listType, list, isListPartiallyLoading} = useContext(ListingContext);
    const onPageChange = usePageChange();
    const onSortChange = useSortChange();
    // useListType(category?.type);

    const topNodeRef = useRef<HTMLDivElement>(null);
    const productListRef = useRef<HTMLDivElement>(null);

    const {scrollId, handleScrollComplete} = useProductRestore();

    const isFilterAvailable = useMemo<boolean>(() => {
        return Boolean(filterList.length && isFilterPanelVisible);
    }, [isFilterPanelVisible, filterList.length]);

    const productCardColumnStyle: string = useMemo(() => {
        const {filterHide, filterShow, listView, mobile} = COLUMNS_COUNT_PRODUCT_ITEM;

        if (listType === PRODUCT_LIST_VIEW.list) {
            return getGridColumnStyles({
                md: listView,
                sm: listView,
            });
        }

        return getGridColumnStyles({
            md: isFilterPanelVisible ? filterShow : filterHide,
            sm: mobile,
        });
    }, [isFilterPanelVisible, listType]);

    return (
        <>
            <div
                className={classNames(productListStyle.productListLayout, className, {
                    [productListStyle.productListLayoutNoFilter]: !isFilterAvailable,
                })}
                ref={topNodeRef}
            >
                <Typography className={style.subTitle} element="h2" variant="h2-bold">
                    Товары бренда
                </Typography>
                <Grid>
                    {isFilterPanelVisible ? <FilterList className={filterColumnStyle} /> : null}
                    <Grid
                        className={classNames(
                            productListStyle.productListLayoutListingContainer,
                            isFilterPanelVisible ? productsFilterShowColumnStyle : productsFilterHideColumnStyle
                        )}
                        noRowGap
                    >
                        <div className={classNames(productListStyle.productListLayoutSorting, columnStyle)}>
                            <ProductListControlBar count={count} onSortChange={onSortChange} sortList={sortingList} />
                        </div>
                        <div
                            className={classNames(productListStyle.productListLayoutListing, columnStyle)}
                            ref={productListRef}
                        >
                            <Grid noRowGap>
                                <FiltersTagList className={classNames(productListStyle.filtersTagList, columnStyle)} />
                                <ScrollTo
                                    behavior="smooth"
                                    block="nearest"
                                    container={productListRef.current}
                                    onScroll={handleScrollComplete}
                                    scrollId={scrollId}
                                    scrollMarkAttribute="data-product-card-id"
                                >
                                    <div className={classNames(productListStyle.listing, columnStyle)}>
                                        <ProductListComponent
                                            categoryType={category?.type}
                                            listType={listType}
                                            productCardRootClassName={productCardColumnStyle}
                                            productList={list as ProductShort[]}
                                        />
                                    </div>
                                </ScrollTo>
                            </Grid>
                        </div>
                        {Boolean(list.length) && functionality.pagination && (
                            <div
                                className={classNames(
                                    productListStyle.productListLayoutPaginationContainer,
                                    columnStyle
                                )}
                                data-skeleton-item
                            >
                                <ListPagination
                                    isLoading={isListPartiallyLoading}
                                    onChange={onPageChange}
                                    pageNomer={page}
                                    pageSize={pageSize}
                                    recordCount={count}
                                    scrollTarget={topNodeRef.current}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
            {functionality.showRelatedCollectionList && (
                <RelatedCollectionsList className={productListStyle.productListPageLayoutLookedList} />
            )}
            <ViewedProductsSlider className={productListStyle.productListPageLayoutLookedList} />
        </>
    );
};
