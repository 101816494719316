import {CheckoutContext} from 'modules/checkout/context';
import {CheckoutPage} from 'modules/checkout/pages/CheckoutPage';
import {CHECKOUT_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {memo} from 'react';

export const CheckoutSkeletonPage = memo(function CheckoutSkeletonPageProvider() {
    return (
        <CheckoutContext.Provider value={CHECKOUT_SKELETON_DATA}>
            <CheckoutPage />
        </CheckoutContext.Provider>
    );
});
