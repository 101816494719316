import {mindBoxService} from 'modules/analytics/services/MindBoxService';
import {Category} from 'new-models';
import {useEffect} from 'react';

export const useViewCategory = (category?: Category) => {
    useEffect(() => {
        if (!category) {
            return;
        }
        mindBoxService.viewCategory(category);
    }, [category]);
};
