import {DashboardLayout} from 'layouts/DashboardLayout';
import {ProfileUserMenu} from 'modules/user/components/ProfileUserMenu';
import {UserPage} from 'modules/user/pages/UserPage';
import React, {FC} from 'react';

export const DashboardProfileSkeleton: FC = () => {
    return (
        <DashboardLayout sidebar={<ProfileUserMenu />}>
            <UserPage />
        </DashboardLayout>
    );
};
