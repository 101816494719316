import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IErrorPayload, IErrorState} from 'modules/errors/types';
import {HYDRATE} from 'next-redux-wrapper';

export const errorsModuleName = 'errors';

const initialState: IErrorState = {
    errors: {},
};

const errorsSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[errorsModuleName];
        },
    },
    initialState,
    name: errorsModuleName,
    reducers: {
        actionFlushErrors(state: IErrorState) {
            state.errors = {};
        },

        actionRemoveError(state: IErrorState, {payload}: PayloadAction<string>) {
            state.errors[payload] = null;
        },

        actionSetError(state: IErrorState, {payload}: PayloadAction<IErrorPayload>) {
            state.errors[payload.moduleName] = payload.errors;
        },
    },
});

export const {
    actions: {actionFlushErrors, actionSetError, actionRemoveError},
} = errorsSlice;

export const {reducer: errorsReducer} = errorsSlice;
