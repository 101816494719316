import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {AbortRequestInterceptor} from 'core/interceptors/AbortRequestInterceptor';
import {IRequestOptions, TResponse} from 'core/types';
import {
    BasketDataResponse,
    BasketUpdateDataRequest,
    PatchBasketRequestData,
    ProductDetail,
    PromocodeActionDataRequest,
} from 'new-models';

export const CART_REQUEST_PATHS: Record<any, NonNullable<IRequestOptions['path']>> = {
    CART: `/basket`,
    PROMO: '/basket/promocode',
};

export class CartService extends EvolutionHttpClient {
    async getCart(): Promise<TResponse<BasketDataResponse>> {
        const {data} = await this.get<TResponse<BasketDataResponse>>({
            url: CART_REQUEST_PATHS.CART,
        });

        return data;
    }

    async updateCart(cartItem: PatchBasketRequestData): Promise<TResponse<BasketDataResponse>> {
        const {data} = await this.patch<BasketUpdateDataRequest, TResponse<BasketDataResponse>>({
            body: {products: [cartItem]},
            options: {
                requestId: CartService.makeUpdateCartRequestId(cartItem.productId),
            },
            url: CART_REQUEST_PATHS.CART,
        });

        return data;
    }

    async updateCartBatch(cartItems: PatchBasketRequestData[]): Promise<TResponse<BasketDataResponse>> {
        const {data} = await this.patch<BasketUpdateDataRequest, TResponse<BasketDataResponse>>({
            body: {products: cartItems},
            url: CART_REQUEST_PATHS.CART,
        });

        return data;
    }

    async clearCart(): Promise<any> {
        const response = await this.delete<any>({
            url: CART_REQUEST_PATHS.CART,
        });

        return response;
    }

    public static makeUpdateCartRequestId(productOfferId: ProductDetail['id']): string {
        return `updateCart__${productOfferId}`;
    }

    public static abortUpdateCartRequest(productOfferId: ProductDetail['id']): void {
        const requestId = CartService.makeUpdateCartRequestId(productOfferId);
        AbortRequestInterceptor.abort(requestId);
    }
    async applyPromoCode(values: PromocodeActionDataRequest): Promise<TResponse<BasketDataResponse>> {
        const {data} = await this.post<PromocodeActionDataRequest, TResponse<BasketDataResponse>>({
            body: values,
            url: CART_REQUEST_PATHS.PROMO,
        });

        return data;
    }

    async deletePromoCode(values: PromocodeActionDataRequest): Promise<TResponse<BasketDataResponse>> {
        const {data} = await this.patch<PromocodeActionDataRequest, TResponse<BasketDataResponse>>({
            body: values,
            url: CART_REQUEST_PATHS.PROMO,
        });

        return data;
    }
}
