import classNames from 'classnames';
import {BackButton} from 'components/BackButton';
// import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
// import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {formatDateWithLocal} from 'core/helpers/date';
import {ReviewStatus} from 'modules/orders/components/ReviewStatus';
import {OrderDetail} from 'new-models';
// import copyIcon from 'public/icons/copy.svg';
import React, {memo, ReactNode, useMemo} from 'react';
import {PROFILE_ORDERS_ROUTE} from 'routing/constants';

const columnStyle = getGridColumnStyles();

// import {copyText} from 'core/helpers';

import {OrderStatusBadge} from '../OrderStatusBadge';
import style from './style.module.scss';

interface IProps extends OrderDetail {
    className?: string;
    isHideBack?: boolean;
}

export const OrderXPageHeader = memo<IProps>(function OrderXPageHeader({
    className,
    isHideBack,
    id,
    date,
    orderStatus,
    paidStatus,
    paymentMethod,
}) {
    const renderReviewStatus = useMemo<ReactNode>(() => {
        if ('on-reciept' === paymentMethod?.code) {
            return null;
        }

        return <ReviewStatus statusType={paidStatus} />;
    }, [paidStatus, paymentMethod?.code]);

    return (
        <Grid className={classNames(style.root, className)}>
            {!isHideBack && (
                <BackButton
                    className={classNames(style.backButton, columnStyle)}
                    isOnlyAdaptive={false}
                    isParentPath
                    path={PROFILE_ORDERS_ROUTE}
                />
            )}
            <div className={classNames(style.content, columnStyle)}>
                <div className={style.titleWrapper} data-skeleton-item>
                    <div className={style.title}>
                        <Typography className={style.titleText} element="h3" variant="h3">
                            Заказ № {id}
                        </Typography>
                        <OrderStatusBadge status={orderStatus} />
                        {/* <Button isSquare onClick={copyText(id)} size="small" theme="ghost">
                            <SvgIcon svg={copyIcon} />
                        </Button> */}
                    </div>
                    <Typography color="gray50" variant="p">
                        от {formatDateWithLocal(date)}
                    </Typography>
                </div>
                {renderReviewStatus}
            </div>
        </Grid>
    );
});
