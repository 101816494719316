import {TPropsWithClassName} from 'core/types';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {ProductPageContext} from 'modules/product-card/context';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import {ProductBase} from 'new-models';
import React, {FC, useContext, useMemo} from 'react';

export const SetProductsSlider: FC<TPropsWithClassName> = ({className}) => {
    const {isProductLoading, productData} = useContext(ProductPageContext);
    const {product: mainProduct} = productData ?? {};
    const {products} = mainProduct ?? {};
    const renderProducts = useMemo(
        () => products?.reduce<ProductBase[]>((acc, {product}) => (product ? [...acc, product] : acc), []),
        [products]
    );
    if (!renderProducts?.length) {
        return null;
    }

    return (
        <div id={MAIN_INFO_DATA.SET_PRODUCTS.name}>
            <PageSectionBlock className={className} title={MAIN_INFO_DATA.SET_PRODUCTS.label} withoutPaddingTop>
                <SkeletonWrapper disabled={!isProductLoading}>
                    <SliderWithBanner products={renderProducts} />
                </SkeletonWrapper>
            </PageSectionBlock>
        </div>
    );
};
