import {FilterChoose, FilterType} from 'new-models';
import {TFilter} from 'plugins/modules/filter/types';
import {useCallback} from 'react';

export const useFilter = () =>
    useCallback(
        (filterList: TFilter[]) =>
            filterList.filter(
                (filter) =>
                    (filter.type !== FilterType.Choose && filter.type !== FilterType.Store) ||
                    (filter as FilterChoose).variants.some(({isEnabled}) => isEnabled)
            ),
        []
    );
