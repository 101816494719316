import {IProps as IControlBarProps} from 'modules/products/components/ProductListControlBar';
import {SortingContext} from 'plugins/modules/sorting/context';
import {useCallback, useContext} from 'react';

type TUseSortChange = () => NonNullable<IControlBarProps['onSortChange']>;
export const useSortChange: TUseSortChange = () => {
    const {urlService: sortUrlService} = useContext(SortingContext);

    return useCallback<NonNullable<IControlBarProps['onSortChange']>>(
        async (newValue) => {
            await sortUrlService?.setSortParam(newValue.value);
        },
        [sortUrlService]
    );
};
