import classNames from 'classnames';
import {Button} from 'components/Button';
import {OnlyDesktop} from 'components/OnlyDesktop';
import {OnlyMobile} from 'components/OnlyMobile';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {LocationName} from 'modules/locations/components/LocationName';
import {useSelectLocation} from 'modules/locations/hooks/useSelectLocation';
import {useUserLocation} from 'modules/locations/hooks/useUserLocation';
import {selectIsLocationsListLoading} from 'modules/locations/selectors';
import {changeLocation} from 'modules/locations/thunks';
import {Modal} from 'modules/modals/components/Modal';
import {ModalContent} from 'modules/modals/components/ModalContent';
import {RegularModal} from 'modules/modals/components/RegularModal';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {actionSetPortalHidden} from 'modules/portal/slice';
import closeIcon from 'public/icons/close.svg';
import React, {memo, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo} from 'react';

import style from './style.module.scss';

export const ConfirmLocationContent = memo(function CityClarificationContent() {
    const userLocation = useUserLocation();
    const selectLocation = useSelectLocation();

    const dispatch = useAppDispatch();

    const isMobile = useIsMobile();

    const isLocationListLoading = useAppSelector(selectIsLocationsListLoading);

    const handleHideModal = useCallback(() => {
        dispatch(actionSetPortalHidden(MODALS.CONFIRM_LOCATION.name));
    }, [dispatch]);

    const handleApplyLocationClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        if (!userLocation) {
            return;
        }

        dispatch(changeLocation(userLocation));
        handleHideModal();
    }, [dispatch, handleHideModal, userLocation]);

    useEffect(() => {
        return () => {
            if (!userLocation) {
                return;
            }

            selectLocation(userLocation);
        };
    }, [selectLocation, userLocation]);

    const handleAnotherLocationClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        if (isMobile) {
            dispatch(showModal(MODALS.ADAPTIVE_LOCATION_CHOOSE.name, {isHideAll: true}));
            return;
        }
        dispatch(showModal(MODALS.LOCATION_CHOOSE.name, {isHideAll: true}));
    }, [dispatch, isMobile]);

    const renderContent = useMemo<ReactNode>(() => {
        return (
            <>
                <div className={style.actions}>
                    <Button
                        disabled={isLocationListLoading}
                        isStretched
                        onClick={handleApplyLocationClick}
                        size="medium"
                        theme="primary"
                    >
                        Да
                    </Button>
                    <Button
                        disabled={isLocationListLoading}
                        isStretched
                        onClick={handleAnotherLocationClick}
                        size="medium"
                        theme="secondary"
                    >
                        Нет, другой
                    </Button>
                </div>
            </>
        );
    }, [handleAnotherLocationClick, handleApplyLocationClick, isLocationListLoading]);

    const onClose = useCallback(() => {
        handleHideModal();
    }, [handleHideModal]);

    return (
        <>
            <OnlyDesktop>
                <div className={classNames(style.container, 'confirm-location-content')}>
                    <div className={style.header}>
                        <Typography color="gray100" element="h5" variant="h5">
                            Ваш город <LocationName />
                        </Typography>
                        <button className={style.close} onClick={onClose}>
                            <SvgIcon svg={closeIcon} />
                        </button>
                    </div>
                    {renderContent}
                </div>
            </OnlyDesktop>
            <Modal hasPortal name={MODALS.CONFIRM_LOCATION.name} willMount>
                <OnlyMobile>
                    <RegularModal adaptive className={style.modal} hasClose onClose={onClose}>
                        <ModalContent>
                            <Typography color="primary80" element="h5" variant="h5">
                                Ваш город <LocationName />?
                            </Typography>
                            {renderContent}
                        </ModalContent>
                    </RegularModal>
                </OnlyMobile>
            </Modal>
        </>
    );
});
