import {isMobileByScreen} from 'core/helpers';
import {ProductOfferDetail} from 'models';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {useCategoryByRouterId} from 'modules/categories/hooks/useCategoryByRouterId';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {ProductListPageContext} from 'modules/products/context';
import {ProductListPage} from 'modules/products/pages/ProductListPage';
import {productsSlice} from 'modules/products/slice';
import {
    SIMPLE_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
    TECHNICAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
    VISUAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
} from 'modules/skeleton/constants';
import {useSkeletonCategoryType} from 'modules/skeleton/hooks/useSkeletonCategoryType';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import {FilterProvider} from 'plugins/modules/filter/provider';
import {ListingProvider} from 'plugins/modules/listing/provider';
import {PaginationProvider} from 'plugins/modules/pagination/provider';
import {SortingProvider} from 'plugins/modules/sorting/provider';
import React, {FC, ReactNode, useMemo} from 'react';

interface IProps {
    ComponentHeader?: ReactNode;
    MobileHeaderComponent?: ReactNode;
    noMobileHeaderOffset?: boolean;
    withBanners?: boolean;
}

export const CategoryThirdLevelSkeleton: FC<IProps> = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ComponentHeader,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MobileHeaderComponent,
    noMobileHeaderOffset,
    withBanners = true,
}) => {
    useBottomBar(BOTTOM_BAR_BUTTONS.catalog);
    const category = useCategoryByRouterId();

    const skeletonData = useSkeletonCategoryType((category?.type as ProductOfferDetail['type']) || 'simple', {
        collection: SIMPLE_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'offline-wish': SIMPLE_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
        simple: SIMPLE_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
        technical: TECHNICAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
        visual: VISUAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA,
    });

    const isVisualType = useMemo(() => {
        return category?.type === PRODUCT_TYPE.visual;
    }, [category?.type]);

    return (
        <SliceProvider slice={productsSlice}>
            <ProductListPageContext.Provider
                value={{
                    category: skeletonData.category,
                }}
            >
                <ListingProvider isShowListingBanner list={skeletonData.productList} listType="tile">
                    <SortingProvider sortingList={skeletonData.sortList}>
                        <FilterProvider
                            appliedChooseFiltersByVariantList={[]}
                            appliedRangeFiltersList={[]}
                            comboFilterList={skeletonData.comboFilters}
                            filterList={skeletonData.filters}
                            isFilterPanelVisible={!isVisualType && !isMobileByScreen()}
                        >
                            <PaginationProvider count={0} page={0} pageSize={0} pageSizeList={[]} type="auto">
                                {ComponentHeader}
                                <ProductListPage
                                    MobileHeaderComponent={MobileHeaderComponent}
                                    noMobileHeaderOffset={noMobileHeaderOffset}
                                    withBanners={withBanners}
                                />
                            </PaginationProvider>
                        </FilterProvider>
                    </SortingProvider>
                </ListingProvider>
            </ProductListPageContext.Provider>
        </SliceProvider>
    );
};
