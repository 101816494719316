import {SeoMetaData} from 'new-models';
import {useMemo} from 'react';

export type TUseSeoMetaDataReturns = Partial<{
    description: string;
    keywords: string;
    metaTitle: string;
    title: string;
}>;

export interface IUseSeoMetaDataParams {
    seoMetaData?: SeoMetaData;
    titles?: (string | undefined)[];
}

export const useSeoMetaData = ({seoMetaData, titles}: IUseSeoMetaDataParams): TUseSeoMetaDataReturns => {
    return useMemo<TUseSeoMetaDataReturns>(() => {
        const metaTitle = seoMetaData?.title ?? seoMetaData?.text;

        const title = seoMetaData?.text || titles?.filter(Boolean)[0] || seoMetaData?.title;

        return {
            ...seoMetaData,
            metaTitle,
            title,
        };
    }, [seoMetaData, titles]);
};
