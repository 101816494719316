import Head from 'next/head';
import React, {memo} from 'react';

interface IPageHeadProps {
    title?: string;
    metaTitle?: string;
    description?: string;
    keywords?: string;
    preload?: string;
}

export const PageHead = memo<IPageHeadProps>(function PageHead({title, metaTitle, description, keywords, preload}) {
    return (
        <Head>
            <link crossOrigin="use-credentials" href="/manifest.json" rel="manifest" />
            <meta
                content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover, width=device-width"
                name="viewport"
            />
            <meta content="yes" name="apple-mobile-web-app-capable" />
            <meta content="yes" name="mobile-web-app-capable" />
            <meta content="yes" name="apple-touch-fullscreen" />
            <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
            <meta content={metaTitle} property="og:title" />
            <meta content={description} property="og:description" />
            <meta content={description} name="description"></meta>
            <meta content={keywords} name="keywords"></meta>
            {/* <link href="/images/favicon/icon-192x192.png" rel="apple-touch-icon" sizes="192x192" />*/}
            {/* <link href="/images/favicon/icon-48x48.png" rel="icon" sizes="48x48" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-72x72.png" rel="icon" sizes="72x72" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-96x96.png" rel="icon" sizes="96x96" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-128x128.png" rel="icon" sizes="128x128" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-144x144.png" rel="icon" sizes="144x144" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-152x152.png" rel="icon" sizes="152x152" type="image/png" />*/}
            {/* <link href="/images/favicon/icon-192x192.png" rel="icon" sizes="192x192" type="image/png" />*/}
            {preload && <link as="image" href={preload} rel="preload" />}

            <title>{title}</title>
        </Head>
    );
});
