import {IImageAdaptiveMap} from 'modules/images/types';

export const IMAGE_RESIZE_TRIGGER = 'preview';

export const IMAGE_RESOLUTION_TOKEN = 'r';

export const IMAGE_CROP_TOKEN = 'c';

export const IMAGE_QUALITY_TOKEN = 'q';

export const IMAGE_DEFAULT_QUALITY = 100;

export const ZERO_BYTE_FALLBACK =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';

export const ADAPTIVE_IMAGE_MAP: IImageAdaptiveMap[] = [
    {screenWidth: 1280, urlScreenVariant: 'urlL'},
    {screenWidth: 1024, urlScreenVariant: 'urlM'},
    {screenWidth: 768, urlScreenVariant: 'urlS'},
    {screenWidth: 0, urlScreenVariant: 'urlMobile'},
];
