import {TPropsWithChildrenRequire} from 'core/types';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {Link as LinkType} from 'new-models';
import Link from 'next/link';
import React, {memo, useMemo} from 'react';

interface IProps extends TPropsWithChildrenRequire {
    url?: LinkType;
}

export const MenuLink = memo<IProps>(function MenuLink({children, url}) {
    return useMemo(() => {
        const urlValue = getMenuItemUrl(url);

        if (urlValue.startsWith('http')) {
            return (
                <a href={urlValue} rel="noopener noreferrer" target="_blank">
                    {children}
                </a>
            );
        }

        if (urlValue.startsWith('/')) {
            return <Link href={urlValue}>{children}</Link>;
        }

        return <Link href={`/${urlValue}`}>{children}</Link>;
    }, [children, url]);
});
