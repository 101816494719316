import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {HIDE, MIME_TYPES} from 'core/constants';
import {formatBytes} from 'core/helpers';
import {ISvgIcon, TPropsWithClassName} from 'core/types';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {MediaFile} from 'new-models';
import docIcon from 'public/icons/doc-icon.svg';
import fileIcon from 'public/icons/file-icon.svg';
import jpgIcon from 'public/icons/jpg-icon.svg';
import pdfIcon from 'public/icons/pdf-icon.svg';
import pngIcon from 'public/icons/png-icon.svg';
import React, {FC, useState} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    files?: MediaFile[];
    hasTitle?: boolean;
}

const MAX_DISPLAYED_FILES = 4;

const getIconByMimetype = ({mimetype}: MediaFile): ISvgIcon => {
    switch (mimetype) {
        case MIME_TYPES.msWord:
        case MIME_TYPES.msPowerPoint:
        case MIME_TYPES.msExcel:
        case MIME_TYPES.openDoc:
        case MIME_TYPES.openPresentation:
        case MIME_TYPES.openSheet:
            return docIcon;
        case MIME_TYPES.jpg:
            return jpgIcon;
        case MIME_TYPES.pdf:
            return pdfIcon;
        case MIME_TYPES.png:
            return pngIcon;
        default:
            return fileIcon;
    }
};

export const ProductCardFiles: FC<IProps> = ({className, files = [], hasTitle = true}) => {
    const [isHidden, setIsHidden] = useState(files.length > MAX_DISPLAYED_FILES);

    return (
        <div className={classNames(className, style.productCardFilesContainer)} id={MAIN_INFO_DATA.FILES.name}>
            {hasTitle && (
                <Typography
                    className={style.productCardFilesHeaderTitle}
                    color="gray100"
                    data-skeleton-item
                    element="div"
                    variant="h4-bold"
                >
                    Инструкции и документы
                </Typography>
            )}
            <div className={style.productCardFiles} data-skeleton-item>
                {Boolean(files.length) &&
                    files.slice(0, isHidden ? MAX_DISPLAYED_FILES : files.length).map((file) => (
                        <div className={style.productCardFile} key={file.title}>
                            <SvgIcon svg={getIconByMimetype(file)} />
                            <div className={style.productCardFileInfo}>
                                <a href={file.url} rel="noreferrer" target="_blank">
                                    <Typography className={style.productCardFileTitle} color="gray100" variant="p">
                                        {file.title}
                                    </Typography>
                                </a>
                                <Typography className={style.productCardFileSize} variant="p">
                                    {formatBytes(file.size)}
                                </Typography>
                            </div>
                        </div>
                    ))}
            </div>
            {files.length > MAX_DISPLAYED_FILES && (
                <Anchor
                    className={classNames(style.productCardFilesButton, {
                        [style.filesHidden]: isHidden,
                    })}
                    data-skeleton-item
                    icon="right"
                    onClick={() => setIsHidden(!isHidden)}
                    size="large"
                    theme="standalone"
                >
                    {isHidden ? 'Все инструкции и документы' : HIDE}
                </Anchor>
            )}
        </div>
    );
};
