import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {isMobileByScreen} from 'core/helpers';
import {useResize} from 'core/hooks/useResize';
import {useCallback, useState} from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>(isMobileByScreen());
    const handleWindowResize = useCallback(() => setIsMobile(window.innerWidth < MIN_DESKTOP_WIDTH), []);

    useResize(handleWindowResize);

    return isMobile;
};
