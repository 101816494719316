import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {FranchiseContext} from 'modules/franchise/context';
import {FranchisePage} from 'modules/franchise/pages/FranchisePage';
import {CONTENT_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const FranchisePageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.main);

    return (
        <FranchiseContext.Provider value={CONTENT_PAGE_SKELETON_DATA}>
            <FranchisePage />
        </FranchiseContext.Provider>
    );
};
