import {RUNTIME_CSS_VARS} from 'core/constants';
import {setCssVar} from 'core/helpers';
import {RefObject, useCallback} from 'react';
import useResizeObserver, {ResizeHandler, ResizeObserverBoxOptions} from 'use-resize-observer';

export const useElementHeightWithCssVar = (
    element: RefObject<HTMLElement>,
    cssVarName: (typeof RUNTIME_CSS_VARS)[keyof typeof RUNTIME_CSS_VARS],
    boxModel: ResizeObserverBoxOptions = 'border-box',
    maxWidth?: number
) => {
    const handleHeightResize = useCallback<ResizeHandler>(
        ({height}) => {
            if (maxWidth && window.innerWidth > maxWidth) {
                return;
            }

            setCssVar({
                cssVarName,
                unit: 'px',
                value: height || 0,
            });
        },
        [cssVarName, maxWidth]
    );

    useResizeObserver({box: boxModel, onResize: handleHeightResize, ref: element});
};
