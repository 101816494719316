import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {InfoBlock} from 'components/InfoBlock';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {TNextPageWithLayout} from 'core/types';
import {PAYMENT_METHODS, STATUS_TITLES} from 'modules/checkout/constants';
import {Aside} from 'modules/order-status/components/Aside';
import {Header} from 'modules/order-status/components/Header';
import {OrderInfoItem} from 'modules/order-status/components/OrderInfoItem';
import {Products} from 'modules/order-status/components/Products';
import {SubBlock} from 'modules/order-status/components/SubBlock';
import {User} from 'modules/order-status/components/User';
import {OrderXPageContext} from 'modules/orders/context';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {DeliveryType, OrderPaymentStatusEnum, OrderStatus} from 'new-models';
import React, {ReactNode, useContext, useMemo} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();
const mainColumnStyle = getGridColumnStyles({md: 8});
const asideColumnStyle = getGridColumnStyles({md: 4});

export const StatusPage: TNextPageWithLayout = () => {
    const {orderData, isOrderLoading} = useContext(OrderXPageContext);
    const {orderStatus, paidStatus, paymentMethod} = orderData ?? {};

    const mountHeader = useMemo<string>(() => {
        if (orderStatus === OrderStatus.CANCELED) {
            return STATUS_TITLES.CANCELED;
        }

        if (!orderData) {
            return STATUS_TITLES.NOT_FOUND;
        }

        if (
            paidStatus?.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID &&
            paymentMethod?.code !== PAYMENT_METHODS['on-reciept']
        ) {
            return STATUS_TITLES.NOT_PAID;
        }

        return STATUS_TITLES.THANK;
    }, [orderData, orderStatus, paidStatus?.status, paymentMethod?.code]);

    const isAdaptiveReverse = useMemo<boolean>(
        () =>
            Boolean(
                orderStatus === OrderStatus.CANCELED ||
                    (paidStatus?.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID &&
                        paymentMethod?.code !== PAYMENT_METHODS['on-reciept'])
            ),
        [orderStatus, paidStatus?.status, paymentMethod?.code]
    );

    const mountInfoBlock = useMemo<ReactNode>(
        () =>
            Boolean(
                orderStatus === OrderStatus.IN_DELIVERY && orderData?.deliveryMethod.type === DeliveryType.COURIER
            ) && <InfoBlock contentText="Курьер свяжется с вами в день доставки" isHideButton />,
        [orderData?.deliveryMethod.type, orderStatus]
    );

    const mountAdditional = useMemo<ReactNode[]>(() => {
        const additional = [];

        // if (orderData?.deliveryData.) {
        //     additional.push(<OrderInfoItem title="Дата доставки" property={orderData?.deliveryData} />);
        // }

        if (orderData?.total.deliveryCost) {
            additional.push(
                <OrderInfoItem
                    property={`${formatPrice((orderData?.total.deliveryCost / PRICE_DENOMINATOR).toString(), {
                        maximumFractionDigits: 2,
                    })} ₽`}
                    title="Стоимость доставки"
                />
            );
        }

        if (orderData?.paymentMethod.title) {
            additional.push(<OrderInfoItem property={orderData?.paymentMethod.title} title="Способ оплаты" />);
        }

        return additional;
    }, [orderData?.paymentMethod.title, orderData?.total.deliveryCost]);

    return (
        <SkeletonWrapper disabled={!isOrderLoading}>
            <Grid as="section" className={style.root} container>
                <PageHeaderTitle className={columnStyle} withContainer={false}>
                    <Typography data-skeleton-item element="h2" variant="h2">
                        {mountHeader}
                    </Typography>
                </PageHeaderTitle>
                {orderData && (
                    <Grid className={classNames(style.content, columnStyle)}>
                        <div className={classNames(style.main, mainColumnStyle)} data-skeleton-item>
                            <Header {...orderData} />
                            <div className={style.mainInfo}>
                                <Products products={orderData.products} />
                                <User customer={orderData.customer} />
                                {mountInfoBlock}
                                {mountAdditional}
                            </div>
                            <SubBlock {...orderData} />
                        </div>
                        <Aside
                            {...orderData}
                            className={classNames(style.aside, asideColumnStyle, {
                                [style.isSkeleton]: isOrderLoading,
                                [style.adaptiveReverse]: isAdaptiveReverse,
                            })}
                        />
                    </Grid>
                )}
            </Grid>
        </SkeletonWrapper>
    );
};
