import classNames from 'classnames';
import {ShadowBlock} from 'components/ShadowBlock';
import {DEBOUNCE_DELAY} from 'core/constants';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {
    CSSProperties,
    FC,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import style from './style.module.scss';

export interface IPopupActionProp {
    ['data-popup-action']?: 'close';
}

export interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    delay?: number;
    popup: ReactNode;
    disable?: boolean;
    willMount?: boolean;
}

export const Popup: FC<IProps> = ({
    willMount = false,
    disable = false,
    className,
    children,
    delay = DEBOUNCE_DELAY,
    popup,
}) => {
    type TTimer = ReturnType<typeof setTimeout>;

    const timerRef = useRef<TTimer>();
    const [display, setDisplay] = useState<CSSProperties['display']>('none');

    const handleMouseOver = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        clearTimeout(timerRef.current);
        setDisplay('block');
    }, []);

    const handleMouseLeave = useCallback<MouseEventHandler<HTMLDivElement>>(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            setDisplay('none');
        }, delay);
    }, [delay]);

    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
        const clickedTarget = event.target as HTMLElement;
        const clickedNode = clickedTarget.closest('[data-popup-action]');

        if (!clickedNode) {
            return;
        }

        const action = clickedNode.getAttribute('data-popup-action') as IPopupActionProp['data-popup-action'];

        switch (action) {
            case 'close': {
                clearTimeout(timerRef.current);
                setDisplay('none');
            }
            default:
        }
    }, []);

    const renderPopup = useMemo<ReactNode>(() => {
        if (willMount && 'none' === display) {
            return null;
        }

        return (
            <div
                className={classNames(style.container, className)}
                onClick={handleClick}
                role="presentation"
                style={{display}}
            >
                {popup}
            </div>
        );
    }, [className, display, handleClick, popup, willMount]);

    useEffect(() => {
        if (disable) {
            setDisplay('none');
        }
    }, [disable]);

    if (disable) {
        return <>{children}</>;
    }

    return (
        <ShadowBlock onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave}>
            {children}
            {renderPopup}
        </ShadowBlock>
    );
};
