import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {
    IAuthenticationState,
    IModalParams,
    TAuthErrors,
    TData,
    TFieldsParams,
    TLoadField,
} from 'modules/authentication/types';
import {HYDRATE} from 'next-redux-wrapper';

export const authenticationModuleName = 'authentication';

const initialState: IAuthenticationState = {
    data: null,
    errors: null,
    fieldsParams: null,
    loads: null,
    modalParams: null,
};

export const authSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[authenticationModuleName];
        },
    },
    initialState,
    name: authenticationModuleName,
    reducers: {
        actionSetData(state, {payload}: PayloadAction<TData>) {
            state.data = payload;
        },
        actionSetErrors(state, {payload}: PayloadAction<TAuthErrors>) {
            state.errors = payload;
        },
        actionSetFieldParams(state, {payload}: PayloadAction<TFieldsParams>) {
            state.fieldsParams = payload;
        },

        actionSetLoad(state, {payload}: PayloadAction<TLoadField>) {
            state.loads = {...state.loads, [payload.name]: payload.isLoading};
        },
        actionSetModalParams(state, {payload}: PayloadAction<IModalParams>) {
            state.modalParams = payload;
        },
    },
});

export const {
    actions: {actionSetLoad, actionSetData, actionSetFieldParams, actionSetModalParams, actionSetErrors},
} = authSlice;

export const {reducer: authenticationReducer} = authSlice;
