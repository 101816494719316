import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {IRequestOptions, TResponse} from 'core/types';
import {FavouritesCreateDataRequest, FavouritesDataResponse} from 'new-models';

export const FAVOURITES_REQUEST_PATHS: Record<any, NonNullable<IRequestOptions['path']>> = {
    FAVOURITES: `/favourites`,
};

export class FavouritesService extends EvolutionHttpClient {
    async getFavouritesList(): Promise<TResponse<FavouritesDataResponse>> {
        const {data} = await this.get<TResponse<FavouritesDataResponse>>({
            url: FAVOURITES_REQUEST_PATHS.FAVOURITES,
        });

        return data;
    }

    async addToFavouritesList({productId}: FavouritesCreateDataRequest): Promise<TResponse<FavouritesDataResponse>> {
        const {data} = await this.post<FavouritesCreateDataRequest, TResponse<FavouritesDataResponse>>({
            body: {
                productId,
            },
            url: FAVOURITES_REQUEST_PATHS.FAVOURITES,
        });

        return data;
    }
    async removeProductInFavouritesList({
        productId,
    }: FavouritesCreateDataRequest): Promise<TResponse<FavouritesDataResponse>> {
        const {data} = await this.delete<TResponse<FavouritesDataResponse>>({
            url: `${FAVOURITES_REQUEST_PATHS.FAVOURITES}/${productId}`,
        });

        return data;
    }
}
