import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {normalizeList} from 'core/helpers/normalize';
import {ICheckoutState} from 'modules/checkout/types';
import {
    CourierSlotsDataResponse,
    DeliveriesDataResponse,
    OrderDetail,
    PaymentsDataResponse,
    PickupPointsDataResponse,
} from 'new-models';

export const checkoutModuleName = 'checkout';

const initialState: ICheckoutState = {
    courierTimeSlotList: undefined,
    deliveries: {data: {}, list: []},
    deliveryInfo: [],
    newOrder: null,
    payments: {data: {}, list: []},
    pickupPoints: {data: {}, list: []},
    selectedPickupPointId: null,
};

export const checkoutSlice = createSlice({
    initialState,
    name: checkoutModuleName,
    reducers: {
        actionSetCourierTimeSlotList(
            state: ICheckoutState,
            {payload}: PayloadAction<CourierSlotsDataResponse['timeslots'] | undefined>
        ) {
            state.courierTimeSlotList = payload;
        },
        actionSetDeliveries(state: ICheckoutState, {payload}: PayloadAction<DeliveriesDataResponse['deliveries']>) {
            if (!payload) {
                state.deliveries.data = {};
                state.deliveries.list = [];
                return;
            }

            const {data, list} = normalizeList(payload);

            state.deliveries.data = data;
            state.deliveries.list = list;
        },
        actionSetDeliveryInfo(state: ICheckoutState, {payload}: PayloadAction<ICheckoutState['deliveryInfo']>) {
            state.deliveryInfo = payload;
        },

        actionSetNewOrder(state, {payload}: PayloadAction<OrderDetail>) {
            state.newOrder = payload;
        },

        actionSetPayments(state: ICheckoutState, {payload}: PayloadAction<PaymentsDataResponse['payments']>) {
            if (!payload) {
                state.payments.data = {};
                state.payments.list = [];
                return;
            }

            const {data, list} = normalizeList(payload);
            state.payments.data = data;
            state.payments.list = list;
        },
        actionSetPickupPoints(
            state: ICheckoutState,
            {payload}: PayloadAction<PickupPointsDataResponse['pickupPoints']>
        ) {
            if (!payload) {
                state.pickupPoints.data = {};
                state.pickupPoints.list = [];
                return;
            }

            const {data, list} = normalizeList(payload);
            state.pickupPoints.data = data;
            state.pickupPoints.list = list;
        },

        actionSetSelectedPickupPointId(
            state: ICheckoutState,
            {payload}: PayloadAction<ICheckoutState['selectedPickupPointId']>
        ) {
            state.selectedPickupPointId = payload;
        },
    },
});

export const {
    actions: {
        actionSetSelectedPickupPointId,
        actionSetPayments,
        actionSetDeliveries,
        actionSetPickupPoints,
        actionSetCourierTimeSlotList,
        actionSetNewOrder,
        actionSetDeliveryInfo,
    },
} = checkoutSlice;

export const {reducer: checkoutReducer} = checkoutSlice;
