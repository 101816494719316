import {useVisualProductImages} from 'modules/product-card/hooks/useVisualProductImages';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {MediaImage, ProductDetail} from 'new-models';
import {useMemo} from 'react';

export const useRenderSliderImages = (product: ProductDetail | undefined): MediaImage[] => {
    const images = useVisualProductImages(product);
    return useMemo<MediaImage[]>(
        () => (product?.type === PRODUCT_TYPE.visual ? images.slice(1) : images),
        [images, product?.type]
    );
};
