import {SkeletonService} from 'modules/skeleton/services/SkeletonService';
import {ForwardRefExoticComponent, PropsWithoutRef, RefAttributes, useLayoutEffect, useRef} from 'react';

export const useMountSkeletonPage = <T extends HTMLElement, P = {}>(
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Page: ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>>,
    pageProps: PropsWithoutRef<P>
) => {
    const ref = useRef<T>(null);

    useLayoutEffect(() => {
        if (ref.current) {
            SkeletonService.makeSkeletonItem(ref.current);
        }
    }, [ref]);

    return <Page ref={ref} {...pageProps} />;
};
