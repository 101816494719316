import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {connectYandexMap} from 'modules/yandex-map/thunks';
import {useEffect} from 'react';

export const useYandexMapConnect = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(connectYandexMap);
    }, [dispatch]);
};
