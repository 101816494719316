import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {FC, ReactNode} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    title: string;
    actionNode?: ReactNode;
    subtitle?: ReactNode;
}

export const BlockTitle: FC<IProps> = ({actionNode, className, title, subtitle}) => {
    return (
        <div className={classNames(style.titleContainer, className)}>
            <Typography className={style.title} color="gray100" data-skeleton-item element="h3" variant="h2">
                {title}
                {subtitle}
            </Typography>
            {actionNode}
        </div>
    );
};
