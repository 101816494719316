import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {errorsModuleName} from 'modules/errors/slice';
import {IErrorState} from 'modules/errors/types';

const select = (state: TAppRootState): IErrorState => state[errorsModuleName];

export const selectErrors = createDraftSafeSelector([select], (state) => state.errors);

export const selectErrorsByModuleName = (moduleName: string) =>
    createDraftSafeSelector([selectErrors], (errors) => {
        return errors[moduleName];
    });

export const selectErrorMessage = (moduleName: string, fieldOrCode: string) =>
    createDraftSafeSelector([selectErrors], (errors) => {
        return errors[moduleName]?.find(({code, field}) => fieldOrCode === field || fieldOrCode === code)?.message;
    });
