import {DEBOUNCE_DELAY} from 'core/constants';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {debounce} from 'lodash';
import {DA_DATA_TYPES} from 'modules/da-data/constants';
import {DaDataService} from 'modules/da-data/services/DaDataService';
import {TDaDataTypes} from 'modules/da-data/types';
import {selectUserLocation} from 'modules/locations/selectors';
import {DaData} from 'new-models';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

interface IUseDaDataSuggestions {
    onBlur: () => void;
    onFocus: () => void;
    showSuggestionsList: boolean;
    suggestionsList: DaData[];
}

export const useDaDataSuggestions = (
    daDataType: TDaDataTypes,
    initialInputValue?: string,
    onlyStreet = false
): IUseDaDataSuggestions => {
    const [suggestionsList, setSuggestionsList] = useState<DaData[]>([]);
    const [showSuggestionsList, setShowSuggestionsList] = useState<boolean>(false);

    const userLocation = useAppSelector(selectUserLocation);

    const daDataService = useMemo(() => new DaDataService(), []);

    const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const loadDaDataSuggestions = async (term: string) => {
        switch (daDataType) {
            case DA_DATA_TYPES.party:
                {
                    const data = await daDataService.getDaDataSuggestionsByParty({query: term});

                    setSuggestionsList(data.suggestions);
                }
                break;
            case DA_DATA_TYPES.address:
                {
                    const data = await daDataService.getDaDataSuggestionsByAddress(
                        onlyStreet
                            ? {
                                  ['from_bound']: {
                                      value: 'street',
                                  },
                                  locations: [
                                      {
                                          city: userLocation?.title,
                                      },
                                  ],
                                  query: term,
                                  ['to_bound']: {
                                      value: 'houses',
                                  },
                              }
                            : {query: term}
                    );

                    setSuggestionsList(data.suggestions);
                }
                break;
            default:
                break;
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = useCallback(
        debounce((term: string) => {
            loadDaDataSuggestions(term);
        }, DEBOUNCE_DELAY),
        []
    );

    useEffect(() => {
        if (initialInputValue) {
            debouncedFetch(initialInputValue as string);
        }

        return () => debouncedFetch.cancel();
    }, [debouncedFetch, initialInputValue]);

    const onBlur = () => {
        if (blurTimeoutRef.current) {
            clearTimeout(blurTimeoutRef.current);
        }

        blurTimeoutRef.current = setTimeout(() => setShowSuggestionsList(false), 100);
    };

    const onFocus = () => {
        if (initialInputValue && !suggestionsList.length) {
            setShowSuggestionsList(true);
            debouncedFetch(initialInputValue as string);
        } else {
            setShowSuggestionsList(true);
        }
    };

    return {onBlur, onFocus, showSuggestionsList, suggestionsList};
};
