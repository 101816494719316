import classNames from 'classnames';
import {EmptyList} from 'components/EmptyList';
import {getGridColumnStyles} from 'components/Grid';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {getElementPluralText} from 'core/helpers/plural';
import {CollectionsList} from 'modules/collections/components/CollectionsList';
import {CollectionShort} from 'new-models';
import {ListingContext} from 'plugins/modules/listing/context';
import {ListPagination} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {usePageChange} from 'plugins/modules/pagination/hooks/usePageChange';
import {usePaginationType} from 'plugins/modules/pagination/hooks/usePaginationType';
import React, {FC, useContext} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

export const CollectionsPage: FC = () => {
    usePaginationType();

    const {count, pageSize, page} = useContext(PaginationContext);

    const {list, isListPartiallyLoading, isListLoading} = useContext(ListingContext);

    const onPageChange = usePageChange();

    if (0 === list.length && false === isListLoading) {
        return <EmptyList title="Список коллекций пуст" />;
    }

    return (
        <>
            <PageHeaderTitle className={style.container} theme="ghost">
                <Typography color="gray100" data-skeleton-item element="h1" variant="h1">
                    Коллекции
                </Typography>
            </PageHeaderTitle>
            <div className={classNames(style.container, style.areaContent)}>
                <CollectionsList list={list as CollectionShort[]} />
                {Boolean(list.length >= pageSize) && (
                    <div className={columnStyle} data-skeleton-item>
                        <ListPagination
                            isLoading={isListPartiallyLoading}
                            onChange={onPageChange}
                            pageNomer={page}
                            pageSize={pageSize}
                            pluralFunction={getElementPluralText}
                            recordCount={count}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
