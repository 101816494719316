import {SvgIcon} from 'components/SvgIcon';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import locationBtnIcon from 'public/icons/location-btn.svg';
import zoomInBtnIcon from 'public/icons/zoom-in-btn.svg';
import zoomOutBtnIcon from 'public/icons/zoom-out-btn.svg';
import {MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

const MAP_OPTIONS = {
    mapCenter: [55.751574, 37.573856],
    mapZoom: 10,
};

export interface IProps {
    initialCenter?: number[];
    mapInstance?: YandexMapService['map'];
    handleCenter?: MouseEventHandler<HTMLButtonElement>;
}

export const YandexMapControls: FC<IProps> = ({initialCenter = MAP_OPTIONS.mapCenter, mapInstance, handleCenter}) => {
    const handleCenterClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        (e) => {
            if (handleCenter) {
                handleCenter(e);
                return;
            }

            if (!mapInstance) {
                return;
            }

            mapInstance.setCenter(initialCenter || MAP_OPTIONS.mapCenter);
        },
        [handleCenter, initialCenter, mapInstance]
    );

    const handleZoom = useCallback(
        (direction: 'in' | 'out') => {
            if (!mapInstance) {
                return;
            }

            const currentZoom = mapInstance.getZoom();
            const zoomChange = 'in' === direction ? 1 : -1;

            mapInstance.setZoom(currentZoom + zoomChange, {checkZoomRange: true});
        },
        [mapInstance]
    );

    const handleZoomIn = useCallback(() => handleZoom('in'), [handleZoom]);

    const handleZoomOut = useCallback(() => handleZoom('out'), [handleZoom]);

    return (
        <div className={style.root}>
            <button className={style.zoomInIcon} onClick={handleZoomIn} type="button">
                <SvgIcon svg={zoomInBtnIcon} />
            </button>
            <button className={style.locationIcon} onClick={handleCenterClick} type="button">
                <SvgIcon svg={locationBtnIcon} />
            </button>
            <button className={style.zoomOutIcon} onClick={handleZoomOut} type="button">
                <SvgIcon svg={zoomOutBtnIcon} />
            </button>
        </div>
    );
};
