import classNames from 'classnames';
import {
    IProps as ISegmentedControlProps,
    ISegmentControlValueList,
    SegmentedControl,
} from 'components/SegmentedControl';
import {useCategoryByRouterId} from 'modules/categories/hooks/useCategoryByRouterId';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {useRouter} from 'next/router';
import React, {FC, useCallback, useMemo} from 'react';
import {CATALOG_ROUTE, COLLECTIONS_LIST_PAGE} from 'routing/constants';

import style from './style.module.scss';

const PRODUCT_TAB: ISegmentControlValueList = {
    id: 0,
    label: 'Товары',
    value: 'products',
};

const COLLECTION_TAB: ISegmentControlValueList = {
    id: 1,
    label: 'Коллекции',
    value: 'collections',
};

const SWITCHER_VALUES: ISegmentedControlProps['valueList'] = [PRODUCT_TAB, COLLECTION_TAB];

interface ICollectionSwitcherProps {
    className?: string;
}

export const CollectionSwitcher: FC<ICollectionSwitcherProps> = ({className}) => {
    const router = useRouter();

    const category = useCategoryByRouterId();

    const checkedTab = useMemo<ISegmentControlValueList['id']>(() => {
        const regex = new RegExp(`${COLLECTIONS_LIST_PAGE}$`, 'ug');
        return regex.test(router.route) ? COLLECTION_TAB.id : PRODUCT_TAB.id;
    }, [router.route]);

    const handleOnChange = useCallback<NonNullable<ISegmentedControlProps['onChange']>>(
        (value) => {
            if (!category) {
                return;
            }

            const categoryPostfix = COLLECTION_TAB.id === Number(value) ? COLLECTIONS_LIST_PAGE : '';
            const url = `${CATALOG_ROUTE}/${CategoryService.makeCategoryUrl(
                category.code,
                category.id
            )}/${categoryPostfix}`;

            router.push(url);
        },
        [category, router]
    );

    return (
        <SegmentedControl
            className={classNames(style.collectionSwitcherContainer, className)}
            defaultCheckedId={checkedTab}
            onChange={handleOnChange}
            valueList={SWITCHER_VALUES}
        />
    );
};
