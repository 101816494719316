import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {
    TDeliveryCourierTimeSlotList,
    TDeliveryPickupPointList,
    TGetDeliveryMethodListResponse,
} from 'modules/checkout/models/DeliveriesResponse';
import {TCreateOrderResponse} from 'modules/checkout/models/OrderResponse';
import {TPaymentLinkResponse} from 'modules/checkout/models/PaymentLinkResponse';
import {TGetPaymentMethodListResponse} from 'modules/checkout/models/PaymentsResponse';
import {OrderBase, OrderCreateRequest} from 'new-models';

export interface ISpecificDeliveryParams {
    locationId: string;
    paymentId?: string;
}

export class CheckoutService extends EvolutionHttpClient {
    public async getDeliveryMethods(paymentId?: string): Promise<TGetDeliveryMethodListResponse> {
        const params = new URLSearchParams();

        if (paymentId) {
            params.set('paymentId', paymentId);
        }

        const query = params.toString();

        const {data} = await this.get<TGetDeliveryMethodListResponse>({
            url: `/order/deliveries${query ? `?${query}` : ''}`,
        });

        return data;
    }

    public async getPaymentMethods(deliveryId?: string): Promise<TGetPaymentMethodListResponse> {
        const params = new URLSearchParams();

        if (deliveryId) {
            params.set('deliveryId', deliveryId);
        }

        const query = params.toString();

        const {data} = await this.get<TGetPaymentMethodListResponse>({
            url: `/payments${query ? `?${query}` : ''}`,
        });

        return data;
    }

    public async getPickupPoints(
        deliveryId: string,
        params: ISpecificDeliveryParams
    ): Promise<TDeliveryPickupPointList> {
        const searchParams = new URLSearchParams(params as unknown as Record<string, string>);
        const query = searchParams.toString();

        const {data} = await this.get<TDeliveryPickupPointList>({
            url: `/delivery/${deliveryId}/pickupPoints${query ? `?${query}` : ''}`,
        });

        return data;
    }

    public async getCourierTimeSlots(
        deliveryId: string,
        params: ISpecificDeliveryParams
    ): Promise<TDeliveryCourierTimeSlotList> {
        const searchParams = new URLSearchParams(params as unknown as Record<string, string>);
        const query = searchParams.toString();

        const {data} = await this.get<TDeliveryCourierTimeSlotList>({
            url: `/delivery/${deliveryId}/courier${query ? `?${query}` : ''}`,
        });

        return data;
    }

    public async createOrder(body: OrderCreateRequest): Promise<TCreateOrderResponse> {
        const {data} = await this.post<OrderCreateRequest, TCreateOrderResponse>({
            body,
            url: '/order/create',
        });

        return data;
    }

    public async getPaymentLink(id: OrderBase['id']): Promise<TPaymentLinkResponse> {
        const {data} = await this.get<TPaymentLinkResponse>({
            url: `/profile/order/${id}/paymentlink`,
        });

        return data;
    }
}
