import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {CollectionsList} from 'modules/collections/components/CollectionsList';
import {selectPopularCollectionList} from 'modules/collections/selectors';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import React, {FC} from 'react';

export const HomeTopCollections: FC = () => {
    const popularCollectionList = useAppSelector(selectPopularCollectionList);

    if (
        !popularCollectionList?.length ||
        popularCollectionList.every((item) => !item.products || !item.products.products.length)
    ) {
        return null;
    }

    return (
        <PageSectionBlock href="/collections" linkText="Все коллекции" title="Коллекции для вашего уюта">
            <CollectionsList list={popularCollectionList} />
        </PageSectionBlock>
    );
};
