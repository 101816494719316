import {Anchor} from 'components/Anchor';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import editIcon from 'public/icons/edit.svg';
import {CART_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export const TotalTitle = () => (
    <div className={style.root}>
        <Typography variant="h4">Ваш заказ</Typography>
        <Anchor href={CART_ROUTE} icon="left" iconNode={<SvgIcon svg={editIcon} />} theme="standalone">
            Изменить
        </Anchor>
    </div>
);
