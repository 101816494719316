import React, {RefObject, useMemo, useState} from 'react';
interface IUseCartHeaderPopupProps {
    productsListContainerRef: RefObject<HTMLDivElement>;
    listLength: number;
}

export const MIN_LIST_LENGTH = 2;

export const useCartHeaderPopup = ({productsListContainerRef, listLength}: IUseCartHeaderPopupProps) => {
    const [sumScrollHeight, setSumScrollHeight] = useState<number>(0);

    const isBottom = useMemo<boolean>(() => {
        if (productsListContainerRef.current) {
            return sumScrollHeight >= productsListContainerRef.current.scrollHeight;
        }
        return false;
    }, [productsListContainerRef, sumScrollHeight]);

    const handleScroll = (event: React.UIEvent) => {
        const target = event.target as HTMLDivElement;

        setSumScrollHeight(Math.round(target.scrollTop) + target.clientHeight);
    };

    const isHeaderShadow = useMemo(() => MIN_LIST_LENGTH < listLength, [listLength]);

    const isFooterShadow = useMemo(() => MIN_LIST_LENGTH < listLength && !isBottom, [listLength, isBottom]);

    return {
        handleScroll,
        isFooterShadow,
        isHeaderShadow,
    };
};
