import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {TOAST_NOTIFICATIONS} from 'modules/cart/constants';
import {setAfterToast} from 'modules/cart/helpers';
import {selectCartProductsList} from 'modules/cart/selectors';
import {actionSetAfterRemoveToast, actionSetCart, actionSetDeliveryInfo} from 'modules/cart/slice';
import {TProductDeliveryInfoResponse} from 'modules/product-card/services/ProductDeliveryInfoResponse';
import {
    BasketDataResponse,
    PatchBasketRequestData,
    ProductDetail,
    ProductShort,
    PromocodeActionDataRequest,
} from 'new-models';

export const updateCart =
    (cartItem: PatchBasketRequestData, callback?: () => void): TAppThunk<void> =>
    async (dispatch, _, {cartService}) => {
        const data = await dispatch(
            requestAction<TResponse<BasketDataResponse>>({
                requestCb: () => cartService.updateCart(cartItem),
                type: `${actionSetCart.type}__id:${cartItem.productId}`,
            })
        );

        if (callback) {
            // TODO: Не надо пробрасывать колбэки блин в санки
            callback();
        }

        dispatch(actionSetCart(data));
    };

export const updateCartBatch =
    (cartItems: PatchBasketRequestData[]): TAppThunk<boolean> =>
    async (dispatch, getState, {cartService}) => {
        const data = await dispatch(
            requestAction<TResponse<BasketDataResponse>>({
                requestCb: () => cartService.updateCartBatch(cartItems),
                type: actionSetCart.type,
            })
        );

        const cartProductsList = selectCartProductsList(getState());
        setAfterToast(cartProductsList, data.products, dispatch);

        dispatch(actionSetCart(data));
        return Boolean(data.products);
    };

export const clearCart: TAppThunk<void> = async (dispatch, _, {cartService}) => {
    await dispatch(
        requestAction<any>({
            requestCb: () => cartService.clearCart(),
            type: actionSetCart.type,
        })
    );

    dispatch(actionSetCart());
    dispatch(actionSetAfterRemoveToast(TOAST_NOTIFICATIONS.CART_CLEARED));
};

export const removeCartProduct =
    (productId: ProductShort['id']): TAppThunk<void> =>
    async (dispatch, getState, {cartService}) => {
        const data = await dispatch(
            requestAction<TResponse<BasketDataResponse>>({
                requestCb: () =>
                    cartService.updateCart({
                        productId,
                        quantity: 0,
                    }),
                type: actionSetCart.type,
            })
        );

        const cartProductsList = selectCartProductsList(getState());

        setAfterToast(cartProductsList, data.products, dispatch);

        dispatch(actionSetCart(data));
    };

export const getCart: TAppThunk<void> = async (dispatch, _, {cartService}) => {
    const data = await dispatch(
        requestAction<TResponse<BasketDataResponse>>({
            requestCb: () => cartService.getCart(),
            type: actionSetCart.type,
        })
    );

    dispatch(actionSetCart(data));
};

export const applyPromoCode =
    (values: PromocodeActionDataRequest): TAppThunk<boolean> =>
    async (dispatch, _, {cartService}) => {
        const data = await dispatch(
            requestAction<TResponse<BasketDataResponse>>({
                requestCb: () => cartService.applyPromoCode(values),
                type: 'promoCode',
            })
        );

        dispatch(actionSetCart(data));
        return Boolean(data);
    };

export const deletePromoCode =
    (values: PromocodeActionDataRequest): TAppThunk<boolean> =>
    async (dispatch, _, {cartService}) => {
        const data = await dispatch(
            requestAction<TResponse<BasketDataResponse>>({
                requestCb: () => cartService.deletePromoCode(values),
                type: 'promoCode',
            })
        );

        dispatch(actionSetCart(data));

        return Boolean(data);
    };

export const getDeliveryInfo =
    (locationId: string, productIds?: ProductDetail['id'][] | null): TAppThunk<void> =>
    async (dispatch, _, {productCardService}) => {
        const data = await dispatch(
            requestAction<TProductDeliveryInfoResponse>({
                requestCb: () => productCardService.getDeliveryInfo(locationId, productIds),
                type: actionSetDeliveryInfo.type,
            })
        );

        const {deliveries} = data || {};

        if (!deliveries) {
            return;
        }

        dispatch(actionSetDeliveryInfo(deliveries));
    };
