import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {getRuntimeConfig} from 'core/next/helpers';
import {TCalcPriceOptions, TMindboxCartItem, TMindboxCartProduct, TMindboxEntity} from 'modules/analytics/types';
import {Category, ProductBase, Profile} from 'new-models';

export const calcPrice = (price: ProductBase['price'], options?: TCalcPriceOptions): string => {
    const currentPrice = (price.mainDiscount?.price ?? price.main?.price) * (options?.count ?? 1);

    return formatPrice((currentPrice / PRICE_DENOMINATOR).toString(), {
        maximumFractionDigits: 2,
    });
};

export const makeMindBoxProduct = ({article}: Pick<ProductBase, 'article'>): TMindboxEntity => ({
    ids: {
        [getRuntimeConfig().NEXT_PUBLIC_MINDBOX_ENDPOINT_ID ?? 'id']: article,
    },
});

export const makeMindboxCategory = ({id}: Pick<Category, 'id'>): TMindboxEntity => ({
    ids: {
        [getRuntimeConfig().NEXT_PUBLIC_MINDBOX_ENDPOINT_ID ?? 'id']: id,
    },
});

export const makeMindboxCustomer = (profile: Profile): Pick<Profile, 'email' | 'phone'> => {
    const {email, phone} = profile;

    return {email, phone};
};

export const makeMindboxCartItem = (cartItem: TMindboxCartProduct): TMindboxCartItem => {
    return {
        count: cartItem.quantity,
        priceOfLine: calcPrice(cartItem.price, {count: cartItem.quantity}),
        pricePerItem: calcPrice(cartItem.price),
        product: makeMindBoxProduct({article: cartItem.article}),
    };
};
