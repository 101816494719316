import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TPopularPromoResponse, TPromoResponse, TPromoXResponse} from 'modules/promo/models/PromoResponse';

export interface IPromoListParams {
    limit?: number;
}

export class PromoService extends EvolutionHttpClient {
    public async getPopularPromoList(): Promise<TPopularPromoResponse> {
        const {data} = await this.get<TPopularPromoResponse>({
            url: `/actions/top`,
        });

        return data;
    }

    public async getPromoList(query: URLSearchParams, params: IPromoListParams = {}): Promise<TPromoResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, params);

        const {data} = await this.get<TPromoResponse>({
            url: `/actions?${query}`,
        });

        return data;
    }

    public async getPromo(code: string): Promise<TPromoXResponse> {
        const {data} = await this.get<TPromoXResponse>({
            url: `/action/by-code/${code}`,
        });

        return data;
    }
}
