import {getDayPluralText, getHourPluralText} from 'core/helpers/plural';
import {differenceInCalendarDays, differenceInHours, format} from 'date-fns';
import ruDateFns from 'date-fns/locale/ru';
import {DeliveryType} from 'new-models';

type TParametersFormat = Parameters<typeof format>;

export const formatDateWithLocal: (
    date?: string | number | Date,
    template?: TParametersFormat[1],
    options?: TParametersFormat[2]
) => ReturnType<typeof format> = (date, template = 'dd MMMM yyyy', options = {locale: ruDateFns}) => {
    let dateObj;

    if (date instanceof Date) {
        dateObj = date;
    } else if (date) {
        dateObj = new Date(date);
    } else {
        dateObj = new Date();
    }

    return format(dateObj, template, options);
};

interface IRelativeDateForDeliveryInfoOptions {
    endDate: Date;
    nowDate?: Date;
    notConcreteDateText?: string;
    type: DeliveryType;
}

interface IRelativeDateForDeliveryInfoReturn {
    text: string;
    hasConcreteDate: boolean;
}
export const relativeDateForDeliveryInfo = ({
    endDate,
    nowDate = new Date(),
    notConcreteDateText = 'дату и время уточняйте у оператора',
    type,
}: IRelativeDateForDeliveryInfoOptions): IRelativeDateForDeliveryInfoReturn => {
    const diffDays = differenceInCalendarDays(endDate, nowDate);
    const diffHour = differenceInHours(endDate, nowDate, {roundingMethod: 'round'});
    const hourFormatted = formatDateWithLocal(endDate, 'HH:mm', {locale: ruDateFns});

    let preposition = '';
    let text = '';
    let hasConcreteDate = true;

    switch (type) {
        case DeliveryType.PICKUP: {
            preposition = 'c';
            break;
        }
        default: {
            preposition = 'после';
        }
    }

    if (0 === diffDays) {
        let hourText = '';
        if (0 < diffHour) {
            hourText += 'через ';
            if (1 < diffHour) {
                hourText += `${diffHour} `;
            }
            hourText += getHourPluralText(diffHour);
        }
        text = `сегодня ${hourText}`;
    } else if (1 === diffDays) {
        text = `завтра ${preposition} ${hourFormatted}`;
    } else if (1 < diffDays && 7 > diffDays) {
        text = `через ${diffDays} ${getDayPluralText(diffDays)} ${preposition} ${hourFormatted}`;
    } else {
        text = notConcreteDateText;
        hasConcreteDate = false;
    }

    return {
        hasConcreteDate,
        text,
    };
};
