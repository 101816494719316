import classNames from 'classnames';
import {getGridColumnStyles} from 'components/Grid';
import {TPropsWithClassName} from 'core/types';
import {CheckoutTotalInfo} from 'modules/checkout/components/CheckoutTotalInfo';
import React, {forwardRef} from 'react';

import style from './style.module.scss';
interface IProps extends TPropsWithClassName {}
const columnStyle = getGridColumnStyles({md: 4});

export const CheckoutTotals = forwardRef<HTMLDivElement, IProps>(function CheckoutTotals({className}, ref) {
    return (
        <div className={columnStyle} ref={ref}>
            <CheckoutTotalInfo className={classNames(style.root, className)} />
        </div>
    );
});
