import classNames from 'classnames';
import {IProps as ICategoryTagProps} from 'components/Tag/CategoryTag';
import {QuickFilterTag} from 'components/Tag/QuickFilterTag';
import {isMobileByScreen} from 'core/helpers';
import {TClosureCallback, TPropsWithClassName} from 'core/types';
import {FastFilter} from 'models';
import {FilterContext} from 'plugins/modules/filter/context';
import {useActiveComboFilter} from 'plugins/modules/filter/hooks/useActiveComboFilter';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import React, {FC, useCallback, useContext} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {}

export const ComboFiltersTagList: FC<IProps> = ({className}) => {
    const activeComboFilter = useActiveComboFilter();

    const {comboFilterList, urlService: filterService} = useContext(FilterContext);

    const isLoading = useIsListLoading();

    const handleToggleComboFilter = useCallback<TClosureCallback<FastFilter, ICategoryTagProps['onClick']>>(
        (comboFilter) => {
            return async () => {
                if (isLoading) {
                    return;
                }

                if (String(comboFilter.id) === activeComboFilter?.tagId) {
                    await filterService?.flushParam();

                    if (isMobileByScreen()) {
                        window.scrollTo(0, 1);
                    }
                    return;
                }

                await filterService?.addComboFilter(comboFilter);
            };
        },
        [activeComboFilter?.tagId, filterService, isLoading]
    );

    return Boolean(comboFilterList.length) ? (
        <ul className={classNames(style.comboFilterTagList, className)}>
            {comboFilterList.map((comboFilter) => {
                return (
                    <li data-skeleton-item key={comboFilter.code}>
                        <QuickFilterTag
                            isActive={String(comboFilter.id) === activeComboFilter?.tagId}
                            name={comboFilter.title}
                            onClick={handleToggleComboFilter(comboFilter)}
                        />
                    </li>
                );
            })}
        </ul>
    ) : null;
};
