import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {getUrlPathname} from 'core/helpers';
import {usePageLoading} from 'core/next/hooks/usePageLoading';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {SearchPanel} from 'modules/search-popup/components/SearchPanel';
import {MAX_SEARCH_TERM_LENGTH, MIN_FETCH_SYMBOLS_AMOUNT} from 'modules/search-popup/constants';
import {usePendingLoader} from 'modules/search-popup/hooks/usePendingLoader';
import {useSearchModule} from 'modules/search-popup/hooks/useSearchModule';
import {
    selectList,
    selectRecommendationsList,
    selectSearchCategoryList,
    selectSearchQuery,
    selectSearchSuggestions,
} from 'modules/search-popup/selectors';
import {SEARCH_PANEL_DATA_SKELETON_DATA} from 'modules/skeleton/constants';
import {ProductShort} from 'new-models';
import closeIcon from 'public/icons/close.svg';
import searchIcon from 'public/icons/search.svg';
import searchLoaderIcon from 'public/icons/search-loader.svg';
import React, {FocusEvent, InputHTMLAttributes, memo, ReactNode, useCallback, useId, useMemo, useRef} from 'react';
import {SEARCH_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    isNeedClearSearch?: boolean;
    isHeader?: boolean;
    placeholder?: string;
    onClose?: () => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    isPanelVisible?: boolean;
    hasClearField?: boolean;
}

// eslint-disable-next-line complexity
export const Search = memo<IProps>(function Search({
    isPanelVisible,
    className,
    isNeedClearSearch = false,
    isHeader = false,
    hasClearField = true,
    onFocus,
    onClose,
    placeholder,
    ...restInputAttributes
}) {
    const isLoaderVisible = usePendingLoader();
    const searchInputRef = useRef<HTMLInputElement>(null);
    const {
        isSearchPanelVisible,
        onChange,
        onFocusInput,
        handleSearchClick,
        handleCrossClick,
        handleSubmit,
        onClickOutSearch,
        isProcessing,
    } = useSearchModule(searchInputRef.current, isPanelVisible, onClose);

    const isPageLoading = usePageLoading({
        resolver: (url) => getUrlPathname(url) === SEARCH_ROUTE,
    });

    const uid = useId();

    const searchValue = useAppSelector(selectSearchQuery);
    const searchCategories = useAppSelector(selectSearchCategoryList);
    const searchSuggestions = useAppSelector(selectSearchSuggestions);
    const searchProducts = useAppSelector(selectList<ProductShort>);
    const recommendations = useAppSelector(selectRecommendationsList);

    const makeData = useMemo(() => {
        return isProcessing
            ? SEARCH_PANEL_DATA_SKELETON_DATA
            : {
                  recommendations,
                  searchCategories,
                  searchProducts,
                  searchSuggestions,
              };
    }, [isProcessing, recommendations, searchCategories, searchProducts, searchSuggestions]);

    const renderSearchLoader = useMemo<ReactNode>(() => {
        if (!isLoaderVisible || MIN_FETCH_SYMBOLS_AMOUNT < searchValue.length) {
            return null;
        }

        return (
            <div className={classNames(style.searchLoaderIcon, 'search-loader-icon')}>
                <SvgIcon svg={searchLoaderIcon} />
            </div>
        );
    }, [isLoaderVisible, searchValue.length]);

    const renderSearchExtraAction = useMemo<ReactNode>(() => {
        if (renderSearchLoader) {
            return renderSearchLoader;
        }

        if (!hasClearField) {
            return null;
        }

        if (!searchValue.length) {
            return (
                <button
                    className={classNames(style.searchButton, 'search-button', {
                        [style.isPanelVisible]: isPanelVisible,
                        [style.isActive]: MIN_FETCH_SYMBOLS_AMOUNT <= searchValue.length,
                    })}
                    type="submit"
                >
                    <SvgIcon svg={searchIcon} />
                </button>
            );
        }

        return (
            <button
                className={classNames(style.labelCross, 'search-label-cross', {
                    [style.labelCrossVisible]: 0 < searchValue.length,
                    [style.isSearchActive]: MIN_FETCH_SYMBOLS_AMOUNT <= searchValue.length && isPanelVisible,
                })}
                onClick={handleCrossClick}
                type="reset"
            >
                <SvgIcon svg={closeIcon} />
            </button>
        );
    }, [handleCrossClick, hasClearField, isPanelVisible, renderSearchLoader, searchValue.length]);

    const handleFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            onFocusInput();
            onFocus?.(e);
        },
        [onFocus, onFocusInput]
    );

    return (
        <>
            <form
                className={classNames(style.form, 'search-form', className, {
                    [style.header]: isHeader,
                    [style.isActive]: isPanelVisible,
                })}
                onSubmit={handleSubmit}
            >
                <label
                    className={classNames(style.label, 'search-label', {[style.isActive]: isPanelVisible})}
                    htmlFor={uid}
                >
                    <input
                        {...restInputAttributes}
                        className={classNames(style.input, 'search-input', {
                            [style.withClearSearch]: isNeedClearSearch,
                            [style.isPanelVisible]: isPanelVisible,
                            [style.isActive]: MIN_FETCH_SYMBOLS_AMOUNT <= searchValue.length,
                        })}
                        disabled={isPageLoading}
                        id={uid}
                        maxLength={MAX_SEARCH_TERM_LENGTH}
                        name="search"
                        onChange={onChange}
                        onClick={handleSearchClick}
                        onFocus={handleFocus}
                        placeholder={placeholder}
                        ref={searchInputRef}
                        type="search"
                        value={searchValue}
                    />
                    {renderSearchExtraAction}
                </label>
                {isSearchPanelVisible && (
                    <SearchPanel isLoading={isProcessing} searchValue={searchValue} {...makeData} />
                )}
            </form>
            {isSearchPanelVisible && <div className={style.overlay} onClick={onClickOutSearch} rel="presentaion" />}
        </>
    );
});
