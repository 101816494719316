import {configureStore} from '@reduxjs/toolkit';
import {extraArgs} from 'core/redux/extra-args';
import {rootReducer} from 'core/redux/reducers';
import {ReduxService} from 'core/redux/services/ReduxService';
import {TAppStore} from 'core/redux/types';
import {dialogListener} from 'modules/portal/middleware';
import {createWrapper} from 'next-redux-wrapper';

export function getStore() {
    return configureStore({
        devTools: true,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
                thunk: {
                    extraArgument: extraArgs,
                },
            }).concat(dialogListener.middleware),
        reducer: rootReducer,
    });
}

export const store = getStore();
export const wrapper = createWrapper<TAppStore>(getStore);

ReduxService.clientReduxStore = store;
