import {withFilter} from 'plugins/modules/filter/state';
import {withListing} from 'plugins/modules/listing/state';
import {withPagination} from 'plugins/modules/pagination/state';
import {TSearchState} from 'plugins/modules/search/types';
import {withSorting} from 'plugins/modules/sorting/state';

export const initialSearchState: TSearchState = withListing(
    withSorting(
        withPagination(
            withFilter({
                categoryList: [],
                query: '',
                redirect: null,
                storeFilterList: [],
                tagList: [],
            })
        )
    )
);

export const withSearch = <T>(originState: T): T & TSearchState => {
    return {
        ...originState,
        ...initialSearchState,
    };
};
