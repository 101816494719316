import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectActiveIdx} from 'modules/slider/selectors';
import {actionSetActiveIdx} from 'modules/slider/slice';
import {useCallback, useEffect, useState} from 'react';

export const useCustomSwiperNavigation = (slidesCount: number) => {
    const activeIndex = useAppSelector(selectActiveIdx);
    const dispatch = useAppDispatch();

    const [disabledBtn, setDisabledBtn] = useState<'prev' | 'next' | 'off'>('prev');

    const onNext = useCallback(() => {
        dispatch(actionSetActiveIdx((activeIndex + 1) % slidesCount));
    }, [activeIndex, dispatch, slidesCount]);

    const onPrev = useCallback(() => {
        dispatch(actionSetActiveIdx((activeIndex - 1 + slidesCount) % slidesCount));
    }, [activeIndex, dispatch, slidesCount]);

    useEffect(() => {
        if (0 === activeIndex) {
            setDisabledBtn('prev');
            return;
        }

        if (slidesCount - 1 === activeIndex) {
            setDisabledBtn('next');
            return;
        }

        setDisabledBtn('off');
    }, [activeIndex, slidesCount]);

    return {isNextDisabled: 'next' === disabledBtn, isPrevDisabled: 'prev' === disabledBtn, onNext, onPrev};
};
