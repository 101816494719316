import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {formatDateWithLocal} from 'core/helpers/date';
import {DeliveryInfo} from 'new-models';
import React from 'react';

import style from './style.module.scss';

interface IProps {
    productDeliveryInfo?: DeliveryInfo[];
}

// TODO: implement productDeliveryInfo logic
export const ProductDeliveryInfo: FC<IProps> = ({productDeliveryInfo}) => {
    return (
        <div className={style.root}>
            <Typography color="gray100" variant="h7">
                Информация о доставке
            </Typography>
            <ul className={style.inner}>
                {productDeliveryInfo &&
                    Boolean(productDeliveryInfo?.length) &&
                    productDeliveryInfo.map(({deliveryMethod, deliveryDate, deliveryMinPrice}) => (
                        <li className={style.contentBlock} key={deliveryMethod.id}>
                            <MediaHarvester className={style.icon} media={deliveryMethod.icon} />
                            <div className={style.description}>
                                <Typography variant="p-regular">{deliveryMethod.title}</Typography>
                                {deliveryDate && (
                                    <Typography variant="p-regular">{`с ${formatDateWithLocal(
                                        deliveryDate,
                                        'dd MMMM'
                                    )},`}</Typography>
                                )}
                                {/* {deliveryInterval && <Typography variant="p-regular">{deliveryInterval}</Typography>}*/}
                                {Boolean(deliveryMinPrice) && (
                                    <Typography variant="p-bold-strong">{`от ${formatPrice(
                                        (deliveryMinPrice / PRICE_DENOMINATOR).toString(),
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )} ₽`}</Typography>
                                )}
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
