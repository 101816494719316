import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TResponse} from 'core/types';
import {ContentPage} from 'new-models';

const ROOT_REQUEST_URL = '/content/page';

export class ContentService extends EvolutionHttpClient {
    async getContentPageByCode(code: string | string[]): Promise<TResponse<ContentPage>> {
        const {data} = await this.get<TResponse<ContentPage>>({
            url: `${ROOT_REQUEST_URL}/${code}`,
        });

        return data;
    }
}
