import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Typography} from 'components/Typography';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo, useMemo} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

interface IProps extends TPropsWithChildrenRequire, TPropsWithClassName {
    title: string;
    RightSideTitleComponent?: FC;
    isFluidGap?: boolean;
}

export const CheckoutSection = memo<IProps>(function CheckoutSection({
    title,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RightSideTitleComponent,
    className,
    children,
    isFluidGap,
}) {
    const renderHeader = useMemo(() => {
        return (
            <div className={classNames(style.header, columnStyle)}>
                <Typography data-skeleton-item element="h4" variant="h4">
                    {title}
                </Typography>
                {RightSideTitleComponent && <RightSideTitleComponent className={style.rightSideComponent} />}
            </div>
        );
    }, [RightSideTitleComponent, title]);

    return (
        <Grid className={classNames(style.root, {[style.fluidGap]: isFluidGap}, className)}>
            {renderHeader}
            <div className={columnStyle}>{children}</div>
        </Grid>
    );
});
