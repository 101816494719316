import classNames from 'classnames';
import {InputSelect, ISelectOption, ISelectProps} from 'components/InputSelect';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {Sorting} from 'new-models';
import {ISortingContext} from 'plugins/modules/sorting/context';
import sortingSelectChevron from 'public/icons/sorting-select-chevron.svg';
import React, {FC, memo, useMemo} from 'react';

import style from './style.module.scss';

const SortDropDownIndicator: FC = () => {
    return (
        <div className={style.sortingSelectIndicator}>
            <SvgIcon svg={sortingSelectChevron} />
        </div>
    );
};

export interface ISortingSelectProps extends TPropsWithClassName {
    sortingList: ISortingContext['sortingList'];
    isLoading?: boolean;
    onSortChange?: ISelectProps<Sorting['direction']>['onChange'];
}

export const SortingSelect = memo<ISortingSelectProps>(function SortingSelect({
    isLoading,
    className,
    sortingList,
    onSortChange,
}: ISortingSelectProps) {
    const sortOptions = useMemo<ISelectProps<Sorting['direction']>['options']>(() => {
        return sortingList.map((sortItem) => {
            return {
                label: sortItem.title,
                value: sortItem.direction,
            } as ISelectOption<Sorting['direction']>;
        });
    }, [sortingList]);

    const defaultSort = useMemo<ISelectProps<Sorting['direction']>['value']>(() => {
        const appliedSort = sortingList.find(({isApplied}) => isApplied);

        if (!appliedSort) {
            return {
                label: 'Сначала популярные',
                value: 'rating-desc',
            } as ISelectOption<Sorting['direction']>;
        }

        return {
            label: appliedSort.title,
            value: appliedSort.direction,
        } as ISelectOption<Sorting['direction']>;
    }, [sortingList]);

    return (
        <InputSelect<Sorting['direction']>
            className={classNames(style.select, className)}
            disabled={isLoading}
            dropDownIndicatorIcon={<SortDropDownIndicator />}
            emptyMessage="Не найдено"
            onChange={onSortChange}
            options={sortOptions}
            value={defaultSort}
            variant="filter"
        />
    );
});
