import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PAYMENT_METHODS} from 'modules/checkout/constants';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import {OrderDetail, OrderPaymentStatusEnum} from 'new-models';
import checkIcon from 'public/icons/check.svg';
import closeIcon from 'public/icons/close.svg';
import React, {ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends Pick<OrderDetail, 'total' | 'paidStatus' | 'paymentMethod'> {}

export const SubBlock: FC<IProps> = ({total, paidStatus, paymentMethod}) => {
    const mountTitle = useMemo<ReactNode>(() => {
        if (paymentMethod.code === PAYMENT_METHODS['on-reciept']) {
            return (
                <Typography className={style.title} color="info50">
                    Оплата при получении
                </Typography>
            );
        }

        if (paidStatus.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID) {
            return (
                <Typography className={style.title} color="error50">
                    <SvgIcon svg={closeIcon} />
                    Не оплачено
                </Typography>
            );
        }

        if (paidStatus.status === OrderPaymentStatusEnum.StatusEnum.PAID) {
            return (
                <Typography className={style.title} color="success50">
                    <SvgIcon svg={checkIcon} />
                    Оплачено
                </Typography>
            );
        }
        return <Typography color="info50">{paymentMethod.title}</Typography>;
    }, [paidStatus.status, paymentMethod.code, paymentMethod.title]);
    return (
        <div className={style.root}>
            {mountTitle}
            <div className={style.price}>
                <TotalPriceBlock isHiddeTotalTitle total={total} usedIn="cartResume" />
            </div>
        </div>
    );
};
