import classNames from 'classnames';
import {Tag} from 'components/Tag';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {memo, MouseEventHandler, PropsWithChildren} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, PropsWithChildren {
    name?: string;
    isActive?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    description?: string;
}

export const FilterTag = memo<IProps>(function FilterTag({name, description, isActive = true, onClick, className}) {
    return name || description ? (
        <Tag
            className={classNames(style.filter, className, {
                [style.active]: isActive,
            })}
            isActive={isActive}
            onClick={onClick}
        >
            <Typography className={style.content} element="span" variant="p-regular">
                {name && <span className={style.name}>{`${name} `}</span>}
                {description && <span className={style.description}>{description}</span>}
            </Typography>
        </Tag>
    ) : null;
});
