import classNames from 'classnames';
import {Button, BUTTON_SIZE} from 'components/Button';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {useAuthBySmsStrategy} from 'modules/authentication/hooks/strategies/useAuthBySmsStrategy';
import {PAYMENT_METHODS} from 'modules/checkout/constants';
import {useStatusCbs} from 'modules/order-status/hooks/useStatusCbs';
import {useIsLoggedIn} from 'modules/user/hooks/useIsLoggedIn';
import {OrderDetail, OrderPaymentStatusEnum, OrderStatus} from 'new-models';
import Link from 'next/link';
import {MouseEventHandler, ReactNode, useCallback, useMemo} from 'react';
import {PROFILE_ORDERS_ROUTE} from 'routing/constants';

import style from './style.module.scss';

interface IProps extends OrderDetail, TPropsWithClassName {}

export const Aside: FC<IProps> = ({className, ...props}) => {
    const {orderStatus, paidStatus, paymentMethod} = props;

    const {payOrder, createNew} = useStatusCbs();

    const userIsLoggedIn = useIsLoggedIn();

    const {startAuthBySms} = useAuthBySmsStrategy();

    const handleLoginClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        startAuthBySms();
    }, [startAuthBySms]);

    const mountContent = useMemo<ReactNode>(() => {
        if (orderStatus === OrderStatus.CANCELED) {
            return (
                <>
                    <Typography color="gray80">
                        Время на оплату заказа истекло. Он был автоматически отменен, но вы можете его повторить.
                    </Typography>
                    <div className={style.actions}>
                        <Button onClick={createNew} size={BUTTON_SIZE.medium}>
                            Повторить заказ
                        </Button>
                    </div>
                </>
            );
        }

        if (
            paidStatus.status === OrderPaymentStatusEnum.StatusEnum.NOTPAID &&
            paymentMethod.code !== PAYMENT_METHODS['on-reciept']
        ) {
            return (
                <>
                    <Typography color="gray80">
                        Необходимо оплатить заказ в течение 3 часов после оформления. Иначе заказ аннулируется. Вы
                        можете выбрать другой способ оплаты.
                    </Typography>
                    <div className={style.actions}>
                        <Button onClick={payOrder} size={BUTTON_SIZE.medium}>
                            Оплатить заказ
                        </Button>
                        {/* <Button onClick={openPayments} size={BUTTON_SIZE.medium} theme={BUTTON_THEMES.secondary}>*/}
                        {/*    Изменить способ оплаты*/}
                        {/* </Button>*/}
                    </div>
                </>
            );
        }

        if (!userIsLoggedIn) {
            return (
                <>
                    <Typography variant="p-regular">
                        Войдите в аккаунт или зарегистрируйтесь, чтобы получить доступ к статусам заказов
                    </Typography>

                    <Button className={style.button} onClick={handleLoginClick} size={BUTTON_SIZE.medium}>
                        Войти или зарегистрироваться
                    </Button>
                </>
            );
        }

        return (
            <>
                <Typography variant="p-regular">
                    Всю информацию о заказе и изменения статуса можно отслеживать в Личном кабинете на вкладке «Мои
                    заказы»
                </Typography>
                <Link href={PROFILE_ORDERS_ROUTE}>
                    <Button className={style.button} size={BUTTON_SIZE.medium}>
                        Перейти в личный кабинет
                    </Button>
                </Link>
            </>
        );
    }, [createNew, handleLoginClick, orderStatus, paidStatus.status, payOrder, paymentMethod.code, userIsLoggedIn]);

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            {mountContent}
        </div>
    );
};
