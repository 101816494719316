import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {Category, CollectionDetail} from 'models';
import {TCollectionXResponse} from 'modules/collection-x/models';
import {COLLECTION_PAGE_X_ROUTE, COLLECTION_X_ROUTE} from 'modules/collection-x/routes';

export interface IGetCollectionXOptions {
    sectionId?: Category['id'];
}

export interface ICollectionXQueryData {
    collectionCode?: string;
    collectionId?: CollectionDetail['id'];
}

export class CollectionXService extends EvolutionHttpClient {
    public async getCollectionData(
        collectionCode: CollectionDetail['code'],
        query: URLSearchParams,
        options: IGetCollectionXOptions
    ): Promise<TCollectionXResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, options);

        const {data} = await this.get<TCollectionXResponse>({
            url: `/collection/by-code/${collectionCode}?${query}`,
        });

        return data;
    }

    public static isCollectionCardUrl(url: string): boolean {
        const regex = new RegExp(COLLECTION_X_ROUTE, 'u');

        return regex.test(url);
    }

    public static makeCollectionUrl(code: string, id: CollectionDetail['id']): string {
        return `${code}-collection-${id}`;
    }

    public static parseCollectionXUrl(url: string): ICollectionXQueryData {
        const regex = new RegExp(COLLECTION_PAGE_X_ROUTE, 'u');

        const matchedGroups: ICollectionXQueryData | undefined = url.match(regex)?.groups;

        if (!matchedGroups) {
            return {};
        }

        return {
            collectionCode: matchedGroups.collectionCode,
        };
    }
}
