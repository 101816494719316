import {Slice} from '@reduxjs/toolkit';
import {TAppRootState, TSelect} from 'core/redux/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {makeListingLoadingId} from 'plugins/modules/listing/helpers';
import {IListingState} from 'plugins/modules/listing/types';

export function createListingSelectors<T extends IListingState>(select: TSelect<T>, slice: Slice) {
    function selectList<L>(state: TAppRootState): IListingState<L>['list'] {
        return select(state).list as L[];
    }

    function selectListType(state: TAppRootState): IListingState['listType'] {
        return select(state).listType;
    }

    function selectIsListingPartiallyLoading(state: TAppRootState): boolean | undefined {
        return selectIsLoading(makeListingLoadingId(slice.actions.actionSetList.type, 'accumulative'))(state);
    }

    function selectIsListingLoading(state: TAppRootState): boolean | undefined {
        return selectIsLoading(makeListingLoadingId(slice.actions.actionSetList.type, 'auto'))(state);
    }

    return {
        selectIsListingLoading,
        selectIsListingPartiallyLoading,
        selectList,
        selectListType,
    };
}
