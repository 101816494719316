import classNames from 'classnames';
import {Button, BUTTON_SIZE, BUTTON_THEMES} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import Link from 'next/link';
import checkCircleIcon from 'public/icons/check-circle.svg';
import closeIcon from 'public/icons/close.svg';
import React, {FC, ReactNode} from 'react';

import style from './style.module.scss';

export const TOAST_THEMES = {
    error: 'error',
    info: 'info',
    warning: 'warning',
} as const;

export type TTheme = keyof typeof TOAST_THEMES;

export interface IProps extends TPropsWithClassName {
    text: string;
    path?: string;
    closeToast?: () => void;
    buttonText?: string;
    isNeedCloseButton?: boolean;
    hasIcon?: boolean;
    theme?: TTheme;
    icon?: ReactNode;
}

export const Toast: FC<IProps> = ({
    className,
    path,
    text,
    closeToast,
    buttonText,
    isNeedCloseButton = false,
    hasIcon = true,
    theme = TOAST_THEMES.info,
}) => {
    const handleClick = () => {
        closeToast?.();
    };

    return (
        <div
            className={classNames(style.toast, className, {
                [style[theme]]: theme,
            })}
        >
            <div className={style.toastContent}>
                {hasIcon && <SvgIcon className={style.icon} svg={checkCircleIcon} />}
                <Typography element="p" variant="p">
                    {text}
                </Typography>
                {isNeedCloseButton && (
                    <button className={style.closeButton} onClick={handleClick}>
                        <SvgIcon svg={closeIcon} />
                    </button>
                )}
            </div>
            {path && buttonText && (
                <Link href={path}>
                    <a>
                        <Button
                            isWithoutPaddings
                            onClick={handleClick}
                            size={BUTTON_SIZE.medium}
                            theme={BUTTON_THEMES.secondary}
                        >
                            {buttonText}
                        </Button>
                    </a>
                </Link>
            )}
        </div>
    );
};
