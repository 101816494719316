import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {ProductProperty, PropertyStringValue} from 'new-models';
import arrowIcon from 'public/icons/arrow-24.svg';
import React, {FC} from 'react';
import {Link} from 'react-scroll';

import style from './styles.module.scss';

interface IProps {
    data: ProductProperty[];
}

const MAX_PRODUCT_PROP = 5;

export const ProductCardTechnicalData: FC<IProps> = ({data}) => {
    return (
        <div className={style.keyCharacteristics}>
            <div>
                <Typography className={style.title} color="gray100" data-skeleton-item element="h5" variant="h5">
                    Ключевые характеристики
                </Typography>

                <ul className={style.list}>
                    {data.slice(0, MAX_PRODUCT_PROP).map(({title, value, id}) => (
                        <li className={style.item} data-skeleton-item key={id}>
                            <Typography color="gray100" element="p" variant="p-medium">
                                {title}
                            </Typography>

                            <div className={style.stretcher}>
                                <div />
                            </div>

                            <Typography
                                className={style.itemValue}
                                color="gray100"
                                element="p"
                                variant="p-medium-strong"
                            >
                                {value as PropertyStringValue}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </div>

            <Link duration={500} smooth={true} spy={true} to="description">
                <Typography className={style.anchor} data-skeleton-item element="p" variant="p">
                    Все характеристики
                    <SvgIcon svg={arrowIcon} />
                </Typography>
            </Link>
        </div>
    );
};
