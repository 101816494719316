import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {getListFromNormalizedData, selectData, selectIdList} from 'core/helpers/selectors';
import {TAppRootState} from 'core/redux/types';
import {actionSetAddressList, addressesModuleName} from 'modules/addresses/slice';
import {TAddressesState} from 'modules/addresses/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {DeliveryAddress} from 'new-models';
const select = (state: TAppRootState): TAddressesState => state[addressesModuleName];

export const selectAddressesData = selectData<TAddressesState['data']>(select);

export const selectAddressesIdList = selectIdList<TAddressesState['list']>(select);

export const selectAddressList = createDraftSafeSelector([selectAddressesData, selectAddressesIdList], (data, list) => {
    const addressList = getListFromNormalizedData(data, list);

    return addressList.reduce(
        (acc, item) => (item.isDefault ? [item, ...acc] : [...acc, item]),
        [] as DeliveryAddress[]
    );
});
export const selectAddressesIsLoading = (state: TAppRootState) => selectIsLoading(actionSetAddressList.type)(state);

export const selectEditAddressId = (state: TAppRootState) => select(state).currentEditAddressId;

export const selectDeleteAddressId = (state: TAppRootState) => select(state).currentDeleteAddressId;

export const selectCurrentEditAddress = createDraftSafeSelector(
    [selectAddressesData, selectEditAddressId],
    (data, id) => (id && id in data ? data[id] : null)
);

export const selectCurrentDeleteAddress = createDraftSafeSelector(
    [selectAddressesData, selectDeleteAddressId],
    (data, id) => (id && id in data ? data[id] : null)
);
