/* eslint-disable max-lines */

import {
    BONUS_DENOMINATOR,
    FORCE_RELOAD_GET_PARAM,
    MOBILE_MAX_WIDTH,
    PRICE_DENOMINATOR,
    RUNTIME_CSS_VARS,
    SECONDS_PER_HOUR,
    SECONDS_PER_MINUTE,
    TODAY,
    TOKEN_KEY,
    TWebVitalsMetric,
    WEB_VITALS_BENCHMARKS,
} from 'core/constants';
import {checkIsLetter, searchLetterIdx, searchNotLetterIdx} from 'core/regex';
import {CookieService} from 'core/services/CookieService';
import {TServerError} from 'core/types';
import {IJwtPayload} from 'core/types';
import {FormikHelpers} from 'formik';
import {FormikValues} from 'formik/dist/types';
import jwtDecode from 'jwt-decode';
import {Bonus, LoyaltyCard, LoyaltyCardBonus} from 'models';
import {Dispatch, SetStateAction} from 'react';
import {EXCEPTION_PATHS} from 'routing/constants';

/**
 * Получение ссылки на функцию fetch (должен быть связан контекстом с window, если это браузер)
 * @returns
 */
export const getFetch = (): typeof fetch => {
    if (isBrowserContext()) {
        return fetch.bind(window);
    }

    return fetch;
};

export const isUserAuthenticated = (): boolean => {
    const cookieService = CookieService.getInstance();

    const token = cookieService.getItem(TOKEN_KEY);
    if (!token) {
        return false;
    }

    const decodeJwtPayload = jwtDecode<IJwtPayload>(token);

    return Boolean(decodeJwtPayload.sub);
};

export const formatPrice = (value: string, options?: Intl.NumberFormatOptions) => {
    const defaultOptions: Intl.NumberFormatOptions = {
        currency: 'RUB',
        maximumFractionDigits: 0,
        ...options,
    };

    return Number(value).toLocaleString('ru-RU', defaultOptions).replace(',', '.');
};

export const formatPriceToDefault = (value: string, options?: Intl.NumberFormatOptions) => {
    return formatPrice(value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'currency',
        ...options,
    });
};

export const formatPriceToDefaultDivideDenominator = (value: number, options?: Intl.NumberFormatOptions) => {
    return formatPriceToDefault((value / PRICE_DENOMINATOR).toString(), options);
};

export const formatLoyaltyCardPoints = (value: LoyaltyCardBonus['points']) => {
    return value.toLocaleString('ru-RU');
};

export const formatLoyaltyCardNumber = (number: LoyaltyCard['number']): string => {
    return number.replace(/\d{2}/gu, (match, offset) => {
        if (2 < offset) {
            return match;
        }
        return `${match} `;
    });
};

export const formatBonus = (bonus?: Bonus) => {
    if (!bonus) {
        return 0;
    }
    return Math.ceil(bonus / BONUS_DENOMINATOR);
};

export const plural = (value: number, words: string[]) => {
    if (0 === value) {
        return words[2];
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const absValue = Math.abs(value) % 100;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const num = absValue % 10;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (10 < absValue && 20 > absValue) {
        return words[2];
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (1 < num && 5 > num) {
        return words[1];
    }
    if (1 === num) {
        return words[0];
    }
    return words[2];
};

export const isMobileByScreen = () =>
    isBrowserContext() && window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`).matches;

export const getParentPath = (path: string) => {
    const backPath = Boolean(path) && -1 !== path.indexOf('/') ? path.slice(0, path.lastIndexOf('/')) : '/';
    return EXCEPTION_PATHS.includes(backPath) ? '/' : backPath;
};

export const formatBytes = (bytes: number, decimals = 2) => {
    if (!Number(bytes)) {
        return '0 Bytes';
    }

    const k = 1024;
    const dm = 0 > decimals ? 0 : decimals;
    const sizes = ['байт', 'кб', 'мб', 'гб', 'тб', 'пб', 'еб', 'зб', 'йб'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isBrowserContext = (): boolean => 'undefined' !== typeof window;

export const disableScroll = (withoutScrollToTop = false) => {
    if (!isBrowserContext()) {
        return;
    }

    const body = document.body;

    if (!body) {
        return;
    }

    if (withoutScrollToTop) {
        lockScrollFixed();
        return;
    }

    body.classList.add('scroll-disabled');
};

export const enableScroll = () => {
    if (!isBrowserContext()) {
        return;
    }

    const body = document.body;

    if (!body) {
        return;
    }

    body.classList.remove('scroll-disabled', 'scroll-disabled-fixed');
    unlockScrollFixed();
};

export const disableScrollOnHtmlNode = () => {
    if (!isBrowserContext()) {
        return;
    }

    const html = document.querySelector('html');

    if (!html) {
        return;
    }

    html.classList.add('scroll-disabled');
};

const lockScrollFixed = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.classList.add('scroll-disabled-fixed');
};

const unlockScrollFixed = () => {
    const scrollPosition = -parseInt(document.body.style.top, 10);

    document.body.style.top = '';
    if (!scrollPosition) {
        return;
    }
    window.scrollTo(0, scrollPosition);
};

export const enableScrollOnHtmlNode = () => {
    if (!isBrowserContext()) {
        return;
    }

    const html = document.querySelector('html');

    if (!html) {
        return;
    }

    html.classList.remove('scroll-disabled');
};

export const isFractionalNumber = (number: number): boolean => 0 !== number % 1;

export const webVitalsCb = (metric: TWebVitalsMetric) => {
    if ('production' === process.env.NODE_ENV) {
        return;
    }

    const benchmark = WEB_VITALS_BENCHMARKS[metric.name];

    // eslint-disable-next-line no-console
    console.groupCollapsed(`WEB VITALS %c ${metric.name}: ${metric.value}`, 'color: lightgreen');
    console.info(metric);
    // eslint-disable-next-line no-console
    console.groupEnd();
    if (benchmark && (metric.value > benchmark.max || metric.value < benchmark.min)) {
        console.info(
            `%c WARNING ${metric.name}: ${metric.value}, normal: ${benchmark.min} - ${benchmark.max}`,
            'background: red; color: white'
        );
    }
};

export const stripHtml = (html: string): string => {
    return html.replace(/(<([^>]+)>)/giu, '');
};

type TScrollListContainer = HTMLUListElement | null;

type TCalcOpenCollapseProps = {
    listContainer: TScrollListContainer;
    sliceEnd: number;
};

interface IToggleScrollListStylesProps {
    container: TScrollListContainer;
    height?: number;
    isAdd?: boolean;
}

const toggleScrollListStyles = ({container, height, isAdd}: IToggleScrollListStylesProps) => {
    if (!container) {
        return;
    }

    container.style.maxHeight = isAdd ? `${height}px` : '';
    container.style.overflowY = isAdd ? 'auto' : '';
    container.style.paddingRight = isAdd ? '12px' : '';
};

// Расчет минимальной высоты, если задано требование отображать фиксированное кол-во элементов с учетом кол-ва строк у элементов списка и добавление скролла
export const makeScrollListStyles = ({listContainer, sliceEnd}: TCalcOpenCollapseProps) => {
    if (listContainer) {
        // Создание единого списка элементов. Длина списка зависит от требования к кол-ву отображаемых элементов при раскрытом списке
        const listItems = Array.from(listContainer.querySelectorAll('li'));
        const visibleFilterItemsArr = listItems.slice(0, sliceEnd);

        if (visibleFilterItemsArr.length && listContainer && listContainer) {
            // Подсчет высоты всех отображаемых элементов, высота = высота элемента + верхний отступ
            const totalHeight = visibleFilterItemsArr.reduce((acc, item) => {
                const itemStyle = window.getComputedStyle(item);
                return acc + (item.getBoundingClientRect().height + parseInt(itemStyle.marginTop, 10));
            }, 0);

            // Добавление стилей для скролла и минимальной высоты, для того, чтобы создать скролящийся список
            toggleScrollListStyles({
                container: listContainer,
                height: totalHeight,
                isAdd: true,
            });
        }
    }
};

export const removeScrollListStyles = (container: TScrollListContainer) => {
    toggleScrollListStyles({
        container,
    });
};

export const formatDeliveryDate = (dateString: string) => {
    const date = new Date(dateString).toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
    });

    const today = new Date().toLocaleDateString('ru-RU', {
        day: 'numeric',
        month: 'long',
    });

    return today === date ? TODAY : date;
};

export const formatEmail = (email = ''): string => {
    const splitEmail = email.split('');

    return splitEmail
        ?.filter((letter) => ' ' !== letter && '+' !== letter && ' ' !== letter && '(' !== letter && ')' !== letter)
        .join('');
};

export const setFormikErrorsToOneField = (
    field: string,
    errors: TServerError[] | TServerError,
    setErrors: FormikHelpers<FormikValues>['setErrors'] | Dispatch<SetStateAction<Record<string, string> | undefined>>
) => {
    if (Array.isArray(errors)) {
        setErrors({[field]: errors.map((err) => err.message).join(' ')});
        return;
    }

    setErrors({[field]: errors.message});
};

// setCssVar<false> -- любое имя переменной
interface ISetCssVar<Strict extends boolean> {
    cssVarName: Strict extends true ? (typeof RUNTIME_CSS_VARS)[keyof typeof RUNTIME_CSS_VARS] : string;
    unit: string;
    value: string | number;
}

export const setCssVar = <Strict extends boolean = true>({cssVarName, unit, value}: ISetCssVar<Strict>): void => {
    if (!isBrowserContext()) {
        return;
    }

    requestAnimationFrame(() => {
        document.documentElement.style.setProperty(cssVarName, `${value}${unit}`);
    });
};

export const keyBy = <T extends Record<string, any>, E extends boolean = false>(
    objectList: T[],
    key: Extract<keyof T, string>,
    extractionCb?: (object: T) => T[Extract<keyof T, string>]
): Record<string, E extends false ? T : T[Extract<keyof T, string>]> => {
    return objectList.reduce((acc, currentValue) => {
        return {
            ...acc,
            [currentValue[key]]: extractionCb?.(currentValue) || currentValue,
        };
    }, {});
};

export const getArrayLikeErrorFieldData = <E extends Record<string, any>>(
    errors: Record<string, string | undefined>,
    field: keyof E
): string | undefined => {
    const firstKey = Object.keys(errors).find((key) => new RegExp(`${String(field)}((\\[\\d+\\])|$)`, 'ug').test(key));

    return errors[firstKey || ''];
};

/**
 * https://stackoverflow.com/questions/62368109/update-router-query-without-firing-page-change-event-in-next-js
 * Ну просто роутер некстовый реагирует на изменение даже гет-параметров
 * @param {string} nextUrl
 */
export const isUrlActuallyChange = (nextUrl: string): boolean => {
    const fullUrl = `${window.location.protocol}//${window.location.host}${nextUrl}`;
    const {searchParams} = new URL(fullUrl);

    const newPathName = getUrlPathname(nextUrl);

    return window.location.pathname !== newPathName || Boolean(searchParams.get(FORCE_RELOAD_GET_PARAM));
};

export const getUrlPathname = (url: string): string => {
    const [pathname] = url.split('?');

    return pathname;
};

export const parseTimer = (seconds = 0) => {
    const hours = Math.floor(seconds / SECONDS_PER_HOUR);
    const minutes = Math.floor((seconds - hours * SECONDS_PER_HOUR) / SECONDS_PER_MINUTE);
    const secondsLeft = seconds - minutes * SECONDS_PER_MINUTE - hours * SECONDS_PER_HOUR;

    let finalMinutes = '';
    let finalSeconds = '';

    if (0 === minutes) {
        finalMinutes = '00';
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (10 > minutes) {
        finalMinutes = `0${minutes}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (10 <= minutes) {
        finalMinutes = `${minutes}`;
    }

    if (0 === secondsLeft) {
        finalSeconds = '00';
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (10 > secondsLeft) {
        finalSeconds = `0${secondsLeft}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (10 <= secondsLeft) {
        finalSeconds = `${secondsLeft}`;
    }

    return hours ? `${hours}:${finalMinutes}:${finalSeconds}` : `${finalMinutes}:${finalSeconds}`;
};

export const findWordEnd = ({text, searchIndex}: {text: string; searchIndex: number}) => {
    const findSymbol = text[searchIndex];
    const textPart = text.slice(searchIndex);

    if (checkIsLetter(findSymbol)) {
        return searchNotLetterIdx(textPart) + searchIndex;
    }

    const firstLetterIdx = searchLetterIdx(textPart);

    return searchNotLetterIdx(textPart.slice(firstLetterIdx)) + firstLetterIdx + searchIndex;
};

export const observeDOM = () => {
    if (!isBrowserContext()) {
        return;
    }

    const ATTRIBUTE_FILTER = ['class', 'value', 'disabled', 'hidden', 'checked'];

    const observer = new MutationObserver((mutationsList) => {
        const groupedAttributeMutationRecords: Record<string, MutationRecord[]> = ATTRIBUTE_FILTER.reduce(
            (groupedMutationsList, attributeName) => {
                const groupedByAttributeMutations = mutationsList.filter(
                    (mutation) => mutation.attributeName === attributeName
                );

                if (!groupedByAttributeMutations.length) {
                    return groupedMutationsList;
                }

                return {
                    ...groupedMutationsList,
                    [attributeName]: groupedByAttributeMutations,
                };
            },
            {}
        );

        const totalAttributeDomMutations = Object.keys(groupedAttributeMutationRecords).length;
        if (totalAttributeDomMutations) {
            // eslint-disable-next-line no-console
            console.groupCollapsed(
                `%c DOM ATTRIBUTE MUTATIONS: %c[%c${totalAttributeDomMutations}%c]`,
                'color: #139490',
                'color: green',
                'color: white',
                'color: green'
            );
            // eslint-disable-next-line no-console
            Object.keys(groupedAttributeMutationRecords).forEach((attributeName) => {
                // eslint-disable-next-line no-console
                console.groupCollapsed(
                    `%c ${attributeName}: %c[%c${groupedAttributeMutationRecords[attributeName].length}%c]`,
                    'color: white; font-style: italic',
                    'color: green',
                    'color: white',
                    'color: green'
                );
                // eslint-disable-next-line no-console
                console.log(groupedAttributeMutationRecords[attributeName]);
                // eslint-disable-next-line no-console
                console.groupEnd();
            });
            // eslint-disable-next-line no-console
            console.groupEnd();
        }

        const groupedChildListMutationList = mutationsList.filter((mutation) => 'childList' === mutation.type);
        const totalChildListMutationList = groupedChildListMutationList.length;

        if (totalChildListMutationList) {
            // eslint-disable-next-line no-console
            console.groupCollapsed(
                `%c DOM NODE MUTATIONS: %c[%c${totalChildListMutationList}%c]`,
                'color: #135091',
                'color: green',
                'color: white',
                'color: green'
            );
            // eslint-disable-next-line no-console
            console.log(groupedChildListMutationList);
            // eslint-disable-next-line no-console
            console.groupEnd();
        }
    });

    observer.observe(document.body, {
        attributeFilter: ATTRIBUTE_FILTER,
        attributeOldValue: true,
        attributes: true,
        childList: true,
        subtree: true,
    });
};

export const addToComaList = (input: string | null, ...items: any[]): string => {
    if (!input) {
        return items.join(',');
    }

    const inputItems = [...input.split(','), ...items];

    return inputItems.join(',');
};

export const deleteFromComaList = (input: string | null, item: string): string => {
    if (!input) {
        return '';
    }

    return input
        .split(',')
        .filter((id) => id !== item)
        .join(',');
};

export const isPage = (pageRoute: string = '', url: string): boolean => {
    const regex = new RegExp(`^${pageRoute}`, 'ug');

    return regex.test(url);
};

export const copyText = (text: string) => () => navigator.clipboard.writeText(text);

export const getHashFromLocation = () =>
    'undefined' !== typeof window ? decodeURIComponent(window.location.hash) : undefined;
