import {NoImage} from 'components/NoImage';
import {getResizedImageUrl, TImageResizeCode} from 'core/helpers/imageResize';
import {useOnErrorImg} from 'core/hooks/useOnErrorImg';
import {TPropsWithClassName} from 'core/types';
import {MediaIcon, MediaImage} from 'new-models';
import React, {memo, useMemo} from 'react';

interface IProps extends TPropsWithClassName {
    media?: MediaImage | MediaIcon;
    imageResizeCode?: TImageResizeCode;
    title?: string;
}

export const SimpleImage = memo<IProps>(function SimpleImage({media, className, imageResizeCode, title}) {
    const {isError, onError} = useOnErrorImg();

    const optimizedImage = useMemo(() => {
        if (!media) {
            return {} as MediaImage | MediaIcon;
        }
        return getResizedImageUrl(media, imageResizeCode);
    }, [imageResizeCode, media]);

    const altTitle = (optimizedImage as MediaImage).alternative ?? (optimizedImage.title || title);

    if (isError) {
        return <NoImage />;
    }

    return (
        <img
            alt={altTitle}
            className={className}
            loading="lazy"
            onError={onError}
            src={optimizedImage.url}
            title={altTitle}
        />
    );
});
