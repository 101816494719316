import classNames from 'classnames';
import {BACK_BUTTON_URL_TYPE} from 'components/BackButton';
import {CollapseAnimatedOnTop} from 'components/CollapseAnimatedOnTop';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {OnlyMobile} from 'components/OnlyMobile';
import {PageHeader} from 'components/PageHeader';
import {CategoryPageHeader} from 'modules/categories/components/CategoryPageHeader';
import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {CollectionSwitcher} from 'modules/collections/components/CollectionSwitcher';
import {ProductListPageFunctionalityContext} from 'modules/products/context';
import {useHeaderBannerOfListing} from 'modules/products/hooks/useHeaderBannerOfListing';
import {useListingTitle} from 'modules/products/hooks/useListingTitle';
import {useIsSearchContext} from 'modules/search-page/hooks/useIsSearchContext';
import {CategoryType} from 'new-models';
import {ComboFiltersTagList} from 'plugins/modules/filter/components/ComboFiltersTagList';
import {FilterContext} from 'plugins/modules/filter/context';
import React, {FC, PropsWithChildren, ReactNode, useContext} from 'react';

const columnStyle = getGridColumnStyles();

// import {useScroll} from 'core/hooks/useScroll';
import {CategoryChildSlider} from 'modules/categories/components/CategoryChildSlider';

import style from './style.module.scss';

interface IProps extends PropsWithChildren {
    category?: ICategoryNode;
    Component?: ReactNode;
    withBanners?: boolean;
}

// eslint-disable-next-line complexity
export const ProductListMobileHeader: FC<IProps> = ({
    category,
    children,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Component,
    withBanners = true,
}) => {
    const {comboFilterList} = useContext(FilterContext);
    const functionality = useContext(ProductListPageFunctionalityContext);

    // const isNotTop = useScroll(1, 0);
    const isNotTop = true;
    const isSearchContext = useIsSearchContext();

    const title = useListingTitle(category);

    const renderBanner = useHeaderBannerOfListing({
        category,
        className: columnStyle,
        withBanners,
    });

    return (
        <PageHeader
            behaviour={{
                onScrollDown: {
                    isBottomHidden: false,
                    isTopHidden: false,
                },
                onScrollUp: {
                    isBottomHidden: false,
                    isTopHidden: false,
                },
            }}
            className={classNames(style.root, isNotTop ? style.isNotTop : undefined)}
            top={
                <>
                    {functionality.title && (
                        <OnlyMobile>
                            <Grid noRowGap>
                                {title && (
                                    <CategoryPageHeader
                                        className={classNames(style.top, columnStyle)}
                                        hasBackButton={!isSearchContext}
                                        title={title}
                                        urlType={BACK_BUTTON_URL_TYPE.new}
                                    />
                                )}
                            </Grid>
                        </OnlyMobile>
                    )}
                </>
            }
        >
            <CollapseAnimatedOnTop isCollapsed={false} noOverflowHiddenIfShow>
                <OnlyMobile>
                    <Grid className={style.container} noRowGap>
                        {Component && <div className={columnStyle}>{Component}</div>}
                        {category?.type === CategoryType.Collection && functionality.collectionSwitcher && (
                            <CollectionSwitcher className={columnStyle} />
                        )}
                        {renderBanner}
                        {Boolean(comboFilterList.length) && (
                            <div className={classNames(style.containerMobileFullWidth, columnStyle)}>
                                <ComboFiltersTagList className={style.comboFilters} />
                            </div>
                        )}
                        {category && Boolean(category?.childrenCategory.length) && (
                            <CategoryChildSlider categoryNode={category} className={columnStyle} />
                        )}
                    </Grid>
                </OnlyMobile>
            </CollapseAnimatedOnTop>
            {children}
        </PageHeader>
    );
};
