import {checkIsPhone, formatPhone} from 'core/regex';
import {TShape} from 'core/types';
import {ERRORS} from 'modules/authentication/constants';
import {ICheckoutFormValidationMessages} from 'modules/checkout/types';
import {
    DeliveryType,
    OrderAddress,
    OrderAddressId,
    OrderCourierDeliveryData,
    OrderCreateRequest,
    OrderDeliveryDataBase,
    OrderPickupDeliveryData,
} from 'new-models';
import * as yup from 'yup';

export const getCheckoutFormAddressFieldsValidator = (messages: ICheckoutFormValidationMessages) =>
    yup.object<TShape<Omit<OrderAddress, 'zip'>>>({
        address: yup.string().required(messages.required),
        apartment: yup.string().notRequired(),
        doorcode: yup.string().notRequired(),
        entrance: yup.number().notRequired(),
        floor: yup.string().notRequired(),
        isPrivateHouse: yup.boolean().notRequired(),
        latitude: yup.string().notRequired(),
        longitude: yup.string().notRequired(),
    });

const getCheckoutFormDeliveryCourierValidator = (messages: ICheckoutFormValidationMessages, addressCount?: number) =>
    yup.object<TShape<OrderCourierDeliveryData | OrderDeliveryDataBase>>({
        address: yup.mixed().when([], () => {
            if (!addressCount) {
                return getCheckoutFormAddressFieldsValidator(messages);
            }

            return yup.object<TShape<OrderAddressId>>({
                id: yup.string().required(messages.courierAddressRequired),
            });
        }),
        deliveryId: yup.string().required(messages.deliveryRequired),
    });

const getCheckoutFormDeliveryPickupValidator = (
    messages: ICheckoutFormValidationMessages
): yup.SchemaOf<OrderPickupDeliveryData> =>
    yup.object({
        deliveryId: yup.string().required(messages.deliveryRequired),
        pickupPointId: yup.string().required(messages.pickupPointRequired),
    });

export const getCheckoutFormValidator = (messages: ICheckoutFormValidationMessages) =>
    yup.object<TShape<OrderCreateRequest>>({
        comment: yup.string().notRequired(),
        customer: yup.object({
            email: yup.string().email('Введён некорректный email адрес').required(messages.required),
            firstname: yup.string().required(messages.required),
            lastname: yup.string().required(messages.required),
            phone: yup
                .string()
                .required(messages.required)
                .test('test-phone', ERRORS.incorrectPhone, (value = '') => {
                    const isValidPhone = checkIsPhone(value);
                    const isPhone = Boolean(Number(formatPhone(value)));

                    return isPhone ? isValidPhone : true;
                }),
        }),
        // @ts-expect-error
        deliveryData: yup
            .mixed<OrderCreateRequest['deliveryData']>()
            .when(
                ['tempFields.deliveryType', 'tempFields.userAddressCount'],
                (deliveryType: DeliveryType, userAddressCount = 0) => {
                    switch (deliveryType) {
                        case DeliveryType.PICKUP: {
                            return getCheckoutFormDeliveryPickupValidator(messages);
                        }
                        default:
                            return getCheckoutFormDeliveryCourierValidator(messages, userAddressCount);
                    }
                }
            ),
        paymentId: yup.string().required(messages.paymentRequired),
    });
