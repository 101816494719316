import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {TServiceTopResponse} from 'modules/services/models/ServicesResponse';
import {IServiceState} from 'modules/services/types';
import {HYDRATE} from 'next-redux-wrapper';

export const serviceModuleName = 'services';

const initialState: IServiceState = {
    popularServices: null,
};

export const servicesSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[serviceModuleName];
        },
    },
    initialState,
    name: serviceModuleName,
    reducers: {
        actionSetPopularServices(state: IServiceState, {payload}: PayloadAction<TServiceTopResponse['data']>) {
            state.popularServices = {
                count: payload.page.count,
                list: payload.services,
            };
        },
    },
});

export const {
    actions: {actionSetPopularServices},
} = servicesSlice;

export const {reducer: servicesReducer} = servicesSlice;
