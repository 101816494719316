import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TResponse} from 'core/types';
import {FranchiseCreateDataRequest} from 'new-models';

export class FranchiseService extends EvolutionHttpClient {
    public async handleFranchise(req: FranchiseCreateDataRequest): Promise<TResponse<undefined>> {
        const {data} = await this.post<FranchiseCreateDataRequest, TResponse<undefined>>({
            body: req,
            url: '/franchise/form',
        });

        return data;
    }
}
