import {getGridColumnStyles, Grid} from 'components/Grid';
import {InputCaption} from 'components/InputCaption';
import {MediaHarvester} from 'components/MediaHarvester';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {CheckoutSection} from 'modules/checkout/components/CheckoutSection';
import {PAYMENT_ID_FIELD_NAME} from 'modules/checkout/constants/fields';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {PaymentMethodShort} from 'new-models';
import cardPaymentIcon from 'public/icons/card-payment-icon.svg';
import React, {memo, useCallback} from 'react';

import {CheckoutPaymentMethod} from '../CheckoutPaymentMethod';
import style from './style.module.scss';

const columnStyle = getGridColumnStyles({
    md: 4,
});

interface IProps extends TPropsWithClassName {
    paymentMethods: PaymentMethodShort[];
}

export const CheckoutPaymentType = memo<IProps>(function CheckoutPaymentType({className, paymentMethods}) {
    const {getFieldMeta, submitCount} = useCheckoutFormikContext();
    const fieldMeta = getFieldMeta(PAYMENT_ID_FIELD_NAME);

    const isOnceSubmitted = Boolean(submitCount);

    const renderIcon = useCallback(
        (icon?: PaymentMethodShort['icon']): FC =>
            ({className: iconClassName}) =>
                (icon && <MediaHarvester className={iconClassName} media={icon} />) || (
                    <SvgIcon className={iconClassName} svg={cardPaymentIcon} />
                ),
        []
    );

    return (
        <CheckoutSection className={className} isFluidGap title="Способ оплаты">
            <Grid as="ul" className={style.paymentList}>
                {paymentMethods?.map(({id, title, available, icon}) => (
                    <li className={columnStyle} key={id}>
                        <Field name={PAYMENT_ID_FIELD_NAME} type="radio" value={id}>
                            {({field, meta}: FieldProps) => (
                                <CheckoutPaymentMethod
                                    {...field}
                                    IconComponent={renderIcon(icon)}
                                    disabled={!available}
                                    error={meta.touched || isOnceSubmitted ? Boolean(meta.error) : undefined}
                                    label={title}
                                />
                            )}
                        </Field>
                    </li>
                ))}
            </Grid>
            {(isOnceSubmitted || fieldMeta.touched) && fieldMeta.error && <InputCaption errorText={fieldMeta.error} />}
        </CheckoutSection>
    );
});
