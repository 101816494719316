import {ModifiedControlRequestInterceptor} from 'core/interceptors/ModifiedControlRequestInterceptor';
import {getRuntimeConfig} from 'core/next/helpers';
import {useEffect} from 'react';

export const useServiceWorker = () => {
    useEffect(() => {
        const host = getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST + '/' + getRuntimeConfig().NEXT_PUBLIC_API_V;

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {
                    console.info('scope is: ', registration.scope);
                })
                .catch((error) => console.error(error));

            navigator.serviceWorker.ready.then((registration) => {
                registration.active?.postMessage({host});
            });

            navigator.serviceWorker.addEventListener('message', (event) => {
                const cacheEndPoints = event.data.cacheEndPoints;
                const modifiedControlRequestInterceptor = ModifiedControlRequestInterceptor.getInstance();

                modifiedControlRequestInterceptor.cacheEndPoints = cacheEndPoints;
            });
        }
    }, []);
};
