import classNames from 'classnames';
import {IconElement} from 'components/IconElement';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {selectBottomBarActiveItem} from 'modules/bottombar/selectors';
import {actionSetActive} from 'modules/bottombar/slice';
import {TActiveItem} from 'modules/bottombar/types';
import {selectCartProductsList} from 'modules/cart/selectors';
import {selectFavouritesList} from 'modules/favourites/selector';
import {MODALS} from 'modules/modals/constants';
import {hideAllModals, hideModal, showModal} from 'modules/modals/thunks';
import {selectIsPortalVisible} from 'modules/portal/selectors';
import {actionPortalHideAll} from 'modules/portal/slice';
import {useIsLoggedIn} from 'modules/user/hooks/useIsLoggedIn';
import {useRouter} from 'next/router';
import addToCartIcon from 'public/icons/add-to-cart.svg';
import catalogIcon from 'public/icons/catalog-second.svg';
import heartIcon from 'public/icons/heart.svg';
import mainIcon from 'public/icons/main.svg';
import profileIcon from 'public/icons/profileBottomNav.svg';
import React, {memo, useCallback, useRef} from 'react';
import {CART_ROUTE, CHECKOUT_ROUTE, FAVOURITES_ROUTE, HOME_ROUTE, PRODUCT_PATH} from 'routing/constants';
import {PAGES} from 'routing/constants';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    disabled?: boolean;
    isShowFavorite?: boolean;
}

export const BottomBar = memo<IProps>(function BottomBar({className, disabled = false}) {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const bottomBarRef = useRef<HTMLDivElement>(null);

    const isNeedShadow = !useIsNeedRoute([PRODUCT_PATH, CART_ROUTE, CHECKOUT_ROUTE]);

    useElementHeightWithCssVar(bottomBarRef, RUNTIME_CSS_VARS.bottomBarHeight);

    const activeItem = useAppSelector(selectBottomBarActiveItem);
    const cartProductsList = useAppSelector(selectCartProductsList);
    const favouritesList = useAppSelector(selectFavouritesList);
    const isLoggedIn = useIsLoggedIn();
    const isMenuOpen = useAppSelector(selectIsPortalVisible(MODALS.CATALOG_MENU.name));

    const handleProfileClick = useProfileClick();

    const handleCatalogClick = useCallback(() => {
        if (isMenuOpen) {
            dispatch(hideModal(MODALS.CATALOG_MENU.name));
            return;
        }

        dispatch(showModal(MODALS.CATALOG_MENU.name));
    }, [dispatch, isMenuOpen]);

    const handleProfileClickByAuth = useCallback(() => {
        if (isLoggedIn) {
            dispatch(showModal(MODALS.AUTH_MENU.name));
            return;
        }

        handleProfileClick();
    }, [dispatch, handleProfileClick, isLoggedIn]);

    const handleClick = useCallback(
        (activeButton: TActiveItem, route: string) => {
            return async () => {
                dispatch(hideAllModals);
                dispatch(actionPortalHideAll());
                dispatch(actionSetActive(activeButton));

                if (route === router.route) {
                    return;
                }
                await router.push(route);
            };
        },
        [dispatch, router]
    );

    return (
        <div
            className={classNames(style.bottomBar, className, {
                [style.disabled]: disabled,
                [style.isNeedShadow]: isNeedShadow,
            })}
            ref={bottomBarRef}
        >
            <ul className={style.bottomBarList}>
                <li
                    className={classNames(style.bottomBarItem, {
                        [style.active]: BOTTOM_BAR_BUTTONS.main === activeItem,
                    })}
                >
                    <button
                        className={style.bottomBarItemContainer}
                        onClick={handleClick(BOTTOM_BAR_BUTTONS.main, HOME_ROUTE)}
                    >
                        <div className={style.svgIconContainer}>
                            <SvgIcon svg={mainIcon} />
                        </div>
                        <Typography element="div" variant="p-small">
                            Главная
                        </Typography>
                    </button>
                </li>
                <li
                    className={classNames(style.bottomBarItem, {
                        [style.active]: BOTTOM_BAR_BUTTONS.catalog === activeItem,
                    })}
                >
                    <button className={style.bottomBarItemContainer} onClick={handleCatalogClick}>
                        <div className={style.svgIconContainer}>
                            <SvgIcon svg={catalogIcon} />
                        </div>
                        <Typography element="div" variant="p-small">
                            Каталог
                        </Typography>
                    </button>
                </li>
                <li
                    className={classNames(style.bottomBarItem, {
                        [style.active]: BOTTOM_BAR_BUTTONS.cart === activeItem,
                    })}
                >
                    <IconElement count={cartProductsList?.length}>
                        <button
                            className={style.bottomBarItemContainer}
                            onClick={handleClick(BOTTOM_BAR_BUTTONS.cart, CART_ROUTE)}
                        >
                            <SvgIcon svg={addToCartIcon} />
                            <Typography element="div" variant="p-small">
                                Корзина
                            </Typography>
                        </button>
                    </IconElement>
                </li>
                <li
                    className={classNames(style.bottomBarItem, {
                        [style.active]: BOTTOM_BAR_BUTTONS.favorite === activeItem,
                    })}
                >
                    <IconElement count={favouritesList.length}>
                        <button
                            className={style.bottomBarItemContainer}
                            onClick={handleClick(BOTTOM_BAR_BUTTONS.favorite, FAVOURITES_ROUTE)}
                        >
                            <SvgIcon svg={heartIcon} />
                            <Typography element="div" variant="p-small">
                                {PAGES.FAVOURITES_PAGE.title}
                            </Typography>
                        </button>
                    </IconElement>
                </li>
                <li
                    className={classNames(style.bottomBarItem, {
                        [style.active]: BOTTOM_BAR_BUTTONS.profile === activeItem,
                    })}
                >
                    <button className={style.bottomBarItemContainer} onClick={handleProfileClickByAuth}>
                        <div className={style.svgIconContainer}>
                            <SvgIcon svg={profileIcon} />
                        </div>
                        <Typography element="div" variant="p-small">
                            Профиль
                        </Typography>
                    </button>
                </li>
            </ul>
        </div>
    );
});
