import {TextArea} from 'components/TextArea';
import {TTextAreaField} from 'core/types';
import {ADDRESS_COMMENT_MAX_LENGTH} from 'modules/addresses/constants';
import {useAddressField} from 'modules/addresses/hooks/useAddressField';

export const AddressComment: FC<TTextAreaField> = ({className, ...inputProps}) => {
    const {handleChange} = useAddressField('comment', ADDRESS_COMMENT_MAX_LENGTH);

    return <TextArea className={className} labelText="Комментарий" {...inputProps} onChange={handleChange} />;
};
