import {CollapseAnimated} from 'components/CollapseAnimated';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {Discount, TotalPrice} from 'new-models';
import React, {useEffect, useMemo, useRef, useState} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    discountPrices?: Discount[];
    discount: TotalPrice['discount'];
}

interface IItemProps extends Discount {
    isOne?: boolean;
    isSum?: boolean;
}

const DISCOUNT_TITLE = 'Скидка';

export const DiscountItem: FC<IItemProps> = ({title, cost, isOne = false, isSum = false}) => (
    <>
        <Typography color={isSum ? 'gray100' : 'gray70'} variant={isSum ? 'p-strong' : 'p'}>
            {title}
        </Typography>
        <Typography color={isOne ? 'gray100' : 'gray70'} variant={isOne ? 'p-strong' : 'p'}>
            -
            {formatPrice((cost / PRICE_DENOMINATOR).toString(), {
                maximumFractionDigits: 2,
            })}{' '}
            <Typography variant="p">₽</Typography>
        </Typography>
    </>
);

export const TotalDiscount: FC<IProps> = ({discountPrices, discount}) => {
    const sumRef = useRef<number>(0);

    const [isHidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
        if (discount) {
            sumRef.current = discount;
            return;
        }

        if (discountPrices?.length) {
            discountPrices?.forEach(({cost}) => (sumRef.current += cost));
        }
    }, [discount, discountPrices]);
    const handleClick = () => setHidden((init) => !init);

    const mountPrice = useMemo(
        () => (
            <>
                <div className={style.item}>
                    <DiscountItem cost={discount} isOne isSum title={DISCOUNT_TITLE} />
                </div>
                {discountPrices && Boolean(discountPrices.length) && (
                    <>
                        <CollapseAnimated isHidden={isHidden}>
                            <ul className={style.list}>
                                {discountPrices?.map((discountPrice, idx) => (
                                    <li className={style.item} key={idx}>
                                        <DiscountItem {...discountPrice} />
                                    </li>
                                ))}
                            </ul>
                        </CollapseAnimated>
                        <button className={style.switcher} onClick={handleClick}>
                            {isHidden ? 'Подробнее' : 'Свернуть'}
                        </button>
                    </>
                )}
            </>
        ),
        [discount, discountPrices, isHidden]
    );

    return discount ? <div className={style.root}>{mountPrice}</div> : null;
};
