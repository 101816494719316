import {useErrorsHandler} from 'core/hooks/useErrorsHandler';
import {TServerError} from 'core/types';
import {FormikConfig, FormikErrors} from 'formik/dist/types';
import {useCallback} from 'react';

export const useFormikSubmit = <V = Record<string, string>>(callback: (values: V) => void) => {
    const errorsHandler = useErrorsHandler();

    return useCallback<FormikConfig<V>['onSubmit']>(
        async (values, {setErrors}) => {
            try {
                setErrors({});
                await callback?.(values);
            } catch (errors) {
                errorsHandler<FormikErrors<V>>({additionalCb: setErrors, errors: errors as TServerError[]});
            }
        },
        [callback, errorsHandler]
    );
};
