import {InfoBlock} from 'components/InfoBlock';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import React, {FC, useCallback} from 'react';

export const EmptyOrderList: FC = () => {
    const dispatch = useAppDispatch();

    const onOpenMenu = useCallback(() => {
        dispatch(showModal(MODALS.CATALOG_MENU.name));
    }, [dispatch]);

    return (
        <InfoBlock
            buttonText="Перейти в каталог"
            buttonTheme="primary"
            contentText="Просмотрите наш каталог или воспользуйтесь поиском, чтобы найти подходяшие товары"
            headerText="У вас пока нет заказов"
            isHideIcon
            onClick={onOpenMenu}
        />
    );
};
