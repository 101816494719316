import {FilterChoose} from 'new-models';
import {IProps as IVariantFilterProps, VariantFilter} from 'plugins/modules/filter/components/VariantFilter';
import {FilterContext} from 'plugins/modules/filter/context';
import React, {FC, useCallback, useContext} from 'react';

export const RegularVariantFilter: FC<FilterChoose> = (filter) => {
    const {code} = filter;

    const {urlService: filterService} = useContext(FilterContext);

    const handleApply = useCallback<IVariantFilterProps['onApply']>(
        async (variantCode) => {
            await filterService?.addChooseParams({id: code, value: variantCode});
        },
        [code, filterService]
    );

    const handleDiscard = useCallback<IVariantFilterProps['onDiscard']>(
        async (variantCode) => {
            await filterService?.removeParam({id: code, value: variantCode});
        },
        [code, filterService]
    );

    return <VariantFilter onApply={handleApply} onDiscard={handleDiscard} {...filter} />;
};
