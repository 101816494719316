import classNames from 'classnames';
import {NoImage} from 'components/NoImage';
import {TPropsWithClassName} from 'core/types';
import {ProductCardTechnicalData} from 'modules/product-card/components/ProductCardTechnicalData/ProductCardTechnicalData';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {GallerySlider} from 'modules/slider/components/GallerySlider';
import {TGalleryMode} from 'modules/slider/types';
import {MediaImage, MediaVideo, MediaYoutube, ProductDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends ProductDetail, TPropsWithClassName {
    sliderImages: MediaImage[];
    sliderVideos: MediaYoutube[] | MediaVideo[];
    galleryMode: TGalleryMode;
}

// TODO: Remove sliderImages and sliderVideos and use only media (MediaGroupVisual, MediaGroupImage)
export const GalleryBlock = memo<IProps>(function GalleryBlock({
    className,
    sliderImages,
    sliderVideos,
    galleryMode,
    availability,
    ...product
}) {
    const {nameplates, properties, type} = product;
    return (
        <div
            className={classNames(style.galleryBlock, className, {
                [style[type]]: type,
            })}
        >
            <div className={style.sliderContainer} data-skeleton-item>
                {sliderImages?.length || sliderVideos?.length ? (
                    <GallerySlider
                        availability={availability}
                        className={style.gallerySlider}
                        images={sliderImages}
                        mode={galleryMode}
                        nameplates={nameplates}
                        videos={sliderVideos}
                    />
                ) : (
                    <NoImage className={style.noImage} type={type} />
                )}
                {/* TODO: For Another type product */}
                {/* {Boolean(sliderImages.length) && (
                    <VisualCardGallery className={style.visualCardGallery} images={sliderImages} />
                )}*/}
            </div>

            {properties && type === PRODUCT_TYPE.technical && <ProductCardTechnicalData data={properties} />}
        </div>
    );
});
