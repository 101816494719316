import {MODALS} from 'modules/modals/constants';
import {useModalApi} from 'modules/modals/hooks/useModalApi';
import {useMemo} from 'react';

interface IUseProfileRemoveAccountModalApi {
    close: () => void;
    open: () => void;
}
export const useProfileRemoveAccountModalApi = (): IUseProfileRemoveAccountModalApi => {
    const {open, close} = useModalApi();

    return useMemo(
        () => ({
            close: () => {
                close(MODALS.PROFILE_REMOVE_ACCOUNT.name);
            },
            open: () => {
                open(MODALS.PROFILE_REMOVE_ACCOUNT.name);
            },
        }),
        [close, open]
    );
};
