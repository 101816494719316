import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {HomePageContext} from 'modules/home-page/context';
import {ServicesPage} from 'modules/home-page/pages/ServicePage';
import {HOME_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const ServicePageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.catalog);

    return (
        <HomePageContext.Provider
            value={{
                aboutLeftSlideList: [],
                aboutRightSlideList: [],
                aboutSlideList: [],
                content: null,
                floristryStudioLeftSlideList: [],
                floristryStudioRightSlideList: [],
                floristryStudioSlideList: [],
                giftCardSlideList: [],
                homeAboutBanners: [],
                homeAdviceList: [],
                homeBannerSecondSlideList: [],
                homeBannerSlideList: [],
                homePrivateLabelList: [],
                homeServiceList: HOME_PAGE_SKELETON_DATA.homeServiceList,
                popularBrandList: [],
                popularCategoryList: [],
                popularCollectionList: [],
                popularPromoList: [],
                popularServices: null,
                summerFiestaLeftSlideList: [],
                summerFiestaRightSlideList: [],
                summerFiestaSlideList: [],
            }}
        >
            <ServicesPage />
        </HomePageContext.Provider>
    );
};
