import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {selectCategoryNodeById} from 'modules/categories/selectors';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {ROUTER_QUERY_KEY} from 'modules/router/constants';
import {useRouter} from 'next/router';

export const useCategoryByRouterId = (): ICategoryNode | undefined => {
    const router = useRouter();
    const {categoryId} = CategoryService.parseCategoryUrl(router.query[ROUTER_QUERY_KEY] as string);
    return useAppSelector(selectCategoryNodeById(categoryId));
};
