import {PromoListPage} from 'modules/promo/pages/PromoListPage';
import {promoSlice} from 'modules/promo/slice';
import {PROMO_LIST_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import {PaginationProvider} from 'plugins/modules/pagination/provider';
import React, {FC} from 'react';

export const PromoListPageSkeleton: FC = () => {
    const {promoList} = PROMO_LIST_PAGE_SKELETON_DATA;

    return (
        <SliceProvider slice={promoSlice}>
            <PaginationProvider count={0} page={0} pageSize={0} pageSizeList={[]} type="auto">
                <PromoListPage promoList={promoList} />
            </PaginationProvider>
        </SliceProvider>
    );
};
