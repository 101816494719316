import {createDraftSafeSelector, createSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {portalModuleName} from 'modules/portal/slice';
import {IPortalState} from 'modules/portal/types';

const select = (state: TAppRootState): IPortalState => state[portalModuleName];

export const selectIsPortalVisible = (dialogName: string) =>
    createDraftSafeSelector([select], (state) => state[dialogName]?.visible);

export const selectIsAnyPortalVisible = createSelector([select], (state) => {
    return Object.keys(state).some((portalName) => state[portalName]?.visible);
});
