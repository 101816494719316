import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IYandexMapState} from 'modules/yandex-map/types';

export const yandexMapModuleName = 'yandexmap';

const initialState: IYandexMapState = {
    isLoadSuccess: false,
    isLoading: false,
};

export const yandexMapSlice = createSlice({
    initialState,
    name: yandexMapModuleName,
    reducers: {
        actionSetIsYandexMapLoadSuccess(state: IYandexMapState, {payload}: PayloadAction<boolean>) {
            state.isLoadSuccess = payload;
        },
        actionSetIsYandexMapLoading(state: IYandexMapState, {payload}: PayloadAction<boolean>) {
            state.isLoading = payload;
        },
    },
});

export const {
    actions: {actionSetIsYandexMapLoading, actionSetIsYandexMapLoadSuccess},
} = yandexMapSlice;

export const {reducer: yandexMapReducer} = yandexMapSlice;
