import {PromoMobileHeader} from 'modules/promo/components/PromoMobileHeader';
import {PromoPage} from 'modules/promo/pages/PromoPage';
import {PROMO_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import {CategoryThirdLevelSkeleton} from 'modules/skeleton/pages/CategoryThirdLevelSkeleton';
import React, {FC} from 'react';

export const PromoPageSkeleton: FC = () => {
    const {promoData} = PROMO_PAGE_SKELETON_DATA;

    return (
        <CategoryThirdLevelSkeleton
            ComponentHeader={<PromoPage data={promoData} />}
            MobileHeaderComponent={<PromoMobileHeader data={promoData} />}
            noMobileHeaderOffset
            withBanners={false}
        />
    );
};
