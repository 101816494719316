import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IContentPageState} from 'modules/content/type';
import {ContentPage} from 'new-models';
import {HYDRATE} from 'next-redux-wrapper';

export const contentPageModuleName = 'contentPage';

const initialState: IContentPageState = {
    content: null,
};

export const contentPageSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[contentPageModuleName];
        },
    },
    initialState,
    name: contentPageModuleName,
    reducers: {
        actionSetContentPage(state: IContentPageState, {payload}: PayloadAction<ContentPage>) {
            state.content = payload;
        },
    },
});

export const {
    actions: {actionSetContentPage},
} = contentPageSlice;

export const {reducer: contentPageReducer} = contentPageSlice;
