import {PreviewRutube} from 'components/VideoRutube/components/Preview';
import {VideoPlayerRutube} from 'components/VideoRutube/components/VideoPlayer';
import {getVideoId} from 'components/VideoRutube/helpers/getVideoId';
import {IVideoRutubeProps} from 'components/VideoRutube/type';
import {memo} from 'react';

export const VideoRutube = memo<IVideoRutubeProps>(({url, isPreview, className}) => {
    const videoId = getVideoId(url);

    if (isPreview) {
        return <PreviewRutube className={className} videoId={videoId} />;
    }

    return <VideoPlayerRutube videoId={videoId} />;
});
