const checkMatchRegexp = (string: string, regExp: RegExp) => Boolean(string.match(regExp));
const checkTestRegexp = (string: string, regExp: RegExp) => regExp.test(string);

const searchRegexpIdx = (string: string, regExp: RegExp) => string.search(regExp);

const latinLetterRegExp = /[a-zA-Z]+/gu;
const cyrillicLetterRegExp = /[а-яА-Я]+/gu;
const digitRegExp = /[0-9]+/gu;
const emailRegExp = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/u;
const phoneRegExp = /^\+?\d[-.\s]?\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{2})[-.\s]?([0-9]{2})$/u;
const phoneReplacingRegExp = /_|\+| |\(|\)|-/gu;
const notLetter = /[?.,;!¡¿。、·\s]/u;
const letter = /[\d\u0400-\u04FFa-zA-Z]/u;

export const captureUrlIdRegExp = /-[0-9]+$/gu;

const fractionalNumbersRegExp = /^\d*[,.]?(\d*)$/gu;
const fractionalNumbersTwoDigitsRegExp = /^\d*[,.]?(\d{0,2})$/gu;
const integerRegExp = /^\d*$/gu;
const isSpaceRegExp = /^\s$/gu;

/**
 *
 * @param phone строка, которую надо очистить от посторонних символов
 * @returns номер телефона без знаков +, _, " "
 */
export const formatPhone = (phone = ''): string => {
    return phone.replace(phoneReplacingRegExp, '');
};

/**
 *
 * @param phone строка, которую надо очистить от посторонних символов
 * @returns номер телефона без знаков +, _, " "
 */
export const formatPhoneForView = (phone = ''): string => {
    return phone.replace(/\D*(\d)\D*(\d{3})\D*(\d{3})\D*(\d{2})\D*(\d{2})\D*/u, '+$1 $2 $3 $4 $5');
};

/**
 *
 * @param string строка, которую надо проверить
 * @returns включает ли строка латинскую букву
 */
export const checkIncludesLatinLetter = (string: string) => checkMatchRegexp(string, latinLetterRegExp);

/**
 *
 * @param string строка, которую надо проверить
 * @returns включает ли строка латинскую букву
 */
export const checkIncludesCyrillicLetter = (string: string) => checkMatchRegexp(string, cyrillicLetterRegExp);

/**
 *
 * @param string строка, которую надо проверить
 * @returns включает ли строка цифру
 */
export const checkIncludesDigit = (string: string) => checkMatchRegexp(string, digitRegExp);

/**
 *
 * @param string строка, которую надо проверить
 * @returns строка является Email
 */
export const checkIsEmail = (string: string) => checkTestRegexp(string, emailRegExp);

/**
 *
 * @param string строка, которую надо проверить
 * @returns строка является телефоном
 */
export const checkIsPhone = (string: string) => checkTestRegexp(string, phoneRegExp);

export const checkIsNumber = (string: string) => checkMatchRegexp(string, fractionalNumbersRegExp);
export const checkIsNumberTwoDigits = (string: string) => checkMatchRegexp(string, fractionalNumbersTwoDigitsRegExp);
export const checkIsInteger = (string: string) => checkMatchRegexp(string, integerRegExp);
export const checkIsSpace = (string: string) => checkMatchRegexp(string, isSpaceRegExp);
export const checkIsLetter = (string: string) => checkTestRegexp(string, letter);
export const searchNotLetterIdx = (string: string) => searchRegexpIdx(string, notLetter);
export const searchLetterIdx = (string: string) => searchRegexpIdx(string, letter);

export const getPriceGroups = (string: string) =>
    (string.match(/(?<price>[0-9 .,\s]+)(\s)(?<currency>.+)/u)?.groups || {}) as {price?: string; currency?: string};
