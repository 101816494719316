import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {MediaHarvester} from 'components/MediaHarvester';
import {TextCollapse} from 'components/TextCollapse';
import {TPropsWithClassName} from 'core/types';
import {BrandDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

const imageColumnStyle = getGridColumnStyles({
    md: 4,
});

const contentColumnStyle = getGridColumnStyles({
    md: 8,
});

const columnStyle = getGridColumnStyles();

interface IProps extends TPropsWithClassName {
    brandData: BrandDetail;
}

export const BrandDescription = memo<IProps>(function BrandDescription({brandData, className}) {
    const {description, title, logo} = brandData;

    return Boolean(description || logo) ? (
        <Grid as="article" className={classNames(style.root, className)} data-skeleton-item>
            {Boolean(logo) && (
                <div className={classNames(style.logoWrapper, imageColumnStyle)}>
                    <MediaHarvester className={style.logo} media={logo} title={title} />
                </div>
            )}
            {description && (
                <div className={Boolean(logo) ? contentColumnStyle : columnStyle}>
                    <TextCollapse buttonTextOpen="Подробнее о бренде" description={description} />
                </div>
            )}
        </Grid>
    ) : null;
});
