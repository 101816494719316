import {Typography} from 'components/Typography';
import {plural} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {ProductListPageContext} from 'modules/products/context';
import {CategoryType} from 'new-models';
import {useRouter} from 'next/router';
import React, {FC, useContext, useMemo} from 'react';
import {SYSTEM_CATALOG_COLLECTIONS_PAGE} from 'routing/constants';

interface IProps extends TPropsWithClassName {
    count: number;
    isStartText?: boolean;
}

export const ProductsCount: FC<IProps> = ({className, count, isStartText}) => {
    const router = useRouter();
    const {category} = useContext(ProductListPageContext);

    const renderPluralWords = useMemo<string[]>(() => {
        const defaultWordsList = ['товар', 'товара', 'товаров'];

        if (!category) {
            return defaultWordsList;
        }

        if (category.type === CategoryType.Collection) {
            // 3-й уровень листинга коллекций
            if (router.pathname === SYSTEM_CATALOG_COLLECTIONS_PAGE) {
                return ['коллекция', 'коллекции', 'коллекций'];
            }

            return defaultWordsList.reduce<string[]>((wordsList, word) => {
                return [...wordsList, `${word} коллекции`];
            }, []);
        }
        return defaultWordsList;
    }, [category, router.pathname]);

    return (
        <Typography className={className} color="gray50" element="div" variant="p-medium">
            {isStartText ? `${plural(count, ['Найден', 'Найдено', 'Найдено'])} ` : ''}
            {count} {plural(count, renderPluralWords)}
        </Typography>
    );
};
