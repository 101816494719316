import classNames from 'classnames';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {CartProductsList} from 'modules/cart/components/CartProductsList';
import {REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {useCartHeaderPopup} from 'modules/cart/hooks/useCartHeaderPopup';
import {useCartModalActions} from 'modules/cart/hooks/useCartModalActions';
import {selectCartProductsListWithoutGifts, selectCartTotal} from 'modules/cart/selectors';
import {actionSetAfterRemoveToast} from 'modules/cart/slice';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import {useRouter} from 'next/router';
import deleteIcon from 'public/icons/delete.svg';
import React, {FC, MouseEventHandler, useCallback, useEffect, useRef} from 'react';
import {CART_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export const CartHeaderPopup: FC<TPropsWithClassName> = ({className}) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const totalPrice = useAppSelector(selectCartTotal);
    const {openCartRemoveModal} = useCartModalActions();
    const cartProductsList = useAppSelector(selectCartProductsListWithoutGifts());
    const productsListContainerRef = useRef<HTMLDivElement>(null);
    const {handleScroll, isHeaderShadow, isFooterShadow} = useCartHeaderPopup({
        listLength: cartProductsList.length,
        productsListContainerRef,
    });

    const handleCartClick = useCallback<MouseEventHandler<HTMLButtonElement>>(async () => {
        await router.push(CART_ROUTE);
    }, [router]);

    useEffect(() => {
        return () => {
            dispatch(actionSetAfterRemoveToast(''));
        };
    }, [dispatch]);

    return (
        <div className={classNames(style.cartHeaderPopupWrapper, className)}>
            <div className={style.cartHeaderPopupContainer}>
                <div className={style.cartHeaderPopup}>
                    <div className={style.cartHeaderPopupContent}>
                        <div
                            className={classNames(style.header, {
                                [style.headerShadow]: isHeaderShadow,
                            })}
                        >
                            <Typography variant="h5">Корзина ({cartProductsList.length})</Typography>
                            <Button
                                className={style.clear}
                                isWithoutPaddings
                                onClick={openCartRemoveModal(REMOVE_MODAL_VIEW.DEFAULT)}
                                rightIcon={<SvgIcon svg={deleteIcon} />}
                                size="small"
                                theme="ghost"
                            >
                                Очистить все
                            </Button>
                        </div>
                        <div
                            className={style.productsListContainer}
                            onScroll={handleScroll}
                            ref={productsListContainerRef}
                        >
                            <CartProductsList
                                cartProductsList={cartProductsList}
                                className={style.cartProductsList}
                                isForHeaderPopup
                            />
                            <TotalPriceBlock
                                className={style.totalPriceBlock}
                                total={totalPrice.price}
                                usedIn="cartPopup"
                            />
                        </div>
                        <div
                            className={classNames(style.footer, {
                                [style.footerShadow]: isFooterShadow,
                            })}
                        >
                            <div className={style.toCartAction}>
                                <Button className={style.toCartActionButton} onClick={handleCartClick}>
                                    Перейти в корзину
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
