import {AddressName} from 'components/FormFields/AddressName';
import {DefaultAddress} from 'components/FormFields/DefaultAddress';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {DeliveryAddressBase} from 'new-models';
import React from 'react';

export const HeaderFields: FC = () => {
    return (
        <Field name="name">
            {({field, meta, form}: FieldProps<string, DeliveryAddressBase>) => (
                <AddressName errorText={meta.touched || form.submitCount ? meta.error : ''} {...field} />
            )}
        </Field>
    );
};

export const FooterFields: FC = () => {
    return (
        <Field name="isDefault">
            {({field}: FieldProps<string, DeliveryAddressBase>) => <DefaultAddress {...field} />}
        </Field>
    );
};
