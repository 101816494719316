import {MODALS} from 'modules/modals/constants';
import {useModalApi} from 'modules/modals/hooks/useModalApi';

export const useCheckoutSelectPickupPointModalApi = () => {
    const {open, close} = useModalApi();

    return {
        close: () => close(MODALS.CHECKOUT_SELECT_SHOP.name),
        open: () => open(MODALS.CHECKOUT_SELECT_SHOP.name),
    };
};
