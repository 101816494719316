import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectBestsellersList} from 'modules/products/selectors';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import {PromoPlace} from 'new-models';

export const HomePageBestseller = () => {
    const list = useAppSelector(selectBestsellersList);

    if (!list?.length) {
        return null;
    }

    return (
        <PageSectionBlock href="/catalog/bestseller" linkText="Все хиты" title="Хиты продаж">
            <SliderWithBanner href="/catalog/bestseller" products={list} promoPlace={PromoPlace.Bestseller} />
        </PageSectionBlock>
    );
};
