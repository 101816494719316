import {PayloadAction} from '@reduxjs/toolkit';
import {IPaginationState} from 'plugins/modules/pagination/types';

export function createPaginationActions<T extends IPaginationState>() {
    return {
        actionSetPage(state: T, {payload}: PayloadAction<T['page']>) {
            state.page = payload;
        },

        actionSetPageCount(state: T, {payload}: PayloadAction<T['count']>) {
            state.count = payload;
        },

        actionSetPageSize(state: T, {payload}: PayloadAction<T['pageSize']>) {
            state.pageSize = payload;
        },

        actionSetPaginationType(state: T, {payload}: PayloadAction<T['type']>) {
            state.type = payload;
        },
    };
}
