import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import RcTooltip from 'rc-tooltip';
import {TooltipProps} from 'rc-tooltip/lib/Tooltip';
import React, {memo, MutableRefObject, ReactElement, ReactNode} from 'react';

import style from './style.module.scss';

export const TRIGGER_ACTIONS = {
    click: 'click',
    hover: 'hover',
};

export const TOOLTIP_PLACEMENT = {
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
    left: 'left',
    right: 'right',
    top: 'top',
} as const;

export interface ITooltip extends TooltipProps, TPropsWithClassName {
    overlay: ReactNode;
    children?: ReactElement;
    overlayClassName?: string;
    trigger?: TooltipProps['trigger'];
    overlayRef?: MutableRefObject<HTMLDivElement | null>;
}

export const Tooltip = memo<ITooltip>(function Tooltip({
    className,
    overlay,
    children,
    showArrow = false,
    placement = TOOLTIP_PLACEMENT.bottomRight,
    overlayClassName,
    trigger = TRIGGER_ACTIONS.click,
    overlayRef,
    ...restTooltipProps
}) {
    return (
        <>
            <RcTooltip
                {...restTooltipProps}
                overlay={
                    <div
                        className={classNames(style.tooltipContainer, className)}
                        ref={(node) => {
                            if (overlayRef) {
                                overlayRef.current = node;
                            }
                        }}
                    >
                        {overlay}
                    </div>
                }
                overlayClassName={classNames(style.tooltip, style[placement], overlayClassName)}
                placement={placement}
                showArrow={showArrow}
                trigger={trigger}
            >
                {children}
            </RcTooltip>
        </>
    );
});
