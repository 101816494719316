import {useFormikSubmit} from 'core/hooks/useFormikSubmit';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {FormikProps} from 'formik/dist/types';
import {selectCartSelectedItemList} from 'modules/cart/selectors';
import {CHECKOUT_INITIAL_VALUES, PAYMENT_METHODS} from 'modules/checkout/constants';
import {useCheckoutFormUpdateData} from 'modules/checkout/hooks/useCheckoutFormUpdateData';
import {useResetCheckoutForm} from 'modules/checkout/hooks/useResetCheckoutForm';
import {createOrder, getPaymentLink} from 'modules/checkout/thunks';
import {ICheckoutValues} from 'modules/checkout/types';
import {getCheckoutFormValidator} from 'modules/checkout/validators';
import {
    DeliveryType,
    OrderAddress,
    OrderCourierDeliveryData,
    OrderCreateRequest,
    OrderPickupDeliveryData,
    ProductsDataRequest,
} from 'new-models';
import {useRouter} from 'next/router';
import {MutableRefObject, useCallback, useMemo, useRef} from 'react';
import {THANKS_FOR_YOUR_ORDER_ROUTE} from 'routing/constants';

const validationSchema = getCheckoutFormValidator({
    courierAddressRequired: 'Выберите адрес доставки',
    deliveryRequired: 'Выберите способ доставки',
    paymentRequired: 'Выберите способ оплаты',
    pickupPointRequired: 'Выберите магазин',
    required: 'Поле является обязательным',
});

interface IUseCheckoutForm {
    formRef: MutableRefObject<FormikProps<ICheckoutValues> | null>;
    handleSubmit: ReturnType<typeof useFormikSubmit<OrderCreateRequest>>;
    initialValues: ICheckoutValues;
    validationSchema: typeof validationSchema;
}

export const useCheckoutForm = (): IUseCheckoutForm => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const cartProductList = useAppSelector(selectCartSelectedItemList);

    const formRef = useRef<FormikProps<ICheckoutValues>>(null);

    useCheckoutFormUpdateData(formRef);
    useResetCheckoutForm(formRef);

    const initialValues: ICheckoutValues = useMemo(() => {
        return CHECKOUT_INITIAL_VALUES;
    }, []);

    const handleSubmitForm = useCallback(
        async (formValues: ICheckoutValues) => {
            const {tempFields, ...orderValues} = formValues;

            switch (tempFields?.deliveryType) {
                case DeliveryType.COURIER:
                    if (!tempFields?.userAddressCount) {
                        delete (orderValues.deliveryData as OrderPickupDeliveryData).pickupPointId;
                    } else {
                        for (const fieldKey in orderValues.deliveryData as OrderCourierDeliveryData) {
                            if ('address' !== fieldKey && 'deliveryId' !== fieldKey) {
                                // @ts-expect-error
                                delete (orderValues.deliveryData as OrderCourierDeliveryData as OrderAddress)[fieldKey];
                            }
                        }
                    }
                    break;
                case DeliveryType.PICKUP:
                    delete (orderValues.deliveryData as OrderCourierDeliveryData).address;
                    break;
                default:
                    break;
            }

            orderValues.products = cartProductList
                .filter(({quantity}) => Boolean(quantity))
                .map<ProductsDataRequest>(({quantity, product}) => ({
                    id: product.id,
                    quantity,
                }));

            const data = await dispatch(createOrder(orderValues));

            if (!data?.order) {
                return;
            }

            if (data?.order.paymentMethod.code !== PAYMENT_METHODS['card-or-sbp']) {
                router.push(`${THANKS_FOR_YOUR_ORDER_ROUTE}/${data.order.id}`);
                return;
            }

            const link = await dispatch(getPaymentLink(data.order.id));

            if (!link) {
                router.push(`${THANKS_FOR_YOUR_ORDER_ROUTE}/${data.order.id}`);
                return;
            }

            const redirectUrl = `${window.location.protocol}//${window.location.host}${THANKS_FOR_YOUR_ORDER_ROUTE}/${data.order.id}`;

            const url = new URL(link);
            url.searchParams.set('back_url_s', redirectUrl);
            url.searchParams.set('back_url_f', redirectUrl);

            router.push(url);

            window.history.replaceState({}, '', redirectUrl);
        },

        [cartProductList, dispatch, router]
    );

    const handleSubmit = useFormikSubmit<OrderCreateRequest>(handleSubmitForm);

    return {
        formRef,
        handleSubmit,
        initialValues,
        validationSchema,
    };
};
