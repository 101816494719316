import classNames from 'classnames';
import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {getProductPluralText} from 'core/helpers/plural';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {TPropsWithClassName} from 'core/types';
import {useFormikContext} from 'formik';
import {TotalPriceBlock} from 'modules/price-n-bonuses/components/TotalPrice';
import React, {FC, useRef} from 'react';

import {useCheckoutTotalInfo} from '../../hooks/useCheckoutTotalInfo';
import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    inView: boolean;
}

export const TabBarCheckout: FC<IProps> = ({className, inView}) => {
    const {totalPrice, productCount, deliveryCost} = useCheckoutTotalInfo();
    const {submitForm, isSubmitting} = useFormikContext();

    const ref = useRef<HTMLDivElement>(null);
    useElementHeightWithCssVar(ref, RUNTIME_CSS_VARS.checkoutTabBarHeight);

    return (
        <div
            className={classNames(style.root, className, {
                [style.inView]: inView,
            })}
            ref={ref}
        >
            <div className={style.content}>
                <Typography color="gray60" variant="p-medium">
                    {productCount ? `${productCount} ${getProductPluralText(productCount)}` : 'Товары не выбраны'}
                </Typography>
                <TotalPriceBlock deliveryCost={deliveryCost} isHiddeTotalTitle total={totalPrice} usedIn="cartResume" />
            </div>
            <Button disabled={!productCount} isLoading={isSubmitting} onClick={submitForm}>
                Оформить
            </Button>
        </div>
    );
};
