import classNames from 'classnames';
import {Button, BUTTON_COLORS, BUTTON_THEMES} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Pagination} from 'components/Pagination';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {isBrowserContext} from 'core/helpers';
import {getProductPluralText} from 'core/helpers/plural';
import {PageInfo} from 'models';
import {GoTopButton} from 'modules/products/components/GoTopButton';
import {DEFAULT_SCROLL_OFFSET, PAGINATION_TYPE} from 'plugins/modules/pagination/constants';
import {TPaginationType} from 'plugins/modules/pagination/types';
import chevronIcon from 'public/icons/chevron-down.svg';
import React, {MouseEventHandler, ReactNode, useCallback, useMemo, useState} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

export interface IProps<E extends HTMLElement> extends PageInfo {
    onChange?: (pageNumber: PageInfo['pageNomer'], type: TPaginationType) => void;
    isLoading?: boolean;
    scrollTarget?: E | null;
    scrollOffset?: number;
    pluralFunction?: (n: number) => string;
}

export const ListPagination = <E extends HTMLElement = HTMLElement>({
    isLoading,
    pageNomer = 1,
    pageSize,
    onChange,
    recordCount,
    scrollTarget,
    scrollOffset = DEFAULT_SCROLL_OFFSET,
    pluralFunction = getProductPluralText,
}: IProps<E>) => {
    const [startShowMorePage, setStartShowMorePage] = useState<number>(0);

    const handleShowMoreClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
        if (onChange) {
            onChange(pageNomer + 1, PAGINATION_TYPE.ACCUMULATIVE);
        }
        if (!startShowMorePage) {
            setStartShowMorePage(pageNomer);
        }
    }, [onChange, pageNomer, startShowMorePage]);

    const handlePageChangeScroll = useCallback(() => {
        if (!isBrowserContext()) {
            return;
        }

        if (!scrollTarget) {
            window.scrollTo(0, 0);
            return;
        }

        const dimension = scrollTarget.getBoundingClientRect();

        window.scrollTo(window.scrollX, dimension.top - scrollOffset + window.scrollY);
    }, [scrollOffset, scrollTarget]);

    const handlePageClick = useCallback(
        (page: number) => {
            handlePageChangeScroll();
            if (onChange) {
                onChange(page, 'auto');
            }
        },
        [handlePageChangeScroll, onChange]
    );

    const totalProducts = useMemo<PageInfo['recordCount']>(() => {
        return pageSize >= recordCount ? recordCount : pageSize;
    }, [pageSize, recordCount]);

    const totalPages = useMemo<number>(() => {
        if (!recordCount) {
            return 1;
        }

        if (!pageSize) {
            return 1;
        }

        return Math.ceil(recordCount / pageSize);
    }, [pageSize, recordCount]);

    const isShowMoreButtonDisabled = useMemo<boolean>(() => {
        return Boolean(pageSize * pageNomer >= recordCount);
    }, [pageNomer, pageSize, recordCount]);

    const currentProductCount = useMemo<number>(() => {
        if (1 === pageNomer) {
            return 1;
        }

        if (startShowMorePage) {
            return (startShowMorePage - 1) * pageSize + 1;
        }

        return (pageNomer - 1) * pageSize + Number(1 !== pageNomer);
    }, [pageNomer, startShowMorePage, pageSize]);

    const nextProductCount = useMemo<number>(() => {
        if (pageNomer * pageSize >= recordCount) {
            return recordCount;
        }

        return pageNomer * pageSize;
    }, [pageNomer, pageSize, recordCount]);

    const renderProductCountRest = useMemo<ReactNode>(() => {
        const productCountRestString = recordCount ? `${currentProductCount} - ${nextProductCount}` : nextProductCount;

        return (
            <div className={style.desktopCount}>
                <Typography color="gray60" element="span" variant="p">
                    {productCountRestString} {pluralFunction(totalProducts)}
                </Typography>
                <Typography className={style.listLayoutPaginationTotal} color="secondary80" element="span" variant="p">
                    из {recordCount}
                </Typography>
            </div>
        );
    }, [currentProductCount, nextProductCount, pluralFunction, recordCount, totalProducts]);

    return (
        <Grid className={style.root}>
            <div className={classNames(style.listLayoutShowMoreContainer, columnStyle)}>
                {recordCount > pageSize && !isShowMoreButtonDisabled && (
                    <Button
                        className={style.listLayoutPaginationMore}
                        color={BUTTON_COLORS.secondaryColor}
                        isLoading={isLoading}
                        isStretched
                        onClick={handleShowMoreClick}
                        rightIcon={<SvgIcon svg={chevronIcon} />}
                        theme={BUTTON_THEMES.secondary}
                    >
                        Показать еще
                    </Button>
                )}
            </div>
            <div className={classNames(style.bottomContainer, columnStyle)}>
                {renderProductCountRest}
                {recordCount > pageSize && (
                    <Pagination
                        className={classNames(style.listLayoutPaginationContainer, columnStyle)}
                        currentPage={pageNomer}
                        onChange={handlePageClick}
                        totalPages={totalPages}
                    />
                )}
            </div>

            <GoTopButton />
        </Grid>
    );
};
