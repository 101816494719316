import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {TUsedIn} from 'modules/price-n-bonuses/types';
import {DeliveryInfo, TotalPrice} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface ITotalPriceProps extends TPropsWithClassName {
    total: TotalPrice;
    usedIn: TUsedIn;
    isHiddeTotalTitle?: boolean;
    deliveryCost?: DeliveryInfo['deliveryMinPrice'];
}

const PriceItem = ({value, isDiscount}: {value: number; isDiscount?: boolean}) => (
    <Typography color={isDiscount ? 'error50' : 'gray100'} variant={isDiscount ? 'old-price-cart' : 'price-preview-m'}>
        {formatPrice((value / PRICE_DENOMINATOR).toString(), {
            maximumFractionDigits: 2,
        })}
        <Typography className={style.totalPriceCurrency} color={isDiscount ? 'error50' : 'gray100'} variant="p-strong">
            ₽
        </Typography>
    </Typography>
);

// eslint-disable-next-line complexity
export const TotalPriceBlock = memo<ITotalPriceProps>(function TotalPriceBlock({
    total,
    usedIn,
    className,
    isHiddeTotalTitle,
    deliveryCost,
}) {
    return (
        <div
            className={classNames(
                style.totalPriceBlock,
                style[usedIn],
                {[style.hideTotalTitle]: isHiddeTotalTitle},
                className
            )}
        >
            {!isHiddeTotalTitle && (
                <Typography color="gray100" variant={'cartPopup' === usedIn ? 'p-strong' : 'p'}>
                    Итого
                </Typography>
            )}
            {Boolean(total.discount && total.cost) && (
                <div
                    className={classNames(style.totalPriceAndSale, {
                        [style[usedIn]]: Boolean(usedIn),
                    })}
                >
                    <PriceItem
                        value={deliveryCost && total.totalCost ? total.totalCost + deliveryCost : total.totalCost}
                    />
                    <Typography className={style.oldPrice} variant="price-preview-m">
                        {formatPrice(
                            (deliveryCost && total.cost
                                ? (total.cost + deliveryCost) / PRICE_DENOMINATOR
                                : total.cost / PRICE_DENOMINATOR
                            ).toString(),
                            {
                                maximumFractionDigits: 2,
                            }
                        )}
                        <Typography className={style.totalPriceCurrency} variant="elements-p">
                            ₽
                        </Typography>
                    </Typography>
                </div>
            )}
            {!total.discount && (
                <PriceItem value={deliveryCost && total.totalCost ? total.totalCost + deliveryCost : total.totalCost} />
            )}
        </div>
    );
});
