import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {HIDE} from 'core/constants';
import {findWordEnd} from 'core/helpers';
import {TPropsWithClassName} from 'core/types';
import {ProductOfferDetail} from 'models';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import chevronIcon from 'public/icons/chevron-down.svg';
import React, {memo, useEffect, useMemo, useState} from 'react';

import style from './style.module.scss';

const MAX_DISPLAYED_TEXT_SYMBOLS_NUMBER = 390;
export interface IProps extends TPropsWithClassName {
    description: ProductOfferDetail['description'];
}

export const ProductCardDescription = memo<IProps>(function ProductCardDescription({className, description}) {
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        setIsHidden(description.length > MAX_DISPLAYED_TEXT_SYMBOLS_NUMBER);
    }, [description.length]);
    const wordEndIdx = useMemo<number>(
        () => findWordEnd({searchIndex: MAX_DISPLAYED_TEXT_SYMBOLS_NUMBER, text: description}),
        [description]
    );

    return (
        <div className={classNames(className, style.productCardDescription)} id={MAIN_INFO_DATA.DESCRIPTION.name}>
            <Typography className={style.productCardDescriptionTitle} data-skeleton-item element="div" variant="h3">
                Описание
            </Typography>
            <Typography className={style.productCardDescriptionText} data-skeleton-item variant="p">
                {/* eslint-disable @typescript-eslint/naming-convention */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: isHidden ? `${description.slice(0, wordEndIdx)}...` : description,
                    }}
                />
                {/* eslint-enable @typescript-eslint/naming-convention */}
            </Typography>
            {description.length > wordEndIdx && (
                <Anchor
                    className={classNames(style.productCardDescriptionButton, {
                        [style.textHidden]: isHidden,
                    })}
                    data-skeleton-item
                    icon="right"
                    iconNode={<SvgIcon svg={chevronIcon} />}
                    onClick={() => setIsHidden(!isHidden)}
                    size="large"
                    theme="standalone"
                    withBorder={false}
                >
                    {isHidden ? 'Все описание' : HIDE}
                </Anchor>
            )}
        </div>
    );
});
