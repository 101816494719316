import classNames from 'classnames';
import {createUltimatePagination, ITEM_TYPES} from 'components/PaginationBasic';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PageInfo} from 'models';
import paginationChevronIcon from 'public/icons/pagination-chevron.svg';
import React, {FC} from 'react';

import style from './style.module.scss';

const ChevronIcon: FC = () => <SvgIcon svg={paginationChevronIcon} />;

export interface IPaginationProps {
    value: PageInfo['pageNomer'];
    isActive: boolean;
    onClick: () => void;
    isDisabled: boolean;
}

const Page: FC<IPaginationProps> = ({value, isActive, onClick, isDisabled}) => (
    <button
        className={classNames(style.pageButton, {[style.pageButtonActive]: isActive})}
        disabled={isDisabled}
        onClick={onClick}
    >
        <Typography element="span" variant="p-bold-strong">
            {value}
        </Typography>
    </button>
);

const Ellipsis: FC<IPaginationProps> = ({onClick, isDisabled}) => (
    <button className={style.pageButton} disabled={isDisabled} onClick={onClick}>
        <Typography element="span" variant="label">
            ...
        </Typography>
    </button>
);

const FirstPageLink: FC<IPaginationProps> = () => null;

const PreviousPageLink: FC<IPaginationProps> = ({onClick, isDisabled}) => (
    <button className={classNames(style.pageButton, style.pageButtonArrow)} disabled={isDisabled} onClick={onClick}>
        <ChevronIcon />
    </button>
);

const NextPageLink: FC<IPaginationProps> = ({onClick, isDisabled}) => {
    return (
        <button
            className={classNames(style.pageButton, style.pageButtonArrow, style.nextButton)}
            disabled={isDisabled}
            onClick={onClick}
        >
            <ChevronIcon />
        </button>
    );
};

const LastPageLink: FC<IPaginationProps> = () => null;

const itemTypeToComponent = {
    [ITEM_TYPES.PAGE]: Page,
    [ITEM_TYPES.ELLIPSIS]: Ellipsis,
    [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
    [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
    [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink,
};

export const Pagination = createUltimatePagination({itemTypeToComponent});
