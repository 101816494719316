import classNames from 'classnames';
import {Button, BUTTON_THEMES, IProps as IButtonProps} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import infoIcon from 'public/icons/info.svg';
import React, {ButtonHTMLAttributes, memo} from 'react';

import style from './style.module.scss';

export const INFO_BLOCK_BUTTON_POSITION = {
    bottom: 'bottom',
    right: 'right',
} as const;

export type TInfoBlockButtonPosition = keyof typeof INFO_BLOCK_BUTTON_POSITION;

export const INFO_BLOCK_TYPE = {
    error: 'error',
    info: 'info',
    warning: 'warning',
} as const;

export type TInfoBlockType = keyof typeof INFO_BLOCK_TYPE;

interface IInfoBlockButtonProps {
    buttonText?: string;
    onClick?: IButtonProps['onClick'];
    buttonTheme?: IButtonProps['theme'];
    buttonHref?: IButtonProps['href'];
    buttonTarget?: IButtonProps['target'];
    buttonRel?: IButtonProps['rel'];
}

export interface IProps extends IInfoBlockButtonProps, TPropsWithClassName {
    type?: TInfoBlockType;
    headerText?: string;
    contentText: string;
    isHideIcon?: boolean;
    isHideButton?: boolean;
    isHideHeading?: boolean;
    buttonPosition?: TInfoBlockButtonPosition;
    ButtonComponent?: FC<ButtonHTMLAttributes<HTMLButtonElement>>;
    contentTextVariant?: ITypographyPublicProps['variant'];
}

const InfoBlockButton: FC<IInfoBlockButtonProps> = ({
    buttonText,
    onClick,
    buttonTheme = BUTTON_THEMES.secondary,
    buttonHref,
    buttonTarget,
    buttonRel,
}) => {
    if (!((onClick || buttonHref) && buttonText)) {
        return null;
    }

    return (
        <Button
            className={style.button}
            href={buttonHref}
            onClick={onClick}
            rel={buttonRel}
            size="medium"
            target={buttonTarget}
            theme={buttonTheme}
        >
            {buttonText}
        </Button>
    );
};

// eslint-disable-next-line complexity
export const InfoBlock = memo<IProps>(function InfoBlock({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ButtonComponent,
    contentText,
    type = INFO_BLOCK_TYPE.info,
    className,
    buttonPosition = INFO_BLOCK_BUTTON_POSITION.right,
    headerText,
    isHideIcon,
    isHideHeading,
    isHideButton,
    contentTextVariant = 'p-medium',
    ...restProps
}) {
    const {buttonText, onClick} = restProps;

    const isShowHeading = !isHideHeading && headerText;
    const isShowIcon = !isHideIcon;
    const isShowButton = !isHideButton && (ButtonComponent || (buttonText && onClick));

    const renderButton =
        isShowButton &&
        (ButtonComponent ? (
            <ButtonComponent className={style.button} onClick={onClick} />
        ) : (
            <InfoBlockButton {...restProps} />
        ));

    return (
        <div
            className={classNames(style.rootContainer, style[`${type}Type`], className, 'info-block-component')}
            data-skeleton-item
        >
            <div className={style.contentContainer}>
                {isShowIcon && <SvgIcon className={style.icon} svg={infoIcon} />}
                <div className={style.container}>
                    <div
                        className={classNames(style.contentWrapper, {
                            [style.onlyContentWithIcon]: isShowIcon && !isShowHeading,
                        })}
                    >
                        {isShowHeading && (
                            <Typography color="gray100" element="h4" variant="p-strong">
                                {headerText}
                            </Typography>
                        )}
                        <Typography color="gray100" element="p" variant={contentTextVariant}>
                            {contentText}
                        </Typography>
                    </div>
                    {renderButton && buttonPosition === INFO_BLOCK_BUTTON_POSITION.bottom && (
                        <div className={style.buttonWrapperBottom}>{renderButton}</div>
                    )}
                </div>
            </div>
            {renderButton && (
                <div
                    className={classNames(style.buttonWrapperRight, {
                        [style.positionRight]: buttonPosition === INFO_BLOCK_BUTTON_POSITION.right,
                    })}
                >
                    {renderButton}
                </div>
            )}
        </div>
    );
});
