export class ScriptConnectionError extends Error {
    public readonly scriptUrl: string;

    constructor(scriptUrl: ScriptConnectionError['scriptUrl']) {
        super();

        this.scriptUrl = scriptUrl;

        this.message = `Script ${scriptUrl} throw error during connection, stack: \n\n${this.stack}`;
    }
}
