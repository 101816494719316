import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IShieldState} from 'modules/shields/types';

export const shieldModuleName = 'shield';

const initialState: IShieldState = {
    tooltipContent: null,
};

const shieldSlice = createSlice({
    initialState,
    name: shieldModuleName,
    reducers: {
        actionSetTooltipContent(state: IShieldState, {payload}: PayloadAction<IShieldState['tooltipContent']>) {
            state.tooltipContent = payload;
        },
    },
});

export const {
    actions: {actionSetTooltipContent},
} = shieldSlice;

export const {reducer: shieldReducer} = shieldSlice;
