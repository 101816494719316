import classNames from 'classnames';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useEditAddressModalApi} from 'modules/addresses/hooks/useEditAddressModalApi';
import {useRemoveAddressModalApi} from 'modules/addresses/hooks/useRemoveAddressModalApi';
import {DeliveryAddress} from 'new-models';
import deleteIcon from 'public/icons/delete.svg';
import pencilIcon from 'public/icons/pencil.svg';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends DeliveryAddress {
    className?: string;
}

export const AddressCardItem = memo<IProps>(function AddressCardItem({className, id, name, address, isDefault}) {
    const {open: openEditModal} = useEditAddressModalApi();
    const {open: openRemoveModal} = useRemoveAddressModalApi();

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={classNames(style.content)}>
                <div className={style.header}>
                    {name && (
                        <Typography element="div" variant="p-strong">
                            {name}
                        </Typography>
                    )}
                    <Typography element="div" variant="p">
                        {address}
                    </Typography>
                </div>
                {isDefault && (
                    <Typography className={style.mainAddress} element="div" variant="p">
                        Основной адрес
                    </Typography>
                )}
            </div>
            <div className={style.actions}>
                <Button isSquare onClick={openEditModal(id)} size="small" theme="ghost">
                    <SvgIcon svg={pencilIcon} />
                </Button>
                <Button isSquare onClick={openRemoveModal(id)} size="small" theme="ghost">
                    <SvgIcon svg={deleteIcon} />
                </Button>
            </div>
        </div>
    );
});
