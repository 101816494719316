import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {BasketItem, DeliveryInfo, TotalPrice} from 'new-models';
import {useContext, useMemo} from 'react';

interface ICheckoutTotalInfo {
    totalPrice: TotalPrice;
    discountPrice: number;
    productSum: number;
    productCount: number;
    weightValue: number;
    volumeValue: number;
    products: BasketItem[];
    deliveryCost: DeliveryInfo['deliveryMinPrice'];
}
export const useCheckoutTotalInfo = (): ICheckoutTotalInfo => {
    const {cartProductList, cartTotal, deliveryInfo} = useContext(CheckoutContext);

    const {price, measure} = cartTotal;

    const {values} = useCheckoutFormikContext();

    const deliveryCost =
        deliveryInfo?.find(({deliveryMethod}) => deliveryMethod.id === values.deliveryData?.deliveryId)
            ?.deliveryMinPrice ?? 0;

    const discountPrice = price.discount;

    const productSum = price.cost;

    const weightValue = measure?.weight ?? 0;

    const volumeValue = measure?.volume ?? 0;

    return useMemo(
        () => ({
            deliveryCost,
            discountPrice,
            productCount: cartProductList.length,
            productSum,
            products: cartProductList,
            totalPrice: price,
            volumeValue,
            weightValue,
        }),
        [deliveryCost, discountPrice, cartProductList, productSum, price, volumeValue, weightValue]
    );
};
