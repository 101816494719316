import {TOKEN_KEY} from 'core/constants';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {IHttpClientResponse, TJson, TResponse, TResponseWithoutContent} from 'core/types';
import {IAuthEmailData, IAuthPhoneData, ILoginPasswordData, IPhoneData} from 'modules/authentication/types';
import {PasswordRestoreDataRequest} from 'new-models';

export class AuthenticationService extends EvolutionHttpClient {
    public async getToken(): Promise<string | null> {
        const {headers} = await this.post({
            url: '/auth/token',
        });

        return headers.get(TOKEN_KEY);
    }

    public async updateToken(): Promise<string | null> {
        const {headers} = await this.patch({
            url: '/auth/token',
        });

        return headers.get(TOKEN_KEY);
    }

    public authByLogin({login, password}: ILoginPasswordData): Promise<TResponseWithoutContent> {
        return this.post<ILoginPasswordData, TResponse<null>>({
            body: {
                login,
                password,
            },
            url: '/auth/by-login',
        });
    }

    public authByEmail({email, code}: IAuthEmailData): Promise<TResponseWithoutContent> {
        const body: IAuthEmailData = {
            email,
        };

        if (code) {
            body.code = code;
        }

        return this.post<IAuthEmailData, TResponse<null>>({
            body,
            url: '/auth/by-email',
        });
    }

    public async authByPhone({phone, code, isLegal}: IPhoneData): Promise<TResponseWithoutContent> {
        const body: IAuthPhoneData = {
            phone,
        };

        if (code) {
            body.code = code;
        }

        if (isLegal) {
            body.isLegal = isLegal;
        }

        return this.post<IAuthPhoneData, TResponse<null>>({
            body,
            url: '/auth/by-phone',
        });
    }

    public async authLogout(): Promise<string | null> {
        const {headers} = await this.post({
            url: '/auth/logout',
        });

        return headers.get(TOKEN_KEY);
    }

    public async passwordRestore(
        req: PasswordRestoreDataRequest
    ): Promise<IHttpClientResponse<TResponse<unknown>, TJson, Response>> {
        const response = await this.post<PasswordRestoreDataRequest, TResponse<undefined>>({
            body: req,
            url: '/password/restore',
        });

        return response;
    }
}
