import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {Alternative} from 'modules/price-n-bonuses/components/Alternative';
import {Main} from 'modules/price-n-bonuses/components/Main';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {usePriceAndBonuses} from 'modules/price-n-bonuses/hooks/usePriceAndBonuses';
import {TUsedIn} from 'modules/price-n-bonuses/types';
import {ProductPrice} from 'new-models';
import React, {FC, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    usedIn: TUsedIn;
    price: ProductPrice;
    isProductAvailable: boolean | undefined;
    withUnit?: boolean;
}

export const PriceAndBonuses: FC<IProps> = ({
    className,
    usedIn = USED_IN.mainOffer,
    price,
    isProductAvailable = true,
    withUnit,
}) => {
    const {isBottomTitle, isAlternative} = usePriceAndBonuses();

    const renderTitle = useMemo<ReactNode>(() => {
        if (isBottomTitle(usedIn, isProductAvailable) || usedIn !== USED_IN.mainOffer) {
            return null;
        }

        if (!isProductAvailable && usedIn !== USED_IN.mainOffer) {
            return (
                <Typography className={style.title} color="gray60" variant="p-medium">
                    Последняя цена
                </Typography>
            );
        }

        return null;
    }, [isBottomTitle, isProductAvailable, usedIn]);

    return (
        <div
            className={classNames(className, style.container, {
                [style[usedIn]]: usedIn,
            })}
            data-skeleton-item
        >
            {renderTitle}
            <Main isProductAvailable={isProductAvailable} price={price} usedIn={usedIn} withUnit={withUnit} />
            {isAlternative(usedIn, price?.additional, isProductAvailable) && (
                <Alternative alternative={price?.additional!} className={style.alternative} />
            )}
            {isBottomTitle(usedIn, isProductAvailable) && (
                <Typography
                    className={classNames(style.bottomTitle, {
                        [style[usedIn]]: usedIn,
                    })}
                    color="price-old"
                    variant="p-medium"
                >
                    Последняя цена
                </Typography>
            )}
        </div>
    );
};
