import {TPropsWithChildrenRequire} from 'core/types';
import {ModalContent} from 'modules/modals/components/ModalContent';
import {ModalTitle} from 'modules/modals/components/ModalTitle';
import {RegularModal} from 'modules/modals/components/RegularModal';
import React, {memo} from 'react';

import style from './syle.module.scss';

interface IProps extends TPropsWithChildrenRequire {
    title: string;
    isSmallerContent?: boolean;
}

export const AddressModalLayout = memo<IProps>(function AddressModalLayout({title, children}) {
    return (
        <RegularModal adaptive className={style.root} hasClose>
            <ModalTitle className={style.title} title={title} />
            <ModalContent className={style.content}>{children}</ModalContent>
        </RegularModal>
    );
});
