export const GALLERY_MODE = {
    basic: 'basic',
    fullscreen: 'fullscreen',
    technical: 'technical',
} as const;

export const SLIDER_DIRECTION = {
    horizontal: 'horizontal',
    vertical: 'vertical',
} as const;

export const SPACE_BETWEEN = {
    cards: 12,
    default: 24,
    galleryButtons: 8,
    galleryButtonsHorizontal: 13,
    mobileGalleryButtons: 16,
    previews: 16,
} as const;

export const SLIDER_SPEED_SLOW = 1200;
export const SLIDER_SPEED_AUTO = 300;

export const MIN_SWIPE_DISTANCE = 30;

export const SLIDERS_PER_VIEW = {
    auto: 'auto',
    four: 4,
    one: 1,
    six: 6,
} as const;

export const SLIDERS_PER_GROUP = {
    four: 4,
    one: 1,
    six: 6,
} as const;
