import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {ORDER_STATUS_TYPE_TEXT} from 'modules/orders/constants';
import {OrderStatus} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    status: OrderStatus;
}

const getColorClassName = (status: OrderStatus) => {
    switch (status) {
        case OrderStatus.CREATED:
            return style.processedBg;
        case OrderStatus.IN_ASSEMBLY:
            return style.assemblyBg;
        case OrderStatus.IN_DELIVERY:
            return style.deliveryBg;
        case OrderStatus.READY_FOR_PICKUP:
            return style.readyForPickupBg;
        case OrderStatus.COMPLETED:
            return style.completedBg;
        case OrderStatus.CANCELED:
            return style.cancelledBg;
        default:
            return style.defaultBg;
    }
};

export const OrderStatusBadge = memo<IProps>(function OrderStatusBadge({status, className}) {
    if (!(status in ORDER_STATUS_TYPE_TEXT)) {
        return null;
    }

    return (
        <Typography className={classNames(style.root, getColorClassName(status), className)} variant="p-small">
            {ORDER_STATUS_TYPE_TEXT[status]}
        </Typography>
    );
});
