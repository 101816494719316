import classNames from 'classnames';
import {PortalContext} from 'modules/portal/context/PortalContext';
import React, {FC, HTMLAttributes, useContext} from 'react';

import style from './style.module.scss';

export const ModalOverlay: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const {visibility} = useContext(PortalContext);

    return <div {...props} className={classNames(style.modalOverlay, {[style.modalOverlayVisible]: visibility})} />;
};
