import {MIN_FETCH_SYMBOLS_AMOUNT} from 'modules/search-popup/constants';
import {CategoryBase, ProductShort} from 'new-models';
import {TSearchSuggestions} from 'plugins/modules/search/types';
import {useMemo} from 'react';

interface IProps {
    searchCategories?: CategoryBase[];
    searchProducts?: ProductShort[];
    searchSuggestions?: TSearchSuggestions;
    searchValue?: string;
}

export const useShowSearchPopupData = ({searchCategories, searchProducts, searchSuggestions, searchValue}: IProps) => {
    return useMemo(() => {
        const isValueValid = Boolean(searchValue && searchValue.length >= MIN_FETCH_SYMBOLS_AMOUNT);
        const isShowProducts = Boolean(searchProducts?.length && isValueValid);
        const isShowCategories = Boolean(isShowProducts && searchCategories?.length);
        const isShowPopularRequests = !isShowProducts && Boolean(searchSuggestions?.length);

        return {
            isShowCategories,
            isShowPopularRequests,
            isShowProducts,
            isValueValid,
        };
    }, [searchCategories, searchProducts, searchSuggestions, searchValue]);
};
