import classNames from 'classnames';
import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {MediaTypeEnum, ProductShort} from 'new-models';
import Link from 'next/link';
import React, {memo} from 'react';

import style from './style.module.scss';

interface ICartProductImgProps extends ProductShort, TPropsWithClassName {
    productUrl: string;
    isInPopup?: boolean;
}

const ImageFallback: FC = () => <NoImage size={NO_IMAGE_SIZE.small} />;

export const CartProductImg = memo<ICartProductImgProps>(function CartProductImg({
    className,
    productUrl,
    isInPopup = false,
    ...product
}) {
    const {availability, media} = product;

    return (
        <Link href={productUrl} prefetch={false}>
            <a data-skeleton-item>
                <div
                    className={classNames(
                        style.image,
                        {
                            [style.noAvailability]: !availability,
                            [style.isInPopup]: isInPopup,
                        },
                        className
                    )}
                >
                    <MediaWithImageFallback
                        NoImageComponent={ImageFallback}
                        media={media}
                        mediaType={MediaTypeEnum.Image}
                        options={generateMediaOptions({
                            resizeKey: IMAGE_RESIZE_CODE.w200,
                            type: MediaTypeEnum.Image,
                        })}
                    />
                </div>
            </a>
        </Link>
    );
});
