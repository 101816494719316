import {AbstractApiResponseInterceptor} from 'core/abstract/AbstractApiResponseInterceptor';
import {AuthSdk} from 'core/services/AuthSdk';
import {CookieService} from 'core/services/CookieService';
import {IHttpClientResponse, TJson, TRequestOption, TRequestParameters} from 'core/types';

export const UPDATE_TOKEN_HEADER_KEY = 'Token-Refresh'.toLowerCase();
export const UPDATE_TOKEN_HEADER_VAL = 'require'.toLowerCase();

export class TokenResponseInterceptor extends AbstractApiResponseInterceptor<
    TRequestParameters<TRequestOption, object>
> {
    private awaitingPromise: Promise<unknown> | null;
    private isTokenRefreshed: boolean;

    constructor() {
        super();

        this.awaitingPromise = null;
        this.isTokenRefreshed = false;
    }

    public override async intercept(
        response: IHttpClientResponse<TJson, Headers, Response>,
        requestOption?: TRequestParameters<TRequestOption, object>
    ): Promise<typeof response> {
        if (401 === response.status) {
            return this.on401(response, requestOption);
        }

        const isNeedTokenUpdate =
            response.headers.get(UPDATE_TOKEN_HEADER_KEY) === UPDATE_TOKEN_HEADER_VAL || 406 === response.status;

        if (isNeedTokenUpdate) {
            return this.onRefresh(response);
        }

        return response;
    }

    private async onRefresh(response: IHttpClientResponse<TJson, Headers, Response>): Promise<typeof response> {
        if (this.isTokenRefreshed) {
            return response;
        }

        this.isTokenRefreshed = true;
        const authSdk = AuthSdk.getInstance(CookieService.getInstance());
        try {
            await authSdk.refresh();
        } finally {
            this.isTokenRefreshed = false;
        }

        return response;
    }

    private async on401(
        response: IHttpClientResponse<TJson, Headers, Response>,
        requestOption?: TRequestParameters<TRequestOption, object> | undefined
    ): Promise<typeof response> {
        if (!requestOption) {
            return response;
        }

        if (this.awaitingPromise) {
            await this.awaitingPromise;

            return response.retry();
        }

        const authSdk = AuthSdk.getInstance(CookieService.getInstance());

        this.awaitingPromise = authSdk.getNewToken();
        await this.awaitingPromise;
        const retryResponse = await response.retry();
        this.awaitingPromise = null;

        return retryResponse;
    }

    public static instance: TokenResponseInterceptor;

    public static getInstance(): TokenResponseInterceptor {
        if (!TokenResponseInterceptor.instance) {
            TokenResponseInterceptor.instance = new TokenResponseInterceptor();
        }

        return TokenResponseInterceptor.instance;
    }
}
