import {IProps as PlaceMarkProps} from 'modules/yandex-map/components/YandexMapPlacemark';
import {YANDEX_PLACEMARK_OPTIONS_DEFAULT} from 'modules/yandex-map/constants';
import {useYandexMapService} from 'modules/yandex-map/hooks/useYandexMapService';
import {Children, isValidElement, ReactNode, useEffect, useState} from 'react';
import {Clusterer, IClustererOptions, Placemark} from 'yandex-maps';

interface IProps {
    options: IClustererOptions;
}

export const YandexMapClusterer: FC<IProps> = ({options, children}) => {
    const [cluster, setCluster] = useState<Clusterer>();
    const [currentOptions, setCurrentOptions] = useState<IClustererOptions>();
    const [currentChildren, setCurrentChildren] = useState<ReactNode>();
    const yandexMapService = useYandexMapService();

    useEffect(() => {
        const isNeedUpdate = options !== currentOptions || children !== currentChildren;

        if (isNeedUpdate) {
            setCurrentOptions(options);
            setCurrentChildren(children);

            if (cluster) {
                yandexMapService?.removeClusterer(cluster);
                setCluster(undefined);
            }
        }
    }, [children, cluster, currentChildren, currentOptions, options, yandexMapService]);

    useEffect(() => {
        if (cluster) {
            return;
        }

        const geoObjectsList: Placemark[] = [];
        yandexMapService?.removeAll();
        Children.forEach(children, (element) => {
            if (!isValidElement(element)) {
                return;
            }
            const {
                geometry,
                options: placeMarkOptions = YANDEX_PLACEMARK_OPTIONS_DEFAULT,
                onClick,
                properties,
            } = element.props as PlaceMarkProps;
            const geoObject = yandexMapService?.addPlaceMark(geometry, properties, placeMarkOptions);

            if (!geoObject) {
                return;
            }

            if (onClick) {
                yandexMapService?.addPlaceMarkEventListener(geoObject, 'click', (event) => {
                    onClick(event, yandexMapService);
                });
            }

            geoObjectsList.push(geoObject);
        });

        if (geoObjectsList.length) {
            const clusterInstance = yandexMapService?.addClusterer(geoObjectsList, options);

            setCluster(clusterInstance);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (!cluster) {
                return;
            }

            yandexMapService?.removeClusterer(cluster);
        };
    }, [children, cluster, options, yandexMapService]);

    return null;
};
