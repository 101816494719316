import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {MenuSection} from 'new-models';
import Link from 'next/link';
import React, {memo} from 'react';

import style from './style.module.scss';

export interface IPropsDropdownLinkList extends TPropsWithClassName {
    list?: MenuSection[];
}

export const DropdownLinkList = memo<IPropsDropdownLinkList>(function DropdownLinkList({className, list}) {
    return (
        <ul className={classNames(style.root, className)}>
            {list?.map(({id, title, url}: MenuSection) => (
                <li key={id}>
                    <Link href={getMenuItemUrl(url)} passHref>
                        <a className={style.link} data-popup-action="close">
                            <Typography variant="p-medium">{title}</Typography>
                        </a>
                    </Link>
                </li>
            ))}
        </ul>
    );
});
