import {MockService} from 'core/services/MockService';
import {Advice, CollectionSectionDetail, CollectionShort, FastFilter, Label, ProductService} from 'models';
import {ICheckoutContextValue} from 'modules/checkout/context';
import {TCollectionXState} from 'modules/collection-x/types';
import {TCollectionState} from 'modules/collections/types';
import {TOrdersState} from 'modules/orders/types';
import {TProductResponse} from 'modules/product-card/models/ProductResponse';
import {ISkeletonState} from 'modules/skeleton/types';
import {
    ActionShort,
    ActionTop,
    BasketDataResponse,
    BrandBase,
    BrandTop,
    Category,
    CategoryBase,
    CategoryShort,
    CollectionTop,
    ContentPageType,
    DeliveryAddress,
    DeliveryType,
    FilterChoose,
    FilterRange,
    OrderShort,
    PaymentMethodShort,
    ProductDetail,
    ProductShort,
    Promo,
    Sorting,
} from 'new-models';
import {SwiperOptions} from 'swiper/types/swiper-options';

export class SkeletonService {
    public static makeProductCardSkeleton(type: ProductDetail['type'] = 'simple'): TProductResponse['data'] {
        return {
            advices: [],
            breadcrumbs: [],
            offersByProperty: [],
            product: MockService.makeProduct(type),
            productSet: [],
            services: [],
        };
    }
    public static makeThirdCategoryLevelSkeleton(
        type: ProductDetail['type']
    ): ISkeletonState['categoryThirdLevelPageData'] {
        return {
            category: MockService.makeCategoryNode(0, type as Category['type']),
            comboFilters: MockService.repeat<FastFilter>(16, MockService.makeComboFilter),
            filters: [
                ...MockService.repeat<FilterRange>(3, () => MockService.makeFilter('range') as FilterRange),
                ...MockService.repeat<FilterChoose>(3, () => MockService.makeFilter('choose') as FilterChoose),
            ],
            productList: MockService.repeat<ProductShort>(14, () => MockService.makeProductShort(type)),
            sortList: MockService.repeat<Sorting>(4, MockService.makeSort),
        };
    }

    public static makeSecondCategoryLevelSkeleton(
        type: Category['type']
    ): ISkeletonState['categorySecondLevelPageData'] {
        return {
            category: MockService.makeCategoryNode(2, type),
            productsList: {
                ['0']: MockService.repeat<ProductShort>(10, MockService.makeProductShort),
                ['1']: MockService.repeat<ProductShort>(10, MockService.makeProductShort),
            },
        };
    }

    public static makeFirstCategoryLevelSkeleton(): ISkeletonState['categoryFirstLevelPageData'] {
        return MockService.makeCategoryNode(5);
    }

    public static makeBrandListPageSkeleton(): ISkeletonState['brandListPageData'] {
        return {
            brandList: MockService.repeat<BrandBase>(20, MockService.makeBrandBase),
            popularBrandList: MockService.repeat<BrandTop>(8, MockService.makeBrandTop),
        };
    }

    public static makeBrandPageSkeleton(): ISkeletonState['brandPageData'] {
        return {
            brandData: MockService.makeBrandDetail(),
        };
    }

    public static makePromoPageSkeleton(): ISkeletonState['promoPageData'] {
        return {
            promoData: MockService.makePromoDetail(),
        };
    }

    public static makeBreadCrumbsSkeleton(): ISkeletonState['breadCrumbsData'] {
        return MockService.repeat(5, MockService.makeShopPage);
    }

    public static makeHomePageSkeleton(): ISkeletonState['homePageData'] {
        return {
            aboutLeftSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            aboutRightSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            aboutSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            content: null,
            floristryStudioLeftSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            floristryStudioRightSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            floristryStudioSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            giftCardSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            homeAboutBanners: MockService.repeat<Promo>(1, MockService.makeSlider),
            homeAdviceList: MockService.repeat<Advice>(8, MockService.makeAdvice),
            homeBannerSecondSlideList: MockService.repeat<Promo>(1, MockService.makeSlider),
            homeBannerSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            homePrivateLabelList: MockService.repeat<Label>(8, MockService.makePrivateLabel),
            homeServiceList: MockService.repeat<ProductService>(8, MockService.makeService),
            popularBrandList: MockService.repeat<BrandTop>(8, MockService.makeBrandTop),
            popularCategoryList: MockService.repeat<CategoryShort>(5, MockService.makeShortCategory),
            popularCollectionList: MockService.repeat<CollectionTop>(4, MockService.makeCollectionTop),
            popularPromoList: MockService.repeat<ActionTop>(3, MockService.makePromo),
            popularServices: MockService.makePopularServices(),
            summerFiestaLeftSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            summerFiestaRightSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
            summerFiestaSlideList: MockService.repeat<Promo>(2, MockService.makeSlider),
        };
    }

    public static makeSearchPanelSkeleton(): ISkeletonState['searchPanelData'] {
        return {
            searchCategories: MockService.repeat<CategoryBase>(11, MockService.makeBaseCategory),
            searchProducts: MockService.repeat<ProductShort>(10, MockService.makeProductShort),
            searchSuggestions: MockService.repeat<string>(5, () => MockService.textPlaceholder(1, 10)),
        };
    }

    public static makeInfoPagesSkeleton(): ISkeletonState['contentData'] {
        return {
            content: MockService.repeat<string>(5, () => MockService.textPlaceholder(1, 50)).join('<br>'),
            image: MockService.makeImage(),
            title: MockService.textPlaceholder(1, 10),
            type: ContentPageType.Html,
        };
    }

    public static makeCartSkeleton(): BasketDataResponse {
        return {
            products: MockService.repeat<BasketDataResponse['products'][5]>(5, MockService.makeBasketItem),
            total: {
                price: {
                    cost: 300_000,
                    currency: 'RUB',
                    deliveryCost: 1000,
                    discount: 100_000,
                    totalCost: 300_000,
                },
            },
        };
    }

    public static makeCheckoutSkeleton(): ICheckoutContextValue {
        const cartData = SkeletonService.makeCartSkeleton();

        return {
            cartProductList: cartData.products,
            cartTotal: cartData.total,
            courierTimeSlotList: [],
            deliveryInfo: [],
            deliveryMethodData: {},
            deliveryMethodList: [
                MockService.makeDeliveryMethodShort(DeliveryType.PICKUP),
                MockService.makeDeliveryMethodShort(DeliveryType.COURIER),
            ],
            isCartLoading: true,
            paymentMethodData: {},
            paymentMethodList: MockService.repeat<PaymentMethodShort>(4, MockService.makePaymentMethodShort),
            pickupPointData: {},
            pickupPointList: [],
            productCount: cartData.products.length,
            userAddressList: [],
        };
    }

    public static makeCollectionPageSkeleton(): TCollectionState {
        return {
            comboFilterList: MockService.repeat<FastFilter>(16, MockService.makeComboFilter),
            count: 0,
            filterList: [
                ...MockService.repeat<FilterRange>(3, () => MockService.makeFilter('range') as FilterRange),
                ...MockService.repeat<FilterChoose>(3, () => MockService.makeFilter('choose') as FilterChoose),
            ],
            isFilterPanelVisible: false,
            list: MockService.repeat<CollectionShort>(12, MockService.makeCollection),
            listType: 'tile',
            page: 0,
            pageSize: 0,
            pageSizeList: [],
            popularCollectionList: [],
            relatedCollection: [],
            type: 'auto',
        };
    }

    public static makeCollectionsPageSkeleton(): CollectionTop[] {
        return MockService.repeat(12, MockService.makeCollectionTop);
    }

    public static makeCollectionXPageSkeleton(): TCollectionXState {
        return {
            collection: MockService.makeCollectionDetail(),
            collectionAdvices: [MockService.makeAdvice()],
            collectionSectionId: -1,
            collectionSeoText: '',
            comboFilterList: [],
            count: 0,
            filterList: [
                ...MockService.repeat<FilterRange>(3, () => MockService.makeFilter('range') as FilterRange),
                ...MockService.repeat<FilterChoose>(3, () => MockService.makeFilter('choose') as FilterChoose),
            ],
            isFilterPanelVisible: false,
            list: MockService.repeat<CollectionSectionDetail>(12, MockService.makeCollectionSectionDetail),
            listType: 'tile',
            page: 0,
            pageSize: 0,
            pageSizeList: [],
            type: 'auto',
        };
    }

    public static makeOrdersPageSkeleton(): TOrdersState {
        return {
            count: 0,
            currentCancelOrderId: null,
            currentShelfLifeOrderId: null,
            detailedOrders: {
                ['0']: MockService.makeOrderDetail(),
            },
            list: MockService.repeat<OrderShort>(2, MockService.makeOrderShort),
            listType: 'list',
            page: 0,
            pageSize: 0,
            pageSizeList: [],
            type: 'auto',
        };
    }

    public static makeDeliveryAddressesPageSkeleton(): ISkeletonState['deliveryAddresses'] {
        return {
            list: MockService.repeat<DeliveryAddress>(3, MockService.makeAddressDelivery),
        };
    }

    public static makeFavouritesPageSkeleton(): ISkeletonState['favourites'] {
        return {
            favouritesList: MockService.repeat<ProductShort>(4, MockService.makeProductShort),
        };
    }

    public static makeShopPageSkeleton(): ISkeletonState['shopListPageData'] {
        return {
            shopsData: {
                filters: [],
                stores: [],
            },
        };
    }

    public static makePromoListPageSkeleton(): ISkeletonState['promoListPageData'] {
        return {
            promoList: MockService.repeat<ActionShort>(12, MockService.makeActionShort),
        };
    }

    public static makeSkeletonItem(element: HTMLElement) {
        // element.querySelectorAll('[data-skeleton-item]').forEach((item) => {
        //     (item as HTMLElement).style.height = `${item.clientHeight}px`;
        //     item.innerHTML = '';
        // });

        element.querySelectorAll('.swiper').forEach((item) => {
            (item as Element & {swiper: SwiperOptions}).swiper.init = false;
            (item as Element & {swiper: SwiperOptions}).swiper.enabled = false;
        });
    }

    public static makeOnlinePaymentPageSkeleton(): ISkeletonState['onlinePaymentData'] {
        return {
            order: MockService.makeOrderDetail(),
        };
    }

    public static makeThanksForYourOrderPageSkeleton(): ISkeletonState['thanksForYourOrderPageData'] {
        return {
            orderData: MockService.makeOrderDetail(),
        };
    }
}
