import {DashboardLayout} from 'layouts/DashboardLayout';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {OrderXPageContext} from 'modules/orders/context';
import {OrderXPage} from 'modules/orders/pages/OrderXPage';
import {ordersSlice} from 'modules/orders/slice';
import {ORDERS_SKELETON_PAGE_DATA} from 'modules/skeleton/constants';
import {ProfileUserMenu} from 'modules/user/components/ProfileUserMenu';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import React, {FC, useMemo} from 'react';

export const DashboardOrderXSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.profile);

    const valueOrderXPage = useMemo(() => {
        return {
            orderData: Object.values(ORDERS_SKELETON_PAGE_DATA.detailedOrders)[0],
        };
    }, []);

    return (
        <SliceProvider slice={ordersSlice}>
            <OrderXPageContext.Provider value={valueOrderXPage}>
                <DashboardLayout sidebar={<ProfileUserMenu />}>
                    <OrderXPage />
                </DashboardLayout>
            </OrderXPageContext.Provider>
        </SliceProvider>
    );
};
