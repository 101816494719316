import {INFO_BLOCK_TYPE, InfoBlock} from 'components/InfoBlock';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import {selectIsLoggedIn} from 'modules/user/selectors';
import {BasketItem} from 'new-models';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

interface ICartInfoBlockProps {
    cartProductsList: BasketItem[];
}

export const CartInfoBlock = memo<ICartInfoBlockProps>(function CartInfoBlock({cartProductsList}) {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const handleProfileClick = useProfileClick();
    const isProductLimit = useMemo(() => {
        let result = false;

        for (let i = 0; i < cartProductsList.length; i++) {
            const inBasket = cartProductsList[i].quantity ?? 0;
            const availabilityCount = cartProductsList[i].product.availabilityCount ?? 0;
            const availability = cartProductsList[i].product.availability;

            if (inBasket > availabilityCount && availability) {
                result = true;
            }
        }

        return result;
    }, [cartProductsList]);

    return useMemo(() => {
        if (isProductLimit) {
            return (
                <InfoBlock
                    className={style.infoBlock}
                    contentText="В корзине есть товары у которых отсутствует необходимое количество. Для оформления заказа, измените количество товара на доступное."
                    isHideIcon
                    type={INFO_BLOCK_TYPE.warning}
                />
            );
        }

        if (!isLoggedIn) {
            return (
                <InfoBlock
                    buttonText="Войти или зарегистрироваться"
                    className={style.infoBlock}
                    contentText="Войдите в аккаунт или зарегистрируйтесь, чтобы получить доступ к личным данным и статусам заказов"
                    contentTextVariant="p-regular"
                    isHideIcon
                    onClick={handleProfileClick}
                />
            );
        }

        return null;
    }, [isLoggedIn, isProductLimit, handleProfileClick]);
});
