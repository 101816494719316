import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectIsLoggedIn} from 'modules/user/selectors';
import {getUser} from 'modules/user/thunks';
import {useEffect} from 'react';

/**
 * Выполнение реквестов завязанных на приватный токен
 */
export const useRequestPrivateData = () => {
    // TODO: вынести в хук useIsLoggedIn
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        dispatch(getUser);
    }, [dispatch, isLoggedIn]);
};
