import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {Typography} from 'components/Typography';
import {formatPhoneForView} from 'core/regex';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {ContactField} from 'modules/user/components/ContactField';
import {useProfileEmailChangeModalApi} from 'modules/user/hooks/useProfileEmailChangeModalApi';
// import {useProfilePasswordChangeModalApi} from 'modules/user/hooks/useProfilePasswordChangeModalApi';
import {useProfilePhoneChangeModalApi} from 'modules/user/hooks/useProfilePhoneChangeModalApi';
import {Profile} from 'new-models';
import dynamic from 'next/dynamic';
import React, {memo} from 'react';

const PhoneChangeModal = dynamic(() => import('modules/user/components/PhoneChangeModal'), {
    loading: () => <ModalLazyLoader />,
});

const EmailChangeModal = dynamic(() => import('modules/user/components/EmailChangeModal'), {
    loading: () => <ModalLazyLoader />,
});

const PasswordChangeModal = dynamic(() => import('modules/user/components/PasswordChangeModal'), {
    loading: () => <ModalLazyLoader />,
});

// import {EmailStatusInfoBlock} from 'modules/user/components/EmailStatusInfoBlock';

import {RemoveAccount} from '../RemoveAccount';
import style from './style.module.scss';

const columnStyle = getGridColumnStyles();
const contactsColumnStyle = getGridColumnStyles({md: 6});

interface IProps {
    className?: string;
    userData: Profile;
}

export const ContactData = memo<IProps>(function ContactData({className, userData}) {
    const {open: openPhoneChangeModal} = useProfilePhoneChangeModalApi();
    const {open: openEmailChangeModal} = useProfileEmailChangeModalApi();
    // const {open: openPasswordChangeModal} = useProfilePasswordChangeModalApi();

    const {email, phone} = userData;

    return (
        <>
            <Grid className={classNames(style.root, className)}>
                <Grid className={classNames(style.header, columnStyle)}>
                    <Typography className={columnStyle} color="gray100" data-skeleton-item element="h5" variant="h5">
                        Контакты
                    </Typography>
                    {/* <EmailStatusInfoBlock email={email} isEmailVerified={isEmailVerified} /> */}
                </Grid>
                <Grid className={columnStyle}>
                    <ContactField
                        allowEdit={false}
                        className={contactsColumnStyle}
                        content={phone ? formatPhoneForView(phone) : null}
                        onClick={openPhoneChangeModal}
                        title="Номер телефона"
                    />
                    <ContactField
                        className={contactsColumnStyle}
                        content={userData.email}
                        onClick={openEmailChangeModal}
                        title="Email"
                    />
                </Grid>
                {/* {isLegal && (
                    <Grid className={columnStyle}>
                        <Typography
                            className={columnStyle}
                            color="gray80"
                            data-skeleton-item
                            element="h5"
                            variant="h5-bold"
                        >
                            Пароль
                        </Typography>
                        <ContactField
                            className={contactsColumnStyle}
                            content="**********"
                            onClick={openPasswordChangeModal}
                            title="Пароль"
                        />
                    </Grid>
                )} */}
                <RemoveAccount className={style.removeAccount} />
            </Grid>
            <Modal name={MODALS.PROFILE_CHANGE_PHONE_MODAL.name} willCloseOnUrlChange willMount>
                <PhoneChangeModal currentPhone={phone} title="Изменить номер телефона" />
            </Modal>
            <Modal name={MODALS.PROFILE_CHANGE_EMAIL_MODAL.name} willCloseOnUrlChange willMount>
                <EmailChangeModal currentEmail={email} title="Изменить email" />
            </Modal>
            <Modal name={MODALS.PROFILE_PASSWORD_CHANGE.name} willCloseOnUrlChange willMount>
                <PasswordChangeModal title="Изменить пароль" />
            </Modal>
        </>
    );
});
