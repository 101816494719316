import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {DEFAULT_LOCATION_ID, IS_GEOLOCATION_ON, USER_LOCATION} from 'modules/locations/constants';
import {selectLocationsList} from 'modules/locations/selectors';
import {actionSetIsLocationSelected, actionSetUserLocation} from 'modules/locations/slice';
import {Location} from 'new-models';
import {useCallback} from 'react';

export const useSetDefaultLocation = (): (() => void) => {
    const dispatch = useAppDispatch();

    const locations = useAppSelector(selectLocationsList);

    return useCallback(() => {
        const localLocationId = localStorage.getItem(USER_LOCATION) as Location['id'];
        const localLocation = locations?.find(({id}) => id === localLocationId);
        const defaultLocation = locations?.find(({id}) => id === DEFAULT_LOCATION_ID) ?? locations?.[0];

        localStorage.removeItem(IS_GEOLOCATION_ON);

        if (localLocation) {
            dispatch(actionSetUserLocation(localLocation));
            dispatch(actionSetIsLocationSelected(true));
            return;
        }

        if (!defaultLocation?.id) {
            return;
        }

        dispatch(actionSetUserLocation(defaultLocation));
        dispatch(actionSetIsLocationSelected(true));
        localStorage.setItem(USER_LOCATION, defaultLocation.id);
    }, [dispatch, locations]);
};
