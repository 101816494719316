import {getRuntimeConfig} from 'core/next/helpers';

export const daDataHeaders = {
    headers: {
        ['Accept']: 'application/json',
        ['Authorization']: `Token ${getRuntimeConfig().NEXT_PUBLIC_DADATA_API_KEY}`,
        ['Content-Type']: 'application/json',
    },
};

export const DA_DATA_TYPES = {
    address: 'address',
    party: 'party',
} as const;
