import {getRuntimeConfig} from 'core/next/helpers';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectMenuList} from 'modules/menu/selectors';
import {MenuPlaceEnum} from 'new-models';
import {useRouter} from 'next/router';
import {useCallback, useMemo} from 'react';
import {DASHBOARD_ROOT_ROUTE, LOGOUT_ROUTE} from 'routing/constants';

export const useMenuList = () => {
    const router = useRouter();
    const menus = useAppSelector(selectMenuList);

    const currentMenuList = useMemo(
        () => menus.find((menu) => menu.place === MenuPlaceEnum.INDIVIDUAL_USER)?.sections,
        [menus]
    );

    const getMenuItemHref = useCallback(
        (path: string | undefined) => (LOGOUT_ROUTE === path ? path : `${DASHBOARD_ROOT_ROUTE}/${path}`),
        []
    );

    const checkForActive = useCallback((path: string) => router.pathname.includes(path as string), [router.pathname]);

    const getIconSrc = useCallback((path: string) => `${getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST}/${path}`, []);

    return {checkForActive, currentMenuList, getIconSrc, getMenuItemHref};
};
