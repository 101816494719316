import {TAppRootState} from 'core/redux/types';
import {IEntity} from 'core/types';

export const getListFromNormalizedData = <T extends IEntity>(data: Record<PropertyKey, T>, list: string[]) =>
    list.map<T>((id) => data[id]);

export const selectSection =
    (section: string) =>
    <T extends any>(selectModule: (state: TAppRootState) => any) =>
    (state: TAppRootState): T =>
        selectModule(state)[section];

export const selectData = selectSection('data');

export const selectIdList = selectSection('list');

export const selectItem =
    <T extends IEntity>(data: (state: TAppRootState) => Record<PropertyKey, T>) =>
    (state: TAppRootState, id: number | string) =>
        data(state)[id] || null;
