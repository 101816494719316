import {getRuntimeConfig} from 'core/next/helpers';
import {IMethodParams, TMethodName} from 'modules/analytics/types';

export class MindBoxScriptService {
    public static MINDBOX_SCRIPT_TAG_ID = 'mindbox-script';
    public static MINDBOX_SCRIPT_SRC = getRuntimeConfig().NEXT_PUBLIC_MINDBOX_SCRIPT_SRC ?? '';
    public static MINDBOX_ENDPOINT_ID = getRuntimeConfig().NEXT_PUBLIC_MINDBOX_ENDPOINT_ID;

    public static connectScript = (): Promise<boolean> => {
        return new Promise((resolve) => {
            if (
                document.querySelector(`#${MindBoxScriptService.MINDBOX_SCRIPT_TAG_ID}`) ||
                global.mindbox !== undefined
            ) {
                resolve(true);
                return;
            }

            MindBoxScriptService.initPlug();
            MindBoxScriptService.createMindbox();

            const scriptTag = MindBoxScriptService.prepareScriptTag();

            scriptTag.addEventListener('load', () => {
                if (global !== undefined) {
                    resolve(true);
                    return;
                }
                resolve(false);
            });
            scriptTag.addEventListener('error', () => resolve(false));

            MindBoxScriptService.attachScript(scriptTag);
        });
    };

    public static initPlug = (): void => {
        global.mindbox =
            global.mindbox ||
            function () {
                if (global.mindbox.queue) {
                    global.mindbox.queue.push(arguments);
                }
            };
        global.mindbox.queue = global.mindbox.queue || [];
    };

    public static prepareScriptTag = (): HTMLScriptElement => {
        const scriptTag = document.createElement('script');
        scriptTag.id = MindBoxScriptService.MINDBOX_SCRIPT_TAG_ID;
        scriptTag.async = true;
        scriptTag.src = MindBoxScriptService.MINDBOX_SCRIPT_SRC;
        return scriptTag;
    };

    public static createMindbox = (): void => {
        global.mindbox('create', {
            endpointId: MindBoxScriptService.MINDBOX_ENDPOINT_ID,
        });
    };

    public static attachScript(script: HTMLScriptElement): void {
        document.head.append(script);
    }

    public post(methodName: TMethodName, methodParams: IMethodParams) {
        if (global.mindbox) {
            global.mindbox(methodName, methodParams);
        }
    }
}
