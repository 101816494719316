import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBarcodeScannerState} from 'modules/barcode-scanner/types';

export const barcodeScannerModuleName = 'barcode-scanner';

const initialState: IBarcodeScannerState = {
    isTorchOn: false,
};

export const barcodeScannerSlice = createSlice({
    initialState,
    name: barcodeScannerModuleName,
    reducers: {
        actionReSetPermission(state) {
            state.isPermitted = undefined;
        },

        actionSetBarCode(state, {payload}: PayloadAction<IBarcodeScannerState['barCode']>) {
            state.barCode = payload;
        },
        actionSetPermissionAllowed(state) {
            state.isPermitted = true;
        },

        actionSetPermissionDenied(state) {
            state.isPermitted = false;
        },

        actionTorchOff(state) {
            state.isTorchOn = false;
        },

        actionTorchOn(state) {
            state.isTorchOn = true;
        },
    },
});

export const {
    actions: {
        actionSetPermissionDenied,
        actionSetPermissionAllowed,
        actionSetBarCode,
        actionReSetPermission,
        actionTorchOn,
        actionTorchOff,
    },
} = barcodeScannerSlice;

export const {reducer: barcodeScannerReducer} = barcodeScannerSlice;
