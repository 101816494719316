import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {actionSetActive} from 'modules/bottombar/slice';
import {TActiveItem} from 'modules/bottombar/types';
import {useEffect} from 'react';

export const useBottomBar = (activeItem: TActiveItem) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionSetActive(activeItem));
    }, [activeItem, dispatch]);
};
