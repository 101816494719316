import {Video} from 'components/Video';
import {VideoRutube} from 'components/VideoRutube';
import {IVideoYoutubeProps, VideoYoutube} from 'components/VideoYoutube';
import {TImageResizeCode} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {AdaptiveImage, IProps as IAdaptiveImageProps} from 'modules/images/components/AdaptiveImage';
import {SimpleImage} from 'modules/images/components/SimpleImage';
import {
    MediaGroupIcon,
    MediaGroupImage,
    MediaGroupVisual,
    MediaIcon,
    MediaImage,
    MediaImageAdaptive,
    MediaTypeEnum,
    MediaVideo,
    MediaYoutube,
} from 'new-models';
import React, {CSSProperties, forwardRef, HTMLAttributes, memo, RefObject} from 'react';

export interface IOptionsMediaHarvesterProps {
    adaptiveImage?: Omit<IAdaptiveImageProps, 'className' | 'image'>;
    imageResizeCode?: TImageResizeCode;
    iconStyle?: CSSProperties;
}

export interface IProps extends TPropsWithClassName {
    media?: MediaGroupIcon | MediaGroupVisual | MediaGroupImage;
    title?: string;
    isPreview?: boolean;
    options?: IOptionsMediaHarvesterProps;
    optionsVideo?: HTMLAttributes<HTMLVideoElement>;
    optionsYoutube?: IVideoYoutubeProps;
}

export const MediaHarvester = memo(
    forwardRef<HTMLVideoElement | HTMLDivElement, IProps>(function MediaHarvester(
        {className, media, title, options, optionsVideo, optionsYoutube, isPreview = false},
        ref
    ) {
        if (!media) {
            return null;
        }

        switch (media.type) {
            case MediaTypeEnum.ImageAdaptive: {
                return (
                    <AdaptiveImage
                        className={className}
                        image={media as MediaImageAdaptive}
                        title={title}
                        {...options?.adaptiveImage}
                    />
                );
            }
            case MediaTypeEnum.Icon: {
                const icon = media as MediaIcon;
                return (
                    <div
                        className={className}
                        style={{maskImage: `url(${icon.url})`, maskSize: 'contain', ...options?.iconStyle}}
                    />
                );
            }
            case MediaTypeEnum.Image: {
                return (
                    <SimpleImage
                        className={className}
                        imageResizeCode={options?.imageResizeCode}
                        media={media as MediaImage | MediaIcon}
                        title={title}
                    />
                );
            }
            case MediaTypeEnum.Youtube: {
                return (
                    <VideoYoutube
                        className={className}
                        ref={ref as RefObject<HTMLDivElement>}
                        {...(media as MediaYoutube)}
                        {...optionsYoutube}
                    />
                );
            }
            case MediaTypeEnum.Rutube: {
                return <VideoRutube className={className} isPreview={isPreview} url={(media as MediaVideo).url} />;
            }
            case MediaTypeEnum.Video: {
                return <Video media={media as MediaVideo} ref={ref as RefObject<HTMLVideoElement>} {...optionsVideo} />;
            }
            default: {
                console.error(`Unknown media type ${media.type} for viewing`);
                return null;
            }
        }
    })
);
