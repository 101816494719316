import {ISegmentControlValueList} from 'components/SegmentedControl';

export const MAP_TAB: ISegmentControlValueList = {
    id: 0,
    label: 'Карта',
    value: 'map',
};

export const LIST_TAB: ISegmentControlValueList = {
    id: 1,
    label: 'Список',
    value: 'list',
};

export const TABS_LIST: ISegmentControlValueList[] = [MAP_TAB, LIST_TAB];

export const DEFAULT_SELECTED_TAB_ID = 0;
