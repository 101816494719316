import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DEFAULT_LIMITS, DEFAULT_TOTAL, REMOVE_MODAL_VIEW} from 'modules/cart/constants';
import {ICartState} from 'modules/cart/types';
import {BasketDataResponse} from 'new-models';

export const cartModuleName = 'cart';

const initialState: ICartState = {
    afterRemoveToast: '',
    deliveryInfo: [],
    isLegalEntity: false,
    isOpenAvailabilities: false,
    limits: DEFAULT_LIMITS,
    productsList: [],
    removeId: null,
    removeModalView: REMOVE_MODAL_VIEW.ASSIGNED,
    selectedProductId: null,
    total: DEFAULT_TOTAL,
};

export const cartSlice = createSlice({
    initialState,
    name: cartModuleName,
    reducers: {
        actionSetAfterRemoveToast(state: ICartState, {payload}: PayloadAction<ICartState['afterRemoveToast']>) {
            state.afterRemoveToast = payload;
        },

        actionSetCart(state: ICartState, {payload}: PayloadAction<BasketDataResponse | undefined>) {
            state.productsList = payload?.products ?? [];
            state.total = payload?.total ?? DEFAULT_TOTAL;
            state.limits.minCost = payload?.limits?.minCost ?? 0;
        },

        actionSetDeliveryInfo(state: ICartState, {payload}: PayloadAction<ICartState['deliveryInfo']>) {
            state.deliveryInfo = payload;
        },

        actionSetIsOpenAvailabilities(state: ICartState, {payload}: PayloadAction<ICartState['isOpenAvailabilities']>) {
            state.isOpenAvailabilities = payload;
        },

        actionSetLegalStatus(state: ICartState, {payload}: PayloadAction<ICartState['isLegalEntity']>) {
            state.isLegalEntity = payload;
        },

        actionSetRemoveId(state: ICartState, {payload}: PayloadAction<ICartState['removeId']>) {
            state.removeId = payload;
        },

        actionSetRemoveModalView(state: ICartState, {payload}: PayloadAction<ICartState['removeModalView']>) {
            state.removeModalView = payload;
        },
        actionSetSelectedProductId(state: ICartState, {payload}: PayloadAction<ICartState['selectedProductId']>) {
            state.selectedProductId = payload;
        },
    },
});

export const {
    actions: {
        actionSetAfterRemoveToast,
        actionSetIsOpenAvailabilities,
        actionSetRemoveId,
        actionSetRemoveModalView,
        actionSetCart,
        actionSetLegalStatus,
        actionSetSelectedProductId,
        actionSetDeliveryInfo,
    },
} = cartSlice;

export const {reducer: cartReducer} = cartSlice;
