import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse} from 'core/types';
import {actionDeleteFromFavourite, actionPushToFavourite, actionSetFavouritesList} from 'modules/favourites/slice';
import {FavouritesCreateDataRequest, FavouritesDataResponse, ProductShort} from 'new-models';

export const getFavoritesList: TAppThunk<void> = async (dispatch, _, {favouritesService}) => {
    const data = await dispatch(
        requestAction<TResponse<FavouritesDataResponse>>({
            requestCb: () => favouritesService.getFavouritesList(),
            type: actionSetFavouritesList.type,
        })
    );

    dispatch(actionSetFavouritesList(data?.favourites ?? []));
};

export const addToFavouritesList =
    (product: ProductShort): TAppThunk<void> =>
    async (dispatch, _, {favouritesService}) => {
        await dispatch(
            requestAction<TResponse<FavouritesDataResponse>>({
                requestCb: () => favouritesService.addToFavouritesList({productId: product.id}),
                type: actionPushToFavourite.type,
            })
        );
        dispatch(actionPushToFavourite(product));
    };

export const removeProductInFavouritesList =
    ({productId}: FavouritesCreateDataRequest): TAppThunk<void> =>
    async (dispatch, _, {favouritesService}) => {
        await dispatch(
            requestAction<TResponse<FavouritesDataResponse>>({
                requestCb: () => favouritesService.removeProductInFavouritesList({productId}),
                type: actionDeleteFromFavourite.type,
            })
        );
        dispatch(actionDeleteFromFavourite({productId}));
    };
