import classNames from 'classnames';
import {BackButton} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
// import {OnlyMobile} from 'components/OnlyMobile';
import {Typography} from 'components/Typography';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire} from 'core/types';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {LoginWarning} from 'modules/user/components/LoginWarning';
// import {MobileProfileMenu} from 'modules/user/components/MobileProfileMenu';
import {useMenuList} from 'modules/user/hooks/useMenuList';
import {selectIsLoggedIn} from 'modules/user/selectors';
import React, {memo, ReactNode, useCallback, useMemo} from 'react';
import {PROFILE_ORDER_PATH} from 'routing/constants';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();
const sidebarColumnStyle = getGridColumnStyles({md: 3});
const contentColumnStyle = getGridColumnStyles({md: 9});

interface IProps extends TPropsWithChildrenRequire {
    sidebar?: ReactNode;
}

export const DashboardLayout = memo<IProps>(function DashboardLayout({sidebar = null, children}) {
    useBottomBar(BOTTOM_BAR_BUTTONS.profile);

    const isMobile = useIsMobile();
    const isNeedTitle = !useIsNeedRoute([PROFILE_ORDER_PATH]);

    const dispatch = useAppDispatch();
    const {currentMenuList, checkForActive} = useMenuList();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const renderTitle = useMemo<string>(() => {
        if (!currentMenuList || !isMobile) {
            return 'Мой профиль';
        }

        const activeItem = currentMenuList.find((item) => checkForActive(item.url?.value || '')) || currentMenuList[0];

        return activeItem.title;
    }, [checkForActive, currentMenuList, isMobile]);

    const renderContent = useMemo<ReactNode>(() => {
        if (isLoggedIn) {
            return (
                <Grid>
                    {sidebar && <div className={classNames(style.sidebar, sidebarColumnStyle)}>{sidebar}</div>}
                    <div className={classNames(style.pageContent, sidebar ? contentColumnStyle : columnStyle)}>
                        {children}
                    </div>
                </Grid>
            );
        }

        return <LoginWarning />;
    }, [children, isLoggedIn, sidebar]);

    const handleOpenMenu = useCallback(() => dispatch(showModal(MODALS.AUTH_MENU.name)), [dispatch]);

    return (
        <Grid className={style.layoutContainer} container>
            {isNeedTitle && (
                <div className={classNames(style.layoutTitle, columnStyle)}>
                    <BackButton isOnlyAdaptive onClick={handleOpenMenu} theme="ghost" />
                    <Typography color="gray100" data-skeleton-item element="div" variant="h2">
                        {renderTitle}
                    </Typography>
                </div>
            )}

            {/* {isLoggedIn && (
                <OnlyMobile className={columnStyle}>
                    <MobileProfileMenu activeMenuItem={activeItem} menuList={PROFILE_USER_MENU_LIST} />
                </OnlyMobile>
            )} */}
            <section className={classNames(style.content, columnStyle)}>{renderContent}</section>
        </Grid>
    );
});
