import classNames from 'classnames';
import {BackButton} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {isMobileByScreen} from 'core/helpers';
import {TClosureCallback} from 'core/types';
import {useViewProduct} from 'modules/analytics/hooks/useViewProduct';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {GalleryBlock} from 'modules/product-card/components/GalleryBlock';
import {MainInfo} from 'modules/product-card/components/MainInfo';
import {NavigationTabs} from 'modules/product-card/components/NavigationTabs';
import {ProductCardCollections} from 'modules/product-card/components/ProductCardCollections';
import {ProductCardHeader} from 'modules/product-card/components/ProductCardHeader';
import {ProductCardSidebar} from 'modules/product-card/components/ProductCardSidebar';
import {ProductCardTitle} from 'modules/product-card/components/ProductCardTitle';
import {ShowMoreImagesLink} from 'modules/product-card/components/ShowMoreImagesLink';
import {
    MAX_IMAGES_NUMBER,
    NAV_USED_IN,
    NAVIGATION_TABS_TYPE,
    PRODUCT_PAGE_GRID_LAYOUT,
} from 'modules/product-card/constants';
import {ProductPageContext} from 'modules/product-card/context';
import {useGetVideoList} from 'modules/product-card/hooks/useGetVideoList';
import {useRenderHeaderHeight} from 'modules/product-card/hooks/useRenderHeaderHeight';
import {useRenderSliderImages} from 'modules/product-card/hooks/useRenderSliderImages';
import {useSetActiveFirstSlide} from 'modules/product-card/hooks/useSetActiveFirstSlide';
import {TNavigationTabs} from 'modules/product-card/types';
import {PRODUCT_TYPE} from 'modules/products/constants';
import {useIsProductAvailable} from 'modules/products/hooks/useIsProductAvailable';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {SimilarProductsSlider} from 'modules/slider/components/SimilarProductsSlider';
import {ViewedProductsSlider} from 'modules/slider/components/ViewedProductsSlider';
import dynamic from 'next/dynamic';
import React, {FC, TouchEventHandler, useCallback, useContext, useMemo, useState} from 'react';
import {useInView} from 'react-intersection-observer';

import style from './style.module.scss';

const {contentBlock, priceBlock} = PRODUCT_PAGE_GRID_LAYOUT;

const contentColumnStyle = getGridColumnStyles({
    md: contentBlock,
});
const priceBlockColumnStyle = getGridColumnStyles({
    md: priceBlock,
});

const MainGalleryModal = dynamic(() => import('modules/product-card/components/MainGalleryModal'), {
    loading: () => <ModalLazyLoader />,
});

// TODO: for the time of mixing two mock servers
// const ProductSetModal = dynamic(() => import('modules/product-card/components/ProductSetModal'), {
//     loading: () => <ModalLazyLoader />,
// });
//
// const CalculatorModal = dynamic(() => import('modules/calculators/components/CalculatorModal'), {
//     loading: () => <ModalLazyLoader />,
// });

export const ProductPage: FC = () => {
    const {productImages, galleryMode, productData, productDeliveryInfo} = useContext(ProductPageContext);

    const product = productData?.product;
    const collections = product?.collections;

    useViewProduct(product);
    useSetActiveFirstSlide();

    const renderHeaderHeight = useRenderHeaderHeight();
    const isSkeleton = useIsSkeleton();

    const rootMargin = `${renderHeaderHeight}px 0% 5000px 0%`;

    const isProductAvailable = useIsProductAvailable(product);
    const sliderImages = useRenderSliderImages(product);
    const {allVideos} = useGetVideoList(product);

    const {ref: navTabsRef, inView: navTabInView} = useInView({
        // TODO: пара грязных трюков, чтобы работало
        rootMargin,
    });

    const {ref: actionsRef, inView: actionsInView} = useInView({
        rootMargin,
    });

    const [touchedTabs, setTouchedTabs] = useState<TNavigationTabs>();

    const onTouchStart = useCallback<TClosureCallback<TNavigationTabs, TouchEventHandler<HTMLDivElement>>>(
        (tabsName) => () => setTouchedTabs(tabsName),
        []
    );

    const isProductHeaderVisible = useMemo(
        () => !navTabInView || (!isMobileByScreen() && !actionsInView),
        [actionsInView, navTabInView]
    );

    if (!product || !productData) {
        return null;
    }

    return (
        <>
            <div className={style.backButtonContainer}>
                <BackButton />
            </div>
            <section
                className={classNames(style.productPage, {
                    [style.visual]: product.type === PRODUCT_TYPE.visual,
                })}
            >
                {!isSkeleton && (
                    <ProductCardHeader
                        className={classNames(style.header, {
                            [style.visible]: isProductHeaderVisible,
                        })}
                        leftNode={
                            <div onTouchStart={onTouchStart(NAVIGATION_TABS_TYPE.fixed)}>
                                <NavigationTabs
                                    isActive={touchedTabs === NAVIGATION_TABS_TYPE.fixed}
                                    product={product}
                                    usedIn={NAV_USED_IN.header}
                                />
                            </div>
                        }
                        product={product}
                    />
                )}
                {/* TODO: For another type product*/}
                {/* <VisualCardMainImage className={style.visualCardMainImage} mainImage={productImages[0]} product={product} />*/}
                <Grid className={style.mainContainer} container noRowGap>
                    <div className={contentColumnStyle}>
                        <GalleryBlock
                            className={classNames({
                                [style[product.type]]: Boolean(product.type),
                            })}
                            galleryMode={galleryMode}
                            sliderImages={sliderImages}
                            sliderVideos={allVideos}
                            {...product}
                        />
                        {productImages.length >= MAX_IMAGES_NUMBER && product.type === PRODUCT_TYPE.visual && (
                            <ShowMoreImagesLink className={style.productImagesShowMore} />
                        )}
                    </div>
                    <ProductCardSidebar
                        className={priceBlockColumnStyle}
                        isVisualCard={product.type === PRODUCT_TYPE.visual}
                        product={product}
                        productDeliveryInfo={productDeliveryInfo}
                        renderTitle={<ProductCardTitle {...product} />}
                        {...(!isMobileByScreen() && {actionsRef})}
                    />
                </Grid>

                <Grid container>
                    <div className={classNames(contentColumnStyle, style.secondContainer)}>
                        <div
                            data-skeleton-item
                            onTouchStart={onTouchStart(NAVIGATION_TABS_TYPE.static)}
                            ref={navTabsRef}
                        >
                            <NavigationTabs
                                className={style.mainNavTabs}
                                isActive={touchedTabs === NAVIGATION_TABS_TYPE.static}
                                product={product}
                                usedIn={NAV_USED_IN.main}
                            />
                        </div>
                        <MainInfo isProductAvailable={isProductAvailable} product={product} />
                    </div>
                </Grid>

                <div className={style.more}>
                    <ProductCardCollections collections={collections} />
                    <SimilarProductsSlider className={style.sliderBlock} />
                    <ViewedProductsSlider className={style.sliderBlock} />
                </div>

                {Boolean(sliderImages.length) && (
                    <Modal name={MODALS.MAIN_GALLERY.name} shouldCloseByEscape willCloseOnUrlChange willMount>
                        <MainGalleryModal images={sliderImages} isSliderOnMobile product={product} videos={allVideos} />
                    </Modal>
                )}
            </section>
        </>
    );
};
