import {GALLERY_MODE} from 'modules/slider/constants';
import {TGalleryMode} from 'modules/slider/types';
import {MediaImage, MediaYoutube} from 'new-models';

export const getSlidesList = (images: MediaImage[], videos?: MediaYoutube[]) => {
    let sliderArray: (MediaImage | MediaYoutube)[] = [];

    if (videos?.length) {
        sliderArray = videos;
    }

    if (images?.length) {
        sliderArray = [...sliderArray, ...images];
    }

    return sliderArray;
};

export const setActiveSlideIdx = (videos?: MediaYoutube[]): number => (videos ? videos.length : 0);

export const isNeedPauseVideo = (isActive?: boolean, isAnyModalVisible?: boolean, mode?: TGalleryMode) => {
    return isActive && isAnyModalVisible && mode === GALLERY_MODE.basic;
};
