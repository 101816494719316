import {BackButton} from 'components/BackButton';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {LOGOUT_ITEM, PROFILE_USER_MENU_LIST} from 'modules/user/constants';
import {useMenuList} from 'modules/user/hooks/useMenuList';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {FC, useCallback} from 'react';
import {HOME_ROUTE} from 'routing/constants';

import style from './style.module.scss';

export const MobileAuthMenu: FC = () => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const {getMenuItemHref} = useMenuList();

    const handleCloseMenu = useCallback(
        (withRedirectToHome: boolean) => {
            return () => {
                dispatch(hideModal(MODALS.AUTH_MENU.name));

                if (withRedirectToHome) {
                    router.push(HOME_ROUTE);
                }
            };
        },
        [dispatch, router]
    );

    return (
        <div className={style.menu}>
            <div className={style.menuTop}>
                <BackButton className={style.menuBack} onClick={handleCloseMenu(true)} theme="ghost" />
                <Typography className={style.title} color="gray100" element="h2" variant="h2">
                    Мой профиль
                </Typography>
            </div>
            <ul className={style.menuList}>
                {PROFILE_USER_MENU_LIST.map(({url, text, icon}) => (
                    <li className={style.menuItem} onClick={handleCloseMenu(false)}>
                        <Link href={url} key={url as string} passHref>
                            <a className={style.menuLink}>
                                <Typography className={style.linkContent} color="gray80" variant="p">
                                    <img alt={text} src={icon?.url} />
                                    <span>{text}</span>
                                </Typography>
                            </a>
                        </Link>
                    </li>
                ))}
                <li className={style.menuItem} key={LOGOUT_ITEM.url}>
                    <Link href={getMenuItemHref(LOGOUT_ITEM.url)} passHref shallow>
                        <a className={style.menuLink}>
                            <Typography className={style.linkContent} color="gray80" variant="p">
                                <SvgIcon svg={LOGOUT_ITEM.icon} />
                                <span>{LOGOUT_ITEM.text}</span>
                            </Typography>
                        </a>
                    </Link>
                </li>
            </ul>
        </div>
    );
};
