import classNames from 'classnames';
import {LogoLoader} from 'components/Loader';
import {SearchInput} from 'components/SearchInput';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {selectIsLocationsListLoading, selectLocationsList} from 'modules/locations/selectors';
import {Location} from 'new-models';
import checkMarkIcon from 'public/icons/check-mark.svg';
import React, {ChangeEventHandler, FC, useCallback, useId, useMemo, useState} from 'react';

import style from './style.module.scss';

const MIN_CITY_COUNT = 10;

export interface IProps extends TPropsWithClassName {
    itemClassName?: string;
    locationNameClassName?: string;
    listClassName?: string;
    inputClassName?: string;
    searchInputContainerClassName?: string;
    infoBlockClassName?: string;

    checkedLocationId?: Location['id'];
    onSelect: (location: Location | null) => void;
    disabled?: boolean;
}

const InfoItem: FC<TPropsWithChildrenRequire & TPropsWithClassName> = ({className, children}) => (
    <li className={classNames(style.infoItem, className)}>
        <Typography className={style.infoText} variant="p-medium">
            {children}
        </Typography>
    </li>
);

export const LocationsList: FC<IProps> = ({
    itemClassName,
    locationNameClassName,
    disabled = false,
    checkedLocationId,
    className,
    listClassName,
    inputClassName,
    searchInputContainerClassName,
    infoBlockClassName,
    onSelect,
}) => {
    const uid = useId();

    const isLocationListLoading = useAppSelector(selectIsLocationsListLoading);

    const locationsList = useAppSelector(selectLocationsList);

    const [searchLocation, setSearchLocation] = useState('');

    const isShowSearchInput = MIN_CITY_COUNT < (locationsList?.length ?? 0);

    const handleLocationChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const target = event.target;
            const location = locationsList?.find(({id}) => id === target.value);
            onSelect(location ?? null);
        },
        [locationsList, onSelect]
    );

    const handleSearchLocationChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setSearchLocation(event.target.value);
    }, []);

    const handleSearchLocationReset = useCallback(() => {
        setSearchLocation('');
    }, []);

    const filteredList = useMemo(() => {
        if (!locationsList) {
            return [];
        }
        if (!searchLocation) {
            return locationsList;
        }

        return locationsList?.filter((location) =>
            location.title?.toLowerCase().includes(searchLocation.toLowerCase())
        );
    }, [locationsList, searchLocation]);

    return (
        <div className={classNames(style.root, className)}>
            {isLocationListLoading && <LogoLoader />}
            {isShowSearchInput && (
                <div className={classNames(style.searchInputContainer, searchInputContainerClassName)}>
                    <SearchInput
                        className={inputClassName}
                        onChange={handleSearchLocationChange}
                        onReset={handleSearchLocationReset}
                        placeholder="Искать город"
                        value={searchLocation}
                    />
                </div>
            )}
            <ul className={classNames(style.list, listClassName)}>
                {isShowSearchInput && searchLocation && 0 === filteredList.length && (
                    <InfoItem className={infoBlockClassName}>Ничего не найдено</InfoItem>
                )}

                {0 < filteredList.length && (
                    <InfoItem className={infoBlockClassName}>
                        Выбор города влияет на ассортимент товара и возможные способы получения.
                    </InfoItem>
                )}

                {filteredList.map(({id, title}) => {
                    return (
                        <li className={classNames(style.item, itemClassName)} key={`${uid}--${id}`}>
                            <input
                                checked={checkedLocationId === id}
                                className={style.input}
                                disabled={disabled}
                                id={id}
                                name={uid}
                                onChange={handleLocationChange}
                                type="radio"
                                value={id}
                            />
                            <label className={style.label} htmlFor={id}>
                                <Typography
                                    className={classNames(style.name, locationNameClassName)}
                                    color="gray80"
                                    element="span"
                                >
                                    {title}
                                </Typography>
                            </label>
                            <SvgIcon className={style.checkMarkIcon} svg={checkMarkIcon} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
