import {env, memoize} from 'core/decorators';
import {ScriptConnectionError} from 'core/exceptions/ScriptConnectionError';

export interface IScriptConnection {
    element: HTMLScriptElement;
    event?: Event | ErrorEvent;
}

export interface IConnectScriptData {
    id: string;
    onScriptAdd?: () => void;
    onScriptLoaded?: () => void;
    mountingPoint?: HTMLElement;
}

export const connectScript = memoize(
    env('client', function connectScript(url: string, data: IConnectScriptData): Promise<IScriptConnection> {
        const {id, mountingPoint, onScriptAdd, onScriptLoaded} = data;

        return new Promise<IScriptConnection>((resolve, reject) => {
            const prevScript = document.querySelector<HTMLScriptElement>(`#${id}`);
            if (prevScript) {
                resolve({element: prevScript});
                return;
            }

            onScriptAdd?.();

            const point = mountingPoint ?? document.head;
            const scriptElement = document.createElement('script');

            scriptElement.setAttribute('id', id);
            scriptElement.setAttribute('src', url);
            scriptElement.setAttribute('type', 'text/javascript');

            scriptElement.addEventListener('load', (event) => {
                resolve({element: scriptElement, event});
                onScriptLoaded?.();
            });

            scriptElement.addEventListener('error', () => {
                reject(new ScriptConnectionError(url));
            });

            point.append(scriptElement);
        });
    })
);
