import {MouseEvent, useCallback} from 'react';

export const useDemoAlert = () => {
    const handleShowDemoAlert = useCallback<(e?: MouseEvent) => void>((e) => {
        e?.preventDefault();
        e?.stopPropagation();
        // eslint-disable-next-line no-alert
        window.alert('В демоверсии сайта функционал недоступен.');
    }, []);

    return {handleShowDemoAlert};
};
