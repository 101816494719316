import {IErrorsHandler, TServerError} from 'core/types';
import {useCallback} from 'react';

export const useErrorsHandler = () => {
    return useCallback(<V = Record<string, string>>({errors, additionalCb}: IErrorsHandler<V>) => {
        const errorsObj = errors.reduce(
            (acc, error) => ({...acc, [error.field ?? error.code]: error.message}),
            {} as V
        );

        additionalCb?.(errorsObj);

        return errorsObj;
    }, []);
};

export const useErrorsHandlerObjects = () => {
    return useCallback(<V = Partial<Record<string, TServerError>>>({errors, additionalCb}: IErrorsHandler<V>) => {
        const errorsObj = errors.reduce<V>((acc, error) => ({...acc, [error.field ?? error.code]: error}), {} as V);

        additionalCb?.(errorsObj);

        return errorsObj;
    }, []);
};
