import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILoaderState} from 'modules/loader/types';

export const loaderModuleName = 'loader';

const initialState: ILoaderState = {
    loadersList: {},
};

const loaderSlice = createSlice({
    initialState,
    name: loaderModuleName,
    reducers: {
        actionLoaderReset(state: ILoaderState, {payload}: PayloadAction<keyof ILoaderState['loadersList']>) {
            state.loadersList[payload] = undefined;
        },

        actionLoaderSetHidden(state: ILoaderState, {payload}: PayloadAction<keyof ILoaderState['loadersList']>) {
            state.loadersList[payload] = false;
        },

        actionLoaderSetVisible(state: ILoaderState, {payload}: PayloadAction<keyof ILoaderState['loadersList']>) {
            state.loadersList[payload] = true;
        },
    },
});

export const {
    actions: {actionLoaderSetVisible, actionLoaderSetHidden, actionLoaderReset},
} = loaderSlice;

export const {reducer: loaderReducer} = loaderSlice;
