import {AgreementBlock} from 'components/AgreementBlock';
import {Button} from 'components/Button';
import {Input} from 'components/Input';
import {TextArea} from 'components/TextArea';
import {TPropsWithClassName} from 'core/types';
import {Field, FieldProps} from 'formik';
import {Franchise} from 'new-models';
import {memo} from 'react';
import InputMask from 'react-input-mask';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    isSubmitting: boolean;
    handleAgreementChange: VoidFunction;
    isAgreement: boolean;
}

export const FranchiseFormFields = memo<IProps>(function FranchiseFormFields({
    isSubmitting,
    handleAgreementChange,
    isAgreement,
}) {
    return (
        <>
            <Field name="name">
                {({field, meta}: FieldProps<string, Franchise['name']>) => (
                    <Input {...field} errorText={meta.error} isBorder labelText="Ваше имя*" />
                )}
            </Field>
            <Field name="secondName">
                {({field, meta}: FieldProps<string, Franchise['name']>) => (
                    <Input {...field} errorText={meta.error} isBorder labelText="Ваша фамилия*" />
                )}
            </Field>
            <Field name="email">
                {({field, meta}: FieldProps<string, Franchise['email']>) => (
                    <Input {...field} errorText={meta.error} isBorder labelText="Электронная почта" />
                )}
            </Field>
            <Field name="phone">
                {({field, meta}: FieldProps<string, Franchise['phone']>) => (
                    <InputMask mask="+7 999 999 99 99" maskPlaceholder="" {...field} disabled={isSubmitting}>
                        <Input
                            {...field}
                            errorText={meta.error}
                            isBorder
                            labelText="Номер телефона*"
                            notFadingPlaceholder="+7 999 999 99 99"
                        />
                    </InputMask>
                )}
            </Field>
            <Field name="message">
                {({field, meta}: FieldProps<string, Franchise['message']>) => (
                    <TextArea {...field} errorText={meta.error} labelText="Сообщение*" />
                )}
            </Field>
            <AgreementBlock handleAgreementChange={handleAgreementChange} isAgreement={isAgreement} />
            <Button
                className={style.submitBtn}
                disabled={!isAgreement}
                isLoading={isSubmitting}
                isNotAdaptive
                isStretched
                type="submit"
            >
                Отправить
            </Button>
        </>
    );
});
