import {BaseUrlParam} from 'core/base/BaseUrlParam';
import {BaseUrlService} from 'core/base/BaseUrlService';
import {PageInfo} from 'models';

export class PaginationUrlService extends BaseUrlService {
    public static readonly COMMON_PARAM_NAMES = {
        LIMIT: new BaseUrlParam('limit', false),
        PAGE: new BaseUrlParam('page', false),
    };

    constructor(
        query: BaseUrlService['query'],
        onParamsChange: BaseUrlService['onParamsChange'],
        options: BaseUrlService['options']
    ) {
        super(query, onParamsChange, options);

        this.fill(Object.values(PaginationUrlService.COMMON_PARAM_NAMES), 'include');
        this.format();
    }

    public async setPageParam(page: PageInfo['pageNomer']) {
        await this.addParam({id: PaginationUrlService.COMMON_PARAM_NAMES.PAGE.toString(), value: page.toString()});
    }

    public async setPageSizeParam(size: PageInfo['pageSize']) {
        await this.addParam({id: PaginationUrlService.COMMON_PARAM_NAMES.LIMIT.toString(), value: size.toString()});
    }
}
