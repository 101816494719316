import {ICheckoutState} from 'modules/checkout/types';
import {
    BasketDataResponse,
    DeliveryAddress,
    DeliveryMethodShort,
    OrderDetail,
    PaymentMethodShort,
    PickupPoint,
} from 'new-models';
import {createContext} from 'react';

export interface ICheckoutContextValue {
    cartProductList: BasketDataResponse['products'];
    cartTotal: BasketDataResponse['total'];
    productCount: number;
    isCartLoading?: boolean;
    selectedPickupPointId?: string | null;
    deliveryMethodList: DeliveryMethodShort[];
    deliveryMethodData: ICheckoutState['deliveries']['data'];
    paymentMethodList: PaymentMethodShort[];
    paymentMethodData: ICheckoutState['payments']['data'];
    pickupPointList: PickupPoint[];
    pickupPointData: ICheckoutState['pickupPoints']['data'];
    isPickupPointListLoading?: boolean;
    courierTimeSlotList: ICheckoutState['courierTimeSlotList'];
    isCourierTimeSlotListLoading?: boolean;
    userAddressList: DeliveryAddress[];
    deliveryInfo: ICheckoutState['deliveryInfo'];
}

export const CheckoutContext = createContext<ICheckoutContextValue>({} as ICheckoutContextValue);

export interface IOnlinePaymentContextValue {
    order?: OrderDetail | null;
}

export const OnlinePaymentContext = createContext<IOnlinePaymentContextValue>({
    order: null,
} as IOnlinePaymentContextValue);
