import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {ActionDetail} from 'new-models';
import React, {memo} from 'react';
import {PROMO_ROUTE} from 'routing/constants';

interface IProps {
    data: ActionDetail;
}

export const PromoMobileHeader = memo<IProps>(function PromoMobileHeader({data}) {
    return (
        <PageHeaderTitle isParentPath path={PROMO_ROUTE} withContainer={false}>
            <Typography color="gray100" data-skeleton-item element="h1" variant="h1">
                {data.title}
            </Typography>
        </PageHeaderTitle>
    );
});
