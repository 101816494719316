import {format} from 'date-fns';
import {ru} from 'date-fns/locale';
import {
    PROMO_ACTIVE_PERIOD_FROM,
    PROMO_ACTIVE_PERIOD_TITLE,
    PROMO_ACTIVE_PERIOD_TITLE_BIG,
    PROMO_ACTIVE_PERIOD_TO,
} from 'modules/promo/constants';
import {ActionTop, ProductBase} from 'new-models';
import {FilterUrlService} from 'plugins/modules/filter/services/FilterUrlService';
import {PROMO_ROUTE} from 'routing/constants';

export const getActionUrl = (actionCode: string | number): string => `${PROMO_ROUTE}/${actionCode}`;
export const setPromoStaticFilter = (id: string): void => {
    FilterUrlService.setStaticFilter({
        actionId: id,
    });
};

const formatPeriod = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getDate();

    return `${day} ${format(date, 'MMMM', {locale: ru})}`;
};

const formatPeriodForPromoCard = (dateStr: string) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = format(date, 'MMMM', {locale: ru});
    const year = date.getFullYear();

    return `${day} ${month} ${year} г.`;
};

interface IGetPromoActivePeriodOptions {
    isBigTitle?: boolean;
}

export const getPromoActivePeriod = (
    activeFrom?: string,
    activeTo?: string,
    options?: IGetPromoActivePeriodOptions
) => {
    const title = options?.isBigTitle ? PROMO_ACTIVE_PERIOD_TITLE_BIG : PROMO_ACTIVE_PERIOD_TITLE;

    if (activeFrom && activeTo) {
        const fromFormatted = formatPeriod(activeFrom);
        const toFormatted = formatPeriod(activeTo);

        return `${title} ${PROMO_ACTIVE_PERIOD_FROM} ${fromFormatted} ${PROMO_ACTIVE_PERIOD_TO} ${toFormatted}`;
    }

    if (activeFrom) {
        const fromFormatted = formatPeriod(activeFrom);

        return `${title} ${PROMO_ACTIVE_PERIOD_FROM} ${fromFormatted}`;
    }

    if (activeTo) {
        const toFormatted = formatPeriod(activeTo);

        return `${title} ${PROMO_ACTIVE_PERIOD_TO} ${toFormatted}`;
    }

    return '';
};

export const getActivePeriodForPromoCard = (activeFrom?: string, activeTo?: string) => {
    if (activeFrom && activeTo) {
        const fromFormatted = formatPeriodForPromoCard(activeFrom);
        const toFormatted = formatPeriodForPromoCard(activeTo);

        return `${fromFormatted} — ${toFormatted}`;
    }

    if (activeFrom) {
        const fromFormatted = formatPeriodForPromoCard(activeFrom);

        return `${PROMO_ACTIVE_PERIOD_TITLE} ${PROMO_ACTIVE_PERIOD_FROM} ${fromFormatted}`;
    }

    if (activeTo) {
        const toFormatted = formatPeriodForPromoCard(activeTo);

        return `${PROMO_ACTIVE_PERIOD_TITLE} ${PROMO_ACTIVE_PERIOD_TO} ${toFormatted}`;
    }

    return '';
};

interface IGetPopularPromoData {
    productsList: ProductBase[];
    productsListLength: number;
    promoHref: string;
    totalCount: number;
}

export const getPopularPromoData = (data: ActionTop): IGetPopularPromoData => {
    const {products} = data;
    const {page, products: productsList} = products || {};
    const list = productsList || [];

    return {
        productsList: list,
        productsListLength: list.length,
        promoHref: getActionUrl(data.code),
        totalCount: page?.count || 0,
    };
};
