import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {CartProductItem, ICartProductItemProps} from 'modules/cart/components/CartProductItem';
import {CartPopupProductItem} from 'modules/cart/components/PopupProductItem';
import {BasketItem} from 'new-models';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    cartProductsList: BasketItem[];
    isForHeaderPopup?: boolean;
}

export const CartProductsList: FC<IProps> = ({className, cartProductsList, isForHeaderPopup = false}) => {
    const Components = (props: ICartProductItemProps) =>
        isForHeaderPopup ? <CartPopupProductItem {...props} /> : <CartProductItem {...props} />;

    return (
        <ul className={classNames(style.cartProductsList, className)}>
            {cartProductsList.map((basketItem) => (
                <li key={basketItem.product.id}>
                    <Components {...basketItem} />
                </li>
            ))}
        </ul>
    );
};
