import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {LocationName} from 'modules/locations/components/LocationName';
import locationMarkIcon from 'public/icons/location-mark.svg';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    isDisable?: boolean;
    onClick?: () => void;
}

export const LocationLabel = memo<IProps>(({className, isDisable, onClick}) => {
    return (
        <Typography
            className={classNames(className, style.itemContainer, {[style.itemAvailability]: !isDisable})}
            element="div"
            onClick={onClick}
            role="presentation"
            variant="p-regular"
        >
            <SvgIcon className={style.locationIcon} svg={locationMarkIcon} />
            <LocationName />
        </Typography>
    );
});
