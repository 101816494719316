import {isBrowserContext} from 'core/helpers';
import {CookieService} from 'core/services/CookieService';
import {AddressesService} from 'modules/addresses/services';
import {AuthenticationService} from 'modules/authentication/services/AuthenticationService';
import {RegistrationService} from 'modules/authentication/services/RegistrationService';
import {BrandService} from 'modules/brand/services/BrandService';
import {CalculatorService} from 'modules/calculators/services/CalculatorService';
import {CartService} from 'modules/cart/services/CartService';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {CheckoutService} from 'modules/checkout/services/CheckoutService';
import {CollectionXService} from 'modules/collection-x/services/CollectionXService';
import {CollectionsService} from 'modules/collections/services/CollectionsService';
import {CommonService} from 'modules/common/services/CommonService';
import {CompareProductsService} from 'modules/compareProducts/services';
import {ContentService} from 'modules/content/services';
import {FavouritesService} from 'modules/favourites/services';
import {FranchiseService} from 'modules/franchise/services';
import {HomePageService} from 'modules/home-page/services/HomePageService';
import {LocationService} from 'modules/locations/services/LocationService';
import {LoyaltyCardService} from 'modules/loyalty-cards/services/LoyaltyCardService';
import {MenuService} from 'modules/menu/services';
import {OrdersService} from 'modules/orders/service';
import {ProductCardService} from 'modules/product-card/services/ProductCardService';
import {ProductSubscriptionService} from 'modules/product-subscription/services/ProductSubscriptionService';
import {ProductsService} from 'modules/products/services/ProductsService';
import {PromoService} from 'modules/promo/services/PromoService';
import {SearchService} from 'modules/search-page/services/SearchService';
import {ServicesService} from 'modules/services/services/ServicesService';
import {ShopService} from 'modules/shops/services/ShopService';
import {SkeletonService} from 'modules/skeleton/services/SkeletonService';
import {UserService} from 'modules/user/services/UserService';
import {WishListService} from 'modules/wish-list/services/WishListService';

export const extraArgs = {
    addressesService: new AddressesService(),
    authenticationService: new AuthenticationService(),
    brandService: new BrandService(),
    calculatorService: new CalculatorService(),
    cartService: new CartService(),
    categoryService: new CategoryService(),
    checkoutService: new CheckoutService(),
    collectionXService: new CollectionXService(),
    collectionsService: new CollectionsService(),
    commonService: new CommonService(),
    compareProductsService: new CompareProductsService(),
    contentService: new ContentService(),
    cookieService: CookieService.getInstance(),
    favouritesService: new FavouritesService(),
    franchiseService: new FranchiseService(),
    homePageService: new HomePageService(),
    locationService: new LocationService(),
    loyaltyCardsService: new LoyaltyCardService(),
    menuService: new MenuService(),
    navigator: isBrowserContext() && 'undefined' !== typeof navigator && navigator,
    orderService: new OrdersService(),
    productCardService: new ProductCardService(),
    productSubscriptionService: new ProductSubscriptionService(),
    productsService: new ProductsService(),
    promoService: new PromoService(),
    registrationService: new RegistrationService(),
    searchService: new SearchService(),
    servicesService: new ServicesService(),
    shopService: new ShopService(),
    skeletonService: new SkeletonService(),
    userService: new UserService(),
    wishListService: new WishListService(),
};

export type TAppExtraArgs = typeof extraArgs;
