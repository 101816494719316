import {Popup} from 'components/Popup';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo, ReactNode} from 'react';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    list: ReactNode;
}
export const Dropdown = memo<IProps>(function Dropdown({children, className, list}) {
    return (
        <Popup className={className} popup={list} willMount>
            {children}
        </Popup>
    );
});
