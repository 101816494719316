import classNames from 'classnames';
import {Button} from 'components/Button';
import {MediaHarvester} from 'components/MediaHarvester';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {HomePageContext} from 'modules/home-page/context';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import cardPaymentIcon from 'public/icons/card-payment-icon.svg';
import deliveryIcon from 'public/icons/delivery.svg';
import percentIcon from 'public/icons/percent.svg';
import shoppingBagIcon from 'public/icons/shopping-bag.svg';
import {useCallback, useContext} from 'react';

import style from './style.module.scss';

const advantages = [
    {icon: <SvgIcon svg={deliveryIcon} />, id: 1, text: 'Бесплатная доставка при покупке от 5 000 ₽ онлайн'},
    {icon: <SvgIcon svg={shoppingBagIcon} />, id: 2, text: 'Наличие всех товаров на складе, при онлайн заказе'},
    {icon: <SvgIcon svg={percentIcon} />, id: 3, text: 'Скидка -15% на первую покупку онлайн'},
    {icon: <SvgIcon svg={cardPaymentIcon} />, id: 4, text: 'Удобная оплата – картой или СБП'},
];

export const HomeAbout: FC = () => {
    const dispatch = useAppDispatch();

    const {homeAboutBanners, content} = useContext(HomePageContext);
    const handleOpenAddressMapModal = useCallback(() => {
        dispatch(showModal(MODALS.ABOUT_ADDRESS_MAP.name));
    }, [dispatch]);

    return (
        <PageSectionBlock
            href={homeAboutBanners.length ? homeAboutBanners[0].link.value : '#'}
            linkText="Больше о нас"
            title="KARACA HOME"
        >
            <div className={classNames(style.content, {[style.withoutImage]: !homeAboutBanners.length})}>
                {Boolean(homeAboutBanners.length) && (
                    <div className={style.leftSide}>
                        <MediaHarvester media={homeAboutBanners[0].media} />
                    </div>
                )}
                <div className={style.rightSide}>
                    <div className={style.rightSideTop}>
                        <Typography element="h4" variant="h4">
                            KARACA HOME – это
                        </Typography>
                        <ul className={style.advantagesList}>
                            {advantages.map((item) => (
                                <li className={style.advantagesItem} key={item.id}>
                                    <span className={style.advantagesIcon}>{item.icon}</span>
                                    <Typography color="gray80" element="p" variant="p-regular">
                                        {item.text}
                                    </Typography>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={style.rightSideBottom}>
                        <Typography element="h4" variant="h4">
                            Наш магазин
                        </Typography>
                        <div className={style.rightSideBottomInner}>
                            <Typography variant="h6">
                                г. Москва, ул. Ходынский бул., 4, ТЦ "АВИАПАРК", 1 этаж
                            </Typography>
                            {Boolean(content?.content) && (
                                <Typography
                                    color="gray80"
                                    dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line @typescript-eslint/naming-convention
                                        __html: content?.content ?? '',
                                    }}
                                    element="p"
                                    variant="p"
                                />
                            )}
                        </div>
                    </div>
                    <Button className={style.rightSideBottomBtn} isNotAdaptive onClick={handleOpenAddressMapModal}>
                        Показать на карте
                    </Button>
                </div>
            </div>
        </PageSectionBlock>
    );
};
