import classNames from 'classnames';
import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {getActionUrl, getActivePeriodForPromoCard} from 'modules/promo/helpers';
import {Shield} from 'modules/shields/components/Shield';
import {ActionShort, MediaTypeEnum} from 'new-models';
import Link from 'next/link';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    data: ActionShort;
}

const ImageFallback: FC = () => <NoImage size={NO_IMAGE_SIZE.small} />;

export const PromoCard = memo<IProps>(function PromoCard({data, className}) {
    const {activeFrom, activeTo, code, image, title} = data;

    return (
        <Link href={getActionUrl(code)}>
            <a className={classNames(style.root, className)} data-skeleton-item>
                <div className={style.imageBlock}>
                    <MediaWithImageFallback
                        NoImageComponent={ImageFallback}
                        className={style.image}
                        media={[image]}
                        mediaType={image?.type}
                        options={generateMediaOptions({
                            resizeMapKey: 'promoCard',
                            type: MediaTypeEnum.ImageAdaptive,
                        })}
                        title={title}
                    />
                    <Shield className={style.ads} theme="ads">
                        Реклама
                    </Shield>
                </div>
                <div className={style.inner}>
                    {(activeFrom || activeTo) && (
                        <Typography className={style.date} color="gray60" element="time" variant="p-regular">
                            {getActivePeriodForPromoCard(activeFrom, activeTo)}
                        </Typography>
                    )}
                    <Typography className={style.title} element="h5" variant="p-strong">
                        {title}
                    </Typography>
                </div>
            </a>
        </Link>
    );
});
