import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {addNewAddress, deleteAddress, updateAddress} from 'modules/addresses/thunks';
import {useCallback} from 'react';

export const useDeliveryAddressesActions = () => {
    const dispatch = useAppDispatch();

    const create = useCallback(
        (payload: Parameters<typeof addNewAddress>['0']) => dispatch(addNewAddress(payload)),
        [dispatch]
    );
    const update = useCallback(
        (payload: Parameters<typeof updateAddress>['0']) => dispatch(updateAddress(payload)),
        [dispatch]
    );
    const remove = useCallback(
        (payload: Parameters<typeof deleteAddress>['0']) => dispatch(deleteAddress(payload)),
        [dispatch]
    );

    return {
        create,
        remove,
        update,
    };
};
