import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {findLocation} from 'modules/locations/helpers';
import {selectLocationsList, selectUserLocation} from 'modules/locations/selectors';
import {MouseEventHandler, useCallback} from 'react';

export const useGeoLocationForMap = () => {
    const locations = useAppSelector(selectLocationsList);
    const userLocation = useAppSelector(selectUserLocation);

    const positive = useCallback(
        (cb: (coords: number[]) => void) =>
            ({coords}: GeolocationPosition) => {
                if (!locations || !userLocation?.latitude || !userLocation?.longitude) {
                    return;
                }

                const currentLocation = findLocation({locations, userCoords: coords});

                if (userLocation?.id !== currentLocation?.id) {
                    cb([Number(userLocation.latitude), Number(userLocation.longitude)]);
                    return;
                }

                cb([coords.latitude, coords.longitude]);
            },
        [locations, userLocation?.id, userLocation?.latitude, userLocation?.longitude]
    );

    const negative = useCallback(
        (cb: (coords: number[]) => void) => () => {
            if (!userLocation?.latitude || !userLocation?.longitude) {
                return;
            }

            cb([Number(userLocation.latitude), Number(userLocation.longitude)]);
        },
        [userLocation?.latitude, userLocation?.longitude]
    );

    const handleCenter = useCallback(
        (cb: (coords: number[]) => void): MouseEventHandler<HTMLButtonElement> =>
            () => {
                if ('geolocation' in navigator) {
                    navigator.geolocation.getCurrentPosition(positive(cb), negative(cb));
                }
            },
        [negative, positive]
    );

    return {handleCenter, negative, positive};
};
