import {formatPhone} from 'core/regex';
import {TResponseWithoutContent} from 'core/types';
import {FormikHelpers} from 'formik/dist/types';
import {AUTH_ERROR_CODES, MODAL_STATES, SCENARIOS, STRATEGIES, VIEWS} from 'modules/authentication/constants';
import {AuthContext} from 'modules/authentication/context';
import {useErrorsHandler} from 'modules/authentication/hooks/handlers/useErrorsHandler';
import {useFieldHandler} from 'modules/authentication/hooks/handlers/useFieldHandler';
import {useModalParamsHandler} from 'modules/authentication/hooks/handlers/useModalParamsHandler';
import {AuthenticationService} from 'modules/authentication/services/AuthenticationService';
import {
    IAuthEmailData,
    IAuthPhoneData,
    ICodeValues,
    ILoginValues,
    IModalParams,
    IPhoneValues,
    TCustomFormikHandler,
    TPartialFormikHandler,
    TScenario,
} from 'modules/authentication/types';
import {useCallback, useContext, useMemo} from 'react';

const ALLOW_RESEND_AFTER_TIME = 60;

export interface IConfirmAndCheckCbs {
    confirmPhoneHandler: TPartialFormikHandler<ICodeValues, TResponseWithoutContent>;
    confirmEmailHandler: TPartialFormikHandler<ICodeValues, boolean>;
    sendCodeToPhone: TPartialFormikHandler<IPhoneValues, number>;
    checkLoginHandler: TCustomFormikHandler<ILoginValues, void>;
    isConfirmAndCheckLoginHandler: TCustomFormikHandler<ILoginValues, boolean>;
    resendCodeHandler: (
        value: string,
        setErrors: FormikHelpers<Omit<Partial<IAuthPhoneData | IAuthEmailData>, 'code'>>['setErrors'],
        options: {isPhone?: boolean}
    ) => void;
}
const authenticationService = new AuthenticationService();

// TODO: Need refactoring
// eslint-disable-next-line max-lines-per-function
export const useCheckAndConfirm = (scenario: TScenario): IConfirmAndCheckCbs => {
    const {fieldsParams, modalParams} = useContext(AuthContext);
    const confirmedPhone = fieldsParams?.phone ?? '';
    const confirmedEmail = fieldsParams?.email ?? '';
    const errorsHandler = useErrorsHandler();
    const fieldsHandler = useFieldHandler();
    const modalParamsHandler = useModalParamsHandler();

    const phoneStrategy = useMemo(
        () => (scenario === SCENARIOS.authentication ? STRATEGIES.authBySms : STRATEGIES.registrationBySms),
        [scenario]
    );

    const newParamsForPhone = useMemo<IModalParams>(
        () =>
            scenario === SCENARIOS.authentication
                ? {isBackButton: true, modalView: VIEWS.phoneConfirm, strategy: phoneStrategy}
                : {modalState: MODAL_STATES.smsCodeShowing, strategy: phoneStrategy},
        [phoneStrategy, scenario]
    );

    const newParamsForLogin = useCallback(
        (isPhone: boolean) => ({
            modalView: isPhone ? VIEWS.phoneConfirm : VIEWS.emailConfirm,
            strategy: STRATEGIES.passwordRecovery,
        }),
        []
    );

    const resendCodeHandler = useCallback<IConfirmAndCheckCbs['resendCodeHandler']>(
        async (value, setErrors, options) => {
            errorsHandler({additionalCb: setErrors, errors: []});

            const {data: checkData} = options?.isPhone
                ? await authenticationService.authByPhone({
                      phone: formatPhone(value),
                  })
                : await authenticationService.authByEmail({
                      email: value,
                  });

            const checkErrors = checkData.errors;

            if (checkErrors) {
                errorsHandler({additionalCb: setErrors, errors: checkErrors});
            }

            fieldsHandler({
                fields: {[options.isPhone ? 'phone' : 'email']: value, repeatAfter: String(ALLOW_RESEND_AFTER_TIME)},
            });
        },
        [errorsHandler, fieldsHandler]
    );

    // TODO: Need refactoring or remove
    const isConfirmedLoginHandler = useCallback<IConfirmAndCheckCbs['isConfirmAndCheckLoginHandler']>(
        async ({value}, formikHelpers, options) => {
            errorsHandler({additionalCb: formikHelpers?.setErrors, errors: []});
            const {data: isConfirmedData} = options?.isPhone
                ? await authenticationService.authByPhone({
                      phone: formatPhone(value),
                  })
                : await authenticationService.authByEmail({
                      email: value,
                  });

            const isConfirmedErrors = isConfirmedData.errors;

            if (isConfirmedErrors) {
                errorsHandler({additionalCb: formikHelpers?.setErrors, errors: isConfirmedErrors});
            }

            return true;
        },
        [errorsHandler]
    );

    const sendCodeToPhone = useCallback<IConfirmAndCheckCbs['sendCodeToPhone']>(
        async ({phone}, formikHelpers) => {
            errorsHandler({additionalCb: formikHelpers?.setErrors, errors: []});
            const {data: checkData, status} = await authenticationService.authByPhone({
                isLegal: modalParams?.modalState === MODAL_STATES.legal,
                phone: formatPhone(phone),
            });

            const checkErrors = checkData.errors;
            const errorTimeout = checkErrors?.find(({code}) => code === AUTH_ERROR_CODES.ERR_TIMEOUT);
            if (errorTimeout) {
                fieldsHandler({fields: {phone, repeatAfter: String(ALLOW_RESEND_AFTER_TIME)}});
                modalParamsHandler(newParamsForPhone);
            }

            if (checkErrors) {
                errorsHandler({additionalCb: formikHelpers?.setErrors, errors: checkErrors});
                return status;
            }

            fieldsHandler({fields: {phone, repeatAfter: String(ALLOW_RESEND_AFTER_TIME)}});
            modalParamsHandler(newParamsForPhone);

            return status;
        },
        [errorsHandler, fieldsHandler, modalParams?.modalState, modalParamsHandler, newParamsForPhone]
    );

    const confirmPhoneHandler = useCallback<IConfirmAndCheckCbs['confirmPhoneHandler']>(
        async (values, formikHelpers) => {
            errorsHandler({errors: []});

            const response = await authenticationService.authByPhone({
                phone: formatPhone(confirmedPhone),
                ...values,
            });

            const {data} = response;

            const errors = data.errors;

            if (errors) {
                errorsHandler({additionalCb: formikHelpers?.setErrors, errors});
            }
            return response;
        },
        [confirmedPhone, errorsHandler]
    );

    // TODO: Need refactoring or remove
    const checkLoginHandler = useCallback<IConfirmAndCheckCbs['checkLoginHandler']>(
        async ({value}, formikHelpers, options) => {
            errorsHandler({additionalCb: formikHelpers?.setErrors, errors: []});
            const {data: checkData} = options?.isPhone
                ? await authenticationService.authByPhone({
                      phone: formatPhone(value),
                  })
                : await authenticationService.authByEmail({
                      email: value,
                  });

            const checkErrors = checkData.errors;

            if (checkErrors) {
                errorsHandler({additionalCb: formikHelpers?.setErrors, errors: checkErrors});
            }

            fieldsHandler({
                fields: {[options?.isPhone ? 'phone' : 'email']: value, repeatAfter: String(ALLOW_RESEND_AFTER_TIME)},
            });
            modalParamsHandler(newParamsForLogin(Boolean(options?.isPhone)));
        },
        [errorsHandler, fieldsHandler, modalParamsHandler, newParamsForLogin]
    );

    // TODO: Need refactoring
    const confirmEmailHandler = useCallback<IConfirmAndCheckCbs['confirmEmailHandler']>(
        async (values, formikHelpers) => {
            errorsHandler({errors: []});
            const {data, status} = await authenticationService.authByEmail({
                email: confirmedEmail,
                ...values,
            });

            const errors = data.errors;

            if (errors) {
                errorsHandler({additionalCb: formikHelpers?.setErrors, errors});
                return false;
            }

            return 204 === status;
        },
        [confirmedEmail, errorsHandler]
    );

    const isConfirmAndCheckLoginHandler = useCallback<IConfirmAndCheckCbs['isConfirmAndCheckLoginHandler']>(
        async (values, formikHelpers, options) => {
            const isConfirmed = await isConfirmedLoginHandler(values, formikHelpers, options);

            if (isConfirmed) {
                return isConfirmed;
            }

            checkLoginHandler(values, formikHelpers, options);

            return isConfirmed;
        },
        [checkLoginHandler, isConfirmedLoginHandler]
    );

    return {
        checkLoginHandler,
        confirmEmailHandler,
        confirmPhoneHandler,
        isConfirmAndCheckLoginHandler,
        resendCodeHandler,
        sendCodeToPhone,
    };
};
