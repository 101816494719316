import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {WishListDetail} from 'models';
import {
    TWishListDetailResponse,
    TWishListResponse,
    TWishListsResponse,
} from 'modules/wish-list/models/WishListsResponse';
import {TProductToWishListParams, TWishListParams} from 'modules/wish-list/types';

export class WishListService extends EvolutionHttpClient {
    async getWishLists(): Promise<TWishListsResponse> {
        const {data} = await this.get<TWishListsResponse>({
            url: '/wish-lists',
        });

        return data;
    }

    async getWishListDetail(id: WishListDetail['wishListId']): Promise<TWishListDetailResponse> {
        const {data} = await this.get<TWishListDetailResponse>({
            url: `/wish-list/${id}`,
        });

        return data;
    }

    async postWishList(body: TWishListParams): Promise<TWishListResponse> {
        const {data} = await this.post<TWishListParams, TWishListResponse>({
            body,
            url: '/wish-list',
        });

        return data;
    }

    async patchWishListDetail(
        id: WishListDetail['wishListId'],
        body: TProductToWishListParams
    ): Promise<TWishListDetailResponse> {
        const {data} = await this.patch<TProductToWishListParams, TWishListDetailResponse>({
            body,
            url: `/wish-list/${id}/products`,
        });

        return data;
    }
}
