import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {CollectionPreviewCard} from 'modules/collections/components/CollectionPreviewCard';
import {selectRelatedCollectionList} from 'modules/collections/selectors';
import {PreviewCardsSlider} from 'modules/slider/components/PreviewCardsSlider';
import {SliderTitle} from 'modules/slider/components/SliderTitle';
import React, {FC, ReactNode, useId, useMemo} from 'react';

export const RelatedCollectionsList: FC<TPropsWithClassName> = ({className}) => {
    const relatedCollectionList = useAppSelector(selectRelatedCollectionList);

    const uid = useId();

    const renderList = useMemo<ReactNode[]>(() => {
        if (!relatedCollectionList.length) {
            return [];
        }

        return relatedCollectionList.map((relatedCollection) => {
            return (
                <CollectionPreviewCard
                    {...relatedCollection}
                    key={`${relatedCollection.id}--${uid}`}
                    variant="preview"
                />
            );
        });
    }, [relatedCollectionList, uid]);

    return renderList.length ? (
        <div className={className}>
            <SliderTitle title="Другие коллекции" />
            <PreviewCardsSlider slidesPerGroup={1} slidesPerView={'auto'}>
                {renderList}
            </PreviewCardsSlider>
        </div>
    ) : null;
};
