import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {BrandDescription} from 'modules/brand/components/BrandDescription';
import {BrandProductList} from 'modules/brand/components/BrandProductList';
import {BrandVideoReviews} from 'modules/brand/components/VideoReviews';
import {BrandDetail} from 'new-models';
import React, {FC, memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    brandData: BrandDetail;
}

export const BrandPage: FC<IProps> = memo(function BrandPage({brandData}) {
    return (
        <div>
            <PageHeaderTitle className={style.header}>
                <Typography color="gray100" data-skeleton-item element="h1" variant="h2-bold">
                    {brandData.title}
                </Typography>
            </PageHeaderTitle>
            <BrandDescription brandData={brandData} />
            <BrandVideoReviews presentationList={brandData.presentation} />
            <BrandProductList className={style.listing} />
        </div>
    );
});
