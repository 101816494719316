import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {IHeaderCatalogMenuItem} from 'modules/menu/types';
import Link from 'next/link';
import React, {memo} from 'react';

import {DIYChevronIconItem} from '../DIYChevronIconItem';
import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    parentItem?: IHeaderCatalogMenuItem | null;
}

export const DIYAllCategoriesLink = memo<IProps>(function DIYAllCategoriesLink({className, parentItem}) {
    if (!parentItem) {
        return null;
    }

    return (
        <li className={className}>
            <Link href={parentItem.url?.value as string} passHref scroll>
                <a className={style.link}>
                    <Typography color="secondary100">Все товары категории</Typography>
                    <DIYChevronIconItem />
                </a>
            </Link>
        </li>
    );
});
