import {PREVENT_DEFAULT_HANDLER_MARKER, STOP_PROP_HANDLER_MARKER} from 'core/constants';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {LinkProps} from 'next/dist/client/link';
import Link from 'next/link';
import React, {FC, MouseEventHandler, useCallback} from 'react';

interface IProps extends LinkProps, TPropsWithClassName, TPropsWithChildrenRequire {
    ['data-skeleton-item']?: boolean;
}

export const PrevDefaultLink: FC<IProps> = ({className, children, ...restNextLinkAttributes}) => {
    const handleLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((event) => {
        const target = event.target as HTMLAnchorElement;
        const targetNodePreventDefault = target.closest(`[${PREVENT_DEFAULT_HANDLER_MARKER}='true']`);
        const targetNodeStopProp = target.closest(`[${STOP_PROP_HANDLER_MARKER}='true']`);

        if (targetNodePreventDefault) {
            event.preventDefault();
        }

        if (targetNodeStopProp) {
            event.stopPropagation();
        }
    }, []);

    return (
        <Link {...restNextLinkAttributes}>
            <a
                className={className}
                data-skeleton-item={restNextLinkAttributes['data-skeleton-item']}
                onClick={handleLinkClick}
            >
                {children}
            </a>
        </Link>
    );
};
