import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TProductAvailabilityInfoResponse} from 'modules/products/models/ProductAvailabilityInfoResponse';
import {TProductGroupsResponse} from 'modules/products/models/ProductGroupsResponse';
import {TProductsListResponse} from 'modules/products/models/ProductsListResponse';
import {Category, ProductDetail} from 'new-models';

export interface IProductListParams {
    categoryId?: Category['id'];
    limit?: number;
    page?: number;
    sortBy?: string;
}

export class ProductsService extends EvolutionHttpClient {
    public async getProductList(
        query: URLSearchParams,
        productListParams: IProductListParams
    ): Promise<TProductsListResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, productListParams);

        const {data} = await this.get<TProductsListResponse>({
            url: `/products?${query}`,
        });

        return data;
    }

    public async getProductGroupsList(
        query: URLSearchParams,
        params: IProductListParams
    ): Promise<TProductGroupsResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, params);

        const {data} = await this.get<TProductGroupsResponse>({
            url: `/products/groups?${query}`,
        });

        return data;
    }

    public async getProductAvailabilityInfo(productId: ProductDetail['id']): Promise<TProductAvailabilityInfoResponse> {
        const {data} = await this.get<TProductAvailabilityInfoResponse>({
            url: `/product/${productId}/availabilities`,
        });

        return data;
    }
}
