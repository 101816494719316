import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useFormik} from 'formik';
import {FormikConfig} from 'formik/dist/types';
import {selectCartTotal} from 'modules/cart/selectors';
import {applyPromoCode, deletePromoCode} from 'modules/cart/thunks';
import {selectErrorMessage} from 'modules/errors/selectors';
import {PromocodeActionDataRequest} from 'new-models';
import {ChangeEventHandler, FormEventHandler, useCallback, useEffect, useMemo, useState} from 'react';
import * as Yup from 'yup';

interface IUseTotalPromo {
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleSubmit: FormEventHandler<HTMLFormElement>;
    isHidden: boolean;
    promoCode: string;
    switchPromo: () => void;
    error?: string;
    successText?: string;
    isPromocodeDisabled: boolean;
    isSubmitting: boolean;
}

export const DELAY = 1000;

export const useTotalPromo = (): IUseTotalPromo => {
    const [isHidden, setHidden] = useState<boolean>(true);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const submitError = useAppSelector(selectErrorMessage('promoCode', 'promoCode'));
    const [successText, setSuccessText] = useState<string>();
    const total = useAppSelector(selectCartTotal);
    const currentPromoCode = total.promoCode ?? '';

    const validationSchema = useMemo(
        () =>
            Yup.object({
                promoCode: Yup.string().required('Пожалуйста, введите промокод'),
            }),
        []
    );

    useEffect(() => {
        if (currentPromoCode) {
            setSuccessText('Промокод применен');
            setHidden(false);
            setIsDisabled(true);
        }
    }, [currentPromoCode]);

    const initialValues: PromocodeActionDataRequest = useMemo<PromocodeActionDataRequest>(
        () => ({
            promoCode: currentPromoCode,
        }),
        [currentPromoCode]
    );

    const onSubmit = useCallback<FormikConfig<PromocodeActionDataRequest>['onSubmit']>(
        async (data) => {
            const success = await dispatch(applyPromoCode(data));

            if (success) {
                setSuccessText('Промокод применен');
            }

            setIsDisabled(true);
        },
        [dispatch]
    );

    const formikProps = useFormik<PromocodeActionDataRequest>({
        initialValues,
        onSubmit,
        validateOnChange: false,
        validationSchema,
    });

    const {handleSubmit, values, isSubmitting, handleChange, errors, resetForm} = formikProps;
    const promoCode = values.promoCode;
    const error = errors.promoCode ?? submitError;

    const switchPromo = useCallback(async () => {
        if (!isHidden && currentPromoCode) {
            const success = await dispatch(deletePromoCode({promoCode: currentPromoCode}));

            if (!success) {
                return;
            }

            resetForm({values: {promoCode: ''}});
            setSuccessText('');
            setIsDisabled(false);
        }

        if (error) {
            resetForm({errors: {promoCode: ''}});
        }

        setHidden((initialState) => !initialState);
    }, [currentPromoCode, dispatch, error, isHidden, resetForm]);

    const isPromocodeDisabled = useMemo(() => isSubmitting || isDisabled, [isDisabled, isSubmitting]);

    useEffect(() => {
        if (!promoCode.length) {
            resetForm({errors: {promoCode: ''}});
        }
    }, [promoCode.length, resetForm]);

    return useMemo(
        () => ({
            error,
            handleChange,
            handleSubmit,
            isHidden,
            isPromocodeDisabled,
            isSubmitting,
            promoCode,
            successText,
            switchPromo,
        }),
        [
            error,
            handleChange,
            handleSubmit,
            isHidden,
            isPromocodeDisabled,
            isSubmitting,
            promoCode,
            successText,
            switchPromo,
        ]
    );
};
