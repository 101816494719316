import {Anchor} from 'components/Anchor';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import cookieIcon from 'public/icons/cookie.svg';
import React, {FC, useCallback, useEffect, useState} from 'react';

import style from './style.module.scss';

const ACCEPT_COOKIE = 'accept-cookie';

export const CookiePopup: FC = () => {
    const [isShow, setIsShow] = useState(false);

    const onClick = useCallback(() => {
        try {
            localStorage.setItem(ACCEPT_COOKIE, '1');

            setIsShow(false);
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        try {
            const isAccept = localStorage.getItem(ACCEPT_COOKIE);

            if (isAccept) {
                return;
            }
        } catch (e) {
            console.error(e);
        }

        setIsShow(true);
    }, []);

    return isShow ? (
        <div className={style.root}>
            <div className={style.content}>
                <div className={style.inner}>
                    <SvgIcon className={style.icon} svg={cookieIcon} />
                    <Typography className={style.text} variant="p">
                        Я согласен с использованием 
                        <Anchor href="/cookie_info.pdf" target="_blank">
                            куки
                        </Anchor>{' '}
                        и передачей данных службам веб-аналитики
                    </Typography>
                </div>
                <Button className={style.acceptButton} onClick={onClick} size="medium">
                    Принять
                </Button>
            </div>
        </div>
    ) : null;
};
