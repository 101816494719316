import {createSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {loaderModuleName} from 'modules/loader/slice';
import {ILoaderState} from 'modules/loader/types';

const select = (state: TAppRootState): ILoaderState => state[loaderModuleName];

export const selectLoaderList = createSelector([select], (state) => state.loadersList);

export const selectIsLoading = (moduleName: string, isNeedReset = false) =>
    createSelector([selectLoaderList], (loaderList) => {
        if (isNeedReset) {
            return loaderList[moduleName] ?? true;
        }

        return loaderList[moduleName];
    });
