import {usePageLoading} from 'core/next/hooks/usePageLoading';
import {TPropsWithChildrenRequire} from 'core/types';
import {BreadCrumbProvider} from 'modules/breadcrumbs/providers/BreadCrumbProvider';
import {SkeletonRoot} from 'modules/skeleton/components/SkeletonRoot';
import {useUrlCapture} from 'modules/skeleton/hooks/useUrlCapture';
import React, {FC} from 'react';

export const Page: FC<TPropsWithChildrenRequire> = ({children}) => {
    const isPageLoading = usePageLoading();
    const nextUrl = useUrlCapture();

    if (!isPageLoading) {
        return (
            <>
                <BreadCrumbProvider />
                {children}
            </>
        );
    }

    return <SkeletonRoot nextUrl={nextUrl} />;
};
