import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {PageInfo} from 'models';
import {useSlice} from 'plugins/hooks/useSlice';
import {IProps as IPaginationProps} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {IPaginationState, TPaginationActions, TPaginationType} from 'plugins/modules/pagination/types';
import {useCallback, useContext} from 'react';

type TUsePageChange = () => (page: PageInfo['pageNomer'], type: TPaginationType) => void;
export const usePageChange: TUsePageChange = () => {
    const dispatch = useAppDispatch();
    const slice = useSlice<IPaginationState, TPaginationActions>();
    const {urlService: paginationService} = useContext(PaginationContext);

    return useCallback<NonNullable<IPaginationProps<HTMLDivElement>['onChange']>>(
        async (pageNumber, type) => {
            dispatch(slice.actions.actionSetPaginationType(type));
            await paginationService?.setPageParam(pageNumber);
        },
        [dispatch, paginationService, slice.actions]
    );
};
