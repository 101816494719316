import 'rc-tooltip/assets/bootstrap.css';
import 'styles/index.scss';

import {THEME_COLOR} from 'core/constants';
import ErrorBoundary from 'core/exceptions/ErrorBoundary';
import {useIsIncludesRoute} from 'core/hooks/useIsNeedRoute';
import {useServiceWorker} from 'core/hooks/useServiceWorker';
import {ReduxService} from 'core/redux/services/ReduxService';
import {wrapper} from 'core/redux/store';
import {init} from 'core/settings';
import {TAppPropsWithLayout} from 'core/types';
import {Layout} from 'layouts/BasicLayout';
import {ErrorLayout} from 'layouts/ErrorLayout';
import AnalyticScripts from 'modules/analytics/components/AnalyticScripts';
import JivoChatScript from 'modules/chat/components/JivoChatScript';
import {ThemeProvider} from 'modules/theme/providers/ThemeProvider';
import Head from 'next/head';
import {Provider} from 'react-redux';

init();

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function App({Component, ...rest}: TAppPropsWithLayout) {
    const {store, props} = wrapper.useWrappedStore(rest);

    ReduxService.clientReduxStore = store;

    const getLayout = Component.getLayout;

    const isMountExternalScripts = !useIsIncludesRoute(['mobile-content']);

    useServiceWorker();
    // TODO: integration of analytics
    // useConnectAnalyticsScript();

    return (
        <>
            <Head>
                <meta
                    content="initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover, width=device-width"
                    name="viewport"
                />
                <meta content="yes" name="apple-mobile-web-app-capable" />
                <meta content="yes" name="mobile-web-app-capable" />
                <meta content="yes" name="apple-touch-fullscreen" />
                <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
                <meta content={THEME_COLOR} name="theme-color" />
                <link href="/images/favicon/favicon-16x16.png" rel="icon" sizes="48x48" type="image/png" />
                <link href="/images/favicon/favicon-32x32.png" rel="icon" sizes="48x48" type="image/png" />
                <link crossOrigin="use-credentials" href="/manifest.json" rel="manifest" />

                {/* <link*/}
                {/*    as="font"*/}
                {/*    crossOrigin="anonymous"*/}
                {/*    href="/fonts/graphik-medium-web.woff2"*/}
                {/*    rel="preload"*/}
                {/*    type="font/woff2"*/}
                {/* />*/}
                {/* <link*/}
                {/*    as="font"*/}
                {/*    crossOrigin="anonymous"*/}
                {/*    href="/fonts/graphik-regular-web.woff2"*/}
                {/*    rel="preload"*/}
                {/*    type="font/woff2"*/}
                {/* />*/}
                {/* <link*/}
                {/*    as="font"*/}
                {/*    crossOrigin="anonymous"*/}
                {/*    href="/fonts/graphik-semibold-web.woff2"*/}
                {/*    rel="preload"*/}
                {/*    type="font/woff2"*/}
                {/* />*/}
                {/* <link*/}
                {/*    as="font"*/}
                {/*    crossOrigin="anonymous"*/}
                {/*    href="/fonts/evolventa-bold.woff2"*/}
                {/*    rel="preload"*/}
                {/*    type="font/woff2"*/}
                {/* />*/}
                {/* <link*/}
                {/*    as="font"*/}
                {/*    crossOrigin="anonymous"*/}
                {/*    href="/fonts/evolventa-regular.woff2"*/}
                {/*    rel="preload"*/}
                {/*    type="font/woff2"*/}
                {/* />*/}
                {/* <link as="font" crossOrigin="anonymous" href="/fonts/jost-medium.ttf" rel="preload" type="font/ttf" />*/}

                {/* KARACHA fonts */}
                <link
                    as="font"
                    crossOrigin="anonymous"
                    href="/fonts/geologica-light.woff2"
                    rel="preload"
                    type="font/woff2"
                />
                <link
                    as="font"
                    crossOrigin="anonymous"
                    href="/fonts/geologica-regular.woff2"
                    rel="preload"
                    type="font/woff2"
                />
                <link
                    as="font"
                    crossOrigin="anonymous"
                    href="/fonts/manrope-bold.woff2"
                    rel="preload"
                    type="font/woff2"
                />
                <link
                    as="font"
                    crossOrigin="anonymous"
                    href="/fonts/manrope-regular.woff2"
                    rel="preload"
                    type="font/woff2"
                />
                <title>Магазин</title>
            </Head>
            <Provider store={store}>
                <ThemeProvider>
                    <ErrorBoundary>
                        <ErrorLayout statusCode={props.pageProps?.statusCode}>
                            {getLayout ? (
                                getLayout(<Component {...props} />)
                            ) : (
                                <Layout>
                                    <Component {...props} />
                                </Layout>
                            )}
                        </ErrorLayout>
                    </ErrorBoundary>
                </ThemeProvider>
            </Provider>
            {isMountExternalScripts && (
                <>
                    <JivoChatScript />
                    <AnalyticScripts />
                </>
            )}
        </>
    );
}
