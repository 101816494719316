import classNames from 'classnames';
import {Button} from 'components/Button';
import {INFO_BLOCK_TYPE, InfoBlock} from 'components/InfoBlock';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import plusIcon from 'public/icons/plus.svg';
import React, {ButtonHTMLAttributes, memo, MouseEventHandler} from 'react';

import style from './style.module.scss';
interface IProps extends TPropsWithClassName {
    onClick: MouseEventHandler;
}

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, TPropsWithClassName {}

const buttonComponent: FC<IButtonProps> = ({className, onClick}) => (
    <Button className={className} leftIcon={<SvgIcon svg={plusIcon} />} onClick={onClick} size="medium">
        Добавить адрес
    </Button>
);

export const EmptyAddressList = memo<IProps>(function EmptyAddressList({onClick, className}) {
    return (
        <InfoBlock
            ButtonComponent={buttonComponent}
            className={classNames(className, style.root)}
            contentText="Добавьте адрес доставки, чтобы быстрее оформлять заказы"
            isHideIcon
            onClick={onClick}
            type={INFO_BLOCK_TYPE.warning}
        />
    );
});
