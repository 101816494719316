import {DashboardLayout} from 'layouts/DashboardLayout';
import {AddressPageContext} from 'modules/addresses/context';
import {AddressesPage} from 'modules/addresses/pages/AddressesPage';
import {addressesSlice} from 'modules/addresses/slice';
import {DELIVERY_ADDRESSES_SKELETON_PAGE_DATA} from 'modules/skeleton/constants';
import {ProfileUserMenu} from 'modules/user/components/ProfileUserMenu';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import React, {FC} from 'react';

export const DashboardAddressesSkeleton: FC = () => {
    return (
        <SliceProvider slice={addressesSlice}>
            <AddressPageContext.Provider
                value={{addressList: DELIVERY_ADDRESSES_SKELETON_PAGE_DATA.list, isLoading: false}}
            >
                <DashboardLayout sidebar={<ProfileUserMenu />}>
                    <AddressesPage />
                </DashboardLayout>
            </AddressPageContext.Provider>
        </SliceProvider>
    );
};
