import {PayloadAction} from '@reduxjs/toolkit';
import {ISortingState} from 'plugins/modules/sorting/types';

export function createSortingActions<T extends ISortingState>() {
    return {
        actionSetSortingList(state: T, {payload}: PayloadAction<T['sortingList']>) {
            state.sortingList = payload;
        },
    };
}
