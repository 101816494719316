import {deleteCookie, getCookie, setCookie} from 'cookies-next';
import {TmpCookiesObj} from 'cookies-next/lib/types';
import {AbstractStorage} from 'core/abstract/AbstractStorage';
import {ContextService} from 'core/services/ContextService';

/**
 * Cookie которые имеют флаг `httpOnly` не видны на клиенте вовсе,
 * их нельзя менять. Т.е., если на клиенте мы задаем куки `example-cookie-name`,
 * которые уже есть в браузере, но с флагом `httpOnly` не произойдет ничего.
 *
 * Поэтому для перезаписи таких куков необходимо использовать метод `setSecureCookie`,
 * который дернет специальный метод апи, и перезапишет защищенные куки.
 */
export class CookieService extends AbstractStorage<TmpCookiesObj> {
    public store: TmpCookiesObj;

    private constructor() {
        super();

        this.store = {};
    }

    public getItem(name: string | number): string | null {
        return getCookie(String(name), {req: ContextService.ctx?.req, res: ContextService.ctx?.res})?.toString() || '';
    }
    public setItem(name: string | number, value: string): void {
        setCookie(String(name), value, {httpOnly: false, req: ContextService.ctx?.req, res: ContextService.ctx?.res});
    }
    public removeItem(name: string | number): void {
        deleteCookie(String(name), {httpOnly: false, req: ContextService.ctx?.req, res: ContextService.ctx?.res});
    }
    public hydrate(): void {
        throw new Error('Method not implemented.');
    }

    private static instance: CookieService;
    public static REWRITE_COOKIE_PREFIX = 'rewrite:';

    public static getInstance(): CookieService {
        if (!CookieService.instance) {
            CookieService.instance = new CookieService();
        }

        return CookieService.instance;
    }
}
