import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {Toast} from 'components/Toast';
import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {Form, Formik} from 'formik';
import {ERRORS} from 'modules/authentication/constants';
import {ContactData} from 'modules/user/components/ContactData';
import {selectUserData} from 'modules/user/selectors';
import {patchProfile} from 'modules/user/thunks';
import {Profile} from 'new-models';
import React, {memo, useCallback} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {PersonalDataMain} from './components/PersonalDataMain';
import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

const individualValidationSchema = Yup.object().shape({
    firstname: Yup.string().required(ERRORS.firstNameRequired),
    lastname: Yup.string().required(ERRORS.lastNameRequired),
});

const legalValidationSchema = Yup.object().shape({
    inn: Yup.string().test('inn-valid', ERRORS.invalidINN, (value) => {
        if (!value) {
            return false;
        }
        return 10 === value.length;
    }),
    kpp: Yup.string().test('kpp-valid', ERRORS.invalidKPP, (value) => {
        if (!value) {
            return false;
        }
        return 9 === value.length;
    }),
});

export const PersonalDataForm = memo<TPropsWithClassName>(function PersonalDataForm({className}) {
    const dispatch = useAppDispatch();
    const userData = useAppSelector(selectUserData);

    const showSuccessToast = useCallback(() => {
        toast(<Toast hasIcon={false} isNeedCloseButton text="Настройки профиля сохранены!" theme="info" />, {
            className: 'notification',
            position: isMobileByScreen() ? 'bottom-center' : 'bottom-right',
        });
    }, []);

    const onFormSubmit = useCallback(
        async (values: Profile) => {
            await dispatch(patchProfile({...values, phone: values.phone?.replace('+', '')}));

            showSuccessToast();
        },
        [dispatch, showSuccessToast]
    );

    if (!userData) {
        return null;
    }

    return (
        <Grid className={style.root}>
            <Formik
                initialValues={userData}
                onSubmit={onFormSubmit}
                validationSchema={userData.isLegal ? legalValidationSchema : individualValidationSchema}
            >
                {({handleSubmit}) => (
                    <Form className={columnStyle} onSubmit={handleSubmit}>
                        <div className={classNames(className)}>
                            <PersonalDataMain />
                        </div>
                    </Form>
                )}
            </Formik>

            <ContactData className={columnStyle} userData={userData} />
        </Grid>
    );
});
