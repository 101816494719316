import {Checkbox} from 'components/Checkbox';
import {TField} from 'core/types';

export const DefaultAddress: FC<TField> = ({className, value, ...inputProps}) => {
    return (
        <Checkbox
            checked={Boolean(value)}
            className={className}
            isLabel
            label="Использовать адрес как по умолчанию"
            {...inputProps}
        />
    );
};
