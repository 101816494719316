import {getRuntimeConfig} from 'core/next/helpers';
import Script from 'next/script';
import React, {memo} from 'react';

const JivoChatScript = memo(function JivoChatScript() {
    const jivoChatId = getRuntimeConfig().NEXT_PUBLIC_JIVO_CHAT_ID;
    const jivoChatUrl = getRuntimeConfig().NEXT_PUBLIC_JIVO_CHAT_URL;

    return <Script async src={`${jivoChatUrl}/${jivoChatId}`} />;
});

export default JivoChatScript;
