import classNames from 'classnames';
import {BackButton, IProps as IBackButtonProps} from 'components/BackButton';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {TPropsWithClassName} from 'core/types';
import React, {memo, PropsWithChildren} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

interface IProps extends IBackButtonProps, TPropsWithClassName, PropsWithChildren {
    hasBackButton?: boolean;
    classNameBackButton?: string;
    withContainer?: boolean;
}

export const PageHeaderTitle = memo<IProps>(function PageHeaderTitle({
    hasBackButton = true,
    className,
    classNameBackButton,
    children,
    withContainer = true,
    ...restBackButtonProps
}) {
    return (
        <Grid className={classNames(style.root, className, {[style.withContainer]: withContainer})} noRowGap>
            {hasBackButton && (
                <BackButton
                    className={classNames(columnStyle, style.backButton, classNameBackButton)}
                    {...restBackButtonProps}
                />
            )}
            <div className={columnStyle}>{children}</div>
        </Grid>
    );
});
