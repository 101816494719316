import {MIN_DESKTOP_WIDTH, MIN_TABLET_SM_MIN_WIDTH} from 'core/constants';
/* eslint-disable sort-keys*/
export const BREAKPOINT_SLIDER = {
    [0]: {
        spaceBetween: 12,
        slidesPerView: 1.2,
    },
    [MIN_TABLET_SM_MIN_WIDTH]: {
        spaceBetween: 12,
        slidesPerView: 2,
    },
    [MIN_DESKTOP_WIDTH]: {
        spaceBetween: 24,
        slidesPerView: 2,
    },
};
