import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {OnlyMobile} from 'components/OnlyMobile';
import {ScrollTo} from 'components/ScrollTo';
import {useViewCategory} from 'modules/analytics/hooks/useViewCategory';
import {RelatedCollectionsList} from 'modules/collections/components/RelatedCollectionsList';
import {IProps as IProductListProps, ProductList} from 'modules/products/components/ProductList';
import {ProductListControlBar} from 'modules/products/components/ProductListControlBar';
import {ProductListDesktopHeader} from 'modules/products/components/ProductListDesktopHeader';
import {ProductListMobileHeader} from 'modules/products/components/ProductListMobileHeader';
import {ProductsCount} from 'modules/products/components/ProductsCount';
import {COLUMNS_COUNT_LAYOUT, COLUMNS_COUNT_PRODUCT_ITEM, PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {ProductListPageContext, ProductListPageFunctionalityContext} from 'modules/products/context';
import {useListingTitle} from 'modules/products/hooks/useListingTitle';
import {useProductRestore} from 'modules/products/hooks/useProductRestore';
import {ViewedProductsSlider} from 'modules/slider/components/ViewedProductsSlider';
import {ProductShort} from 'new-models';
import Head from 'next/head';
import {FilterList} from 'plugins/modules/filter/components/FilterList';
import {FiltersTagList} from 'plugins/modules/filter/components/FiltersTagList';
import {FilterContext} from 'plugins/modules/filter/context';
import {ListingContext} from 'plugins/modules/listing/context';
import {useListType} from 'plugins/modules/listing/hooks/useListType';
import {ListPagination} from 'plugins/modules/pagination/components/ListPagination';
import {PaginationContext} from 'plugins/modules/pagination/context';
import {usePageChange} from 'plugins/modules/pagination/hooks/usePageChange';
import {usePaginationType} from 'plugins/modules/pagination/hooks/usePaginationType';
import {SortingContext} from 'plugins/modules/sorting/context';
import {useSortChange} from 'plugins/modules/sorting/hooks/useSortChange';
import React, {FC, ReactNode, useContext, useMemo, useRef} from 'react';

import style from './style.module.scss';

interface IProps {
    ProductListComponent?: FC<IProductListProps>;
    MobileHeaderComponent?: ReactNode;
    noMobileHeaderOffset?: boolean;
    withBanners?: boolean;
}

const {filter, productsFilterHide, productsFilterShow} = COLUMNS_COUNT_LAYOUT;

const filterColumnStyle = getGridColumnStyles({md: filter});
const productsFilterHideColumnStyle = getGridColumnStyles({md: productsFilterHide});
const productsFilterShowColumnStyle = getGridColumnStyles({md: productsFilterShow});
const columnStyle = getGridColumnStyles();

// eslint-disable-next-line complexity
export const ProductListPage: FC<IProps> = ({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ProductListComponent = ProductList,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MobileHeaderComponent,
    noMobileHeaderOffset,
    withBanners = true,
}) => {
    usePaginationType();

    const functionality = useContext(ProductListPageFunctionalityContext);
    const {category} = useContext(ProductListPageContext);
    const {sortingList} = useContext(SortingContext);
    const {count, pageSize, page} = useContext(PaginationContext);
    const {filterList, isFilterPanelVisible} = useContext(FilterContext);
    const {listType, list, isListPartiallyLoading} = useContext(ListingContext);
    const onPageChange = usePageChange();
    const onSortChange = useSortChange();
    const title = useListingTitle(category);
    useListType();

    useViewCategory(category);

    const topNodeRef = useRef<HTMLDivElement>(null);
    const productListRef = useRef<HTMLDivElement>(null);

    const {scrollId, handleScrollComplete} = useProductRestore();

    const isFilterAvailable = useMemo<boolean>(() => {
        return Boolean(filterList.length && isFilterPanelVisible);
    }, [isFilterPanelVisible, filterList.length]);

    const renderMobileHeader = useMemo<ReactNode>(() => {
        const renderListControlBar = (
            <div className={classNames(style.productListLayoutSorting, columnStyle)}>
                <ProductListControlBar count={count} onSortChange={onSortChange} sortList={sortingList} />
            </div>
        );

        if (functionality.mobileHeader) {
            return (
                <div className={columnStyle}>
                    <ProductListMobileHeader
                        Component={MobileHeaderComponent}
                        category={category}
                        withBanners={withBanners}
                    >
                        <Grid noRowGap>
                            {renderListControlBar}
                            {functionality.searchCountIndicator && (
                                <OnlyMobile>
                                    <ProductsCount
                                        className={classNames(style.productListSearchCount, columnStyle)}
                                        count={count}
                                    />
                                </OnlyMobile>
                            )}
                        </Grid>
                    </ProductListMobileHeader>
                </div>
            );
        }

        return renderListControlBar;
    }, [
        category,
        count,
        functionality.mobileHeader,
        functionality.searchCountIndicator,
        MobileHeaderComponent,
        onSortChange,
        sortingList,
        withBanners,
    ]);

    const productCardColumnStyle: string = useMemo(() => {
        const {filterHide, filterShow, listView, mobile} = COLUMNS_COUNT_PRODUCT_ITEM;

        if (listType === PRODUCT_LIST_VIEW.list) {
            return getGridColumnStyles({
                md: listView,
                sm: listView,
            });
        }

        return getGridColumnStyles({
            md: isFilterPanelVisible ? filterShow : filterHide,
            sm: mobile,
        });
    }, [isFilterPanelVisible, listType]);

    return (
        <>
            {Boolean(title) && (
                <Head>
                    <title>{title}</title>
                </Head>
            )}
            <ProductListDesktopHeader withBanners={withBanners} />
            <div
                className={classNames(style.productListLayout, {
                    [style.productListLayoutNoFilter]: !isFilterAvailable,
                })}
                ref={topNodeRef}
            >
                <Grid>
                    {isFilterPanelVisible ? <FilterList className={filterColumnStyle} /> : null}
                    <Grid
                        className={classNames(
                            style.productListLayoutListingContainer,
                            isFilterPanelVisible ? productsFilterShowColumnStyle : productsFilterHideColumnStyle
                        )}
                        noRowGap
                    >
                        {renderMobileHeader}
                        <div
                            className={classNames(
                                style.productListLayoutListing,
                                {
                                    [style.noMobileHeaderOffset]: noMobileHeaderOffset,
                                },
                                columnStyle
                            )}
                            ref={productListRef}
                        >
                            <Grid noRowGap>
                                <FiltersTagList className={classNames(style.filtersTagList, columnStyle)} />
                                <ScrollTo
                                    behavior="smooth"
                                    block="nearest"
                                    container={productListRef.current}
                                    onScroll={handleScrollComplete}
                                    scrollId={scrollId}
                                    scrollMarkAttribute="data-product-card-id"
                                >
                                    <div className={classNames(style.listing, columnStyle)}>
                                        <ProductListComponent
                                            categoryType={category?.type}
                                            listType={listType}
                                            productCardRootClassName={productCardColumnStyle}
                                            productList={list as ProductShort[]}
                                        />
                                    </div>
                                </ScrollTo>
                            </Grid>
                        </div>
                        {Boolean(list.length) && functionality.pagination && (
                            <div
                                className={classNames(style.productListLayoutPaginationContainer, columnStyle)}
                                data-skeleton-item
                            >
                                <ListPagination
                                    isLoading={isListPartiallyLoading}
                                    onChange={onPageChange}
                                    pageNomer={page}
                                    pageSize={pageSize}
                                    recordCount={count}
                                    scrollTarget={topNodeRef.current}
                                />
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
            {functionality.showRelatedCollectionList && (
                <RelatedCollectionsList className={style.productListPageLayoutLookedList} />
            )}
            <ViewedProductsSlider className={style.productListPageLayoutLookedList} />
        </>
    );
};
