import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {CartContext} from 'modules/cart/context';
import {CartPage} from 'modules/cart/pages/CartPage';
import {CART_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const CartPageSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.cart);

    const dividedByAvailability = CART_SKELETON_DATA.products.filter((item) => item.product.availability);

    return (
        <CartContext.Provider
            value={{
                cartProductsList: CART_SKELETON_DATA.products,
                checkedListCondition: 'none',
                countSelected: 0,
                divideByAvailability: [dividedByAvailability, []],
                isCartLoading: false,
                isLegalEntity: true,
            }}
        >
            <CartPage />
        </CartContext.Provider>
    );
};
