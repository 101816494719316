import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import handHeartIcon from 'public/icons/hand-heart.svg';
import heartIcon from 'public/icons/heart.svg';
import percentIcon from 'public/icons/percent.svg';

import style from './style.module.scss';

const advantagesList = [
    {icon: <SvgIcon svg={heartIcon} />, id: 1, title: 'Не потерять свое избранное'},
    {icon: <SvgIcon svg={handHeartIcon} />, id: 2, title: 'Покупать любимые товары со скидками'},
    {icon: <SvgIcon svg={percentIcon} />, id: 3, title: 'Оформить первый заказ со скидкой -15%'},
];

export const HeaderAuthPopup = () => {
    const handleProfileClick = useProfileClick();

    return (
        <div className={style.root}>
            <Typography className={style.mainTitle} color="gray100" element="h5" variant="h5">
                Добро пожаловать
            </Typography>
            <Typography className={style.mainDescription} element="p" variant="p">
                Авторизуйтесь или зарегистрируйтесь по номеру телефона и вы сможете:
            </Typography>
            <ul className={style.advantagesList}>
                {advantagesList.map((item) => (
                    <li className={style.advantagesItem} key={item.id}>
                        <span className={style.advantagesIcon}>{item.icon}</span>
                        <Typography color="gray80" element="p" variant="p">
                            {item.title}
                        </Typography>
                    </li>
                ))}
            </ul>
            <Button isStretched onClick={handleProfileClick}>
                Войти или зарегистрироваться
            </Button>
        </div>
    );
};
