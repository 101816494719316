import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {IS_GEOLOCATION_ON, USER_LOCATION} from 'modules/locations/constants';
// import {distanceBetweenLocations} from 'modules/locations/helpers';
import {selectLocationsList} from 'modules/locations/selectors';
import {actionSetIsLocationSelected, actionSetUserLocation} from 'modules/locations/slice';
import {Location} from 'new-models';
import {useCallback} from 'react';

export const useCheckForActuality = (): ((location: Location) => void) => {
    const dispatch = useAppDispatch();

    const locationsList = useAppSelector(selectLocationsList);

    return useCallback(
        (currentLocation: Location) => {
            const isGeolocationAlreadyIncluded = Boolean(localStorage.getItem(IS_GEOLOCATION_ON));
            const previousLocationId = localStorage.getItem(USER_LOCATION) as Location['id'];
            const previousLocation = locationsList?.find(({id}) => id === previousLocationId);

            if (previousLocation && isGeolocationAlreadyIncluded) {
                dispatch(actionSetUserLocation(previousLocation));
                dispatch(actionSetIsLocationSelected(true));
                return;
            }

            if (!currentLocation?.id) {
                return;
            }

            // const distanceBetween = distanceBetweenLocations(currentLocation, previousLocation ?? currentLocation);

            // if ((distanceBetween ?? 0) > SEARCH_RADIUS) {
            //     return;
            // }

            dispatch(actionSetUserLocation(currentLocation));
            dispatch(actionSetIsLocationSelected(true));
            localStorage.setItem(USER_LOCATION, currentLocation.id);
            localStorage.setItem(IS_GEOLOCATION_ON, IS_GEOLOCATION_ON);
        },
        [dispatch, locationsList]
    );
};
