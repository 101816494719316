import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {CategoryGallery} from 'modules/categories/components/CategoryGallery';
import {CategoryShort} from 'new-models';
import React, {FC} from 'react';
import {CATALOG_ROUTE} from 'routing/constants';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    categoryList?: CategoryShort[];
}

export const HomePopularCategory: FC<IProps> = ({categoryList, className}) => {
    if (!categoryList?.length) {
        return null;
    }

    return (
        <div className={classNames(style.root, className)}>
            <CategoryGallery catalogRoute={CATALOG_ROUTE} list={categoryList} />
        </div>
    );
};
