import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {AuthContext} from 'modules/authentication/context';
import {actionSetFieldParams} from 'modules/authentication/slice';
import {TFieldsHandler, TFieldsHandlerParams} from 'modules/authentication/types';
import {useCallback, useContext} from 'react';

export const useFieldHandler = (): TFieldsHandler => {
    const dispatch = useAppDispatch();
    const {fieldsParams: currentFieldsParams} = useContext(AuthContext);

    return useCallback(
        ({additionalCallback, fields}: TFieldsHandlerParams) => {
            dispatch(actionSetFieldParams({...currentFieldsParams, ...fields}));

            additionalCallback?.(fields);
        },
        [currentFieldsParams, dispatch]
    );
};
