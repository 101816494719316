import {MODALS} from 'modules/modals/constants';
import {useModalApi} from 'modules/modals/hooks/useModalApi';
import {useMemo} from 'react';

interface IUseProfileEmailChangeModalApi {
    close: () => void;
    open: () => void;
}
export const useProfileEmailChangeModalApi = (): IUseProfileEmailChangeModalApi => {
    const {open, close} = useModalApi();

    return useMemo(
        () => ({
            close: () => {
                close(MODALS.PROFILE_CHANGE_EMAIL_MODAL.name);
            },
            open: () => {
                open(MODALS.PROFILE_CHANGE_EMAIL_MODAL.name);
            },
        }),
        [close, open]
    );
};
