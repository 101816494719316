import classNames from 'classnames';
import {IProps as IMediaHarvesterProps, MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import {Link as TLink, Promo} from 'new-models';
import Link from 'next/link';
import React, {FC} from 'react';

import style from './style.module.scss';

interface IBannerProps extends TLink, TPropsWithChildrenRequire {}
const Banner: FC<IBannerProps> = (props) => {
    const {value, children} = props;
    if (!value) {
        return <div>{children}</div>;
    }

    return (
        <Link href={value}>
            <a>{children}</a>
        </Link>
    );
};

interface IProps extends Promo, TPropsWithClassName {
    options: IMediaHarvesterProps['options'];
}

export const ProductListDesktopHeaderBanner: FC<IProps> = ({className, ...promo}) => {
    const {link, media, title, description, options} = promo;

    return (
        <div className={classNames(className, style.desktopHeaderBanner)}>
            <Banner {...link}>
                <MediaHarvester media={media} options={options} />
                <div className={style.content}>
                    <Typography color="white100" variant="h4-bold">
                        {title}
                    </Typography>
                    <Typography color="gray30" variant="h6">
                        {description}
                    </Typography>
                </div>
            </Banner>
        </div>
    );
};
