import {InfoBlock} from 'components/InfoBlock';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import {selectIsLoggedIn} from 'modules/user/selectors';
import React, {memo, ReactNode, useMemo} from 'react';

interface IProps extends TPropsWithClassName {}

export const CheckoutInfoBlock = memo<IProps>(function CartInfoBlock({className}) {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const handleProfileClick = useProfileClick();

    return useMemo<ReactNode>(() => {
        if (!isLoggedIn) {
            return (
                <InfoBlock
                    buttonText="Войти или зарегистрироваться"
                    className={className}
                    contentText="Авторизуйтесь или зарегистрируйтесь, чтобы получить доступ к личным данным и статусам заказа"
                    isHideIcon
                    onClick={handleProfileClick}
                />
            );
        }

        return null;
    }, [isLoggedIn, className, handleProfileClick]);
});
