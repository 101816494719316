import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TCompareProductsState} from 'modules/compareProducts/types';
import {ProductBase} from 'new-models';

export const compareProductsModuleName = 'compareProducts';

const initialState: TCompareProductsState = {
    compareProductsList: [],
};
export const compareProductsSlice = createSlice({
    initialState,
    name: compareProductsModuleName,
    reducers: {
        actionDeleteFromCompareProducts(state: TCompareProductsState, {payload}: PayloadAction<ProductBase>) {
            state.compareProductsList = state.compareProductsList.filter(({id}) => payload.id !== id);
        },
        actionPushToCompareProducts(state: TCompareProductsState, {payload}: PayloadAction<ProductBase>) {
            state.compareProductsList.push(payload);
        },
        actionSetCompareProductsList(
            state: TCompareProductsState,
            {payload}: PayloadAction<TCompareProductsState['compareProductsList']>
        ) {
            state.compareProductsList = payload;
        },
    },
});

export const {
    actions: {actionDeleteFromCompareProducts, actionPushToCompareProducts, actionSetCompareProductsList},
} = compareProductsSlice;

export const {reducer: compareProductsReducer} = compareProductsSlice;
