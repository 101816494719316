import {Dropdown, DropdownLinkList} from 'components/Dropdown';
import {SvgIcon} from 'components/SvgIcon';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {HeaderElement} from 'modules/header/components/HeaderElement';
import {selectHeaderTopForBuyersMenu} from 'modules/menu/selectors';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {FC} from 'react';

import style from './style.module.scss';

export const InfoForBuyerDropdown: FC = () => {
    const menuTopForBuyers = useAppSelector(selectHeaderTopForBuyersMenu);

    return (
        <div className={style.root}>
            <Dropdown
                className={style.popup}
                list={<DropdownLinkList className={style.list} list={menuTopForBuyers?.sections} />}
            >
                <HeaderElement variant="p-regular">
                    Для покупателей
                    <SvgIcon svg={chevronDownIcon} />
                </HeaderElement>
            </Dropdown>
        </div>
    );
};
