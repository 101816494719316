import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {actionSetActiveIdx} from 'modules/slider/slice';
import {MouseEventHandler, useCallback} from 'react';

export const useHandleShowGalleryModal = () => {
    const dispatch = useAppDispatch();

    return useCallback(
        (idx?: number): MouseEventHandler<HTMLAnchorElement | HTMLButtonElement> =>
            (event) => {
                event.preventDefault();

                if (idx) {
                    dispatch(actionSetActiveIdx(idx));
                }

                dispatch(showModal(MODALS.MAIN_GALLERY.name));
            },
        [dispatch]
    );
};
