import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {Typography} from 'components/Typography';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {MediaTypeEnum, OrderDetail, OrderItem} from 'new-models';
import Link from 'next/link';
import React from 'react';

import style from './style.module.scss';

interface IProps extends Pick<OrderDetail, 'products'>, TPropsWithClassName {}

const Product: FC<OrderItem> = ({product}) => {
    const {id, media} = product;

    const productUrl = useProductUrl(product);

    return (
        <li className={style.item} key={id}>
            <Link href={productUrl}>
                <a>
                    <MediaWithImageFallback
                        media={media}
                        mediaType={MediaTypeEnum.Image}
                        options={generateMediaOptions({
                            resizeKey: IMAGE_RESIZE_CODE.w200,
                            type: MediaTypeEnum.Image,
                        })}
                    />
                </a>
            </Link>
        </li>
    );
};

export const Products: FC<IProps> = ({products}) => {
    return (
        <div className={style.root}>
            <Typography color="gray60">Состав заказа</Typography>
            <ul className={style.list}>{products.map(Product)}</ul>
        </div>
    );
};
