import {isUserAuthenticated} from 'core/helpers';
import {useAuthBySmsStrategy} from 'modules/authentication/hooks/strategies/useAuthBySmsStrategy';
import {useRouter} from 'next/router';
import {useCallback} from 'react';
import {PROFILE_ROOT_ROUTE} from 'routing/constants';

export const useProfileClick = () => {
    const router = useRouter();

    const {startAuthBySms} = useAuthBySmsStrategy();

    return useCallback(
        () => (isUserAuthenticated() ? router.push(PROFILE_ROOT_ROUTE) : startAuthBySms()),
        [router, startAuthBySms]
    );
};
