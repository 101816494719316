import classNames from 'classnames';
import {Typography} from 'components/Typography';
import React, {forwardRef, InputHTMLAttributes, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    isLabel?: boolean;
    disabled?: boolean;
    isError?: boolean;
}

export const RadioButton = forwardRef<HTMLInputElement, IProps>(function RadioButton(
    {className, label, isLabel, disabled, value, isError = false, ...restInputAttributes},
    ref
) {
    const uid = useId();

    return (
        <div className={classNames(style.radioButton, className, 'radio-component')}>
            <input
                {...restInputAttributes}
                className={style.radio}
                disabled={disabled}
                {...(isError && {checked: false})}
                id={uid}
                ref={ref}
                type="radio"
                value={value}
            />
            <label className={classNames(style.label, {[style.isError]: isError})} htmlFor={uid} tabIndex={0}>
                {isLabel && (
                    <Typography className={style.text} variant="p">
                        {label || value}
                    </Typography>
                )}
            </label>
        </div>
    );
});
