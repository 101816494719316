import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {createContext} from 'react';

export interface IProductListPageContextProps {
    category?: ICategoryNode;
}

export const ProductListPageContext = createContext<IProductListPageContextProps>({} as IProductListPageContextProps);

export interface IProductListFunctionalityProps {
    countIndicator?: boolean;
    collectionSwitcher?: boolean;
    pagination?: boolean;
    viewSwitcher?: boolean;
    sortingSelector?: boolean;
    title?: boolean;
    mobileHeader?: boolean;
    showRelatedCollectionList?: boolean;
    searchCountIndicator?: boolean;
    collectionFilterButton?: boolean;
    orderFilterButton?: boolean;
    perPage?: boolean;
}

export const ProductListPageFunctionalityContext = createContext<IProductListFunctionalityProps>({
    collectionFilterButton: false,
    collectionSwitcher: true,
    countIndicator: true,
    mobileHeader: true,
    orderFilterButton: false,
    pagination: true,
    perPage: true,
    searchCountIndicator: false,
    showRelatedCollectionList: false,
    sortingSelector: true,
    title: true,
    viewSwitcher: true,
});
