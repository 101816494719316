import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {MediaTypeEnum, Promo} from 'new-models';
import Link from 'next/link';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    bannerSlide: Promo;
}

export const HomePageBannerSlide = memo<IProps>(function HomePageBannerSlide({className, bannerSlide}) {
    const {title, buttonMessage, isLight} = bannerSlide;

    const content = useMemo(
        () =>
            Boolean(title || buttonMessage) && (
                <div className={style.content}>
                    {title && (
                        <Typography
                            className={style.title}
                            color={isLight ? 'gray100' : 'white100'}
                            element="h1"
                            variant="h1"
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    // eslint-disable-next-line @typescript-eslint/naming-convention
                                    __html: title,
                                }}
                            />
                        </Typography>
                    )}
                    {buttonMessage && (
                        <Anchor
                            as="button"
                            icon="right"
                            mode={isLight ? 'dark' : 'light'}
                            size="large"
                            theme="standalone"
                        >
                            {buttonMessage}
                        </Anchor>
                    )}
                </div>
            ),
        [buttonMessage, isLight, title]
    );

    if (bannerSlide.link.value) {
        return (
            <Link href={bannerSlide.link.value}>
                <a className={style.container} data-skeleton-item title={bannerSlide.media.title}>
                    {content}
                    <MediaHarvester
                        className={classNames(className, style.image)}
                        media={bannerSlide.media}
                        options={generateMediaOptions({
                            resizeMapKey: 'homePageMainBanner',
                            type: MediaTypeEnum.ImageAdaptive,
                        })}
                    />
                </a>
            </Link>
        );
    }

    return (
        <div className={style.container}>
            {content}
            <MediaHarvester
                className={classNames(className, style.image)}
                media={bannerSlide.media}
                options={generateMediaOptions({
                    resizeMapKey: 'homePageMainBanner',
                    type: MediaTypeEnum.ImageAdaptive,
                })}
            />
        </div>
    );
});
