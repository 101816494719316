import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TabContext} from 'components/TabProvider';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {forwardRef, InputHTMLAttributes, useContext, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement>, TPropsWithClassName {
    label?: string;
    hasDropDown?: boolean;
    disabled?: boolean;
}

export const Tab = forwardRef<HTMLInputElement, IProps>(function Tab(
    {value, className, label, hasDropDown, disabled, ...restInputAttributes}: IProps,
    ref
) {
    const uid = useId();
    const name = useContext(TabContext);

    return (
        <div className={style.tabContainer}>
            <input
                {...restInputAttributes}
                className={classNames(style.tab, className)}
                disabled={disabled}
                id={uid}
                name={name}
                ref={ref}
                type="radio"
                value={value}
            />
            <label
                className={classNames(
                    style.tabLabel,
                    {
                        [style.tabDropDownLabel]: hasDropDown,
                    },
                    'tab-label-component'
                )}
                htmlFor={uid}
            >
                <Typography variant="h6">{label || value}</Typography>
                {hasDropDown && (
                    <label className={style.tabDropDown} htmlFor={uid}>
                        <SvgIcon svg={chevronDownIcon} />
                    </label>
                )}
            </label>
        </div>
    );
});
