import 'react-datepicker/dist/react-datepicker.css';

import classNames from 'classnames';
import {Button} from 'components/Button';
import {DateSelect} from 'components/DateSelect';
import {Input, IProps as IInputProps} from 'components/Input';
import {IInputCaptionPublicProps} from 'components/InputCaption';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import ru from 'date-fns/locale/ru';
import range from 'lodash/range';
import calendarIcon from 'public/icons/calendar.svg';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {FC, forwardRef, ReactNode, useCallback, useMemo} from 'react';
import DatePicker, {ReactDatePickerProps, registerLocale} from 'react-datepicker';
import InputMask, {Props} from 'react-input-mask';

import style from './style.module.scss';

registerLocale('ru', ru);

type TInputProps = Pick<IInputProps, 'labelText' | 'errorText'>;

interface IDatePickerProps extends ReactDatePickerProps, TInputProps, IInputCaptionPublicProps {
    placeholderText?: string;
    customInput?: ReactNode;
    mask?: Props['mask'];
    rootClassName?: string;
}

const CustomInput = forwardRef<InputMask, Partial<Props> & TInputProps & IInputCaptionPublicProps>((props, ref) => {
    const {placeholder, labelText, errorText, captionText} = props;

    return (
        <InputMask {...props} mask={props.mask || '99.99.99'} maskPlaceholder="" ref={ref}>
            <Input
                captionText={captionText}
                errorText={errorText}
                inputClassname={style.calendarInput}
                isBorder
                labelText={labelText || placeholder}
                onExtraActionClick={props.onClick}
                trailIcon={<SvgIcon className={style.calendarInputIcon} svg={calendarIcon} />}
            />
        </InputMask>
    );
});

const ChevronIcon: FC = () => <SvgIcon svg={chevronDownIcon} />;

export const DatePickerCalendar: FC<IDatePickerProps> = ({
    selected,
    onChange,
    startDate,
    endDate,
    placeholderText,
    customInput,
    mask,
    labelText,
    errorText,
    rootClassName,
    captionText,
    ...props
}) => {
    const yearsRange = useMemo(() => {
        const currentYear = new Date().getFullYear();
        const firstYear = currentYear - 100;
        return range(firstYear, currentYear + 1, 1).sort((a, b) => b - a);
    }, []);
    const yearsOptions = useMemo(
        () =>
            yearsRange.map((item, index) => {
                return {
                    id: index,
                    label: String(item),
                    value: item,
                };
            }),
        [yearsRange]
    );
    const getCurrentDateOption = useCallback(
        (date: Date) => yearsOptions.find((item) => item.value === date.getFullYear()),
        [yearsOptions]
    );

    return (
        <div className={classNames(style.datePickerCalendar, rootClassName)}>
            <DatePicker
                customInput={
                    customInput || (
                        <CustomInput
                            captionText={captionText}
                            errorText={errorText}
                            labelText={labelText}
                            mask={mask}
                        />
                    )
                }
                dateFormatCalendar="MMMM"
                endDate={endDate}
                locale="ru"
                onChange={onChange}
                placeholderText={placeholderText}
                scrollableYearDropdown
                selected={selected}
                showYearDropdown
                startDate={startDate}
                {...props}
                renderCustomHeader={({
                    date,
                    monthDate,
                    changeYear,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className={style.headerContainer}>
                        <Button
                            className={classNames(style.arrowIcon, style.left)}
                            disabled={prevMonthButtonDisabled}
                            isSquare
                            leftIcon={<ChevronIcon />}
                            onClick={decreaseMonth}
                            size="small"
                            theme="ghost"
                        />
                        <span className={style.monthName}>
                            <Typography color="gray100" element="span" variant="p">
                                {monthDate.toLocaleString('ru-RU', {
                                    month: 'long',
                                })}
                            </Typography>
                        </span>
                        <DateSelect
                            defaultValue={getCurrentDateOption(date)}
                            handleChange={({value}) => changeYear(value)}
                            options={yearsOptions}
                        />
                        <Button
                            className={classNames(style.arrowIcon, style.right)}
                            disabled={nextMonthButtonDisabled}
                            isSquare
                            leftIcon={<ChevronIcon />}
                            onClick={increaseMonth}
                            size="small"
                            theme="ghost"
                        />
                    </div>
                )}
            />
        </div>
    );
};
