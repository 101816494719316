import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {
    TCreateAddressResponse,
    TGetAddressListResponse,
    TUpdateAddressResponse,
} from 'modules/addresses/models/AddressResponse';
import {
    actionDeleteAddress,
    actionSetAddressList,
    actionSetNewAddress,
    actionUpdateAddress,
} from 'modules/addresses/slice';
import {DeliveryAddressCreateDataRequest, DeliveryAddressUpdateDataRequest} from 'new-models';

export const getAddressList: TAppThunk<void> = async (dispatch, _, {addressesService}) => {
    const data = await dispatch(
        requestAction<TGetAddressListResponse>({
            requestCb: () => addressesService.getAddressList(),
            type: actionSetAddressList.type,
        })
    );

    const {deliveryAddresses} = data || {};

    if (!deliveryAddresses) {
        return;
    }

    dispatch(actionSetAddressList(deliveryAddresses || []));
};

export const addNewAddress =
    (body: DeliveryAddressCreateDataRequest): TAppThunk<void> =>
    async (dispatch, _, {addressesService}) => {
        const data = await dispatch(
            requestAction<TCreateAddressResponse>({
                requestCb: () => addressesService.postAddress(body),
                type: actionSetNewAddress.type,
            })
        );

        const {deliveryAddress} = data || {};

        if (!deliveryAddress) {
            return;
        }

        dispatch(actionSetNewAddress(deliveryAddress));
    };

export const updateAddress =
    ({id, body}: {id: string; body: DeliveryAddressUpdateDataRequest}): TAppThunk<void> =>
    async (dispatch, _, {addressesService}) => {
        const data = await dispatch(
            requestAction<TUpdateAddressResponse>({
                requestCb: () => addressesService.patchAddress(id, body),
                type: actionUpdateAddress.type,
            })
        );

        const {deliveryAddress} = data || {};

        if (!deliveryAddress) {
            return;
        }

        dispatch(actionUpdateAddress(deliveryAddress));
    };

export const deleteAddress =
    (id: string): TAppThunk<void> =>
    async (dispatch, _, {addressesService}) => {
        await dispatch(
            requestAction<any>({
                requestCb: () => addressesService.deleteAddress(id),
                type: actionDeleteAddress.type,
            })
        );

        dispatch(actionDeleteAddress(id));
    };
