import {isUrlActuallyChange} from 'core/helpers';
import {onHistoryChange} from 'core/next/helpers';
import {TBeforePopStateCallback} from 'core/types';
import Router from 'next/router';
import {useCallback, useEffect, useMemo, useState} from 'react';

export const useUrlCapture = (): string => {
    const [url, setUrl] = useState<string>();

    const handleRouterChangeStart = useCallback((newUrl: string) => {
        const [newPathName] = newUrl.split('?');

        if (!isUrlActuallyChange(newUrl)) {
            return true;
        }

        setUrl(newPathName);

        return true;
    }, []);

    const handleRouterBack = useCallback<TBeforePopStateCallback>(({as}) => {
        const [newPathName] = as.split('?');
        setUrl(newPathName);
        return true;
    }, []);

    useEffect(() => {
        const disposer = onHistoryChange(handleRouterBack);
        Router.events.on('routeChangeStart', handleRouterChangeStart);

        return () => {
            Router.events.off('routeChangeStart', handleRouterChangeStart);
            disposer(handleRouterBack);
        };
    }, [handleRouterBack, handleRouterChangeStart]);

    return useMemo<string>(() => {
        return url || '';
    }, [url]);
};
