import {ISortingState, TWithSorting} from 'plugins/modules/sorting/types';

export const initialSortingState: ISortingState = {
    sortingList: [],
};

export const withSorting = <T>(originState: T): TWithSorting<T> => {
    return {
        ...originState,
        ...initialSortingState,
    };
};
