import {PayloadAction} from '@reduxjs/toolkit';
import {isMobileByScreen} from 'core/helpers';
import {TListenerEffect} from 'core/redux/types';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {selectBottomBarPrevActiveItem} from 'modules/bottombar/selectors';
import {actionSetActive} from 'modules/bottombar/slice';
import {MODALS} from 'modules/modals/constants';
import {actionSetPortalHidden, actionSetPortalVisible} from 'modules/portal/slice';
import {IPortalState} from 'modules/portal/types';
import {GLOBAL_FILTER_ACTIONS} from 'plugins/modules/filter/actions';

export const bottomBarModalEffect: TListenerEffect<PayloadAction<keyof IPortalState>> = (
    dispatch,
    getState,
    modalAction
) => {
    if (!modalAction) {
        return;
    }

    const {payload} = modalAction;

    if (payload !== MODALS.CATALOG_MENU.name) {
        return;
    }

    const {type} = modalAction;

    if (type === actionSetPortalVisible.type) {
        dispatch(actionSetActive(BOTTOM_BAR_BUTTONS.catalog));

        if (isMobileByScreen()) {
            dispatch(GLOBAL_FILTER_ACTIONS.actionSetIsFilterPanelVisible(false));
        }

        return;
    }

    if (type === actionSetPortalHidden.type) {
        const prevActiveItem = selectBottomBarPrevActiveItem(getState());
        dispatch(actionSetActive(prevActiveItem));
    }
};
