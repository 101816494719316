import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {TCollectionXState} from 'modules/collection-x/types';
import {HYDRATE} from 'next-redux-wrapper';
import {createFilterActions} from 'plugins/modules/filter/actions';
import {createFilterExtraReducers} from 'plugins/modules/filter/reducer';
import {withFilter} from 'plugins/modules/filter/state';
import {createListingActions} from 'plugins/modules/listing/actions';
import {withListing} from 'plugins/modules/listing/state';
import {createPaginationActions} from 'plugins/modules/pagination/actions';
import {withPagination} from 'plugins/modules/pagination/state';

export const collectionXModuleName = 'collection-x';

const initialState: TCollectionXState = withPagination(
    withListing(
        withFilter({
            collection: null,
            collectionAdvices: [],
            collectionSectionId: null,
            collectionSeoText: '',
        })
    )
);

export const collectionXSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[collectionXModuleName];
        },

        ...createFilterExtraReducers<TCollectionXState>(),
    },
    initialState,
    name: collectionXModuleName,
    reducers: {
        actionSetCollection(state: TCollectionXState, {payload}: PayloadAction<TCollectionXState['collection']>) {
            state.collection = payload;
        },

        actionSetCollectionAdvices(
            state: TCollectionXState,
            {payload}: PayloadAction<TCollectionXState['collectionAdvices']>
        ) {
            state.collectionAdvices = payload;
        },

        actionSetCollectionSectionId(
            state: TCollectionXState,
            {payload}: PayloadAction<TCollectionXState['collectionSectionId']>
        ) {
            state.collectionSectionId = payload;
        },

        actionSetSeoText(state: TCollectionXState, {payload}: PayloadAction<TCollectionXState['collectionSeoText']>) {
            state.collectionSeoText = payload;
        },

        ...createFilterActions<TCollectionXState>(),
        ...createListingActions<TCollectionXState>(),
        ...createPaginationActions<TCollectionXState>(),
    },
});

export const {
    actions: {
        actionSetCollectionAdvices,
        actionSetCollection,
        actionSetSeoText,
        actionSetFilterList,
        actionSetList,
        actionSetCollectionSectionId,
        actionSetPageCount,
    },
} = collectionXSlice;

export const {reducer: collectionXReducer} = collectionXSlice;
