import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {useEffect} from 'react';

export const useCloseByEscape = (shouldCloseByEscape: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ('Escape' === event.key) {
                dispatch(hideModal(MODALS.MAIN_GALLERY.name));
            }
        };

        if (!shouldCloseByEscape) {
            return document.removeEventListener('keydown', handleKeyDown);
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [dispatch, shouldCloseByEscape]);
};
