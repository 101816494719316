import {TCheckoutDottedNotationValues} from 'modules/checkout/types';

// @ts-expect-error-next-line
export const USER_ADDRESS_COUNT_FIELD_NAME: TCheckoutDottedNotationValues = 'tempFields.userAddressCount';
export const DELIVERY_TYPE_FIELD_NAME: TCheckoutDottedNotationValues = 'tempFields.deliveryType';

export const DELIVERY_ID_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.deliveryId';

export const PICKUP_POINT_ID_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.pickupPointId';
// @ts-expect-error-next-line
export const DELIVERY_ADDRESS_STRUCTURE_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address';
export const USER_ADDRESS_ID_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.id';

export const DELIVERY_ADDRESS_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.address';

// @ts-expect-error-next-line
export const DELIVERY_PRIVATE_HOUSE_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.isPrivateHouse';

export const DELIVERY_APARTMENT_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.apartment';

// @ts-expect-error-next-line
export const DELIVERY_ENTRANCE_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.entrance';

// @ts-expect-error-next-line
export const DELIVERY_FLOOR_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.floor';

export const DELIVERY_DOORCODE_FIELD_NAME: TCheckoutDottedNotationValues = 'deliveryData.address.doorcode';

export const CUSTOMER_LASTNAME_FIELD_NAME: TCheckoutDottedNotationValues = 'customer.lastname';

export const CUSTOMER_FIRSTNAME_FIELD_NAME: TCheckoutDottedNotationValues = 'customer.firstname';

export const CUSTOMER_PHONE_FIELD_NAME: TCheckoutDottedNotationValues = 'customer.phone';

export const CUSTOMER_EMAIL_FIELD_NAME: TCheckoutDottedNotationValues = 'customer.email';

export const COMMENT_FIELD_NAME: TCheckoutDottedNotationValues = 'comment';

export const PAYMENT_ID_FIELD_NAME: TCheckoutDottedNotationValues = 'paymentId';
