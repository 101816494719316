import {TAppDispatch} from 'core/redux/types';
import {TMindboxCartProduct} from 'modules/analytics/types';
import {TOAST_NOTIFICATIONS} from 'modules/cart/constants';
import {actionSetAfterRemoveToast} from 'modules/cart/slice';
import {BasketItem, PatchBasketRequestData} from 'new-models';

export const setAfterToast = (
    productListBefore: BasketItem[],
    productListAfter: BasketItem[],
    dispatch: TAppDispatch
): void => {
    if (1 === productListBefore.length - productListAfter.length) {
        dispatch(actionSetAfterRemoveToast(TOAST_NOTIFICATIONS.PRODUCT_REMOVED));
    } else if (productListBefore.length > productListAfter.length) {
        dispatch(actionSetAfterRemoveToast(TOAST_NOTIFICATIONS.PRODUCTS_REMOVED));
    }
};

export const makePatchBasket = (cartItem: TMindboxCartProduct): PatchBasketRequestData => {
    const {id, quantity} = cartItem;

    return {
        productId: id,
        quantity,
    };
};
