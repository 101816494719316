import {SkeletonService} from 'modules/skeleton/services/SkeletonService';
import {CategoryType} from 'new-models';

export const FIRST_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeFirstCategoryLevelSkeleton();

export const SIMPLE_SECOND_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeSecondCategoryLevelSkeleton(
    CategoryType.Simple
);

export const VISUAL_SECOND_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeSecondCategoryLevelSkeleton(
    CategoryType.Visual
);

// eslint-disable-next-line id-length
export const TECHNICAL_SECOND_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeSecondCategoryLevelSkeleton(
    CategoryType.Technical
);

export const MIXED_SECOND_CATEGORY_LEVEL_DATA = SkeletonService.makeSecondCategoryLevelSkeleton(CategoryType.Mixed);

export const SIMPLE_THIRD_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeThirdCategoryLevelSkeleton('simple');

export const VISUAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeThirdCategoryLevelSkeleton('visual');

// eslint-disable-next-line id-length
export const TECHNICAL_THIRD_CATEGORY_LEVEL_SKELETON_DATA = SkeletonService.makeThirdCategoryLevelSkeleton('technical');

export const TECHNICAL_PRODUCT_CARD_SKELETON_DATA = SkeletonService.makeProductCardSkeleton('technical');

export const SIMPLE_PRODUCT_CARD_SKELETON_DATA = SkeletonService.makeProductCardSkeleton('simple');

export const VISUAL_PRODUCT_CARD_SKELETON_DATA = SkeletonService.makeProductCardSkeleton('visual');

export const CART_SKELETON_DATA = SkeletonService.makeCartSkeleton();
export const CHECKOUT_SKELETON_DATA = SkeletonService.makeCheckoutSkeleton();

export const BREAD_CRUMBS_SKELETON_DATA = SkeletonService.makeBreadCrumbsSkeleton();

export const HOME_PAGE_SKELETON_DATA = SkeletonService.makeHomePageSkeleton();

export const COLLECTIONS_SKELETON_PAGE_DATA = SkeletonService.makeCollectionPageSkeleton();

export const COLLECTION_X_SKELETON_PAGE_DATA = SkeletonService.makeCollectionXPageSkeleton();

export const ORDERS_SKELETON_PAGE_DATA = SkeletonService.makeOrdersPageSkeleton();

export const DELIVERY_ADDRESSES_SKELETON_PAGE_DATA = SkeletonService.makeDeliveryAddressesPageSkeleton();

export const FAVOURITES_SKELETON_PAGE_DATA = SkeletonService.makeFavouritesPageSkeleton();

export const BRAND_LIST_PAGE_SKELETON_DATA = SkeletonService.makeBrandListPageSkeleton();

export const BRAND_PAGE_SKELETON_DATA = SkeletonService.makeBrandPageSkeleton();

export const SHOP_LIST_PAGE_SKELETON_DATA = SkeletonService.makeShopPageSkeleton();

export const PROMO_LIST_PAGE_SKELETON_DATA = SkeletonService.makePromoListPageSkeleton();

export const PROMO_PAGE_SKELETON_DATA = SkeletonService.makePromoPageSkeleton();

export const ONLINE_PAYMENT_PAGE_SKELETON_DATA = SkeletonService.makeOnlinePaymentPageSkeleton();

export const THANKS_FOR_YOUR_ORDER_PAGE_SKELETON_DATA = SkeletonService.makeThanksForYourOrderPageSkeleton();

export const SEARCH_PANEL_DATA_SKELETON_DATA = SkeletonService.makeSearchPanelSkeleton();

export const COLLECTIONS_PAGE_SKELETON_DATA = SkeletonService.makeCollectionsPageSkeleton();
export const CONTENT_PAGE_SKELETON_DATA = SkeletonService.makeInfoPagesSkeleton();
