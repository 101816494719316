/* eslint max-lines: off */

import {
    Advice,
    CollectionDetail,
    CollectionSectionDetail,
    CollectionShort,
    FastFilter,
    Image as OldImage,
    Label,
    ProductOfferAvailability,
    ProductOfferShort,
    ProductService,
    StoreType,
} from 'models';
import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {IPopularService} from 'modules/services/types';
import {
    ActionDetail,
    ActionShort,
    ActionTop,
    BasketItem,
    BrandBase,
    BrandDetail,
    BrandTop,
    Category,
    CategoryBase,
    CategoryShort,
    CategoryType,
    CategoryTypeEnum,
    DeliveryAddress,
    DeliveryMethodShort,
    DeliveryType,
    FilterChoose,
    FilterRange,
    FilterType,
    MediaImage,
    MediaImageAdaptive,
    MediaTypeEnum,
    OrderCourierDeliveryData,
    OrderDetail,
    OrderShort,
    OrderStatus,
    PaymentMethodBase,
    PaymentMethodShort,
    ProductBase,
    ProductDetail,
    ProductShort,
    Promo,
    PromoPlace,
    ServiceTop,
    Sorting,
    StoreShort,
} from 'new-models';
import {IShopPage} from 'routing/types';

export class MockService {
    public static repeat<T>(count: number, cb: () => T): T[] {
        return Array.from(Array(count).keys()).map(() => cb());
    }

    public static placeholder(length: number = 1): string {
        return Array.from(Array(length).keys()).reduce((placeholder) => {
            return placeholder + Math.random().toString(36).slice(2);
        }, '');
    }

    public static textPlaceholder(length: number = 1, charLength: number = 1, withSpace: boolean = false): string {
        let char = 'X'.repeat(charLength);
        if (withSpace) {
            char += ' ';
        }

        return char.repeat(length);
    }

    public static makeCategoryNode(depth: number, type: Category['type'] = CategoryType.Simple): ICategoryNode {
        return {
            childrenCategory: depth
                ? MockService.repeat<ICategoryNode>(5, () => MockService.makeCategoryNode(depth - 1, type))
                : [],
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            parentId: '0',
            parentIdList: [],
            title: MockService.placeholder(),
            type,
        };
    }

    public static makeProductAvailability(): ProductOfferAvailability {
        return {
            availText: MockService.placeholder(),
            extra: null,
            placements: MockService.repeat<string>(1, () => MockService.placeholder()),
            planDate: MockService.placeholder(),
            planDateText: '',
            store: {
                address: MockService.placeholder(),
                id: 0,
                storeType: StoreType.Store,
                title: MockService.placeholder(),
            },
            unit: '',
        };
    }

    public static makeProductShort(type: ProductDetail['type'] = 'simple'): ProductShort {
        return {
            article: '1234567',
            availability: true,
            availabilityCount: 2,
            categoryId: '0',
            categoryIdList: [],
            code: 'code',
            id: MockService.placeholder(),
            media: [],
            nameplates: [],
            price: {
                additional: [],
                base: {
                    currency: 'RUB',
                    price: 20_000,
                },
                main: {
                    currency: 'RUB',
                    price: 1_000_000,
                    unit: 'шт',
                },
            },
            rating: 0,
            title: MockService.placeholder(),
            type,
        };
    }

    public static makeProduct(type: ProductDetail['type'] = 'simple'): ProductDetail {
        return {
            ...MockService.makeProductShort(type),
            description: MockService.placeholder(),
            properties: [
                {
                    code: '0',
                    id: '0',
                    title: MockService.placeholder(),
                    type: 'string',
                    value: '0',
                },
                {
                    code: '1',
                    id: '1',
                    title: MockService.placeholder(),
                    type: 'string',
                    value: '0',
                },
            ],
        };
    }

    public static makeStoreShort(): StoreShort {
        return {
            address: MockService.placeholder(),
            id: MockService.placeholder(),
            phones: MockService.placeholder(),
            schedule: [
                {
                    days: MockService.textPlaceholder(5),
                    from: MockService.textPlaceholder(5),
                    to: MockService.textPlaceholder(5),
                },
            ],
            title: MockService.placeholder(),
        };
    }

    public static makeBasketItem(): BasketItem {
        return {
            isSelected: false,
            product: MockService.makeProduct(),
            quantity: 1,
        };
    }

    public static makeSort(): Sorting {
        return {
            comment: 'SKELETON',
            direction: 'rating-desc',
            isApplied: false,
            title: 'SKELETON',
        };
    }

    public static makeComboFilter(): FastFilter {
        return {
            amount: 1,
            code: MockService.placeholder(),
            filters: [
                {
                    id: MockService.placeholder(),
                    value: MockService.placeholder(),
                },
            ],
            id: 0,
            title: MockService.placeholder(),
        };
    }

    public static makeFilter(type: 'choose' | 'range' = 'choose'): FilterRange | FilterChoose {
        const chooseFilter: FilterChoose = {
            code: '0',
            hint: '',
            isCollapsed: false,
            isMultiple: false,
            title: MockService.placeholder(),
            type: FilterType.Choose,
            variants: [
                {
                    code: MockService.placeholder(),
                    count: 1,
                    isApplied: false,
                    isEnabled: true,
                    title: MockService.placeholder(),
                },
            ],
        };

        const rangeFilter: FilterRange = {
            code: '1',
            maxRange: 100,
            maxValue: 90,
            minRange: 0,
            minValue: 10,
            step: 0,
            title: MockService.placeholder(),
            type: FilterType.Range,
        };

        switch (type) {
            case 'choose': {
                return chooseFilter;
            }
            case 'range': {
                return rangeFilter;
            }

            default: {
                return chooseFilter;
            }
        }
    }

    public static makeShopPage(): IShopPage {
        return {
            isLastActive: true,
            path: MockService.placeholder(),
            title: MockService.placeholder(),
        };
    }

    public static makeAdvice(): Advice {
        return {
            description: MockService.placeholder(2),
            id: 0,
            image: MockService.makeImageOld(),
            name: MockService.placeholder(1),
            timeToReadInSeconds: 30,
            url: '',
        };
    }

    // TODO: Remove after refactoring the models and unuse methods
    public static makeImageOld(): OldImage {
        return {
            alternative: '',
            path: '',
            title: '',
            type: 'image' as OldImage['type'],
        };
    }
    public static makeImage(): MediaImage {
        return {
            alternative: '',
            title: '',
            type: MediaTypeEnum.Image,
            url: '',
        };
    }
    public static makeAdaptiveImage(): MediaImageAdaptive {
        return {
            alternative: '',
            title: '',
            type: MediaTypeEnum.ImageAdaptive,
            urlL: '',
            urlM: '',
            urlMobile: '',
            urlS: '',
        };
    }

    public static makeService(): ProductService {
        return {
            image: MockService.makeImageOld(),
            isProfi: false,
            title: '',
            type: 'offline',
        };
    }

    public static makeSlider(): Promo {
        return {
            id: MockService.placeholder(),
            link: {type: 'category', value: ''},
            media: MockService.makeAdaptiveImage(),
            place: PromoPlace.Top,
        };
    }

    public static makePrivateLabel(): Label {
        return {
            code: 'code',
            description: MockService.placeholder(1),
            image: MockService.makeImageOld(),
            title: MockService.placeholder(1),
        };
    }

    public static makePromo(): ActionTop {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            image: MockService.makeAdaptiveImage(),
            products: {
                page: {
                    count: 24,
                    limit: 12,
                    page: 1,
                },
                products: MockService.repeat<ProductBase>(4, MockService.makeProductShort),
            },
            title: MockService.placeholder(),
        };
    }

    public static makeCollection(): CollectionShort {
        return {
            code: MockService.placeholder(2),
            description: '',
            id: Math.round(Math.random() * 20),
            masterImage: {
                alternative: '',
                path: '',
                title: '',
                type: 'image',
            },
            priceFrom: {
                basePrice: 0,
                currency: 'RUB',
                price: 0,
            },
            quantity: 0,
            title: '',
        };
    }

    public static makeCollectionDetail(): CollectionDetail {
        return {
            code: MockService.placeholder(12),
            description: MockService.textPlaceholder(600),
            id: -1,
            images: [MockService.makeImageOld(), MockService.makeImageOld(), MockService.makeImageOld()],
            title: MockService.textPlaceholder(18),
        };
    }

    public static makeCollectionSectionDetail(): CollectionSectionDetail {
        return {
            code: MockService.placeholder(1),
            products: MockService.repeat<ProductOfferShort>(
                12,
                // TODO: for the time of mixing two mock servers
                () => MockService.makeProduct('simple') as unknown as ProductOfferShort
            ),
            title: MockService.textPlaceholder(24),
        };
    }

    public static makeOrderShort(): OrderShort {
        return {
            date: '2023-07-21T00:00:00Z',
            deliveryData: {} as OrderCourierDeliveryData,
            id: MockService.placeholder(),
            orderStatus: OrderStatus.CREATED,
            paidStatus: {},
            paymentMethod: {} as PaymentMethodBase,
            quantity: 1,
            total: {
                cost: 91105,
                deliveryCost: 200,
                discount: 4795,
                totalCost: 4995,
            },
        };
    }

    public static makeOrderDetail(): OrderDetail {
        return {
            ...MockService.makeOrderShort(),
            customer: {
                firstname: MockService.textPlaceholder(),
                lastname: MockService.textPlaceholder(),
                phone: '+79999999999',
            },
            deliveryMethod: MockService.makeDeliveryMethodShort(),
            paymentMethod: {
                code: 'card-or-sbp',
                id: MockService.placeholder(),
                title: MockService.textPlaceholder(),
            },

            products: [
                {
                    product: MockService.makeProductShort(),
                    quantity: 2,
                },
            ],
        };
    }

    public static makeShortCategory(): CategoryShort {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            title: MockService.textPlaceholder(1, 10),
            type: CategoryType.Simple,
        };
    }

    public static makeBaseCategory(): CategoryBase {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            title: MockService.textPlaceholder(1, 15),
            type: CategoryTypeEnum.Simple,
        };
    }

    public static makeBrandBase(): BrandBase {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            logo: MockService.makeImage(),
            title: MockService.placeholder(),
        };
    }

    public static makeBrandDetail(): BrandDetail {
        return {
            code: MockService.placeholder(),
            description: MockService.textPlaceholder(40, 10, true),
            id: MockService.placeholder(),
            logo: MockService.makeImage(),
            title: MockService.placeholder(),
        };
    }

    public static makePromoDetail(): ActionDetail {
        return {
            code: MockService.placeholder(),
            fullDescription: MockService.textPlaceholder(40, 10, true),
            id: MockService.placeholder(),
            image: MockService.makeAdaptiveImage(),
            title: MockService.placeholder(),
        };
    }

    public static makeBrandTop(): BrandTop {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            logo: MockService.makeImage(),
            title: MockService.textPlaceholder(3),
        };
    }

    public static makeCollectionTop(): {
        image: MediaImageAdaptive | MediaImage | (MediaImageAdaptive & MediaImage);
        code: string;
        id: string;
        title: string;
        products: {products: ProductShort[]};
    } {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            image: MockService.makeAdaptiveImage(),
            products: {
                products: MockService.repeat(2, MockService.makeProductShort),
            },
            title: MockService.textPlaceholder(5),
        };
    }

    public static makePopularServices(): IPopularService {
        return {
            count: 4,
            list: MockService.repeat(4, MockService.makeServiceTop),
        };
    }

    public static makeServiceTop(): ServiceTop {
        return {
            code: MockService.placeholder(),
            icon: MockService.makeImage(),
            id: MockService.placeholder(),
            shortDescription: MockService.placeholder(),
            title: MockService.placeholder(),
        };
    }

    public static makeAddressDelivery(): DeliveryAddress {
        return {
            address: MockService.placeholder(),
            apartment: '111',
            comment: MockService.placeholder(),
            entrance: 1,
            floor: 1,
            id: MockService.placeholder(),
            isDefault: false,
            isPrivateHouse: false,
            latitude: '00.000000',
            longitude: '00.000000',
            name: MockService.placeholder(),
        };
    }

    public static makeDeliveryMethodShort(type: DeliveryType = DeliveryType.PICKUP): DeliveryMethodShort {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            title: MockService.placeholder(),
            type,
        };
    }

    public static makePaymentMethodShort(): PaymentMethodShort {
        return {
            code: 'card-or-sbp',
            id: MockService.placeholder(),
            title: MockService.placeholder(),
        };
    }

    public static makeActionShort(): ActionShort {
        return {
            code: MockService.placeholder(),
            id: MockService.placeholder(),
            title: MockService.placeholder(),
        };
    }
}
