import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {IProps as ILocationsListProps, LocationsList} from 'modules/locations/components/LocationsList';
import {selectIsChangeCityLoading} from 'modules/locations/selectors';
import {changeLocation} from 'modules/locations/thunks';
import {MODALS} from 'modules/modals/constants';
import {actionSetPortalHidden} from 'modules/portal/slice';
import {Location} from 'new-models';
import closeIcon from 'public/icons/close.svg';
import React, {memo, useCallback} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    onClose: () => void;
    userLocation: Location | null;
}

export const LocationMiniModal = memo<IProps>(({onClose, className, userLocation}) => {
    const dispatch = useAppDispatch();

    const isChangeCityLoading = useAppSelector(selectIsChangeCityLoading);

    const handleCitySelect = useCallback<ILocationsListProps['onSelect']>(
        (location) => {
            if (!location) {
                return;
            }

            dispatch(changeLocation(location));
            dispatch(actionSetPortalHidden(MODALS.LOCATION_CHOOSE.name));
        },
        [dispatch]
    );

    return (
        <div className={classNames(style.container, 'location-mini-modal', className)}>
            <div className={style.header}>
                <Typography color="gray100" element="h5" variant="h5">
                    Выбор города
                </Typography>
                <button className={style.close} onClick={onClose}>
                    <SvgIcon svg={closeIcon} />
                </button>
            </div>
            <LocationsList
                checkedLocationId={userLocation?.id}
                className={style.cityListRoot}
                disabled={isChangeCityLoading}
                infoBlockClassName={style.infoBlock}
                itemClassName={style.cityItem}
                listClassName={style.list}
                locationNameClassName={style.cityItemName}
                onSelect={handleCitySelect}
                searchInputContainerClassName={style.searchContainer}
            />
        </div>
    );
});
