import classNames from 'classnames';
import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {SvgIcon} from 'components/SvgIcon';
import {AddressCardItem} from 'modules/addresses/components/AddressCardItem';
import {EmptyAddressList} from 'modules/addresses/components/EmptyAddressList';
import {AddressPageContext} from 'modules/addresses/context';
import {useAddAddressModalApi} from 'modules/addresses/hooks/useAddAddressModalApi';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useYandexMapConnect} from 'modules/yandex-map/hooks/useYandexMapConnect';
import dynamic from 'next/dynamic';
import plusIcon from 'public/icons/plus.svg';
import React, {memo, useContext} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

const AddressAddModal = dynamic(() => import('modules/addresses/components/AddressAddModal'), {
    loading: () => <ModalLazyLoader />,
});

const AddressEditModal = dynamic(() => import('modules/addresses/components/AddressEditModal'), {
    loading: () => <ModalLazyLoader />,
});

const AddressRemoveModal = dynamic(() => import('modules/addresses/components/AddressRemoveModal'), {
    loading: () => <ModalLazyLoader />,
});

export const AddressesPageLayout: FC = ({children}) => {
    return (
        <>
            <Grid className={style.root}>{children}</Grid>
            <Modal name={MODALS.ADDRESS_ADD.name} willMount>
                <AddressAddModal />
            </Modal>
            <Modal name={MODALS.ADDRESS_CHANGE.name} willMount>
                <AddressEditModal />
            </Modal>
            <Modal name={MODALS.ADDRESS_REMOVE.name} willMount>
                <AddressRemoveModal />
            </Modal>
        </>
    );
};

export const AddressesPage = memo(function AddressesPage() {
    const {addressList, isLoading} = useContext(AddressPageContext);
    const {open} = useAddAddressModalApi();
    useYandexMapConnect();

    if (isLoading === undefined) {
        return null;
    }

    if (0 === addressList.length && !isLoading) {
        return (
            <AddressesPageLayout>
                <EmptyAddressList onClick={open} rootClassName={columnStyle} />
            </AddressesPageLayout>
        );
    }

    return (
        <AddressesPageLayout>
            <Button
                className={classNames(style.addBtn, columnStyle)}
                data-skeleton-item
                isWithoutPaddings
                leftIcon={<SvgIcon svg={plusIcon} />}
                onClick={open}
                size="medium"
                theme="ghost"
            >
                Новый адрес
            </Button>
            {addressList.map((item) => (
                <AddressCardItem className={columnStyle} key={item.id} {...item} />
            ))}
        </AddressesPageLayout>
    );
});
