import * as Sentry from '@sentry/nextjs';
import {AbstractApiResponseInterceptor} from 'core/abstract/AbstractApiResponseInterceptor';
import {checkIsCorrectErrors} from 'core/helpers/checkIsCorrectErrors';
import {IHttpClientResponse, TJson, TRequestOption, TRequestParameters} from 'core/types';

export class SentryResponseInterceptor extends AbstractApiResponseInterceptor<
    TRequestParameters<TRequestOption, object>
> {
    constructor() {
        super();
    }

    public override async intercept(
        response: IHttpClientResponse<TJson, Headers, Response>,
        requestOption?: TRequestParameters<TRequestOption, object>
    ): Promise<typeof response> {
        if (!response.ok && !checkIsCorrectErrors(response.data.errors)) {
            Sentry.withScope((scope) => {
                scope.setExtra('path', requestOption?.url);

                if (response.data.errors.length) {
                    // @ts-expect-error
                    response.data.errors.forEach((key) => {
                        scope.setExtra('key', JSON.stringify(key));
                    });
                }

                if (requestOption) {
                    scope.setExtra('body', JSON.stringify(requestOption));
                }

                Sentry.captureException(new Error('incorrect response error'));
            });
        }

        return response;
    }

    public static instance: SentryResponseInterceptor;

    public static getInstance(): SentryResponseInterceptor {
        if (!SentryResponseInterceptor.instance) {
            SentryResponseInterceptor.instance = new SentryResponseInterceptor();
        }

        return SentryResponseInterceptor.instance;
    }
}
