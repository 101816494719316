import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {BrandTop} from 'new-models';
import React, {HTMLAttributes, memo} from 'react';

import style from './style.module.scss';

interface IProps extends HTMLAttributes<HTMLAnchorElement> {
    brand: BrandTop;
    isHoverLayout?: boolean;
}

export const PopularBrandCard = memo<IProps>(function PopularBrandCard({brand, className, isHoverLayout}) {
    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <MediaHarvester className={style.image} media={brand.logo} title={brand.title} />
            {isHoverLayout && <div className={style.hoverLayout} />}
        </div>
    );
});
