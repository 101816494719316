import classNames from 'classnames';
import {Typography} from 'components/Typography';
import Link from 'next/link';
import React, {FC} from 'react';
import {IShopPage} from 'routing/types';

import style from './style.module.scss';

interface IProps {
    crumbsList: IShopPage[];
}

export const BreadCrumbs: FC<IProps> = ({crumbsList}) => {
    return (
        <nav
            className={classNames(style.breadCrumbs, {
                [style.notDisplay]: !crumbsList.length,
            })}
        >
            <ul className={style.breadCrumbsList}>
                {crumbsList.map(({path, title, isLastActive}, index) => {
                    if (path) {
                        return (
                            <li className={style.breadCrumbsItem} key={path}>
                                <Link href={path} passHref>
                                    <a
                                        className={classNames({
                                            [style.breadCrumbsItemLinkDisabled]:
                                                index === Object.values(crumbsList).length - 1 && !isLastActive,
                                        })}
                                    >
                                        <Typography data-skeleton-item element="div" variant="p">
                                            {title}
                                        </Typography>
                                    </a>
                                </Link>
                            </li>
                        );
                    }

                    return null;
                })}
            </ul>
        </nav>
    );
};
