import {Typography} from 'components/Typography';
import {OrderXInfoBlock} from 'modules/orders/components/OrderXInfoBlock';
import {OrderXInfoBlockItem} from 'modules/orders/components/OrderXInfoBlockItem';
import {DeliveryType, OrderDetail, ProfileOrderDeliveryDataBase, ProfileOrderPickupDeliveryData} from 'new-models';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends OrderDetail {}

const renderPickupInfo = (data: ProfileOrderPickupDeliveryData) => {
    return (
        <>
            <OrderXInfoBlockItem leftSideText="Адрес самовывоза">
                <Typography element="p" variant="p">
                    {data.address}
                </Typography>
            </OrderXInfoBlockItem>
            {data.deliveryInterval && (
                <OrderXInfoBlockItem leftSideText="Дата получения">
                    <Typography element="p" variant="p">
                        {data.deliveryInterval}
                    </Typography>
                </OrderXInfoBlockItem>
            )}
            {data.storePeriod && (
                <OrderXInfoBlockItem leftSideText="Срок хранения">
                    <Typography element="p" variant="p">
                        {data.storePeriod}
                    </Typography>
                </OrderXInfoBlockItem>
            )}
        </>
    );
};

const renderCourierInfo = (data: ProfileOrderDeliveryDataBase) => {
    return (
        <>
            <OrderXInfoBlockItem leftSideText="Адрес доставки">
                <Typography element="p" variant="p">
                    {data.address}
                </Typography>
            </OrderXInfoBlockItem>
            {data.deliveryInterval && (
                <OrderXInfoBlockItem leftSideText="Дата доставки">
                    <Typography element="p" variant="p">
                        {data.deliveryInterval}
                    </Typography>
                </OrderXInfoBlockItem>
            )}
        </>
    );
};

export const OrderXPageDeliveryInfo = memo<IProps>(function OrderXPageDeliveryInfo({deliveryMethod, deliveryData}) {
    const renderInfo = useMemo<React.ReactNode>(() => {
        if (!deliveryData) {
            return null;
        }

        switch (deliveryMethod.type) {
            case DeliveryType.PICKUP:
                return renderPickupInfo(deliveryData as ProfileOrderPickupDeliveryData);
            default:
                return renderCourierInfo(deliveryData as ProfileOrderDeliveryDataBase);
        }
    }, [deliveryData, deliveryMethod.type]);

    const renderContent = useMemo<React.ReactNode>(() => {
        if (!deliveryData) {
            return null;
        }

        return (
            <div className={style.root}>
                <Typography element="h5" variant="h5">
                    Способ получения
                </Typography>
                <OrderXInfoBlock>{renderInfo}</OrderXInfoBlock>
            </div>
        );
    }, [deliveryData, renderInfo]);

    return renderContent;
});
