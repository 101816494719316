import {PaginationUrlService} from 'plugins/modules/pagination/services/PaginationUrlService';
import {IPaginationState} from 'plugins/modules/pagination/types';
import {createContext} from 'react';

export interface IPaginationContext extends IPaginationState {
    urlService?: PaginationUrlService;
}

export const PaginationContext = createContext<IPaginationContext>({
    count: 0,
    page: 0,
    pageSize: 0,
    pageSizeList: [],
    type: 'auto',
});
