import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {CollectionItem} from 'modules/collections/components/CollectionItem';
import {selectList} from 'modules/products/selectors';
import {getProductList} from 'modules/products/thunks';
import {CollectionProductsSlider} from 'modules/slider/components/CollectionProductsSlider';
import {CollectionShort, ProductShort} from 'new-models';
import React, {memo, ReactNode, useEffect, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    collectionItem: CollectionShort;
}

export const ProductCardCollectionsItem = memo<IProps>(function ProductCardInCollectionsItem({collectionItem}) {
    const dispatch = useAppDispatch();
    const collectionProducts = useAppSelector(selectList);

    useEffect(() => {
        const searchParams = new URLSearchParams();
        searchParams.set('filter[collectionCode]', collectionItem.code);

        dispatch(getProductList(searchParams));
    }, [collectionItem.code, dispatch]);

    const renderCollectionItem = useMemo<ReactNode>(() => {
        if (!collectionProducts.length) {
            return null;
        }

        const collection: CollectionShort = {
            ...collectionItem,
            products: {
                products: collectionProducts as ProductShort[],
            },
        };

        return (
            <div className={style.root}>
                <CollectionItem className={style.collectionItem} collectionItem={collection} />
                <CollectionProductsSlider className={style.sliderBlock} collectionCode={collectionItem.code} />
            </div>
        );
    }, [collectionItem, collectionProducts]);

    return renderCollectionItem;
});
