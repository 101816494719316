import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import React, {memo} from 'react';

import style from './style.module.scss';
interface IProps extends TPropsWithClassName {}

export const HrElement = memo<IProps>(function HrElement({className}) {
    return <div className={classNames(style.root, className)} />;
});
