import classNames from 'classnames';
import {IProps as IRadioButtonProps, RadioButton} from 'components/RadioButton';
import {memo} from 'react';

export interface IProps extends IRadioButtonProps {
    error?: boolean;
}

export const SegmentedRadio = memo<IProps>(function SegmentedRadio({children, className, error, ...radioProps}) {
    const {checked, defaultChecked, disabled} = radioProps;

    return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
            className={classNames(
                className,
                {
                    checked: checked ?? defaultChecked,
                    disabled,
                    error,
                },
                'segmented-radio-component'
            )}
            data-skeleton-item
        >
            <RadioButton className="radio" {...radioProps} />
            {children}
        </label>
    );
});
