import {BRAND_ROUTE_LAST_PART} from 'modules/brand/routes';
import {CATALOG_FIRST_LEVEL_ROUTE, CATALOG_SELECTION_ROUTE} from 'modules/categories/routes';
import {ONLINE_PAYMENT_LAST_PART_ROUTE, THANKS_FOR_YOUR_ORDER_LAST_PART_ROUTE} from 'modules/checkout/routes';
import {COLLECTION_PAGE_X_ROUTE} from 'modules/collection-x/routes';
import {CONTENT_URL_ROUTE} from 'modules/content/constants';
import {ORDER_X_PAGE_ROUTE} from 'modules/orders/routes';
import {PRODUCT_PAGE_ROUTE, PRODUCT_PAGE_SHORT_ROUTE} from 'modules/product-card/routes';
import {PROMO_ROUTE_LAST_PART} from 'modules/promo/routes';
import {COMBO_FILTER_URL_ROUTE} from 'plugins/modules/filter/constants';
import {
    BRAND_LIST_ROUTE,
    BRAND_ROUTE,
    CART_ROUTE,
    CATALOG_ROUTE,
    CHECKOUT_ROUTE,
    COLLECTIONS_LIST_PAGE,
    FAVOURITES_ROUTE,
    FRANCHISE_ROUTE,
    HOME_ROUTE,
    INFO_ROUTE,
    ONLINE_PAYMENT_ROUTE,
    PRODUCT_ROUTE,
    PROFILE_ADDRESS_ROUTE,
    PROFILE_ORDERS_ROUTE,
    PROFILE_ROOT_ROUTE,
    PROMO_ROUTE,
    SEARCH_ROUTE,
    SERVICE_ROUTE,
    SHOP_LIST_ROUTE,
    THANKS_FOR_YOUR_ORDER_ROUTE,
} from 'routing/constants';

export const SKELETON_CATALOG_ROUTE = `${CATALOG_ROUTE}/${CATALOG_FIRST_LEVEL_ROUTE}`;
export const SKELETON_CATALOG_SELECTION_ROUTE = `${CATALOG_ROUTE}/${CATALOG_SELECTION_ROUTE}`;
export const SKELETON_COLLECTION_ROUTE = `/${COLLECTIONS_LIST_PAGE}`;

export const SKELETON_COLLECTION_X_ROUTE = `${SKELETON_COLLECTION_ROUTE}/${COLLECTION_PAGE_X_ROUTE}`;

export const SKELETON_COMBO_FILTER_ROUTE = `/${COMBO_FILTER_URL_ROUTE}`;

export const SKELETON_CART_ROUTE = `${CART_ROUTE}`;
export const SKELETON_CHECKOUT_ROUTE = `${CHECKOUT_ROUTE}`;

export const SKELETON_PRODUCT_ROUTE = `${PRODUCT_ROUTE}/${PRODUCT_PAGE_ROUTE}`;

// TODO: Remove or refactoring the routes
export const SKELETON_PRODUCT_SHOR_ROUTE = `${PRODUCT_ROUTE}/${PRODUCT_PAGE_SHORT_ROUTE}`;

export const SKELETON_HOME_ROUTE = `${HOME_ROUTE}`;

export const SKELETON_SEARCH_ROUTE = `${SEARCH_ROUTE}`;

export const SKELETON_SERVICE_ROUTE = `${SERVICE_ROUTE}`;

export const SKELETON_DASHBOARD_PROFILE_ROUTE = PROFILE_ROOT_ROUTE;

export const SKELETON_PROFILE_ORDERS_ROUTE = PROFILE_ORDERS_ROUTE;
export const SKELETON_PROFILE_ORDER_X_ROUTE = `/${ORDER_X_PAGE_ROUTE}`;
export const SKELETON_PROFILE_ADDRESSES_ROUTE = PROFILE_ADDRESS_ROUTE;

export const SKELETON_FAVOURITES_ROUTE = FAVOURITES_ROUTE;

export const SKELETON_BRAND_LIST_ROUTE = BRAND_LIST_ROUTE;

export const SKELETON_BRAND_ROUTE = `${BRAND_ROUTE}/${BRAND_ROUTE_LAST_PART}`;

export const SKELETON_SHOP_LIST_ROUTE = SHOP_LIST_ROUTE;

export const SKELETON_PROMO_LIST_ROUTE = PROMO_ROUTE;

export const SKELETON_PROMO_ROUTE = `${PROMO_ROUTE}/${PROMO_ROUTE_LAST_PART}`;

export const SKELETON_ONLINE_PAYMENT_ROUTE = `${ONLINE_PAYMENT_ROUTE}/${ONLINE_PAYMENT_LAST_PART_ROUTE}`;

export const SKELETON_THANKS_FOR_YOUR_ORDER_ROUTE = `${THANKS_FOR_YOUR_ORDER_ROUTE}/${THANKS_FOR_YOUR_ORDER_LAST_PART_ROUTE}`;

export const SKELETON_INFO_PAGES_ROUTE = `${INFO_ROUTE}/${CONTENT_URL_ROUTE}`;

export const SKELETON_FRANCHISE_PAGES_ROUTE = FRANCHISE_ROUTE;
