import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IRequestLogRecord} from 'modules/request-logger/model';
import {IRequestLoggerState} from 'modules/request-logger/types';
import {HYDRATE} from 'next-redux-wrapper';

export const requestLoggerModuleName = 'requestLogger';

const initialState: IRequestLoggerState = {
    log: [],
};

export const requestLoggerSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return payload[requestLoggerModuleName];
        },
    },
    initialState,
    name: requestLoggerModuleName,
    reducers: {
        // @ts-expect-error
        actionPushLogRecord(state: IRequestLoggerState, {payload}: PayloadAction<IRequestLogRecord>) {
            state.log.push(payload);
        },
    },
});

export const {
    actions: {actionPushLogRecord},
} = requestLoggerSlice;

export const {reducer: requestLoggerReducer} = requestLoggerSlice;
