import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TabContext} from 'components/TabProvider';
import {Typography} from 'components/Typography';
import checkmarkIcon from 'public/icons/check.svg';
import React, {forwardRef, InputHTMLAttributes, useContext, useId} from 'react';

import style from './style.module.scss';

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    isLabel?: boolean;
    labelClassName?: string;
    disabled?: boolean;
    isMixed?: boolean;
    textClassName?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, IProps>(function Checkbox(
    {
        className,
        label,
        isLabel,
        labelClassName,
        isMixed,
        disabled,
        name,
        value,
        textClassName,
        ...restInputAttributes
    }: IProps,
    ref
) {
    const uid = useId();
    const checkboxName = useContext(TabContext);

    return (
        <div className={classNames(style.checkbox, className)} data-skeleton-item>
            <input
                {...restInputAttributes}
                className={style.input}
                disabled={disabled}
                id={uid}
                name={checkboxName || name}
                ref={ref}
                type="checkbox"
                value={value}
            />
            <label className={classNames(style.label, labelClassName)} htmlFor={uid}>
                <div className={classNames(style.checkMarkContainer, isMixed && style.mixed)}>
                    {!isMixed && <SvgIcon className={style.checkMarkIcon} svg={checkmarkIcon} />}
                </div>
                {isLabel && (
                    <Typography className={classNames(style.text, textClassName)} element="div" variant="p">
                        {label || value}
                    </Typography>
                )}
            </label>
        </div>
    );
});
