import {isUserAuthenticated} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {actionSetUser} from 'modules/user/slice';
import {useEffect, useMemo} from 'react';

export const useLogout = () => {
    const dispatch = useAppDispatch();

    const isLoggedInToken = useMemo<boolean>(() => isUserAuthenticated(), []);

    useEffect(() => {
        if (isLoggedInToken) {
            return;
        }

        dispatch(actionSetUser(null));
    }, [dispatch, isLoggedInToken]);
};
