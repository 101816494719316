import {AVAILABLE_PAGE_SIZE_LIST, DEFAULT_PAGE_SIZE, PAGINATION_TYPE} from 'plugins/modules/pagination/constants';
import {IPaginationState, TWithPagination} from 'plugins/modules/pagination/types';

export const initialPaginationState: IPaginationState = {
    count: 1,
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageSizeList: [...AVAILABLE_PAGE_SIZE_LIST],
    type: PAGINATION_TYPE.AUTO,
};

export const withPagination = <T>(originState: T): TWithPagination<T> => {
    return {
        ...originState,
        ...initialPaginationState,
    };
};
