import {isUrlActuallyChange} from 'core/helpers';
import {onHistoryChange} from 'core/next/helpers';
import {INextHistoryState, TBeforePopStateCallback} from 'core/types';
import Router, {useRouter} from 'next/router';
import {useCallback, useEffect, useState} from 'react';

interface IPageLoadingOptions {
    resolver?: (url: string, options: INextHistoryState['options']) => boolean;
    watchShallow?: boolean;
}
export const usePageLoading = ({resolver, watchShallow}: IPageLoadingOptions = {}): boolean => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState(false);

    const handleRouterChangeStart = useCallback(
        (newUrl: string, options: INextHistoryState['options']) => {
            if (options.shallow && !watchShallow) {
                return;
            }

            if (!isUrlActuallyChange(newUrl)) {
                return;
            }

            if (resolver) {
                setIsLoading(resolver(newUrl, options));
                return;
            }

            setIsLoading(true);
        },
        [resolver, watchShallow]
    );

    const handleRouterBack = useCallback<TBeforePopStateCallback>(() => {
        setIsLoading(true);
        return true;
    }, []);

    useEffect(() => {
        const disposer = onHistoryChange(handleRouterBack);

        Router.events.on('routeChangeStart', handleRouterChangeStart);
        Router.events.on('routeChangeComplete', () => setIsLoading(false));
        Router.events.on('routeChangeError', () => setIsLoading(false));

        return () => {
            Router.events.off('routeChangeStart', handleRouterChangeStart);
            Router.events.off('routeChangeComplete', () => setIsLoading(false));
            Router.events.off('routeChangeError', () => setIsLoading(false));

            disposer(handleRouterBack);
        };
    }, [handleRouterBack, handleRouterChangeStart, router]);

    return isLoading;
};
