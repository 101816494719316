import classNames from 'classnames';
import gridStyle from 'components/Grid/grid.module.scss';
import {IBaseGridProps, TColumnCount} from 'components/Grid/types';
import React, {CSSProperties, memo} from 'react';

export const GRID_COLUMNS_COUNT = 12;

export interface IGridProps extends IBaseGridProps {
    container?: boolean;
    gridColumnsCount?: TColumnCount;
    noRowGap?: boolean;
}

export const Grid = memo<IGridProps>(function Grid({
    children,
    as = 'div',
    className,
    container,
    gridColumnsCount,
    noRowGap,
    ...rest
}) {
    const Component = as;

    return (
        <Component
            className={classNames(
                gridStyle.grid,
                {
                    [gridStyle.container]: container,
                    [gridStyle.noRowGap]: noRowGap,
                },
                className
            )}
            {...rest}
            style={
                gridColumnsCount &&
                ({
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    '--evo-cols': gridColumnsCount,
                    ...rest?.style,
                } as CSSProperties)
            }
        >
            {children}
        </Component>
    );
});

interface IGetGridColumnStylesProps {
    sm?: TColumnCount;
    md?: TColumnCount;
    lg?: TColumnCount;
    xl?: TColumnCount;
}

export const getGridColumnStyles = (data?: IGetGridColumnStylesProps): string => {
    let style = gridStyle.col;

    if (!data) {
        return style;
    }

    Object.entries(data).forEach(([breakpoint, value]) => {
        style += ' ' + gridStyle[`col${breakpoint}${value}`];
    });

    return style;
};

export const getGridStyles = (data?: Pick<IGridProps, 'container' | 'noRowGap'>): string => {
    return classNames(gridStyle.grid, {
        [gridStyle.container]: data?.container,
        [gridStyle.noRowGap]: data?.noRowGap,
    });
};
