import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import React, {memo} from 'react';
import {BRAND_LIST_ROUTE} from 'routing/constants';

interface IProps {
    title: string;
}

export const BrandMobileHeader = memo<IProps>(function BrandMobileHeader({title}) {
    return (
        <PageHeaderTitle isParentPath path={BRAND_LIST_ROUTE} withContainer={false}>
            <Typography color="gray100" data-skeleton-item element="h1" variant="h2-bold">
                {title}
            </Typography>
        </PageHeaderTitle>
    );
});
