import classNames from 'classnames';
import {Loader, LOADER_MODES, LOADER_THEMES} from 'components/Loader';
import {Typography} from 'components/Typography';
import React, {ButtonHTMLAttributes, FC, MouseEventHandler, useMemo} from 'react';

import style from './style.module.scss';

interface IPropsFlyFilterButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    isLoading?: boolean;
}

export const FlyFilterButton: FC<IPropsFlyFilterButton> = ({title, disabled, onClick, isLoading}) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (isLoading) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    };

    const renderChildren = useMemo(() => {
        return (
            title && (
                <>
                    <Typography className={style.buttonText} variant="button-m">
                        {title}
                    </Typography>
                    {isLoading && <Loader mode={LOADER_MODES.compact} theme={LOADER_THEMES.primary} />}
                </>
            )
        );
    }, [isLoading, title]);

    return (
        <div className={style.flyFilterButtonWrapper}>
            <button
                className={classNames(style.flyFilterButton, {[style.loaded]: isLoading})}
                disabled={disabled}
                onClick={handleClick}
            >
                {renderChildren}
            </button>
        </div>
    );
};
