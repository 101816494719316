import {INextHistoryState, TBeforePopStateCallback} from 'core/types';
import getConfig from 'next/config';
import Router from 'next/router';

export const getRuntimeConfig = (): typeof process.env => {
    const {publicRuntimeConfig} = getConfig();

    return publicRuntimeConfig ?? {};
};

export const addSearchParam = async <T extends string>(
    paramKey: string,
    paramVal: T,
    silent: boolean = false,
    options?: {
        scroll?: boolean;
        shallow?: boolean;
    }
) => {
    const url = new URL(window.location.href);

    url.searchParams.set(paramKey, paramVal);

    if (silent) {
        await Router.replace(
            {
                query: {
                    ...Router.query,
                    [paramKey]: paramVal,
                },
            },
            url,
            options
        );
        return;
    }

    await Router.push(
        {
            query: {
                ...Router.query,
                [paramKey]: paramVal,
            },
        },
        url,
        options
    );
};

export const softReload = async (route?: string) => {
    if (route) {
        await Router.replace(route);
        return;
    }
    await Router.reload();
};

export const removeSearchParam = async (
    paramKey: string,
    silent: boolean = false,
    options?: {
        scroll: boolean;
        shallow: boolean;
    }
) => {
    const url = new URL(window.location.href);

    url.searchParams.delete(paramKey);

    if (silent) {
        await Router.replace(
            {
                query: {
                    ...Router.query,
                    [paramKey]: null,
                },
            },
            url,
            options
        );
        return;
    }

    await Router.push(
        {
            query: {
                ...Router.query,
                [paramKey]: null,
            },
        },
        url,
        options
    );
};

export type TMakeHistoryChangeListeners = () => (
    listenerCallback: TBeforePopStateCallback
) => (disposerCallback: TBeforePopStateCallback) => void;
export const makeHistoryChangeListeners: TMakeHistoryChangeListeners = () => {
    const callbackList = new Set<TBeforePopStateCallback>();

    return (listenerCallback) => {
        callbackList.add(listenerCallback);

        const onBeforePopState = (state: INextHistoryState) => {
            callbackList.forEach((cb) => cb(state));

            return true;
        };

        Router.beforePopState(onBeforePopState);

        return (disposerCallback) => {
            callbackList.delete(disposerCallback);

            Router.beforePopState(onBeforePopState);
        };
    };
};

export const onHistoryChange = makeHistoryChangeListeners();
