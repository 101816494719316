import classNames from 'classnames';
import {Button} from 'components/Button';
import {Address} from 'components/FormFields/Address';
import {Apartment} from 'components/FormFields/Apartment';
import {Entrance} from 'components/FormFields/Entrance';
import {Floor} from 'components/FormFields/Floor';
import {Intercom} from 'components/FormFields/Intercom';
import {PrivateHouse} from 'components/FormFields/PrivateHouse';
import {getGridColumnStyles} from 'components/Grid';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {Field, Form, Formik, FormikProps} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {FormikConfig} from 'formik/dist/types';
import {AddressComment} from 'modules/addresses/components/AddressComment';
import {DeliveryAddressBase} from 'new-models';
import React, {memo, ReactElement, useCallback, useState} from 'react';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

interface ISubmitButtonProps {
    className?: string;
    isLoading?: boolean;
}

const SubmitButton: FC<ISubmitButtonProps> = ({className, isLoading}) => {
    return (
        <Button className={classNames(style.submitButton, className)} isLoading={isLoading} isStretched type="submit">
            Сохранить
        </Button>
    );
};

interface IProps<V extends DeliveryAddressBase> extends TPropsWithClassName, FormikConfig<V> {
    HeaderComponent?: FC;
    FooterComponent?: FC;
    RightSideComponent?: FC<FormikProps<V>>;
}

const AddressFormOriginal = <V extends DeliveryAddressBase>({
    className,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HeaderComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FooterComponent,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RightSideComponent,
    ...formikProps
}: IProps<V>) => {
    const [isRolledUp, setIsRolledUp] = useState(false);
    const [touchStartY, setTouchStartY] = useState<number | null>(null);
    const [touchEndY, setTouchEndY] = useState<number | null>(null);

    const handleFieldsRollUp = () => setIsRolledUp(true);

    const handleTouchStart = useCallback((e: React.TouchEvent<HTMLButtonElement>) => {
        if (1 === e.targetTouches.length) {
            setTouchStartY(e.targetTouches[0].clientY);
        }
    }, []);

    const handleTouchMove = useCallback((e: React.TouchEvent<HTMLButtonElement>) => {
        if (1 === e.targetTouches.length) {
            setTouchEndY(e.targetTouches[0].clientY);
        }
    }, []);

    const handleTouchEnd = useCallback(() => {
        if (null !== touchStartY && null !== touchEndY) {
            const swipeDistance = touchStartY - touchEndY;

            if (50 < swipeDistance) {
                setIsRolledUp(true);
            } else if (-50 > swipeDistance) {
                setIsRolledUp(false);
            }
        }

        setTouchStartY(null);
        setTouchEndY(null);
    }, [touchStartY, touchEndY]);

    return (
        <Formik {...formikProps} enableReinitialize>
            {(formProps) => {
                const {handleSubmit, isSubmitting} = formProps;

                return (
                    <>
                        <Form className={classNames(style.form, className)} onSubmit={handleSubmit}>
                            <div className={style.mainContainer}>
                                <div className={style.mapBlock}>
                                    {RightSideComponent && <RightSideComponent {...formProps} />}
                                </div>
                                <div className={classNames(style.fields, {[style.isRolledUp]: isRolledUp})}>
                                    <div className={style.fieldsInner}>
                                        <button
                                            className={style.bottomSheet}
                                            onTouchEnd={handleTouchEnd}
                                            onTouchMove={handleTouchMove}
                                            onTouchStart={handleTouchStart}
                                        />
                                        <Typography color="gray100" variant="h4">
                                            Добавить адрес
                                        </Typography>
                                        {!isRolledUp && (
                                            <>
                                                <Typography color="gray100" variant="p">
                                                    Выберите на карте или укажите адрес доставки
                                                </Typography>
                                                <Button isStretched onClick={handleFieldsRollUp}>
                                                    Уточнить адрес
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                    {HeaderComponent && <HeaderComponent />}
                                    <Address />
                                    <Field name="isPrivateHouse">
                                        {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                                            <PrivateHouse
                                                className={style.house}
                                                errorText={meta.touched ? meta.error : ''}
                                                {...field}
                                            />
                                        )}
                                    </Field>
                                    <div className={style.groupField}>
                                        <Field name="apartment">
                                            {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                                                <Apartment
                                                    autoComplete="off"
                                                    errorText={meta.touched ? meta.error : ''}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                        <Field name="entrance">
                                            {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                                                <Entrance
                                                    autoComplete="off"
                                                    errorText={meta.touched ? meta.error : ''}
                                                    min={0}
                                                    type="number"
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                        <Field name="floor">
                                            {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                                                <Floor
                                                    autoComplete="off"
                                                    errorText={meta.touched ? meta.error : ''}
                                                    min={0}
                                                    type="number"
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                        <Field name="doorcode">
                                            {({field, meta}: FieldProps<string, DeliveryAddressBase>) => (
                                                <Intercom
                                                    autoComplete="off"
                                                    errorText={meta.touched ? meta.error : ''}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                        <Field name="comment">
                                            {({field, meta}: FieldProps) => (
                                                <AddressComment
                                                    className={classNames(style.commentInput, columnStyle)}
                                                    errorText={meta.touched ? meta.error : ''}
                                                    {...field}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    {FooterComponent && <FooterComponent />}

                                    <SubmitButton isLoading={isSubmitting} />
                                </div>
                            </div>
                        </Form>
                    </>
                );
            }}
        </Formik>
    );
};

export const AddressForm = memo(AddressFormOriginal) as <V extends DeliveryAddressBase>(
    props: IProps<V>
) => ReactElement;
