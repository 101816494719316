import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {
    TCreateAddressResponse,
    TGetAddressListResponse,
    TUpdateAddressResponse,
} from 'modules/addresses/models/AddressResponse';
import {DeliveryAddressCreateDataRequest, DeliveryAddressUpdateDataRequest} from 'new-models';

export class AddressesService extends EvolutionHttpClient {
    public async getAddressList(): Promise<TGetAddressListResponse> {
        const {data} = await this.get<TGetAddressListResponse>({
            url: '/delivery-addresses',
        });

        return data;
    }

    public async postAddress(body: DeliveryAddressCreateDataRequest): Promise<TCreateAddressResponse> {
        const {data} = await this.post<DeliveryAddressCreateDataRequest, TCreateAddressResponse>({
            body,
            url: '/delivery-address',
        });

        return data;
    }

    public async patchAddress(id: string, body: DeliveryAddressUpdateDataRequest): Promise<TUpdateAddressResponse> {
        const {data} = await this.patch<DeliveryAddressUpdateDataRequest, TUpdateAddressResponse>({
            body,
            url: `/delivery-address/${id}`,
        });

        return data;
    }

    public async deleteAddress(id: string): Promise<any> {
        const response = await this.delete<any>({
            url: `/delivery-address/${id}`,
        });

        return response;
    }
}
