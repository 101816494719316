export const YANDEX_MAP_VENDOR_SCRIPT_ID = 'yandex-map-script';
export const YANDEX_MAP_ZOOM_DEFAULT = 10;
export const YANDEX_MAP_ZOOM_CLICKED = 16;
export const YANDEX_MAP_CONTROLS_DEFAULT = ['zoomControl', 'geolocationControl'];
export const YANDEX_PLACEMARK_OPTIONS_DEFAULT = {
    iconImageHref: '/icons/placemark.svg',
    iconImageSize: [40, 40],
    iconLayout: 'default#image',
};
export const YANDEX_MAP_CENTER_DEFAULT = [51.76, 36.64];

export const DEFAULT_CLUSTER_OPTIONS = {
    minClusterSize: 5,
    preset: 'islands#blackClusterIcons',
};
