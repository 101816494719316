import {Input} from 'components/Input';
import {TInputField} from 'core/types';
import {ADDRESS_MAX_LENGTH} from 'modules/addresses/constants';
import {useAddressField} from 'modules/addresses/hooks/useAddressField';

export const AddressName: FC<TInputField> = ({className, ...inputProps}) => {
    const {handleChange} = useAddressField('name', ADDRESS_MAX_LENGTH);

    return (
        <Input className={className} isBorder labelText="Название адреса*" {...inputProps} onChange={handleChange} />
    );
};
