import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {ProductService} from 'models';
import style from 'modules/product-card/components/OnlineServicesList/style.module.scss';
import {Shield} from 'modules/shields/components/Shield';
import React, {FC} from 'react';

import styles from './style.module.scss';

interface IProps extends TPropsWithClassName {
    service: ProductService;
}

export const HomePageServiceCard: FC<IProps> = ({className, service}) => {
    return (
        <a data-skeleton-item href={service.url || ''} rel="noreferrer" target="_blank" title={service.title}>
            <div className={classNames(styles.container, className)}>
                {service.image && (
                    <img
                        alt={service.image.alternative}
                        className={styles.image}
                        src={service.image.path}
                        title={service.image.title}
                    />
                )}
                <Typography className={styles.title} color="primary80" element="p" variant="p">
                    {service.title}
                </Typography>
                {service.price && 0 === service.price.value && (
                    <Shield className={style.shield} size="small">
                        Бесплатно
                    </Shield>
                )}
            </div>
        </a>
    );
};
