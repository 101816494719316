import {Button} from 'components/Button';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {useIsNeedRoute} from 'core/hooks/useIsNeedRoute';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useProfileRemoveAccountModalApi} from 'modules/user/hooks/useProfileRemoveAccountModalApi';
import {selectIsLoggedIn} from 'modules/user/selectors';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, {memo, useEffect} from 'react';
import {PROFILE_DELETE_ROUTE} from 'routing/constants';

const RemoveAccountModal = dynamic(() => import('modules/user/components/RemoveAccountModal'), {
    loading: () => <ModalLazyLoader />,
});

interface IProps extends TPropsWithClassName {}

export const RemoveAccount = memo<IProps>(function RemoveAccount({className}) {
    const {open: openRemoveAccountModal, close: closeRemoveAccountModal} = useProfileRemoveAccountModalApi();
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const isDeleteRoute = useIsNeedRoute([PROFILE_DELETE_ROUTE]);

    useEffect(() => {
        if (isDeleteRoute && isLoggedIn) {
            openRemoveAccountModal();
            return;
        }

        closeRemoveAccountModal();
    }, [closeRemoveAccountModal, isDeleteRoute, isLoggedIn, openRemoveAccountModal]);

    return (
        <>
            <div className={className}>
                <Link href={PROFILE_DELETE_ROUTE} shallow>
                    <Button data-skeleton-item isContentSize isNotAdaptive theme="ghost">
                        Удалить аккаунт
                    </Button>
                </Link>
            </div>
            <Modal name={MODALS.PROFILE_REMOVE_ACCOUNT.name} willCloseOnUrlChange willMount>
                <RemoveAccountModal title="Удаление аккаунта" />
            </Modal>
        </>
    );
});
