import {ShadowBlock} from 'components/ShadowBlock';
import {isBrowserContext, isUrlActuallyChange} from 'core/helpers';
import {usePageLoading} from 'core/next/hooks/usePageLoading';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire} from 'core/types';
import {PortalProvider} from 'modules/portal/providers/PortalProvider';
import {selectIsPortalVisible} from 'modules/portal/selectors';
import {actionSetPortalHidden} from 'modules/portal/slice';
import Router, {useRouter} from 'next/router';
import React, {FC, ReactNode, useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';

export interface IPortalProps extends TPropsWithChildrenRequire {
    onClose?: () => void;
    name: string;
    willCloseOnUrlChange?: boolean;
    willMount?: boolean;
    hasPortal?: boolean;
    defaultVisible?: boolean;
    disableOnPageLoading?: boolean;
}

export const Portal: FC<IPortalProps> = ({
    name,
    willMount,
    children,
    onClose,
    willCloseOnUrlChange,
    hasPortal,
    defaultVisible = false,
    disableOnPageLoading = true,
}) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const isPageLoading = usePageLoading();

    const visibility = useAppSelector(selectIsPortalVisible(name));

    const isModalVisible = useMemo(() => {
        return visibility || defaultVisible;
    }, [defaultVisible, visibility]);

    useEffect(() => {
        if (!willCloseOnUrlChange) {
            return;
        }

        const handleRouterChangeStart = (newUrl: string) => {
            if (!isUrlActuallyChange(newUrl)) {
                return;
            }

            dispatch(actionSetPortalHidden(name));
        };

        Router.router?.events.on('routeChangeStart', handleRouterChangeStart);

        // eslint-disable-next-line consistent-return
        return () => {
            Router.router?.events.off('routeChangeStart', handleRouterChangeStart);
        };
    }, [dispatch, name, router.asPath, willCloseOnUrlChange]);

    const renderPortal = useMemo<ReactNode>(() => {
        if ('development' === process.env.NODE_ENV) {
            return (
                <ShadowBlock data-portal-name={name}>
                    <PortalProvider name={name} onClose={onClose} visibility={isModalVisible}>
                        {children}
                    </PortalProvider>
                </ShadowBlock>
            );
        }

        return (
            <PortalProvider name={name} onClose={onClose} visibility={isModalVisible}>
                {children}
            </PortalProvider>
        );
    }, [children, name, onClose, isModalVisible]);

    if (isPageLoading && disableOnPageLoading) {
        return null;
    }

    if (willMount && !isModalVisible) {
        return null;
    }

    if (!hasPortal) {
        return <>{renderPortal}</>;
    }

    if (!isBrowserContext()) {
        return null;
    }

    return ReactDOM.createPortal(renderPortal, document.body);
};
