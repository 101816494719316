import classNames from 'classnames';
import {Button} from 'components/Button';
import {Collapse} from 'components/Collapse';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {disableScroll, isMobileByScreen, plural, stripHtml} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TClosureCallback} from 'core/types';
import {BaseFilter} from 'models';
import {MODALS} from 'modules/modals/constants';
import {hideModal, showModal} from 'modules/modals/thunks';
import {COLUMNS_COUNT_LAYOUT} from 'modules/products/constants';
import {useSlice} from 'plugins/hooks/useSlice';
import {FilterItem} from 'plugins/modules/filter/components/FilterItem';
import {FilterTooltipModal} from 'plugins/modules/filter/components/FilterTooltipModal';
import {FilterContext} from 'plugins/modules/filter/context';
import {useFilter} from 'plugins/modules/filter/hooks/useFilter';
import {IFilterState, TFilterActions} from 'plugins/modules/filter/types';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import {PaginationContext} from 'plugins/modules/pagination/context';
import closeIcon from 'public/icons/close.svg';
import filterHintIcon from 'public/icons/filter-hint.svg';
import React, {FC, useCallback, useContext, useEffect, useState} from 'react';

import {FiltersTagList} from '../FiltersTagList';
import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

interface IFilterListProps {
    className?: string;
}

export const FilterList: FC<IFilterListProps> = ({className}) => {
    const dispatch = useAppDispatch();

    const isProductListLoading = useIsListLoading();
    const {isFilterPanelVisible, filterList, pluralList = ['товар', 'товара', 'товаров']} = useContext(FilterContext);
    const {count} = useContext(PaginationContext);
    const slice = useSlice<IFilterState, TFilterActions>();
    const filterFilterList = useFilter();
    const [currentTooltipContent, setCurrentTooltipContent] = useState<BaseFilter['hint']>();

    const handleFilterCloseClick = useCallback(() => {
        dispatch(slice.actions.actionSetIsFilterPanelVisible(false));
    }, [dispatch, slice.actions]);

    const handleTooltipIconClick = useCallback<TClosureCallback<BaseFilter['hint'], any>>((hint) => {
        return () => {
            setCurrentTooltipContent((state) => (state === hint ? '' : hint));
        };
    }, []);

    const handleTooltipClose = useCallback(() => {
        setCurrentTooltipContent('');
    }, []);

    useEffect(() => {
        if (!currentTooltipContent) {
            dispatch(hideModal(MODALS.FILTER_TOOLTIP.name));

            if (isFilterPanelVisible && isMobileByScreen()) {
                disableScroll();
            }
            return;
        }

        if (isMobileByScreen()) {
            dispatch(showModal(MODALS.FILTER_TOOLTIP.name));
        }
    }, [dispatch, currentTooltipContent, isFilterPanelVisible]);

    return (
        <>
            {currentTooltipContent && (
                <FilterTooltipModal content={currentTooltipContent} onClose={handleTooltipClose} />
            )}
            <div
                className={classNames(
                    style.filterListContainer,
                    {[style.filterListHidden]: !isFilterPanelVisible},
                    className
                )}
            >
                <Typography className={classNames(style.filterListTitle, columnStyle)} element="div" variant="h4-bold">
                    Фильтры
                    <button className={style.filterListTitleClose} onClick={handleFilterCloseClick}>
                        <SvgIcon svg={closeIcon} />
                    </button>
                </Typography>
                <FiltersTagList isInFiltersList />
                <Grid
                    as="ul"
                    className={classNames(style.filterListScrollContainer, columnStyle)}
                    data-skeleton-item
                    gridColumnsCount={COLUMNS_COUNT_LAYOUT.filter}
                >
                    {filterFilterList(filterList).map((filter, index) => {
                        return (
                            <li className={columnStyle} key={`${filter.code}-${index}`}>
                                <Collapse
                                    className={style.filterListItemCollapse}
                                    defaultOpen
                                    title={
                                        <Typography className={style.filterListItemTitle} variant="p-strong">
                                            {filter.title}
                                            {filter.hint && (
                                                <button
                                                    className={style.filterListTooltipIcon}
                                                    onBlur={handleTooltipClose}
                                                    onClick={handleTooltipIconClick(filter.hint)}
                                                >
                                                    <SvgIcon svg={filterHintIcon} />
                                                    <div className={style.filterListTooltipContainer}>
                                                        <Typography
                                                            className={classNames(style.filterListTooltip, {
                                                                [style.displayed]: Boolean(
                                                                    currentTooltipContent === filter.hint
                                                                ),
                                                            })}
                                                            color="gray100"
                                                            variant="p-medium"
                                                        >
                                                            {stripHtml(filter.hint)}
                                                        </Typography>
                                                    </div>
                                                </button>
                                            )}
                                        </Typography>
                                    }
                                >
                                    <FilterItem filter={filter} />
                                </Collapse>
                            </li>
                        );
                    })}
                </Grid>
                <div className={style.filterListButton}>
                    <Button
                        isLoading={isProductListLoading}
                        isStretched
                        onClick={handleFilterCloseClick}
                        size="large"
                        theme="primary"
                    >
                        {count} {plural(count, pluralList)}
                    </Button>
                </div>
            </div>
        </>
    );
};
