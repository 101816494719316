import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import favouriteCheckedIcon from 'public/icons/favourite-checked.svg';
import {AllHTMLAttributes, FC, ReactNode, useId} from 'react';

import style from './style.module.scss';

interface IFavouriteSwitcherBaseProps extends AllHTMLAttributes<HTMLElement> {
    notCheckedIcon: ReactNode;
    isChecked: boolean;
}

export const FavouriteSwitcherBase: FC<IFavouriteSwitcherBaseProps> = ({
    className,
    onChange,
    isChecked = false,
    notCheckedIcon,
    ...restInputAttributes
}) => {
    const uid = useId();

    return (
        <div className={classNames(style.favourite, className)} {...restInputAttributes}>
            <input checked={isChecked} id={uid} onChange={onChange} type="checkbox" />
            <label htmlFor={uid}>{isChecked ? <SvgIcon svg={favouriteCheckedIcon} /> : notCheckedIcon}</label>
        </div>
    );
};
