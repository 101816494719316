import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TMenuListResponse} from 'modules/menu/types';

export class MenuService extends EvolutionHttpClient {
    public async getMenuList(): Promise<TMenuListResponse> {
        const {data} = await this.get<TMenuListResponse>({
            url: '/menus',
        });

        return data;
    }
}
