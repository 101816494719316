import {OnlinePaymentContext} from 'modules/checkout/context';
import {OnlinePaymentPage} from 'modules/checkout/pages/OnlinePaymentPage';
import {ONLINE_PAYMENT_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const OnlinePaymentPageSkeleton: FC = () => {
    return (
        <OnlinePaymentContext.Provider value={ONLINE_PAYMENT_PAGE_SKELETON_DATA}>
            <OnlinePaymentPage />
        </OnlinePaymentContext.Provider>
    );
};
