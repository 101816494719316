import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {THEMES} from 'modules/theme/constants';
import {IThemeState} from 'modules/theme/types';
import {HYDRATE} from 'next-redux-wrapper';

export const themeModuleName = 'theme';

const initialState: IThemeState = {
    theme: THEMES.light,
};

export const themeSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return {
                ...payload[themeModuleName],
            };
        },
    },
    initialState,
    name: themeModuleName,
    reducers: {
        actionSetTheme(state: IThemeState, {payload}: PayloadAction<IThemeState['theme']>) {
            state.theme = payload;
        },
    },
});

export const {
    actions: {actionSetTheme},
} = themeSlice;

export const {reducer: themeReducer} = themeSlice;
