import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectUserLocation} from 'modules/locations/selectors';
import {Location} from 'new-models';
import {useMemo} from 'react';

export const useUserLocation = (): Location | null => {
    const userLocation = useAppSelector(selectUserLocation);

    return useMemo<Location | null>(() => userLocation, [userLocation]);
};
