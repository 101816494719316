import {
    getFormattedCoordinates,
    IGetFormattedCoordinatesProps,
    TGetFormattedCoordinates,
} from 'modules/yandex-map/helpers';
import {useMemo} from 'react';

export const useGetFormattedCoordinates = (data: IGetFormattedCoordinatesProps): TGetFormattedCoordinates => {
    return useMemo(() => {
        return getFormattedCoordinates(data);
    }, [data]);
};
