import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {checkIsProductInWishList} from 'modules/wish-list/helpers';
import {wishListModuleName} from 'modules/wish-list/slice';
import {IWishListState} from 'modules/wish-list/types';

const select = (state: TAppRootState): IWishListState => state[wishListModuleName];

export const selectWishLists = (state: TAppRootState): IWishListState['wishLists'] => select(state).wishLists;
export const selectActiveWishList = createDraftSafeSelector([select], (state) => state.activeWishList);

export const selectIsProductInWishList = (productId: number) =>
    createDraftSafeSelector([selectActiveWishList], (activeWishList) =>
        checkIsProductInWishList(activeWishList, productId)
    );

export const selectSelectedProducts = (state: TAppRootState): IWishListState['selectedProducts'] =>
    select(state).selectedProducts;

export const selectIsAfterCalculation = (state: TAppRootState): IWishListState['isAfterCalculation'] =>
    select(state).isAfterCalculation;

export const selectIsProductAdded = (state: TAppRootState): IWishListState['isProductAdded'] =>
    select(state).isProductAdded;

export const selectAddedProductQuantity = (state: TAppRootState): IWishListState['addedProductQuantity'] =>
    select(state).addedProductQuantity;
