import classNames from 'classnames';
import {Loader, LOADER_MODES, LOADER_THEMES} from 'components/Loader';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {PREVENT_DEFAULT_HANDLER_MARKER, STOP_PROP_HANDLER_MARKER} from 'core/constants';
import React, {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    HTMLAttributes,
    memo,
    MouseEventHandler,
    ReactNode,
    useMemo,
} from 'react';

import style from './style.module.scss';

/*
     isSquare - квадратная кнопка
     isNotAdaptive - не уменьшается в мобильной версии
     isStretched - растягивается до ширины родителя
     isWithoutPaddings - обнуляем падинги
     isWithoutTextLoading - убираем текст во время загрузки (только лоадер)
     isContentSize - размеры кнопки (ширина и высота) под размер контента (особое состояние для Ghost)
 */

export const BUTTON_THEMES = {
    default: 'default',
    floating: 'floating',
    ghost: 'ghost',
    primary: 'primary',
    secondary: 'secondary',
} as const;

export const BUTTON_COLORS = {
    primaryColor: 'primaryColor',
    secondaryColor: 'secondaryColor',
} as const;

export type TButtonTheme = keyof typeof BUTTON_THEMES;

export type TButtonColor = keyof typeof BUTTON_COLORS;

export const BUTTON_SIZE = {
    default: 'default',
    large: 'large',
    medium: 'medium',
    small: 'small',
} as const;

export type TButtonSize = keyof typeof BUTTON_SIZE;

export interface IProps
    extends ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>,
        Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'rel'>,
        Pick<ITypographyPublicProps, 'variant'> {
    theme?: TButtonTheme;
    size?: TButtonSize;
    isLoading?: boolean;
    disabled?: boolean;
    isSquare?: boolean;
    isNotAdaptive?: boolean;
    isStretched?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    isWithoutPaddings?: boolean;
    isWithoutTextLoading?: boolean;
    [PREVENT_DEFAULT_HANDLER_MARKER]?: boolean;
    [STOP_PROP_HANDLER_MARKER]?: boolean;
    isContentSize?: boolean;
    color?: TButtonColor;
    isHideTextAdaptive?: boolean;
    ['data-modal-action']?: string;
}

export const Button = memo<IProps>(function Button({
    theme = BUTTON_THEMES.primary,
    isLoading = false,
    disabled = false,
    size = BUTTON_SIZE.large,
    className,
    children,
    isSquare = false,
    isNotAdaptive = false,
    isStretched = false,
    isWithoutPaddings = false,
    isContentSize = false,
    isWithoutTextLoading = false,
    leftIcon,
    rightIcon,
    type = 'button',
    onClick,
    href,
    target,
    color = BUTTON_COLORS.primaryColor,
    rel,
    isHideTextAdaptive,
    variant,
    ...restButtonAttributes
}) {
    function renderTextTag(btnSize: TButtonSize) {
        switch (btnSize) {
            case BUTTON_SIZE.medium: {
                return 'button-m';
            }

            case BUTTON_SIZE.small: {
                return 'button-s';
            }

            default:
                return 'button-l';
        }
    }

    function renderLoaderTheme(btnTheme: TButtonTheme) {
        switch (btnTheme) {
            case BUTTON_THEMES.primary: {
                return LOADER_THEMES.primary;
            }

            case BUTTON_THEMES.floating: {
                return LOADER_THEMES.primary;
            }

            case BUTTON_THEMES.secondary: {
                return LOADER_THEMES.secondary;
            }

            case BUTTON_THEMES.ghost: {
                return LOADER_THEMES.secondary;
            }

            default:
                return LOADER_THEMES.primary;
        }
    }

    const renderChildren = useMemo<ReactNode>(
        () => (
            <>
                {!isLoading ? (
                    <>
                        {leftIcon}
                        {children && (
                            <Typography
                                className={classNames(style.text, {
                                    [style.isNotDisplayed]: isWithoutTextLoading && isLoading,
                                })}
                                variant={variant ?? renderTextTag(size)}
                            >
                                {children}
                            </Typography>
                        )}
                        {rightIcon}
                    </>
                ) : (
                    <Loader mode={LOADER_MODES.compact} theme={renderLoaderTheme(theme)} />
                )}
            </>
        ),
        [isLoading, leftIcon, children, isWithoutTextLoading, variant, size, rightIcon, theme]
    );

    const handleClick: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> = (e) => {
        if (isLoading) {
            e.preventDefault();
        } else {
            onClick?.(e);
        }
    };

    const TargetElement = useMemo<'a' | 'button'>(() => {
        if (href) {
            return 'a';
        }

        return 'button';
    }, [href]);

    const targetAttributes = useMemo<HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>>(() => {
        if ('a' === TargetElement) {
            return {
                href,
                rel,
                target,
            } as HTMLAttributes<HTMLAnchorElement>;
        }

        return {
            type,
        } as HTMLAttributes<HTMLButtonElement>;
    }, [TargetElement, href, rel, target, type]);

    return (
        <TargetElement
            {...targetAttributes}
            {...restButtonAttributes}
            className={classNames(
                className,
                style.button,
                {
                    [style.isContentSize]: isContentSize,
                    [style.isLoading]: isLoading,
                    [style.isAdaptive]: !isNotAdaptive,
                    [style.isSquare]: isSquare,
                    [style.isStretched]: isStretched,
                    [style.isWithoutPaddings]: isWithoutPaddings,
                    [style.isHideTextAdaptive]: isHideTextAdaptive,
                    [style[theme]]: Boolean(theme),
                    [style[size]]: Boolean(size),
                    [style[color]]: Boolean(color),
                },
                'button-component'
            )}
            disabled={disabled}
            onClick={handleClick}
        >
            {renderChildren}
        </TargetElement>
    );
});
