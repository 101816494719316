import classNames from 'classnames';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TPropsWithChildrenRequire} from 'core/types';
import _debounce from 'lodash/debounce';
import {actionSetImageZoomDirection} from 'modules/product-card/slice';
import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {
    ReactZoomPanPinchContentRef,
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch';

import style from './style.module.scss';

const DOUBLE_TAP_THRESHOLD = 500;
const DELAY = 100;

interface IProps extends TPropsWithChildrenRequire {
    wrapperClass?: string;
    contentClass?: string;
}

export const ImageZooming = memo<IProps>(function ImageZooming({children, wrapperClass, contentClass}) {
    const dispatch = useAppDispatch();

    const lastTouchEnd = useRef(0);
    const zoomInstanceRef = useRef<ReactZoomPanPinchContentRef['instance'] | null>(null);

    const [isImageZoomed, setIsImageZoomed] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnZoomStop = useCallback(
        _debounce((ref: ReactZoomPanPinchRef) => setIsImageZoomed(1 < ref.state.scale), DELAY),
        []
    );

    const handleZoom = (ref: ReactZoomPanPinchRef) => {
        const scale = ref.state.scale;
        dispatch(actionSetImageZoomDirection(scale > ref.state.previousScale ? 'zooming-in' : 'zooming-out'));
        ref.state.previousScale = scale;
    };

    const handleDoubleTap = useCallback((event: Event) => {
        const now = new Date().getTime();
        const zoomInstance = zoomInstanceRef.current?.getContext();

        if (zoomInstance && DOUBLE_TAP_THRESHOLD > now - lastTouchEnd.current) {
            event.preventDefault();
            zoomInstance.zoomIn();
        }

        lastTouchEnd.current = now;
    }, []);

    useEffect(() => {
        const zoomContainer = zoomInstanceRef.current?.contentComponent;

        if (!zoomContainer) {
            return;
        }

        zoomContainer.addEventListener('touchend', handleDoubleTap);

        // eslint-disable-next-line consistent-return
        return () => {
            zoomContainer.removeEventListener('touchend', handleDoubleTap);
        };
    }, [handleDoubleTap]);

    return (
        <TransformWrapper
            doubleClick={{mode: 'zoomIn'}}
            onZoom={handleZoom}
            onZoomStop={debouncedOnZoomStop}
            panning={{disabled: !isImageZoomed}}
            pinch={{disabled: false}}
        >
            {(ref) => {
                zoomInstanceRef.current = ref.instance;

                return (
                    <TransformComponent contentClass={contentClass} wrapperClass={classNames(style.root, wrapperClass)}>
                        {children}
                    </TransformComponent>
                );
            }}
        </TransformWrapper>
    );
});
