import classNames from 'classnames';
import {IProps as IMediaHarvesterProps, MediaHarvester} from 'components/MediaHarvester';
import {TPropsWithClassName} from 'core/types';
import {ProductDetail} from 'new-models';
import React, {memo, useCallback, useId, useMemo, useRef, useState} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, Pick<IMediaHarvesterProps, 'options'> {
    imageList: ProductDetail['media'];
}

const SLIDES_COUNT = 5;
const SWIPE_THRESHOLD = 50;

export const ProductImageSwitcher = memo<IProps>(function ProductImageSwitcher({className, imageList, options}) {
    const [activeItemId, setActiveItemId] = useState(0);
    const sliderRef = useRef<HTMLDivElement>(null);
    const uid = useId();

    const touchStartXRef = useRef(0);
    const touchEndXRef = useRef(0);

    const shortImagesList = useMemo(() => {
        if (!imageList) {
            return null;
        }
        return imageList.slice(0, SLIDES_COUNT);
    }, [imageList]);

    const handleTouchStart = useCallback((e: React.TouchEvent) => {
        touchStartXRef.current = e.touches[0].clientX;
    }, []);

    const handleTouchMove = useCallback((e: React.TouchEvent) => {
        touchEndXRef.current = e.touches[0].clientX;
    }, []);

    const handleTouchEnd = () => {
        const deltaX = touchEndXRef.current - touchStartXRef.current;

        if (deltaX > SWIPE_THRESHOLD) {
            setActiveItemId((prev) => (0 < prev ? prev - 1 : 0));
        } else if (deltaX < -SWIPE_THRESHOLD) {
            setActiveItemId((prev) => (prev < (shortImagesList || []).length - 1 ? prev + 1 : prev));
        }
    };

    const handleBulletHover = useCallback((id: number) => {
        return () => {
            setActiveItemId(id);
        };
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (!imageList) {
            return;
        }

        setActiveItemId(0);
    }, [imageList]);

    return (
        <div className={classNames(className, style.productImageSwitcher)} onMouseLeave={handleMouseLeave}>
            {shortImagesList && (
                <>
                    <div
                        className={classNames(style.productImageSwitcherImageContainer, {
                            [style.isOneImage]: 1 === shortImagesList.length,
                        })}
                        onTouchEnd={handleTouchEnd}
                        onTouchMove={handleTouchMove}
                        onTouchStart={handleTouchStart}
                        ref={sliderRef}
                    >
                        {shortImagesList.map((image, index) => {
                            const keyId = `${uid}${index}${image.type}`;
                            return (
                                <div
                                    className={classNames(style.productImageSwitcherImageSlide, {
                                        [style.productImageSwitcherImageSlideActive]: activeItemId === index,
                                    })}
                                    key={keyId}
                                >
                                    <MediaHarvester media={image} options={options} />
                                </div>
                            );
                        })}
                    </div>
                    <div className={style.productImageSwitcherPaginationContainer}>
                        {shortImagesList.map(({type}, index) => {
                            const keyId = `${uid}${index}${type}`;
                            return (
                                <div
                                    className={style.productImageSwitcherPagination}
                                    key={keyId}
                                    onMouseEnter={handleBulletHover(index)}
                                />
                            );
                        })}
                    </div>
                </>
            )}
            <div className={classNames(style.productImageSwitcherInner, 'product-image-switcher-inner')}>
                {shortImagesList && 1 < shortImagesList.length && (
                    <ul className={style.productImageSwitcherNavItems}>
                        {shortImagesList.map(({type}, index) => {
                            const keyId = `${uid}${index}${type}`;
                            return (
                                <li
                                    className={classNames(style.productImageSwitcherNavItem, {
                                        [style.productImageSwitcherNavItemActive]: activeItemId === index,
                                    })}
                                    key={keyId}
                                    onMouseEnter={handleBulletHover(index)}
                                />
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
});
