import classNames from 'classnames';
// import {Button} from 'components/Button';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {formatDateWithLocal} from 'core/helpers/date';
import {getProductPluralText} from 'core/helpers/plural';
import {OrderStatusBadge} from 'modules/orders/components/OrderStatusBadge';
// import {useOrderCancelModalApi} from 'modules/orders/hooks/useOrderCancelModalApi';
import {OrderStatus} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    price: number;
    quantity: number;
    status: OrderStatus;
    className?: string;
    orderNumber: string;
    createDate: string;
    id: string;
}

export const OrderCardItemHeader = memo<IProps>(function OrderCardItemHeader({
    className,
    orderNumber,
    createDate,
    price,
    quantity,
    status,
    // id,
}) {
    // const {open} = useOrderCancelModalApi();

    return (
        <div className={classNames(style.root, className)} data-skeleton-item>
            <div className={style.leftSide}>
                <Typography className={style.leftSideTitle} color="gray100" element="h5" variant="h5">
                    <span>Заказ № {orderNumber}</span>
                    <OrderStatusBadge status={status} />
                </Typography>
                {createDate && (
                    <Typography color="gray50" variant="p">
                        от {formatDateWithLocal(createDate)}
                    </Typography>
                )}
            </div>
            <div className={style.rightSide}>
                <div className={style.rightSideInner}>
                    <Typography className={style.price} color="primary100" element="h5" variant="price-preview-m">
                        {formatPrice((price / PRICE_DENOMINATOR).toString(), {
                            maximumFractionDigits: 2,
                        })}{' '}
                        <Typography variant="p">₽</Typography>
                    </Typography>
                    <Typography color="gray50" variant="p">
                        {quantity} {getProductPluralText(quantity)}
                    </Typography>
                </div>
                {/** // TODO: enable this button when it is clear when it is needed */}
                {/* <Button theme="secondary" onClick={open(id)}>
                    Отменить заказ
                </Button> */}
            </div>
        </div>
    );
});
