import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {CHECKED_LIST_CONDITIONS} from 'modules/cart/constants';
import {actionSetCart, cartModuleName} from 'modules/cart/slice';
import {ICartState, TCheckedListCondition, TRemoveModalView} from 'modules/cart/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {BasketItem, PatchBasketRequestData, ProductDetail, ProductShort} from 'new-models';

const select = (state: TAppRootState): ICartState => state[cartModuleName];

export const selectCartProductsList = createDraftSafeSelector([select], (state) => state.productsList);

export const selectCartTotal = (state: TAppRootState): ICartState['total'] => select(state).total;
export const selectCartLimits = (state: TAppRootState): ICartState['limits']['minCost'] => select(state).limits.minCost;
export const selectCartItemSelectedList = (state: TAppRootState): ICartState['limits']['minCost'] =>
    select(state).limits.minCost;

export const selectCartProductById = (id?: ProductShort['id'] | null) =>
    createDraftSafeSelector([selectCartProductsList], (cartProductList) => {
        return cartProductList.find(({product}) => product.id === id);
    });

export const selectCartProductsListWithoutGifts = () =>
    createDraftSafeSelector([selectCartProductsList], (cartProductList) =>
        cartProductList.filter(({isGift}) => !isGift)
    );

export const selectCartProductCount = (id: ProductShort['id']) =>
    createDraftSafeSelector([(state) => selectCartProductById(id)(state)], (product) => {
        if (!product) {
            return 0;
        }

        return product.quantity;
    });

export const selectCartIsLegalEntity = (state: TAppRootState): boolean => select(state).isLegalEntity;

export const selectCartAfterRemoveToast = (state: TAppRootState): string => select(state).afterRemoveToast;

export const selectCartRemoveModalView = (state: TAppRootState): TRemoveModalView => select(state).removeModalView;

export const selectCartIsOpenAvailabilities = (state: TAppRootState): boolean => select(state).isOpenAvailabilities;

export const selectCartDivideByAvailability = createDraftSafeSelector([selectCartProductsList], (cartProductsList) =>
    cartProductsList.reduce(
        (acc, basketItem) => {
            const {
                quantity,
                product: {availabilityCount, availability},
            } = basketItem;

            const isGift = basketItem.isGift;

            if (isGift) {
                acc[3].push(basketItem);
                return acc;
            }

            const isNotEnough = quantity && availabilityCount && availabilityCount < quantity;

            if (isNotEnough) {
                acc[2].push(basketItem);
                return acc;
            }

            acc[Number(!availability)].push(basketItem);
            return acc;
        },
        [[], [], [], []] as BasketItem[][]
    )
);

export const selectCartAvailabilityItemList = createDraftSafeSelector([selectCartDivideByAvailability], ([list]) =>
    list.filter((item) => item.product.availability)
);

export const selectCartSelectedItemList = createDraftSafeSelector([selectCartDivideByAvailability], ([list]) =>
    list.filter((item) => item.isSelected && item.product.availability)
);

export const selectCartCheckedListCondition = createDraftSafeSelector(
    [selectCartAvailabilityItemList, selectCartSelectedItemList],
    (cartProductList, cartSelectedProductList): TCheckedListCondition => {
        if (!cartSelectedProductList.length) {
            return CHECKED_LIST_CONDITIONS.NONE;
        }

        if (cartSelectedProductList.length === cartProductList.length) {
            return CHECKED_LIST_CONDITIONS.ALL;
        }

        if (1 === cartSelectedProductList.length) {
            return CHECKED_LIST_CONDITIONS.ONE;
        }

        return CHECKED_LIST_CONDITIONS.SOME;
    }
);

export const selectCartRemoveList = createDraftSafeSelector(
    [selectCartProductsList],
    (cartProductList): PatchBasketRequestData[] => {
        return cartProductList
            .filter((basketItem) => basketItem.isSelected)
            .map(({product}) => ({productId: product.id, quantity: 0}));
    }
);

export const selectCartRemoveId = (state: TAppRootState): ProductShort['id'] | null => select(state).removeId;
export const selectCartSelectedProductId = (state: TAppRootState): ProductShort['id'] | null =>
    select(state).selectedProductId;

export const selectIsCartLoads = (state: TAppRootState) => selectIsLoading(actionSetCart.type, true)(state);

export const selectIsCartCountLoads = (id: ProductDetail['id']) => (state: TAppRootState) =>
    selectIsLoading(`${actionSetCart.type}__id:${id}`)(state);

export const selectDeliveryInfo = (state: TAppRootState): ICartState['deliveryInfo'] => select(state).deliveryInfo;
