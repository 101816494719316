import {FilterTag, IProps as IFilterTagProps} from 'components/Tag/FilterTag';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {FilterRange} from 'new-models';
import {FilterContext} from 'plugins/modules/filter/context';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import React, {FC, useCallback, useContext} from 'react';

export const RangeFilterTagItem: FC<FilterRange> = (rangeFilter) => {
    const {code} = rangeFilter;
    const isLoading = useIsListLoading();
    const {urlService: filterService} = useContext(FilterContext);

    const handleDiscardRangeFilter: IFilterTagProps['onClick'] = useCallback(async () => {
        if (isLoading) {
            return;
        }

        await filterService?.removeParam({id: code});
    }, [code, filterService, isLoading]);

    const formatPriceOption: Intl.NumberFormatOptions = 'price' === rangeFilter.code ? {style: 'currency'} : {};

    return (
        <FilterTag
            description={`от ${formatPrice(
                String((rangeFilter.minValue ?? 0) / PRICE_DENOMINATOR),
                formatPriceOption
            )} до ${formatPrice(String((rangeFilter.maxValue ?? 0) / PRICE_DENOMINATOR), formatPriceOption)}`}
            name={rangeFilter.title}
            onClick={handleDiscardRangeFilter}
        />
    );
};
