import {CalculatorType} from 'models';
import {
    ICalculatorField,
    ICalculatorResult,
    ICalculatorSurfaceField,
    TCalculatorFieldsKeys,
} from 'modules/calculators/types';

export const FIELDS: Record<string, ICalculatorField> = {
    CLIMATE_ZONE: {
        error: 'климатическую зону',
        name: 'climateZone',
        placeholder: 'Климатическая зона (до °C)',
        selectOptions: [
            {label: 'До -15C', value: 15},
            {label: 'До -20C', value: 20},
            {label: 'До -30C', value: 30},
        ],
    },
    CONNECTION_TYPE: {
        error: 'тип подключения',
        name: 'connectionType',
        placeholder: 'Тип подключения',
        selectOptions: [
            {label: 'Боковое', value: 'side'},
            {label: 'Нижнее', value: 'bottom'},
        ],
    },
    DOOR_WIDTH: {
        error: 'ширину двери',
        name: 'doorWidth',
        placeholder: 'Ширина двери (м)',
    },
    DOORS_QUANTITY: {
        error: 'количество дверей',
        name: 'doorsQuantity',
        placeholder: 'Количество дверей (шт.)',
    },
    DOORWAYS_AREA: {
        error: 'площадь дверных проемов',
        name: 'doorwaysArea',
        placeholder: 'Площадь дверных проемов (м²)',
    },
    FLOOR: {
        error: 'этаж',
        name: 'floor',
        placeholder: 'Этаж',
        selectOptions: [
            {label: 'Первый или последний', value: 'firstOrLast'},
            {label: 'Средний', value: 'middle'},
        ],
    },
    GABLE_HEIGHT: {
        error: 'высоту фронтона',
        name: 'gableHeight',
        placeholder: 'Высота фронтона (м)',
    },
    GABLE_WIDTH: {
        error: 'ширину фронтона',
        name: 'gableWidth',
        placeholder: 'Ширина фронтона (м)',
    },
    HEIGHT: {
        error: 'высоту окрашиваемой поверхности',
        name: 'height',
        placeholder: 'Высота окрашиваемой поверхности (м)',
    },
    HOUSE_LENGTH: {
        error: 'длину дома',
        name: 'houseLength',
        placeholder: 'Длина дома (м)',
    },
    HOUSE_WIDTH: {
        error: 'ширину дома',
        name: 'houseWidth',
        placeholder: 'Ширина дома (м)',
    },
    LAYER_THICKNESS: {
        error: 'толщину слоя',
        name: 'layerThickness',
        placeholder: 'Толщина слоя (мм)',
    },
    LAYING_METHOD: {
        name: 'layingMethod',
        placeholder: 'Способ укладки',
        radios: [
            {id: 0, label: 'По прямой', value: 'straight'},
            {id: 1, label: 'По диагонали', value: 'diagonal'},
        ],
    },
    LENGTH: {
        error: 'длину помещения',
        name: 'length',
        placeholder: 'Длина помещения (м)',
    },
    PAINT_COATS_QUANTITY: {
        error: 'количество слоев краски',
        name: 'paintCoatsQuantity',
        placeholder: 'Количество слоев краски (шт.)',
    },
    ROOM_AREA: {
        error: 'площадь поверхности',
        name: 'roomArea',
        placeholder: 'Площадь поверхности (м²)',
    },
    ROOM_HEIGHT: {
        error: 'высоту помещения',
        name: 'roomHeight',
        placeholder: 'Высота помещения (м)',
        selectOptions: [
            {label: 'До 2.7м', value: 2.7},
            {label: 'До 3.0м', value: 3},
            {label: 'До 3.5м', value: 3.5},
        ],
    },
    ROOM_LOCATION: {
        error: 'расположение комнаты',
        name: 'roomLocation',
        placeholder: 'Расположение комнаты',
        selectOptions: [
            {label: 'Угловая', value: 'corner'},
            {label: 'Неугловая', value: 'non-corner'},
        ],
    },
    ROOM_PERIMETER: {
        error: 'периметр помещения',
        name: 'roomPerimeter',
        placeholder: 'Периметр помещения (м)',
    },
    SURFACE_AREA: {
        error: 'площадь поверхности',
        name: 'surfaceArea',
        placeholder: 'Площадь поверхности (м²)',
    },
    TILE_JOINT_WIDTH: {
        error: 'ширину плиточного шва',
        name: 'tileJointWidth',
        placeholder: 'Ширина плиточного шва (мм)',
    },
    TILE_LENGTH: {
        error: 'длину плитки',
        name: 'tileLength',
        placeholder: 'Длина плитки (см)',
    },
    TILE_THICKNESS: {
        error: 'длину плитки',
        name: 'tileThickness',
        placeholder: 'Толщина плитки (мм)',
    },
    TILE_WIDTH: {
        error: 'ширину плитки',
        name: 'tileWidth',
        placeholder: 'Ширина плитки (см)',
    },
    WALL_HEIGHT: {
        error: 'высоту оклеиваемой стены',
        name: 'wallHeight',
        placeholder: 'Высота стены, до фронтона (м)',
    },
    WALL_TYPE: {
        error: 'тип стен',
        name: 'wallType',
        placeholder: 'Тип стен',
        selectOptions: [
            {label: 'Панельные', value: 'panel'},
            {label: 'Кирпичные', value: 'brick'},
            {label: 'Утепленные', value: 'insulated'},
        ],
    },
    WIDTH: {
        error: 'ширину помещения',
        name: 'width',
        placeholder: 'Ширина помещения (м)',
    },
    WINDOW_AREA: {
        error: 'площадь окон',
        name: 'windowArea',
        placeholder: 'Площадь окон (м²)',
    },
    WINDOW_ORIENTATION: {
        error: 'ориентацию окон',
        name: 'windowOrientation',
        placeholder: 'Ориентация окон',
        selectOptions: [
            {label: 'Юг', value: 'south'},
            {label: 'Запад', value: 'west'},
            {label: 'Восток', value: 'east'},
            {label: 'Север', value: 'north'},
        ],
    },
    WINDOW_TYPE: {
        error: 'тип стеклопакета',
        name: 'windowType',
        placeholder: 'Тип стеклопакета',
        selectOptions: [
            {label: 'Дерево', value: 'wood'},
            {label: 'Однокамерный', value: 'one'},
            {label: 'Двухкамерный и более', value: 'twoOrMore'},
        ],
    },
    WINDOW_WIDTH: {
        error: 'ширину окна',
        name: 'windowWidth',
        placeholder: 'Ширина окна (м)',
    },
    WINDOWS_QUANTITY: {
        error: 'количество окон',
        name: 'windowsQuantity',
        placeholder: 'Количество окон (шт.)',
    },
};

export const CONVERSION_FIELDS: Record<string, ICalculatorSurfaceField> = {
    SURFACE_LENGTH: {
        error: 'длину поверхности',
        name: 'surfaceLength',
        placeholder: 'Длина поверхности (м)',
    },
    SURFACE_WIDTH: {
        error: 'ширину поверхности',
        name: 'surfaceWidth',
        placeholder: 'Ширина поверхности (м)',
    },
};

export const PAINT_ABLE_SURFACE_FIELDS: Record<string, ICalculatorField> = {
    LENGTH: {
        error: 'длину помещения',
        name: 'length',
        placeholder: 'Длина помещения (м)',
    },
    PAINT_COATS_QUANTITY: {
        error: 'количество слоев краски',
        name: 'paintCoatsQuantity',
        placeholder: 'Количество слоев краски (шт.)',
    },
    WIDTH: {
        error: 'ширину помещения',
        name: 'width',
        placeholder: 'Ширина помещения (м)',
    },
};

export const INITIAL_VALUES: Record<CalculatorType, Partial<Record<TCalculatorFieldsKeys, string>>> = {
    /* eslint-disable sort-keys*/
    ['dry-mixes']: {surfaceArea: '', layerThickness: ''},
    drywall: {surfaceArea: ''},
    ['interior-paint']: {
        length: '',
        width: '',
        height: '',
        windowArea: '',
        doorwaysArea: '',
        paintCoatsQuantity: '',
    },
    ['glass-wool']: {surfaceArea: ''},
    grout: {
        tileWidth: '',
        tileLength: '',
        tileThickness: '',
        tileJointWidth: '',
        surfaceArea: '',
    },
    laminate: {
        layingMethod: 'straight',
        length: '',
        width: '',
    },
    ['outdoor-paint']: {
        houseWidth: '',
        houseLength: '',
        wallHeight: '',
        gableWidth: '',
        gableHeight: '',
        windowArea: '',
        doorwaysArea: '',
        paintCoatsQuantity: '',
    },
    tile: {surfaceArea: ''},
    wallpaper: {
        roomPerimeter: '',
        wallHeight: '',
        windowWidth: '',
        windowsQuantity: '',
        doorWidth: '',
        doorsQuantity: '',
    },
    radiator: {
        roomArea: '',
        windowOrientation: '',
        roomLocation: '',
        wallType: '',
        climateZone: '',
        roomHeight: '',
        floor: '',
        windowType: '',
        connectionType: '',
    },
    /* eslint-enable sort-keys*/
};

export const CONVERSION_VALUES: Record<string, string> = {
    surfaceLength: '',
    surfaceWidth: '',
};

export const PAINT_ABLE_SURFACE_VALUES: Record<string, string> = {
    length: '',
    width: '',
    // eslint-disable-next-line sort-keys
    paintCoatsQuantity: '',
};

export const CALCULATOR_TITLE: string = 'Расчёт количества';
export const CALCULATOR_OPEN_BUTTON_TEXT: string = 'Рассчитать количество';
export const AMOUNT_TITLE: string = 'На сумму:';

export const RESULTS: Record<CalculatorType, ICalculatorResult[]> = {
    ['dry-mixes']: [
        {
            name: 'totalWeight',
            title: 'Требуемое количество:',
            unit: 'кг.',
        },
        {
            name: 'packingQuantity',
            title: 'Количество штук к покупке:',
        },
    ],
    ['drywall']: [
        {
            name: 'quantity',
            title: 'Количество штук к покупке:',
        },
    ],
    ['glass-wool']: [
        {
            name: 'packingQuantity',
            title: 'Количество упаковок к покупке:',
        },
        {
            name: 'packingArea',
            title: 'Количество м² в упаковке:',
        },
    ],
    ['grout']: [
        {
            name: 'totalWeight',
            title: 'Требуемое количество:',
            unit: 'кг.',
        },
        {
            name: 'quantity',
            title: 'Количество штук к покупке:',
        },
    ],
    ['interior-paint']: [
        {
            name: 'quantity',
            title: 'Количество штук к покупке:',
        },
    ],
    ['laminate']: [
        {
            name: 'packingQuantity',
            title: 'Количество упаковок к покупке:',
        },
        {
            name: 'packingArea',
            title: 'Количество м² в упаковке:',
        },
    ],
    ['outdoor-paint']: [
        {
            name: 'quantity',
            title: 'Количество штук к покупке:',
        },
    ],
    ['radiator']: [
        {
            name: 'power',
            title: 'Требуемая мощность радиаторов:',
            unit: 'Вт',
        },
        {
            name: 'sectionsQuantity',
            title: 'Требуемое количество секций:',
        },
    ],
    ['tile']: [
        {
            adaptiveTitle: 'Упаковок:',
            name: 'packingQuantity',
            title: 'Количество упаковок к покупке:',
            unit: 'упак.',
        },
        {
            name: 'extraTileQuantity',
            title: '',
            unit: 'плитки',
        },
        {
            adaptiveTitle: 'м² для заказа:',
            name: 'tileArea',
            title: 'Количество м² для заказа:',
        },
    ],
    ['wallpaper']: [
        {
            name: 'quantity',
            title: 'Количество рулонов к покупке:',
        },
    ],
};

export const UNIT = {
    METER: 'м²',
    PACK: 'упак.',
    PIECE: 'шт.',
    ROLL: 'рул.',
};

export const DISPLAYED_RESULT_FIELD_INDEX = [0, 1, 2];
export const BUTTON_TEXT = {
    IN_CART: 'В корзине',
    IN_WISH: 'В списке покупок',
    INTO_CART: 'В корзину',
    INTO_WISH: 'В список покупок',
};

export const ERROR_TEXT = 'Пожалуйста, введите';

export const FORM_SWITCHERS = {
    CONVERSION: {
        name: 'conversion',
        placeholder: 'Cпособ расчёта',
        radios: [
            {id: 0, label: 'По площади', value: 'area'},
            {id: 1, label: 'По длине и ширине', value: 'sizes'},
        ],
    },
    PAINT_ABLE_SURFACE: {
        name: 'paintableSurface',
        placeholder: 'Окрашиваемая поверхность',
        radios: [
            {id: 0, label: 'Стена', value: 'wall'},
            {id: 1, label: 'Потолок', value: 'ceiling'},
        ],
    },
};

export const SCROLLED_TO_ID = 'scrolledToId';
