import {Typography} from 'components/Typography';

import style from './style.module.scss';

interface IProps {
    title: string;
    property: string;
}
export const OrderInfoItem: FC<IProps> = ({title, property}) => (
    <div className={style.root}>
        <Typography color="gray60">{title}</Typography>
        <Typography>{property}</Typography>
    </div>
);
