import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {SvgIcon} from 'components/SvgIcon';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {selectActiveIdx} from 'modules/slider/selectors';
import {MediaImage, MediaTypeEnum, MediaVideo, MediaYoutube} from 'new-models';
import videoIcon from 'public/icons/video.svg';
import React, {FC, MouseEventHandler, useContext} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    fullWidthThumb?: boolean;
    media: MediaImage | MediaYoutube | MediaVideo;
    onClick: MouseEventHandler<HTMLButtonElement>;
    idx: number;
}

export const GalleryButtonSlide: FC<IProps> = ({fullWidthThumb, media, onClick, className, idx}) => {
    const {mode} = useContext<IGallerySliderContextProps>(GallerySliderContext);

    const activeIdx = useAppSelector(selectActiveIdx);

    if (MediaTypeEnum.Youtube === media.type || MediaTypeEnum.Video === media.type) {
        const preview = (media as MediaYoutube | MediaVideo).preview;

        return (
            <button
                className={classNames(className, style.galleryButtonSlide, style.video, {
                    [style[mode]]: Boolean(mode),
                    [style[media.type]]: Boolean(media.type),
                    [style.active]: Boolean(activeIdx === idx),
                    [style.fullWidthThumb]: fullWidthThumb,
                    [style.withPreview]: Boolean(preview),
                })}
                onClick={onClick}
            >
                <MediaHarvester media={preview} />
                <span className={style.videoButtonOverlay}>
                    <SvgIcon svg={videoIcon} />
                </span>
            </button>
        );
    }

    return (
        <button
            className={classNames(className, style.galleryButtonSlide, {
                [style[mode]]: Boolean(mode),
                [style[media.type]]: Boolean(media.type),
                [style.active]: Boolean(activeIdx === idx),
                [style.fullWidthThumb]: fullWidthThumb,
            })}
            onClick={onClick}
        >
            <MediaHarvester
                media={media}
                options={generateMediaOptions({
                    resizeKey: IMAGE_RESIZE_CODE.w108,
                    type: MediaTypeEnum.Image,
                })}
            />
        </button>
    );
};
