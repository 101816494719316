import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {IHttpClientResponse, TJson, TResponse} from 'core/types';
import {IPhoneValues} from 'modules/authentication/types';
import {Profile} from 'new-models';

export class RegistrationService extends EvolutionHttpClient {
    public registration({phone}: IPhoneValues): Promise<IHttpClientResponse<TResponse<Profile>, TJson, Response>> {
        return this.post<IPhoneValues, TResponse<Profile>>({
            body: {
                phone,
            },
            url: '/profile/registration',
        });
    }
}
