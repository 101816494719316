import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getValueByKey} from 'core/helpers/normalize';
import {TArrayExtract} from 'core/types';
import {TFavouritesState} from 'modules/favourites/types';
import {FavouritesCreateDataRequest, ProductShort} from 'new-models';

export const favouritesModuleName = 'favourites';

const initialState: TFavouritesState = {
    favouritesIdList: [],
    favouritesList: [],
};
export const favouritesSlice = createSlice({
    initialState,
    name: favouritesModuleName,
    reducers: {
        actionDeleteFromFavourite(state: TFavouritesState, {payload}: PayloadAction<FavouritesCreateDataRequest>) {
            state.favouritesList = state.favouritesList.filter(({id}) => payload.productId !== id);
            state.favouritesIdList = state.favouritesIdList.filter((id) => payload.productId !== id);
        },
        actionPushToFavourite(state: TFavouritesState, {payload}: PayloadAction<ProductShort>) {
            state.favouritesList.push(payload);
            state.favouritesIdList.push(payload.id);
        },
        actionSetFavouritesList(state: TFavouritesState, {payload}: PayloadAction<TFavouritesState['favouritesList']>) {
            state.favouritesList = payload;
            state.favouritesIdList = payload.map(
                getValueByKey<TArrayExtract<TFavouritesState['favouritesList']>>('id')
            );
        },
    },
});

export const {
    actions: {actionDeleteFromFavourite, actionPushToFavourite, actionSetFavouritesList},
} = favouritesSlice;

export const {reducer: favouritesReducer} = favouritesSlice;
