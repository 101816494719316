import {BUTTON_COLORS, BUTTON_SIZE, BUTTON_THEMES, TButtonColor, TButtonSize, TButtonTheme} from 'components/Button';
import {useIsMobile} from 'core/hooks/useIsMobile';
import {POSITION_IN_CARD_VARIANT, PRODUCT_CARD_VARIANT, PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {TPositionInCardVariant, TProductCardVariant, TProductListView} from 'modules/products/types';
import {useCallback} from 'react';

export const useProductCardActions = () => {
    const isMobile = useIsMobile();
    const isPreview = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): boolean =>
            PRODUCT_LIST_VIEW.tile === variant ||
            PRODUCT_CARD_VARIANT.auto === variant ||
            POSITION_IN_CARD_VARIANT.searchPanel === variant,
        []
    );

    const productCardActionsButtonsSize = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): TButtonSize => {
            if (
                POSITION_IN_CARD_VARIANT.productPageHeader === variant ||
                PRODUCT_LIST_VIEW.tile === variant ||
                PRODUCT_CARD_VARIANT.auto === variant ||
                POSITION_IN_CARD_VARIANT.searchPanel === variant ||
                (isMobile && PRODUCT_CARD_VARIANT.preview === variant)
            ) {
                return BUTTON_SIZE.medium;
            }

            return BUTTON_SIZE.large;
        },
        [isMobile]
    );

    const isStretched = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): boolean =>
            (variant === PRODUCT_CARD_VARIANT.auto && isMobile) || POSITION_IN_CARD_VARIANT.searchPanel === variant,
        [isMobile]
    );

    const productCardActionsButtonsColor = useCallback((isInCart: boolean): TButtonColor => {
        if (isInCart) {
            return BUTTON_COLORS.primaryColor;
        }

        return BUTTON_COLORS.primaryColor;
    }, []);

    const subscribeColor = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): TButtonColor => {
            if (isPreview(variant)) {
                return BUTTON_COLORS.primaryColor;
            }

            return BUTTON_COLORS.primaryColor;
        },
        [isPreview]
    );

    const subscribeTheme = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): TButtonTheme => {
            if (isPreview(variant)) {
                return BUTTON_THEMES.secondary;
            }

            return BUTTON_THEMES.secondary;
        },
        [isPreview]
    );

    const isSquare = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): boolean =>
            (isPreview(variant) && !isMobile) || POSITION_IN_CARD_VARIANT.searchPanel === variant,
        [isMobile, isPreview]
    );

    const isWithoutPaddings = useCallback(
        (variant: TProductListView | TProductCardVariant | TPositionInCardVariant): boolean =>
            PRODUCT_CARD_VARIANT.preview === variant || POSITION_IN_CARD_VARIANT.searchPanel === variant,
        []
    );

    return {
        isWithoutPaddings,
        // eslint-disable-next-line sort-keys
        isPreview,
        isSquare,
        isStretched,
        productCardActionsButtonsColor,
        productCardActionsButtonsSize,
        subscribeColor,
        subscribeTheme,
    };
};
