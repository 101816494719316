import classNames from 'classnames';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {}

export const OrderXInfoBlock = memo<IProps>(function OrderXInfoBlock({className, children}) {
    return <div className={classNames(style.root, className)}>{children}</div>;
});
