import classNames from 'classnames';
import {isBrowserContext, isMobileByScreen} from 'core/helpers';
import {StandardLonghandProperties} from 'csstype';
import {
    createElement,
    CSSProperties,
    DataHTMLAttributes,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';

import css from './style.module.scss';

export interface ITypographyPublicProps {
    style?: CSSProperties;
    align?: StandardLonghandProperties['textAlign'];
    color?:
        | 'error50'
        | 'error10'
        | 'warning50'
        | 'warning10'
        | 'info50'
        | 'success50'
        | 'primary100'
        | 'primary80'
        | 'primary70'
        | 'primary60'
        | 'primary50'
        | 'primary30'
        | 'primary30a20'
        | 'primary20'
        | 'primary10'
        | 'secondary100'
        | 'secondary80'
        | 'secondary70'
        | 'gray100'
        | 'gray80'
        | 'gray70'
        | 'gray65'
        | 'gray60'
        | 'gray50'
        | 'gray40'
        | 'gray30'
        | 'gray20'
        | 'black100'
        | 'black-a50'
        | 'black-a40'
        | 'black-a10'
        | 'white100'
        | 'white-a90'
        | 'white-a70'
        | 'white-a50'
        | 'white-a10'
        | 'yellow100'
        | 'yellow80'
        | 'yellow-green80'
        | 'gray-blue80'
        | 'gray-blue60'
        | 'gray-blue50'
        | 'gray-blue30'
        | 'gray-blue20'
        | 'light-gray-blue90'
        | 'light-gray-blue80'
        | 'shadow-gray-blue-a3'
        | 'current-color'
        | 'price-old'
        | 'basic-price'
        | 'gray-a50';
    element?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'p'
        | 'span'
        | 'div'
        | 'dd'
        | 'dt'
        | 'a'
        | 'li'
        | 'legend'
        | 'address'
        | 'time';
    variant?:
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'h5'
        | 'h6'
        | 'h7'
        | 'p'
        | 'p-strong'
        | 'elements-strong'
        | 'elements-p'
        | 'p-u'
        | 'p-regular'
        | 'p-bold-strong'
        | 'p-small'
        | 'p-small-strong'
        | 'link'
        | 'button-l'
        | 'button-m'
        | 'button-s'
        | 'price-l'
        | 'price-preview-l'
        | 'price-preview-m'
        | 'price-preview-s'
        | 'old-price-preview-l'
        | 'old-price-preview-m'
        | 'old-price-preview-s'
        | 'price-preview-cart'
        | 'old-price-cart'
        // unused fonts
        | 'h1-bold'
        | 'h2-bold'
        | 'h3-bold'
        | 'h4-bold'
        | 'h5-bold'
        | 'h6-bold'
        | 'h7-bold'
        | 'p-medium'
        | 'p-medium-strong'
        | 'label'
        | 'badge';
    onlyResponsiveVariant?: 'small' | 'large';
    shrinkWidthOnTextWrapOptions?: {
        onlyMobile?: boolean;
        onResize?: boolean;
    };
}

export interface IProps extends ITypographyPublicProps, Omit<DataHTMLAttributes<any>, 'color' | 'style'> {}

export const Typography = forwardRef<HTMLElement, IProps>(function Typography(
    {
        children,
        onlyResponsiveVariant,
        element = 'span',
        variant = 'p',
        color = '',
        align = '',
        style = {},
        className,
        shrinkWidthOnTextWrapOptions,
        ...rest
    },
    ref
) {
    const innerRef = useRef<HTMLElement>();

    useImperativeHandle(ref, () => {
        return innerRef.current as HTMLElement;
    });

    useEffect(() => {
        if (!shrinkWidthOnTextWrapOptions) {
            return;
        }

        if (!isBrowserContext()) {
            return;
        }

        /**
         * Текстовые узлы, при переносе слов продолжают использовать размер блока такой, который необходим для вмещения всего текста без переносов строк.
         * Уменьшить, а точнее сделать размер блока сугубо по новому размеру текста с переносом можно лишь с помощь
         */
        const shrinkTextNodeWidth = () => {
            if (!innerRef.current) {
                return;
            }

            if (!isMobileByScreen() && shrinkWidthOnTextWrapOptions.onlyMobile) {
                innerRef.current.style.width = 'unset';
                return;
            }

            innerRef.current.style.width = 'unset';

            const textNode = innerRef.current.childNodes[0];
            if (!textNode) {
                return;
            }
            const range = document.createRange();

            range.setStartBefore(textNode);
            range.setEndAfter(textNode);

            const clientRect = range.getBoundingClientRect();

            innerRef.current.style.width = `${clientRect.width}px`;
        };

        shrinkTextNodeWidth();

        if (shrinkWidthOnTextWrapOptions.onResize) {
            window.addEventListener('resize', shrinkTextNodeWidth);
        }

        // eslint-disable-next-line consistent-return
        return () => {
            if (shrinkWidthOnTextWrapOptions.onResize) {
                window.removeEventListener('resize', shrinkTextNodeWidth);
            }
        };
    }, [shrinkWidthOnTextWrapOptions]);

    return createElement(
        element,
        {
            ...rest,
            className: classNames(
                css[align],
                css[color],
                css[variant],
                {
                    [css[String(onlyResponsiveVariant)]]: Boolean(onlyResponsiveVariant),
                },
                className
            ),
            ref: innerRef,
            style,
        },
        children
    );
});
