import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {THEMES} from 'modules/theme/constants';
import {ThemeContext} from 'modules/theme/context';
import {actionSetTheme} from 'modules/theme/slice';
import {TTheme} from 'modules/theme/types';
import {useCallback, useContext, useEffect, useMemo} from 'react';

interface IUseTheme {
    theme: TTheme;
    switchTheme: (isLight: boolean) => void;
}

export const useTheme = (): IUseTheme => {
    const dispatch = useAppDispatch();

    const {theme} = useContext(ThemeContext);

    const switchTheme = useCallback(
        (isLight: boolean) => {
            dispatch(actionSetTheme(isLight ? THEMES.light : THEMES.dark));
        },
        [dispatch]
    );

    useEffect(() => {
        return () => {
            dispatch(actionSetTheme(THEMES.light));
        };
    }, [dispatch]);

    return useMemo(
        () => ({
            switchTheme,
            theme,
        }),
        [switchTheme, theme]
    );
};
