import {AbstractUrlService} from 'core/abstract/AbstractUrlService';
import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TOrderDataResponse, TOrderListResponse} from 'modules/orders/models/OrderListResponse';
import {OrderCancelDataRequest} from 'new-models';

export class OrdersService extends EvolutionHttpClient {
    public async getOrderList(
        query: URLSearchParams,
        params: {page: number; limit: number}
    ): Promise<TOrderListResponse> {
        AbstractUrlService.mapToUrlSearchParams(query, params);

        const {data} = await this.get<TOrderListResponse>({
            url: `/profile/orders?${query}`,
        });

        return data;
    }

    public async getOrder(orderId: string): Promise<TOrderDataResponse> {
        const {data} = await this.get<TOrderDataResponse>({
            url: `/profile/order/${orderId}`,
        });

        return data;
    }

    public async cancelOrder(orderId: string, body?: OrderCancelDataRequest): Promise<TOrderDataResponse> {
        const {data} = await this.deleteWithBody<OrderCancelDataRequest, TOrderDataResponse>({
            body,
            url: `/profile/order/${orderId}`,
        });

        return data;
    }
}
