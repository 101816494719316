import {DatePickerCalendar} from 'components/Datepicker';
import {FlyFilterButton} from 'components/FlyFilterButton';
import {FC, useState} from 'react';
import React from 'react';

import style from './style.module.scss';

interface IDatePickerRange {
    from?: string;
    to?: string;
}

export const DatePickerRange: FC<IDatePickerRange> = ({from = '', to = ''}) => {
    const [startDate, setStartDate] = useState(from ? new Date(from) : null);
    const [endDate, setEndDate] = useState(to ? new Date(to) : null);

    const handleSubmit = (data: any) => {
        console.info(data);
    };

    const acceptButton = (
        <FlyFilterButton onClick={handleSubmit} title="Применить">
            Применить
        </FlyFilterButton>
    );

    return (
        <div className={style.datePickerRangeContainer}>
            <DatePickerCalendar
                endDate={endDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="C"
                selected={startDate}
                selectsStart
                startDate={startDate}
            />
            <DatePickerCalendar
                endDate={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText="По"
                selected={endDate}
                selectsEnd
                startDate={startDate}
            />
            {startDate && endDate && acceptButton}
        </div>
    );
};
