import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {commonModuleName} from 'modules/common/slice';
import {ICommonState} from 'modules/common/types';
import {Promo} from 'new-models';

const select = (state: TAppRootState): ICommonState => state[commonModuleName];

export const selectBannerList = (state: TAppRootState): ICommonState['bannerList'] => select(state).bannerList;

export const selectFeedbackSubjects = (state: TAppRootState): ICommonState['feedbackSubjects'] =>
    select(state).feedbackSubjects;

export const selectBannerListByPlace = (needPlace: Promo['place']) =>
    createDraftSafeSelector([selectBannerList], (promoList) => {
        return promoList.filter(({place}) => place === needPlace);
    });
