import classNames from 'classnames';
import {CollapseAnimated} from 'components/CollapseAnimated';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import React, {FC, ReactNode, useId, useState} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    title: ReactNode;
    defaultOpen?: boolean;
}

export const Collapse: FC<IProps> = ({defaultOpen, className, children, title}) => {
    const uid = useId();
    const [isCollapsed, setIsCollapsed] = useState<boolean>(Boolean(defaultOpen));

    const onChange = () => setIsCollapsed((prevState) => !prevState);

    return (
        <div className={className}>
            <input
                checked={isCollapsed}
                className={style.input}
                defaultChecked={defaultOpen}
                id={uid}
                onChange={onChange}
                type="checkbox"
            />
            <label className={style.label} htmlFor={uid}>
                {title}
                <SvgIcon
                    className={classNames(style.chevronIcon, {
                        [style.chevronIconCollapsed]: isCollapsed,
                    })}
                    svg={chevronDownIcon}
                />
            </label>
            <CollapseAnimated
                className={classNames(style.content, {
                    [style.contentCollapsed]: isCollapsed,
                })}
                isHidden={!isCollapsed}
            >
                {children}
            </CollapseAnimated>
        </div>
    );
};
