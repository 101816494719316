import classNames from 'classnames';
import {Toast} from 'components/Toast';
import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {formatPhone} from 'core/regex';
import {TPropsWithClassName} from 'core/types';
import {Form, Formik} from 'formik';
import {ERRORS, MIN_PHONE_LENGTH} from 'modules/authentication/constants';
import {FranchiseFormFields} from 'modules/franchise/components/FranchiseFormFields';
import {handleFranchise} from 'modules/franchise/thunks';
import {Franchise} from 'new-models';
import {memo, useCallback, useMemo, useState} from 'react';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import style from './style.module.scss';

const MESSAGE_MAX_LENGTH = 2000;

const validationSchema = Yup.object().shape({
    email: Yup.string().trim().email(ERRORS.incorrectEmail),
    message: Yup.string()
        .required(ERRORS.messageRequired)
        .test('long message', ERRORS.longMessage, (value) => MESSAGE_MAX_LENGTH > (value?.length || 0)),
    name: Yup.string().required(ERRORS.firstNameRequired),
    phone: Yup.string()
        .required(ERRORS.phoneRequired)
        .test('short phone', ERRORS.shortPhone, (value) => formatPhone(value as string)?.length > MIN_PHONE_LENGTH),
    secondName: Yup.string().required(ERRORS.lastNameRequired),
});

export const FranchiseForm = memo<TPropsWithClassName>(function FranchiseForm({className}) {
    const dispatch = useAppDispatch();

    const [isAgreement, setIsAgreement] = useState(false);

    const initialValues = useMemo<Franchise>(
        () => ({agreementConfirmed: false, email: '', message: '', name: '', secondName: ''}),
        []
    );

    const showSuccessToast = useCallback(() => {
        toast(
            <Toast
                hasIcon={false}
                isNeedCloseButton
                text="Ваша заявка отправлена! Наши специалисты свяжутся с вами в течении 3-х дней."
                theme="info"
            />,
            {
                className: 'notification',
                position: isMobileByScreen() ? 'bottom-center' : 'bottom-right',
            }
        );
    }, []);

    const onFormSubmit = useCallback(
        async (values: Franchise) => {
            await dispatch(handleFranchise({...values, agreementConfirmed: isAgreement}));
            showSuccessToast();
        },
        [dispatch, isAgreement, showSuccessToast]
    );

    const handleAgreementChange = useCallback(() => setIsAgreement(!isAgreement), [isAgreement]);

    return (
        <Formik initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={validationSchema}>
            {({handleSubmit, isSubmitting}) => (
                <Form className={classNames(style.form, className)} onSubmit={handleSubmit}>
                    <FranchiseFormFields
                        handleAgreementChange={handleAgreementChange}
                        isAgreement={isAgreement}
                        isSubmitting={isSubmitting}
                    />
                </Form>
            )}
        </Formik>
    );
});
