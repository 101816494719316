import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {isUserAuthenticated} from 'core/helpers';
import {TAppRootState} from 'core/redux/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {actionSetUser, userModuleName} from 'modules/user/slice';
import {IUserState} from 'modules/user/types';

const select = (state: TAppRootState): IUserState => state[userModuleName];

export const selectUserData = createDraftSafeSelector([select], (state) => state.userData);

export const selectIsUserDataLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetUser.type)(state);

export const selectIsLoggedIn = () => {
    return isUserAuthenticated();
};
