import classNames from 'classnames';
import {TPropsWithClassName} from 'core/types';
import {OrderCardItemProductItem} from 'modules/orders/components/OrderCardItemProductItem';
import {OrderItem} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    orderProduct: OrderItem;
}

export const OrderXPageProductItem = memo<IProps>(function OrderXPageProductItem({className, orderProduct}) {
    return (
        <div className={classNames(style.root, className)}>
            <OrderCardItemProductItem {...orderProduct} />
        </div>
    );
});
