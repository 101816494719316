import {ShadowBlock} from 'components/ShadowBlock';
import {TAppDispatch} from 'core/redux/types';
import {useCloseByEscape} from 'modules/modals/hooks/useCloseByEscape';
import {hideModal} from 'modules/modals/thunks';
import {IModalActionProp} from 'modules/modals/types';
import {IPortalProps, Portal} from 'modules/portal/components/Portal';
import React, {FC, MouseEvent, useCallback} from 'react';
import {useDispatch} from 'react-redux';

/**
 * Немного мудрости:
 *
 * При проектировании модальных окон, не следует использовать провайдер модального окна,
 * внутри компонента самой модалки!
 *
 * @example
 * export const SomeModal: FC = () => {
 *     const userData = useAppSelector(selectUserData);
 *
 *     return <Modal name={MODAL.SOME_MODAL.name}>
 *         <RegularModal>
 *             <div>User: {userData}</div>
 *         </RegularModal>
 *     </Modal>
 * }
 *
 * так делать не нужно, потому что теряется смысл. Все хуки, весь жизненный цикл компонент `SomeModal` запустится вне зависимости состояния в редаксе.
 *
 * Нужно делать так:
 *
 * @example
 * export const SomeModal: FC = () => {
 *     const userData = useAppSelector(selectUserData);
 *
 *     return <RegularModal>
 *             <div>User: {userData}</div>
 *     </RegularModal>
 * }
 *
 * а выше по дереву:
 * @example
 * <Modal name={MODAL.SOME_MODAL.name}>
 *   <SomeModal />
 * </Modal>
 *
 * Флаги:
 *
 * `willMount === true` -- модалка не будет вмонтирована в дерево, до тех пор пока не будет вызван `showModal`
 * `willMount === false` -- модалка будет вмонтирована в дерево, но будет скрыта стилями, до тех пор пока не будет вызван `showModal`
 *
 * `hasPortal === true` -- модалка будет вмонтирована в `body`
 * `hasPortal === false` -- модалка будет  вмонтирована ровно там, где она описана в `JSX`
 * `propagate` == false -- события `data-modal-action` не будут всплывать выше, значит родительские модалки не закроются
 */

export interface IModalProps extends IPortalProps {
    onSubmit?: () => void;
    propagate?: boolean;
    shouldCloseByEscape?: boolean;
}

export const Modal: FC<IModalProps> = ({
    onSubmit,
    children,
    propagate = false,
    shouldCloseByEscape = false,
    ...restDialogProps
}) => {
    const dispatch = useDispatch<TAppDispatch>();

    useCloseByEscape(shouldCloseByEscape);

    const modalData =
        'development' === process.env.NODE_ENV
            ? {
                  ['data-modal-name']: restDialogProps.name,
              }
            : {};

    const handleCloseClick = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            const clickedTarget = event.target as HTMLElement;
            const clickedNode = clickedTarget.closest('[data-modal-action]');

            if (!clickedNode) {
                return;
            }

            const action = clickedNode.getAttribute('data-modal-action') as IModalActionProp['data-modal-action'];

            switch (action) {
                case 'close': {
                    dispatch(hideModal(restDialogProps.name));
                    restDialogProps?.onClose?.();
                    break;
                }

                case 'submit': {
                    if (onSubmit) {
                        onSubmit();
                    }
                    break;
                }

                default:
                    return;
            }

            if (!propagate) {
                event.stopPropagation();
            }
        },
        [dispatch, onSubmit, propagate, restDialogProps]
    );

    return (
        <ShadowBlock role="presentation" {...modalData} onClick={handleCloseClick}>
            <Portal {...restDialogProps}>{children}</Portal>
        </ShadowBlock>
    );
};
