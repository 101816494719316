import classNames from 'classnames';
import {Checkbox} from 'components/Checkbox';
import {Input} from 'components/Input';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TClosureCallback} from 'core/types';
import {ChooseVariant, FilterChoose, FilterType} from 'new-models';
import {FilterCollapse} from 'plugins/modules/filter/components/FilterCollapse';
import {QuantityVariantFilter} from 'plugins/modules/filter/components/QuantityVariantFilter';
import {ListingContext} from 'plugins/modules/listing/context';
import searchIcon from 'public/icons/search.svg';
import React, {
    ChangeEvent,
    ChangeEventHandler,
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import style from './style.module.scss';

const SHOW_MORE_COUNT_THRESHOLD = 5;

export interface IProps extends FilterChoose {
    onApply: (id: ChooseVariant['code']) => void;
    onDiscard: (id: ChooseVariant['code']) => void;
}

export const VariantFilter: FC<IProps> = ({onApply, onDiscard, variants, type}) => {
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const {isListLoading} = useContext(ListingContext);
    const [filteredVariants, setFilteredVariants] = useState<ChooseVariant[]>([]);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [enabledVariants, setEnabledVariants] = useState<ChooseVariant[]>([]);

    const handleSearchInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(e.target.value);
    }, []);

    const handleSearchInputActionClick = useCallback(() => {
        if (!searchInputRef.current) {
            return;
        }

        searchInputRef.current.focus();
    }, []);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const ulContainer = useRef<HTMLUListElement>(null);

    const isStoreFilter = useMemo<boolean>(() => type === FilterType.Store, [type]);

    const isQuantityFilter = useMemo<boolean>(() => type === FilterType.Quantity, [type]);

    const handleApplyFilter = useCallback<TClosureCallback<ChooseVariant, ChangeEventHandler<HTMLInputElement>>>(
        ({code: currentCode}: ChooseVariant) => {
            return (event) => {
                setFilteredVariants((initialVariants) =>
                    initialVariants.map((variant) => {
                        if (variant.code === currentCode) {
                            return {...variant, isApplied: true};
                        }

                        return variant;
                    })
                );

                if (event.target.checked) {
                    onApply(currentCode);

                    return;
                }
                onDiscard(currentCode);
            };
        },
        [onApply, onDiscard]
    );

    useEffect(() => {
        const enabledList = variants.filter(({isEnabled}) => isEnabled ?? true);

        setEnabledVariants(enabledList);
    }, [variants]);

    useEffect(() => {
        const filteredList = enabledVariants.filter(({title}) => {
            return title.toLowerCase().includes(searchInputValue.trim().toLowerCase());
        });

        setFilteredVariants(filteredList);
    }, [searchInputValue, enabledVariants]);

    const isEnabled = useCallback((filterVariant: ChooseVariant) => filterVariant.isEnabled ?? true, []);

    const setCollapseState = useCallback((isOpen: boolean) => () => setIsCollapseOpen(isOpen), []);

    const renderAction = useCallback<FC<ChooseVariant>>(
        (filterVariant) => {
            const isApplied = filterVariant.isApplied;

            if (isQuantityFilter) {
                return (
                    <QuantityVariantFilter
                        checked={isApplied}
                        disabled={!isEnabled(filterVariant) || isListLoading}
                        onChange={handleApplyFilter(filterVariant)}
                        value={filterVariant.title}
                    />
                );
            }

            return (
                <>
                    <Checkbox
                        checked={isApplied}
                        disabled={!isEnabled(filterVariant) || isListLoading}
                        isLabel={Boolean(filterVariant.title)}
                        label={filterVariant.title}
                        onChange={handleApplyFilter(filterVariant)}
                    />
                    {!isStoreFilter && (
                        <Typography
                            className={classNames(style.variantFilterItemAmount, {
                                [style.variantFilterItemAmountDisabled]: !filterVariant.isEnabled,
                            })}
                            element="div"
                            variant="p"
                        >
                            {filterVariant.count}
                        </Typography>
                    )}
                </>
            );
        },
        [handleApplyFilter, isEnabled, isListLoading, isQuantityFilter, isStoreFilter]
    );

    const {showSearchInput, showMoreButton, itemsToShow} = useMemo(() => {
        return {
            itemsToShow: filteredVariants.length - SHOW_MORE_COUNT_THRESHOLD,
            showMoreButton: SHOW_MORE_COUNT_THRESHOLD < filteredVariants.length,
            showSearchInput: SHOW_MORE_COUNT_THRESHOLD < enabledVariants.length,
        };
    }, [enabledVariants, filteredVariants]);

    return (
        <div className={classNames(style.variantFilterContainer, {[style.variantFilterOpen]: isCollapseOpen})}>
            {showSearchInput && (
                <Input
                    className={style.searchInput}
                    labelText="Поиск"
                    onChange={handleSearchInputChange}
                    onExtraActionClick={handleSearchInputActionClick}
                    ref={searchInputRef}
                    trailIcon={<SvgIcon svg={searchIcon} />}
                    value={searchInputValue}
                />
            )}
            <ul
                className={classNames(style.variantFilterList, {
                    [style.isQuantity]: isQuantityFilter,
                })}
                ref={ulContainer}
            >
                {filteredVariants.slice(0, SHOW_MORE_COUNT_THRESHOLD).map((filterVariant) => {
                    return (
                        <li className={style.variantFilterItem} key={filterVariant.code}>
                            {renderAction(filterVariant)}
                        </li>
                    );
                })}
                {showMoreButton && (
                    <FilterCollapse
                        callbackFn={setCollapseState(true)}
                        count={itemsToShow}
                        onClose={setCollapseState(false)}
                        parentContainer={ulContainer}
                        variants={filteredVariants}
                    >
                        {filteredVariants.slice(SHOW_MORE_COUNT_THRESHOLD).map((filterVariant) => {
                            return (
                                <li className={style.variantFilterItem} key={filterVariant.code}>
                                    {renderAction(filterVariant)}
                                </li>
                            );
                        })}
                    </FilterCollapse>
                )}
            </ul>
        </div>
    );
};
