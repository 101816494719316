import classNames from 'classnames';
import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {TPropsWithChildrenRequire} from 'core/types';
import React, {CSSProperties, FC, useId} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithChildrenRequire {
    isContainer?: boolean;
    displayType?: CSSProperties['display'];
}

export const OnlyDesktop: FC<IProps> = ({children, isContainer = false, displayType = 'block'}) => {
    const uid = useId().replaceAll(/\W/gu, '_');

    return (
        <>
            <style>{`.${uid} {
                    display: none;
                    
                    @media (min-width: ${MIN_DESKTOP_WIDTH}px) {
                        display: ${displayType};
                    }
            }`}</style>
            <div
                className={classNames(
                    style.wrapper,
                    {
                        [style.isContainer]: isContainer,
                    },
                    uid
                )}
            >
                {children}
            </div>
        </>
    );
};
