import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import {CartContext} from 'modules/cart/context';
import {selectCartTotal, selectDeliveryInfo} from 'modules/cart/selectors';
import {getDeliveryInfo} from 'modules/cart/thunks';
import {selectUserLocation} from 'modules/locations/selectors';
import {selectIsLoggedIn} from 'modules/user/selectors';
import {BasketItem, DeliveryInfo, Discount, TotalPrice} from 'new-models';
import {useRouter} from 'next/router';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {CHECKOUT_ROUTE} from 'routing/constants';

export interface IUseCartOrderMakingReturns {
    delivery: DeliveryInfo[];
    totalPrice: TotalPrice;
    discountPrice: number;
    productSum: number;
    productCount: number;
    weightValue?: number;
    volumeValue?: number;
    handleStartCheckout: () => void;
    divideByAvailability: BasketItem[][];
    discounts?: Discount[];
}

export const useCartTotalInfo = (notEnoughElement: HTMLDivElement | null): IUseCartOrderMakingReturns => {
    const dispatch = useAppDispatch();

    const location = useAppSelector(selectUserLocation);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);
    const handleProfileClick = useProfileClick();

    const {countSelected, divideByAvailability} = useContext(CartContext);
    const router = useRouter();

    const {price, measure, discounts} = useAppSelector(selectCartTotal);

    const currentPriceRef = useRef<number>(0);

    const delivery = useAppSelector(selectDeliveryInfo);
    const handleStartCheckout = useCallback(() => {
        if (!isLoggedIn) {
            handleProfileClick();
            return;
        }

        if (divideByAvailability[2].length && notEnoughElement) {
            notEnoughElement.scrollIntoView({behavior: 'smooth'});
            return;
        }

        router.push(CHECKOUT_ROUTE);
    }, [divideByAvailability, handleProfileClick, isLoggedIn, notEnoughElement, router]);

    const discountPrice = price.discount;

    const productSum = price.cost;

    const weightValue = measure?.weight;

    const volumeValue = measure?.volume;

    useEffect(() => {
        if (currentPriceRef.current !== price.cost && location?.id) {
            dispatch(getDeliveryInfo(location.id));
        }

        if (currentPriceRef.current !== price.cost) {
            currentPriceRef.current = price.cost;
        }
    }, [dispatch, location?.id, price.cost, productSum]);

    return useMemo(
        () => ({
            delivery,
            discountPrice,
            discounts,
            divideByAvailability,
            handleStartCheckout,
            productCount: countSelected,
            productSum,
            totalPrice: price,
            volumeValue,
            weightValue,
        }),
        [
            delivery,
            discountPrice,
            discounts,
            divideByAvailability,
            handleStartCheckout,
            countSelected,
            productSum,
            price,
            volumeValue,
            weightValue,
        ]
    );
};
