import {TMountLinkUrlOptions} from 'core/types';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {Category, Link} from 'new-models';
import {
    BRAND_ROUTE,
    CATALOG_ROUTE,
    COLLECTION_ROUTE,
    HOME_ROUTE,
    INFO_ROUTE,
    PRODUCT_ROUTE,
    PROMO_ROUTE,
    STORES_ROUTE,
} from 'routing/constants';

export const mountLinkUrl = (link: Link, options: TMountLinkUrlOptions): Link => {
    if ('url' === link.type || !link.type) {
        return link;
    }

    return {...link, value: options[link.type](link)};
};

export const makeCollectionUrl = (link: Link) => `${COLLECTION_ROUTE}/${link.value}`;
export const makeCategoryUrl = (categoriesList: Category[]) => (link: Link) => {
    const categoryUrl = CategoryService.makeCategoryUrlFoundById(categoriesList, link.value);

    return `${CATALOG_ROUTE}/${categoryUrl}`;
};

export const makeProductUrl = (link: Link) => {
    return `${PRODUCT_ROUTE}/${PRODUCT_ROUTE}-${link.value}`;
};

export const makeBrandUrl = (link: Link) => {
    return `${BRAND_ROUTE}/${link.value}`;
};

export const makeActionUrl = (link: Link) => {
    return `${PROMO_ROUTE}/${link.value}`;
};

export const makeInfoUrl = (link: Link) => {
    return `${INFO_ROUTE}/${link.value}`;
};

export const makeServiceUrl = () => {
    return HOME_ROUTE;
};

export const makeStoreUrl = () => {
    return STORES_ROUTE;
};

export const makeUrl = (link: Link) => {
    return link.value ?? HOME_ROUTE;
};

export const mountLinkUrlOptions = (categoriesList: Category[]): TMountLinkUrlOptions => ({
    action: makeActionUrl,
    article: makeInfoUrl,
    brand: makeBrandUrl,
    category: makeCategoryUrl(categoriesList),
    collection: makeCollectionUrl,
    ['content-page']: makeInfoUrl,
    news: makeInfoUrl,
    product: makeProductUrl,
    service: makeServiceUrl,
    store: makeStoreUrl,
    url: makeUrl,
});
