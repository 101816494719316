import classNames from 'classnames';
import {Tag} from 'components/Tag';
import {ITypographyPublicProps, Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {memo, MouseEventHandler, PropsWithChildren} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, PropsWithChildren, ITypographyPublicProps {
    name?: string;
    isActive?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    description?: string;
}

// TODO: Need Redesign
export const CategoryTag = memo<IProps>(function CategoryTag({
    name,
    description,
    isActive = false,
    onClick,
    variant = 'p',
}) {
    return (
        <Tag
            className={classNames(style.category, {
                [style.alter]: !description,
                [style.active]: isActive,
            })}
            isActive={isActive}
            onClick={onClick}
        >
            <div className={style.container}>
                <Typography className={style.name} variant={variant}>
                    {name}
                </Typography>
                <Typography className={style.description} variant={variant}>
                    {description}
                </Typography>
            </div>
        </Tag>
    );
});
