import classNames from 'classnames';
import {IProps as IInputProps} from 'components/Input';
import {InputCaption} from 'components/InputCaption';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import checkMarkIcon from 'public/icons/check-mark.svg';
import chevronDownIcon from 'public/icons/chevron-down.svg';
import closeIcon from 'public/icons/close.svg';
import {FC, ReactNode, useCallback, useState} from 'react';
import Select, {ActionMeta, components, DropdownIndicatorProps, MultiValueRemoveProps, OptionProps} from 'react-select';

import style from './style.module.scss';

export interface ISelectOption<T> {
    label: string;
    value: T;
    isDisabled?: boolean;
}

type TSelectInputProps<V extends string = string> = Pick<
    IInputProps<V>,
    'captionText' | 'errorText' | 'labelText' | 'placeholder' | 'name' | 'disabled'
>;

export interface ISelectProps<T, M extends string = string> extends TSelectInputProps<M>, TPropsWithClassName {
    options?: ISelectOption<T>[];
    defaultOption?: ISelectOption<T> | ISelectOption<T>[];
    onChange?: (value: ISelectOption<T>) => void;
    onChangeMulti?: (value: ISelectOption<T>[]) => void;
    value?: ISelectOption<T> | ISelectOption<T>[];
    isLoading?: boolean;
    emptyMessage?: string;
    variant?: 'default' | 'filter';
    dropDownIndicatorIcon?: ReactNode;
    isSearchable?: boolean;
    multiply?: boolean;
}

export const InputSelect = <T, M extends string = string>({
    className,
    name,
    onChange,
    value,
    defaultOption,
    options,
    isLoading,
    labelText,
    captionText,
    errorText,
    placeholder,
    disabled,
    emptyMessage,
    variant = 'default',
    onChangeMulti,
    dropDownIndicatorIcon,
    isSearchable = false,
    multiply = false,
}: ISelectProps<T, M>) => {
    const [isFilled, setIsFilled] = useState<boolean>(Array.isArray(defaultOption) && Boolean(defaultOption?.length));
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleSelectChange = useCallback(
        (newValue: ISelectOption<T>) => {
            setIsFilled(Boolean(newValue));

            if (onChangeMulti) {
                onChangeMulti(newValue as unknown as ISelectOption<T>[]);
            }

            if (onChange) {
                onChange(newValue);
            }
        },
        [onChange, onChangeMulti]
    );

    const handleSelectOnFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleSelectOnBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    const renderMultiValueRemoveIcon = useCallback<FC<MultiValueRemoveProps>>((props) => {
        return (
            <components.MultiValueRemove {...props}>
                <div className={style.multiValueRemove}>
                    <SvgIcon svg={closeIcon} />
                </div>
            </components.MultiValueRemove>
        );
    }, []);

    const renderDropDownIndicator = useCallback<FC<DropdownIndicatorProps>>(
        (props) => {
            const {
                selectProps: {menuIsOpen},
                isDisabled,
            } = props;

            return (
                <components.DropdownIndicator {...props}>
                    <button className={style.inputSelectDropDown} disabled={isDisabled} type="button">
                        <div
                            className={classNames(style.inputSelectDropDownIcon, {
                                [style.inputSelectDropDownRotate]: menuIsOpen,
                            })}
                        >
                            {dropDownIndicatorIcon || <SvgIcon className={style.commonIcon} svg={chevronDownIcon} />}
                        </div>
                    </button>
                </components.DropdownIndicator>
            );
        },
        [dropDownIndicatorIcon]
    );

    const renderOption = useCallback<FC<OptionProps>>((props) => {
        const {children, isSelected} = props;

        return (
            <components.Option {...props}>
                {children}
                {isSelected && <SvgIcon svg={checkMarkIcon} />}
            </components.Option>
        );
    }, []);

    const renderNoOptionMessage = useCallback(() => emptyMessage, [emptyMessage]);

    return (
        <div
            className={classNames(style.inputSelect, className, {
                [style.inputSelectValueSelected]: isFilled,
                [style.inputSelectError]: errorText,
                [style.inputSelectDisabled]: disabled,
                [style.inputSelectFocused]: isFocused,
                [style.inputSelectHasLabel]: labelText,
                [style.inputSelectFilterCustom]: 'filter' === variant,
            })}
            data-label={labelText}
        >
            <Select
                className="custom-select"
                classNamePrefix="custom-select"
                components={{
                    DropdownIndicator: renderDropDownIndicator,
                    MultiValueRemove: renderMultiValueRemoveIcon,
                    Option: renderOption,
                }}
                defaultValue={defaultOption}
                hideSelectedOptions={false}
                isClearable={false}
                isDisabled={disabled}
                isLoading={isLoading}
                isMulti={multiply}
                isSearchable={isSearchable}
                name={name}
                noOptionsMessage={renderNoOptionMessage}
                onBlur={handleSelectOnBlur}
                onChange={handleSelectChange as (newValue: unknown, actionMeta: ActionMeta<unknown>) => void}
                onFocus={handleSelectOnFocus}
                options={options}
                placeholder={placeholder ?? ''}
                value={value}
            />
            {(captionText || errorText) && (
                <InputCaption captionText={captionText} disabled={disabled} errorText={errorText} />
            )}
        </div>
    );
};
