import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TResponse} from 'core/types';
import {ICompareResponse} from 'modules/compareProducts/types';
import {CompareCreateDataRequest, CompareDataResponse} from 'new-models';

const ROOT_REQUEST_URL = '/compare';

export class CompareProductsService extends EvolutionHttpClient {
    async getCompareProductsList(): Promise<TResponse<CompareDataResponse>> {
        const {data} = await this.get<TResponse<CompareDataResponse>>({
            url: ROOT_REQUEST_URL,
        });

        return data;
    }

    async addToCompareProductsList({productId}: CompareCreateDataRequest): Promise<ICompareResponse> {
        const {ok} = await this.post<CompareCreateDataRequest, TResponse<null>>({
            body: {
                productId,
            },
            url: ROOT_REQUEST_URL,
        });

        return {ok};
    }

    async removeFromCompareProductsList({productId}: CompareCreateDataRequest): Promise<ICompareResponse> {
        const {ok} = await this.delete<TResponse<null>>({
            url: `${ROOT_REQUEST_URL}/${productId}`,
        });

        return {ok};
    }
}
