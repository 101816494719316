import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {Checkbox} from 'components/Checkbox';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import Link from 'next/link';
import {FC} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    handleAgreementChange: VoidFunction;
    isAgreement: boolean;
}

export const AgreementBlock: FC<IProps> = ({isAgreement, handleAgreementChange, className}) => {
    return (
        <div className={classNames(style.root, className)}>
            <Checkbox checked={isAgreement} isLabel onChange={handleAgreementChange} />
            <Typography variant="p">
                Я даю свое согласие на обработку{' '}
                <Link href="/info/privacy-security" passHref>
                    <Anchor size="large" theme="standalone">
                        Персональных данных
                    </Anchor>
                </Link>
            </Typography>
        </div>
    );
};
