/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface Entity {
  /** Идентификатор сущности */
  id?: number;
  /** Имя сущности */
  title?: string;
}

/** Профиль пользователя */
export interface Profile {
  /** Идентификатор пользователя */
  id: number;
  /** Телефон пользователя */
  phone: string;
  /** Фамилия пользователя */
  lastname: string | null;
  /** Имя пользователя */
  firstname: string | null;
  /** Отчество пользователя */
  patronymic?: string | null;
  /** Пол пользователя */
  gender?: "male" | "female" | null;
  /** Дата рождения (формат: ISO-8601 = DATE_ATOM) */
  birthday?: string | null;
  /** EMail пользователя */
  email?: string | null;
  /** Подтвержден ли email пользователя? */
  isEmailVerified: boolean;
  /** Флаг необходимости установить пароль */
  isNeedPasswordSet?: boolean;
  /** Предпочтительный способ получения чека */
  receipt: "electronic-receipt" | "cash-receipt";
}

/** Данные по навигации */
export interface PageInfo {
  /**
   * Номер текущей страницы
   * @example 1
   */
  pageNomer: number;
  /**
   * Кол-во товаров на странице
   * @example 12
   */
  pageSize: number;
  /**
   * Общее кол-во товаров
   * @example 32
   */
  recordCount: number;
}

/** Запрос для регистрации пользователя */
export interface RegistrationRequest {
  /** Фамилия пользователя */
  lastname?: string;
  /** Имя пользователя */
  firstname?: string;
  /** Телефон пользователя */
  phone?: string;
}

/** Запрос для обновления профиля пользователя */
export interface ProfileRequest {
  /** Фамилия пользователя */
  lastname?: string;
  /** Имя пользователя */
  firstname?: string;
  /** Отчество пользователя */
  patronymic?: string;
  /** Пол пользователя */
  gender?: "male" | "female";
  /**
   * Дата рождения (формат: ISO-8601 = DATE_ATOM)
   * @format date
   */
  birthday?: string;
  /** EMail пользователя */
  email?: string;
  /** Телефон пользователя */
  phone?: string;
  /** Предпочтительный способ получения чека */
  receipt?: "electronic-receipt" | "cash-receipt";
  /** Пароль пользователя */
  password?: string;
}

/** Краткая информации по организации */
export interface LegalOrganizationShort {
  /**
   * ИНН организации
   * @example "7702596813"
   */
  inn: string;
  /**
   * Краткое наименование организации
   * @example "ООО "БАУЦЕНТР РУС""
   */
  shortName: string;
}

/** Подробная информация об организации */
export type LegalOrganization = LegalOrganizationShort & {
  /**
   * Полное наименование организации
   * @example "ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "БАУЦЕНТР РУС""
   */
  name: string;
  /**
   * Адрес юридического лица
   * @example "236029, Калининградская область, г.Калининград, ул.А.Невского, д.205"
   */
  legalAddress: string;
  /**
   * Основной государственный регистрационный номер
   * @example "1067746357524"
   */
  ogrn: string;
  /**
   * Код причины постановки на учет
   * @example "390601001"
   */
  kpp: string;
  /**
   * Директор организации
   * @example "Курандин Александр Юрьевич"
   */
  ceo: string;
  /**
   * Список договоров
   * @minItems 0
   */
  contracts?: OrganizationContract[];
};

/** Держатель карты лояльности */
export interface LoyaltyCardHolderBase {
  /**
   * Фамилия
   * @example "Иванов"
   */
  lastname?: string;
  /**
   * Имя
   * @example "Иван"
   */
  firstname?: string;
  /**
   * Отчество
   * @example "Иванович"
   */
  patronymic?: string;
  /**
   * Дата рождения (ISO 8601)
   * @format date
   * @example "1975-10-05"
   */
  birthdate?: string;
}

/** Запрос для создания карты лояльности типа Бонус */
export type CreateLoyaltyCardBonusRequest = UtilRequiredKeys<
  LoyaltyCardHolderBase,
  "lastname" | "firstname" | "birthdate"
>;

/** Запрос для создания карты лояльности типа Профи для ФЛ */
export type CreateLoyaltyCardProPhysicalRequest = UtilRequiredKeys<
  LoyaltyCardHolderBase,
  "lastname" | "firstname" | "patronymic"
> & {
  /**
   * Адрес электронной почты
   * @example "some@email.com"
   */
  email: string;
  /**
   * Идентификатор ТЦ из которого пользователь заберет карту
   * @example 1100358585
   */
  storeId: number;
};

/** Запрос для создания карты лояльности типа Профи для ЮЛ */
export type CreateLoyaltyCardProLegalRequest = UtilRequiredKeys<LoyaltyCardHolderBase, "lastname" | "firstname"> & {
  /**
   * Адрес электронной почты
   * @example "some@email.com"
   */
  email: string;
  /**
   * Идентификатор ТЦ из которого пользователь заберет карту
   * @example 1100358585
   */
  storeId: number;
  /**
   * ИНН организации
   * @example "7702596813"
   */
  inn: string;
  /**
   * Краткое наименование организации
   * @example "ООО "БАУЦЕНТР РУС""
   */
  legalName: string;
};

/** Держатель карты лояльности */
export type LoyaltyCardHolder = UtilRequiredKeys<LoyaltyCardHolderBase, "lastname" | "firstname"> & {
  /**
   * Пол
   * @example "male"
   */
  gender?: "male" | "female";
  /**
   * Телефон
   * @example "79001234567"
   */
  phone: string;
  /**
   * Адрес электронной почты
   * @example "some@email.com"
   */
  email?: string;
};

/** Держатель карты лояльности Профи */
export type LoyaltyCardHolderPro = UtilRequiredKeys<LoyaltyCardHolderBase, "lastname" | "firstname"> & {
  /**
   * Пол
   * @example "male"
   */
  gender?: "male" | "female";
  /**
   * Телефон
   * @example "79001234567"
   */
  phone: string;
  /**
   * Адрес электронной почты
   * @example "some@email.com"
   */
  email?: string;
  /**
   * Адрес регистрации
   * @example "г.Москва, ул.Ленина, д.35, кв.15"
   */
  address: string;
};

/** Тип карты */
export enum LoyaltyCardType {
  Bonus = "bonus",
  Profi = "profi",
  Worker = "worker",
  Ooso = "ooso",
  Elite = "elite",
}

/** Карта лояльности */
export interface LoyaltyCardBase {
  /**
   * Номер
   * @example "89784456411"
   */
  number: string;
  /** Тип карты */
  type: LoyaltyCardType;
}

/** Карта лояльности */
export interface LoyaltyCard {
  /**
   * Номер
   * @example "79784456411"
   */
  number: string;
  /** Тип карты */
  type: LoyaltyCardType;
  /**
   * Признак видимости карты пользователем
   * @default true
   * @example true
   */
  isActive: boolean;
  /**
   * Текущая выбранная карта в профиле
   * @example true
   */
  isSelected: boolean;
}

/** Карта лояльности типа "Бонус" с запретом списания бонусов */
export interface LoyaltyCardBonusFree {
  /**
   * Номер
   * @example "3942097022"
   */
  number: string;
  type: LoyaltyCardType;
  /**
   * Количество начисленных бонусов (значение умноженное на 100)
   * @example 12550
   */
  points: number;
  /**
   * Сгорающие бонусы/баллы
   * @minItems 0
   */
  burning?: BurningBonus[];
}

/** Карта лояльности типа "Бонус" */
export type LoyaltyCardBonus = LoyaltyCard & {
  /** @example "bonus" */
  type?: any;
  /**
   * Количество начисленных бонусов (значение умноженное на 100)
   * @example 12550
   */
  points: number;
  /**
   * Сгорающие бонусы/баллы
   * @minItems 0
   */
  burning: BurningBonus[];
  /**
   * Необходимость в объединении с другими картами
   * @example false
   */
  needToMerge: boolean;
  /** Держатель карты лояльности */
  holder: LoyaltyCardHolder;
};

/** Карта лояльности типа "ПРОФИ" */
export type LoyaltyCardPro = LoyaltyCard & {
  /** @example "profi" */
  type?: any;
  /** @example "physical" */
  personType?: "physical" | "legal";
  /** Данные организации */
  organization?: LoyaltyCardOrganization & LoyaltyCardBankOfOrganization;
  /**
   * Количество начисленных баллов (значение умноженное на 100)
   * @example 7530
   */
  points: number;
  /**
   * Сгорающие бонусы/баллы
   * @minItems 0
   */
  burning: BurningBonus[];
  /**
   * Скидка по карте
   * @example 10
   */
  discountPercent: number;
  /** Держатель карты лояльности Профи */
  holder: LoyaltyCardHolderPro;
};

/** Карта лояльности типа "Сотрудник" */
export type LoyaltyCardWorker = LoyaltyCard & {
  /** @example "worker" */
  type?: any;
  /**
   * Скидка по карте
   * @example 10
   */
  discountPercent: number;
  /** Держатель карты лояльности */
  holder: LoyaltyCardHolder;
};

/** Договор организации */
export interface OrganizationContract {
  /** Идентификатор договора */
  id: string;
  /**
   * Номер договора
   * @example "КЛД3"
   */
  number: string | null;
  /**
   * Дата заключения договора (краткий формат ISO-8601: YYYY-MM-DD)
   * @format date
   */
  date: string;
  /**
   * Дата начала действия договора
   * @format date
   * @example "2020-05-29"
   */
  startDate: string;
  /**
   * Дата окончания действия договора
   * @format date
   * @example "2020-05-29"
   */
  endDate: string | null;
  /**
   * Тип договора
   * @example "Продажа (под заказ)"
   */
  type: string;
  /**
   * Флаг блокировки договора
   * @example false
   */
  isBlocked: boolean;
  /**
   * Доступный остаток в дробных единицах валюты без применения десятичного разделителя (например, копейки) устарело, использовать поле balance
   * @deprecated
   * @example 1000
   */
  saldo?: number | null;
  /**
   * Доступный остаток в дробных единицах валюты без применения десятичного разделителя (например, копейки)
   * @example 1000
   */
  balance: number | null;
  /** Организация, с которой заключен договор (может быть заключен с филиалами) */
  branch: {
    /**
     * Код причины постановки на учет
     * @example "390601001"
     */
    kpp: string;
    /**
     * Краткое наименование организации
     * @example "ООО "БАУЦЕНТР РУС""
     */
    shortName: string;
  };
  /**
   * Дни отсрочки
   * @example 30
   */
  delayDays?: number | null;
  /**
   * Лимит по договору в дробных единицах валюты без применения десятичного разделителя (например, копейки)
   * @example 1000
   */
  limit?: number | null;
  /**
   * Валюта договора (RUB)
   * @example "RUB"
   */
  currency?: "RUB" | null;
  /**
   * Уведомление к договору
   * @example "У вас просрочка по платежам на сумму 40 000.00 ₽"
   */
  notice?: string | null;
}

/** Доверенность организации */
export interface OrganizationPowerOfAttorney {
  /**
   * Номер договора
   * @example "КЛД3"
   */
  number: string;
  /**
   * Дата начала действия доверенности
   * @format date
   * @example "2020-05-29"
   */
  startDate: string;
  /**
   * Дата окончания действия доверенности
   * @format date
   * @example "2020-05-29"
   */
  endDate: string;
  /**
   * Кому выдана доверенность(ФИО)
   * @example "Михайлов М.М."
   */
  owner: string;
}

/** Данные организации */
export interface LoyaltyCardOrganization {
  /**
   * Юридическое наименование организации
   * @example "ООО 'Умный уют'"
   */
  name: string;
  /**
   * Сокращенное наименование организации
   * @example "ООО 'Умный уют'"
   */
  shortName: string;
  /**
   * Юридический адрес
   * @example "Калининградская область, г.Калининград, ул.Ленина, д.125"
   */
  legalAddress: string;
  /**
   * Фактический адрес
   * @example "Калининградская область, г.Калининград, ул.Ленина, д.125"
   */
  actualAddress: string;
  /**
   * Адрес электронной почты
   * @example "email@someorg.com"
   */
  email: string;
  /**
   * ИНН
   * @example "7731347089"
   */
  inn: string;
  /**
   * ОГРН
   * @example "1177756126040"
   */
  ogrn: string;
  /**
   * КПП
   * @example "773101001"
   */
  kpp?: string;
}

/** Данные по банку */
export interface LoyaltyCardBankOfOrganization {
  /**
   * ИНН
   * @example "7731347089"
   */
  inn: string;
  /**
   * КПП
   * @example "773101001"
   */
  kpp: string;
  /**
   * ОГРН
   * @example "1177756126040"
   */
  ogrn: string;
  /**
   * Расчетный счет
   * @example "40602810206000050025"
   */
  rs: string;
  /**
   * БИК
   * @example "044525974"
   */
  bik: string;
  /**
   * Наименование банка
   * @example "СБЕРБАНК"
   */
  bankName: string;
}

/** Операция по карте */
export interface LoyaltyCardOperation {
  /**
   * Дата совершения операции (формат: ISO-8601 = DATE_ATOM)
   * @format date-time
   * @example "2021-05-07T00:00:00+03:00"
   */
  operationDate: string;
  /**
   * Номер документа
   * @example "БнПк 9-00004444"
   */
  docNumber?: string;
  /**
   * Сумма (значение умноженное на 100)
   * @example 1000
   */
  docSum: number;
  /**
   * Алгоритм
   * @deprecated
   * @example "Административный расчет"
   */
  algorithm?: string;
  /**
   * Наименование операции
   * @example "Покупка в магазине"
   */
  operationName: string;
  /**
   * Начисленные бонусы (значение умноженное на 100)
   * @example 5000
   */
  accruedBonuses: number;
  /**
   * Списанные бонусы (значение умноженное на 100)
   * @example 1000
   */
  writtenOffBonuses: number;
}

export interface SocialConnect {
  /**
   * Код провайдера авторизации
   * @example "VKontakte"
   */
  provider?: string;
  /**
   * Наименование провайдера авторизации
   * @example "ВКонтакте"
   */
  title?: string;
  /**
   * Идентификатор клиента в социальной сети
   * @example "8213369"
   */
  clientId?: string;
  /**
   * URL авторизации в системе
   * @example "https://oauth.vk.com/authorize?client_id=8213369&redirect_uri=http%3A%2F%2F15.dev.baucenter.ru%2Fbitrix%2Ftools%2Foauth%2Fvkontakte.php&scope=friends,offline,email&response_type=code&state=site_id%3Ds1%26backurl%3D%252Fapi%252Fv2%252Fsocial-connects%253Fcheck_key%253Dce7c79d8344654dc3d9fc275bf92a78e%2526routePath%253D%25252Fsocial-connects"
   */
  authUrl?: string;
  /**
   * URL авторизации в системе провайдера
   * @example "https//oauth.vk.com/authorize"
   */
  providerUrl?: string;
  /** Список запрашиваемых данных */
  scope?: string[];
  /**
   * Обратный URL на который возвращается пользователь
   * @example "https://baucenter.ru/bitrix/tools/oauth/vkontakte.php"
   */
  redirectUri?: string;
  /** Признак подписки соц сети */
  active?: boolean;
}

/** Данные для операций с социальной сетью */
export interface SocialConnectRequest {
  /**
   * Код провайдера авторизации
   * @example "VKontakte"
   */
  provider: string;
}

export interface Category {
  /**
   * Идентификатор категории
   * @example 29180
   */
  id: number;
  /**
   * Тип категории товаров
   * @default "simple"
   * @example "simple"
   */
  type: "simple" | "collection" | "technical" | "visual" | "mixed";
  /** Код категории товаров на транслите (например: shurupoverty, stroymaterialy) */
  code: string;
  /**
   * Наименование категории товаров
   * @example "Штукатурки"
   */
  title: string;
  /** Идентификатор узла в иерархии категорий */
  nodeId: number;
  /** Идентификатор родительского узла в в иерархии категорий */
  parentNodeId: number | null;
  media: (Image & object) | null;
  /** Количество товаров в категории */
  amount: number;
}

export interface BaseFilter {
  /**
   * Идентификатор фильтра = идентификатор свойства товаров
   * @example 5279
   */
  id: number;
  /** Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат) */
  type: FilterType;
  /**
   * Наименование фильтра
   * @example "Тип"
   */
  title: string;
  /**
   * Подсказка к фильтру
   * @example "Способность плитки впитывать и удерживать воду в порах"
   */
  hint?: string | null;
}

/** Диапазон дат */
export interface DateRange {
  /**
   * Дата начала периода
   * @format date
   * @example "2022-01-01"
   */
  from?: string;
  /**
   * Дата окончания периода
   * @format date
   * @example "2022-01-31"
   */
  to?: string;
}

export type DateRangeRequired = UtilRequiredKeys<DateRange, "from" | "to">;

export type FilterDateRange = BaseFilter & {
  /** Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат) */
  type?: FilterType;
  /** Диапазон дат */
  dateRange: DateRange;
};

export type FilterOrderChoose = UtilRequiredKeys<BaseFilter, "type"> & {
  /** Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат) */
  type: FilterType;
  /** @example "Карты лояльности" */
  title?: string;
  /**
   * Флаг, символизирующий допустимость множественного выбора
   * @example true
   */
  isMultiple: boolean;
  variants: ChooseOrderVariant[];
};

export interface ChooseOrderVariant {
  /**
   * Идентификатор варианта
   * @example "cardNumber_1111111111"
   */
  id: string;
  /**
   * Наименование варианта выбора
   * @example "1111111111"
   */
  title: string;
  /** Вариант фильтра выбран */
  checked: boolean;
  image?: Image;
}

export type FilterChoose = UtilRequiredKeys<BaseFilter, "type"> & {
  /** Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат) */
  type: FilterType;
  /**
   * Флаг, символизирующий допустимость множественного выбора
   * @example true
   */
  isMultiple: boolean;
  variants: ChooseVariant[];
};

export interface ChooseVariant {
  /**
   * Идентификатор варианта
   * @example "arrFilter_5279_2411440629"
   */
  id: string;
  /**
   * Наименование варианта выбора
   * @example "Гипсовая штукатурка"
   */
  title: string;
  /** Вариант фильтра не активен */
  disabled: boolean;
  /** Вариант фильтра выбран */
  checked: boolean;
  /**
   * Количество элементов, соответствующих значению
   * @example 12
   */
  amount: number;
}

export type FlagVariant = Entity & {
  /**
   * Идентификатор варианта
   * @example "arrFilter_5279_2411440629"
   */
  id?: string;
  /**
   * Наименование варианта выбора
   * @example "Гипсовая штукатурка"
   */
  title?: string;
  /** Вариант фильтра не активен */
  disabled?: boolean;
  /** Вариант фильтра выбран */
  checked?: boolean;
  /**
   * Количество элементов, соответствующих значению
   * @example 12
   */
  amount?: number;
};

export type FilterFlag = BaseFilter & {
  /** Состояние переключателя по умолчанию */
  defaultState: boolean;
  variants: FlagVariant[];
};

export type FilterRange = BaseFilter & {
  /**
   * Ключ для фильтра, т.е.: filter[minId]=1000
   * @example "arrFilter_7339_MIN"
   */
  minId: string;
  /**
   * Ключ для фильтра, т.е.: filter[maxId]=2000
   * @example "arrFilter_7339_MAX"
   */
  maxId: string;
  /**
   * Минимальное допустимое значение
   * @example 170
   */
  minRange: number;
  /**
   * Максимальное допустимое значение
   * @example 170
   */
  maxRange: number;
  /**
   * Минимальное значение, указанное пользователем
   * @example 200
   */
  minValue: number;
  /**
   * Максимальное значение, указанное пользователем
   * @example 500
   */
  maxValue: number;
  /**
   * Шаг изменения
   * @example 1
   */
  step: number;
  /**
   * Единица измерения
   * @example "RUB"
   */
  unit?: string;
};

/** Быстрая фильтрация */
export interface FastFilter {
  /**
   * Идентификатор быстрого фильтра
   * @example 123
   */
  id: number;
  /**
   * Наименование быстрого фильтра
   * @example "Аккумуляторы Bosh"
   */
  title: string;
  /**
   * Часть URL, добавляемого в адресную строку
   * @example "akkumulyatory-bosch"
   */
  code: string;
  /**
   * Количество товаров, соответствующих параметрам фильтрации
   * @example 10
   */
  amount: number;
  /** Параметры фильтрации */
  filters: FastFilterItem[];
}

/** Элемент быстрой фильтрации */
export interface FastFilterItem {
  /**
   * Идентификатор выбранного варианта фильтрации
   * @example "arrFilter_5188_2327587119"
   */
  id: string;
  /**
   * Значение выбранного варианта фильтрации (не типизировано: true | 200)
   * @example "true"
   */
  value: string;
}

export interface Sorting {
  /**
   * Признак по которому осуществляется сортировка
   * @example "pop-desc"
   */
  param: "pop-desc" | "name-asc" | "price-asc" | "price-desc" | "raiting-desc";
  /**
   * Наименование сортировки
   * @example "По популярности"
   */
  title: string;
  /**
   * Комментарий к способу сортировки
   * @example "Сначала чаще покупаемые"
   */
  comment: string;
  /** В текущей выборке использовался именно этот признак сортировки? */
  isApplied: boolean;
}

export interface BasketDataResponse {
  /** Общая стоимость товаров в корзине, включая доставку */
  total: number;
  /** Валюта общей стоимости */
  currency: "RUB";
  /**
   * Тип плательщика:
   *   * `physical` - Физическое лицо
   *   * `legal` - Юридическое лицо или ИП
   */
  personType: "physical" | "legal";
  products: (ProductOfferShort & {
    productParams: (ProductParams & object) | null;
    /** ЕГАИС */
    egais: boolean;
    /** Честный знак */
    honestSign: boolean;
  })[];
  /** Минимальная стоимость заказа */
  minOrderSum?: number | null;
}

export interface BasketPatchRequest {
  /** Идентификатор товарного предложения */
  productOfferId: number;
  /** Требуемое количество товаров в списке покупок (корзине). Если не указано, то ноль */
  amount?: number;
}

export interface BasketEraseRequest {
  operation: "erase";
}

export interface ProductOfferShort {
  /**
   * Идентификатор товарной позиции
   * @example 577087
   */
  id: number;
  /**
   * Наименование товарной позиции
   * @example "Цементная штукатурка легкая с перлитом Ceresit CT 24 Light 20 кг"
   */
  title: string;
  /**
   * Артикул товарного предложения
   * @example "511001209"
   */
  article: string;
  /**
   * Средний рейтинг (оценка) товарного предложения
   * @example 4.7
   */
  rating: number;
  /** Список изображений товарного предложения */
  images: Image[];
  price: ProductOfferPrice;
  /** @deprecated */
  availabilities?: ProductOfferAvailability[];
  /** Доступность товара на ближайшую дату по типам (магазины, самовывоз, доставка) */
  availability: ProductOfferAvailabilityByStoreTypes;
  /**
   * Тип доступности товара:
   *   * `online` - доступен к покупке онлайн
   *   * `offline-wish` - доступен к покупке только офлайн, но может быть добавлен в список желаний онлайн
   *   * `offline` - доступен только офлайн, не доступен онлайн
   */
  availableType: AvailableType;
  /** Идентификатор раздела (категории) */
  sectionId: SectionId;
  /**
   * Кол-во товара в корзине
   * @format float
   * @example 1.49
   */
  inBasket: number;
  /**
   * Кол-во товара в списке покупок
   * @deprecated
   * @format float
   * @example 3
   */
  inWishList?: number;
  /** @minItems 0 */
  quantityInWishLists: ProductOfferQuantityInWishList[];
  /**
   * Кратность добавления товара в корзину
   * @default 1
   */
  multiple: number;
  /** Признак товара 24 */
  is24: boolean;
  /** Флаг наличия созданного уведомления о поступлении */
  isNoticeReceiptExist: boolean;
  /** Шильдики товара */
  nameplates: Nameplate[];
  /**
   * Наименование товарной позиции транслитом
   * @example "nasosnaya-stantsiya-grundfos-jp4-47pt-h-850-vt-4700-l-chas-napor-47-m"
   */
  code: string;
}

export type ProductOffer = ProductOfferShort & {
  /** Описание товарной позиции */
  description?: string;
};

export type ProductOfferDetail = UtilRequiredKeys<
  ProductOfferShort,
  "id" | "title" | "article" | "rating" | "price" | "sectionId" | "inBasket" | "inWishList"
> & {
  /**
   * Описание товара
   * @example "Легкая штукатурная смесь Ceresit CT 24 Light..."
   */
  description: string;
  /**
   * Тип товара:
   *   * `simple` - Обычные товары
   *   * `technical` - Технические товары
   *   * `collection` - Коллекции товаров
   *   * `visual` - Визуальные товары
   *   * `offline-wish` - Оффлайн отображается в каталоге
   * @example "simple"
   */
  type: "simple" | "technical" | "collection" | "visual" | "offline-wish";
  /** Способы получения */
  productReceiving: string[];
  video: Youtube[];
  productParams: object | null;
  /** Характеристики товара */
  properties: ProductProperty[];
  /** Документы */
  files: File[];
  /**
   * Тип калькулятора:
   *   * `laminate` - Калькулятор для расчета ламината
   *   * `dry-mixes` - Калькулятор для расчета сухих строительных смесей
   *   * `grout` - Калькулятор для расчета затирки
   *   * `drywall` - Калькулятор для расчета гипсокартона
   *   * `wallpaper` - Калькулятор для расчета обоев
   *   * `interior-paint` - Калькулятор для расчета краски для внутренних работ
   *   * `outdoor-paint` - Калькулятор для расчета краски для наружных работ
   *   * `tile` - Калькулятор для расчета плитки
   *   * `glass-wool` - Калькулятор для расчета стекловаты
   *   * `radiator` - Калькулятор для расчета радиаторов
   */
  calcType: CalculatorType;
  /**
   * Ближайшая дата доставки
   * @deprecated
   * @example "2023-01-01T13:00:00.000Z"
   */
  delivery?: string | null;
  /** @deprecated */
  pickups?: Pickups | null;
  /** Список изображений визуального товара */
  visualImages?: Image[] | null;
};

/** Данные по габаритам */
export interface ProductParams {
  /** Габариты */
  dimensions?: {
    /**
     * Значение ШxВxД
     * @example "1310x205x69"
     */
    value: string;
    /**
     * Единица измерения
     * @example "мм"
     */
    unit: string;
  };
  /** Объем */
  volume?: {
    /**
     * Значение
     * @format float
     * @example 0.02
     */
    value: number;
    /**
     * Единица измерения
     * @example "м3"
     */
    unit: string;
  };
  /** Объем */
  weight?: {
    /**
     * Значение
     * @format float
     * @example 14.79
     */
    value: number;
    /**
     * Единица измерения
     * @example "кг"
     */
    unit: string;
  };
}

/** Элемент хлебных крошек */
export interface BreadCrumb {
  /**
   * Id раздела
   * @example 29394
   */
  id: number;
  /**
   * Наименование раздела
   * @example "Сантехника"
   */
  title: string;
  /**
   * Код раздела
   * @example "santekhnika"
   */
  code: string;
  /**
   * Код раздела в цепочке навигации
   * @example "santekhnika-ctg-29394"
   */
  modifyCode: string;
}

export interface ProductOfferByProperty {
  /**
   * Наименование характеристики
   * @example "Диаметр (мм)"
   */
  title: string;
  variants: {
    /**
     * Значение характеристики
     * @example "3.5"
     */
    value: string;
    /**
     * Артикул товара
     * @example "511000304"
     */
    article: string;
    /**
     * Идентификатор товара (null, если товара с соответствующим значением характеристики отсутствует)
     * @example 1283475
     */
    productOfferId: number | null;
    /**
     * Код предложения
     * @example "kover-sheggi-ultra-0-8kh1-5-m-kremovyy"
     */
    code: string;
    /** Идентификатор раздела (категории) */
    sectionId: SectionId;
    /**
     * Относится к текущему товару?
     * @default true
     */
    isCurrent: boolean;
    image: Image | null;
  }[];
}

export interface ProductOfferProductSet {
  /**
   * Наименование группы комплектации
   * @example "Короб"
   */
  title: string;
  /** Товары комплектации */
  variants: {
    /**
     * Идентификатор товара
     * @example 720513
     */
    productOfferId: number;
    /**
     * Код предложения
     * @example "dver-mezhkomnatnaya-glukhaya-2000kh800-mm-tekhno-701-belosnezhnaya"
     */
    code: string;
    /** Идентификатор раздела (категории) */
    sectionId: SectionId;
    /**
     * Наименование
     * @example "С коробом"
     */
    title: string;
    /**
     * Описание элемента комплектации
     * @example "Стойка коробки телескопическая с уплотнителем для дверей PSK 2070х74х28 мм Ривьера айс"
     */
    description?: string | null;
    /**
     * Выбран ли текущий товар по умолчанию
     * @example true
     */
    isSelected: boolean;
    /**
     * Допустимо ли исключение элемента их комплектации
     * @example true
     */
    canChange: boolean;
    /**
     * Кол-во товаров (1 дверь = 3 добора, 2 двери = 5 доборов, 3=8, 4=10)
     * @example 3
     */
    quantity: number;
    /** Цена товара и единица измерения товара */
    price: Price;
    /**
     * Кол-во товара в корзине
     * @format float
     * @example 1.49
     */
    inBasket: number;
    /**
     * Кол-во товара в списке покупок
     * @deprecated
     * @format float
     * @example 3
     */
    inWishList?: number;
    /** @minItems 0 */
    quantityInWishLists: ProductOfferQuantityInWishList[];
  }[];
}

export interface Advice {
  /**
   * Id совета
   * @example 64222
   */
  id: number;
  /**
   * Название совета
   * @example "Как выбрать ковёр"
   */
  name: string;
  /**
   * Описание совета
   * @example "Выбор качественных материалов не самая простая задача..."
   */
  description?: string;
  /**
   * URL статьи
   * @example "/advices/64222/"
   */
  url: string;
  image?: Image;
  /** @example "Напольные покрытия" */
  tag?: string;
  /** @example 180 */
  timeToReadInSeconds?: number;
}

export interface ProductOfferStoreAvailability {
  /**
   * Идентификатор магазина
   * @example 323
   */
  id: number;
  /**
   * Наименование магазина
   * @example "ул. Дзержинского, 35"
   */
  title: string;
  /**
   * Адрес магазина
   * @example "Московская область, г. Пушкино, 33 км Ярославского шоссе (17 км от МКАД) – ТРК «Акварель»"
   */
  address: string;
  /**
   * Тип магазина
   *   * `store` - магазин
   *   * `warehouse` - склад
   */
  storeType: StoreType;
}

export interface ProductOfferAvailability {
  store: ProductOfferStoreAvailability;
  /**
   * Планируемая дата поставки
   * @format date
   * @example "2022-01-01"
   */
  planDate: string;
  /**
   * Ближайшая дата поставки как строка
   * @example "Послезавтра"
   */
  planDateText: string;
  /**
   * Доступное количество
   * @example 116.176
   */
  amount?: number | null;
  /** Ед. измерения товара */
  unit: Unit;
  /**
   * Текст с информацией по доступности (Доступное кол-во + ед.измерения, Отсутствует, Не продается)
   * @example "45.63 кв. м"
   */
  availText: string;
  /** Дополнительное описание (Завтра: много, Завтра: 20шт и т.д.) */
  extra: string | null;
  placements: string[];
  /**
   * Подсказка к строке наличия товара
   * @example "Этот товар Вы можете купить только в магазине"
   */
  hint?: string | null;
}

export interface ProductOfferPickup {
  /** @example 673358 */
  id: number;
  /** @example "Московский" */
  title: string;
  /**
   * Ближайшая дата самовывоза
   * @format date
   * @example "2023-01-01"
   */
  date?: string;
  /**
   * Ближайшая дата самовывоза как строка
   * @example "Послезавтра"
   */
  dateText?: string;
  /**
   * Адрес пункта выдачи
   * @example "Московский пр., 1-й съезд, д.1"
   */
  address: string;
  /**
   * Географическая широта пункта выдачи
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.707296566598"
   */
  latitude: string;
  /**
   * Географическая долгота пункта выдачи
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.602595000527"
   */
  longitude: string;
  /** Часы работы */
  workingHours: WorkingHours;
}

export interface ProductOfferBarcodeAvailability {
  store: ProductOfferStoreAvailability;
  /**
   * Доступное количество
   * @example 116.176
   */
  amount: number;
  /** Ед. измерения товара */
  unit: Unit;
  /**
   * Текст с информацией по доступности (Доступное кол-во + ед.измерения, Отсутствует, Не продается)
   * @example "45.63 кв. м"
   */
  availText?: string;
  /** Дополнительное описание (Завтра: много, Завтра: 20шт и т.д.) */
  extra?: string | null;
  placements?: string[];
}

export type SolutionShort = Entity & {
  /** Идентификатор готового решения */
  id?: number;
  /** Наименование готового решения */
  title?: string;
  /** Описание готового решения */
  description?: string;
  masterImage?: Media;
};

export type Solution = SolutionShort & {
  /** Дополнительные изображения */
  images?: Media[];
  /** Список товаров, входящих в готовое решение */
  products?: ProductOffer[];
  /** Ссылка на спецификацию готового решения */
  specification?: string;
};

export type CollectionShort = UtilRequiredKeys<Entity, "id" | "title"> & {
  /** Идентификатор коллекции товаров */
  id: number;
  /** Наименование коллекции товаров */
  title: string;
  /**
   * Код коллекции на транслите
   * @example "elementy_dlya_pvkh_okon_i_dverey"
   */
  code: string;
  /** Описание коллекции товаров */
  description?: string;
  masterImage?: Image;
  /** Количество товаров в коллекции */
  quantity: number;
  priceFrom?: PriceDiscountBase;
};

export interface CollectionDetail {
  /** Идентификатор коллекции товаров */
  id: number;
  /** Наименование коллекции товаров */
  title: string;
  /**
   * Код коллекции на транслите
   * @example "elementy_dlya_pvkh_okon_i_dverey"
   */
  code: string;
  /** Описание коллекции товаров */
  description?: string;
  /** Список изображений коллекции */
  images?: Image[];
  /** Список файлов коллекции */
  attachments?: File[];
}

export type Collection = CollectionShort & {
  /** Список товаров, относящихся к коллекции товаров */
  products?: ProductOffer[];
};

/**
 * Размер начисляемых бонусов
 * @example 30000
 */
export type Bonus = number;

/**
 * Ед. измерения товара
 * @example "кв. м"
 */
export type Unit = string;

/** Цена товара */
export interface BasePrice {
  /**
   * Цена
   * @example 119900
   */
  price: number;
  /**
   * Валюта стоимости (RUB)
   * @example "RUB"
   */
  currency: "RUB";
}

/** Цена товара и единица измерения товара */
export type Price = BasePrice & {
  /** Ед. измерения товара */
  unit?: Unit;
};

/** Цена товара со скидкой и базовая цена */
export type PriceDiscountBase = Price & {
  /**
   * Цена без учета скидки, может отсутствовать. Если указана - показываем её как перечеркнутую
   * @example 127000
   */
  basePrice?: number | null;
};

export interface ProductOfferPrice {
  /** Основная цена товара */
  main: PriceDiscountBase;
  alternative?: (PriceDiscountBase & object) | null;
  /**
   * Список дополнительных цен товара
   * @minItems 0
   */
  additional: Price[];
  /** Учетная (балансовая) цена товара */
  accounting: PriceDiscountBase;
  bonus: Bonus;
  /** Размер начисляемых срочных бонусов */
  extraBonus?: Bonus;
}

/** Получатель заказа */
export interface OrderRecipient {
  /** ФИО получателя */
  name: string;
  /** Номер телефона получателя */
  phone: string;
  /** Email адрес получателя */
  email?: string;
  /** Комментарий */
  comment?: string;
}

export interface CreateOrderRequest {
  /** Информация о ЮЛ. Отправлять в случае заказа на ЮЛ, данные о котором отсутствуют. */
  legal?: {
    /** ИНН организации */
    inn: string;
    /** Полное наименование организации */
    name: string;
    /** Юридический адрес организации */
    legalAddress: string;
    /** КПП */
    kpp?: string;
    /** Руководитель организации */
    ceo: string;
  };
  /** Идентификатор договора */
  legalContractId?: string;
  /** Получатель заказа */
  recipient?: OrderRecipient;
  /** Выбранный временной слот для способа получения "Доставка" */
  deliveryTime?: {
    /**
     * Выбранная дата доставки
     * @format date
     */
    date: string;
    /**
     * Выбранный слот доставки
     * @example "08 - 16 ч."
     */
    time: string;
  };
  /** Выбранный список дополнительных услуг */
  deliveryServices?: OrderDeliveryServices[];
  /** Идентификатор выбранного способа оплаты */
  paymentMethodId: number;
  /** Размер списываемых бонусов */
  writeOffBonuses?: WriteOffBonuses;
  paymentChangeAmount?: PaymentChangeAmount | null;
}

export interface PreOrderBaseRequest {
  /** Способ получения заказа */
  wayToReceive: "pickup" | "delivery" | "pickup-point";
  /** Идентификатор выбранного договора ЮЛ (следует указать при заказе на ЮЛ) */
  contractId?: string;
  /** Флаг о намерениях использования бонусов/баллов */
  wantUseBonuses?: boolean;
  /** Размер списываемых бонусов */
  writeOffBonuses?: WriteOffBonuses;
}

export type PreOrderPickupRequest = PreOrderBaseRequest & {
  pickup?: PreOrderPickupRequestPickup;
};

export type PreOrderPickupPointRequest = PreOrderBaseRequest & {
  pickupPoint?: PreOrderDeliveryRequestDeliveryPickupPoint;
};

export type PreOrderDeliveryRequest = PreOrderBaseRequest & {
  delivery?: PreOrderDeliveryRequestDelivery;
};

export interface DeliveryService {
  /** Код дополнительной услуги */
  code: "rise" | "transfer";
  /** Наименование дополнительной услуги */
  name: string;
  /** Дополнительные функции услуги */
  features?:
    | {
        /** Код дополнительной функции */
        code: "noLift" | "serviceLift" | "passengerLift" | "technicalFloor";
        /** Наименование дополнительной функции */
        name: string;
        /** Признак, что дополнительная функция услуги учтена */
        isApplied: boolean;
      }[]
    | null;
  /** Признак, что услуга обязательна и не может быть исключена */
  isRequired: boolean;
  /** Признак, что дополнительная услуга учтена */
  isApplied: boolean;
}

/** Договор организации для вывода при оформлении заказа */
export type LegalContract = OrganizationContract & {
  /**
   * Наименование договора (устарело, использовать поле type)
   * @deprecated
   */
  name?: string;
  /**
   * Является ли договор примененным для предзаказа
   * @example false
   */
  isApplied: boolean;
};

/** Данные по ЮЛ. Будет отсутствовать для заказа ФЛ. */
export type PreOrderLegalOrganizationShort = OrganizationShort & {
  /** Список договоров */
  contracts?: LegalContract[] | null;
};

/** Способ оплаты заказа */
export interface PaymentMethod {
  /** Идентификатор способа оплаты */
  id: number;
  /** Наименование способа оплаты */
  name: string;
  /** Код способа оплаты */
  code: "cash" | "card" | "online" | "store" | "cashless" | "sberpay";
  logo?: (Image & object) | null;
}

/** Способ доставки */
export interface DeliveryMethod {
  /** Идентификатор способа доставки */
  id: number;
  /** Наименование способа доставки */
  name: string;
  /** Стоимость доставки */
  price?: number | null;
  /**
   * Является ли способ доставки выбранным
   * @default false
   */
  isApplied: boolean;
}

/** Список временных слотов доставки */
export interface DeliveryTime {
  /**
   * Дата доступной доставки
   * @format date
   */
  date: string;
  /**
   * Список временных слотов для конкретной даты
   * @example ["08 - 16 ч.","15 - 22 ч."]
   */
  times: string[];
}

export type StoreOnPickupBase = Store & {
  /**
   * Режим работы
   * @deprecated
   * @example "Ежедневно 08:00 - 20:00"
   */
  workTime?: string | null;
  /**
   * Время готовности заказа текстом
   * @example "Можно забрать 26 января (10:00-12:00)"
   */
  readyTime?: string | null;
};

export type StoreOnPreOrderPickup = StoreOnPickupBase & {
  /**
   * Является ли ТЦ выбранным при оформлении предзаказа
   * @example false
   */
  isApplied: boolean;
};

export interface PreOrderBase {
  /** Список продуктов в создаваемом заказе */
  products: PreOrderProduct[];
  /** Данные по ЮЛ. Будет отсутствовать для заказа ФЛ. */
  legal?: PreOrderLegalOrganizationShort;
  /** Список доступных способов оплаты */
  paymentMethods: PaymentMethod[];
  /** Примененная карта лояльности */
  loyalty?: {
    /** Максимально допустимый размер бонусов/баллов возможных к списанию. Данные рассчитываются в случае, если явно указан wantUseBonuses в запросе. */
    maxBonuses?: number | null;
    card?: LoyaltyCardBonus | LoyaltyCardPro;
  };
  /**
   * Тип плательщика: заказ на ФЛ или на ЮЛ:
   *   * `physical` - Физическое лицо
   *   * `legal` - Юридическое лицо или ИП
   */
  customerType: "physical" | "legal";
  /** Флаг о том, что может быть оформлен только предзаказ */
  isPreOrder: boolean;
  /** Данные по габаритам */
  orderParams: ProductParams;
  /** Полный список составляющих стоимости заказа */
  cost: CostOrder;
  /** Список доступных способов получения заказа */
  waysToReceive: ("pickup" | "delivery" | "pickup-point")[];
  /** Детализация изменений состояния корзины */
  changes?: BasketChanges;
}

/** Краткая форма документа возврата */
export interface RefundDocument {
  /**
   * Идентификатор документа возврата
   * @example 923072765
   */
  docId: number;
  /**
   * Код документа возврата
   * @example "ВнПок 8-00456191"
   */
  docCode: string;
  /** Дата возврата (формат: ISO-8601 = DATE_ATOM) */
  refundDate: string;
  /**
   * Сумма
   * @example 1000
   */
  sum: number;
  products: RefundDocumentProductShort[];
}

/** Краткая информация о возвращенном товаре */
export interface RefundDocumentProductShort {
  /**
   * Артикул товарного предложения
   * @example "511001209"
   */
  article: string;
  /** Название товара */
  title: string;
  /** Идентификатор раздела (категории) */
  sectionId: SectionId;
  /** Изображение товара */
  image?: (Image & object) | null;
}

/** Полная информация о возвращенном товаре */
export type RefundDocumentProductDetail = RefundDocumentProductShort & {
  /**
   * Название
   * @deprecated
   * @example "923072765"
   */
  name?: string;
  /** Ед. измерения товара */
  unit: Unit;
  /**
   * Количество
   * @example 10
   */
  quantity: number;
  /**
   * Цена за единицу
   * @example 10
   */
  price: number;
  /**
   * Итоговая сумма
   * @example 10
   */
  sum: number;
  /**
   * Сумма НДС
   * @example 10
   */
  vatAmount: number;
};

/** Полная форма документа возврата */
export type RefundDocumentDetail = UtilRequiredKeys<RefundDocument, "products"> & {
  /**
   * Сумма НДС
   * @example 10
   */
  vatAmount: number;
  products: RefundDocumentProductDetail[];
};

/** @deprecated */
export interface ProductRefund {
  /**
   * Название
   * @example "923072765"
   */
  name: string;
  /**
   * Единица измерения
   * @example "шт"
   */
  unit: string;
  /**
   * Количество
   * @example 10
   */
  quantity: number;
  /**
   * Цена за единицу
   * @example 10
   */
  price: number;
  /**
   * Итоговая сумма
   * @example 10
   */
  sum: number;
  /**
   * Сумма НДС
   * @example 10
   */
  vatAmount: number;
  image: object | (null & Image);
}

export interface CreateOrderByPickupDeliveryRequest {
  /** Идентификатор выбранного магазина при самовывозе */
  storeId: number;
  /** Идентификатор временного слота */
  timeSlotId: string;
  /** Выбранный способ оплаты */
  paymentMethodId: number;
}

export interface CreateOrderByExpressDeliveryRequest {
  /** Способ оплаты заказа */
  deliveryCode: PaymentMethod;
}

export type PreOrderPickup = {
  /** Информация о самовывозе */
  pickup: {
    /** Список доступных ТЦ для самовывоза */
    stores: StoreOnPreOrderPickup[];
  };
} & PreOrderBase;

export type PreOrderDelivery = {
  /** Информация о способах доставки */
  delivery: {
    /** Идентификатор выбранного ранее или предустановленного адреса доставки */
    deliveryAddressId?: number;
    /** Список способов доставки */
    deliveryMethods: DeliveryMethod[];
    /** Список доступных временных слотов */
    deliveryTimes?: DeliveryTime[];
    /** Список дополнительных услуг */
    services?: DeliveryService[];
    /** Уведомление о смене выбранного ТЦ */
    pickupClosedStatus?: string | null;
  };
} & PreOrderBase;

export type PreOrderPickupPoint = {
  /** Информация о пунктах выдачи заказа */
  pickupPoint: {
    /** Список доступных мобильных пунктов выдачи */
    points: StoreOnPreOrderPickup[];
  };
} & PreOrderBase;

export interface OrderProductBase {
  /**
   * Идентификатор товарной позиции
   * @example 577087
   */
  id?: number;
  /**
   * Наименование товарной позиции
   * @example "Цементная штукатурка легкая с перлитом Ceresit CT 24 Light 20 кг"
   */
  title?: string;
  /**
   * Артикул товарного предложения
   * @example "511001209"
   */
  article?: string;
  image?: Image;
  /** Цена товара и единица измерения товара */
  price?: Price;
  /**
   * Количество заказанного товара
   * @format regex
   * @pattern ^\d+(\.\d{1,2})?$
   */
  quantity?: string;
}

export type PreOrderProduct = OrderProductBase & {
  /**
   * Товар доступен для заказа в нужном количестве
   * @default true
   */
  isAvailable: boolean;
};

export type OrderProduct = OrderProductBase & {
  sectionId?: SectionId | null;
  /**
   * Наименование товарной позиции транслитом
   * @example "nasosnaya-stantsiya-grundfos-jp4-47pt-h-850-vt-4700-l-chas-napor-47-m"
   */
  code?: string | null;
};

export interface OrderDeliveryTime {
  /**
   * Дата доставки
   * @format date
   */
  date: string;
  /**
   * Временной слот доставки
   * @example "10:00 - 12:00 ч."
   */
  time: string;
}

export type OrderPickupReceive = StoreOnPickupBase;

export type OrderPickupPointReceive = StoreOnPickupBase;

export type OrderOfflineReceive = StoreOnPickupBase;

export interface OrderDeliveryReceive {
  deliveryAddress?: DeliveryAddressBase & object;
  deliveryTime?: OrderDeliveryTime;
}

export interface OrderBase {
  /** Номер заказа */
  number?: string;
  /**
   * Дата оформления заказа
   * @format date
   */
  date?: string;
  /**
   * Тип заказа (онлайн, оффлайн)
   * @default "online"
   * @example "online"
   */
  type?: "online" | "offline";
  /** Статус заказа */
  status?: {
    /** Код статуса заказа */
    code:
      | "created"
      | "waiting-for-payment"
      | "waiting-for-calling"
      | "processed"
      | "assembly"
      | "delivery"
      | "ready-for-pickup"
      | "completed"
      | "canceled";
    /** Статус заказа текстом */
    title: string;
  };
  /** Выбранный способ оплаты заказа и её статус */
  payment?: {
    status: {
      /** Статус оплаты заказа кодом */
      code: "not-paid" | "holded" | "invoice-sent" | "paid" | "canceled";
      /** Статус оплаты заказа текстом */
      title: string;
    };
  };
  /** Список товаров в заказе */
  products?: OrderProduct[];
  /** Базовые составляющие стоимости заказа */
  cost?: CostBase;
}

/** Краткая информация о заказе */
export type OrderShort = OrderBase & {
  /** Код способа получения товара */
  codeOfWayToReceive: "pickup" | "delivery" | "pickup-point" | "offline";
  /** Код для получения заказа */
  keyToReceive?: number | null;
  payment: any;
};

/** Детальная информация о заказе */
export type OrderDetail = OrderShort & {
  /** Список товаров в заказе */
  products?: (OrderProduct & {
    /**
     * Кратность добавления товара в корзину (если отсутствует, товар недоступен для заказа)
     * @default 1
     */
    multiple?: number;
    /**
     * Тип доступности товара:
     *   * `online` - доступен к покупке онлайн
     *   * `offline-wish` - доступен к покупке только офлайн, но может быть добавлен в список желаний онлайн
     *   * `offline` - доступен только офлайн, не доступен онлайн
     */
    availableType: AvailableType;
  })[];
  payment?: PaymentMethod & {
    /**
     * Ссылка на электронный чек заказа
     * @format uri
     */
    orderReceipt?: string | null;
    /**
     * Ссылка на электронный чек доставки
     * @format uri
     */
    deliveryReceipt?: string | null;
  };
  /** Получатель заказа */
  recipient: OrderRecipient;
  /** Полный список составляющих стоимости заказа */
  cost?: CostOrder;
  /** Карта лояльности */
  card?: LoyaltyCardBase;
  wayToReceive?: OrderPickupReceive | OrderDeliveryReceive | OrderPickupPointReceive | OrderOfflineReceive;
  /** Данные по габаритам */
  orderParams?: ProductParams;
  /**
   * Хэшированный код заказа (для оплаты неавторизованными пользователями)
   * @example "827ccb0eea8a706c4c34a16891f84e7b"
   */
  hash?: string | null;
  /**
   * Идентификатор ТЦ, в котором собран заказ
   * @example 1100358585
   */
  pickupStoreId?: number | null;
  /**
   * Штриход в формате EAN 13 - идентификатор документа, оформленного в Аварде для данного заказа
   * @example "460123456789"
   */
  barCode?: string | null;
};

export interface Message {
  /** Идентификатор сообщения */
  id?: number;
  /** Имя автора сообщения */
  author?: string;
  /** Дата создания сообщения (формат: ISO-8601 = DATE_ATOM) */
  dateCreated?: string;
  /** Текст сообщения */
  message?: string;
}

/** Статья */
export interface Article {
  /** Идентификатор статьи */
  id?: number;
  /** Заголовок статьи */
  caption?: string;
  /** Содержимое статьи */
  content?: string;
  media?: Media;
}

export interface BaseMessageRequest {
  /** Имя автора сообщения */
  author?: string;
  /** Текст сообщения */
  message: string;
}

export type MessageRequest = BaseMessageRequest & {
  /** Тип субъекта для которого добавляется отзыв (например: product) */
  type: string;
  /** Идентификатор субъекта для которого добавляется отзыв (например: идентификатор продукта) */
  subjectId: number;
  /** Идентификатор сообщения, для которого создается ответное сообщение */
  parentMessageId?: string;
};

export type ReviewRequest = BaseMessageRequest & {
  /** Тип субъекта для которого добавляется отзыв (например: product) */
  type: string;
  /** Идентификатор субъекта для которого добавляется отзыв (например: идентификатор продукта) */
  subjectId: number;
  /** Имя автора отзыва */
  author?: any;
  /** Текст отзыва */
  message?: any;
  /** Оценка, установленная автором отзыва */
  estimation: number;
};

export interface ReactionRequest {
  /** Тип субъекта для которого добавляется отзыв (например: review) */
  type: string;
  /** Идентификатор субъекта для которого добавляется отзыв (например: идентификатор отзыва) */
  subjectId: number;
  /** Код реакции (например: like, dislike, fire, nice) */
  reaction: string;
}

export type Review = Message & {
  /** Дата создания отзыва (формат: ISO-8601 = DATE_ATOM) */
  dateCreated?: any;
  /** Оценка, установленная автором отзыва */
  estimation?: number;
  /** Количество положительных реакций на отзыв */
  positiveReaction?: number;
  /** Количество негативных реакций на отзыв */
  negativeReaction?: number;
  attachments?: Media[];
};

export type TopicMessage = Message & {
  /** Идентификатор родительского сообщение */
  parentMessageId?: number;
};

export interface Media {
  /** Тип медиадокумента */
  type: "image" | "youtube" | "file";
  /** Наименование документа */
  title: string;
  /** URL к медиадокумемнту */
  path: string;
}

export type Image = Media & {
  /** Альтернативный текст (отображающийся в случае невозможности отображения медиадокумента) */
  alternative: string;
};

export type Youtube = Media & {
  preview: Image;
};

export type File = Media & {
  /**
   * Размер файла в байтах
   * @example 1048576
   */
  size: number;
  /**
   * MIME тип документа
   * @default "application/octet-stream"
   * @example "application/pdf"
   */
  mimetype:
    | "application/msword"
    | "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    | "application/pdf"
    | "application/vnd.ms-powerpoint"
    | "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    | "application/vnd.ms-excel"
    | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    | "application/zip"
    | "application/octet-stream";
};

export interface ProductProperty {
  /**
   * Id свойства
   * @example 5279
   */
  id: number;
  /**
   * Наименование свойства
   * @example "Тип"
   */
  title: string;
  /**
   * Код свойства
   * @example "TIP"
   */
  code: string;
  /**
   * Тип свойства
   * @example "string"
   */
  type: "integer" | "string" | "list" | "number" | "media";
  /**
   * Значение свойства представленное в строковом виде
   * @example "Ковры из синтетических материалов"
   */
  value: string;
}

export type FeedbackSubject = Entity & {
  /** Идентификатор темы сообщения */
  id?: number;
  /** Наименование темы сообщения */
  title?: string;
};

export interface Feedback {
  /** Идентификатор сообщения */
  id?: number;
  subject?: FeedbackSubject;
  location?: Location;
  store?: Store;
  /** Имя */
  name?: string;
  /** Адрес электронной почты */
  email?: string;
  /** Телефон */
  phone?: string;
  /** Сообщение */
  message?: string;
}

export interface Store {
  /**
   * Идентификатор магазина
   * @example 1100468182
   */
  id: number;
  /**
   * Наименование магазина
   * @example "ул. Дзержинского, 35"
   */
  name: string;
  /**
   * Дополнительная строка названия адреса магазина
   * @example "Бауцентр на ул. Дзержинского, 35"
   */
  title: string;
  /**
   * Адрес магазина
   * @example "Московская область, г. Пушкино, 33 км Ярославского шоссе (17 км от МКАД) – ТРК «Акварель»"
   */
  address: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.695829999995"
   */
  latitude: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.525669"
   */
  longitude: string;
  /** Часы работы */
  workingHours: WorkingHours;
  /** Телефоны магазина */
  phones?: {
    /**
     * Телефон интернет магазина
     * @example "+7 (495) 539-39-39"
     */
    online?: string | null;
    /**
     * Телефон магазина
     * @example "+7 (495) 870-55-44"
     */
    store?: string | null;
    /**
     * Телефон отдела Профи
     * @example "+7 (495) 870-55-88"
     */
    profi?: string | null;
  };
  /**
   * Ссылка на страницу магазина
   * @example "https://baucenter.ru/store/seleznev/"
   */
  pageUrl?: string | null;
}

/** Данные с результатами выборки */
export interface Locations {
  data: {
    locations: Location[];
    /** API-ключ яндекс-карт */
    mapKey?: string;
  };
}

export interface Location {
  /**
   * Идентификатор города (совпадает с id основного ТЦ региона)
   * @example 317
   */
  id: number;
  /**
   * Наименование города
   * @example "Калининград"
   */
  name: string;
  /**
   * Наименование региона (область доставки)
   * @example "г. Калининград и Калининградская область"
   */
  region?: string;
  /**
   * Широта
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.695829999995"
   */
  latitude: string;
  /**
   * Долгота
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.525669"
   */
  longitude: string;
  /** Список магазинов в городе */
  stores: Store[];
  /**
   * Идентификатор города в аварда
   * @example "130203050"
   */
  avardaId?: string;
}

export interface SearchTag {
  /**
   * Наименование тега
   * @example "для шкафа"
   */
  title: string;
  /**
   * Запрос для поиска
   * @example "двери для шкафа купе"
   */
  query: string;
}

export interface SearchCategory {
  /**
   * Идентификатор категории
   * @example 30166
   */
  id: number;
  /**
   * Наименование категории
   * @example "Элементы для ПВХ окон и дверей"
   */
  title: string;
  /**
   * Код категории на транслите
   * @example "elementy_dlya_pvkh_okon_i_dverey"
   */
  code: string;
  /**
   * Количество товаров в категории
   * @example 5
   */
  amount: number;
  /** В текущей выборке использовались эти категории */
  isApplied: boolean;
}

/** Раздел акции */
export interface ActionCategory {
  /**
   * Идентификатор раздела акции
   * @example 29888
   */
  id: number;
  /**
   * Заголовок раздела акции
   * @example "Акции месяца"
   */
  title: string;
  /** Список акций */
  actions: ActionShort[];
}

export interface ActionShort {
  /**
   * Идентификатор Акции
   * @example 1181248
   */
  id: number;
  /**
   * Заголовок акции
   * @example "Бесплатно! Краситель и колеровка красок Wollton!"
   */
  title: string;
  /** Изображение акции */
  image?: (Image & object) | null;
  /** Шильдики акции */
  nameplates?: ((ActionNameplate & object) | null)[];
  /**
   * Дата начала активности акции (формат: ISO-8601 = DATE_ATOM)
   * @example "2022-07-19T21:00:00.000Z"
   */
  activeFrom?: string | null;
  /**
   * Дата окончания активности акции (формат: ISO-8601 = DATE_ATOM)
   * @example "2023-07-19T21:00:00.000Z"
   */
  activeTo?: string | null;
}

/** Детальная информация об акции */
export interface ActionDetail {
  /**
   * Идентификатор акции
   * @example 1181248
   */
  id: number;
  /**
   * Заголовок акции
   * @example "Бесплатно! Краситель и колеровка красок Wollton"
   */
  title: string;
  /**
   * Текст акции
   * @example "При покупке дисперсионных красок Wollton услуга колеровки и необходимые красящие пигменты предоставляются бесплатно!"
   */
  text: string;
  /**
   * Условие акции
   * @example "При покупке товара, участвующего в акции, вы получаете 15% от стоимости покупки в виде бонусов"
   */
  condition: string;
  /** Список разделов товаров */
  sections: ActionProductList[];
  /** Детальное изображение акции */
  image?: (Image & object) | null;
  /**
   * Дата начала активности акции (формат: ISO-8601 = DATE_ATOM)
   * @example "2022-07-19T21:00:00.000Z"
   */
  activeFrom?: string | null;
  /**
   * Дата окончания активности акции (формат: ISO-8601 = DATE_ATOM)
   * @example "2023-07-19T21:00:00.000Z"
   */
  activeTo?: string | null;
}

export interface ActionProductList {
  /**
   * Идентификатор раздела
   * @example 29180
   */
  id: number;
  /**
   * Заголовок раздела
   * @example "Штукатурки"
   */
  title: string;
  /** Список товаров, относящихся к текущему разделу в акции */
  products?: ProductOfferShort[];
}

/** Детальная информация об акции со списком товаров */
export type ActionDetailProductList = ActionShort & {
  /** Список товаров в акции */
  products?: ProductOfferShort[];
};

/** Базовый объект расчета */
export interface Calc {
  /**
   * Тип калькулятора:
   *   * `laminate` - Калькулятор для расчета ламината
   *   * `dry-mixes` - Калькулятор для расчета сухих строительных смесей
   *   * `grout` - Калькулятор для расчета затирки
   *   * `drywall` - Калькулятор для расчета гипсокартона
   *   * `wallpaper` - Калькулятор для расчета обоев
   *   * `interior-paint` - Калькулятор для расчета краски для внутренних работ
   *   * `outdoor-paint` - Калькулятор для расчета краски для наружных работ
   *   * `tile` - Калькулятор для расчета плитки
   *   * `glass-wool` - Калькулятор для расчета стекловаты
   *   * `radiator` - Калькулятор для расчета радиаторов
   */
  type: CalculatorType;
}

/** Базовый объект типа калькулятора результата расчета */
export interface CalcResultType {
  /**
   * Тип калькулятора:
   *   * `laminate` - Калькулятор для расчета ламината
   *   * `dry-mixes` - Калькулятор для расчета сухих строительных смесей
   *   * `grout` - Калькулятор для расчета затирки
   *   * `drywall` - Калькулятор для расчета гипсокартона
   *   * `wallpaper` - Калькулятор для расчета обоев
   *   * `interior-paint` - Калькулятор для расчета краски для внутренних работ
   *   * `outdoor-paint` - Калькулятор для расчета краски для наружных работ
   *   * `tile` - Калькулятор для расчета плитки
   *   * `glass-wool` - Калькулятор для расчета стекловаты
   *   * `radiator` - Калькулятор для расчета радиаторов
   */
  type?: CalculatorType;
}

/** Базовый объект результата расчета */
export type CalcResult = CalcResultType & {
  /**
   * Тип калькулятора:
   *   * `laminate` - Калькулятор для расчета ламината
   *   * `dry-mixes` - Калькулятор для расчета сухих строительных смесей
   *   * `grout` - Калькулятор для расчета затирки
   *   * `drywall` - Калькулятор для расчета гипсокартона
   *   * `wallpaper` - Калькулятор для расчета обоев
   *   * `interior-paint` - Калькулятор для расчета краски для внутренних работ
   *   * `outdoor-paint` - Калькулятор для расчета краски для наружных работ
   *   * `tile` - Калькулятор для расчета плитки
   *   * `glass-wool` - Калькулятор для расчета стекловаты
   *   * `radiator` - Калькулятор для расчета радиаторов
   */
  type?: CalculatorType;
  /**
   * Сумма покупки (в копейках)
   * @example 2100
   */
  amount: number;
};

/** Запрос для расчета ламината */
export type CalcLaminateRequest = Calc & {
  type?: CalculatorType;
  /**
   * Способ укладки:
   *   * `straight` - По прямой
   *   * `diagonal` - По диагонали
   */
  layingMethod: "straight" | "diagonal";
  /**
   * Длина помещения (м)
   * @example 10.5
   */
  length: number;
  /**
   * Ширина помещения (м)
   * @example 21
   */
  width: number;
};

/** Результаты расчета ламината */
export type CalcLaminateResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество упаковок к покупке (шт)
   * @example 5
   */
  packingQuantity: number;
  /**
   * Количество м2 в упаковке (м2)
   * @example 250
   */
  packingArea: number;
};

/** Запрос для расчета сухих строительных смесей */
export type CalcDryMixesRequest = Calc & {
  type?: CalculatorType;
  /**
   * Площадь поверхности (м2)
   * @example 120.49
   */
  surfaceArea: number;
  /**
   * Толщина слоя (мм)
   * @example 10
   */
  layerThickness: number;
};

/** Результаты расчета сухих строительных смесей */
export type CalcDryMixesResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Общая масса (кг)
   * @example 50
   */
  totalWeight: number;
  /**
   * Количество пачек к покупке (шт)
   * @example 10
   */
  packingQuantity: number;
};

/** Запрос для расчета затирки */
export type CalcGroutRequest = Calc & {
  type?: CalculatorType;
  /**
   * Ширина плитки (м)
   * @example 0.5
   */
  tileWidth: number;
  /**
   * Длина плитки (м)
   * @example 0.5
   */
  tileLength: number;
  /**
   * Толщина плитки (мм)
   * @example 5
   */
  tileThickness: number;
  /**
   * Ширина плиточного шва (мм)
   * @example 3
   */
  tileJointWidth: number;
  /**
   * Площадь поверхности (м2)
   * @example 1200
   */
  surfaceArea: number;
};

/** Результаты расчета затирки */
export type CalcGroutResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Общая масса (кг)
   * @example 50
   */
  totalWeight: number;
  /**
   * Количество штук к покупке (шт)
   * @example 10
   */
  quantity: number;
};

/** Запрос для расчета гипсокартона */
export type CalcDrywallRequest = Calc & {
  type?: CalculatorType;
  /**
   * Площадь поверхности (м2)
   * @example 1200
   */
  surfaceArea: number;
};

/** Результаты расчета гипсокартона */
export type CalcDrywallResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество штук к покупке (шт)
   * @example 10
   */
  quantity: number;
};

/** Запрос для расчета обоев */
export type CalcWallpaperRequest = Calc & {
  type?: CalculatorType;
  /**
   * Периметр помещения (м)
   * @example 100
   */
  roomPerimeter: number;
  /**
   * Высота оклеиваемой стены (м)
   * @example 2.7
   */
  wallHeight: number;
  /**
   * Ширина окна (м)
   * @example 1.6
   */
  windowWidth: number;
  /**
   * Количество окон (шт)
   * @example 5
   */
  windowsQuantity: number;
  /**
   * Ширина двери (м)
   * @example 0.9
   */
  doorWidth: number;
  /**
   * Количество дверей (шт)
   * @example 3
   */
  doorsQuantity: number;
};

/** Результаты расчета обоев */
export type CalcWallpaperResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество рулонов к покупке (шт)
   * @example 15
   */
  quantity: number;
};

/** Запрос для расчета краски для внутренних работ */
export type CalcInteriorPaintRequest = Calc & {
  /**
   * Окрашиваемая поверхность:
   *   * `ceiling` - потолок
   *   * `wall` - стена
   */
  paintableSurface: "ceiling" | "wall";
  /**
   * Длина помещения (м)
   * @example 10
   */
  length: number;
  /**
   * Ширина помещения (м)
   * @example 7
   */
  width: number;
  /**
   * Высота окрашиваемой поверхности (м)
   * @example 3
   */
  height: number;
  /**
   * Площадь окон (м2)
   * @example 25
   */
  windowArea: number;
  /**
   * Площадь дверных проемов (м2)
   * @example 35
   */
  doorwaysArea: number;
  /**
   * Количество слоев краски (шт)
   * @example 2
   */
  paintCoatsQuantity: number;
};

/** Результаты расчета краски для внутренних работ */
export type CalcInteriorPaintResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество банок краски к покупке (шт)
   * @example 5
   */
  quantity: number;
};

/** Запрос для расчета краски для наружных работ */
export type CalcOutdoorPaintRequest = Calc & {
  type?: CalculatorType;
  /**
   * Ширина дома (м)
   * @example 10
   */
  houseWidth: number;
  /**
   * Длина дома (м)
   * @example 10
   */
  houseLength: number;
  /**
   * Высота стены (м)
   * @example 3
   */
  wallHeight: number;
  /**
   * Ширина фронтона (м)
   * @example 10
   */
  gableWidth: number;
  /**
   * Высота фронтона (м)
   * @example 3
   */
  gableHeight: number;
  /**
   * Площадь окон (м2)
   * @example 30
   */
  windowArea: number;
  /**
   * Площадь дверных проемов (м2)
   * @example 40
   */
  doorwaysArea: number;
  /**
   * Количество слоев краски (шт)
   * @example 2
   */
  paintCoatsQuantity: number;
};

/** Результаты расчета краски для наружных работ */
export type CalcOutdoorPaintResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество банок краски к покупке (шт)
   * @example 5
   */
  quantity: number;
};

/** Запрос для расчета плитки */
export type CalcTileRequest = Calc & {
  type?: CalculatorType;
  /**
   * Площадь поверхности (м2)
   * @example 1200
   */
  surfaceArea: number;
};

/** Результаты расчета плитки */
export type CalcTileResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество упаковок плитки к покупке (шт)
   * @example 7
   */
  packingQuantity: number;
  /**
   * Количество дополнительных плиток сверх упаковок к покупке (шт)
   * @example 5
   */
  extraTileQuantity: number;
  /**
   * Площадь плитки к покупке (м2)
   * @example 1150
   */
  tileArea: number;
};

/** Запрос для расчета стекловаты */
export type CalcGlassWoolRequest = Calc & {
  type?: CalculatorType;
  /**
   * Площадь поверхности (м2)
   * @example 1200
   */
  surfaceArea: number;
};

/** Результаты расчета стекловаты */
export type CalcGlassWoolResult = CalcResult & {
  type?: CalculatorType;
  /**
   * Количество упаковок к покупке (шт)
   * @example 7
   */
  packingQuantity: number;
  /**
   * Количество м2 в упаковке (м2)
   * @example 250
   */
  packingArea: number;
};

/** Запрос для расчета радиаторов */
export type CalcRadiatorRequest = Calc & {
  type?: CalculatorType;
  /**
   * Площадь помещения (м2)
   * @example 100
   */
  roomArea?: number;
  /**
   * Ориентация окон:
   *   * `south` - Юг
   *   * `west` - Запад
   *   * `east` - Восток
   *   * `north` - Север
   */
  windowOrientation?: "south" | "west" | "east" | "north";
  /**
   * Расположение комнаты:
   *   * `corner` - Угловая
   *   * `non-corner` - Неугловая
   */
  roomLocation?: "corner" | "non-corner";
  /**
   * Тип стен:
   *   * `panel` - Панельные
   *   * `brick` - Кирпичные
   *   * `insulated` - Утепленные
   */
  wallType?: "panel" | "brick" | "insulated";
  /**
   * Климатическая зона:
   *   * `15` - До -15C
   *   * `20` - До -20C
   *   * `30` - До -30C
   */
  climateZone?: 15 | 20 | 30;
  /**
   * Высота помещения:
   *   * `2.7` - До 2.7м
   *   * `3` - До 3.0м
   *   * `3.5` - До 3.5м
   */
  roomHeight?: 2.7 | 3 | 3.5;
  /**
   * Этаж:
   *   * `firstOrLast` - Первый или последний
   *   * `middle` - Средний
   */
  floor?: "firstOrLast" | "middle";
  /**
   * Тип стеклопакета:
   *   * `wood` - Дерево
   *   * `one` - Однокамерный
   *   * `twoOrMore` - Двухкамерный и более
   */
  windowType?: "wood" | "one" | "twoOrMore";
  /**
   * Тип подключения:
   *   * `side` - Боковое
   *   * `bottom` - Нижнее
   */
  connectionType?: "side" | "bottom";
};

/** Результаты расчета радиаторов */
export type CalcRadiatorResult = CalcResultType & {
  type?: CalculatorType;
  /**
   * Мощность радиаторов (Вт)
   * @example 1500
   */
  power: number;
  /**
   * Количество секций радиатора (шт)
   * @example 25
   */
  sectionsQuantity: number;
};

export type Nameplate = {
  /**
   * Тип шильдика:
   *  * `new` - Новинка
   *  * `action` - Акция
   *  * `action-pro` - Акция профи
   *  * `hit` - Хит продаж
   *  * `project` - Готовое решение
   *  * `3d-viz` - Экспресс визуализация
   *  * `supply-expect` - Ожидается поставка
   */
  type: "new" | "action" | "action-pro" | "hit" | "project" | "media" | "supply-expect";
  /**
   * Заголовок шильдика
   * @example "Экспресс визуализация"
   */
  title: string;
  /** Идентификатор акции или готового решения */
  id?: number;
  /**
   * URL акции, готового решения, 3d визуализации
   * @example "/3d/?wall1=301017320&wall2=301017320"
   */
  url?: string;
  /**
   * Цвет фона шильдика
   * @example "#FFC72C"
   */
  bgColor?: string | null;
  /**
   * Цвет текста шильдика
   * @example "#1C1C1C"
   */
  textColor?: string | null;
  /** Иконка шильдика */
  icon?: (Image & object) | null;
};

export interface ActionNameplate {
  /**
   * Заголовок шильдика
   * @example "15% Бонус"
   */
  title: string;
  /**
   * Цвет фона шильдика
   * @example "#FFC72C"
   */
  bgColor?: string | null;
  /**
   * Цвет текста шильдика
   * @example "#1C1C1C"
   */
  textColor?: string | null;
  /** Иконка шильдика */
  icon?: (Image & object) | null;
}

export interface CommonError {
  /** Описание возникшей ошибки */
  detail: string;
  /** Код ошибки */
  code: string;
}

export type ValidationError = CommonError & {
  /** Путь до поля с ошибкой */
  field?: string;
};

/** Усеченные данные по продукту при поиске по штрихкоду */
export interface ProductOfferBarcode {
  /**
   * Наименование товара
   * @example "Цементная штукатурка легкая Ceresit CT 24 Light 20 кг"
   */
  title?: string;
  /**
   * Артикул товара
   * @example "511001209"
   */
  article?: string;
  /** Список изображений товарного предложения */
  images?: Image[];
  price?: ProductOfferPrice;
  /** Доступность товара на ближайшую дату по типам (магазины, самовывоз, доставка) */
  availability?: ProductOfferAvailabilityByStoreTypes;
  /** Данные по габаритам */
  productParams?: ProductParams;
}

/** Услуги (сервисы) для товара */
export interface ProductService {
  /**
   * Название услуги
   * @example "Заказать дизайн-проект кухни"
   */
  title: string;
  /** Тип оказания услуги */
  type: "online" | "offline";
  image?: (Image & object) | null;
  /**
   * Ссылка на страницу услуги
   * @example "https://baucenter.ru/services/1998692/"
   */
  url?: string | null;
  /** Цена услуги */
  price?: {
    /**
     * Стоимость (в копейках)
     * @example 150000
     */
    value?: number;
    /** Префикс цены */
    prefix?: "from" | "up-to" | null;
  };
  /**
   * Признак того, применен ли фильтр для пользователей Профи
   * @example true
   */
  isProfi: boolean;
}

/** Список покупок (краткий) */
export interface WishListShort {
  /**
   * Идентификатор списка
   * @example 1234567
   */
  wishListId: number;
  /**
   * Название списка
   * @example "Для дома"
   */
  title: string;
  /**
   * Общее количество элементов в списке
   * @example 10
   */
  totalQuantity: number;
  /**
   * Общая стоимость товаров в списке (в копейках)
   * @example 254680
   */
  totalAmount: number;
  /**
   * Комментарий к списку
   * @example "Сантехника и прочее"
   */
  comment?: string;
  /**
   * Адрес QR-кода в формате https://baucenter.ru/api/v2/wish-list/{wishListId}/qr/{cityId}
   * @example "https://baucenter.ru/api/v2/wishlists/qr/1234567/317"
   */
  qr?: string;
}

/** Список покупок (расширенный) */
export type WishListDetail = WishListShort & {
  /** Список товаров */
  products: (ProductOfferShort & {
    productParams?: (ProductParams & object) | null;
  })[];
};

export interface Type400Response {
  errors?: (CommonError | ValidationError | (CommonError & ValidationError))[];
}

export interface Type404Response {
  errors?: CommonError[];
}

/** Адрес доставки (базовая часть) */
export interface DeliveryAddressBase {
  /**
   * Адрес доставки
   * @example "Калининградская область, посёлок городского типа Янтарный, ул. Обогатительная улица, д. 11"
   */
  address?: string;
  /**
   * Широта географической координаты
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "54.81237751"
   */
  latitude?: string;
  /**
   * Долгота географической координаты
   * @format regex
   * @pattern ^\-?\d+(\.\d+)?$
   * @example "20.87054495"
   */
  longitude?: string;
  /**
   * Признак, что адрес используется по умолчанию
   * @example true
   */
  isDefault?: boolean;
  /**
   * Флаг частного дома
   * @example false
   */
  isPrivateHouse?: boolean;
  /**
   * Подъезд
   * @example 3
   */
  entrance?: number | null;
  /**
   * Этаж
   * @example 6
   */
  floor?: number | null;
  /** Квартира */
  apartment?: string | null;
  /**
   * Комментарий
   * @example "Подъезд со стороны улицы. Вторая дверь (синяя)"
   */
  comment?: string | null;
}

/** Адрес доставки */
export type DeliveryAddress = {
  /** Идентификатор адреса доставки */
  id: number;
} & DeliveryAddressBase;

/** Запрос для адреса доставки */
export type DeliveryAddressRequest = DeliveryAddressBase;

export interface BurningBonus {
  /**
   * Текущий размер бессрочных бонусов/баллов (значение умноженное на 100)
   * @example 15000
   */
  quantity: number;
  /**
   * Дата/время начала активности (ISO 8601)
   * @example "2023-06-10T15:35:45.000Z"
   */
  availableFrom?: string | null;
  /**
   * Дата/время сгорания (ISO 8601)
   * @example "2023-06-10T15:35:45.000Z"
   */
  expiredAt?: string | null;
}

/** Список марок */
export interface Label {
  /**
   * Название марки
   * @example "DORN"
   */
  title: string;
  /**
   * Символьный код марки
   * @example "dorn"
   */
  code: string;
  /**
   * Описание марки
   * @example "DORN PRO — серия инструмента DORN, созданная специально для профессионалов."
   */
  description: string;
  /** Логотип марки */
  image: (Image & object) | null;
}

/** Баннер слайдера */
export interface Slider {
  /** Изображение баннера */
  image: Image & object;
  /**
   * URL на акцию или публикацию
   * @example "https://baucenter.ru/upload/Pri_poluchenie_nalichnymi_kartoy_Mob.jpg"
   */
  link?: string | null;
  /**
   * Тип страницы
   * @example "publication"
   */
  type?: "actions" | "publication" | null;
  /**
   * id объекта
   * @example 237858
   */
  elementId?: number | null;
  /**
   * Признак того, применен ли фильтр для пользователей Профи
   * @example true
   */
  isProfi: boolean;
}

/**
 * Тип калькулятора:
 *   * `laminate` - Калькулятор для расчета ламината
 *   * `dry-mixes` - Калькулятор для расчета сухих строительных смесей
 *   * `grout` - Калькулятор для расчета затирки
 *   * `drywall` - Калькулятор для расчета гипсокартона
 *   * `wallpaper` - Калькулятор для расчета обоев
 *   * `interior-paint` - Калькулятор для расчета краски для внутренних работ
 *   * `outdoor-paint` - Калькулятор для расчета краски для наружных работ
 *   * `tile` - Калькулятор для расчета плитки
 *   * `glass-wool` - Калькулятор для расчета стекловаты
 *   * `radiator` - Калькулятор для расчета радиаторов
 */
export enum CalculatorType {
  Laminate = "laminate",
  DryMixes = "dry-mixes",
  Grout = "grout",
  Drywall = "drywall",
  Wallpaper = "wallpaper",
  InteriorPaint = "interior-paint",
  OutdoorPaint = "outdoor-paint",
  Tile = "tile",
  GlassWool = "glass-wool",
  Radiator = "radiator",
}

/**
 * Тип фильтра (выбор из предложенного, переключатель, диапазон, диапазон дат)
 * @example "choose"
 */
export enum FilterType {
  Choose = "choose",
  Flag = "flag",
  Range = "range",
  DateRange = "date-range",
}

/** Список страниц */
export interface InfoPage {
  /**
   * Заголовок страницы
   * @example "Больше о Бауцентр"
   */
  title: string;
  /**
   * Уникальный символьный код страницы
   * @example "company"
   */
  code: string;
  /**
   * URL на инфостраницу
   * @example "https://baucenter.ru/company/"
   */
  link: string;
}

/** Список блоков */
export interface ProfiBlock {
  /**
   * Заголовок баннера
   * @example "Специальные условия для бюджетных организаций"
   */
  title: string;
  /** Изображение баннера */
  image?: (Image & object) | null;
  /**
   * URL на инфостраницу
   * @example "https://baucenter.ru/action/"
   */
  link?: string;
}

/** Блок преимущества для Профи */
export interface ProfiAdvantage {
  /**
   * Заголовок блока
   * @example "Больше о Бауцентр"
   */
  title: string;
  /** Изображение иконки */
  image: Image & object;
  /**
   * URL ссылка на страницу
   * @example "https://baucenter.ru/company/"
   */
  link?: string;
}

/** Блок семинара для Профи */
export interface ProfiSeminar {
  /** id семинара */
  id: number;
  /**
   * Название семинара
   * @example "Мастер-класс по укладке ПВХ покрытий"
   */
  title: string;
  /** Тип семинара */
  type: {
    /** Название типа семинара */
    title: "Семинар" | "Вебинар";
    /** Символьный код типа семинара */
    code: "seminar" | "webinar";
  };
  /**
   * Дата проведения семинара
   * @example "2023-01-01T14:00:00+03:00"
   */
  date?: string | null;
  /**
   * Время проведения семинара
   * @example "10:00"
   */
  time?: string | null;
  /**
   * Место проведения семинара
   * @example "Учебный класс гипермаркета «Бауцентр» в Пушкино, 33 км Ярославского шоссе"
   */
  location?: string | null;
  /** Изображение */
  image?: (Image & object) | null;
}

/** Блок Информационное сообщение */
export interface Announcement {
  /**
   * Заголовок объявления
   * @example "В связи с проведением технических работ сайт будет недоступен"
   */
  title: string;
  /**
   * Цвет фона объявления
   * @example "#d5e7f3"
   */
  bgColor?: string | null;
  /**
   * Цвет текста объявления
   * @example "#d5e7f3"
   */
  textColor?: string | null;
}

/** Детальная информация о товарах раздела коллекции */
export interface CollectionSectionDetail {
  /**
   * Идентификатор раздела коллекции
   * @example "kitchen"
   */
  code: string;
  /**
   * Заголовок раздела коллекции
   * @example "Шкафы настенные"
   */
  title?: string | null;
  /** Список товаров раздела коллекции */
  products: ProductOfferShort[];
}

/** Сокращенный формат данных организации */
export interface OrganizationShort {
  /**
   * ИНН
   * @example "7731347089"
   */
  inn: string;
  /**
   * Юридическое (полное) наименование организации
   * @example "ООО 'Умный уют'"
   */
  name: string;
}

/** Полный формат данных организации */
export type Organization = OrganizationShort & {
  /**
   * ОГРН
   * @example "1177756126040"
   */
  ogrn?: string | null;
  /**
   * Расчетный счет
   * @example "40602810206000050025"
   */
  rs?: string | null;
  /**
   * БИК
   * @example "044525974"
   */
  bik?: string | null;
  /**
   * Наименование банка
   * @example "СБЕРБАНК"
   */
  bankName?: string | null;
  /**
   * Юридический адрес
   * @example "Калининградская область, г.Калининград, ул.Ленина, д.125"
   */
  legalAddress?: string | null;
  /**
   * Фактический адрес
   * @example "Калининградская область, г.Калининград, ул.Ленина, д.125"
   */
  actualAddress?: string | null;
  /**
   * КПП
   * @example "773101001"
   */
  kpp?: string | null;
  /**
   * Телефон
   * @example "+7 (495) 539-39-39"
   */
  phone?: string | null;
  /**
   * Адрес электронной почты
   * @example "email@someorg.com"
   */
  email?: string | null;
  /** ФИО держателя карты */
  cardHolder?: Person;
  /**
   * Список договоров
   * @minItems 0
   */
  contracts?: OrganizationContract[];
};

/** Объекты самовывозов из ТЦ и ПВЗ */
export interface Pickups {
  stores?: ProductOfferPickup[];
  pvz?: ProductOfferPickup[] | null;
}

/** Информация по персоне (ФИО) */
export interface Person {
  /**
   * Имя
   * @example "Иван"
   */
  name: string;
  /**
   * Фамилия
   * @example "Иванов"
   */
  surname: string;
  /**
   * Отчество
   * @example "Иванович"
   */
  patronymic?: string;
}

/** Данные по магазину в поисковом ответе */
export interface SearchStore {
  /**
   * Идентификатор магазина
   * @example 323
   */
  id: number;
  /**
   * Наименование магазина
   * @example "ул. Дзержинского, 35"
   */
  title: string;
  /** В текущей выборке использовался этот магазин */
  isApplied: boolean;
}

/** Данные по скорректированному запросу */
export interface SearchQueryCorrection {
  /**
   * Исправленный вариант запроса
   * @example "инструменты"
   */
  improvementQuery: string;
  /**
   * Оригинальный запрос
   * @example "bycnhevtyns"
   */
  originalQuery: string;
  /**
   * Ссылка на оригинальный запрос
   * @example "/api/v2/search?query=...&hasCorrection=false"
   */
  originalQueryUrl: string;
}

/** Данные для редиректов при выполнении поискового запроса */
export interface SearchRedirect {
  /**
   * URL редиректа для мобильных устройств
   * @example "https://baucenter.ru/catalog/vkhodnye-dveri-ctg-06-14-01/elbrus-tsarga-collection-1100556325/"
   */
  mobile: string | null;
  /**
   * URL редиректа для десктоп устройств
   * @example "https://baucenter.ru/catalog/vkhodnye-dveri-ctg-06-14-01/elbrus-tsarga-collection-1100556325/"
   */
  desktop: string | null;
}

/**
 * WishListQRDetailDataItem
 * Единица товарной позиции в списке товаров для сервиса SmartStaff
 */
export interface WishListQRDetailDataItem {
  /**
   * Артикул товара
   * @example "3859589"
   */
  product: string;
  /**
   * Кол-во товара
   * @example 2.02
   */
  count: number;
}

export interface PreOrderDeliveryRequestDelivery {
  /** Доставка на ранее сохраненный адрес доставки */
  deliveryAddressId?: number;
  /** Идентификатор выбранного способа доставки */
  deliveryMethodId?: number;
  /** Выбранный список дополнительных услуг */
  services?: OrderDeliveryServices[];
}

export interface PreOrderDeliveryRequestDeliveryPickupPoint {
  /** Идентификатор мобильного пункта выдачи заказов, с которого будет осуществлен самовывоз */
  pointId?: number;
}

export interface PreOrderPickupRequestPickup {
  /** Идентификатор ТЦ, с которого будет осуществлен самовывоз */
  storeId?: number;
}

/**
 * ProductOfferDetailSectionResponse
 * Описание товарной позиции при указании ключа sections
 */
export interface ProductOfferDetailSectionResponse {
  product: {
    /** ID торгового предложения */
    id: number;
  };
}

/**
 * ProductOfferDetailResponse
 * Описание товарной позиции
 */
export interface ProductOfferDetailResponse {
  product: ProductOfferDetail;
  /** Хлебные крошки */
  breadcrumbs: BreadCrumb[] | null;
  /** Варианты товаров других размеров. (Тип товара technical или collection) */
  offersByProperty: ProductOfferByProperty[] | null;
  /** Комплектация */
  productSet: ProductOfferProductSet[] | null;
  advices: Advice[] | null;
  /** Список услуг для товара */
  services: ProductService[] | null;
}

/** Параметры запроса на обновление токена сервиса уведомлений */
export interface NotifyTokenRequestItem {
  /**
   * Тип сервиса уведомлений
   * @example "fcm"
   */
  type: "fcm";
  /** @example "zfgqMDoyO8DloKVbEhkpgb12xClgCShRCDnfCJtbPy5nfR2Dg0YrQnJwhBHFjuv0nl9uFP2pBUciKvdR" */
  token: string;
}

export interface MobileAppDescription {
  /**
   * Версия операционной системы
   * @example "Android 12"
   */
  platform: string;
  /**
   * Версия приложения
   * @example "2.1.0"
   */
  version?: string;
  /**
   * Номер сборки
   * @example "Build/SP1A.210812.016"
   */
  build?: string;
}

/** Ближайшая дата наличия товара */
export interface ProductOfferAvailabilityDate {
  /**
   * Наименование
   * @example "Магазины Калининграда"
   */
  title: string;
  /**
   * Планируемая дата поставки
   * @format date
   * @example "2022-01-01"
   */
  planDate: string;
  /**
   * Дата строкой, когда товар будет готов к выдаче
   * @example "Завтра"
   */
  planDateText: string;
  /** Подсказка с заголовком и текстом */
  prompt?: Prompt;
}

/** Часы работы */
export interface WorkingHours {
  /**
   * Тип графика работы магазина
   * @example "Ежедневно"
   */
  title: string;
  /**
   * Часы работы (с)
   * @example "10:00"
   */
  from: string;
  /**
   * Часы работы (до)
   * @example "22:00"
   */
  to: string;
}

/** Информация о количестве и дате наличия товара для доставки */
export interface ProductOfferDelivery {
  /**
   * Заголовок
   * @example "Сегодня 100 шт"
   */
  title: string;
}

/**
 * Тип магазина
 *   * `store` - магазин
 *   * `warehouse` - склад
 * @default "store"
 */
export enum StoreType {
  Store = "store",
  Warehouse = "warehouse",
}

/** Количество и единица измерения доступного товара */
export interface ProductOfferAvailabilityCount {
  /**
   * Количество доступного товара
   * @example 1000
   */
  amount: number;
  /** Ед. измерения товара */
  unit: Unit;
  /**
   * Текстовое представление количества доступного товара
   * @example "Доступно к заказу 20 шт"
   */
  text?: string | null;
}

/** Подсказка с заголовком и текстом */
export type Prompt = {
  /**
   * Заголовок подсказки
   * @example "Время сборки 2 часа в магазине в пределах остатков в наличии"
   */
  title: string;
  /**
   * Текст подсказки
   * @example "Время сборки"
   */
  text: string;
} | null;

/** Доступность товара по складам, магазинам, пунктам выдачи */
export interface ProductOfferAvailabilityShort {
  /**
   * Заголовок
   * @example "Доставка"
   */
  title: string;
  /**
   * Тип расположения товара (магазин, самовывоз, доставка):
   *   * `store` - магазин
   *   * `pickup` - самовывоз
   *   * `delivery` - доставка
   * @example "store"
   */
  type: "store" | "pickup" | "delivery";
  availabilityCount: (ProductOfferAvailabilityCount & object) | null;
  /** Подсказка с заголовком и текстом */
  prompt?: Prompt;
}

/** Доступность товара в пунктах самовывоза */
export type ProductOfferAvailabilityPickups = ProductOfferAvailabilityShort & {
  /** Ближайшая дата наличия товара */
  availabilityDate?: ProductOfferAvailabilityDate;
  pickups: ProductOfferPickup[];
};

/** Доступность товара в магазинах */
export type ProductOfferAvailabilityStores = ProductOfferAvailabilityShort & {
  stores: ProductOfferAvailability[];
  /** Ближайшая дата наличия товара */
  availabilityDate?: ProductOfferAvailabilityDate;
};

/** Доступность товара для доставки */
export type ProductOfferAvailabilityDelivery = ProductOfferAvailabilityShort & {
  delivery?: ProductOfferDelivery[];
};

/** Доступность товара на ближайшую дату по типам (магазины, самовывоз, доставка) */
export type ProductOfferAvailabilityByStoreTypes = (
  | ProductOfferAvailabilityStores
  | ProductOfferAvailabilityPickups
  | ProductOfferAvailabilityDelivery
  | (ProductOfferAvailabilityStores & ProductOfferAvailabilityPickups & ProductOfferAvailabilityDelivery)
)[];

/** Количество товара в списке покупок */
export interface ProductOfferQuantityInWishList {
  /**
   * Идентификатор списка покупок
   * @example 10
   */
  wishListId: number;
  /**
   * Количество товара в списке покупок
   * @example 100
   */
  quantity: number;
}

/**
 * Идентификатор раздела (категории)
 * @example 29180
 */
export type SectionId = number;

/** Размер списываемых бонусов */
export type WriteOffBonuses = number | null;

/** Сумма, с которой необходима сдача для допустимых типов оплат */
export interface PaymentChangeAmount {
  /**
   * Сумма сдачи
   * @example 119900
   */
  amount: number;
  /**
   * Валюта (RUB)
   * @example "RUB"
   */
  currency: "RUB";
}

/** Детализация изменений состояния корзины */
export interface BasketChanges {
  /** Детализация изменений товаров */
  products: {
    /**
     * Идентификатор товара
     * @example 292787
     */
    productOfferId: number;
    /**
     * Название товара
     * @example "Цементная штукатурка легкая с перлитом Ceresit CT 24 Light 20 кг"
     */
    name: string;
    /**
     * Массив изменений товара
     * @example ["Цена изменилась с 3200 р. на 3600 р.","Наличие изменилось с 10 шт. на 5 шт."]
     */
    details: string[];
  }[];
}

/** Базовые составляющие стоимости заказа */
export interface CostBase {
  /** Суммарная стоимость заказа */
  totalCost: number;
}

/** Полный список составляющих стоимости заказа */
export type CostOrder = CostBase & {
  /** Стоимость товаров без скидок */
  baseProductsCost?: number | null;
  /** Стоимость товаров */
  productsCost: number;
  /** Стоимость доставки */
  deliveryCost?: number | null;
  /** Список заказанных услуг */
  servicesCost?:
    | {
        /** Описание услуги */
        name: string;
        /** Стоимость услуги */
        cost: number;
      }[]
    | null;
  /** Общая стоимость заказа */
  totalCost?: number;
  /** Размер бонусов/баллов, которые будут начислены за заказ */
  bonuses?: number | null;
  /** При оформлении заказа бонусов списано */
  discountBonuses?: number | null;
};

/** Выбранный список дополнительных услуг при заказе доставки */
export interface OrderDeliveryServices {
  /** Выбранная услуга */
  code: "rise" | "transfer";
  /** Значение для выбранной услуги */
  value: number;
  /** Список кодов дополнительных функций услуги */
  features?: ("noLift" | "serviceLift" | "passengerLift" | "technicalFloor")[] | null;
}

/**
 * Тип доступности товара:
 *   * `online` - доступен к покупке онлайн
 *   * `offline-wish` - доступен к покупке только офлайн, но может быть добавлен в список желаний онлайн
 *   * `offline` - доступен только офлайн, не доступен онлайн
 * @example "online"
 */
export enum AvailableType {
  Online = "online",
  OfflineWish = "offline-wish",
  Offline = "offline",
}

/**
 * ProductOfferBarcodeResponse
 * Описание товарной позиции по штрихкоду
 */
export interface ProductOfferBarcodeResponse {
  /** Усеченные данные по продукту при поиске по штрихкоду */
  product: ProductOfferBarcode;
}
