import classNames from 'classnames';
import {getProductPluralText} from 'core/helpers/plural';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {selectBannerListByPlace} from 'modules/common/selectors';
import {SimpleProductCard} from 'modules/products/components/SimpleProductCard';
import {LeftSideBlock} from 'modules/slider/components/SliderWithBanner/components/LeftSideBlock';
import {SliderWithExternalButton} from 'modules/slider/components/SliderWithExternalButton';
import {SLIDER_DIRECTION, SPACE_BETWEEN} from 'modules/slider/constants';
import {ProductShort, PromoPlace} from 'new-models';
import {ReactNode, useCallback, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    promoPlace?: PromoPlace;
    products: ProductShort[];
    href?: string;
}

export const SliderWithBanner: FC<IProps> = ({promoPlace, products, href}) => {
    const productsCount = products.length;

    const banners = useAppSelector(selectBannerListByPlace(promoPlace as PromoPlace));

    const renderBannerBlock = useMemo<ReactNode>(() => {
        if (!promoPlace || !banners.length) {
            return null;
        }

        return (
            <LeftSideBlock
                banner={banners[0]}
                link={href}
                linkText={`${productsCount} ${getProductPluralText(productsCount)}`}
            />
        );
    }, [banners, href, productsCount, promoPlace]);

    const renderSlides = useCallback(
        (isDesktop = true) => {
            const productsSlides = products.map((slide, idx) => (
                <SimpleProductCard key={idx} {...slide} isWithSlider listType="list" variant="preview" />
            ));

            if (!isDesktop && renderBannerBlock) {
                return [<div className={style.bannerMobile}>{renderBannerBlock}</div>, ...productsSlides];
            }

            return productsSlides;
        },
        [products, renderBannerBlock]
    );

    if (!products?.length) {
        return null;
    }

    return (
        <div className={classNames(style.content, {[style.isOnlySlider]: !renderBannerBlock})}>
            {renderBannerBlock && <div className={style.bannerDesktop}>{renderBannerBlock}</div>}
            <div className={classNames(style.rightSide, {[style.isOnlySlider]: !renderBannerBlock})}>
                <SliderWithExternalButton
                    autoplay={false}
                    className={classNames(style.slider, style.desktop)}
                    direction={SLIDER_DIRECTION.horizontal}
                    isNavigation
                    slidesPerView="auto"
                    spaceBetween={SPACE_BETWEEN.default}
                >
                    {renderSlides()}
                </SliderWithExternalButton>
                <SliderWithExternalButton
                    autoplay={false}
                    className={classNames(style.slider, style.mobile)}
                    direction={SLIDER_DIRECTION.horizontal}
                    freeMode
                    isNavigation={false}
                    longSwipesMs={10}
                    longSwipesRatio={0.01}
                    slidesPerGroupAuto
                    slidesPerView="auto"
                    spaceBetween={SPACE_BETWEEN.previews}
                >
                    {renderSlides(false)}
                </SliderWithExternalButton>
            </div>
        </div>
    );
};
