import {FilterChoose, FilterRange} from 'new-models';
import {FilterUrlService} from 'plugins/modules/filter/services/FilterUrlService';
import {initialFilterState} from 'plugins/modules/filter/state';
import {IFilterState} from 'plugins/modules/filter/types';
import {createContext} from 'react';

export interface IFilterContext extends IFilterState {
    appliedChooseFiltersByVariantList: FilterChoose[];
    appliedRangeFiltersList: FilterRange[];
    pluralList?: [string, string, string];
    urlService?: FilterUrlService;
    hasAppliedFilters?: boolean;
}

export const FilterContext = createContext<IFilterContext>({
    appliedChooseFiltersByVariantList: [],
    appliedRangeFiltersList: [],
    ...initialFilterState,
});
