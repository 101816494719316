import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {NoImage} from 'components/NoImage';
import {Typography} from 'components/Typography';
import {RUNTIME_CSS_VARS} from 'core/constants';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {useElementHeightWithCssVar} from 'core/hooks/useElementHeightWithCssVar';
import {TPropsWithClassName} from 'core/types';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {ProductCardActions} from 'modules/product-card-actions/components/ProductCardActions';
import {MediaTypeEnum, ProductDetail} from 'new-models';
import React, {memo, ReactNode, useMemo, useRef} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    leftNode?: ReactNode;
    product: ProductDetail;
}

export const ProductCardHeader = memo<IProps>(function ProductCardHeader({className, leftNode, product}) {
    const productCardHeaderRef = useRef<HTMLDivElement>(null);

    // TODO: for the time of mixing two mock servers
    // const productSetTotalPrice = useAppSelector(selectProductTotalPrice) ?? product.price;
    // const isSelectedSetPrice = useAppSelector(selectIsSelectedSetPrice);

    useElementHeightWithCssVar(productCardHeaderRef, RUNTIME_CSS_VARS.productCardFixedHeader);

    // TODO: for the time of mixing two mock servers
    // const isProductAvailable = useIsProductAvailable(product);
    const isProductAvailable = true;

    const renderImage = useMemo(() => {
        const image = product.media?.find(
            (media) => media.type === MediaTypeEnum.Image || media.type === MediaTypeEnum.ImageAdaptive
        );

        if (!image) {
            return <NoImage className={style.image} />;
        }

        return (
            <MediaHarvester
                className={style.image}
                media={image}
                options={generateMediaOptions({
                    resizeKey: IMAGE_RESIZE_CODE.w108,
                    type: MediaTypeEnum.Image,
                })}
                title={image.title || product.title}
            />
        );
    }, [product.media, product.title]);

    const productPrice = useMemo(
        // TODO: for the time of mixing two mock servers
        // () => (isSelectedSetPrice ? transformUnitToSet(productSetTotalPrice) : product.price)
        () => product.price,
        [product.price]
    );

    return (
        <div className={classNames(style.headerWrapper, className)} ref={productCardHeaderRef}>
            <div className={style.headerContainer}>
                <div className={style.headerLeft}>
                    {leftNode ? (
                        <>{leftNode}</>
                    ) : (
                        <>
                            {renderImage}
                            <div className={style.title}>
                                <Typography color="gray60" variant="p-small">
                                    {product.article}
                                </Typography>
                                <Typography color="gray100" variant="p-medium">
                                    {product.title}
                                </Typography>
                            </div>
                        </>
                    )}
                </div>
                <div className={style.headerRight}>
                    <div className={style.imageAndPrice}>
                        {leftNode && renderImage}
                        <PriceAndBonuses
                            isProductAvailable={isProductAvailable}
                            price={productPrice}
                            usedIn={USED_IN.mainHeader}
                            withUnit
                        />
                    </div>
                    <div className={style.actions}>
                        <ProductCardActions
                            favoriteBtnSize="medium"
                            isHorizontal
                            isStaticWidth
                            showPrice
                            variant="productPageHeader"
                            {...product}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});
