import {TClosureCallback} from 'core/types';
import {MAIN_INFO_DATA} from 'modules/product-card/constants';
import {IUseIsNeedMainInfoItemParams} from 'modules/product-card/types';
import {useCallback} from 'react';

export const useIsNeedMainInfoBlock: TClosureCallback<
    IUseIsNeedMainInfoItemParams,
    TClosureCallback<string, boolean>
> = ({product, isProductAvailable}) => {
    return useCallback(
        // eslint-disable-next-line complexity
        (name) => {
            const isNeedDescription = name === MAIN_INFO_DATA.DESCRIPTION.name && Boolean(product?.description?.length);
            const isNeedFiles = name === MAIN_INFO_DATA.FILES.name && Boolean(product?.files?.length);
            const isNeedProperties = name === MAIN_INFO_DATA.PROPERTIES.name && Boolean(product?.properties?.length);
            const isNeedSimilarProducts = name === MAIN_INFO_DATA.SIMILAR_PRODUCTS.name && !isProductAvailable;
            const isNeedSetProducts = name === MAIN_INFO_DATA.SET_PRODUCTS.name && Boolean(product?.products?.length);

            return isNeedDescription || isNeedFiles || isNeedProperties || isNeedSimilarProducts || isNeedSetProducts;
        },
        [
            product?.description?.length,
            product?.files?.length,
            product?.properties?.length,
            product?.products?.length,
            isProductAvailable,
        ]
    );
};
