// import {isBrowserContext} from 'core/helpers';
import Script from 'next/script';
import React, {memo} from 'react';

const YandexMetrikaScripts = memo<{yaMetrikaId: string}>(function YandexMetrikaScripts({yaMetrikaId}) {
    return (
        <>
            <Script id="yandex-metrika" strategy="afterInteractive">
                {`
            {
                (function (d, w, c) {
                    (w[c] = w[c] || []).push(function () {
                        try {
                            w.yaCounter${yaMetrikaId} = new Ya.Metrika(
                                {id: ${yaMetrikaId}, clickmap: true, trackLinks: true, accurateTrackBounce: true, ecommerce: "dataLayer"}
                            );
                        } catch (e) {
                        }
                    });
            
                    var n = d.getElementsByTagName("script")[0],
                        x = "https://mc.yandex.ru/metrika/watch.js",
                        s = d.createElement("script"),
                        f = function () {
                            n.parentNode.insertBefore(s, n);
                        };
                    for (var i = 0; i < document.scripts.length; i++) {
                        if (document.scripts[i].src === x) {
                            return;
                        }
                    }
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = x;
            
                    f();
                })(document, window, "yandex_metrika_callbacks");
                 
                const noScript = document.createElement('noscript');
                const div = document.createElement('div');
                const img = document.createElement('img');
                img.src = 'https://mc.yandex.ru/watch/${yaMetrikaId}';
                img.style.cssText = 'left: -9999px; position: absolute';
                img.alt = '';
                div.appendChild(img);
                noScript.appendChild(div);
                document.body.appendChild(noScript);
            }
          `}
            </Script>
        </>
    );
});

export default YandexMetrikaScripts;
