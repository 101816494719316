import {USER_ADDRESS_ID_FIELD_NAME} from 'modules/checkout/constants/fields';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {DeliveryAddress} from 'new-models';
import {useEffect} from 'react';

export const useCheckoutDeliveryAddress = (list: DeliveryAddress[]) => {
    const {setFieldValue, getFieldMeta} = useCheckoutFormikContext();

    useEffect(() => {
        const currentAddress = getFieldMeta(USER_ADDRESS_ID_FIELD_NAME).value;

        if (Boolean(currentAddress)) {
            return;
        }
        setFieldValue(USER_ADDRESS_ID_FIELD_NAME, list[0].id);
    }, [getFieldMeta, list, setFieldValue]);
};
