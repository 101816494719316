import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectCategoryBySectionId} from 'modules/categories/selectors';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {ProductCardActions} from 'modules/product-card-actions/components/ProductCardActions';
import {ProductImageSwitcher} from 'modules/products/components/ProductImageSwitcher';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {Shield} from 'modules/shields/components/Shield';
import {ShieldsList} from 'modules/shields/components/ShieldsList';
import {SHIELD_THEMES} from 'modules/shields/constants';
import {ProductShort} from 'new-models';
import React, {FC} from 'react';

import style from './style.module.scss';

export const VisualProductCard: FC<ProductShort> = (product) => {
    const {price, media, article, title, nameplates} = product;

    const productUrl = useProductUrl({...product});
    const categoryData = useAppSelector(selectCategoryBySectionId(product.categoryId));

    // TODO: for the time of mixing two mock servers
    // const isProductAvailable = useIsProductAvailable(product);
    const isProductAvailable = true;

    return (
        <div className={style.visualProductCard}>
            <div className={style.visualProductCardFrameContainer}>
                <PrevDefaultLink href={productUrl} prefetch={false}>
                    <div className={style.visualProductCardImageList} data-skeleton-item>
                        <ProductImageSwitcher imageList={media} />
                        <ShieldsList shields={nameplates} />
                    </div>
                    <div className={style.visualProductCardInfoContainer}>
                        <Typography
                            className={style.visualProductCardArticle}
                            data-skeleton-item
                            element="div"
                            variant="p-medium"
                        >
                            {article}
                        </Typography>
                        <Typography
                            className={style.visualProductCardTitle}
                            data-skeleton-item
                            element="div"
                            variant="p"
                        >
                            {title}
                        </Typography>
                    </div>
                    <div className={style.visualProductSubCategoryContainer}>
                        {categoryData && (
                            <Shield className={style.visualProductSubCategory} theme={SHIELD_THEMES.info}>
                                <Typography data-skeleton-item element="span" variant="badge">
                                    {categoryData.title}
                                </Typography>
                            </Shield>
                        )}
                    </div>
                    <PriceAndBonuses
                        className={style.visualProductCardPriceContainer}
                        isProductAvailable={isProductAvailable}
                        price={price}
                        usedIn={USED_IN.visualPreview}
                    />
                    {/* TODO: for the time of mixing two mock servers*/}
                    {/* {isProductAvailable && (*/}
                    {/*    <AvailabilityButton*/}
                    {/*        availability={availability}*/}
                    {/*        className={style.visualProductCardAvailability}*/}
                    {/*        data-prevent-default-marker*/}
                    {/*        productId={id}*/}
                    {/*    />*/}
                    {/* )}*/}
                </PrevDefaultLink>
                <ProductCardActions
                    className={style.visualProductCardActions}
                    isHorizontal
                    variant="tile"
                    {...product}
                />
            </div>

            <div className={style.visualProductCardFrame}>
                <ProductCardActions
                    className={style.visualProductCardActions}
                    isHorizontal
                    variant="tile"
                    {...product}
                />
            </div>
        </div>
    );
};
