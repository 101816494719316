import {IListingState} from 'plugins/modules/listing/types';
import {createContext} from 'react';

export interface IListingContext extends IListingState {
    isListLoading?: boolean;
    isListPartiallyLoading?: boolean;
    isShowListingBanner?: boolean;
}

export const ListingContext = createContext<IListingContext>({
    isListLoading: false,
    isListPartiallyLoading: false,
    isShowListingBanner: false,
    list: [],
    listType: 'tile',
});
