import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IRootReducer} from 'core/redux/reducers';
import {IProductState} from 'modules/product-card/types';
import {HYDRATE} from 'next-redux-wrapper';

export const productModuleName = 'product';

const initialState: IProductState = {
    currentProductData: null,
    imageZoomDirection: 'zooming-out',
    isSelectedSetPrice: false,
    productDeliveryInfo: [],
    productSetList: [],
    productSetTotalPrice: null,
};

export const productSlice = createSlice({
    extraReducers: {
        [HYDRATE]: (state, {payload}: PayloadAction<IRootReducer>) => {
            if (!payload) {
                return state;
            }

            return {
                ...payload[productModuleName],
            };
        },
    },
    initialState,
    name: productModuleName,
    reducers: {
        actionChangeProductSetList(state: IProductState, {payload}: PayloadAction<number>) {
            state.productSetList = [
                ...state.productSetList.map((productSetItem) => {
                    return {
                        ...productSetItem,
                        variants: productSetItem.variants.map((variant) => {
                            return variant.productOfferId === payload
                                ? {...variant, isSelected: !variant.isSelected}
                                : variant;
                        }),
                    };
                }),
            ];
        },

        actionSetCurrentProduct(state: IProductState, {payload}: PayloadAction<IProductState['currentProductData']>) {
            state.currentProductData = payload;
        },

        actionSetImageZoomDirection(
            state: IProductState,
            {payload}: PayloadAction<IProductState['imageZoomDirection']>
        ) {
            state.imageZoomDirection = payload;
        },

        actionSetProductDeliveryInfo(
            state: IProductState,
            {payload}: PayloadAction<IProductState['productDeliveryInfo']>
        ) {
            state.productDeliveryInfo = payload;
        },

        actionSetProductSetList(state: IProductState, {payload}: PayloadAction<IProductState['productSetList']>) {
            state.productSetList = payload;
        },

        actionSetProductSetTotalPrice(
            state: IProductState,
            {payload}: PayloadAction<IProductState['productSetTotalPrice']>
        ) {
            state.productSetTotalPrice = payload;
        },

        actionSwitchSelectedSetPrice(
            state: IProductState,
            {payload}: PayloadAction<IProductState['isSelectedSetPrice']>
        ) {
            state.isSelectedSetPrice = payload;
        },
    },
});

export const {
    actions: {
        actionSetCurrentProduct,
        actionSetImageZoomDirection,
        actionSetProductSetTotalPrice,
        actionChangeProductSetList,
        actionSwitchSelectedSetPrice,
        actionSetProductSetList,
        actionSetProductDeliveryInfo,
    },
} = productSlice;

export const {reducer: productReducer} = productSlice;
