import {EmptyList} from 'components/EmptyList';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import favouriteIcon from 'public/icons/favourite.svg';
import React, {ReactNode, useMemo} from 'react';

import style from './style.module.scss';

export const EmptyFavouritesList = () => {
    const renderAdvice = useMemo<ReactNode>(
        () => (
            <Typography className={style.advice} color="gray60" element="div" variant="p">
                Добавить товар в избранное можно по клику на
                <SvgIcon svg={favouriteIcon} />
            </Typography>
        ),
        []
    );
    return <EmptyList advice={renderAdvice} isToHomeButton title="Здесь ничего нет" />;
};
