import {useEffect, useState} from 'react';

export interface IUseScrollDirection {
    onScrollDown: () => void;
    onScrollUp: () => void;
}
export const useScrollDirection = ({onScrollDown, onScrollUp}: IUseScrollDirection) => {
    const [isScrollDown, setIsScrollDown] = useState<boolean>(false);

    useEffect(() => {
        const threshold = 100;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.scrollY;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setIsScrollDown(scrollY > lastScrollY);
            lastScrollY = 0 < scrollY ? scrollY : 0;
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [isScrollDown]);

    useEffect(() => {
        if (isScrollDown) {
            onScrollDown();
            return;
        }

        onScrollUp();
    }, [isScrollDown, onScrollDown, onScrollUp]);
};
