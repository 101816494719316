import classNames from 'classnames';
import {HrElement} from 'components/HrElement';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {OrderXPageProductItem} from 'modules/orders/components/OrderXPageProductItem';
import {OrderDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    products: OrderDetail['products'];
}

export const OrderXPageProductList = memo<IProps>(function OrderXPageProductList({className, products}) {
    return (
        <div className={classNames(style.root, className)}>
            <Typography element="h5" variant="h5">
                Товары в заказе
            </Typography>

            <div className={style.list}>
                {products?.map((orderItem, index) => (
                    <>
                        <OrderXPageProductItem key={orderItem.product.id} orderProduct={orderItem} />
                        {index !== products.length - 1 && <HrElement />}
                    </>
                ))}
            </div>
        </div>
    );
});
