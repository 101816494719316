import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectIsPopularProductsLoading, selectPopularList} from 'modules/products/selectors';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import React, {FC} from 'react';

export const PopularProductsSlider: FC<TPropsWithClassName> = ({className}) => {
    const isLoading = useAppSelector(selectIsPopularProductsLoading);
    const popularProducts = useAppSelector(selectPopularList);

    if (!popularProducts.length) {
        return null;
    }

    return (
        <PageSectionBlock className={className} title="Популярные товары" withoutPaddingTop>
            <SkeletonWrapper disabled={!isLoading}>
                <SliderWithBanner products={popularProducts} />
            </SkeletonWrapper>
        </PageSectionBlock>
    );
};
