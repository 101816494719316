import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {formatDateWithLocal} from 'core/helpers/date';
import {CheckoutContext} from 'modules/checkout/context';
import {DeliveryMethodShort} from 'new-models';
import {useCallback, useContext} from 'react';

export const useMountDeliveryNoteText = () => {
    const {deliveryInfo} = useContext(CheckoutContext);

    return useCallback(
        (id: DeliveryMethodShort['id']) => {
            const delivery = deliveryInfo?.find(({deliveryMethod}) => deliveryMethod.id === id);

            if (!delivery) {
                return '';
            }

            const noteTextArray = [];

            if (delivery?.deliveryDate) {
                noteTextArray.push(`С ${formatDateWithLocal(delivery.deliveryDate, 'dd MMMM')},`);
            }

            noteTextArray.push(
                delivery?.deliveryMinPrice
                    ? `${formatPrice((delivery.deliveryMinPrice / PRICE_DENOMINATOR).toString(), {
                          maximumFractionDigits: 2,
                      })}₽`
                    : 'бесплатно'
            );

            return noteTextArray.join(' ');
        },
        [deliveryInfo]
    );
};
