import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import ConfirmLocationMiniModal from 'modules/locations/components/ConfirmLocationMiniModal';
import {LocationLabel} from 'modules/locations/components/LocationLabel';
import {LocationMiniModal} from 'modules/locations/components/LocationMiniModal';
import {useSelectLocation} from 'modules/locations/hooks/useSelectLocation';
import {useUserLocation} from 'modules/locations/hooks/useUserLocation';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {Portal} from 'modules/portal/components/Portal';
import {selectIsPortalVisible} from 'modules/portal/selectors';
import {actionTogglePortal} from 'modules/portal/slice';
import React, {FC, useCallback} from 'react';

export const HeaderLocationItem: FC = () => {
    const dispatch = useAppDispatch();
    const userLocation = useUserLocation();
    const selectLocation = useSelectLocation();
    const isShowConfirmLocationModal = useAppSelector(selectIsPortalVisible(MODALS.CONFIRM_LOCATION.name));

    const onClose = useCallback(() => {
        dispatch(hideModal(MODALS.LOCATION_CHOOSE.name));
        dispatch(hideModal(MODALS.CONFIRM_LOCATION.name));

        if (!userLocation) {
            return;
        }
        selectLocation(userLocation);
    }, [dispatch, selectLocation, userLocation]);

    const handleShowLocationsList = useCallback(() => {
        if (isShowConfirmLocationModal) {
            return;
        }
        dispatch(actionTogglePortal(MODALS.LOCATION_CHOOSE.name));
    }, [dispatch, isShowConfirmLocationModal]);

    return (
        <div>
            <LocationLabel isDisable={isShowConfirmLocationModal} onClick={handleShowLocationsList} />
            <Portal name={MODALS.LOCATION_CHOOSE.name} willMount>
                <LocationMiniModal onClose={onClose} userLocation={userLocation} />
            </Portal>
            <ConfirmLocationMiniModal />
        </div>
    );
};
