import {DashboardLayout} from 'layouts/DashboardLayout';
import {BOTTOM_BAR_BUTTONS} from 'modules/bottombar/constants';
import {useBottomBar} from 'modules/bottombar/hooks/useBottomBar';
import {OrdersPage} from 'modules/orders/pages/OrdersPage';
import {ordersSlice} from 'modules/orders/slice';
import {ProductListPageFunctionalityContext} from 'modules/products/context';
import {ORDERS_SKELETON_PAGE_DATA} from 'modules/skeleton/constants';
import {ProfileUserMenu} from 'modules/user/components/ProfileUserMenu';
import {SliceProvider} from 'plugins/context/Slice/SliceProvider';
import {ListingProvider} from 'plugins/modules/listing/provider';
import React, {FC} from 'react';

export const DashboardOrdersSkeleton: FC = () => {
    useBottomBar(BOTTOM_BAR_BUTTONS.profile);

    return (
        <SliceProvider slice={ordersSlice}>
            <ProductListPageFunctionalityContext.Provider
                value={{
                    collectionSwitcher: false,
                    countIndicator: false,
                    mobileHeader: false,
                    orderFilterButton: true,
                    sortingSelector: false,
                    title: false,
                    viewSwitcher: false,
                }}
            >
                <ListingProvider list={ORDERS_SKELETON_PAGE_DATA.list} listType="list">
                    <DashboardLayout sidebar={<ProfileUserMenu />}>
                        <OrdersPage />
                    </DashboardLayout>
                </ListingProvider>
            </ProductListPageFunctionalityContext.Provider>
        </SliceProvider>
    );
};
