import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {NO_IMAGE_SIZE, NoImage} from 'components/NoImage';
import {PrevDefaultLink} from 'components/PrevDefaultLink';
import {Typography} from 'components/Typography';
import {PriceAndBonuses} from 'modules/price-n-bonuses/components/PriceAndBonuses';
import {USED_IN} from 'modules/price-n-bonuses/constants';
import {ProductCardActions} from 'modules/product-card-actions/components/ProductCardActions';
import {PRODUCT_LIST_VIEW} from 'modules/products/constants';
import {useIsProductAvailable} from 'modules/products/hooks/useIsProductAvailable';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {MediaTypeEnum, ProductShort} from 'new-models';
import React, {FC} from 'react';

import styles from './styles.module.scss';

interface IProps {
    areCardActionsVisible?: boolean;
    data: ProductShort;
}

const ImageFallback: FC = () => <NoImage listView={PRODUCT_LIST_VIEW.list} size={NO_IMAGE_SIZE.stretched} />;

export const SearchPanelProduct: FC<IProps> = ({data}) => {
    const {code, id, media, price, article, title} = data;

    const productUrl = useProductUrl({code, id});

    const isProductAvailable = useIsProductAvailable(data);
    return (
        <PrevDefaultLink href={productUrl}>
            <div className={styles.searchPanelProduct}>
                <div className={styles.searchPanelProductImage} data-skeleton-item>
                    <MediaWithImageFallback
                        NoImageComponent={ImageFallback}
                        media={media}
                        mediaType={MediaTypeEnum.Image}
                    />
                </div>
                <div className={styles.searchPanelProductDescription}>
                    <Typography color="gray-a50" data-skeleton-item element="p" variant="p-regular">
                        {article}
                    </Typography>
                    <Typography className={styles.title} data-skeleton-item element="p" variant="p">
                        {title}
                    </Typography>
                    <div className={styles.bottom}>
                        <PriceAndBonuses
                            className={styles.priceAndBonuses}
                            isProductAvailable={isProductAvailable}
                            price={price}
                            usedIn={USED_IN.searchPanel}
                        />

                        <ProductCardActions
                            isHorizontal
                            isStaticWidth
                            stretched
                            variant="searchPanel"
                            {...data}
                            className={styles.searchPanelProductCardActionsBtn}
                        />
                    </div>
                </div>
            </div>
        </PrevDefaultLink>
    );
};
