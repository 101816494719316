import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    leftSideText: string;
}

export const OrderXInfoBlockItem = memo<IProps>(function OrderXInfoBlockItem({leftSideText, children, className}) {
    return (
        <div className={classNames(style.root, className)}>
            <Typography className={style.leftSide} color="gray60" element="p" variant="p">
                {leftSideText}
            </Typography>
            <div className={style.rightSide}>{children}</div>
        </div>
    );
});
