import {Route} from 'modules/router/components/Route';
import {SkeletonPageLayout} from 'modules/skeleton/layouts/SkeletonPageLayout';
import {FranchisePageSkeleton} from 'modules/skeleton/pages/FranchisePageSkeleton';
import {SKELETON_FRANCHISE_PAGES_ROUTE} from 'modules/skeleton/routes';
import {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import React, {FC} from 'react';

interface IProps {
    initialQuery: NextParsedUrlQuery;
}

export const FranchiseSkeletonRoute: FC<IProps> = ({initialQuery}) => {
    return (
        <Route
            component={<FranchisePageSkeleton />}
            initialQuery={initialQuery}
            layout={<SkeletonPageLayout />}
            path={`${SKELETON_FRANCHISE_PAGES_ROUTE}`}
        />
    );
};
