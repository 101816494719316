import {useMemo} from 'react';

export const useSkeletonCategoryType = <S extends Record<string, any>, T extends string>(
    categoryType: T,
    categoryMap: Record<T, S>
): S => {
    return useMemo<S>(() => {
        return categoryMap[categoryType];
    }, [categoryMap, categoryType]);
};
