import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {IProps as ISegmentedRadioProps, SegmentedRadio} from 'components/SegmentedRadio';
import {SvgIcon} from 'components/SvgIcon';
import pencilIcon from 'public/icons/pencil.svg';
import React, {memo, MouseEventHandler, useCallback} from 'react';

import style from './style.module.scss';

interface IProps extends ISegmentedRadioProps {
    onOpenModal?: () => void;
}

export const CheckoutDeliveryAddressItem = memo<IProps>(function CheckoutDeliveryAddressItem({
    className,
    isLabel = true,
    onOpenModal,
    ...restSegmentProps
}) {
    const onClick = useCallback<MouseEventHandler>(
        (event) => {
            event.preventDefault();
            onOpenModal?.();
        },
        [onOpenModal]
    );

    return (
        <SegmentedRadio className={classNames(style.root, className)} isLabel={isLabel} {...restSegmentProps}>
            <Anchor
                as="button"
                icon="left"
                iconNode={<SvgIcon svg={pencilIcon} />}
                isHideTextAdaptive
                onClick={onClick}
                theme="standalone"
            >
                Изменить
            </Anchor>
        </SegmentedRadio>
    );
});
