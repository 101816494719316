import {useFormikContext} from 'formik';
import {useCallback} from 'react';

export const useAddressField = (fieldName: string, fieldMaxLength: number) => {
    const {getFieldMeta, setFieldValue} = useFormikContext();
    const privateHouseMeta = getFieldMeta('isPrivateHouse');
    const fieldMeta = getFieldMeta(fieldName);

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setFieldValue(fieldName, e.target.value.slice(0, fieldMaxLength));
        },
        [fieldMaxLength, fieldName, setFieldValue]
    );

    return {fieldMeta, handleChange, isPrivateHouse: privateHouseMeta.value as boolean, setFieldValue};
};
