import classNames from 'classnames';
import {Button, BUTTON_THEMES} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {selectActiveIdx} from 'modules/slider/selectors';
import {actionSetActiveIdx} from 'modules/slider/slice';
import {THEMES} from 'modules/theme/constants';
import {TTheme} from 'modules/theme/types';
import arrowIcon from 'public/icons/arrow.svg';
import {useCallback, useEffect} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    theme: TTheme;
    slidesLength: number;
    arrows?: boolean;
}

export const CustomNav: FC<IProps> = (props) => {
    const dispatch = useAppDispatch();
    const activeIndex = useAppSelector(selectActiveIdx);
    const {slidesLength, className, theme, arrows = true} = props;

    useEffect(() => {
        return () => {
            dispatch(actionSetActiveIdx(0));
        };
    }, [dispatch]);

    const onNext = useCallback(() => {
        dispatch(actionSetActiveIdx(activeIndex + 1));
    }, [activeIndex, dispatch]);

    const onPrev = useCallback(() => {
        dispatch(actionSetActiveIdx(activeIndex - 1));
    }, [activeIndex, dispatch]);

    return (
        <div
            className={classNames(style.nav, className, {
                [style.light]: theme === THEMES.light,
            })}
        >
            {arrows && (
                <Button
                    className={style.prev}
                    disabled={1 > activeIndex}
                    isNotAdaptive
                    isSquare
                    leftIcon={<SvgIcon svg={arrowIcon} />}
                    onClick={onPrev}
                    theme={BUTTON_THEMES.ghost}
                />
            )}

            <ul className={style.pagination}>
                {Array(slidesLength)
                    .fill(0)
                    .map((_, idx) => (
                        <li
                            className={classNames(style.bullet, {
                                [style.active]: activeIndex === idx,
                                [style.light]: theme === THEMES.light,
                            })}
                            key={idx}
                        ></li>
                    ))}
            </ul>
            {arrows && (
                <Button
                    className={style.next}
                    disabled={activeIndex + 1 >= slidesLength}
                    isNotAdaptive
                    isSquare
                    leftIcon={<SvgIcon svg={arrowIcon} />}
                    onClick={onNext}
                    theme={BUTTON_THEMES.ghost}
                />
            )}
        </div>
    );
};
