import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {useEffect} from 'react';
import {IMapState} from 'yandex-maps';

export const useYandexMapStateChange = (state: IMapState, api: YandexMapService['map']) => {
    useEffect(() => {
        if (!state.zoom) {
            return;
        }

        api?.setZoom(state.zoom);
    }, [api, state.zoom]);

    useEffect(() => {
        if (!state.center) {
            return;
        }

        api?.setCenter(state.center);
    }, [api, state.center]);
};
