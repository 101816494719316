import classNames from 'classnames';
import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {formatPriceToDefaultDivideDenominator} from 'core/helpers';
import {OnlinePaymentContext} from 'modules/checkout/context';
import {useRouter} from 'next/router';
import React, {memo, useCallback, useContext} from 'react';
import {THANKS_FOR_YOUR_ORDER_ROUTE} from 'routing/constants';

import style from './style.module.scss';

const columnStyle = getGridColumnStyles();

export const OnlinePaymentPage = memo(function OnlinePaymentPage() {
    const {order} = useContext(OnlinePaymentContext);
    const router = useRouter();

    const handleClick = useCallback(() => {
        router.push(`${THANKS_FOR_YOUR_ORDER_ROUTE}/${order?.id}`);
    }, [order?.id, router]);

    if (!order) {
        return (
            <Grid as="section" className={style.root} container>
                <PageHeaderTitle className={columnStyle}>
                    <Typography align="center" color="gray100" data-skeleton-item element="h2" variant="h2">
                        Заказ не найден
                    </Typography>
                </PageHeaderTitle>
            </Grid>
        );
    }

    return (
        <Grid as="section" className={style.root} container>
            <PageHeaderTitle className={columnStyle}>
                <Typography align="center" color="gray100" data-skeleton-item element="h2" variant="h2">
                    Спасибо за покупку!
                </Typography>
            </PageHeaderTitle>
            <Typography className={columnStyle} data-skeleton-item element="p" variant="p-strong">
                Номер вашего заказа: {order.id}
            </Typography>
            <Typography className={columnStyle} data-skeleton-item element="p" variant="p-strong">
                Сумма к оплате: {formatPriceToDefaultDivideDenominator(order.total.totalCost)}
            </Typography>

            <Typography className={columnStyle} data-skeleton-item element="p" variant="p-strong">
                Если вы выбрали самовывоз, пожалуйста, дождитесь оповещения от нас о том, что заказ готов, перед тем как
                ехать в магазин.
            </Typography>

            <Typography className={columnStyle} data-skeleton-item element="p" variant="p-strong">
                Способ оплаты: {order.paymentMethod.title}
            </Typography>

            <div className={classNames(style.button, columnStyle)}>
                <Button data-skeleton-item onClick={handleClick}>
                    Перейти к полате
                </Button>
            </div>
        </Grid>
    );
});
