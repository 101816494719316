import {AbstractApiRequestInterceptor} from 'core/abstract/AbstractApiRequestInterceptor';
import {TRequestOption, TRequestParameters} from 'core/types';

export class HeaderRequestInterceptor extends AbstractApiRequestInterceptor<
    TRequestParameters<TRequestOption, object>
> {
    public async intercept(
        requestOption: TRequestParameters<TRequestOption, object>
    ): Promise<TRequestParameters<TRequestOption, object>> {
        return requestOption;
    }
}
