import {EARTH_RADIUS} from 'modules/locations/constants';
import {IFindLocationParams, TCoords, TLocationWithDistance} from 'modules/locations/types';
import {Location} from 'new-models';

const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);

const radiansToDegrees = (radians: number): number => radians * (180 / Math.PI);

const centralSubtendedAngle = (userCoords: TCoords, locationCoords: TCoords) => {
    const locationXLatRadians = degreesToRadians(userCoords.latitude);
    const locationYLatRadians = degreesToRadians(locationCoords.latitude);
    return radiansToDegrees(
        Math.acos(
            Math.sin(locationXLatRadians) * Math.sin(locationYLatRadians) +
                Math.cos(locationXLatRadians) *
                    Math.cos(locationYLatRadians) *
                    Math.cos(degreesToRadians(Math.abs(userCoords.longitude - locationCoords.longitude)))
        )
    );
};

const greatCircleDistance = (angle: number): number => 2 * Math.PI * EARTH_RADIUS * (angle / 360);

export const distanceBetweenCoords = (userCoords: TCoords, locationCoords: TCoords): number =>
    greatCircleDistance(centralSubtendedAngle(userCoords, locationCoords));

const makeCoords = (location: Location): TCoords | null => {
    if (!location.latitude || !location.longitude) {
        return null;
    }
    return {
        latitude: Number(location?.latitude),
        longitude: Number(location?.longitude),
    };
};

export const distanceBetweenLocations = (userLocation: Location, location: Location): number | null => {
    const userCoords = makeCoords(userLocation);
    const locationCoords = makeCoords(location);

    if (!userCoords || !locationCoords) {
        return null;
    }

    return greatCircleDistance(centralSubtendedAngle(userCoords, locationCoords));
};

export const findLocation = ({locations, userCoords}: IFindLocationParams): TLocationWithDistance | undefined =>
    locations.reduce<TLocationWithDistance | undefined>((locationWithDistance, location) => {
        const locationCoords = makeCoords(location);

        if (!locationCoords || !userCoords?.longitude || !userCoords?.latitude) {
            return locationWithDistance;
        }

        const distance = distanceBetweenCoords(userCoords, locationCoords);
        return !locationWithDistance?.distance || distance < locationWithDistance?.distance
            ? {...location, distance}
            : locationWithDistance;
    }, undefined);
