import classNames from 'classnames';
import {Button, IProps as IButtonProps} from 'components/Button';
import {TPropsWithClassName} from 'core/types';
import {useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    buttonsProps?: IButtonProps[];
    childPlaceIdx?: number;
    withHorizontalPadding?: boolean;
}

export const ModalActions: FC<IProps> = ({
    className,
    buttonsProps,
    children,
    childPlaceIdx = buttonsProps?.length ?? 0,
    withHorizontalPadding = false,
}) => {
    const renderButtons = useMemo(
        () =>
            buttonsProps &&
            Boolean(buttonsProps?.length) &&
            buttonsProps.map((buttonProps) => <Button {...buttonProps}>{buttonProps.value}</Button>),
        [buttonsProps]
    );

    if (!renderButtons && !children) {
        return null;
    }

    return (
        <div
            className={classNames(style.root, className, {
                [style.withHorizontalPadding]: withHorizontalPadding,
            })}
        >
            {[
                ...(renderButtons || []).slice(0, childPlaceIdx),
                children,
                ...(renderButtons || []).slice(childPlaceIdx),
            ]}
        </div>
    );
};
