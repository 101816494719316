import {connectScript, IConnectScriptData} from 'core/helpers/script';
import {
    YANDEX_MAP_CENTER_DEFAULT,
    YANDEX_MAP_CONTROLS_DEFAULT,
    YANDEX_MAP_VENDOR_SCRIPT_ID,
    YANDEX_MAP_ZOOM_DEFAULT,
} from 'modules/yandex-map/constants';
import {IAddressCoords, IPlaceMarkEvent, IYandexGeocodeData} from 'modules/yandex-map/types';
import {
    Clusterer,
    GeoObject,
    IClustererOptions,
    IDataManager,
    IEvent,
    IMapState,
    IPlacemarkOptions,
    IPointGeometry,
    Map,
    Placemark,
} from 'yandex-maps';

type TConnectData = Pick<IConnectScriptData, 'onScriptAdd' | 'onScriptLoaded'>;

export class YandexMapService {
    private static readonly scriptUrl = `https://api-maps.yandex.ru/2.1/?lang=ru_RU`;
    private static readonly geocodeUrl = `https://geocode-maps.yandex.ru/1.x/?lang=ru_RU&format=json`;

    public readonly instance: typeof global.ymaps;
    public map: Map | undefined;

    constructor(instance: YandexMapService['instance']) {
        this.instance = instance;
    }

    public addPlaceMarkEventListener(
        placeMark: Placemark,
        event: string,
        callback: (e: IPlaceMarkEvent<object, IPointGeometry>) => void
    ) {
        placeMark.events.add([event], callback);
    }

    public removePlaceMarkEventListener(placeMark: Placemark, event: string, callback: (e: object | IEvent) => void) {
        placeMark.events.remove([event], callback);
    }

    public addPlaceMark(
        geometry: number[] | object | IPointGeometry,
        properties: object | IDataManager,
        options?: IPlacemarkOptions
    ) {
        const placeMark = new this.instance.Placemark(geometry, properties, options);

        this.map?.geoObjects.add(placeMark);

        return placeMark;
    }

    public removeAll() {
        this.map?.geoObjects.removeAll();
    }

    public removePlaceMark(placeMark: Placemark) {
        this.map?.geoObjects.remove(placeMark);
    }

    public render(selector: string, mapState: IMapState): void {
        if (this.map) {
            this.destroy();
        }

        this.map = new this.instance.Map(selector, {
            center: YANDEX_MAP_CENTER_DEFAULT,
            controls: YANDEX_MAP_CONTROLS_DEFAULT,
            zoom: YANDEX_MAP_ZOOM_DEFAULT,

            ...mapState,
        });
    }

    public destroy() {
        this.map?.destroy();
    }

    public static connect(data: TConnectData): void {
        connectScript(YandexMapService.scriptUrl, {
            id: YANDEX_MAP_VENDOR_SCRIPT_ID,
            ...data,
        });
    }

    public async getGeocodeData(address: string): Promise<IAddressCoords | null> {
        const response = await fetch(
            `${YandexMapService.geocodeUrl}&apikey=${process.env.NEXT_PUBLIC_YANDEX_GEOCODE_API_KEY}&geocode=${address}`
        );
        const data = await response.json();
        const geocodeData: IYandexGeocodeData = data.response;

        if (!geocodeData.GeoObjectCollection.featureMember.length) {
            return null;
        }

        const latLon = {
            latitude: geocodeData.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')[1],
            longitude: geocodeData.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ')[0],
        };

        return latLon;
    }

    public addClusterer(geoObjects: GeoObject[], options: IClustererOptions) {
        const clusterer = new this.instance.Clusterer(options);
        clusterer.remove(geoObjects);
        clusterer.add(geoObjects);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.map?.geoObjects.add(clusterer);
        return clusterer;
    }

    public removeClusterer(clusterer: Clusterer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.map?.geoObjects.remove(clusterer);
    }
}
