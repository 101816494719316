import {MIN_DESKTOP_WIDTH} from 'core/constants';
import {CategoriesCard} from 'modules/categories/components/CategoryGallery/Card';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {IProps as ISliderProps, SliderWithExternalButton} from 'modules/slider/components/SliderWithExternalButton';
import {SLIDER_DIRECTION, SPACE_BETWEEN} from 'modules/slider/constants';
import {CategoryShort} from 'new-models';
import React, {memo, ReactNode, useCallback, useMemo} from 'react';

import style from './style.module.scss';

const DEFAULT_BREAKPOINT_SLIDER: ISliderProps['breakpoints'] = {
    [0]: {
        spaceBetween: SPACE_BETWEEN.previews,
    },
    [MIN_DESKTOP_WIDTH]: {
        spaceBetween: SPACE_BETWEEN.default,
    },
};

interface IProps {
    list: CategoryShort[];
    catalogRoute: string;
    offsetCoefficient?: number;
}

export const CategoryGallery = memo<IProps>(({list, catalogRoute}) => {
    const getCardHref = useCallback(
        (code: CategoryShort['code'], id: CategoryShort['code']) =>
            `${catalogRoute}/${CategoryService.makeCategoryUrl(code, id)}/`,
        [catalogRoute]
    );

    const slides = useMemo<ReactNode[]>(() => {
        return list.map((item, index) => {
            return (
                <CategoriesCard className={style.slide} href={getCardHref(item.code, item.id)} key={index} {...item} />
            );
        });
    }, [getCardHref, list]);

    const renderContent = useMemo<ReactNode>(() => {
        return (
            <SliderWithExternalButton
                autoplay={false}
                breakpoints={DEFAULT_BREAKPOINT_SLIDER}
                direction={SLIDER_DIRECTION.horizontal}
                isNavigation
                slidesPerView="auto"
                spaceBetween={SPACE_BETWEEN.default}
            >
                {slides}
            </SliderWithExternalButton>
        );
    }, [slides]);

    return <div className={style.root}>{renderContent}</div>;
});
