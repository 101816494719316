import classNames from 'classnames';
import {MAX_COUNT_DISPLAY} from 'core/constants';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {memo, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire {
    count?: number;
    countClassName?: string;
}

export const IconElement = memo<IProps>(function IconElement({children, className, count, countClassName}) {
    const renderCount = useMemo<number | string>(() => {
        if (!count) {
            return 0;
        }

        return count > MAX_COUNT_DISPLAY ? `${MAX_COUNT_DISPLAY}+` : count;
    }, [count]);

    return (
        <div className={classNames(style.iconElement, className)}>
            {children}
            {Boolean(count) && (
                <span className={classNames(style.count, countClassName, 'icon-element-count')}>{renderCount}</span>
            )}
        </div>
    );
});
