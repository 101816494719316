import {MediaImage, MediaTypeEnum, ProductDetail} from 'new-models';
import {useMemo} from 'react';

export const useVisualProductImages = (product?: ProductDetail): MediaImage[] => {
    return useMemo(() => {
        if (!product?.media) {
            return [];
        }

        // TODO: for the time of mixing two mock servers
        // let imagesArray: Image[] = [];
        // const visualImages = product?.visualImages;
        // if (visualImages?.length) {
        //     const mainVisualImage = visualImages.find(({path}) => path.includes(INTERIOR_LABEL));
        //     const withoutMainVisualImageArray = visualImages.filter(({path}) => !path.includes(INTERIOR_LABEL));
        //     const sortedArray = [mainVisualImage, ...withoutMainVisualImageArray];
        //
        //     imagesArray = Boolean(mainVisualImage) ? (sortedArray as Image[]) : visualImages;
        // }

        return product.media.filter(({type}) => type === MediaTypeEnum.Image) as MediaImage[];
    }, [product?.media]);
};
