import {TAppRootState, TSelect} from 'core/redux/types';
import {actionSetRelatedCollectionList, collectionsModuleName, collectionsSlice} from 'modules/collections/slice';
import {TCollectionState} from 'modules/collections/types';
import {selectIsLoading} from 'modules/loader/selectors';
import {createFilterSelectors} from 'plugins/modules/filter/selectors';
import {createListingSelectors} from 'plugins/modules/listing/selectors';
import {createPaginationSelectors} from 'plugins/modules/pagination/selectors';

export const select: TSelect<TCollectionState> = (state) => state[collectionsModuleName];

export const selectPopularCollectionList = (state: TAppRootState): TCollectionState['popularCollectionList'] =>
    select(state).popularCollectionList;

export const selectRelatedCollectionList = (state: TAppRootState): TCollectionState['relatedCollection'] =>
    select(state).relatedCollection;

export const selectIsRelatedCollectionListLoading = (state: TAppRootState): boolean | undefined =>
    selectIsLoading(actionSetRelatedCollectionList.type)(state);

export const {selectPageSize, selectPageCount, selectPage, selectPaginationType, selectPageSizeList} =
    createPaginationSelectors<TCollectionState>(select);

export const {
    selectComboFilterList,
    selectFilterList,
    selectAppliedRangeFilterList,
    selectAppliedChooseFilterByVariants,
    selectIsFilterPanelVisible,
} = createFilterSelectors<TCollectionState>(select);

export const {selectListType, selectList, selectIsListingLoading, selectIsListingPartiallyLoading} =
    createListingSelectors<TCollectionState>(select, collectionsSlice);
