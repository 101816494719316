import {Anchor} from 'components/Anchor';
import {MediaHarvester} from 'components/MediaHarvester';
import {Typography} from 'components/Typography';
import {generateMediaOptions} from 'core/helpers/imageResize';
import {HomePageContext} from 'modules/home-page/context';
import {MediaTypeEnum} from 'new-models';
import Link from 'next/link';
import React, {memo, ReactNode, useContext, useMemo} from 'react';

import style from './style.module.scss';

export const BannerRightBlock = memo(function BannerRightBlock() {
    const {homeBannerSecondSlideList} = useContext(HomePageContext);

    const {media, title, buttonMessage, isLight, link} = homeBannerSecondSlideList[0];

    const renderContent = useMemo<ReactNode>(
        () => (
            <>
                <MediaHarvester
                    className={style.image}
                    media={media}
                    options={generateMediaOptions({
                        resizeMapKey: 'homePageSecondaryBanner',
                        type: MediaTypeEnum.ImageAdaptive,
                    })}
                />
                {Boolean(title || buttonMessage) && (
                    <div className={style.content}>
                        <div className={style.contentContainer}>
                            {title && (
                                <Typography
                                    className={style.title}
                                    color={isLight ? 'gray100' : 'white100'}
                                    element="h4"
                                    variant="h4"
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            // eslint-disable-next-line @typescript-eslint/naming-convention
                                            __html: title,
                                        }}
                                    />
                                </Typography>
                            )}
                            {buttonMessage && (
                                <Anchor
                                    as="button"
                                    icon="right"
                                    mode={isLight ? 'dark' : 'light'}
                                    size="large"
                                    theme="standalone"
                                >
                                    {buttonMessage}
                                </Anchor>
                            )}
                        </div>
                    </div>
                )}
            </>
        ),
        [buttonMessage, isLight, media, title]
    );

    return link.value ? (
        <Link href={link.value}>
            <a>{renderContent}</a>
        </Link>
    ) : (
        renderContent
    );
});
