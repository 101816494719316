import {ProductCardService} from 'modules/product-card/services/ProductCardService';
import {ProductShort} from 'new-models';
import {useMemo} from 'react';
import {PRODUCT_ROUTE} from 'routing/constants';

export const useProductUrl = ({id, code}: Pick<ProductShort, 'id' | 'code'>) => {
    return useMemo<string>(() => {
        return `${PRODUCT_ROUTE}/${ProductCardService.makeProductCardUrl(code, id)}`;
    }, [code, id]);
};
