import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {selectIsLocationSelected} from 'modules/locations/selectors';
import {changeLocation} from 'modules/locations/thunks';
import {Location} from 'new-models';
import {useCallback} from 'react';

export const useSelectLocation = (): ((location: Location) => void) => {
    const dispatch = useAppDispatch();
    const isLocationSelected = useAppSelector(selectIsLocationSelected);

    return useCallback(
        (location: Location) => {
            if (isLocationSelected) {
                return;
            }

            dispatch(changeLocation(location));
        },
        [dispatch, isLocationSelected]
    );
};
