import {MINDBOX_OPERATIONS} from 'modules/analytics/constants';
import {
    calcPrice,
    makeMindboxCartItem,
    makeMindboxCategory,
    makeMindboxCustomer,
    makeMindBoxProduct,
} from 'modules/analytics/helpers';
import {MindBoxScriptService} from 'modules/analytics/services/MindBoxScriptService';
import {IMethodParams, TMindboxCartProduct} from 'modules/analytics/types';
import {Category, ProductBase, Profile} from 'new-models';

export class MindBoxService extends MindBoxScriptService {
    public viewProduct(product: ProductBase) {
        const methodParams: IMethodParams = {
            data: {
                viewProduct: {
                    price: calcPrice(product.price),
                    product: makeMindBoxProduct(product),
                },
            },
            operation: MINDBOX_OPERATIONS.viewProduct,
        };
        this.post('async', methodParams);
    }

    public viewProducts(products: ProductBase[]) {
        const mindboxProducts = products.map(makeMindBoxProduct);

        const methodParams: IMethodParams = {
            data: {
                viewProduct: {
                    productGroup: mindboxProducts,
                },
            },
            operation: MINDBOX_OPERATIONS.viewProduct,
        };
        this.post('async', methodParams);
    }

    public viewCategory({id}: Pick<Category, 'id'>) {
        const methodParams: IMethodParams = {
            data: {
                viewProductCategory: {
                    product: makeMindboxCategory({id}),
                },
            },
            operation: MINDBOX_OPERATIONS.viewCategory,
        };
        this.post('async', methodParams);
    }

    public addToCart(cartItems: TMindboxCartProduct[], profile: Profile) {
        const mindboxProducts = cartItems.map(makeMindboxCartItem);

        const methodParams: IMethodParams = {
            data: {
                customer: makeMindboxCustomer(profile),
                productList: mindboxProducts,
            },
            operation: MINDBOX_OPERATIONS.addToCart,
        };
        this.post('async', methodParams);
    }

    public deleteFromCart(profile: Profile) {
        const methodParams: IMethodParams = {
            data: {
                customer: makeMindboxCustomer(profile),
            },
            operation: MINDBOX_OPERATIONS.deleteFromCart,
        };

        this.post('async', methodParams);
    }

    public addToFavouriteProducts(products: ProductBase[], profile: Profile) {
        const mindboxProducts = products.map(makeMindBoxProduct);

        const methodParams: IMethodParams = {
            data: {
                customer: makeMindboxCustomer(profile),
                productList: mindboxProducts,
            },
            operation: MINDBOX_OPERATIONS.addToFavourite,
        };
        this.post('async', methodParams);
    }

    public deleteFromFavourite(profile: Profile) {
        const methodParams: IMethodParams = {
            data: {
                customer: makeMindboxCustomer(profile),
            },
            operation: MINDBOX_OPERATIONS.deleteFromFavourite,
        };

        this.post('async', methodParams);
    }
}

export const mindBoxService = new MindBoxService();
