import {Typography} from 'components/Typography';
import {ProductDetail} from 'new-models';
import React from 'react';

import style from './style.module.scss';

export const ProductCardTitle: FC<Partial<ProductDetail>> = (props) => {
    const {title} = props ?? {};

    return (
        <Typography className={style.root} data-skeleton-item element="h1" variant="h4">
            {title}
        </Typography>
    );
};
