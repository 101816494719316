import classNames from 'classnames';
import {BlockTitle} from 'components/BlockTitle';
import {ShowMoreTitleLink} from 'components/ShowMoreTitleLink';
import {TPropsWithClassName} from 'core/types';
import React, {memo, ReactNode, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    href?: string;
    title: string;
    linkText?: ReactNode;
    actionNode?: ReactNode;
    isIgnoreLinkAction?: boolean;
    subtitle?: ReactNode;
}

export const BlockTitleWithAction = memo<IProps>(function BlockTitleWithAction({
    href,
    title,
    linkText,
    className,
    actionNode,
    isIgnoreLinkAction = false,
    subtitle,
}) {
    const actionRender = useMemo(
        () =>
            actionNode ||
            (linkText && href && (
                <ShowMoreTitleLink href={href} isIgnoreLinkAction={isIgnoreLinkAction}>
                    {linkText}
                </ShowMoreTitleLink>
            )),
        [actionNode, href, isIgnoreLinkAction, linkText]
    );

    return (
        <BlockTitle
            actionNode={actionRender}
            className={classNames(style.title, className)}
            data-skeleton-item
            subtitle={subtitle}
            title={title}
        />
    );
});
