import {IHttpClientResponse, THttpMethod, TJson, TRequestParameters} from 'core/types';

export abstract class AbstractHttpClient<VendorClient = unknown, RequestOptions = unknown, RawRs = unknown> {
    public readonly vendorClient: VendorClient;

    protected constructor(vendorClient: VendorClient) {
        this.vendorClient = vendorClient;
    }

    public request<Body extends TJson = TJson, Rs extends TJson = TJson>(
        method: THttpMethod,
        {url, options, body}: TRequestParameters<RequestOptions, Body>
    ) {
        switch (method) {
            case 'GET': {
                return this.get<Rs>({options, url});
            }
            case 'HEAD': {
                return this.head<Rs>({options, url});
            }
            case 'POST': {
                return this.post<Body, Rs>(<TRequestParameters<RequestOptions, Body>>{body, options, url});
            }
            case 'PUT': {
                return this.put<Body, Rs>(<TRequestParameters<RequestOptions, Body>>{body, options, url});
            }
            case 'PATCH': {
                return this.put<Body, Rs>(<TRequestParameters<RequestOptions, Body>>{body, options, url});
            }
            case 'DELETE': {
                return this.delete<Rs>({options, url});
            }

            default: {
                return this.get<Rs>({options, url});
            }
        }
    }

    public abstract post<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<RequestOptions, Body>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract put<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<RequestOptions, Body>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract patch<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<RequestOptions, Body>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract deleteWithBody<Body extends TJson = TJson, Rs extends TJson = TJson>({
        url,
        options,
        body,
    }: TRequestParameters<RequestOptions, Body>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract delete<Rs extends TJson = TJson>({
        url,
        options,
    }: TRequestParameters<RequestOptions>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract head<Rs extends TJson = TJson>({
        url,
        options,
    }: TRequestParameters<RequestOptions>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;

    public abstract get<Rs extends TJson = TJson>({
        url,
        options,
    }: TRequestParameters<RequestOptions>): Promise<IHttpClientResponse<Rs, TJson, RawRs>>;
}
