import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {ICategoryNode} from 'modules/categories/models/CategoryTree';
import {useGetMediaHarvesterOptionsAdaptiveImage} from 'modules/common/hooks/useGetMediaHarvesterOptionsAdaptiveImage';
import {selectBannerListByPlace} from 'modules/common/selectors';
import {IImageAdaptiveMap} from 'modules/images/types';
import {ProductListDesktopHeaderBanner} from 'modules/products/components/ProductListDesktopHeaderBanner';
import {PromoPlace} from 'new-models';
import React, {ReactNode} from 'react';

interface IProps {
    className?: string;
    withBanners?: boolean;
    category?: ICategoryNode;
    urlScreenVariant?: IImageAdaptiveMap['urlScreenVariant'];
}

export const useHeaderBannerOfListing = ({
    className,
    withBanners = true,
    category,
    urlScreenVariant,
}: IProps = {}): ReactNode => {
    const banner = useAppSelector(selectBannerListByPlace(category?.code as Partial<PromoPlace>))[0];

    const options = useGetMediaHarvesterOptionsAdaptiveImage(urlScreenVariant);

    if (!withBanners || !banner) {
        return null;
    }

    return <ProductListDesktopHeaderBanner className={className} data-skeleton-item {...banner} options={options} />;
};
