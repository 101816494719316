import {IProps as ISegmentedControlProps} from 'components/SegmentedControl';
import {TCheckedListCondition} from 'modules/cart/types';
import {BasketDataResponse, BasketItem} from 'new-models';
import {createContext, MouseEventHandler} from 'react';

interface ICartContextProps {
    isCartLoading?: boolean;
    cartProductsList: BasketDataResponse['products'];
    onActionClick?: MouseEventHandler<HTMLButtonElement>;
    onChangeIsLegalEntity?: ISegmentedControlProps['onChange'];
    isLegalEntity: boolean;
    checkedListCondition: TCheckedListCondition;
    countSelected: number;
    divideByAvailability: BasketItem[][];
}

export const CartContext = createContext<ICartContextProps>({} as ICartContextProps);
