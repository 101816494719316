import {BACK_BUTTON_THEMES} from 'components/BackButton';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {PromoDescription} from 'modules/promo/components/PromoDescription';
import {getPromoActivePeriod} from 'modules/promo/helpers';
import {ActionDetail} from 'new-models';
import React, {FC, memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    data: ActionDetail;
}

export const PromoPage: FC<IProps> = memo(function PromoPage({data}) {
    const {activeFrom, activeTo} = data;

    const subtitle = getPromoActivePeriod(activeFrom, activeTo, {isBigTitle: true});

    return (
        <div className={style.root}>
            <PageHeaderTitle theme={BACK_BUTTON_THEMES.ghost} withContainer={false}>
                <div className={style.container}>
                    <Typography data-skeleton-item element="h1" variant="h2">
                        Акция "{data.title}"
                    </Typography>
                    {subtitle && (
                        <Typography color="secondary100" variant="h7">
                            {subtitle}
                        </Typography>
                    )}
                </div>
            </PageHeaderTitle>
            <PromoDescription data={data} />
        </div>
    );
});
