import {TPropsWithChildrenRequire} from 'core/types';
import {IRouteContextProps, RouteContext} from 'modules/router/context';
import React, {FC} from 'react';

interface IProps extends IRouteContextProps, TPropsWithChildrenRequire {}

export const RouteProvider: FC<IProps> = ({
    matchedGroups,
    component,
    children,
    prevPath,
    matched,
    allMatches,
    defaultQuery,
}) => {
    return (
        <RouteContext.Provider value={{allMatches, component, defaultQuery, matched, matchedGroups, prevPath}}>
            {children}
        </RouteContext.Provider>
    );
};
