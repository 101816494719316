import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {CategoryService} from 'modules/categories/services/CategoryService';
import {MODALS} from 'modules/modals/constants';
import {hideModal, showModal} from 'modules/modals/thunks';
import {selectIsPortalVisible} from 'modules/portal/selectors';
import {CategoryShort} from 'new-models';
import {useCallback} from 'react';
import {CATALOG_ROUTE} from 'routing/constants';

export const useSearch = () => {
    const dispatch = useAppDispatch();

    const isSearchModalVisible = useAppSelector(selectIsPortalVisible(MODALS.SEARCH_MOBILE.name));

    const handleCloseSearchModal = useCallback(() => {
        if (isSearchModalVisible) {
            dispatch(hideModal(MODALS.SEARCH_MOBILE.name));
            return;
        }

        dispatch(hideModal(MODALS.CATALOG_MENU.name));
    }, [dispatch, isSearchModalVisible]);

    const handleBarCodeScannerClick = useCallback(() => {
        dispatch(showModal(MODALS.BARCODE_SCANNER.name));
    }, [dispatch]);

    const handleSearchFieldFocus = useCallback(() => {
        if (isSearchModalVisible) {
            return;
        }

        dispatch(showModal(MODALS.SEARCH_MOBILE.name));
    }, [dispatch, isSearchModalVisible]);

    const makeSearchCategoryUrl = useCallback(
        (code: CategoryShort['code'], id: CategoryShort['id']): string =>
            `${CATALOG_ROUTE}/${CategoryService.makeCategoryUrl(code, id)}`,
        []
    );

    return {
        handleBarCodeScannerClick,
        handleCloseSearchModal,
        handleSearchFieldFocus,
        makeSearchCategoryUrl,
    };
};
