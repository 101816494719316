import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {BrandListCard} from 'modules/brand/components/BrandListCard';
import {TAlphabetData} from 'modules/brand/types';
import React, {memo} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

interface IProps {
    alphabetData: TAlphabetData;
}

export const BrandListView = memo<IProps>(function BrandListView({alphabetData}) {
    return (
        <Grid as="ul" className={style.root}>
            {Object.entries(alphabetData).map(([letter, brandList]) => (
                <li
                    className={classNames(columnStyle, style.listItem)}
                    data-intersection-mark={`#${letter}`}
                    data-scroll-mark={`#${letter}`}
                    key={letter}
                >
                    <BrandListCard brandList={brandList} title={letter} />
                </li>
            ))}
        </Grid>
    );
});
