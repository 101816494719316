import {SortUrlService} from 'plugins/modules/sorting/services/SortUrlService';
import {ISortingState} from 'plugins/modules/sorting/types';
import {createContext} from 'react';

export interface ISortingContext extends ISortingState {
    urlService?: SortUrlService;
}

export const SortingContext = createContext<ISortingContext>({
    sortingList: [],
});
