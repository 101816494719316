import {TOKEN_KEY} from 'core/constants';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {CookieService} from 'core/services/CookieService';
import {IHttpClientResponse, TJson, TResponse} from 'core/types';
// import jwtDecode from 'jwt-decode';
// import {MODAL_STATES, STRATEGIES, VIEWS} from 'modules/authentication/constants';
// import {AuthContext} from 'modules/authentication/context';
// import {useModalParamsHandler} from 'modules/authentication/hooks/handlers/useModalParamsHandler';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {useCallback} from 'react';

interface IOptions {
    isScriptCompleted?: boolean;
}

export const useSuccessExecutionHandler = () => {
    const dispatch = useAppDispatch();
    // const modalParamsHandler = useModalParamsHandler();

    // const {modalParams} = useContext(AuthContext);

    return useCallback(
        async (response: IHttpClientResponse<TResponse<unknown>, TJson, Response>, options?: IOptions) => {
            if (options?.isScriptCompleted && response.ok) {
                dispatch(hideModal(MODALS.MODAL_DIALOG.name));
                return;
            }

            const cookieService = CookieService.getInstance();

            const privateToken = response.headers.get(TOKEN_KEY);

            if (!privateToken) {
                return;
            }

            // const jwtPayload = jwtDecode<IJwtPayload>(privateToken);

            await cookieService.setItem(TOKEN_KEY, privateToken);

            // if (jwtPayload.reg) {
            //     modalParamsHandler({
            //         isBackButton: true,
            //         modalView:
            //             modalParams?.modalState === MODAL_STATES.auth ? VIEWS.registration : VIEWS.registrationLegal,
            //         strategy: STRATEGIES.registrationBySms,
            //     });
            //     return;
            // }

            dispatch(hideModal(MODALS.MODAL_DIALOG.name));
        },
        [dispatch]
    );
};
