import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {StoreShort, WorkingHours} from 'new-models';
import React, {forwardRef, memo} from 'react';

import style from './style.module.scss';

interface IProps {
    data: StoreShort;
    isActive?: boolean;
    onClick?: (value: StoreShort) => void;
}

export const ShopCard = memo(
    forwardRef<HTMLDivElement, IProps>(function ShopCard({isActive, data, onClick}, ref) {
        const {address, phones, schedule, title} = data;

        const handleClick = () => {
            onClick?.(data);
        };

        return (
            <div
                className={classNames(style.root, isActive ? style.active : null)}
                onClick={handleClick}
                ref={ref}
                role="button"
            >
                <Typography variant="p-strong">{title}</Typography>
                <Typography className={style.address} color="black-a50" element="address">
                    {address}
                </Typography>
                {schedule && (
                    <ul className={style.scheduleBlock}>
                        {schedule.map(({days, from, to}: WorkingHours) => {
                            const time = `${days}: ${from}-${to}`;

                            return (
                                <li key={time}>
                                    <Typography element="time">{time}</Typography>
                                </li>
                            );
                        })}
                    </ul>
                )}

                {phones && (
                    <ul className={style.phones}>
                        {phones.split(',').map((phone: string) => {
                            return (
                                <li key={phone}>
                                    <a href={`tel:${phone}`}>
                                        <Typography color="primary60">{phone}</Typography>
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    })
);
