import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IUserState} from 'modules/user/types';

export const userModuleName = 'user';

const initialState: IUserState = {
    userData: null,
};

export const userSlice = createSlice({
    initialState,
    name: userModuleName,
    reducers: {
        actionSetAuthorizedUserEmailVerified(
            state: IUserState,
            {payload}: PayloadAction<NonNullable<IUserState['userData']>['isEmailVerified']>
        ) {
            if (!state.userData) {
                return;
            }

            state.userData.isEmailVerified = payload;
        },
        actionSetUser(state: IUserState, {payload}: PayloadAction<IUserState['userData']>) {
            state.userData = payload;
        },
        actionUpdateUserEmail(state: IUserState, {payload}: PayloadAction<string>) {
            if (!state.userData) {
                return;
            }
            state.userData.email = payload;
        },
        actionUpdateUserPhone(state: IUserState, {payload}: PayloadAction<string>) {
            if (!state.userData) {
                return;
            }
            state.userData.phone = payload;
        },
    },
});

export const {
    actions: {actionSetUser, actionUpdateUserEmail, actionUpdateUserPhone},
} = userSlice;

export const {reducer: userReducer} = userSlice;
