import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {PageSectionBlock} from 'modules/common/components/PageSectionBlock';
import {selectIsSimilarProductsLoading, selectSimilarProductList} from 'modules/products/selectors';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {SliderWithBanner} from 'modules/slider/components/SliderWithBanner';
import React, {FC} from 'react';

export const SimilarProductsSlider: FC<TPropsWithClassName> = ({className}) => {
    const isLoading = useAppSelector(selectIsSimilarProductsLoading);
    const similarProductList = useAppSelector(selectSimilarProductList);

    if (!similarProductList.length) {
        return null;
    }

    return (
        <PageSectionBlock className={className} title="Похожие товары" withoutPaddingTop>
            <SkeletonWrapper disabled={!isLoading}>
                <SliderWithBanner products={similarProductList} />
            </SkeletonWrapper>
        </PageSectionBlock>
    );
};
