import classNames from 'classnames';
import {MediaHarvester} from 'components/MediaHarvester';
import {PageHead} from 'components/PageHead';
import {PageHeaderTitle} from 'components/PageHeaderTitle';
import {Typography} from 'components/Typography';
import {useSeoMetaData} from 'core/hooks/useSeoMetaData';
import {FranchiseForm} from 'modules/franchise/components/FranchiseForm';
import {FranchiseContext} from 'modules/franchise/context';
import React, {useContext, useMemo} from 'react';
import {PAGES} from 'routing/constants';

import style from './style.module.scss';

export const FranchisePage: FC = () => {
    const content = useContext(FranchiseContext);

    const renderContent = useMemo(() => {
        if (!content?.content) {
            return null;
        }

        return (
            <div
                className={style.description}
                // eslint-disable-next-line @typescript-eslint/naming-convention
                dangerouslySetInnerHTML={{__html: content?.content}}
                data-skeleton-item
            />
        );
    }, [content?.content]);

    const seoMetaData = useSeoMetaData({
        seoMetaData: content?.seoMetaData,
        titles: [content?.title],
    });

    return (
        <>
            <PageHead {...seoMetaData} />
            <section className={classNames(style.franchisePage)}>
                <PageHeaderTitle theme="ghost" withContainer={false}>
                    <Typography color="gray100" data-skeleton-item element="h1" variant="h1">
                        {PAGES.FRANCHISE_PAGE.title}
                    </Typography>
                </PageHeaderTitle>
                {renderContent}
                <div className={classNames(style.content, {[style.onlyForm]: !content?.image})}>
                    <div className={style.leftSide}>
                        <FranchiseForm />
                    </div>
                    {content?.image && (
                        <div className={style.rightSide} data-skeleton-item>
                            <MediaHarvester media={content?.image} />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
};
