import {TPaginationType} from 'plugins/modules/pagination/types';

export const PAGINATION_TYPE: Record<string, TPaginationType> = {
    ACCUMULATIVE: 'accumulative',
    AUTO: 'auto',
} as const;

export const PAGE_SIZE = 36;

export const MAX_PERCENT = 100;

export const DEFAULT_SCROLL_OFFSET = 400;

export const DEFAULT_PLURAL_STORE_LIST = ['магазин', 'магазина', 'магазинов'];

export const DEFAULT_PAGE_SIZE = 24;

export const AVAILABLE_PAGE_SIZE_LIST = [12, DEFAULT_PAGE_SIZE, 36, 48, 72] as const;
