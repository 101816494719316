import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {BrandBase} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    brandList: BrandBase[];
    title: string;
}

export const BrandListCard = memo<IProps>(function BrandListCard({brandList, className, title}) {
    return (
        <div className={classNames(style.root, className)}>
            <Typography
                className={style.blockTitleCard}
                color="primary50"
                data-skeleton-item
                element="div"
                variant="h2-bold"
            >
                {title}
            </Typography>
            <ul className={style.blockTitleBrand}>
                {brandList.map(({title: brandTitle}) => (
                    <li className={style.item} data-skeleton-item key={brandTitle}>
                        <Typography className={style.titleBrand} color="gray60" variant="p-medium">
                            {brandTitle}
                        </Typography>
                    </li>
                ))}
            </ul>
        </div>
    );
});
