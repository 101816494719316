/**
 * https://github.com/ultimate-pagination/react-ultimate-pagination/blob/master/src/react-ultimate-pagination.js
 * Вот так. Потому что, проблемы с зависимостями у пакета
 */

import {IPaginationProps} from 'components/Pagination';
import {TPropsWithClassName} from 'core/types';
import React, {FC} from 'react';
import {getPaginationModel, ITEM_TYPES} from 'ultimate-pagination';

// eslint-disable-next-line id-length
const renderItemComponentFunctionFactory = (
    itemTypeToComponent: Record<keyof typeof ITEM_TYPES, FC<IPaginationProps>>,
    currentPage: number,
    onChange: (value: number) => void
) => {
    const onItemClickFunctionFactory = ({value, isDisabled}: {value: number; isDisabled: boolean}) => {
        return () => {
            if (!isDisabled && onChange && currentPage !== value) {
                onChange(value);
            }
        };
    };

    return function renderItemComponent(props: {
        isActive: boolean;
        value: number;
        isDisabled: boolean;
        type: keyof typeof ITEM_TYPES;
        key: number;
    }) {
        const ItemComponent = itemTypeToComponent[props.type];
        const onItemClick = onItemClickFunctionFactory(props);
        const {key, ...resetProps} = props;
        return <ItemComponent key={key} onClick={onItemClick} {...resetProps} />;
    };
};

interface IProps extends TPropsWithClassName {
    currentPage: number;
    totalPages: number;
    boundaryPagesRange?: number;
    siblingPagesRange?: number;
    hideEllipsis?: boolean;
    hidePreviousAndNextPageLinks?: boolean;
    hideFirstAndLastPageLinks?: boolean;
    onChange: (value: number) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const createUltimatePagination = ({
    itemTypeToComponent,
}: {
    itemTypeToComponent: Record<keyof typeof ITEM_TYPES, FC<IPaginationProps>>;
}): FC<IProps> => {
    return (props: IProps) => {
        const {
            currentPage,
            totalPages,
            boundaryPagesRange,
            siblingPagesRange,
            hideEllipsis,
            hidePreviousAndNextPageLinks,
            hideFirstAndLastPageLinks,
            onChange,
            ...restProps
        } = props;

        const paginationModel = getPaginationModel({
            boundaryPagesRange,
            currentPage,
            hideEllipsis,
            hideFirstAndLastPageLinks,
            hidePreviousAndNextPageLinks,
            siblingPagesRange,
            totalPages,
        });
        const renderItemComponent = renderItemComponentFunctionFactory(itemTypeToComponent, currentPage, onChange);
        return (
            <div {...restProps}>
                {paginationModel.map((itemModel) => {
                    let isDisabled = false;

                    if ('NEXT_PAGE_LINK' === itemModel.type && currentPage >= totalPages) {
                        isDisabled = true;
                    }

                    if ('PREVIOUS_PAGE_LINK' === itemModel.type && 1 === currentPage) {
                        isDisabled = true;
                    }

                    return renderItemComponent({
                        ...itemModel,
                        isDisabled,
                    });
                })}
            </div>
        );
    };
};

export {ITEM_TYPES};
