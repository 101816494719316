import {SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY} from 'modules/products/constants';
import {ILocalStorageSafeProductData} from 'modules/products/types';
import {useCallback, useEffect, useState} from 'react';

type TUseProductRestore = () => {
    handleScrollComplete: () => void;
    scrollId: string;
};
export const useProductRestore: TUseProductRestore = () => {
    const [productScrollId, setProductScrollId] = useState<string>('');

    useEffect(() => {
        const restoringProductData: ILocalStorageSafeProductData = JSON.parse(
            localStorage.getItem(SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY) || '{}'
        );

        if (!restoringProductData.productId) {
            return;
        }

        setProductScrollId(restoringProductData.productId);
    }, []);

    const handleScrollComplete = useCallback(() => {
        localStorage.removeItem(SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY);
        setProductScrollId('');
    }, []);

    return {
        handleScrollComplete,
        scrollId: productScrollId,
    };
};
