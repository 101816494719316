import {AbstractControllerException} from 'core/abstract/AbstractControllerException';
import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {TResponse, TServerError} from 'core/types';
import {actionSetError} from 'modules/errors/slice';
import {actionLoaderSetHidden} from 'modules/loader/slice';
import {
    actionClearSearchData,
    actionSetList,
    actionSetSearchCategoryList,
    actionSetSearchQuery,
    actionSetSearchRecommendationsList,
    actionSetSearchSuggestions,
} from 'modules/search-popup/slice';
import {CategoryBase, ProductShort, SearchResultDataResponse, SearchSuggestDataResponse} from 'new-models';

export const getSearchResultsPopup =
    (query: URLSearchParams): TAppThunk<boolean> =>
    async (dispatch, _, {searchService}) => {
        try {
            const data = await dispatch(
                requestAction<TResponse<SearchSuggestDataResponse>>({
                    requestCb: () => searchService.getSearchSuggestions(query),
                    type: actionSetSearchQuery.type,
                })
            );

            dispatch(actionSetSearchRecommendationsList(data.productsHits));
            dispatch(actionSetSearchCategoryList(data.categories));
            dispatch(actionSetSearchSuggestions(data.suggestions));
            dispatch(actionSetList(data.products));
            dispatch(actionLoaderSetHidden(actionSetSearchQuery.type));
            return true;
        } catch (errors) {
            if (AbstractControllerException.isIt(errors)) {
                throw errors;
            }

            if (errors && (errors as TServerError[]).length) {
                dispatch(actionClearSearchData());
                dispatch(actionSetError({errors: errors as TServerError[], moduleName: actionSetSearchQuery.type}));
                dispatch(actionLoaderSetHidden(actionSetSearchQuery.type));
            }

            return true;
        }
    };

export const setSearchResultsPopup =
    (categories: CategoryBase[] = [], products: ProductShort[] = []): TAppThunk<void> =>
    async (dispatch) => {
        dispatch(actionSetSearchCategoryList(categories));
        dispatch(actionSetList(products));

        dispatch(actionLoaderSetHidden(actionSetSearchQuery.type));
    };

interface IGetSearchResultPopupBySubmit {
    redirect?: string;
    isAborted: boolean;
}
export const getSearchResultPopupOnSubmit =
    (query: URLSearchParams): TAppThunk<IGetSearchResultPopupBySubmit> =>
    async (dispatch, _, {productsService}) => {
        try {
            const data = await dispatch(
                requestAction<TResponse<SearchResultDataResponse>>({
                    requestCb: () => productsService.getProductList(query, {}),
                })
            );

            return {
                isAborted: false,
                redirect: data.redirect,
            };
        } catch (error) {
            if (error instanceof AbstractControllerException) {
                throw error;
            }

            if (error instanceof DOMException && 'AbortError' === error.name) {
                return {
                    isAborted: true,
                };
            }

            return {
                isAborted: false,
            };
        }
    };
