import {mindBoxService} from 'modules/analytics/services/MindBoxService';
import {ProductBase} from 'new-models';
import {useEffect} from 'react';

export const useViewProduct = (product?: ProductBase) => {
    useEffect(() => {
        if (!product) {
            return;
        }
        mindBoxService.viewProduct(product);
    }, [product]);
};
