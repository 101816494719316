import {Checkbox} from 'components/Checkbox';
import {FilterFlag} from 'new-models';
import {FilterContext} from 'plugins/modules/filter/context';
import {ListingContext} from 'plugins/modules/listing/context';
import React, {ChangeEventHandler, FC, useCallback, useContext} from 'react';

import style from './style.module.scss';

export const FilterFlagVariant: FC<FilterFlag> = (filter) => {
    const {isListLoading} = useContext(ListingContext);
    const {urlService: filterService} = useContext(FilterContext);
    const {isApplied, code, title} = filter;

    const handleApply = useCallback<ChangeEventHandler<HTMLInputElement>>(
        async (e) => {
            const isFilterApplied = e.target.checked;
            if (isFilterApplied) {
                await filterService?.addFlagParams({code, isFilterApplied});
            }

            await filterService?.removeParam({id: code});
        },
        [code, filterService]
    );
    return (
        <Checkbox
            checked={isApplied}
            className={style.filterFlag}
            disabled={isListLoading}
            isLabel={Boolean(title)}
            label={filter.title}
            onChange={handleApply}
        />
    );
};
