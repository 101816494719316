import {Typography} from 'components/Typography';
import {formatPhoneForView} from 'core/regex';
import {OrderXInfoBlock} from 'modules/orders/components/OrderXInfoBlock';
import {OrderXInfoBlockItem} from 'modules/orders/components/OrderXInfoBlockItem';
import {OrderDetail} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps extends OrderDetail {}

export const OrderXPageAboutInfo = memo<IProps>(function OrderXPageAboutInfo({
    paymentMethod,
    deliveryMethod,
    customer,
    comment,
}) {
    return (
        <div className={style.root}>
            <Typography element="h5" variant="h5">
                Информация о заказе
            </Typography>
            <OrderXInfoBlock>
                {customer && (
                    <OrderXInfoBlockItem leftSideText="Получатель">
                        <Typography color="gray100" element="p" variant="p">
                            {customer.firstname} {customer.lastname}
                            <br />
                            {formatPhoneForView(customer.phone)}
                            {customer.email && (
                                <>
                                    <br />
                                    {customer.email}
                                </>
                            )}
                        </Typography>
                    </OrderXInfoBlockItem>
                )}

                <OrderXInfoBlockItem leftSideText="Способ получения">
                    <Typography element="p" variant="p">
                        {deliveryMethod.title}
                    </Typography>
                </OrderXInfoBlockItem>
                <OrderXInfoBlockItem leftSideText="Способ оплаты">
                    <Typography element="p" variant="p">
                        {paymentMethod.title}
                    </Typography>
                </OrderXInfoBlockItem>

                {Boolean(comment) && (
                    <OrderXInfoBlockItem leftSideText="Комментарий">
                        <Typography element="p" variant="p">
                            {comment}
                        </Typography>
                    </OrderXInfoBlockItem>
                )}
            </OrderXInfoBlock>
        </div>
    );
});
