import {AddressForm} from 'modules/addresses/components/AddressForm';
import {AddressMap} from 'modules/addresses/components/AddressMap';
import {AddressModalLayout} from 'modules/addresses/components/AddressModalLayout';
import {FooterFields, HeaderFields} from 'modules/addresses/components/AddressOptionalFields';
import {useEditAddressForm} from 'modules/addresses/hooks/useEditAddressForm';
import React, {memo} from 'react';

interface IProps {
    isHideOptionalFields?: boolean;
}

const AddressEditModal = memo<IProps>(function AddressEditModal({isHideOptionalFields}) {
    const {initialValues, validationSchema, onSubmitForm} = useEditAddressForm();

    if (!initialValues) {
        return null;
    }

    return (
        <AddressModalLayout isSmallerContent={isHideOptionalFields} title="Изменить адрес">
            <AddressForm<typeof initialValues>
                FooterComponent={!isHideOptionalFields ? FooterFields : undefined}
                HeaderComponent={!isHideOptionalFields ? HeaderFields : undefined}
                RightSideComponent={AddressMap}
                initialValues={initialValues}
                onSubmit={onSubmitForm}
                validationSchema={validationSchema}
            />
        </AddressModalLayout>
    );
});

export default AddressEditModal;
