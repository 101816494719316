import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {TClosureCallback, TPropsWithClassName} from 'core/types';
import {MODALS} from 'modules/modals/constants';
import {showModal} from 'modules/modals/thunks';
import {PRODUCT_SLIDER_GRID_LAYOUT} from 'modules/product-card/constants';
import {useIntersection} from 'modules/product-card/hooks/useIntersection';
import {PRODUCT_CARD_VARIANT} from 'modules/products/constants';
import {ShieldsList} from 'modules/shields/components/ShieldsList';
import {GalleryButtonSlider} from 'modules/slider/components/GalleryButtonSlider';
import {GalleryMainSlider} from 'modules/slider/components/GalleryMainSlider';
import {GALLERY_MODE} from 'modules/slider/constants';
import {GallerySliderContext} from 'modules/slider/context';
import {getSlidesList} from 'modules/slider/helpers';
import {actionSetActiveIdx, actionSetAutoStartVideoPath} from 'modules/slider/slice';
import {IHandleSlideProps, TGalleryMode} from 'modules/slider/types';
import {MediaImage, MediaYoutube, Nameplate, ProductDetail} from 'new-models';
import React, {FC, MouseEventHandler, useCallback, useMemo, useRef, useState} from 'react';

import style from './style.module.scss';

const {gridColumns, sliderBlock, thumbBlock} = PRODUCT_SLIDER_GRID_LAYOUT;

const sliderColumnStyle = getGridColumnStyles({
    md: sliderBlock,
});
const thumbColumnStyle = getGridColumnStyles({
    md: thumbBlock,
});

const SLIDER_INTERSECTION_THRESHOLD = 0.9;

const intersectionOptions = {
    threshold: SLIDER_INTERSECTION_THRESHOLD,
};

export interface IProps extends Pick<ProductDetail, 'nameplates' | 'availability'>, TPropsWithClassName {
    images: MediaImage[];
    videos?: MediaYoutube[];
    mode: TGalleryMode;
}

export const GallerySlider: FC<IProps> = ({
    className,
    images,
    videos,
    availability,
    mode = GALLERY_MODE.basic,
    nameplates,
}) => {
    const dispatch = useAppDispatch();
    const [isVisible, setIsVisible] = useState(true);
    const sliderContainerRef = useRef(null);

    const handleIntersection = useCallback((entry: IntersectionObserverEntry) => {
        setIsVisible(entry.isIntersecting);
    }, []);

    useIntersection({
        callBack: handleIntersection,
        options: intersectionOptions,
        ref: sliderContainerRef,
    });

    const onClick = useCallback<TClosureCallback<IHandleSlideProps, MouseEventHandler<HTMLButtonElement>>>(
        ({activeSlideIdx, isVideoButton, isNeedOpenModal, path}) =>
            () => {
                if (mode !== GALLERY_MODE.fullscreen && isNeedOpenModal) {
                    dispatch(showModal(MODALS.MAIN_GALLERY.name));
                }

                if (mode !== GALLERY_MODE.fullscreen && isVideoButton) {
                    dispatch(actionSetAutoStartVideoPath(path ?? null));
                }

                dispatch(actionSetActiveIdx(activeSlideIdx));
            },
        [dispatch, mode]
    );

    const mountShields = useMemo<Nameplate[]>(() => {
        const shields = [];
        shields.push(...nameplates);
        if (!availability) {
            shields.unshift({
                bgColor: '#CECECE',
                textColor: null,
                title: 'Нет в наличии',
                type: 'new' as Nameplate['type'],
            });
        }
        return shields;
    }, [availability, nameplates]);

    const slidesList = useMemo<(MediaImage | MediaYoutube)[]>(() => getSlidesList(images, videos), [images, videos]);
    return (
        <GallerySliderContext.Provider
            value={{
                isVisible,
                mode,
                onClick,
                slidesList,
            }}
        >
            <Grid
                className={classNames(className, style.gallerySlider, {
                    [style[mode]]: Boolean(mode),
                })}
                gridColumnsCount={gridColumns}
            >
                <div className={classNames(style.galleryButtonSlider, thumbColumnStyle)}>
                    <GalleryButtonSlider
                        className={classNames(style.galleryThumbs, {
                            [style.active]: Boolean(1 < slidesList.length),
                        })}
                        fullWidthThumb
                    />
                </div>
                <div className={classNames(style.sliderBlock, sliderColumnStyle)} ref={sliderContainerRef}>
                    <GalleryMainSlider className={style.galleryMainSlider} />
                    <ShieldsList
                        className={style.gallerySliderShieldList}
                        shields={mountShields}
                        variant={PRODUCT_CARD_VARIANT.main}
                    />
                </div>
            </Grid>
        </GallerySliderContext.Provider>
    );
};
