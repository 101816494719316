import {TOKEN_KEY} from 'core/constants';
import {requestAction} from 'core/redux/helpers';
import {TAppThunk} from 'core/redux/types';
import {IJwtPayload, TResponse} from 'core/types';
import jwtDecode from 'jwt-decode';
import {USER_LOCATION} from 'modules/locations/constants';
import {TChangeLocationResponse, TLocationResponse} from 'modules/locations/models/LocationsResponse';
import {selectIsLocationSelected} from 'modules/locations/selectors';
import {actionSetIsLocationSelected, actionSetLocationsList, actionSetUserLocation} from 'modules/locations/slice';
import {Location} from 'new-models';

export const getLocationsList: TAppThunk<void> = async (dispatch, _, {locationService}) => {
    const data = await dispatch(
        requestAction<TResponse<TLocationResponse>>({
            requestCb: () => locationService.getLocationsList(),
            type: actionSetLocationsList.type,
        })
    );

    dispatch(actionSetLocationsList(data.locations));
};

export const changeLocation =
    (location: Location): TAppThunk<void> =>
    async (dispatch, getState, {locationService, cookieService}) => {
        const data = await dispatch(
            requestAction<TResponse<TChangeLocationResponse>>({
                requestCb: () => locationService.changeLocation(location.id),
                type: actionSetUserLocation.type,
            })
        );

        const isLocationSelected = selectIsLocationSelected(getState());

        const locationId = jwtDecode<IJwtPayload>(data.token ?? '').loc;

        if (!locationId) {
            return;
        }

        if (!isLocationSelected) {
            dispatch(actionSetIsLocationSelected(true));
        }
        localStorage.setItem(USER_LOCATION, locationId);
        dispatch(actionSetUserLocation(location));
        await cookieService.setItem(TOKEN_KEY, data.token);
    };
