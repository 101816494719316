import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import chevronIcon from 'public/icons/sorting-select-chevron.svg';
import React, {CSSProperties, memo, useLayoutEffect, useMemo, useRef, useState} from 'react';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    buttonTextClose?: string;
    buttonTextOpen?: string;
    description: string;
    textMaxHeight?: number;
}

export const TextCollapse = memo<IProps>(function TextCollapse({
    buttonTextClose = 'Скрыть',
    buttonTextOpen = 'Развернуть',
    className,
    description,
    textMaxHeight = 200,
}) {
    const [isShowFullText, setIsShowFullText] = useState(false);
    const [isShowButtonOnDesktop, setIsShowButtonOnDesktop] = useState(false);
    const textRef = useRef<HTMLSpanElement | null>(null);

    const textStyle = useMemo(() => {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '--max-height': `${textMaxHeight}px`,
        } as CSSProperties;
    }, [textMaxHeight]);

    const handleClick = () => {
        setIsShowFullText((show) => {
            if (show) {
                window.scrollTo(0, 0);
            }

            return !show;
        });
    };

    useLayoutEffect(() => {
        const bcr = textRef.current?.getBoundingClientRect();

        if (!bcr) {
            return;
        }

        if (bcr.height > textMaxHeight) {
            setIsShowButtonOnDesktop(true);
        }
    }, [textMaxHeight]);

    return (
        <div
            className={classNames(
                style.root,
                {
                    [style.show]: isShowFullText,
                    [style.showButtonDesktop]: isShowButtonOnDesktop,
                },
                className
            )}
        >
            <div className={style.content} style={textStyle}>
                <Typography
                    className={style.title}
                    dangerouslySetInnerHTML={{
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: description,
                    }}
                    element="div"
                    ref={textRef}
                />
            </div>
            <button className={style.button} onClick={handleClick} type="button">
                <span>{isShowFullText ? buttonTextClose : buttonTextOpen}</span>
                <SvgIcon className={style.icon} svg={chevronIcon} />
            </button>
        </div>
    );
});
