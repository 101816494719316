import {isMobileByScreen} from 'core/helpers';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {CATEGORY_LEVEL} from 'modules/categories/constants';
import {getMenuItemUrl} from 'modules/menu/helpers';
import {selectHeaderCatalogMenuFormatted} from 'modules/menu/selectors';
import {IHeaderCatalogMenuItem, THeaderCatalogMenu} from 'modules/menu/types';
import {MODALS} from 'modules/modals/constants';
import {hideModal} from 'modules/modals/thunks';
import {useRouter} from 'next/router';
import {LiHTMLAttributes, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react';

const DESKTOP_TIMEOUT = 200;

type TRenderInteractionCallback = (
    categoryNode: IHeaderCatalogMenuItem
) => Partial<Pick<LiHTMLAttributes<HTMLLIElement>, 'onClick' | 'onMouseEnter'>>;

interface IUseDIYCatalogMenuList {
    categoriesList: THeaderCatalogMenu;
    depth?: number;
    parentCategory?: IHeaderCatalogMenuItem | null;
    setCatalogMenu: (menu: THeaderCatalogMenu) => void;
    setParentItem: (item: IHeaderCatalogMenuItem | null) => void;
}

export const useDIYCatalogMenuList = ({
    categoriesList,
    parentCategory,
    depth,
    setCatalogMenu,
    setParentItem,
}: IUseDIYCatalogMenuList) => {
    const dispatch = useAppDispatch();
    const {asPath} = useRouter();

    const timeOutRef = useRef<ReturnType<typeof setTimeout>>();

    const [activeCategoryId, setActiveCategoryId] = useState<IHeaderCatalogMenuItem['id'] | null>(null);

    const catalogMenuList = useAppSelector(selectHeaderCatalogMenuFormatted);

    const activeCategory = useMemo<IHeaderCatalogMenuItem | null>(
        () => categoriesList.find((categoryNode) => categoryNode.id === activeCategoryId) || null,
        [activeCategoryId, categoriesList]
    );

    const parentCategoryLink = useMemo<string>(() => getMenuItemUrl(parentCategory?.url), [parentCategory]);

    const flatByField = useCallback(
        (arr: THeaderCatalogMenu, getChildren: (item: IHeaderCatalogMenuItem) => THeaderCatalogMenu | undefined) => {
            return arr.reduce<THeaderCatalogMenu>((acc, item) => {
                acc.push(item);

                const children = getChildren(item);

                if (children) {
                    acc.push(...flatByField(children, getChildren));
                }

                return acc;
            }, []);
        },
        []
    );

    const handleBackClick = useCallback(() => {
        const allowedCategories = flatByField(catalogMenuList, (item) => item.childrenCategory);
        const activeCategoryParent = allowedCategories.find((item) => item.id === categoriesList[0].parentId);

        if (!activeCategoryParent?.parentId) {
            setCatalogMenu(allowedCategories.filter((item) => null === item.parentId));
            setActiveCategoryId(null);
            setParentItem(null);
            return;
        }

        const currentCategory = allowedCategories.find((item) => item.id === activeCategoryParent.parentId);

        if (currentCategory) {
            setCatalogMenu(currentCategory.childrenCategory);
            setActiveCategoryId(currentCategory?.id || null);
            setParentItem(currentCategory);
        }
    }, [catalogMenuList, categoriesList, flatByField, setCatalogMenu, setParentItem]);

    const handleLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isMobileByScreen() && (CATEGORY_LEVEL.first === depth || parentCategoryLink === asPath)) {
                event.preventDefault();
                return;
            }

            dispatch(hideModal(MODALS.CATALOG_MENU.name));
        },
        [asPath, parentCategoryLink, depth, dispatch]
    );

    const switchCategory = useCallback((categoryId: IHeaderCatalogMenuItem['id']) => {
        clearTimeout(timeOutRef.current);

        timeOutRef.current = setTimeout(
            () => {
                setActiveCategoryId(categoryId);
            },
            isMobileByScreen() ? 0 : DESKTOP_TIMEOUT
        );
    }, []);

    const renderInteractionCallback: TRenderInteractionCallback = useCallback(
        ({id: categoryId}: IHeaderCatalogMenuItem) => {
            if (isMobileByScreen()) {
                return {
                    onClick: () => switchCategory(categoryId),
                };
            }

            return {
                onMouseEnter: () => switchCategory(categoryId),
            };
        },
        [switchCategory]
    );
    const handleMouseLeave = useCallback(() => {
        clearTimeout(timeOutRef.current);
    }, []);

    const bannersNotShowing = useMemo<boolean>(
        () => Boolean(CATEGORY_LEVEL.first !== depth || !categoriesList.length),
        [categoriesList.length, depth]
    );

    useEffect(() => {
        return () => clearTimeout(timeOutRef.current);
    }, []);

    return {
        activeCategory,
        activeCategoryId,
        bannersNotShowing,
        handleBackClick,
        handleLinkClick,
        handleMouseLeave,
        parentCategoryLink,
        renderInteractionCallback,
        setActiveCategoryId,
    };
};
