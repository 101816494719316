import {TEnv} from 'core/types';

export class WrongEnvException extends Error {
    public readonly callName: string;
    public readonly env: TEnv;

    constructor(callName: WrongEnvException['callName'], env: WrongEnvException['env']) {
        super();

        this.callName = callName;
        this.env = env;

        this.message = `Call '${this.callName}' not allowed on a ${this.env}-side, stacktrace: \n\n${this.stack}'`;
    }
}
