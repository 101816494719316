import classNames from 'classnames';
import {Anchor} from 'components/Anchor';
import {Typography} from 'components/Typography';
import {getProductPluralText, getSuitablePluralText} from 'core/helpers/plural';
import {SearchPanelProduct} from 'modules/search-popup/components/SearchPanelProduct';
import {SHOWING_PRODUCTS_AMOUNT} from 'modules/search-popup/constants';
import {ProductShort} from 'new-models';
import Link from 'next/link';
import React, {FC, ReactNode, useId, useMemo} from 'react';
import styles from 'styles/search-styles.module.scss';

import style from './style.module.scss';

interface IProps {
    className?: string;
    data: ProductShort[];
    title: string;
    availableProductsUrl?: string;
}

export const SearchPanelGoods: FC<IProps> = ({className, title, data, availableProductsUrl}) => {
    const uid = useId();

    const productsData = useMemo(
        () => (data?.length ? data.slice(0, availableProductsUrl ? SHOWING_PRODUCTS_AMOUNT : undefined) : null),
        [availableProductsUrl, data]
    );
    const renderData = useMemo<ReactNode[] | null>(
        () =>
            productsData?.map((product) => (
                <li key={product.id}>
                    <SearchPanelProduct data={product} key={product.id} />
                </li>
            )) || null,
        [productsData]
    );
    const productsCount = data.length;

    const renderRemainder = useMemo<ReactNode | null>(
        () =>
            SHOWING_PRODUCTS_AMOUNT < productsCount && availableProductsUrl ? (
                <li data-skeleton-item key={uid}>
                    <Link href={availableProductsUrl} passHref>
                        <Anchor className={styles.searchPanelLink} icon="right" size="large" theme="standalone">
                            Показать {productsCount} {getSuitablePluralText(productsCount)}{' '}
                            {getProductPluralText(productsCount)}
                        </Anchor>
                    </Link>
                </li>
            ) : null,
        [availableProductsUrl, productsCount, uid]
    );

    return (
        <div className={classNames(styles.searchPanelElement, className)}>
            <Typography color="gray50" data-skeleton-item element="p" variant="elements-p">
                {title}
            </Typography>
            <ul className={style.list}>
                {renderData}
                {renderRemainder}
            </ul>
        </div>
    );
};
