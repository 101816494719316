import {EvolutionHttpClient} from 'core/http-client/EvolutionHttpClient';
import {TServiceTopResponse} from 'modules/services/models/ServicesResponse';

export class ServicesService extends EvolutionHttpClient {
    public async getPopularServiceList(limit = 7, page = 1): Promise<TServiceTopResponse> {
        const {data} = await this.get<TServiceTopResponse>({
            url: `/services/top?limit=${limit}&page=${page}`,
        });

        return data;
    }
}
