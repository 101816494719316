import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {TPropsWithClassName} from 'core/types';
import {CheckoutCourierAddressFromProfile} from 'modules/checkout/components/CheckoutCourierAddressFromProfile';
import {USER_ADDRESS_COUNT_FIELD_NAME} from 'modules/checkout/constants/fields';
import {CheckoutContext} from 'modules/checkout/context';
import {useCheckoutFormikContext} from 'modules/checkout/hooks/useCheckoutFormikContext';
import {useIsLoggedIn} from 'modules/user/hooks/useIsLoggedIn';
import {DeliveryType} from 'new-models';
import React, {memo, useContext, useEffect, useMemo} from 'react';

const columnStyle = getGridColumnStyles();

import {useAddAddressModalApi} from 'modules/addresses/hooks/useAddAddressModalApi';
import {EmptyAddressList} from 'modules/checkout/components/EmptyAddressList';

import style from './style.module.scss';

export interface IProps extends TPropsWithClassName {
    deliveryId?: string;
}

export const CheckoutDeliveryDetails = memo<IProps>(function CheckoutDeliveryDetails({className, deliveryId}) {
    const {deliveryMethodData, userAddressList} = useContext(CheckoutContext);
    const {setFieldValue} = useCheckoutFormikContext();
    const userIsLoggedIn = useIsLoggedIn();
    const {open} = useAddAddressModalApi();

    const currentDeliveryType = (deliveryId && deliveryMethodData[deliveryId]?.type) || null;

    useEffect(() => {
        setFieldValue(USER_ADDRESS_COUNT_FIELD_NAME, userIsLoggedIn ? userAddressList.length : 0);
    }, [setFieldValue, userAddressList.length, userIsLoggedIn]);

    const renderDetails = useMemo(() => {
        switch (currentDeliveryType) {
            case DeliveryType.COURIER: {
                return (
                    <Grid className={classNames(style.courierGrid, className)}>
                        {userIsLoggedIn && userAddressList.length ? (
                            <CheckoutCourierAddressFromProfile addressList={userAddressList} className={columnStyle} />
                        ) : (
                            <EmptyAddressList className={columnStyle} onClick={open} />
                        )}
                    </Grid>
                );
            }
            case DeliveryType.PICKUP:
            default:
                return null;
        }
    }, [currentDeliveryType, className, userIsLoggedIn, userAddressList, open]);

    return <>{renderDetails}</>;
});
