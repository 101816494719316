import {AbstractStorage} from 'core/abstract/AbstractStorage';
import {BaseHttpClient} from 'core/base/BaseHttpClient';
import {APP_BUILD_VERSION, APP_VERSION} from 'core/constants';
import {isBrowserContext} from 'core/helpers';
import {getRuntimeConfig} from 'core/next/helpers';
import {ContextService} from 'core/services/ContextService';
import {MobileAppDescription} from 'models';

export const AUTH_TOKEN_HEADER_NAME = 'x-auth-token';

export class AuthSdk {
    private readonly client: BaseHttpClient;
    public readonly storage: AbstractStorage;

    constructor(storage: AuthSdk['storage']) {
        this.client = new BaseHttpClient(
            getRuntimeConfig().NEXT_PUBLIC_BACKEND_HOST ?? '',
            getRuntimeConfig().NEXT_PUBLIC_API_V ?? ''
        );

        this.storage = storage;
    }

    public isLoggedIn(): boolean {
        return Boolean(this.storage.getItem(AUTH_TOKEN_HEADER_NAME));
    }

    public async getNewToken(): Promise<string> {
        await this.flushToken();
        return this.getToken();
    }

    public async flushToken(): Promise<void> {
        await this.storage.removeItem(AUTH_TOKEN_HEADER_NAME);
    }

    public async getToken(): Promise<string> {
        const storedToken = await this.storage.getItem(AUTH_TOKEN_HEADER_NAME);

        if (storedToken) {
            return storedToken;
        }

        const token = await this.login();
        this.storage.setItem(AUTH_TOKEN_HEADER_NAME, token);

        return token;
    }

    private get platform(): MobileAppDescription {
        const platform: MobileAppDescription['platform'] = isBrowserContext()
            ? navigator.userAgent
            : ContextService.ctx?.req?.headers['user-agent'] ?? 'Undefined';

        return {
            build: APP_BUILD_VERSION,
            platform,
            version: APP_VERSION,
        };
    }

    public async refresh(): Promise<string> {
        const oldToken = await this.getToken();

        const response = await this.client.patch({
            body: this.platform,
            options: {
                headers: {
                    [AUTH_TOKEN_HEADER_NAME]: oldToken,
                    ['Content-Type']: 'application/json',
                },
            },
            url: '/auth/token',
        });

        const token: string = response.headers.get(AUTH_TOKEN_HEADER_NAME);

        this.storage.setItem(AUTH_TOKEN_HEADER_NAME, token);

        return token;
    }

    private async login(): Promise<string> {
        const response = await this.client.post({
            body: this.platform,
            options: {
                headers: {
                    ['Content-Type']: 'application/json',
                },
            },
            url: '/auth/token',
        });

        return response.headers.get(AUTH_TOKEN_HEADER_NAME);
    }

    public static instance: AuthSdk;

    public static getInstance(storage: AuthSdk['storage']): AuthSdk {
        if (!AuthSdk.instance) {
            AuthSdk.instance = new AuthSdk(storage);
        }

        return AuthSdk.instance;
    }
}
