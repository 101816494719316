import {HeaderRequestInterceptor} from 'core/interceptors/HeaderRequestInterceptor';
import {TRequestOption, TRequestParameters} from 'core/types';

export class ModifiedControlRequestInterceptor extends HeaderRequestInterceptor {
    public readonly requestLastModifiedMap: Map<string, string>;
    public cacheEndPoints: string[];

    private constructor() {
        super();

        this.requestLastModifiedMap = new Map<string, string>();
        this.cacheEndPoints = [];
    }

    public override async intercept(
        requestOption: TRequestParameters<TRequestOption, object>
    ): Promise<TRequestParameters<TRequestOption, object>> {
        const lastModified = this.requestLastModifiedMap.get(requestOption.url);
        const isNeedCache = this.cacheEndPoints.some((endpoint) => requestOption.url.includes(endpoint));

        if (lastModified && isNeedCache) {
            requestOption.options = {
                ...requestOption.options,
                headers: {
                    ...requestOption.options?.headers,
                    ['If-Modified-Since']: lastModified,
                },
            };
        }

        return requestOption;
    }

    private static instance: ModifiedControlRequestInterceptor;

    public static getInstance(): ModifiedControlRequestInterceptor {
        if (!ModifiedControlRequestInterceptor.instance) {
            ModifiedControlRequestInterceptor.instance = new ModifiedControlRequestInterceptor();
        }

        return ModifiedControlRequestInterceptor.instance;
    }
}
