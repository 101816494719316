import {RefObject, useCallback, useEffect, useState} from 'react';

interface IUseIntersectionProps {
    callBack: (entry: IntersectionObserverEntry) => void;
    options?: IntersectionObserverInit;
    ref: RefObject<any>;
}

export const useIntersection = ({ref, callBack, options}: IUseIntersectionProps) => {
    const [state, setState] = useState<IUseIntersectionProps>({callBack, options, ref});
    const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver>();

    const unsubscribe = useCallback((container?: HTMLElement, io?: IntersectionObserver) => {
        if (container && io) {
            io.unobserve(container);
        }
    }, []);

    useEffect(() => {
        if (intersectionObserver) {
            const {ref: stateRef, callBack: stateCallback, options: stateOptions} = state;

            if (ref.current !== stateRef.current || callBack !== stateCallback || options !== stateOptions) {
                unsubscribe(stateRef.current, intersectionObserver);
                setIntersectionObserver(undefined);
                setState({
                    callBack,
                    options,
                    ref,
                });
            }
        }
    }, [ref, callBack, options, state, intersectionObserver, unsubscribe]);

    useEffect(() => {
        const {ref: stateRef, callBack: stateCallback, options: stateOptions} = state;
        const container = stateRef.current;

        if (!intersectionObserver && container) {
            const io = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    stateCallback(entry);
                });
            }, stateOptions);

            io.observe(container);
            setIntersectionObserver(io);
        }

        return () => {
            unsubscribe(container, intersectionObserver);
        };
    }, [state, intersectionObserver, unsubscribe]);
};
