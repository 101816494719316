import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISearchPopUpOriginState, TSearchPopUpState} from 'modules/search-popup/types';
import {createListingActions} from 'plugins/modules/listing/actions';
import {createSearchActions} from 'plugins/modules/search/actions';
import {withSearch} from 'plugins/modules/search/state';

export const searchPopupModuleName = 'search-popup';
const initialState: TSearchPopUpState = withSearch<ISearchPopUpOriginState>({
    isSearchPanelVisible: false,
});

export const searchPopupSlice = createSlice({
    initialState,
    name: searchPopupModuleName,
    reducers: {
        actionSetIsSearchPanelVisible(
            state: TSearchPopUpState,
            {payload}: PayloadAction<TSearchPopUpState['isSearchPanelVisible']>
        ) {
            state.isSearchPanelVisible = payload;
        },

        ...createSearchActions(),
        ...createListingActions(),
    },
});

export const {
    actions: {
        actionSetIsSearchPanelVisible,
        actionClearSearchData,
        actionSetSearchQuery,
        actionSetSearchCategoryList,
        actionSetSearchSuggestions,
        actionSetSearchRecommendationsList,
        actionSetList,
    },
} = searchPopupSlice;

export const {reducer: searchPopupReducer} = searchPopupSlice;
