import {MediaHarvester} from 'components/MediaHarvester';
import {IVideoYoutubeProps, YOUTUBE_STATE} from 'components/VideoYoutube';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {selectIsAnyPortalVisible} from 'modules/portal/selectors';
import {stopYoutubeVideo} from 'modules/product-card/helpers';
import {GALLERY_MODE} from 'modules/slider/constants';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {isNeedPauseVideo} from 'modules/slider/helpers';
import {MediaYoutube} from 'new-models';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {YouTubeEvent, YouTubePlayer} from 'react-youtube';

const {playing} = YOUTUBE_STATE;

interface IProps extends TPropsWithClassName {
    isActive?: boolean;
    media: MediaYoutube;
}

export const GalleryMainYoutubeSlide: FC<IProps> = ({className, isActive, media}) => {
    const {isVisible, mode} = useContext<IGallerySliderContextProps>(GallerySliderContext);
    const [youtubeVideo, setYoutubeVideo] = useState<YouTubePlayer | null>(null);
    const [isActivePaused, setIsActivePaused] = useState(false);
    const isAnyModalVisible = useAppSelector(selectIsAnyPortalVisible);

    useEffect(() => {
        const isNeedPause = isNeedPauseVideo(isActive, isAnyModalVisible, mode);

        if (isNeedPause) {
            setIsActivePaused(true);
        }
    }, [isActive, isAnyModalVisible, mode]);

    useEffect(() => {
        if (isAnyModalVisible && mode === GALLERY_MODE.basic) {
            return;
        }

        if (!youtubeVideo || isActive) {
            return;
        }

        stopYoutubeVideo(youtubeVideo.target);
        setIsActivePaused(false);
    }, [isActive, isAnyModalVisible, media, mode, youtubeVideo]);

    useEffect(() => {
        if (isAnyModalVisible) {
            return;
        }

        if (!youtubeVideo || !isActive) {
            return;
        }

        if (!isVisible) {
            const state = youtubeVideo.target.getPlayerState();

            if (playing === state) {
                setIsActivePaused(true);
                youtubeVideo.target.pauseVideo();
            }

            return;
        }

        if (!isActivePaused) {
            youtubeVideo.target.playVideo();
        }
    }, [isActive, isActivePaused, isAnyModalVisible, isVisible, media, mode, youtubeVideo]);

    const onYoutubeVideoReady = useCallback((youtubeApiEvent: YouTubeEvent) => {
        setYoutubeVideo(youtubeApiEvent);
        youtubeApiEvent.target.mute();
    }, []);

    const optionsYoutube = useMemo(() => {
        return {
            onYoutubeVideoReady,
            overlayOnlyMobile: true,
        } as IVideoYoutubeProps;
    }, [onYoutubeVideoReady]);

    return <MediaHarvester className={className} media={media} optionsYoutube={optionsYoutube} />;
};
