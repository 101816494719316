import {ISegmentControlValueList} from 'components/SegmentedControl';
import {TCheckedListCondition, TCountActions, TRemoveModalView} from 'modules/cart/types';
import {BasketTotal} from 'new-models';

export const CHECKED_LIST_CONDITIONS: Record<string, TCheckedListCondition> = {
    ALL: 'all',
    NONE: 'none',
    ONE: 'one',
    SOME: 'some',
};

export const REMOVE_MODAL_VIEW: Record<string, TRemoveModalView> = {
    ASSIGNED: '',
    DEFAULT: 'default',
    ONE_SELECTED: 'one-selected',
};

export const COUNT_ACTIONS: Record<string, TCountActions> = {
    ADD: 'add',
    REMOVE: 'remove',
};

export const CART_MAX_SIZE = 200;

export const TOAST_NOTIFICATIONS = {
    CART_CLEARED: 'Корзина очищена,',
    CART_FILLED: 'В корзине 200 товаров. Чтобы добавить этот товар, удалите лишние товары из корзины.',
    PRODUCT_ADDED: 'Товар добавлен в корзину',
    PRODUCT_REMOVED: 'Товар удален из корзины',
    PRODUCTS_REMOVED: 'Товары удалены из корзины',
};

export const LEGAL_STATUS = {
    LEGAL: 'Юридическое лицо',
    NATURAL: 'Физическое лицо',
};

export const LEGAL_TAB: ISegmentControlValueList = {
    id: 0,
    label: LEGAL_STATUS.LEGAL,
    value: LEGAL_STATUS.LEGAL,
};

export const NATURAL_TAB: ISegmentControlValueList = {
    id: 1,
    label: LEGAL_STATUS.NATURAL,
    value: LEGAL_STATUS.NATURAL,
};

export const AVAILABILITY = 'Наличие';

export const DEFAULT_TOTAL: BasketTotal = {
    price: {
        cost: 0,
        deliveryCost: 0,
        discount: 0,
        totalCost: 0,
    },
};

export const DEFAULT_LIMITS = {
    minCost: 0,
};
