import {Input} from 'components/Input';
import {TInputField} from 'core/types';
import {ADDRESS_DETAILS_MAX_LENGTH} from 'modules/addresses/constants';
import {useAddressField} from 'modules/addresses/hooks/useAddressField';

export const Entrance: FC<TInputField> = ({className, ...inputProps}) => {
    const {handleChange, isPrivateHouse} = useAddressField('entrance', ADDRESS_DETAILS_MAX_LENGTH);

    return (
        <Input
            className={className}
            disabled={isPrivateHouse}
            isBorder
            labelText="Подъезд"
            {...inputProps}
            onChange={handleChange}
        />
    );
};
