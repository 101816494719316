import classNames from 'classnames';
import {MediaWithImageFallback} from 'components/MediaWithImageFallback';
import {ModalLazyLoader} from 'components/ModalLazyLoader';
import {Typography} from 'components/Typography';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TotalTitle} from 'modules/checkout/components/TotalTitle';
import {DISPLAYED_PRODUCTS_COUNT} from 'modules/checkout/constants';
import {useTotalProducts} from 'modules/checkout/hooks/useTotalProducts';
import {Modal} from 'modules/modals/components/Modal';
import {MODALS} from 'modules/modals/constants';
import {useProductUrl} from 'modules/products/hooks/useProductUrl';
import {BasketItem, MediaTypeEnum} from 'new-models';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, {MouseEventHandler} from 'react';

import style from './style.module.scss';

const TotalProductsModal = dynamic(() => import('modules/checkout/components/TotalProductsModal'), {
    loading: () => <ModalLazyLoader />,
});

interface IProductProps extends BasketItem {}

interface IShowMoreProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
    hiddenCount: number;
}

interface IProps {
    products: BasketItem[];
}

const TotalProduct: FC<IProductProps> = ({product}) => {
    const productUrl = useProductUrl(product);

    return (
        <li className={style.item}>
            <Link href={productUrl}>
                <a>
                    <MediaWithImageFallback
                        media={product.media}
                        mediaType={MediaTypeEnum.Image}
                        options={generateMediaOptions({
                            resizeKey: IMAGE_RESIZE_CODE.w200,
                            type: MediaTypeEnum.Image,
                        })}
                    />
                </a>
            </Link>
        </li>
    );
};

const ShowMore: FC<IShowMoreProps> = ({onClick, hiddenCount}) => {
    return (
        <li className={classNames(style.item, style.button)}>
            <button onClick={onClick}>
                <Typography color="primary80" variant="elements-p">{`+${hiddenCount}`}</Typography>
            </button>
        </li>
    );
};

export const TotalProducts: FC<IProps> = ({products}) => {
    const {mountProducts, isHidden, onClick} = useTotalProducts(products);

    return (
        <div className={style.root}>
            <TotalTitle />
            <ul className={style.list}>
                {mountProducts.map((product, idx) => (
                    <TotalProduct key={idx} {...product} />
                ))}
                {isHidden && <ShowMore hiddenCount={products.length - DISPLAYED_PRODUCTS_COUNT} onClick={onClick} />}
            </ul>
            <Modal hasPortal name={MODALS.CHECKOUT_TOTAL_PRODUCTS.name} willMount>
                <TotalProductsModal products={products} />
            </Modal>
        </div>
    );
};
