import {Typography} from 'components/Typography';
import {getPriceGroups} from 'core/regex';
import {useMemo} from 'react';

import style from './style.module.scss';

interface IProps {
    totalCost: string;
    cost: string;
    isDiscount: boolean;
}

export const PriceItem: FC<IProps> = ({totalCost, cost, isDiscount}) => {
    const {price: totalPrice, currency: totalPriceCurrency} = useMemo(() => getPriceGroups(totalCost), [totalCost]);

    const renderCost = useMemo(() => {
        if (!isDiscount) {
            return null;
        }

        const {price, currency} = getPriceGroups(cost);

        return (
            <Typography className={style.oldPrice} variant="old-price-preview-m">
                {price}&nbsp;
                <Typography className={style.totalPriceCurrency} variant="p-strong">
                    {currency}
                </Typography>
            </Typography>
        );
    }, [cost, isDiscount]);

    return (
        <div className={style.priceItem}>
            {renderCost}
            <Typography className={style.totalPrice} variant="price-preview-m">
                {totalPrice}&nbsp;
                <Typography className={style.totalPriceCurrency} variant="p-strong">
                    {totalPriceCurrency}
                </Typography>
            </Typography>
        </div>
    );
};
