import {YANDEX_PLACEMARK_OPTIONS_DEFAULT} from 'modules/yandex-map/constants';
import {useYandexMapService} from 'modules/yandex-map/hooks/useYandexMapService';
import {YandexMapService} from 'modules/yandex-map/services/YandexMapService';
import {IPlaceMarkEvent} from 'modules/yandex-map/types';
import {useEffect, useRef, useState} from 'react';
import {IDataManager, IPlacemarkOptions, IPointGeometry, Placemark} from 'yandex-maps';

export interface IPlaceMarkUserData {
    meta?: {
        customId?: string;
    };
}

export type TYandexMapPlaceMarkClickEvent = IPlaceMarkEvent<object, IPointGeometry>;

export interface IProps {
    geometry: number[] | object | IPointGeometry;
    properties: (object | IDataManager) & IPlaceMarkUserData;
    options?: IPlacemarkOptions;
    onClick?: (event: TYandexMapPlaceMarkClickEvent, yandexMapService: YandexMapService) => void;
}

export const YandexMapPlaceMark: FC<IProps> = ({
    geometry,
    onClick,
    properties,
    options = YANDEX_PLACEMARK_OPTIONS_DEFAULT,
}) => {
    const placeMarkRef = useRef<Placemark>();
    const [isPlaceMarkReady, setIsPlaceMarkReady] = useState<boolean>(false);

    const yandexMapService = useYandexMapService();

    useEffect(() => {
        if (!isPlaceMarkReady || !placeMarkRef.current || !onClick || !yandexMapService) {
            return;
        }

        yandexMapService.addPlaceMarkEventListener(placeMarkRef.current, 'click', (event) => {
            onClick(event, yandexMapService);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            if (!placeMarkRef.current) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            yandexMapService.removePlaceMarkEventListener(placeMarkRef.current, 'click', onClick);
        };
    }, [isPlaceMarkReady, onClick, yandexMapService]);

    useEffect(() => {
        placeMarkRef.current = yandexMapService?.addPlaceMark(geometry, properties, options);
        setIsPlaceMarkReady(Boolean(placeMarkRef.current));

        // eslint-disable-next-line consistent-return
        return () => {
            if (!placeMarkRef.current) {
                return;
            }

            yandexMapService?.removePlaceMark(placeMarkRef.current);
            setIsPlaceMarkReady(false);
        };
    }, [geometry, options, properties, yandexMapService]);

    return null;
};
