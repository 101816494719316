import classNames from 'classnames';
import {SvgIcon} from 'components/SvgIcon';
import {TPropsWithClassName} from 'core/types';
import {TProductListView} from 'modules/products/types';
import listViewIcon from 'public/icons/list-view.svg';
import listViewFillIcon from 'public/icons/list-view-fill.svg';
import tileViewIcon from 'public/icons/tile-view.svg';
import tileViewFillIcon from 'public/icons/tile-view-fill.svg';
import React, {ChangeEventHandler, FC, useMemo} from 'react';

import style from './style.module.scss';

export const switcherList = [
    {
        hint: 'Отображение списком',
        icon: listViewIcon,
        selectedIcon: listViewFillIcon,
        value: 'list',
    },
    {
        hint: 'Отображение карточками',
        icon: tileViewIcon,
        selectedIcon: tileViewFillIcon,
        value: 'tile',
    },
];

export interface IProps extends TPropsWithClassName {
    name?: string;
    value: TProductListView;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export const ViewSwitcher: FC<IProps> = ({className, name = 'view-switcher', value, onChange}) => {
    const icons = useMemo(() => {
        return switcherList.map(({value: iconValue, icon, selectedIcon, hint}) => {
            const inputId = `${name}-${iconValue}`;
            const checked = value === iconValue;
            const currentIcon = checked ? selectedIcon : icon;

            return (
                <label className={style.label} htmlFor={inputId} key={inputId}>
                    <input
                        checked={checked}
                        className={style.checkbox}
                        id={inputId}
                        name={name}
                        onChange={onChange}
                        type="radio"
                        value={iconValue}
                    />
                    <div className={style.inner} title={hint}>
                        <SvgIcon svg={currentIcon} />
                    </div>
                </label>
            );
        });
    }, [name, value, onChange]);

    return (
        <div className={classNames(style.switch, className)}>
            <div className={style.container} data-skeleton-item>
                {icons}
            </div>
        </div>
    );
};
