import {CollapseAnimated, ICollapseAnimatedProps} from 'components/CollapseAnimated';
import {useScroll} from 'core/hooks/useScroll';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {FC, useEffect} from 'react';

interface IProps extends TPropsWithClassName, TPropsWithChildrenRequire, ICollapseAnimatedProps {
    isCollapsed?: boolean;
    isHidden?: boolean;
    onCollapsed?: (state: boolean) => void;
}
export const CollapseAnimatedOnTop: FC<IProps> = ({
    children,
    className,
    isHidden = false,
    onCollapsed,
    isCollapsed = true,
    ...rest
}) => {
    const isNotAtTop = useScroll(1, 0);

    useEffect(() => {
        onCollapsed?.(isNotAtTop || isHidden);
    }, [isHidden, isNotAtTop, onCollapsed]);

    return (
        <CollapseAnimated className={className} isHidden={isCollapsed && (isNotAtTop || isHidden)} {...rest}>
            {children}
        </CollapseAnimated>
    );
};
