import classNames from 'classnames';
import {Button} from 'components/Button';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {useRouter} from 'next/router';
import {FilterContext} from 'plugins/modules/filter/context';
import productListEmpty from 'public/icons/product-list-empty.svg';
import {memo, MouseEventHandler, ReactNode, useCallback, useContext} from 'react';
import {HOME_ROUTE} from 'routing/constants';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    advice?: ReactNode;
    title?: string;
    isToHomeButton?: boolean;
    isResetButton?: boolean;
    children?: ReactNode;
}

export const EmptyList = memo<IProps>(function ({
    children,
    advice,
    title,
    isToHomeButton = false,
    isResetButton = false,
    className,
}) {
    const router = useRouter();
    const {urlService: filterUrlService} = useContext(FilterContext);

    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(async () => {
        await router.push(HOME_ROUTE);
    }, [router]);

    const handleResetAllFilters = useCallback<MouseEventHandler<HTMLButtonElement>>(async () => {
        await filterUrlService?.flushParam();
    }, [filterUrlService]);

    return (
        <div className={classNames(style.root, className)}>
            <div className={style.container}>
                <SvgIcon className={style.emptyIcon} svg={productListEmpty} />
                <div>
                    <Typography className={style.listTitle} color="gray100" element="h4" variant="h4">
                        {title}
                    </Typography>
                    {advice}
                </div>
                <div>
                    {isToHomeButton && (
                        <Button className={style.resetButton} onClick={handleClick} size="large" theme="primary">
                            На главную
                        </Button>
                    )}
                    {isResetButton && (
                        <Button
                            className={style.resetButton}
                            onClick={handleResetAllFilters}
                            size="large"
                            theme="primary"
                        >
                            Сбросить фильтр
                        </Button>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
});
