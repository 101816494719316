import {combineReducers} from '@reduxjs/toolkit';
import {addressesModuleName, addressesReducer} from 'modules/addresses/slice';
import {TAddressesState} from 'modules/addresses/types';
import {authenticationModuleName, authenticationReducer} from 'modules/authentication/slice';
import {IAuthenticationState} from 'modules/authentication/types';
import {barcodeScannerModuleName, barcodeScannerReducer} from 'modules/barcode-scanner/slice';
import {IBarcodeScannerState} from 'modules/barcode-scanner/types';
import {bottomBarModuleName, bottomBarReducer} from 'modules/bottombar/slice';
import {IBottomBarState} from 'modules/bottombar/types';
import {brandModuleName, brandReducer} from 'modules/brand/slice';
import {IBrandState} from 'modules/brand/types';
import {breadCrumbsModuleName, breadCrumbsReducer} from 'modules/breadcrumbs/slice';
import {IBreadCrumbsState} from 'modules/breadcrumbs/types';
import {calculatorModuleName, calculatorReducer} from 'modules/calculators/slice';
import {ICalculatorState} from 'modules/calculators/types';
import {cartModuleName, cartReducer} from 'modules/cart/slice';
import {ICartState} from 'modules/cart/types';
import {categoriesReducer, categoryModuleName} from 'modules/categories/slice';
import {ICategoriesState} from 'modules/categories/types';
import {checkoutModuleName, checkoutReducer} from 'modules/checkout/slice';
import {ICheckoutState} from 'modules/checkout/types';
import {collectionXModuleName, collectionXReducer} from 'modules/collection-x/slice';
import {TCollectionXState} from 'modules/collection-x/types';
import {collectionsModuleName, collectionsReducer} from 'modules/collections/slice';
import {TCollectionState} from 'modules/collections/types';
import {commonModuleName, commonReducer} from 'modules/common/slice';
import {ICommonState} from 'modules/common/types';
import {compareProductsModuleName, compareProductsReducer} from 'modules/compareProducts/slice';
import {TCompareProductsState} from 'modules/compareProducts/types';
import {contentPageModuleName, contentPageReducer} from 'modules/content/slice';
import {IContentPageState} from 'modules/content/type';
import {errorsModuleName, errorsReducer} from 'modules/errors/slice';
import {IErrorState} from 'modules/errors/types';
import {favouritesModuleName, favouritesReducer} from 'modules/favourites/slice';
import {TFavouritesState} from 'modules/favourites/types';
import {homePageModuleName, homePageReducer} from 'modules/home-page/slice';
import {IHomePageState} from 'modules/home-page/types';
import {loaderModuleName, loaderReducer} from 'modules/loader/slice';
import {ILoaderState} from 'modules/loader/types';
import {locationsModuleName, locationsReducer} from 'modules/locations/slice';
import {ILocationsState} from 'modules/locations/types';
import {loyaltyCardListModuleName, loyaltyCardsListReducer} from 'modules/loyalty-cards/slice';
import {ILoyaltyCardListState} from 'modules/loyalty-cards/types';
import {menuModuleName, menuReducer} from 'modules/menu/slice';
import {IMenuState} from 'modules/menu/types';
import {ordersModuleName, ordersReducer} from 'modules/orders/slice';
import {TOrdersState} from 'modules/orders/types';
import {portalModuleName, portalReducer} from 'modules/portal/slice';
import {IPortalState} from 'modules/portal/types';
import {priceAndBonusesModuleName, priceAndBonusesReducer} from 'modules/price-n-bonuses/slice';
import {IPriceAndBonusesState} from 'modules/price-n-bonuses/types';
import {productModuleName, productReducer} from 'modules/product-card/slice';
import {IProductState} from 'modules/product-card/types';
import {productSubscriptionModuleName, productSubscriptionReducer} from 'modules/product-subscription/slice';
import {IProductSubscriptionState} from 'modules/product-subscription/types';
import {productsModuleName, productsReducer} from 'modules/products/slice';
import {TProductState} from 'modules/products/types';
import {promoModuleName, promoReducer} from 'modules/promo/slice';
import {TPromoState} from 'modules/promo/types';
import {requestLoggerModuleName, requestLoggerReducer} from 'modules/request-logger/slice';
import {IRequestLoggerState} from 'modules/request-logger/types';
import {searchPageModuleName, searchPageReducer} from 'modules/search-page/slice';
import {TSearchPageState} from 'modules/search-page/types';
import {searchPopupModuleName, searchPopupReducer} from 'modules/search-popup/slice';
import {TSearchPopUpState} from 'modules/search-popup/types';
import {serviceModuleName, servicesReducer} from 'modules/services/slice';
import {IServiceState} from 'modules/services/types';
import {shieldModuleName, shieldReducer} from 'modules/shields/slice';
import {IShieldState} from 'modules/shields/types';
import {shopsModuleName, shopsReducer} from 'modules/shops/slice';
import {IShopsState} from 'modules/shops/types';
import {sliderModuleName, sliderReducer} from 'modules/slider/slice';
import {ISliderState} from 'modules/slider/types';
import {themeModuleName, themeReducer} from 'modules/theme/slice';
import {IThemeState} from 'modules/theme/types';
import {userModuleName, userReducer} from 'modules/user/slice';
import {IUserState} from 'modules/user/types';
import {wishListModuleName, wishListReducer} from 'modules/wish-list/slice';
import {IWishListState} from 'modules/wish-list/types';
import {yandexMapModuleName, yandexMapReducer} from 'modules/yandex-map/slice';
import {IYandexMapState} from 'modules/yandex-map/types';

export interface IRootReducer {
    [authenticationModuleName]: IAuthenticationState;
    [barcodeScannerModuleName]: IBarcodeScannerState;
    [bottomBarModuleName]: IBottomBarState;
    [breadCrumbsModuleName]: IBreadCrumbsState;
    [calculatorModuleName]: ICalculatorState;
    [cartModuleName]: ICartState;
    [categoryModuleName]: ICategoriesState;
    [collectionXModuleName]: TCollectionXState;
    [collectionsModuleName]: TCollectionState;
    [errorsModuleName]: IErrorState;
    [errorsModuleName]: IErrorState;
    [homePageModuleName]: IHomePageState;
    [homePageModuleName]: IHomePageState;
    [loaderModuleName]: ILoaderState;
    [locationsModuleName]: ILocationsState;
    [loyaltyCardListModuleName]: ILoyaltyCardListState;
    [ordersModuleName]: TOrdersState;
    [portalModuleName]: IPortalState;
    [portalModuleName]: IPortalState;
    [priceAndBonusesModuleName]: IPriceAndBonusesState;
    [productModuleName]: IProductState;
    [productSubscriptionModuleName]: IProductSubscriptionState;
    [productsModuleName]: TProductState;
    [requestLoggerModuleName]: IRequestLoggerState;
    [searchPageModuleName]: TSearchPageState;
    [searchPopupModuleName]: TSearchPopUpState;
    [shieldModuleName]: IShieldState;
    [sliderModuleName]: ISliderState;
    [userModuleName]: IUserState;
    [wishListModuleName]: IWishListState;
    [loyaltyCardListModuleName]: ILoyaltyCardListState;
    [collectionXModuleName]: TCollectionXState;
    [ordersModuleName]: TOrdersState;
    [commonModuleName]: ICommonState;
    [brandModuleName]: IBrandState;
    [shopsModuleName]: IShopsState;
    [promoModuleName]: TPromoState;
    [serviceModuleName]: IServiceState;
    [checkoutModuleName]: ICheckoutState;
    [addressesModuleName]: TAddressesState;
    [favouritesModuleName]: TFavouritesState;
    [yandexMapModuleName]: IYandexMapState;
    [menuModuleName]: IMenuState;
    [compareProductsModuleName]: TCompareProductsState;
    [themeModuleName]: IThemeState;
    [contentPageModuleName]: IContentPageState;
}

export const rootReducer = combineReducers<IRootReducer>({
    [addressesModuleName]: addressesReducer,
    [authenticationModuleName]: authenticationReducer,
    [barcodeScannerModuleName]: barcodeScannerReducer,
    [bottomBarModuleName]: bottomBarReducer,
    [brandModuleName]: brandReducer,
    [breadCrumbsModuleName]: breadCrumbsReducer,
    [calculatorModuleName]: calculatorReducer,
    [cartModuleName]: cartReducer,
    [categoryModuleName]: categoriesReducer,
    [checkoutModuleName]: checkoutReducer,
    [collectionXModuleName]: collectionXReducer,
    [collectionsModuleName]: collectionsReducer,
    [commonModuleName]: commonReducer,
    [compareProductsModuleName]: compareProductsReducer,
    [contentPageModuleName]: contentPageReducer,
    [errorsModuleName]: errorsReducer,
    [favouritesModuleName]: favouritesReducer,
    [homePageModuleName]: homePageReducer,
    [loaderModuleName]: loaderReducer,
    [locationsModuleName]: locationsReducer,
    [loyaltyCardListModuleName]: loyaltyCardsListReducer,
    [menuModuleName]: menuReducer,
    [ordersModuleName]: ordersReducer,
    [portalModuleName]: portalReducer,
    [priceAndBonusesModuleName]: priceAndBonusesReducer,
    [productModuleName]: productReducer,
    [productSubscriptionModuleName]: productSubscriptionReducer,
    [productsModuleName]: productsReducer,
    [promoModuleName]: promoReducer,
    [requestLoggerModuleName]: requestLoggerReducer,
    [searchPageModuleName]: searchPageReducer,
    [searchPopupModuleName]: searchPopupReducer,
    [serviceModuleName]: servicesReducer,
    [shieldModuleName]: shieldReducer,
    [shopsModuleName]: shopsReducer,
    [sliderModuleName]: sliderReducer,
    [themeModuleName]: themeReducer,
    [userModuleName]: userReducer,
    [wishListModuleName]: wishListReducer,
    [yandexMapModuleName]: yandexMapReducer,
});
