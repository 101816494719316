import {plural} from './index';

export const getReviewPluralText = (value: number) => plural(value, ['отзыв', 'отзыва', 'отзывов']);
export const getProductPluralText = (value: number) => plural(value, ['товар', 'товара', 'товаров']);
export const getElementPluralText = (value: number) => plural(value, ['элемент', 'элемента', 'элементов']);
export const getSuitablePluralText = (value: number) => plural(value, ['подходящий', 'подходящих', 'подходящих']);

export const getOrderPluralText = (value: number) => plural(value, ['заказ', 'заказа', 'заказов']);

export const getHourPluralText = (value: number) => plural(value, ['час', 'часа', 'часов']);
export const getDayPluralText = (value: number) => plural(value, ['день', 'дня', 'дней']);

export const getFromStorePluralText = (value: number) => plural(value, ['магазина', 'магазинов', 'магазинов']);

export const getCategoryPluralText = (value: number) => plural(value, ['категория', 'категории', 'категорий']);
