import classNames from 'classnames';
import {Button} from 'components/Button';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {getPaymentLink} from 'modules/checkout/thunks';
import {OrderXPageSection} from 'modules/orders/components/OrderXPageSection';
import {OrderDetail} from 'new-models';
import {useRouter} from 'next/router';
import React, {memo} from 'react';
import {PROFILE_ORDERS_ROUTE} from 'routing/constants';

import style from './style.module.scss';

interface IProps extends OrderDetail {
    className?: string;
}

export const OrderXNoPaidSection = memo<IProps>(function OrderXNoPaidSection({className, id}) {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const handlePay = async () => {
        const link = await dispatch(getPaymentLink(id));

        if (!link) {
            router.push(`${PROFILE_ORDERS_ROUTE}/${id}`);
            return;
        }

        const redirectUrl = `${window.location.protocol}//${window.location.host}${PROFILE_ORDERS_ROUTE}/${id}`;

        const url = new URL(link);
        url.searchParams.set('back_url_s', redirectUrl);
        url.searchParams.set('back_url_f', redirectUrl);

        router.push(url);
    };

    return (
        <OrderXPageSection className={classNames(style.root, className)}>
            <Button className={style.button} isStretched onClick={handlePay} size="medium">
                Оплатить заказ
            </Button>
        </OrderXPageSection>
    );
});
