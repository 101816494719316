import {BackButton} from 'components/BackButton';
import {Typography} from 'components/Typography';
import {HomePageServiceCard} from 'modules/home-page/components/HomePageServiceCard';
import {HomePageContext} from 'modules/home-page/context';
import {useContext} from 'react';

import style from './style.module.scss';

// TODO: Transferring services to the service module
export const ServicesPage = () => {
    const {homeServiceList} = useContext(HomePageContext);

    return (
        <div className={style.servicePageContainer}>
            <BackButton />
            <Typography className={style.servicePageTitle} color="gray100" data-skeleton-item element="h1" variant="h1">
                Сервисы и услуги
            </Typography>
            <ul className={style.servicePageList}>
                {homeServiceList.map((serviceItem) => {
                    return (
                        <li key={serviceItem.url}>
                            <HomePageServiceCard className={style.servicePageCard} service={serviceItem} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
