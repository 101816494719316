import {getHashFromLocation} from 'core/helpers';
import {useEffect, useState} from 'react';

export const useHash = () => {
    const [hash, setHash] = useState(getHashFromLocation());

    useEffect(() => {
        const handleHashChange = () => {
            setHash(getHashFromLocation());
        };
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return hash;
};
