import classNames from 'classnames';
import {ImageZooming} from 'components/ImageZooming';
import {IOptionsMediaHarvesterProps, MediaHarvester} from 'components/MediaHarvester';
import {generateMediaOptions, IMAGE_RESIZE_CODE} from 'core/helpers/imageResize';
import {TPropsWithClassName} from 'core/types';
import {GalleryMainVideoSlide} from 'modules/slider/components/GalleryMainVideoSlide';
import {GalleryMainYoutubeSlide} from 'modules/slider/components/GalleryMainYoutubeSlide';
import {GALLERY_MODE} from 'modules/slider/constants';
import {GallerySliderContext, IGallerySliderContextProps} from 'modules/slider/context';
import {MediaImage, MediaTypeEnum, MediaYoutube} from 'new-models';
import React, {MouseEventHandler, useContext, useMemo} from 'react';

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    isActive?: boolean;
    media: MediaImage | MediaYoutube;
    onClick: MouseEventHandler<HTMLButtonElement>;
}

export const GalleryMainSlide: FC<IProps> = ({isActive, media, onClick, className}) => {
    const {mode} = useContext<IGallerySliderContextProps>(GallerySliderContext);

    const options = useMemo<IOptionsMediaHarvesterProps>(
        () =>
            generateMediaOptions({
                resizeKey: IMAGE_RESIZE_CODE[mode === GALLERY_MODE.fullscreen ? 'w1920' : 'w1176'],
                type: MediaTypeEnum.Image,
            }),
        [mode]
    );

    if (MediaTypeEnum.Video === media.type) {
        return <GalleryMainVideoSlide isActive={isActive} media={media} />;
    }

    if (MediaTypeEnum.Youtube === media.type) {
        return <GalleryMainYoutubeSlide className={style.video} isActive={isActive} media={media} />;
    }

    return (
        <button
            className={classNames(className, style.galleryMainSlide, {
                [style[mode]]: Boolean(mode),
            })}
            onClick={onClick}
        >
            {GALLERY_MODE.fullscreen === mode ? (
                <ImageZooming>
                    <MediaHarvester media={media} options={options} />
                </ImageZooming>
            ) : (
                <MediaHarvester media={media} options={options} />
            )}
        </button>
    );
};
