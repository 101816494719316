// import {CATEGORY_LEVEL} from 'modules/categories/constants';
// import {useCategoryLevel} from 'modules/categories/hooks/useCategoryLevel';
import {Route} from 'modules/router/components/Route';
import {FranchiseSkeletonRoute} from 'modules/skeleton/components/FranchiseSkeletonRoute';
import {SkeletonWrapper} from 'modules/skeleton/components/SkeletonWrapper';
import {useMountSkeletonPage} from 'modules/skeleton/hooks/useMountSkeletonPage';
import {SkeletonPageLayout} from 'modules/skeleton/layouts/SkeletonPageLayout';
import {BrandListPageSkeleton} from 'modules/skeleton/pages/BrandListPageSkeleton';
import {BrandPageSkeleton} from 'modules/skeleton/pages/BrandPageSkeleton';
import {CartPageSkeleton} from 'modules/skeleton/pages/CartPageSkeleton';
// import {CategoryFirstLevelSkeleton} from 'modules/skeleton/pages/CategoryFirstLevelSkeleton';
// import {CategorySecondLevelSkeleton} from 'modules/skeleton/pages/CategorySecondLevelSkeleton';
import {CategoryThirdLevelSkeleton} from 'modules/skeleton/pages/CategoryThirdLevelSkeleton';
import {CheckoutSkeletonPage} from 'modules/skeleton/pages/CheckoutSkeletonPage';
import {CollectionsPageSkeleton} from 'modules/skeleton/pages/CollectionsPageSkeleton';
import {ContentPageSkeleton} from 'modules/skeleton/pages/ContentPageSkeleton';
import {DashboardAddressesSkeleton} from 'modules/skeleton/pages/DashboardAddressesSkeleton';
import {DashboardOrdersSkeleton} from 'modules/skeleton/pages/DashboardOrdersSkeleton';
import {DashboardOrderXSkeleton} from 'modules/skeleton/pages/DashboardOrderXSkeleton';
import {DashboardProfileSkeleton} from 'modules/skeleton/pages/DashboardProfileSkeleton';
import {FavouritesPageSkeleton} from 'modules/skeleton/pages/FavouritesPageSkeleton';
import {HomePageSkeleton} from 'modules/skeleton/pages/HomePageSkeleton';
import {OnlinePaymentPageSkeleton} from 'modules/skeleton/pages/OnlinePaymentPageSkeleton';
import {ProductPageSkeleton} from 'modules/skeleton/pages/ProductPageSkeleton';
import {PromoListPageSkeleton} from 'modules/skeleton/pages/PromoListPageSkeleton';
import {PromoPageSkeleton} from 'modules/skeleton/pages/PromoPageSkeleton';
import {ServicePageSkeleton} from 'modules/skeleton/pages/ServicePageSkeleton';
import {ShopListPageSkeleton} from 'modules/skeleton/pages/ShopListPageSkeleton';
import {ThanksForYourOrderPageSkeleton} from 'modules/skeleton/pages/ThanksForYourOrderPageSkeleton';
import {
    SKELETON_BRAND_LIST_ROUTE,
    SKELETON_BRAND_ROUTE,
    SKELETON_CART_ROUTE,
    SKELETON_CATALOG_ROUTE,
    SKELETON_CATALOG_SELECTION_ROUTE,
    SKELETON_CHECKOUT_ROUTE,
    SKELETON_COLLECTION_ROUTE,
    SKELETON_COLLECTION_X_ROUTE,
    SKELETON_DASHBOARD_PROFILE_ROUTE,
    SKELETON_FAVOURITES_ROUTE,
    SKELETON_HOME_ROUTE,
    SKELETON_INFO_PAGES_ROUTE,
    SKELETON_ONLINE_PAYMENT_ROUTE,
    SKELETON_PRODUCT_ROUTE,
    SKELETON_PRODUCT_SHOR_ROUTE,
    SKELETON_PROFILE_ADDRESSES_ROUTE,
    SKELETON_PROFILE_ORDER_X_ROUTE,
    SKELETON_PROFILE_ORDERS_ROUTE,
    SKELETON_PROMO_LIST_ROUTE,
    SKELETON_PROMO_ROUTE,
    SKELETON_SEARCH_ROUTE,
    SKELETON_SERVICE_ROUTE,
    SKELETON_SHOP_LIST_ROUTE,
    SKELETON_THANKS_FOR_YOUR_ORDER_ROUTE,
} from 'modules/skeleton/routes';
import {NextParsedUrlQuery} from 'next/dist/server/request-meta';
import React, {FC, useEffect} from 'react';

interface IProps {
    nextUrl: string;
}

// const CatalogSkeleton: FC<IProps> = ({nextUrl}) => {
//     const categoryLevel = useCategoryLevel(nextUrl);
//
//     switch (categoryLevel) {
//         case CATEGORY_LEVEL.first: {
//             return <CategoryFirstLevelSkeleton />;
//         }
//         case CATEGORY_LEVEL.second: {
//             return <CategorySecondLevelSkeleton />;
//         }
//         case CATEGORY_LEVEL.third: {
//             return <CategoryThirdLevelSkeleton />;
//         }
//         default:
//             return null;
//     }
// };

export const SkeletonRoot: FC<IProps> = ({nextUrl}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const initialQuery: NextParsedUrlQuery = {path: nextUrl};

    const routes = (
        <>
            <Route
                component={<CategoryThirdLevelSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_CATALOG_ROUTE}
            >
                {/*  TODO: Fix while refactoring collections and combo filters */}
                {/*
                <Route
                        component={<CategoryThirdLevelSkeleton />}
                        layout={<SkeletonPageLayout />}
                        path={SKELETON_COMBO_FILTER_ROUTE}
                    />
                    <Route
                        component={<CollectionXPageSkeleton />}
                        layout={<SkeletonPageLayout />}
                        path={SKELETON_COLLECTION_X_ROUTE}
                    />
                    <Route
                        component={<CollectionsListPageSkeleton />}
                        initialQuery={initialQuery}
                        layout={<SkeletonPageLayout />}
                        path={SKELETON_COLLECTION_ROUTE}
                    />
                    */}
            </Route>
            <Route
                component={<CategoryThirdLevelSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_CATALOG_SELECTION_ROUTE}
            />

            <Route
                component={<CartPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_CART_ROUTE}
            />
            <Route
                component={<CheckoutSkeletonPage />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout isHideBreadcrumbs />}
                path={SKELETON_CHECKOUT_ROUTE}
            />
            <Route
                component={<ProductPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_PRODUCT_ROUTE}
            />
            <Route
                component={<ProductPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_PRODUCT_SHOR_ROUTE}
            />
            <Route
                component={<CategoryThirdLevelSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_SEARCH_ROUTE}
            />
            <Route component={<HomePageSkeleton />} initialQuery={initialQuery} path={SKELETON_HOME_ROUTE} />
            <Route
                component={<ServicePageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_SERVICE_ROUTE}
            />
            <Route
                component={<DashboardProfileSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_DASHBOARD_PROFILE_ROUTE}
            />
            <Route
                component={<DashboardOrdersSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_PROFILE_ORDERS_ROUTE}
            >
                <Route
                    component={<DashboardOrderXSkeleton />}
                    initialQuery={initialQuery}
                    layout={<SkeletonPageLayout />}
                    path={SKELETON_PROFILE_ORDER_X_ROUTE}
                />
            </Route>
            <Route
                component={<DashboardAddressesSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_PROFILE_ADDRESSES_ROUTE}
            />
            <Route
                component={<FavouritesPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_FAVOURITES_ROUTE}
            />
            <Route
                component={<BrandListPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_BRAND_LIST_ROUTE}
            />
            <Route
                component={<BrandPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_BRAND_ROUTE}`}
            />
            <Route
                component={<ShopListPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_SHOP_LIST_ROUTE}`}
            />
            <Route
                component={<PromoListPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_PROMO_LIST_ROUTE}`}
            />
            <Route
                component={<PromoPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_PROMO_ROUTE}`}
            />
            <Route
                component={<OnlinePaymentPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout isHideBreadcrumbs />}
                path={`${SKELETON_ONLINE_PAYMENT_ROUTE}`}
            />
            <Route
                component={<ThanksForYourOrderPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout isHideBreadcrumbs />}
                path={`${SKELETON_THANKS_FOR_YOUR_ORDER_ROUTE}`}
            />
            <Route
                component={<CollectionsPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_COLLECTION_ROUTE}`}
            />
            <Route
                component={<CategoryThirdLevelSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={SKELETON_COLLECTION_X_ROUTE}
            />
            <Route
                component={<ContentPageSkeleton />}
                initialQuery={initialQuery}
                layout={<SkeletonPageLayout />}
                path={`${SKELETON_INFO_PAGES_ROUTE}`}
            />
            <FranchiseSkeletonRoute initialQuery={initialQuery} />
        </>
    );

    return useMountSkeletonPage(SkeletonWrapper, {children: routes});
};
