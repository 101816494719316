import classNames from 'classnames';
import {ISelectProps} from 'components/InputSelect';
import {ProductsCount} from 'modules/products/components/ProductsCount';
import {ProductListPageFunctionalityContext} from 'modules/products/context';
import {PageInfo, Sorting} from 'new-models';
import {FilterButton} from 'plugins/modules/filter/components/FilterButton';
import {FilterContext} from 'plugins/modules/filter/context';
import {useIsListLoading} from 'plugins/modules/listing/hooks/useIsListLoading';
import {SortingSelect} from 'plugins/modules/sorting/components/SortingSelect';
import React, {FC, useContext, useMemo} from 'react';

import style from './style.module.scss';

export interface IProps {
    count: PageInfo['count'];
    sortList: Sorting[];
    onSortChange: ISelectProps<Sorting['direction']>['onChange'];
}

export const ProductListControlBar: FC<IProps> = ({count, onSortChange, sortList}) => {
    const functionality = useContext(ProductListPageFunctionalityContext);
    const isLoading = useIsListLoading();
    const {filterList, appliedChooseFiltersByVariantList, appliedRangeFiltersList} = useContext(FilterContext);

    const areAppliedFilters = useMemo(
        () => Boolean(appliedChooseFiltersByVariantList.length || appliedRangeFiltersList.length),
        [appliedChooseFiltersByVariantList.length, appliedRangeFiltersList.length]
    );

    return (
        <>
            <div
                className={classNames(style.productListLayoutControlBar, {
                    [style.productListLayoutControlBarCollections]: functionality.collectionFilterButton,
                })}
                data-skeleton-item
            >
                {Boolean(filterList.length) && <FilterButton className={style.productListLayoutFilterButton} />}
                {functionality.countIndicator && (
                    <ProductsCount
                        className={style.productListLayoutProductCount}
                        count={count || 0}
                        isStartText={areAppliedFilters}
                    />
                )}
            </div>
            {(functionality.sortingSelector || functionality.viewSwitcher) && (
                <div
                    className={classNames(style.productListLayoutControlBar, style.productListLayoutControlBarLeft)}
                    data-skeleton-item
                >
                    {functionality.sortingSelector && (
                        <SortingSelect
                            className={style.productListLayoutFilterSelect}
                            isLoading={isLoading}
                            onSortChange={onSortChange}
                            sortingList={sortList}
                        />
                    )}
                </div>
            )}
        </>
    );
};
