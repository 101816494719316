import {TPropsWithChildrenRequire} from 'core/types';
import React, {createContext} from 'react';

export const TabContext = createContext<string>('');

interface IProps<T extends string> extends TPropsWithChildrenRequire {
    name: T;
}

export const TabProvider = <T extends string>({name, children}: IProps<T>) => {
    return <TabContext.Provider value={name}>{children}</TabContext.Provider>;
};
