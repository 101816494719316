import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithChildrenRequire} from 'core/types';
import {ThemeContext} from 'modules/theme/context';
import {selectTheme} from 'modules/theme/selectors';
import React, {FC} from 'react';

interface IProps extends TPropsWithChildrenRequire {}

export const ThemeProvider: FC<IProps> = ({children}) => {
    const theme = useAppSelector(selectTheme);

    return <ThemeContext.Provider value={{theme}}>{children}</ThemeContext.Provider>;
};
