import {CollapseAnimated, ICollapseAnimatedProps} from 'components/CollapseAnimated';
import {CollapseAnimatedOnScrollBottom} from 'components/CollapseAnimatedOnScrollBottom';
import {CollapseAnimatedOnTop} from 'components/CollapseAnimatedOnTop';
import {TPropsWithChildrenRequire, TPropsWithClassName} from 'core/types';
import React, {FC, useMemo} from 'react';

export const COLLAPSE_VARIANTS = {
    onScrollBottom: 'onScrollBottom',
    onTop: 'onTop',
} as const;

interface IProps extends TPropsWithChildrenRequire, ICollapseAnimatedProps, TPropsWithClassName {
    variant?: keyof typeof COLLAPSE_VARIANTS;
    isCollapsed?: boolean;
}
export const CollapseAnimatedOnScrollMix: FC<IProps> = ({className, children, variant, ...rest}) => {
    const Component = useMemo(() => {
        if (variant === COLLAPSE_VARIANTS.onTop) {
            return CollapseAnimatedOnTop;
        }

        if (variant === COLLAPSE_VARIANTS.onScrollBottom) {
            return CollapseAnimatedOnScrollBottom;
        }

        return CollapseAnimated;
    }, [variant]);

    return (
        <Component {...rest} className={className}>
            {children}
        </Component>
    );
};
