import 'react-datepicker/dist/react-datepicker.css';

import ruDateFns from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import ruDayJs from 'dayjs/locale/ru';
import {registerLocale} from 'react-datepicker';

export const init = () => {
    dayjs.locale(ruDayJs);
    registerLocale('ru', ruDateFns);
};
