import classNames from 'classnames';
import {useAppDispatch} from 'core/redux/hooks/useAppDispatch';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {TPropsWithClassName} from 'core/types';
import {useIsSkeleton} from 'modules/skeleton/hooks/useIsSkeleton';
import {MIN_SWIPE_DISTANCE, SPACE_BETWEEN} from 'modules/slider/constants';
import {selectActiveIdx} from 'modules/slider/selectors';
import {actionSetActiveIdx} from 'modules/slider/slice';
import {memo, ReactNode, useCallback, useEffect, useMemo, useRef} from 'react';
import {Autoplay, Mousewheel, Navigation, Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {SwiperProps} from 'swiper/swiper-react';
import {AutoplayOptions} from 'swiper/types';
import {PaginationOptions} from 'swiper/types/modules/pagination';
import TSwiper from 'swiper/types/swiper-class';
import {SwiperOptions} from 'swiper/types/swiper-options';

import style from './style.module.scss';

const DEFAULT_MOUSE_WHEEL = {
    forceToAxis: true,
};

export interface IProps extends SwiperProps, TPropsWithClassName {
    children: ReactNode[];
    isSlideTo?: boolean;
    isPagination?: boolean;
    isNavigation?: boolean;
    onInitialized?: (swiper: TSwiper) => void;
    isSliderButtonsDefault?: boolean;
}

export const Slider = memo<IProps>(function Slider({
    autoplay,
    children,
    className,
    isSlideTo = false,
    isPagination = false,
    isNavigation = false,
    isSliderButtonsDefault = true,
    spaceBetween,
    onInitialized,
    pagination,
    navigation,
    loop = false,
    ...sliderProps
}) {
    const dispatch = useAppDispatch();

    const activeIdx = useAppSelector(selectActiveIdx);

    const slider = useRef<TSwiper | undefined>();

    const isSkeleton = useIsSkeleton();

    useEffect(() => {
        if (isSlideTo && Boolean(slider.current)) {
            slider.current?.slideTo(activeIdx);
        }
    }, [activeIdx, isSlideTo, slider]);

    const onSwiper = useCallback(
        (swiper: TSwiper) => {
            slider.current = swiper;

            onInitialized?.(swiper);
        },
        [onInitialized]
    );

    const onActiveIndexChange = useCallback(
        (swiper: TSwiper) => {
            if (isSlideTo) {
                dispatch(actionSetActiveIdx(swiper.activeIndex));
            }
        },
        [dispatch, isSlideTo]
    );

    const renderSlides = useMemo<ReactNode[]>(
        () =>
            children.map((child, index) => (
                <SwiperSlide
                    key={index}
                    {...(autoplay && {['data-swiper-autoplay']: (autoplay as AutoplayOptions)?.delay})}
                >
                    {child}
                </SwiperSlide>
            )),
        [autoplay, children]
    );

    const renderModules = useMemo<SwiperOptions['modules']>(() => {
        const modules = [Mousewheel];

        if (isPagination) {
            modules.push(Pagination);
        }

        if (isNavigation) {
            modules.push(Navigation);
        }

        if (autoplay) {
            modules.push(Autoplay);
        }

        return modules;
    }, [autoplay, isNavigation, isPagination]);

    const paginationParams = useMemo<PaginationOptions | boolean>(
        () => pagination ?? isPagination,
        [isPagination, pagination]
    );

    return (
        <Swiper
            {...sliderProps}
            className={classNames(className, style.slider, {
                [style.sliderButtonsDefault]: Boolean(isSliderButtonsDefault),
            })}
            enabled={!isSkeleton}
            loop={loop}
            modules={renderModules}
            mousewheel={DEFAULT_MOUSE_WHEEL}
            navigation={navigation ?? (isNavigation && !isSkeleton)}
            onActiveIndexChange={onActiveIndexChange}
            onSwiper={onSwiper}
            pagination={paginationParams}
            spaceBetween={spaceBetween ?? SPACE_BETWEEN.default}
            threshold={MIN_SWIPE_DISTANCE}
        >
            {renderSlides}
        </Swiper>
    );
});
