import {EmptyList} from 'components/EmptyList';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import React, {ReactNode, useMemo} from 'react';

export const EmptyProductList: FC<TPropsWithClassName> = ({className}) => {
    const renderAdvice = useMemo<ReactNode>(
        () => (
            <Typography color="gray80" element="div" variant="p">
                Попробуйте обновить страницу или выбрать другие значения фильтров
            </Typography>
        ),
        []
    );
    return <EmptyList advice={renderAdvice} className={className} isResetButton title="Ничего не найдено" />;
};
