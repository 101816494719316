import {AbstractApiRequestInterceptor} from 'core/abstract/AbstractApiRequestInterceptor';
import {TCommonRequestParameters, TRequestOption} from 'core/types';

export class AbortRequestInterceptor extends AbstractApiRequestInterceptor<TCommonRequestParameters<TRequestOption>> {
    private readonly requestMap: Map<string, AbortController>;

    private constructor() {
        super();

        this.requestMap = new Map<string, AbortController>();
    }

    public async intercept(
        requestOption: TCommonRequestParameters<TRequestOption>
    ): Promise<TCommonRequestParameters<TRequestOption>> {
        if (requestOption.options?.requestId) {
            if (!this.requestMap.has(requestOption.options.requestId)) {
                this.registerRequest(requestOption);
            } else {
                this.requestMap.get(requestOption.options.requestId)?.abort();
                this.requestMap.delete(requestOption.options.requestId);

                this.registerRequest(requestOption);
            }
        }

        return requestOption;
    }

    private registerRequest(requestOption: TCommonRequestParameters<TRequestOption>): void {
        const abortController = new AbortController();

        if (!requestOption.options?.requestId) {
            return;
        }

        requestOption.options.signal = abortController.signal;

        this.requestMap.set(requestOption.options.requestId, abortController);
    }

    public static instance: AbortRequestInterceptor;

    public static abort(requestId: string) {
        const abortInterceptor = AbortRequestInterceptor.getInstance();

        const request = abortInterceptor.requestMap.get(requestId);

        if (!request) {
            return;
        }

        request.abort();
    }

    public static getInstance(): AbortRequestInterceptor {
        if (!AbortRequestInterceptor.instance) {
            AbortRequestInterceptor.instance = new AbortRequestInterceptor();
        }

        return AbortRequestInterceptor.instance;
    }
}
