import {ShopCard} from 'modules/shops/components/ShopCard';
import {LIST_TAB} from 'modules/shops/constants';
import {IActiveShop} from 'modules/shops/pages/ShopListPage';
import {StoreShort} from 'new-models';
import React, {memo, useCallback, useEffect, useRef} from 'react';

import style from './style.module.scss';

interface IProps {
    activeShop?: IActiveShop;
    activeTabId?: number;
    onClick?: (data: StoreShort) => void;
    stores: StoreShort[];
}

export const ShopCardList = memo<IProps>(function ShopCardList({activeShop, activeTabId, onClick, stores}) {
    const activeCardRef = useRef<HTMLDivElement | null>(null);

    const scrollToCard = useCallback(() => {
        activeCardRef.current?.scrollIntoView({
            block: 'nearest',
        });
    }, []);

    useEffect(() => {
        if (!activeShop?.isClickedPlaceMark) {
            return;
        }

        scrollToCard();
    }, [activeShop, scrollToCard]);

    useEffect(() => {
        if (activeTabId !== LIST_TAB.id) {
            return;
        }

        scrollToCard();
    }, [activeTabId, scrollToCard]);

    return (
        <ul className={style.root}>
            {stores.map((data) => {
                const {id} = data;

                const isActive = activeShop?.id === id;

                return (
                    <li key={id}>
                        <ShopCard
                            data={data}
                            isActive={isActive}
                            onClick={onClick}
                            ref={isActive ? activeCardRef : null}
                        />
                    </li>
                );
            })}
        </ul>
    );
});
