import classNames from 'classnames';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {getPopularRequestUrl} from 'modules/search-popup/helpers';
import Link from 'next/link';
import React, {FC, memo} from 'react';
import styles from 'styles/search-styles.module.scss';

interface IProps extends TPropsWithClassName {
    list: string[];
    title: string;
}

export const SearchPanelPopularRequests: FC<IProps> = memo(function SearchPanelPopularRequests({
    className,
    list,
    title,
}) {
    return (
        <div className={classNames(styles.searchPanelElement, className)}>
            <Typography color="gray50" data-skeleton-item element="p" variant="elements-p">
                {title}
            </Typography>
            <ul className={styles.searchPanelList}>
                {list.map((value) => (
                    <Link href={getPopularRequestUrl(value)} key={value}>
                        <Typography data-skeleton-item element="a" variant="elements-p">
                            {value}
                        </Typography>
                    </Link>
                ))}
            </ul>
        </div>
    );
});
