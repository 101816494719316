import {GRID_COLUMNS_COUNT} from 'components/Grid';
import {ProductOfferDetail} from 'models';
import {TCatalogResizingMap, TColumnsCountLayout, TColumnsCountProductItem} from 'modules/products/types';

export const PRODUCT_TYPE: {
    [K in Exclude<ProductOfferDetail['type'], 'offline-wish'>]: K;
} & {
    offline: 'offline-wish';
} = {
    collection: 'collection',
    offline: 'offline-wish',
    simple: 'simple',
    technical: 'technical',
    visual: 'visual',
};

export const PRODUCT_LIST_VIEW = {
    list: 'list',
    tile: 'tile',
} as const;

export const PRODUCT_CARD_VARIANT = {
    auto: 'auto',
    bestSelling: 'bestSelling',
    favourites: 'favourites',
    main: 'main',
    mainBottom: 'mainBottom',
    preview: 'preview',
    previewVisual: 'previewVisual',
} as const;

export const POSITION_IN_CARD_VARIANT = {
    productPageHeader: 'productPageHeader',
    searchPanel: 'searchPanel',
} as const;

export const CATALOG_LISTING_IMAGE_RESIZING_MAP: TCatalogResizingMap = {
    collection: [],
    mixed: [],
    simplelist: [
        {
            defaultSize: true,
            imageSize: {
                height: 100,
                width: 100,
            },
            screenWidth: 1440,
        },
    ],
    simplepreview: [
        {
            defaultSize: true,

            imageSize: {
                height: 207,
                width: 207,
            },
            screenWidth: 1440,
        },
        {
            imageSize: {
                height: 207,
                width: 207,
            },
            screenWidth: 360,
        },
    ],
    simpletile: [
        {
            defaultSize: true,

            imageSize: {
                height: 260,
                width: 322,
            },
            screenWidth: 1440,
        },
        {
            imageSize: {
                height: 228,
                width: 282,
            },
            screenWidth: 1280,
        },
        {
            imageSize: {
                height: 132,
                width: 156,
            },
            screenWidth: 360,
        },
    ],
    technical: [],
    visual: [
        {
            defaultSize: true,

            imageSize: {
                height: 322,
                width: 322,
            },
            screenWidth: 1440,
        },
        {
            imageSize: {
                height: 282,
                width: 282,
            },
            screenWidth: 1280,
        },
        {
            imageSize: {
                height: 274,
                width: 274,
            },
            screenWidth: 360,
        },
    ],
};

export const BATHROOM_FURNITURE_ID_60_74 = '30153';

export const SAFE_PRODUCT_PAGE_LOCAL_STORAGE_KEY = '__product-page';

export const DEFAULT_SKELETON_CARD_COUNT = 10;

export const COLUMNS_COUNT_LAYOUT: TColumnsCountLayout = {
    filter: 3,
    productsFilterHide: GRID_COLUMNS_COUNT,
    productsFilterShow: 9,
};

export const COLUMNS_COUNT_PRODUCT_ITEM: TColumnsCountProductItem = {
    filterHide: 3,
    filterShow: 3,
    listView: GRID_COLUMNS_COUNT,
    mobile: 6,
};

export const PRODUCTS_SELECTIONS = ['new', 'bestseller', 'popular'];
