import classNames from 'classnames';
import {Button} from 'components/Button';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {TPropsWithClassName} from 'core/types';
import {PickupPoint} from 'new-models';
import arrowRightIcon from 'public/icons/arrow-right-20.svg';
import React, {memo} from 'react';

const columnStyle = getGridColumnStyles();

import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    point: PickupPoint;
    onChangeStore: () => void;
    onDetailStore: () => void;
}

export const StoreSelection = memo<IProps>(function StoreSelection({className, point, onChangeStore, onDetailStore}) {
    const {title, address} = point;
    return (
        <Grid className={classNames(style.root, className)}>
            <div className={classNames(style.content, columnStyle)}>
                <Typography color="gray100" variant="h5">
                    {title}
                </Typography>
                <Typography color="primary70" variant="p">
                    {address}
                </Typography>
            </div>
            <div className={classNames(style.footer, columnStyle)}>
                <Button isStretched onClick={onDetailStore} theme="secondary">
                    Подробнее
                </Button>
                <Button
                    isStretched
                    onClick={onChangeStore}
                    rightIcon={<SvgIcon svg={arrowRightIcon} />}
                    theme="primary"
                >
                    Изменить магазин
                </Button>
            </div>
        </Grid>
    );
});
