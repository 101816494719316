import classNames from 'classnames';
import {IconElement} from 'components/IconElement';
import {Popup} from 'components/Popup';
import {SvgIcon} from 'components/SvgIcon';
import {Typography} from 'components/Typography';
import {PRICE_DENOMINATOR} from 'core/constants';
import {formatPrice} from 'core/helpers';
import {useAppSelector} from 'core/redux/hooks/useAppSelector';
import {useProfileClick} from 'modules/authentication/hooks/useProfileClick';
import {CartHeaderPopup} from 'modules/cart/components/CartHeaderPopup';
import {useIsCartPage} from 'modules/cart/hooks/useIsCartPage';
import {selectCartProductsList, selectCartTotal} from 'modules/cart/selectors';
import {selectFavouritesList} from 'modules/favourites/selector';
import {HeaderAuthPopup} from 'modules/header/components/HeaderAuthPopup';
import {HeaderUserMenu} from 'modules/user/components/HeaderUserMenu';
import {selectIsLoggedIn} from 'modules/user/selectors';
import Link from 'next/link';
import addToCartIcon from 'public/icons/add-to-cart.svg';
import favouriteIcon from 'public/icons/favourite.svg';
import loginIcon from 'public/icons/login.svg';
import profileIcon from 'public/icons/profile.svg';
import React, {memo, ReactNode, useMemo} from 'react';
import {CART_ROUTE, FAVOURITES_ROUTE, PAGES} from 'routing/constants';

import style from './style.module.scss';

export const HeaderMenuElements = memo(function HeaderRightButtons() {
    const cartTotal = useAppSelector(selectCartTotal);
    const cartProductsList = useAppSelector(selectCartProductsList);
    const favouritesList = useAppSelector(selectFavouritesList);
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    const isCartPage = useIsCartPage();
    const handleProfileClick = useProfileClick();

    const isCartPopupDisabled = useMemo<boolean>(() => {
        return isCartPage || 0 === cartProductsList.length;
    }, [isCartPage, cartProductsList.length]);

    const renderElements = useMemo<ReactNode>(() => {
        if (isLoggedIn) {
            return (
                <>
                    <li className={style.headerMenuElementsItem}>
                        <Popup className={style.headerUserMenuPopup} popup={<HeaderUserMenu />}>
                            <div className={style.link} onClick={handleProfileClick} role="presentation">
                                <IconElement>
                                    <SvgIcon className={style.svg} svg={profileIcon} />
                                </IconElement>
                                <Typography className={style.text} element="div" variant="p-small">
                                    Профиль
                                </Typography>
                            </div>
                        </Popup>
                    </li>
                </>
            );
        }

        return (
            <li className={style.headerMenuElementsItem}>
                <Popup className={style.headerAuthPopup} popup={<HeaderAuthPopup />}>
                    <div className={style.link} onClick={handleProfileClick} role="presentation">
                        <IconElement>
                            <SvgIcon className={style.svg} svg={loginIcon} />
                        </IconElement>
                    </div>
                    <Typography className={style.text} element="div" variant="p-small">
                        Войти
                    </Typography>
                </Popup>
            </li>
        );
    }, [handleProfileClick, isLoggedIn]);

    return (
        <ul className={style.headerMenuElementsList}>
            <li className={style.headerMenuElementsItem}>
                <Link href={FAVOURITES_ROUTE} passHref>
                    <a className={style.link}>
                        <IconElement count={favouritesList.length} countClassName={style.counter}>
                            <SvgIcon className={style.svg} svg={favouriteIcon} />
                        </IconElement>
                        <Typography className={style.text} element="div" variant="p-small">
                            {PAGES.FAVOURITES_PAGE.title}
                        </Typography>
                    </a>
                </Link>
            </li>
            {renderElements}
            <li className={style.headerMenuElementsItem}>
                <Popup className={style.cartPopup} disable={isCartPopupDisabled} popup={<CartHeaderPopup />}>
                    <Link href={CART_ROUTE} passHref>
                        <a className={style.link}>
                            <IconElement count={cartProductsList.length} countClassName={style.counter}>
                                <SvgIcon className={style.svg} svg={addToCartIcon} />
                            </IconElement>
                            {Boolean(cartTotal.price.totalCost) ? (
                                <Typography className={style.text} element="div" variant="p-small">
                                    {formatPrice((cartTotal.price.totalCost / PRICE_DENOMINATOR).toString(), {
                                        maximumFractionDigits: 2,
                                    })}
                                    <Typography
                                        className={classNames(style.headerLinkButtonPriceCurrency, style.text)}
                                        element="span"
                                        variant="p-small"
                                    >
                                        ₽
                                    </Typography>
                                </Typography>
                            ) : (
                                <Typography className={style.text} element="div" variant="p-small">
                                    Корзина
                                </Typography>
                            )}
                        </a>
                    </Link>
                </Popup>
            </li>
        </ul>
    );
});
