import * as yup from 'yup';
import {ObjectShape} from 'yup/lib/object';

export const getAddressValidator = (schema: ObjectShape) =>
    yup.object().shape({
        address: yup.string().trim().nullable(false).required('Поле является обязательным'),
        comment: yup.string().notRequired(),
        entrance: yup.number().notRequired(),
        floor: yup.number().notRequired(),
        isPrivateHouse: yup.boolean().notRequired(),
        latitude: yup.string().notRequired(),
        longitude: yup.string().notRequired(),
        name: yup.string().required('Поле является обязательным'),
        ...schema,
    });
