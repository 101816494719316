import {PromoCard} from 'modules/promo/components/PromoCard';
import {ActionShort} from 'new-models';
import React, {memo} from 'react';

import style from './style.module.scss';

interface IProps {
    promoList: ActionShort[];
}

export const PromoCardList = memo<IProps>(function PromoCardList({promoList}) {
    return (
        <ul className={style.list}>
            {promoList.map((data) => {
                return (
                    <li className={style.listItem} key={data.id}>
                        <PromoCard data={data} />
                    </li>
                );
            })}
        </ul>
    );
});
