import classNames from 'classnames';
import {getGridColumnStyles, Grid} from 'components/Grid';
import {TPropsWithClassName} from 'core/types';
import {HomePageBannerSlide} from 'modules/home-page/components/HomePageBannerSlide';
import {CustomButtons} from 'modules/slider/components/CustomButtons';
import {CustomNav} from 'modules/slider/components/CustomNav';
import {Slider} from 'modules/slider/components/Slider';
import useSwiper from 'modules/slider/hooks/useSwiper';
import {useTheme} from 'modules/theme/hooks/useTheme';
import {Promo} from 'new-models';
import React, {FC, useCallback, useEffect, useRef} from 'react';
import TSwiper from 'swiper/types/swiper-class';

import {BannerRightBlock} from './components/BannerRightBlock';
import style from './style.module.scss';

interface IProps extends TPropsWithClassName {
    bannerSlideList: Promo[];
    autoSlideInterval?: number;
}

const rightColumnStyle = getGridColumnStyles({md: 4});
const leftColumnStyle = getGridColumnStyles({md: 8});

export const HomePageBanner: FC<IProps> = ({className, bannerSlideList}) => {
    const {swiper, onInit} = useSwiper();

    const {switchTheme, theme} = useTheme();

    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);

    const onSlideChange = useCallback<(swiperClass: TSwiper) => void>(
        (swiperClass) => {
            switchTheme(bannerSlideList[swiperClass.activeIndex].isLight ?? true);
        },
        [bannerSlideList, switchTheme]
    );

    useEffect(() => {
        if (!swiper) {
            return;
        }

        switchTheme(bannerSlideList[0]?.isLight ?? true);
        swiper.on('slideChange', onSlideChange);
    }, [bannerSlideList, onSlideChange, swiper, switchTheme]);

    return (
        <div className={style.homePageBannerWrapper}>
            <Grid className={style.homePageBannerTop}>
                <div className={classNames(style.homePageBannerTopLeft, leftColumnStyle)}>
                    <CustomButtons
                        className={style.navigationActive}
                        nextRef={nextRef}
                        prevRef={prevRef}
                        slidesCount={bannerSlideList.length}
                    />
                    <div className={style.homePageBannerSlider} data-skeleton-item>
                        <Slider
                            autoplay={{delay: 10000, disableOnInteraction: false}}
                            centerInsufficientSlides
                            centeredSlides
                            className={classNames(style.slider, className)}
                            isPagination
                            isSlideTo
                            navigation={{
                                nextEl: nextRef.current,
                                prevEl: prevRef.current,
                            }}
                            onInit={onInit}
                            slidesPerGroup={1}
                            slidesPerView={1}
                            spaceBetween={0}
                        >
                            {bannerSlideList.map((bannerSlide, index) => {
                                return (
                                    <HomePageBannerSlide bannerSlide={bannerSlide} key={`${bannerSlide.id}:${index}`} />
                                );
                            })}
                        </Slider>
                        <CustomNav
                            arrows={false}
                            className={style.nav}
                            slidesLength={bannerSlideList.length}
                            theme={theme}
                        />
                    </div>
                </div>
                <div className={classNames(style.homePageBannerTopRight, rightColumnStyle)} data-skeleton-item>
                    <BannerRightBlock />
                </div>
            </Grid>
        </div>
    );
};
