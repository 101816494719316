import {ShopListPageContext} from 'modules/shops/context';
import {ShopListPage} from 'modules/shops/pages/ShopListPage';
import {SHOP_LIST_PAGE_SKELETON_DATA} from 'modules/skeleton/constants';
import React, {FC} from 'react';

export const ShopListPageSkeleton: FC = () => {
    return (
        <ShopListPageContext.Provider value={SHOP_LIST_PAGE_SKELETON_DATA}>
            <ShopListPage />
        </ShopListPageContext.Provider>
    );
};
